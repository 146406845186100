import React from "react";
import {
  Box,
  Button,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const NavBarToggle = ({
  text,
  isOpen,
  handleToggle,
  routes,
  handleNavigate,
  nestedRoutes,
  sx,
  isNormalButton,
  buttonPath,
}) => {
  return (
    <Box sx={sx}>
      <Box
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        padding="10px"
      >
        {isNormalButton ? (
          <Button
            sx={{ color: "#645e29", width: "100%" }}
            onClick={() => {
              handleNavigate(buttonPath);
            }}
          >
            {text}
          </Button>
        ) : (
          <Button
            sx={{ color: "#645e29", width: "100%" }}
            onClick={handleToggle}
            endIcon={
              <ArrowDropDownIcon
                sx={{
                  transition: "transform 0.5s", // smooth transition for rotation
                  transform: isOpen ? "rotate(-90deg)" : "rotate(0deg)", // decides the rotation based on collapseOpen state
                }}
              />
            }
          >
            {text}
          </Button>
        )}
      </Box>
      {!isNormalButton && (
        <Collapse in={isOpen}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              marginLeft: "2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { lg: "row", xs: "row" },
              }}
            >
              <Box>
                {routes.map(({ text, path }) => (
                  <ListItem
                    key={text}
                    disablePadding
                    onClick={() => handleNavigate(path)}
                  >
                    <ListItemButton sx={{ color: "#645e29" }}>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
                {nestedRoutes &&
                  nestedRoutes.map((nestedRoute) => (
                    <NavBarToggle
                      key={nestedRoute.text}
                      text={nestedRoute.text}
                      isOpen={nestedRoute.isOpen}
                      handleToggle={nestedRoute.handleToggle}
                      routes={nestedRoute.routes}
                      handleNavigate={handleNavigate}
                      sx={nestedRoute.sx}
                    />
                  ))}
              </Box>
            </Box>
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

export default NavBarToggle;
