import React, { useCallback, useEffect, useState } from "react";

import "./App.css";
import { Box } from "@mui/material";
import BarChart from "./components/BarCharts/BarChart";
import ChartLoading from "./components/Loading/ChartLoading";

let prefix = "";

if (process.env.NODE_ENV === "development") {
  prefix = "http://127.0.0.1:8000";
}
const token = localStorage.getItem("token");

const HolidayTable = ({
  restaurant,
  startDate,
  selectedPage,
  endDate,
  isXSScreen,
  isRecommended = false,
  // onFilter, currentDate, onPreviousDay, onNextDay
}) => {

  // const [holidays, setHolidays] = useState([]);
  const [factors, setFactors] = useState([]);
  const company = localStorage.getItem("company");

  const [isLoading, setIsLoading] = useState(false);

  // const isPreviousDay = new Date(currentDate) < new Date(startDate);
  // const isNextDay = new Date(currentDate) > new Date(endDate);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    let url = `${prefix}/api/holidayparameters/?company=${company}&start_date=${
      new Date(startDate).toISOString().split("T")[0]
    }&end_date=${new Date(endDate).toISOString().split("T")[0]}`;

    // Conditionally add the restaurant parameter if applicable
    if (selectedPage !== "company" && restaurant && restaurant.length > 0) {
      url += `&restaurant=${restaurant}`;
    }

    fetch(url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const filterOut = [
          // "sunshine_amount",
          "monthly",
          "weekly",
        ];
        const filteredData = data.results.filter(
          (result) => !filterOut.includes(result.name)
        );

        // Extracting required properties for the Nivo bar chart
        const chartData = filteredData.reduce((result, item) => {
          const date = item.date;
          const existingData = result.find((entry) => entry.date === date);

          if (existingData) {
            existingData[item.name] = item.effect;
          } else {
            const newData = {
              [item.name]: item.effect,
              date: item.date,
            };
            result.push(newData);
          }

          return result;
        }, []);

        // setHolidays(filteredData);
        setFactors(chartData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setFactors([]);
        setIsLoading(false);
      });
  }, [restaurant, startDate, endDate, selectedPage, company]);

  // useEffect(() => {
  //   if (restaurant && startDate && endDate) {
  //     fetchData();
  //   }
  // }, [restaurant, startDate, endDate, fetchData]);

  useEffect(() => {
    if (
      selectedPage === "multiple" &&
      (!restaurant || restaurant.length === 0)
    ) {
      setFactors([]);
    } else {
      fetchData();
    }
  }, [fetchData, restaurant, selectedPage]);
  /*log restaurant, start and end dat inthe console*/

  let bar_keys = [];

  factors.forEach((factors) => {
    const all_keys = Object.keys(factors);
    all_keys.forEach((key) => {
      if (key !== "date" && !bar_keys.includes(key)) {
        bar_keys.push(key);
      }
    });
  });

  let isClosed = selectedPage === "multiple" && bar_keys.includes("closed");

  if (isClosed) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <h2>Restaurant Closed</h2>
      </div>
    );
  }

  // const handleFilterClick = () => {
  //   fetchData();
  // };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        m: "2rem",
        height: "90%",
        // border: "solid red 1px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80%",
          margin: "auto",
          // outline: "solid red 1px",
        }}
      >
        {isLoading ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              // margin: "auto",
              // outline: "solid red 1px",
            }}
          >
            <ChartLoading />
          </Box>
        ) : (
          <BarChart
            data={factors}
            isDetailedChart={false}
            isXSScreen={isXSScreen}
            isRecommended={isRecommended}
          />
        )}
      </Box>
    </div>
  );
};

export default HolidayTable;
