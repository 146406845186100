import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import formatNumber from "../../utils/FormatNumber";
import { formatRegressorValue } from "../../utils/FormatRegressor";
// Helper function to transform your event data into a shape that's suitable for Nivo's stacked bar chart.
// const transformDataForStacking = (events, eventType) => {
//   const eventsByDate = {};
//   const eventDetailsByName = {}; // This will map event names to their additional details
//   events.forEach((event) => {
//     if (event.type === eventType) {
//       if (!eventsByDate[event.date]) {
//         eventsByDate[event.date] = { date: event.date };
//       }
//       eventsByDate[event.date][event.Name] = event.effect;
//       eventDetailsByName[event.Name] = {
//         event_category: event.event_category,
//         location: event.location,
//       }; // Store additional details here
//     }
//   });
//   return {
//     transformedData: Object.values(eventsByDate),
//     eventDetailsByName, // Return this mapping for later use
//   };
// };

const transformDataForStacking = (events, eventType) => {
  const eventsByDate = {};
  const eventDetailsByName = {};

  events.forEach((event) => {
    if (event.type === eventType) {
      const uniqueKey = `${event.Name}~${event.date}`;
      if (!eventsByDate[event.date]) {
        eventsByDate[event.date] = { date: event.date };
      }
      if (!eventsByDate[event.date][uniqueKey]) {
        eventsByDate[event.date][uniqueKey] = 0;
      }
      eventsByDate[event.date][uniqueKey] += event.effect;
      eventDetailsByName[uniqueKey] = {
        event_category: event.event_category,
        location: event.location,
      };
    }
  });

  return {
    transformedData: Object.values(eventsByDate),
    eventDetailsByName,
  };
};

const CustomTooltip = ({ id, value, color, data, eventDetailsByName }) => {
  // Format the date nicely
  let formattedDate;
  const eventName = id.split("~")[0];

  if (data.date) {
    const date = new Date(data.date);
    formattedDate = date.toLocaleDateString(undefined, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  const details = eventDetailsByName[id]; // Access the details using id
  return (
    <div
      style={{
        background: "white",
        padding: "10px",
        borderRadius: "5px",
        boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
      }}
    >
      <strong>{formattedDate}</strong> <br />
      <div style={{ maxWidth: "300px", overflowWrap: "break-word" }}>
        <strong
          style={{
            margin: 0,
            fontWeight: 700,
            color: color,
            whiteSpace: "normal",
            display: "block",
          }}
        >
          Name: {formatRegressorValue(eventName)}
        </strong>
      </div>
      <p style={{ margin: 0, fontWeight: 700, color: color }}>
        Effect: {formatNumber(value)}
      </p>
      {details && (
        <>
          <strong>
            Category: {details.event_category}
            <br />
          </strong>
          <strong>Location: {details.location}</strong>
        </>
      )}
    </div>
  );
};
const EventBarChart = ({ data, title, isXSScreen = false, range }) => {
  console.log(data, "event holiday");
  const { transformedData, eventDetailsByName } = transformDataForStacking(
    data,
    title
  );

  const getColor = (bar) => {
    // Extract the name from the unique key
    const eventName = bar.id.split("~")[0];

    // Find the original event by name to get the color
    const event = data.find((event) => event.Name === eventName);
    return event ? event.Color : "#C7317E";
  };

  const getBarTickValues = (data, range, isXSScreen) => {
    const allDates = data.map((d) => d.date);
    let tickValues = [];
    const numTicks = 5; // Define numTicks here
    if (range === "hour") {
      const totalDates = allDates.length;
      // Ensure there's at least one tick and avoid division by zero
      if (totalDates > numTicks) {
        const step = Math.floor(totalDates / (numTicks - 1));
        for (let i = 0; i < totalDates; i += step) {
          tickValues.push(allDates[i]);
        }
        // Ensure the last value is included
        if (tickValues[tickValues.length - 1] !== allDates[totalDates - 1]) {
          tickValues.push(allDates[totalDates - 1]);
        }
      } else {
        // If there are fewer dates than the number of desired ticks, show all dates
        tickValues = [...allDates];
      }
    } else if (isXSScreen) {
      // For small screens, get the start, middle, and end dates
      tickValues = [
        allDates[0],
        allDates[Math.floor(allDates.length / 2)],
        allDates[allDates.length - 1],
      ];
    } else {
      // For larger screens or other ranges, divide the dates into 6 segments and get them
      for (let i = 0; i < numTicks; i++) {
        const index = Math.floor((allDates.length - 1) * (i / (numTicks - 1)));
        tickValues.push(allDates[index]);
      }
    }
    return tickValues;
  };

  return transformedData.length > 0 ? (
    <ResponsiveBar
      data={transformedData}
      keys={Object.keys(
        transformedData.reduce((acc, val) => ({ ...acc, ...val }), {})
      ).filter((key) => key !== "date")}
      indexBy="date"
      groupMode="stacked"
      margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={getColor}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      enableLabel={false}
      borderRadius={5}
      enableGridY={true}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: 32,
        format: (value) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString(undefined, {
            month: "short",
            day: "numeric",
          });
          return formattedDate;
        },
        tickValues: getBarTickValues(transformedData, range, isXSScreen),
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltip={({ id, value, color, data }) => (
        <CustomTooltip
          id={id}
          value={value}
          color={color}
          data={data}
          eventDetailsByName={eventDetailsByName}
        />
      )}
    />
  ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <h2>No data</h2>
    </div>
  );
};
export default EventBarChart;
