import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  // IconButton,
  Typography,
  //   Button,
  //   TextField,
  //   FormControlLabel,
  //   Radio,
  //   RadioGroup,

  // IconButton,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BlogCard from "./BlogCards";

const theme = createTheme({
  typography: {
    fontFamily: "Lexend, sans-serif",
  },
});

// const blogData = [
//   {
//     title: "Connecting Bridges",
//     shortDescription: "This is a short description of the first blog.",
//     writerName: "Scarlett Johansson",
//     writerAvatarUrl: "url-to-avatar1.png",
//     headerImageUrl: "OverlapImage.jpg",
//   },
//   {
//     title: "Connecting Bridges",
//     shortDescription: "This is a short description of the first blog.",
//     writerName: "Scarlett Johansson",
//     writerAvatarUrl: "url-to-avatar1.png",
//     headerImageUrl: "OverlapImage.jpg",
//   },
//   {
//     title: "Connecting Bridges",
//     shortDescription: "This is a short description of the first blog.",
//     writerName: "Scarlett Johansson",
//     writerAvatarUrl: "url-to-avatar1.png",
//     headerImageUrl: "OverlapImage.jpg",
//   },
//   {
//     title: "Connecting Bridges",
//     shortDescription: "This is a short description of the first blog.",
//     writerName: "Scarlett Johansson",
//     writerAvatarUrl: "url-to-avatar1.png",
//     headerImageUrl: "OverlapImage.jpg",
//   },
//   {
//     title: "Connecting Bridges",
//     shortDescription: "This is a short description of the first blog.",
//     writerName: "Scarlett Johansson",
//     writerAvatarUrl: "url-to-avatar1.png",
//     headerImageUrl: "OverlapImage.jpg",
//   },
//   // ... other blog data objects
// ];
const Blogs = () => {
  // const [blogData, setBlogData] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const navigate = useNavigate();
  //   const handleNavigate = () => {
  //     navigate("/");
  //     window.scrollTo(0, 0);
  //   };

  const handleAboutUs = () => {
    navigate("/aboutus");
    window.scrollTo(0, 0);
  };

  const handleFAQ = () => {
    navigate("/faq");
    window.scrollTo(0, 0);
  };

  const handleNewContact = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  const handleResources = () => {
    navigate("/resources");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      const RSS_URL = `https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40kristian_5882`;

      try {
        const response = await fetch(RSS_URL);
        const data = await response.json();

        // console.log("Full response data:", data); // Log the entire response
        // console.log("Blog items:", data.items); // Log just the items

        setBlogs(data.items);
      } catch (error) {
        // console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignContent: "center",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            boxSizing: "border-box",
            alignItems: "center",
            // padding: "1rem", // add some padding around the grid
          }}
        >
          <Grid
            container
            // spacing={2} // add some spacing between grid items
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Grid
              item
              lg={12}
              xs={12}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "130vh" },
                // background: "linear-gradient(white, #CCDDFF)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: 1300,
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column" },
                }}
              >
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: { lg: "center", xs: "center" },
                    alignItems: { lg: "center", xs: "center" },
                    flexDirection: { xs: "column", lg: "row" },
                    minHeight: { lg: "100vh", xs: "60vh" },
                    mt: { lg: "2rem", xs: 0 },
                  }}
                >
                  {/* <Box
                    sx={{
                      display: { lg: "flex", xs: "flex" },
                      flexDirection: "column",
                      position: "absolute",
                      top: "2%",
                      left: "10%",
                    }}
                  >
                    <img
                      onClick={handleNavigate}
                      src="avintofinal.png"
                      alt="logo"
                      style={{
                        maxHeight: "80px",
                        maxWidth: "120px",
                        marginLeft: "5%",
                        cursor: "pointer", // Makes it look clickable
                      }}
                    />
                  </Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: { lg: "80%", xs: "90%" },
                      width: "100%",
                      gap: { lg: 2, xs: 0 },
                      padding: "1rem",
                      borderRadius: "1rem",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="h1"
                        sx={{
                          fontSize: "30px",
                          mt: { lg: 0, xs: "10%" },
                        }}
                      >
                        Resources
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        justifyContent: "space-around",
                        height: { lg: "90%", xs: "50%" },
                        mt: { lg: 0, xs: "10vh" },
                        alignItems: "center",
                        // border: "solid 1px red",
                      }}
                    >
                      <Grid container spacing={2}>
                        {blogs.map((blog, index) => {
                          // console.log(blog);
                          return (
                            <Grid item xs={12} lg={3} key={index}>
                              <BlogCard blog={blog} />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                // border: "solid 1px blue",
                justifyContent: "center",
                minHeight: "30vh",
                background: "#FFBD00",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: { xs: 1300, xl: 1600 },
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column" },
                }}
              >
                <Grid
                  item
                  lg={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: { lg: "12vh", xs: "0%" },
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      maxHeight: { xs: "100px", xl: "200px" },
                      maxWidth: { xs: "120px", xl: "240px" },
                      paddingTop: "0.5rem",
                    }}
                  >
                    <img
                      src="transparent_logo.png"
                      alt="logo"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: { lg: "12vh", xs: "0%" },
                    textAlign: "left",
                    ml: { lg: 0, xs: "5%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#191D23",
                      cursor: "pointer", // Makes it look clickable
                      "&:hover": {
                        color: "#808080", // Optional: Change color on hover
                      },
                      fontSize: { xs: "16px", xl: "20px" },
                    }}
                    onClick={handleNewContact}
                  >
                    Contact us
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#191D23",
                      cursor: "pointer", // Makes it look clickable
                      "&:hover": {
                        color: "#808080", // Optional: Change color on hover
                      },
                      fontSize: { xs: "16px", xl: "20px" },
                    }}
                    onClick={handleNewContact}
                  >
                    Reserve a meeting
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: { lg: "12vh", xs: "0%" },
                    textAlign: "left",
                    ml: { lg: 0, xs: "5%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#191D23",
                      cursor: "pointer", // Makes it look clickable
                      "&:hover": {
                        color: "#808080", // Optional: Change color on hover
                      },
                      fontSize: { xs: "16px", xl: "20px" },
                    }}
                    onClick={handleAboutUs}
                  >
                    About us
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#191D23",
                      cursor: "pointer", // Makes it look clickable
                      "&:hover": {
                        color: "#808080", // Optional: Change color on hover
                      },
                      fontSize: { xs: "16px", xl: "20px" },
                    }}
                    onClick={handleFAQ}
                  >
                    FAQs
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: { lg: "12vh", xs: "0%" },
                    textAlign: "left",
                    ml: { lg: 0, xs: "5%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#191D23",
                      cursor: "pointer", // Makes it look clickable
                      "&:hover": {
                        color: "#808080", // Optional: Change color on hover
                      },
                      fontSize: { xs: "16px", xl: "20px" },
                    }}
                    onClick={handleNewContact}
                  >
                    Support
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#191D23",
                      cursor: "pointer", // Makes it look clickable
                      "&:hover": {
                        color: "#808080", // Optional: Change color on hover
                      },
                      fontSize: { xs: "16px", xl: "20px" },
                    }}
                    onClick={handleResources}
                  >
                    Resources
                  </Typography>
                  {/* <Typography
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    Terms & Conditions
                  </Typography> */}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Blogs;
