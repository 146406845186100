import React from "react";
import { Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const HelpModeButton = ({ isXSScreen, handleHelpMode, helpMode }) => {
  return (
    <>
      {isXSScreen ? (
        <Tooltip
          title="Get a tour of the page and how it works"
          arrow
          placement="bottom"
        >
          <InfoIcon
            onClick={handleHelpMode}
            sx={{
              color: helpMode ? "#FFBD00" : "initial",
              padding: "8px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                borderRadius: "50%",
              },
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip
          title={<Typography variant="body1">Click to Close</Typography>}
          open={helpMode}
          arrow
          placement="top"
        >
          <Tooltip
            title="Get a tour of the page and how it works"
            arrow
            placement="bottom"
          >
            <InfoIcon
              onClick={handleHelpMode}
              sx={{
                color: helpMode ? "#FFBD00" : "initial",
                padding: "8px",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  borderRadius: "50%",
                },
              }}
            />
          </Tooltip>
        </Tooltip>
      )}
    </>
  );
};

export default HelpModeButton;
