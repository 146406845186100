//import React, { useState } from "react";
import {
  Box,
  Grid,
  // IconButton,
  Avatar,
  Typography,
  //Rating,
  Button,
  //Switch,
  //styled,
  List,
  ListItem,
  ListItemIcon,
  // IconButton,
} from "@mui/material";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckIcon from "@mui/icons-material/Check";

import { useNavigate } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CookieConfirmation from "../components/CookieConfirmation";
//import CheckIcon from "@mui/icons-material/Check";
//import CloseIcon from "@mui/icons-material/Close";

//notes
//move button css to global
//white button and yellow button

/*
  const StyledCheckItem = ({ text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <CheckIcon
        sx={{
          //borderRadius: "100%",
          height: "1.5rem",
          width: "1.5rem",
          background: "#E8EDFB",
          padding: "2px",
          color: "blue",
        }}
      />
      <Typography
        variant="body2"
        sx={{
          textAlign: "left",
          fontSize: "12px",
          ml: ".5rem",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
const StyledCrossItem = ({ text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <CloseIcon
        sx={{
          //borderRadius: "100%",
          height: "1.5rem",
          width: "1.5rem",
          background: "#E8EDFB",
          padding: "2px",
          color: "#6E5555",
        }}
      />
      <Typography
        variant="body2"
        sx={{
          textAlign: "left",
          fontSize: "12px",
          ml: ".5rem",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};*/

const theme = createTheme({
  typography: {
    fontFamily: "Lexend, sans-serif",
  },
});

const Landingv2 = () => {
  //const [isAnnual, setIsAnnual] = useState(false);
  const navigate = useNavigate();

  /*const handlePlanChange = () => {
    setIsAnnual((prev) => !prev);
  };*/

  const handleLearnMore = () => {
    navigate("/features");
    window.scrollTo(0, 0);
  };

  const handleNewContact = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };
  const handleAboutUs = () => {
    navigate("/aboutus");
    window.scrollTo(0, 0);
  };
  const handleFAQ = () => {
    navigate("/FAQ");
    window.scrollTo(0, 0);
  };
  const handleResources = () => {
    navigate("/resources");
    window.scrollTo(0, 0);
  };

  /*const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      //borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      //borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "//border-box",
    },
  }));*/

  return (
    <ThemeProvider theme={theme}>
      <Box // Main Parent Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignContent: "center",
          // border: "1px solid red",
          //boxSizing: "//border-box",
          alignItems: "center",
        }}
      >
        <CookieConfirmation />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // border: "solid 1px green",
            width: "100%",
            //boxSizing: "//border-box",
            alignItems: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              ////border: "solid 1px purple",
              width: "100%",
              alignItems: "center",
            }}
          >
            {/* First Page starts */}
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "130vh" },

                //background: "#FFF4BF",
                background:
                  "linear-gradient(0deg, #FFF4BF 0%, rgba(242, 242, 242, 0.00) 100%)",
                borderBottomLeftRadius: "2rem",
                borderBottomRightRadius: "2rem",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // adding this line
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: { xs: 1300, xl: 1600 },
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column-reverse" },
                }}
              >
                <Grid
                  item
                  lg={6}
                  xs={12}
                  sx={{
                    // border: "solid 1px blue",
                    display: "flex",
                    justifyContent: {
                      lg: "flex-end",
                      xs: "center",
                      xl: "flex-start",
                    },
                    alignItems: "center",
                    flexDirection: { xs: "column", lg: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: "flex", lg: "none" },
                      height: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="heropage_man2.png"
                      alt="land"
                      style={{
                        height: { xs: "70%", lg: "70%" },
                        width: "70%",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      ////border: "solid 1px red",
                      height: "70%",
                      width: "80%",
                      gap: 2,
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: { lg: "30px", xs: "27px", xl: "50px" },
                        wordWrap: "break-word", // This makes it break
                        hyphens: "auto", // This adds hyphens if needed and supported
                      }}
                    >
                      Forstå ditt fremtidige restaurantsalg
                    </Typography>

                    <Typography
                      color={"#4A4C50"}
                      sx={{
                        textAlign: "left",
                        fontSize: { xs: "16px", xl: "27px" },
                      }}
                    >
                      Krunch lager treffsikre salgsprediksjoner skreddersydd for
                      dine restauranter. Dette lar deg redusere bemanningskost,
                      se trender før de skjer og få full kontroll på både
                      historisk og fremtidig lønnsomhet.
                    </Typography>
                    <List sx={{ padding: 0 }}>
                      <ListItem
                        sx={{
                          padding: "0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ListItemIcon
                          sx={{ minWidth: "auto", marginRight: "8px" }}
                        >
                          <CheckIcon sx={{ color: "#C38718" }} />
                        </ListItemIcon>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#4A4C50",
                            fontSize: { xs: "14px", xl: "20px" },
                          }}
                        >
                          Lavere lønnskost
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{
                          padding: "0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ListItemIcon
                          sx={{ minWidth: "auto", marginRight: "8px" }}
                        >
                          <CheckIcon sx={{ color: "#C38718" }} />
                        </ListItemIcon>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#4A4C50",
                            fontSize: { xs: "14px", xl: "20px" },
                          }}
                        >
                          Lavere varekost
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{
                          padding: "0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ListItemIcon
                          sx={{ minWidth: "auto", marginRight: "8px" }}
                        >
                          <CheckIcon sx={{ color: "#C38718" }} />
                        </ListItemIcon>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#4A4C50",
                            fontSize: { xs: "14px", xl: "20px" },
                          }}
                        >
                          Se fremtidig salg og lønnsomhet
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{
                          padding: "0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ListItemIcon
                          sx={{ minWidth: "auto", marginRight: "8px" }}
                        >
                          <CheckIcon sx={{ color: "#C38718" }} />
                        </ListItemIcon>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#4A4C50",
                            fontSize: { xs: "14px", xl: "20px" },
                          }}
                        >
                          Sammelign dine restauranter
                        </Typography>
                      </ListItem>
                    </List>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={handleNewContact}
                        sx={{
                          width: { xs: "13ch", xl: "18ch" },
                          background: "#FFBD00",
                          color: "black",
                          "&:hover": {
                            backgroundColor: "#e3a902", // You can adjust this color based on your preference
                            // Prevent MUI Button from changing the color on hover
                            boxShadow: "none",
                          },
                          "&:active": {
                            backgroundColor: "#e3a902", // Adjust this color based on your preference
                          },
                          fontWeight: 700,
                          // borderRadius: "5px",
                          fontSize: { xs: "14px", xl: "18px" },
                        }}
                      >
                        Start nå
                      </Button>
                      {/* <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 400,
                          paddingLeft: "5px",
                        }}
                      >
                        Kom i gang nå
                      </Typography> */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "50%",
                      }}
                    >
                      <img
                        src="losTacosLogo.png"
                        alt="los tacos"
                        style={{
                          height: "93px",
                          width: "89px",
                        }}
                      />
                      {/*<img
                        src="https://restostagingstorage3.blob.core.windows.net/static/static/yipsLogo.png"
                        alt="yips Logo"
                        style={{
                          height: "93px",
                          width: "89px",
                        }}
                      />*/}
                    </Box>
                    {/* <Typography
                      variant="caption"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                      }}
                    >
                      Den mest pålitelige Resturant AI-appen i Norge
                    </Typography> */}
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  sx={{
                    display: { lg: "flex", xs: "none" },
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "solid 1px blue",
                  }}
                >
                  <img
                    src="heropage_man2.png"
                    alt="land"
                    style={{
                      height: "auto",
                      width: "80%",
                    }}
                  />
                </Grid>
              </Box>
            </Grid>

            {/* Pain point page starts here */}
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                // border: "solid 1px blue",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "130vh" },
                background:
                  "linear-gradient(0deg, #F2F2F2 0%, rgba(242, 242, 242, 0.00) 100%)",
                // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: { xs: 1300, xl: 1600 },
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: { lg: "70%", xs: "90%", xl: "80%" },
                    height: "20%",
                    mt: { lg: "1rem", xs: "1rem" },
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 700,
                      fontSize: { lg: "30px", xs: "27px", xl: "50px" },
                    }}
                  >
                    God lønnsomhet i restaurantbransjen krever solid innsikt i
                    fremtidig salg og aktivitet
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "80%",
                    gap: { xs: "0", lg: "0", xl: "10rem" },
                  }}
                >
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sx={{
                      //border: "solid 1px blue",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: { lg: "flex-end", xs: "center" },
                      justifyContent: "center",
                      mb: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "90%",
                        width: { xs: "70%", xl: "90%" },
                        borderLeft: "solid 2px #FFE000",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          gap: { xs: 1, xl: 5 },
                        }}
                      >
                        <Box
                          sx={{
                            borderLeft: "solid 6px #FFE000",
                            height: "22%",
                            mt: "5%",
                          }}
                        >
                          <Typography
                            sx={{
                              paddingLeft: "10px",
                              fontWeight: 700,
                              textAlign: "left",
                              fontSize: { xs: "16px", xl: "25px" },
                            }}
                          >
                            Uforutsigbart salg er et stort problem for god
                            lønnsomhet
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            color={"#4A4C50"}
                            sx={{
                              textAlign: "left",
                              paddingLeft: "16px",
                              fontSize: { xs: "14px", xl: "18px" },
                            }}
                          >
                            Det kan være utfordrende å planlegge bemanning,
                            vareinnkjøp og markedsføring riktig. <br />
                            Selv om man kjenner salgsmønstrene er det ofte store
                            variasjoner fra dag til dag. <br />
                            Dette gjør at man planlegger med for mange eller for
                            få ansatte på jobb, blir utsolgt for populære retter
                            eller sitter igjen med for mye av enkelte varer på
                            lager.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: { xs: "70%", xl: "90%" },
                        borderLeft: "solid 2px #FFE000",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          gap: { xs: 1, xl: 4 },
                        }}
                      >
                        <Box
                          sx={{
                            borderLeft: "solid 6px #FFE000",
                            height: "15%",
                            mt: "5%",
                          }}
                        >
                          <Typography
                            sx={{
                              paddingLeft: "10px",
                              fontWeight: 700,
                              textAlign: "left",
                              fontSize: { xs: "16px", xl: "25px" },
                            }}
                          >
                            Med Krunch kan du ta tilbake kontrollen
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            color={"#4A4C50"}
                            sx={{
                              textAlign: "left",
                              paddingLeft: "16px",
                              fontSize: { xs: "14px", lg: "14px", xl: "20px" },
                            }}
                          >
                            Se for deg at du kan forutse hva ditt salg kommer
                            til å bli de neste ukene og når på dagen det blir
                            mest hektisk eller rolig. <br />
                            Med Krunch kan du endelig gjøre dette. Du kan enkelt
                            planlegge rett bemanning og vareinnkjøp, vite når
                            det er tid til ukesvask og når du trenger alle mann
                            på dekk.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: { xs: "70%", xl: "90%" },
                        borderLeft: "solid 2px #FFE000",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          gap: { xs: 1, xl: 3 },
                        }}
                      >
                        <Box
                          sx={{
                            borderLeft: "solid 6px #FFE000",
                            height: "15%",
                            mt: { xs: "5%", xl: "2%" },
                          }}
                        >
                          <Typography
                            sx={{
                              paddingLeft: "10px",
                              fontWeight: 700,
                              textAlign: "left",
                              fontSize: { xs: "16px", xl: "25px" },
                            }}
                          >
                            Forstå dine drivere for lønnsomhet
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            color={"#4A4C50"}
                            sx={{
                              textAlign: "left",
                              paddingLeft: "16px",
                              fontSize: { xs: "14px", lg: "14px", xl: "20px" },
                            }}
                          >
                            I tillegg til bedre planlegging har Krunch utviklet
                            skreddersydde analyseverktøy for norske
                            restauranter. <br />
                            Nå kan du se hvilke produkter som driver din
                            lønnsomhet, hvilke retter som trender positivt eller
                            negativt og få innsikt i hvordan din lønnsomhet
                            kommer til å bli de neste ukene
                          </Typography>
                          <Typography
                            variant="h5"
                            sx={{
                              textAlign: "left",
                              paddingLeft: "20px",
                              fontSize: { xs: "16px", xl: "20px" },
                              fontWeight: 700,
                              mt: { xs: "1rem", xl: "2rem" },
                            }}
                          >
                            Gå inn i en ny tidsalder for restaurantdrift med
                            Krunch
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    xs={6}
                    sx={{
                      // border: "solid 1px blue",
                      display: { lg: "flex", xs: "none" },
                      justifyContent: "center", // Centers content horizontally
                      alignItems: "center", // Positions content at the bottom
                    }}
                  >
                    <img
                      src="Landing02.jpg"
                      alt="second banner"
                      style={{
                        height: "auto",
                        width: "95%",
                        borderRadius: "10px",
                      }}
                    />
                  </Grid>
                </Box>
              </Box>
            </Grid>
            {/* Pain point page ends here */}

            {/* Fourth section ends here */}
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                ////border: "solid 1px blue",
                justifyContent: "center",
                minHeight: { lg: "120vh", xs: "240vh" },
                background:
                  "linear-gradient(0deg, #F2F2F2 0%, rgba(242, 242, 242, 0.00) 100%)",
                // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: { xs: 1300, xl: 1600 },
                  width: "100%",
                  flexDirection: "column",
                  gap: "2rem",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                    fontSize: { lg: "30px", xs: "27px", xl: "50px" },
                  }}
                >
                  Med Krunch kan du...
                </Typography>
                {/* <Typography variant="body2" fontWeight={700} color={"#4A4C50"}>
                  Hvordan vi kan hjelpe deg med å oppnå resultater
                </Typography> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    sx={{
                      // border: "solid 1px blue",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: { lg: "60%", xs: "90%", xl: "90%" },
                        flexDirection: {
                          lg: "column",
                          xs: "column",
                        },
                        height: { lg: "70%", xs: "90%" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { lg: "row", xs: "column" },
                          width: "100%",
                          height: "70%",
                          // border: "solid 1px red",
                          gap: { xs: 1, xl: 5 },
                        }}
                      >
                        <Grid
                          item
                          lg={4}
                          xs={12}
                          sx={{
                            display: "flex",
                            // border: "solid 1px green",
                            flexDirection: "column",
                            gap: 1,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              height: { xs: "45px", xl: "70px" },
                              width: { xs: "45px", xl: "70px" },
                            }}
                          >
                            <img
                              src="people.png"
                              alt="people"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </Box>
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: 600,
                              fontSize: { xs: "16px", xl: "25px" },
                            }}
                          >
                            Ha riktig bemanning hver dag
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              textAlign: "left",
                              mt: { lg: "2rem", xs: "0.5rem" },
                              fontSize: { xs: "14px", xl: "20px" },
                            }}
                          >
                            Ved å predikere hvor mye du kommer til å selge frem
                            i tid kan vi lage anbefalinger om hvor mange ansatte
                            du trenger på jobb
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          xs={12}
                          sx={{
                            display: "flex",
                            // border: "solid 1px green",
                            flexDirection: "column",
                            gap: 1,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              height: { xs: "45px", xl: "70px" },
                              width: { xs: "45px", xl: "70px" },
                            }}
                          >
                            <img
                              src="cutlery.png"
                              alt="cutlery"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </Box>
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: 600,
                              fontSize: { xs: "16px", xl: "25px" },
                              textAlign: "left",
                            }}
                          >
                            Ha full kontroll på varelageret
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              textAlign: "left",
                              mt: { lg: "2rem", xs: "0.5rem" },
                              fontSize: { xs: "14px", xl: "20px" },
                            }}
                          >
                            Vi predikerer din produktmiks frem i tid. Slik kan
                            du få en forståelse for hvor mye du kommer til å
                            selge av dine produkter og dermed ha rett varelager,
                            hver dag
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          xs={12}
                          sx={{
                            display: "flex",
                            // border: "solid 1px green",
                            flexDirection: "column",
                            gap: 1,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              height: { xs: "45px", xl: "70px" },
                              width: { xs: "45px", xl: "70px" },
                            }}
                          >
                            <img
                              src="downarrow.png"
                              alt="downarrow"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </Box>
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: 600,
                              fontSize: { xs: "16px", xl: "25px" },
                              textAlign: "left",
                            }}
                          >
                            Se trender før de blir et problem
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              textAlign: "left",
                              mt: { lg: "0.5rem", xs: "0.5rem", xl: "2rem" },
                              fontSize: { xs: "14px", xl: "20px" },
                            }}
                          >
                            Populariteten til dine retter varierer over tid.
                            Forstå hvordan hver rett på menyen utvikler seg og
                            gjør tilpasninger tidlig for å øke salget og
                            redusere tap
                          </Typography>
                        </Grid>
                      </Box>
                      <Box
                        sx={{
                          // border:"1px solid red",
                          display: "flex",
                          flexDirection: { lg: "row", xs: "column" },
                          width: "100%",
                          height: "50%",
                          mt: { lg: "1rem", xs: "5%" },
                          gap: { xs: 1, xl: 5 },
                        }}
                      >
                        <Grid
                          item
                          lg={4}
                          xs={12}
                          sx={{
                            display: "flex",
                            // border: "solid 1px green",
                            flexDirection: "column",
                            gap: 1,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              height: { xs: "45px", xl: "70px" },
                              width: { xs: "45px", xl: "70px" },
                            }}
                          >
                            <img
                              src="socialmedia.png"
                              alt="socialmedia"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </Box>
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: 600,
                              fontSize: { xs: "16px", xl: "25px" },
                              textAlign: "left",
                            }}
                          >
                            Forstå effekten av markedsføring
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              textAlign: "left",
                              mt: { lg: "0.5rem", xs: "0.5rem", xl: "1.7rem" },
                              fontSize: { xs: "14px", xl: "20px" },
                            }}
                          >
                            Forstå hvordan markedsføring, menyendringer og andre
                            tiltak påvirker ditt salg og lønnsomhet. Slik kan du
                            se hva som fungerer og hva som burde endres
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          xs={12}
                          sx={{
                            display: "flex",
                            // border: "solid 1px green",
                            flexDirection: "column",
                            gap: 1,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              height: { xs: "45px", xl: "70px" },
                              width: { xs: "45px", xl: "70px" },
                            }}
                          >
                            <img
                              src="compare.png"
                              alt="compare"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </Box>
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: 600,
                              fontSize: { xs: "16px", xl: "25px" },
                              textAlign: "left",
                            }}
                          >
                            Sammenligne restauranter
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              textAlign: "left",
                              mt: { lg: "1.8rem", xs: "0.5rem" },
                              fontSize: { xs: "14px", xl: "20px" },
                            }}
                          >
                            Sammenlign dine restauranter basert på KPIer som
                            salg, lønnsprosent, ansattproduktivitet produktsalg
                            og -lønnsomhet og mer
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          xs={12}
                          sx={{
                            display: "flex",
                            //border: "solid 1px green",
                            flexDirection: "column",
                            gap: 1,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              height: { xs: "45px", xl: "70px" },
                              width: { xs: "45px", xl: "70px" },
                            }}
                          >
                            <img
                              src="integration2.png"
                              alt="integration"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </Box>
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: 600,
                              fontSize: { xs: "16px", xl: "25px" },
                              textAlign: "left",
                            }}
                          >
                            Integrere dine systemer
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              textAlign: "left",
                              mt: { lg: "1.8rem", xs: "0.5rem" },
                              fontSize: { xs: "14px", xl: "20px" },
                            }}
                          >
                            Vi setter opp integrasjoner med dine IT-systemer
                            slik at du får en sømløs opplevelse og slipper å
                            bruke tid på å flytte data manuelt
                          </Typography>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            {/* How we do it page starts here */}
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                //border: "solid 1px blue",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "130vh" },
                background:
                  "linear-gradient(0deg, #F2F2F2 0%, rgba(242, 242, 242, 0.00) 100%)",
                // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                paddingTop: "4rem",
                //paddingBottom: "4rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: { xs: 1300, xl: 1600 },
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: { lg: "70%", xs: "90%" },
                    height: "20%",
                    mt: { lg: "1rem", xs: "1rem" },
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 700,
                      fontSize: { lg: "30px", xs: "27px", xl: "50px" },
                    }}
                  >
                    Hvordan kan vi lage så treffsikre prediksjoner?
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "80%",
                  }}
                >
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sx={{
                      // border: "solid 1px blue",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: {
                        lg: "flex-end",
                        xs: "center",
                        xl: "center",
                      },
                      justifyContent: "center",
                      mb: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "90%",
                        width: "70%",
                        //borderLeft: "solid 2px #FFE000",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            // borderLeft: "solid 6px #FFE000",
                            height: "15%",
                            mt: "5%",
                          }}
                        >
                          <Typography
                            sx={{
                              paddingLeft: "10px",
                              fontWeight: 700,
                              textAlign: "left",
                              fontSize: { xs: "16px", xl: "25px" },
                            }}
                          >
                            Samle data
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            color={"#4A4C50"}
                            sx={{
                              textAlign: "left",
                              paddingLeft: "20px",
                              fontSize: { xs: "16px", xl: "20px" },
                            }}
                          >
                            Vi henter innsikt fra blant annet salgshistorikk,
                            vær, arrangementer, økonomiske indikatorer,
                            forventet produktmix og take-away. Målet er å finne
                            all relevant informasjon som påvirker ditt salg
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "70%",
                        //borderLeft: "solid 2px #FFE000",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            //borderLeft: "solid 6px #FFE000",
                            height: "15%",
                            mt: "5%",
                          }}
                        >
                          <Typography
                            sx={{
                              paddingLeft: "10px",
                              fontWeight: 700,
                              textAlign: "left",
                              fontSize: { xs: "16px", xl: "25px" },
                            }}
                          >
                            Gjennomføre vurderinger
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            color={"#4A4C50"}
                            sx={{
                              textAlign: "left",
                              paddingLeft: "20px",
                              fontSize: { xs: "16px", xl: "20px" },
                            }}
                          >
                            Så trener vi opp en kunstig intelligens modell
                            tilpasset din restaurant. Vi kombinerer dette med
                            restaurantledernes egen erfaring, slik at de selv
                            kan justere prediksjonene og legge til sine egne
                            vurderinger. Vår erfaring er at denne kombinasjonen
                            gir det beste resultatet.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "70%",
                        ////borderLeft: "solid 2px #FFE000",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            ////borderLeft: "solid 6px #FFE000",
                            height: "15%",
                            mt: "5%",
                          }}
                        >
                          <Typography
                            sx={{
                              paddingLeft: "10px",
                              fontWeight: 700,
                              textAlign: "left",
                              fontSize: { xs: "16px", xl: "25px" },
                            }}
                          >
                            Få resultater
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            color={"#4A4C50"}
                            sx={{
                              textAlign: "left",
                              paddingLeft: "20px",
                              fontSize: { xs: "16px", xl: "20px" },
                            }}
                          >
                            Basert på dette kommer vi med anbefalinger om
                            bemanning som vil redusere din lønnskostnad og vi
                            fremhever de viktigste driverne for økt lønnsomhet.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    xs={6}
                    sx={{
                      // border: "solid 1px blue",
                      display: { lg: "flex", xs: "none" },
                      justifyContent: "center", // Centers content horizontally
                      alignItems: "flex-end", // Positions content at the bottom
                    }}
                  >
                    <img
                      src="mobile_krunch.png"
                      alt="mobile krunch"
                      style={{
                        height: "auto",
                        width: "50%",
                      }}
                    />
                  </Grid>
                </Box>
              </Box>
            </Grid>
            {/* How we do it page ends here */}

            {/* Fourth page starts here */}
            {/* <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                //border: "solid 1px blue",
                justifyContent: "center",
                minHeight: "100vh",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: 1300,
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    height: "20%",
                    width: { lg: "100%", xs: "90%" },
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    Løsningen består av tre grunnpilarer
                  </Typography>
                  {/* <Typography
                    variant="body2"
                    fontWeight={700}
                    color={"#4A4C50"}
                  >
                    Bli med over 100+ restauranter for å bruke AI for å øke
                    virksomheten din
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column" },
                    width: "100%",
                    height: { lg: "70%", xs: "100%" },
                  }}
                >
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    sx={{
                      //border: "solid 1px blue",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img src="L02.svg" alt="sales prediction" />
                    <Typography variant="h5" fontWeight={700}>
                      Salgsprediksjoner
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    sx={{
                      //border: "solid 1px blue",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img src="L05.svg" alt="data driven" />
                    <Typography
                      variant="h5"
                      fontWeight={700}
                      sx={{
                        mt: { lg: "-2rem", xs: 0 },
                      }}
                    >
                      Økt lønnsomhet gjennom datadrevet innsikt
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    sx={{
                      //border: "solid 1px blue",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img src="L03.svg" alt="Integration" />
                    <Typography variant="h5" fontWeight={700}>
                      Integrasjon og databehandling
                    </Typography>
                  </Grid>
                </Box>
              </Box>
            </Grid>*/}
            {/* Third page ends here */}
            {/*<Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                //border: "solid 1px blue",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "70vh" },
                background:
                  "linear-gradient(to bottom, #FEEA43 0%, #FEEA43 50%, white 50%, white 100%)", // adding this line
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: 1300,
                  width: "100%",
                  flexDirection: "column",
                  mx: { lg: 0, xs: "1rem" },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                    mt: "1rem",
                  }}
                >
                  Utforsk mulighetene med Krunch!
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    sx={{
                      //border: "solid 1px blue",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        height: { lg: "500px", xs: "200px" },
                        width: { lg: "800px", xs: "300px" },
                        mt: { lg: 0, xs: "-50%" },
                      }}
                    >
                      <img
                        src="https://restostagingstorage3.blob.core.windows.net/static/static/Landing02.jpg"
                        alt="landing 2"
                        style={{
                          height: "100%",
                          width: "100%",
                          //borderRadius: "2rem",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                        }}
                      />
                    </Box>
                  </Grid>
                </Box>
              </Box>
                      </Grid>*/}

            {/* Fifth section ends here */}

            {/* Features section starts here */}
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                // border: "solid 1px blue",
                justifyContent: "center",
                minHeight: { lg: "90vh", xs: "95vh" },
                background: {
                  lg: "linear-gradient(white 0%, white 20%, #FFE849  20%, #FFE849  80%, white 80%, white 100%)",
                  xs: "linear-gradient(to bottom, white 0%,white 30%, #FFE849 30%, #FFE849 100%)",
                  // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                },
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              <Box
                sx={{
                  // border:"1px solid green",
                  display: "flex",
                  maxWidth: { xs: 1300, xl: "100%" },
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column-reverse" },
                    // justifyContent:"flex-end",
                    width: "100%",
                    height: "100%",
                    // gap:"70rem"
                  }}
                >
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sx={{
                      // border: "solid 1px blue",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: {
                        lg: "center",
                        xs: "center",
                      },
                      alignItems: "center",
                      mt: { lg: 0, xs: "-20%" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        // border: "solid 1px green",
                        flexDirection: "column",
                        ml: { lg: "20%", xs: 0 },
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontWeight={700}
                        sx={{
                          textAlign: { lg: "left", xs: "center" },
                          mb: "1rem",
                          fontSize: { xs: "34px", xl: "50px" },
                        }}
                      >
                        Våre funksjoner
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: { lg: "left", xs: "center" },
                          mb: "1rem",
                          mx: { lg: 0, xs: "5%" },
                          fontSize: { xs: "14px", xl: "20px" },
                        }}
                      >
                        Les mer om hvilke funksjoner Krunch tilbyr, hvordan de
                        fungerer og forstå hvordan de kan sørge for økt
                        lønnsomhet for deg
                      </Typography>
                      <Button
                        onClick={handleLearnMore}
                        sx={{
                          width: { xs: "12ch", xl: "16ch" },
                          background: "#FFF",
                          color: "black",
                          "&:hover": {
                            backgroundColor: "#e0e0e0", // You can adjust this color based on your preference
                            // Prevent MUI Button from changing the color on hover
                            boxShadow: "none",
                          },
                          "&:active": {
                            backgroundColor: "#e0e0e0", // Adjust this color based on your preference
                          },
                          fontWeight: 700,
                          //borderRadius: "5px",
                          ml: { lg: 0, xs: "30%" },
                          fontSize: { xs: "14px", xl: "18px" },
                        }}
                      >
                        Lære mer
                      </Button>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sx={{
                      // border: "solid 1px blue",
                      display: { lg: "flex", xs: "flex" },
                      justifyContent: "center",
                      overflow: { lg: "hidden", xs: "none" },
                      alignItems: { xs: "none", lg: "center" },
                    }}
                  >
                    <Avatar
                      src="round_image.jpg"
                      alt="User Avatar"
                      sx={{
                        width: { xs: "300px", lg: "600px", xl: "900px" },
                        height: { xs: "300px", lg: "600px", xl: "900px" },
                        ml: { xs: 0, lg: "70%" },
                      }}
                    />
                  </Grid>
                </Box>
              </Box>
            </Grid>
            {/* Features section ends here */}

            {/* Price page starts here */}
            {/*<Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                //border: "solid 1px blue",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "160vh" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: 1300,
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Velg en plan som passer deg <br />
                  {/* <span
                    style={{
                      background:
                        "linear-gradient(266deg, #FDF300 0.33%, #D8771D 137.75%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text", // For Safari support
                      color: "transparent",
                      WebkitTextFillColor: "transparent", // For Safari support
                    }}
                  >
                    Fremtidige Virksomheter
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                  }}
                >
                  Mange systemer krever at du binder deg til en lang
                  abonnementsperiode. Vi ønsker å gjøre risikoen minst mulig for
                  deg. Prøv oss, og er du ikke fornøyd kan du si opp avtalen med
                  kun 30 dagers varsel, eller velg årlig betaling hvis du ønsker
                  en lavere pris
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    gap: 2,
                    justifyContent: "center",
                    my: "2rem",
                  }}
                >
                  <Typography variant="h7">Betal månedlig</Typography>
                  <AntSwitch checked={isAnnual} onChange={handlePlanChange} />
                  <Typography variant="h7">Betal årlig</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column" },
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sx={{
                      //border: "solid 1px blue",
                      display: { lg: "flex", xs: "flex" },
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        height: "90%",
                        width: "80%",
                        //borderRadius: "12px",
                        background: "#FFF5A3",
                        boxShadow: "0px 10px 25px 0px #CCD9FF;",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "95%",
                          height: { lg: "30%", xs: "40%" },
                          flexDirection: "column",
                          //border: "solid 1px red",
                          alignItems: "flex-start",
                          gap: 1,
                          mt: "2%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                          }}
                        >
                          Standard
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 400,
                            color: "black",
                            textOverflow: "ellipsis",
                            fontSize: "13px",
                            textAlign: "left",
                          }}
                        >
                          Passer best for enkeltrestauranter som først og fremst
                          ønsker å redusere bemanningskost gjennom treffsikre
                          salgsprediksjoner og ikke har behov for dypere innsikt
                          i lønnsomhet og trender
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: 500,
                            mt: "2%",
                          }}
                        >
                          NOK {isAnnual ? "1750" : "1900"}
                          <span
                            style={{
                              fontSize: "16px",
                              color: "black",
                              marginLeft: "8px",
                            }}
                          >
                            /Mnd
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          width: "95%",
                          height: "65%",
                          flexDirection: "column",
                          //border: "solid 1px red",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          sx={{
                            width: "90%",
                            background: "#FFF",
                            color: "black",
                            "&:hover": {
                              backgroundColor: "#e0e0e0", // You can adjust this color based on your preference
                              // Prevent MUI Button from changing the color on hover
                              boxShadow: "none",
                            },
                            "&:active": {
                              backgroundColor: "#e0e0e0", // Adjust this color based on your preference
                            },
                            fontWeight: 700,
                            //borderRadius: "5px",
                          }}
                        >
                          Start nå
                        </Button>
                        <Box
                          sx={{
                            display: "flex",
                            width: "90%",
                            height: "100%",
                            flexDirection: "column",
                            //border: "solid 1px red",
                            alignItems: "left",
                            padding: "1%",
                            gap: 1,
                          }}
                        >
                          <StyledCheckItem text="Salgsprediksjoner tilpasset de fleste norske byer" />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCrossItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCrossItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sx={{
                      //border: "solid 1px blue",
                      display: { lg: "flex", xs: "flex" },
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        height: "90%",
                        width: "80%",
                        background: "#FFF",
                        // boxShadow: "0px 10px 25px 0px #CCD9FF;",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "95%",
                          height: { lg: "30%", xs: "40%" },
                          flexDirection: "column",
                          //border: "solid 1px red",
                          alignItems: "flex-start",
                          gap: 1,
                          mt: "2%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                          }}
                        >
                          Profesjonell
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 400,
                            color: "#64748B",
                            textOverflow: "ellipsis",
                            fontSize: "13px",
                            textAlign: "left",
                          }}
                        >
                          Ideell for restaurantkjeder og ambisiøse
                          enkeltrestauranter som ønsker å øke sin lønnsomhet
                          gjennom dypere innsikt og spesialtilpassede
                          prediksjoner.
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: 500,
                            mt: "2%",
                          }}
                        >
                          NOK {isAnnual ? "2300" : "2450"}
                          <span
                            style={{
                              fontSize: "16px",
                              color: "gray",
                              marginLeft: "8px",
                            }}
                          >
                            /Month
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          width: "95%",
                          height: "65%",
                          flexDirection: "column",
                          //border: "solid 1px red",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          sx={{
                            width: "90%",
                            background: "#FFF",
                            color: "black",
                            "&:hover": {
                              backgroundColor: "#e0e0e0", // You can adjust this color based on your preference
                              // Prevent MUI Button from changing the color on hover
                              boxShadow: "none",
                            },
                            "&:active": {
                              backgroundColor: "#e0e0e0", // Adjust this color based on your preference
                            },
                            fontWeight: 700,
                            //borderRadius: "5px",
                            //border: "1.5px solid #645E29",
                          }}
                        >
                          Start nå
                        </Button>
                        <Box
                          sx={{
                            display: "flex",
                            width: "90%",
                            height: "100%",
                            flexDirection: "column",
                            //border: "solid 1px red",
                            alignItems: "left",
                            padding: "1%",
                            gap: 1,
                          }}
                        >
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sx={{
                      //border: "solid 1px blue",
                      display: { lg: "flex", xs: "flex" },
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        height: "90%",
                        width: "80%",
                        background: "#FFF",
                        // boxShadow: "0px 10px 25px 0px #CCD9FF;",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "95%",
                          height: { lg: "30%", xs: "40%" },
                          flexDirection: "column",
                          //border: "solid 1px red",
                          alignItems: "flex-start",
                          gap: 1,
                          mt: "2%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                          }}
                        >
                          Enterprise
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 400,
                            color: "#64748B",
                            textOverflow: "ellipsis",
                            fontSize: "13px",
                            textAlign: "left",
                          }}
                        >
                          Passer for større restaurantkjeder som ønsker
                          spesialtilpasning og skreddersydde
                          prediksjonsløsninger for å øke sin lønnshomhet
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: 500,
                            mt: "2%",
                          }}
                        >
                          Kontakt oss
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          width: "95%",
                          height: "65%",
                          flexDirection: "column",
                          //border: "solid 1px red",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          sx={{
                            width: "90%",
                            background: "#FFF",
                            color: "black",
                            "&:hover": {
                              backgroundColor: "#e0e0e0", // You can adjust this color based on your preference
                              // Prevent MUI Button from changing the color on hover
                              boxShadow: "none",
                            },
                            "&:active": {
                              backgroundColor: "#e0e0e0", // Adjust this color based on your preference
                            },
                            fontWeight: 700,
                            //borderRadius: "5px",
                            //border: "1.5px solid #645E29",
                          }}
                        >
                          Start nå
                        </Button>
                        <Box
                          sx={{
                            display: "flex",
                            width: "90%",
                            height: "100%",
                            flexDirection: "column",
                            //border: "solid 1px red",
                            alignItems: "left",
                            padding: "1%",
                            gap: 1,
                          }}
                        >
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                          <StyledCheckItem text="Lorem ipsum dolor sit amet consectetur. Risus sollicitudin." />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Grid>*/}
            {/* Price page ends here */}

            {/* how we can help you page starts here */}
            {/*<Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                //border: "solid 1px blue",
                justifyContent: "center",
                minHeight: "100vh",
                background: "#FFF4BF",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: 1300,
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    paddingTop: "1rem",
                  }}
                >
                  Hva våre kunder sier om oss
                </Typography>
                {/* <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    color: "#4A4C50",
                  }}
                >
                  Hvordan vi kan hjelpe deg med å oppnå resultater
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    sx={{
                      //border: "solid 1px blue",
                      display: "flex",
                      position: "relative",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <IconButton
                      sx={{
                        //borderRadius: "100%",
                        height: "3rem",
                        //border: "1px solid rgba(128, 128, 128, 0.30)",
                        width: "3rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#FFFFFF",
                        position: "absolute",
                        left: "5%",
                        top: "50%",
                      }}
                    >
                      <ArrowBackIosNewIcon />
                    </IconButton>
                    <Box
                      sx={{
                        display: "flex",
                        background: "#FFF",
                        height: "90%",
                        width: {
                          lg: "50%",
                          xs: "70%",
                        },
                        //borderRadius: "10px",
                        //border: "10px solid #FFF", // This simulates the top edge of the bottom box
                        boxShadow: `
                      0px 10px 21px 0px rgba(230, 57, 70, 0.15)
        `,
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          background: "#FFF",
                          height: { lg: "50%", xs: "70%" },
                          width: "100%",
                          //borderRadius: "10px",
                          //border: "10px solid #FFF", // This simulates the top edge of the bottom box
                          boxShadow: `
                      0px 10px 21px 0px rgba(230, 57, 70, 0.15)
        `,
                          position: "relative",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="quoteStart.svg"
                          alt="quotationstart"
                          style={{
                            height: "50px",
                            width: "50px",
                            position: "absolute",
                            top: "1rem",
                            left: "1rem",
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#607D8B",
                            width: "60%",
                            height: { lg: "50%", xs: "50%" },
                            mt: { lg: 0, xs: "-40%" },
                          }}
                        >
                          Tekst fra Brian Kovary her
                        </Typography>
                        <img
                          src="quoteEnd.svg"
                          alt="quotationend"
                          style={{
                            height: "50px",
                            width: "50px",
                            position: "absolute",
                            bottom: "1rem",
                            right: "1rem",
                          }}
                        />
                      </Box>
                      <Avatar
                        src="scarjo.jpeg"
                        alt="User Avatar"
                        sx={{
                          position: "absolute",
                          top: {
                            lg: "calc(60% - 40px)",
                            xs: "calc(77% - 40px)",
                          }, // Assuming the Avatar's height is 80px, adjust accordingly
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "80px",
                          height: "80px",
                          zIndex: 1, // Optional, to make sure Avatar appears on top of other elements
                        }}
                      />
                      <Rating
                        name="rating"
                        defaultValue={5} // You can set this value based on your requirement
                        precision={1}
                        readOnly
                        sx={{
                          position: "absolute",
                          top: { lg: "70%", xs: "77%" }, // Adjust this based on your design preferences
                          left: "50%",
                          transform: "translate(-50%)",
                          zIndex: 1,
                        }}
                      />

                      <Typography
                        variant="body2"
                        sx={{
                          position: "absolute",
                          top: { lg: "75%", xs: "82%" }, // Adjust this based on your design preferences
                          left: "50%",
                          transform: "translate(-50%)",
                          zIndex: 1,
                        }}
                      >
                        Brian Kovari
                      </Typography>

                      <Typography
                        variant="caption"
                        sx={{
                          position: "absolute",
                          top: { lg: "78%", xs: "85%" }, // Adjust this based on your design preferences
                          left: "50%",
                          transform: "translate(-50%)",
                          zIndex: 1,
                        }}
                      >
                        CEO & Founder, Los Tacos AS
                      </Typography>
                      <Box
                        sx={{
                          position: "absolute",
                          height: "4px",
                          width: "10%",
                          bottom: "1rem",
                          left: "20%",
                          background:
                            "linear-gradient(270deg, #B56576 0%, #6D597A 96.43%)",
                          //borderRadius: "5px",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          height: "4px",
                          width: "10%",
                          bottom: "1rem",
                          left: "30.5%",
                          background:
                            "linear-gradient(270deg, #B56576 0%, #6D597A 96.43%)",
                          //borderRadius: "5px",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          height: "4px",
                          width: "10%",
                          bottom: "1rem",
                          left: "41%",
                          background:
                            "linear-gradient(270deg, #B56576 0%, #6D597A 96.43%)",
                          //borderRadius: "5px",
                        }}
                      />
                    </Box>
                    {/* <IconButton
                      sx={{
                        //borderRadius: "100%",
                        height: "3rem",
                        //border: "1px solid rgba(128, 128, 128, 0.30)",
                        width: "3rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#FFFFFF",
                        position: "absolute",
                        right: "5%",
                        top: "50%",
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Grid>
                </Box>
              </Box>
            </Grid>*/}

            {/* Eigth section ends here */}
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: { lg: "flex", xs: "none" },
                width: "100%",
                // border: "solid 1px blue",
                justifyContent: "center",
                minHeight: { lg: "40vh", xs: "10vh" },
                background: { lg: "#FFF4BF", xs: "#FFF" },
                // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                // paddingTop: "4rem",
                // paddingBottom: "4rem",
              }}
            >
              <Box
                sx={{
                  display: { lg: "flex", xs: "none" },
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                  backgroundImage: `url(footBanner.png)`, // Wrapped inside url() function
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Button
                  onClick={handleNewContact}
                  sx={{
                    position: "absolute",
                    left: "3%",
                    top: "45%",
                    width: { xs: "13ch", xl: "16ch" },
                    background: "#FFBD00",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "#e3a902", // You can adjust this color based on your preference
                      // Prevent MUI Button from changing the color on hover
                      boxShadow: "none",
                    },
                    "&:active": {
                      backgroundColor: "#e3a902", // Adjust this color based on your preference
                    },
                    fontWeight: 700,
                    //borderRadius: "5px",
                    fontSize: { xs: "14px", xl: "18px" },
                  }}
                >
                  Start nå
                </Button>
              </Box>
            </Grid>

            {/* Ninth Section ends here */}

            {/*Bottom navbar starts here */}
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                // border: "solid 1px blue",
                justifyContent: "center",
                minHeight: "30vh",
                background: "#FFBD00",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: { xs: 1300, xl: 1600 },
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column" },
                }}
              >
                <Grid
                  item
                  lg={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: { lg: "12vh", xs: "4%" },
                    ml: { lg: "none", xs: "2%" },
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      maxHeight: { xs: "100px", xl: "200px" },
                      maxWidth: { xs: "120px", xl: "240px" },
                      paddingTop: "0.5rem",
                    }}
                  >
                    <img
                      src="transparent_logo.png"
                      alt="logo"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: { lg: "12vh", xs: "0%" },
                    textAlign: "left",
                    ml: { lg: 0, xs: "5%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#191D23",
                      cursor: "pointer", // Makes it look clickable
                      "&:hover": {
                        color: "#808080", // Optional: Change color on hover
                      },
                      fontSize: { xs: "16px", xl: "20px" },
                    }}
                    onClick={handleNewContact}
                  >
                    Contact us
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#191D23",
                      cursor: "pointer", // Makes it look clickable
                      "&:hover": {
                        color: "#808080", // Optional: Change color on hover
                      },
                      fontSize: { xs: "16px", xl: "20px" },
                    }}
                    onClick={handleNewContact}
                  >
                    Reserve a meeting
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: { lg: "12vh", xs: "0%" },
                    textAlign: "left",
                    ml: { lg: 0, xs: "5%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#191D23",
                      cursor: "pointer", // Makes it look clickable
                      "&:hover": {
                        color: "#808080", // Optional: Change color on hover
                      },
                      fontSize: { xs: "16px", xl: "20px" },
                    }}
                    onClick={handleAboutUs}
                  >
                    About us
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#191D23",
                      cursor: "pointer", // Makes it look clickable
                      "&:hover": {
                        color: "#808080", // Optional: Change color on hover
                      },
                      fontSize: { xs: "16px", xl: "20px" },
                    }}
                    onClick={handleFAQ}
                  >
                    FAQs
                  </Typography>
                  
                </Grid>
                <Grid
                  item
                  lg={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: { lg: "12vh", xs: "0%" },
                    textAlign: "left",
                    ml: { lg: 0, xs: "5%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#191D23",
                      cursor: "pointer", // Makes it look clickable
                      "&:hover": {
                        color: "#808080", // Optional: Change color on hover
                      },
                      fontSize: { xs: "16px", xl: "20px" },
                    }}
                    onClick={handleNewContact}
                  >
                    Support
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "#191D23",
                      cursor: "pointer", // Makes it look clickable
                      "&:hover": {
                        color: "#808080", // Optional: Change color on hover
                      },
                      fontSize: { xs: "16px", xl: "20px" },
                    }}
                    onClick={handleResources}
                  >
                    Resources
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight:700,
                      color: "#191D23",
                      textAlign: "left",
                      fontSize: { xs: "16px", xl: "20px" },

                      mt: "20px", // Adjust the margin-top as needed
                    }}
                  >
                    org nr: 931 734 156
                  </Typography>

                  {/* <Typography
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    Terms & Conditions
                  </Typography> */}
                </Grid>
              </Box>
            </Grid>
            {/* Footer ends Here */}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Landingv2;
