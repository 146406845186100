const getHighlightedStyle = () => {
    const highlightedStyle = {
      border: "1px solid #FFBD00",
      borderRadius: "10px",
      boxShadow: "0 0 8px 4px rgba(255, 189, 0, 1)",
    };
  
    return highlightedStyle;
  };
  
  export default getHighlightedStyle;
  