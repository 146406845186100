import React from "react";
import {
  Box,
  Grid,
  // IconButton,
  Typography,
  Button,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: "Lexend, sans-serif",
  },
});

const Features = () => {
  const navigate = useNavigate();

  /*const handleFeatureSecond = () => {
    navigate("/featuresecond");
  };
  const handleFeatureThird = () => {
    navigate("/featurethird");
  };*/
  const handlenewContact = () => {
    navigate("/contact");
    //window.scrollTo(0, 0);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box // Main Parent Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignContent: "center",
          //border: "1px solid red",
          boxSizing: "//border-box",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            //border: "solid 1px green",
            width: "100%",
            boxSizing: "//border-box",
            alignItems: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              //border: "solid 1px purple",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "70vh" },
                background: "#FFF4BF",
                paddingTop: "4rem",
                paddingBottom: "4rem",

                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // adding this line
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: 1300,
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column-reverse" },
                }}
              >
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sx={{
                    //border: "solid 1px blue",
                    display: "flex",
                    justifyContent: { lg: "center", xs: "center" },
                    alignItems: "center",
                    flexDirection: { xs: "column", lg: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: { lg: "center", xs: "flex-start" },
                      alignItems: "center",
                      //border: "solid 1px red",
                      height: "100%",
                      width: { lg: "70%", xs: "100%" },
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textAlign: "center",
                        fontSize: { lg: "40px", xs: "24px" },
                        wordWrap: "break-word", // This makes it break
                        hyphens: "auto", // This adds hyphens if needed and supported
                        mt: { lg: 0, xs: "2rem" },
                      }}
                    >
                      Salgsprediksjon og rett bemanning
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "14px",
                        width: "70%",
                      }}
                    >
                      Vår salgsprediksjon gir deg oversikt over hvor mye du
                      kommer til å selge hver dag og når på dagen de hektiske og
                      rolige periodene kommer. <br />
                      Basert på dette og dine historiske bemanningsbehov lager
                      vi anbefalinger for hvordan mange ansatte du trenger på
                      jobb til ulike tidspunkt
                    </Typography>
                    <Box
                      sx={{
                        height: { lg: "480px", xs: "180px" },
                        width: { lg: "770px", xs: "300px" },
                      }}
                    >
                      <img
                        src="https://restostagingstorage3.blob.core.windows.net/static/static/predictions.png"
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          //borderRadius: "30px",
                        }}
                      />
                    </Box>
                    <Button
                      onClick={handlenewContact}
                      sx={{
                        width: "13ch",
                        background: "#FFBD00",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#e3a902", // You can adjust this color based on your preference
                          // Prevent MUI Button from changing the color on hover
                          boxShadow: "none",
                        },
                        "&:active": {
                          backgroundColor: "#e3a902", // Adjust this color based on your preference
                        },
                        fontWeight: 700,
                        //borderRadius: "5px",
                      }}
                    >
                      Start nå
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            {/* First section ends here */}
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "70vh" },
                background: "#FFF",
                paddingTop: "4rem",
                paddingBottom: "4rem",

                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // adding this line
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: 1300,
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column-reverse" },
                }}
              >
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sx={{
                    //border: "solid 1px blue",
                    display: "flex",
                    justifyContent: { lg: "center", xs: "center" },
                    alignItems: "center",
                    flexDirection: { xs: "column", lg: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: { lg: "center", xs: "flex-start" },
                      alignItems: "center",
                      //border: "solid 1px red",
                      height: "100%",
                      width: { lg: "70%", xs: "100%" },
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textAlign: "center",
                        fontSize: { lg: "40px", xs: "24px" },
                        wordWrap: "break-word", // This makes it break
                        hyphens: "auto", // This adds hyphens if needed and supported
                        mt: { lg: 0, xs: "2rem" },
                      }}
                    >
                      Produktprediksjon som lar deg planlegge vareinnkjøp og se
                      forventet produktsalg
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "14px",
                        width: "70%",
                      }}
                    >
                      Ved å predikere hvor mye de ulike produktene på din meny
                      kommer til å selge blir det enklere for deg å planlegge
                      vareinnkjøp slik at du hverken går tom for varer eller
                      sitter igjen med for mye. <br />
                      Ved å forstå hvordan hver rett på menyen din selger kan du
                      også gjøre tilpasninger i markedsføring, priser og meny
                      for å øke salget og kutte i varekostnadene
                    </Typography>
                    <Box
                      sx={{
                        height: { lg: "480px", xs: "180px" },
                        width: { lg: "770px", xs: "300px" },
                      }}
                    >
                      <img
                        src="https://restostagingstorage3.blob.core.windows.net/static/static/product_overview.png"
                        alt=""
                        style={{
                          height: "100%",
                          width: "auto",
                          //borderRadius: "30px",
                        }}
                      />
                    </Box>
                    <Button
                      onClick={handlenewContact}
                      sx={{
                        width: "13ch",
                        background: "#FFBD00",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#e3a902", // You can adjust this color based on your preference
                          // Prevent MUI Button from changing the color on hover
                          boxShadow: "none",
                        },
                        "&:active": {
                          backgroundColor: "#e3a902", // Adjust this color based on your preference
                        },
                        fontWeight: 700,
                        //borderRadius: "5px",
                      }}
                    >
                      Start nå
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Grid>

            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "70vh" },
                background: "#FFF4BF",
                paddingTop: "4rem",
                paddingBottom: "4rem",

                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // adding this line
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: 1300,
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column-reverse" },
                }}
              >
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sx={{
                    //border: "solid 1px blue",
                    display: "flex",
                    justifyContent: { lg: "center", xs: "center" },
                    alignItems: "center",
                    flexDirection: { xs: "column", lg: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: { lg: "center", xs: "flex-start" },
                      alignItems: "center",
                      //border: "solid 1px red",
                      height: "100%",
                      width: { lg: "70%", xs: "100%" },
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textAlign: "center",
                        fontSize: { lg: "40px", xs: "24px" },
                        wordWrap: "break-word", // This makes it break
                        hyphens: "auto", // This adds hyphens if needed and supported
                        mt: { lg: 0, xs: "2rem" },
                      }}
                    >
                      Ha full kontroll på lønnsomheten, <br /> både bakover og
                      fremover i tid
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "14px",
                        width: "70%",
                      }}
                    >
                      De fleste moderne styringssystemer lar deg se hvordan
                      lønnshomheten ble for en gitt periode. Krunch tar steget
                      videre og lar deg se hvordan lønnnsomheten kommer til å
                      bli, gitt ditt forventede salg og kostnadsutvikling.{" "}
                      <br />
                      Dette gir deg forutsigbarhet og viktige signaler på om du
                      har planlagt driften på en god måte eller ikke
                    </Typography>
                    <Box
                      sx={{
                        height: { lg: "480px", xs: "180px" },
                        width: { lg: "770px", xs: "300px" },
                      }}
                    >
                      <img
                        src="https://restostagingstorage3.blob.core.windows.net/static/static/datadriven_features.svg"
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          //borderRadius: "30px",
                        }}
                      />
                    </Box>
                    <Button
                      onClick={handlenewContact}
                      sx={{
                        width: "13ch",
                        background: "#FFBD00",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#e3a902", // You can adjust this color based on your preference
                          // Prevent MUI Button from changing the color on hover
                          boxShadow: "none",
                        },
                        "&:active": {
                          backgroundColor: "#e3a902", // Adjust this color based on your preference
                        },
                        fontWeight: 700,
                        //borderRadius: "5px",
                      }}
                    >
                      Start nå
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Grid>

            {/* Second section ends here */}
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "70vh" },
                background: "#FFF",
                //borderBottomLeftRadius: "2rem",
                //borderBottomRightRadius: "2rem",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // adding this line
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: 1300,
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column-reverse" },
                }}
              >
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sx={{
                    //border: "solid 1px blue",
                    display: "flex",
                    justifyContent: { lg: "center", xs: "center" },
                    alignItems: "center",
                    flexDirection: { xs: "column", lg: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: { lg: "center", xs: "flex-start" },
                      alignItems: "center",
                      //border: "solid 1px red",
                      height: "100%",
                      width: { lg: "70%", xs: "100%" },
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textAlign: "center",
                        fontSize: { lg: "40px", xs: "24px" },
                        wordWrap: "break-word", // This makes it break
                        hyphens: "auto", // This adds hyphens if needed and supported
                        mt: { lg: 0, xs: "2rem" },
                      }}
                    >
                      Sammenlign dine restauranter
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "14px",
                        width: "70%",
                      }}
                    >
                      Har du flere restauranter? Da kan du enkelt sammenligne
                      dem på tvers av dine viktigste KPIer. Forstå driverne til
                      at noen restauranter gjør det bra mens andre gjør det
                      mindre bra. Vi måler produktivitet, bemanningskost,
                      hvordan ulike produkter selger mer eller mindre på ulike
                      lokasjoner og mye mer
                    </Typography>
                    <Box
                      sx={{
                        height: { lg: "480px", xs: "180px" },
                        width: { lg: "770px", xs: "300px" },
                      }}
                    >
                      <img
                        src="https://restostagingstorage3.blob.core.windows.net/static/static/compare_restaurants.png"
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          //borderRadius: "30px",
                        }}
                      />
                    </Box>
                    <Button
                      onClick={handlenewContact}
                      sx={{
                        width: "13ch",
                        background: "#FFBD00",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#e3a902", // You can adjust this color based on your preference
                          // Prevent MUI Button from changing the color on hover
                          boxShadow: "none",
                        },
                        "&:active": {
                          backgroundColor: "#e3a902", // Adjust this color based on your preference
                        },
                        fontWeight: 700,
                        //borderRadius: "5px",
                      }}
                    >
                      Start nå
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Grid>

            {/* Second section ends here */}
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "70vh" },
                background: "#FFF4BF",
                paddingTop: "4rem",
                paddingBottom: "4rem",
                //borderBottomLeftRadius: "2rem",
                //borderBottomRightRadius: "2rem",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // adding this line
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: 1300,
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column-reverse" },
                }}
              >
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sx={{
                    //border: "solid 1px blue",
                    display: "flex",
                    justifyContent: { lg: "center", xs: "center" },
                    alignItems: "center",
                    flexDirection: { xs: "column", lg: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: { lg: "center", xs: "flex-start" },
                      alignItems: "center",
                      //border: "solid 1px red",
                      height: "100%",
                      width: { lg: "70%", xs: "100%" },
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textAlign: "center",
                        fontSize: { lg: "40px", xs: "24px" },
                        wordWrap: "break-word", // This makes it break
                        hyphens: "auto", // This adds hyphens if needed and supported
                        mt: { lg: 0, xs: "2rem" },
                      }}
                    >
                      Integrasjon og databehandling
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "14px",
                        width: "70%",
                      }}
                    >
                      God dataflyt mellom IT-systemer er svært viktig for en
                      moderne restaurant. Mange bruker mye tid på å manuelt
                      behandle og flytte data og går glipp av datadrevet innsikt
                      som følge av dette. <br />
                      Vi setter opp integrasjoner mot de fleste systemer og kan
                      håndtere de oppgavene som fortsatt krever manuelt arbeid
                      for deg om du ønsker
                    </Typography>
                    <Box
                      sx={{
                        height: { lg: "480px", xs: "180px" },
                        width: { lg: "770px", xs: "300px" },
                      }}
                    >
                      <img
                        src="https://restostagingstorage3.blob.core.windows.net/static/static/integration_3.png"
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          //borderRadius: "30px",
                        }}
                      />
                    </Box>
                    <Button
                      onClick={handlenewContact}
                      sx={{
                        width: "13ch",
                        background: "#FFBD00",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#e3a902", // You can adjust this color based on your preference
                          // Prevent MUI Button from changing the color on hover
                          boxShadow: "none",
                        },
                        "&:active": {
                          backgroundColor: "#e3a902", // Adjust this color based on your preference
                        },
                        fontWeight: 700,
                        //borderRadius: "5px",
                      }}
                    >
                      Start nå
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            {/* Third section ends here */}
            {/* <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "130vh" },
                background: "#FFF4BF",
                //borderBottomLeftRadius: "2rem",
                //borderBottomRightRadius: "2rem",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // adding this line
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: 1300,
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column-reverse" },
                }}
              >
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sx={{
                    //border: "solid 1px blue",
                    display: "flex",
                    justifyContent: { lg: "center", xs: "center" },
                    alignItems: "center",
                    flexDirection: { xs: "column", lg: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      //border: "solid 1px red",
                      height: "100%",
                      width: "70%",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: "40px",
                        wordWrap: "break-word", // This makes it break
                        hyphens: "auto", // This adds hyphens if needed and supported
                      }}
                    >
                      Salgsprediksjoner
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "14px",
                        width: "70%",
                      }}
                    >
                      Lag faktura, tilbud og ordrebekreftelse, og la Fiken fikse
                      regnskap og mva. Velg om det skal sendes på e-post, Vipps
                      eFaktura, EHF, sms eller papir. Følg opp utestående
                      betalinger når det passer, eller la Fiken gjøre det for
                      deg. Gratis inkasso.
                    </Typography>
                    <Box
                      sx={{
                        height: "580px",
                        width: "770px",
                      }}
                    >
                      <img
                        src=""
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Grid> */}
            {/* Fourth section ends here */}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Features;
