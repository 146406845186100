import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";

import EventBarChart from "../../components/BarCharts/EventBarChart";
import ChartLoading from "../../components/Loading/ChartLoading";
import getRandomColor from "../../utils/RandomColor";
// import BarChart from "../../components/BarCharts/BarChart";
const EventCategory = ({
  type,
  isXSScreen,
  holidayData,
  isLoading,
  selectedPage,
  // selectedRestaurant,
  restaurant,
  // showBarchart = false,
}) => {
  const [tableData, setTableData] = useState([]);

  const fetchData = useCallback(async () => {
    if (holidayData) {
      // console.log(holidayData, "holid");
      const processedData = holidayData.results
        .filter((row) => row.name !== "High/low season")
        // .filter(
        //   (row) => !(row.event_category && row.location && row.effect <= 2000)
        // )

        .map((row) => ({
          ...row,
          name: row.name === null ? "None" : row.name,
          effect: row.effect === null ? "None" : row.effect,
          date: row.date === null ? "None" : row.date,
          event_category:
            row.event_category === null ? "None" : row.event_category,
          location: row.location === null ? "None" : row.location,
          type: row.type === null ? "None" : row.type,
        }))
        .reverse();
      setTableData(processedData);
      // console.log(processedData, "ruru");
    } else {
      setTableData([]);
    }
    // } else {
    //   setTableData([]);
    //   console.error("Failed to fetch data");
    //   setIsLoading(false);
    // }
  }, [holidayData]);

  useEffect(() => {
    if (!holidayData || holidayData.length === 0) {
      setTableData([]);
    }
  }, [restaurant, holidayData]);

  useEffect(() => {
    if (
      selectedPage === "multiple" &&
      (!restaurant || restaurant.length === 0)
    ) {
      setTableData([]);
    } else {
      fetchData();
    }
  }, [fetchData, restaurant, selectedPage]);

  const barChartData = tableData
    .filter((item) => item.type !== "None")
    .filter((item) => (type ? item.type === type : true))

    .map((item) => ({
      Color: getRandomColor(),
      type: item.type,
      effect: item.effect,
      Name: item.name,
      date: item.date,
      event_category: item.event_category,
      location: item.location,
    }));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90%",
        height: "100%",
        // border: "solid red 1px",
      }}
    >
      {isLoading ? (
        <ChartLoading />
      ) : (
        // ) : showBarchart ? (
        //   <BarChart data={barChartData} title={type} isXSScreen={isXSScreen} />
        <EventBarChart
          data={barChartData}
          title={type}
          isXSScreen={isXSScreen}
        />
      )}
    </Box>
  );
};

export default EventCategory;
