import * as dayjs from "dayjs";
import * as XLSX from "xlsx";

const downloadComparisons = (
  comparisonLineData,
  productMixData,
  subProductChartData,
  firstFetchSum,
  secondFetchSum,
  firstProductSum,
  secondProductSum,
  firstSubProductSum,
  secondSubProductSum,
  allSums, // Add this parameter
  restaurantNames
) => {
  const wb = XLSX.utils.book_new();

  // Function to process and transform chart data for Excel
  const processChartData = (data, sheetName) => {
    if (!Array.isArray(data)) {
      console.error(`Expected an array for ${sheetName}, but got:`, data);
      return;
    }

    let transformedData = [];
    data.forEach((dataSet) => {
      const groupByDateTime = dataSet.data.reduce((acc, entry) => {
        const dateTime = dayjs(entry.actualDate).format("YYYY/MM/DD");

        if (!acc[dateTime]) {
          acc[dateTime] = [];
        }
        acc[dateTime].push({
          Category: dataSet.id,
          Value: entry.y,
        });
        return acc;
      }, {});

      Object.entries(groupByDateTime).forEach(([dateTime, entries]) => {
        transformedData.push({
          Date: dateTime.replace("T", " ").replace("Z", ""),
          ...entries[0],
        });
        entries.slice(1).forEach((entry) => {
          transformedData.push({ ...entry });
        });
      });
    });
    if (transformedData.length > 0) {
      transformedData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
      const ws = XLSX.utils.json_to_sheet(transformedData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    }
  };

  // Process Comparison Line Data for sales and profit
  processChartData(comparisonLineData, "Comparison Line Data");

  // Process Product Mix Data
  processChartData(productMixData, "Product Mix Data");

  // Process Sub-Product Chart Data
  processChartData(subProductChartData, "Sub-Product Data");

  // Add summary data
  const summaryData = [
    { Key: "Sales Current Period", Value: firstFetchSum },
    { Key: "Sales Last Period", Value: secondFetchSum },
    { Key: "Product Current Period", Value: firstProductSum },
    { Key: "Product Last Period", Value: secondProductSum },
    { Key: "Sub-Product Total Current Period", Value: firstSubProductSum },
    { Key: "Sub-Product Total Last Period", Value: secondSubProductSum },
  ];

  const wsSummary = XLSX.utils.json_to_sheet(summaryData);
  XLSX.utils.book_append_sheet(wb, wsSummary, "Summary");

  // Process and add comparison data
  const comparisonFormattedData = [
    {
      Category: "Sales",
      "This Year's Period": allSums.total_net.currentSum,
      "Last Year's Period": allSums.total_net.correspondingSum,
      Difference:
        allSums.total_net.currentSum - allSums.total_net.correspondingSum,
    },
    {
      Category: "Gross Profit I",
      "This Year's Period": allSums.gross_profit.currentSum,
      "Last Year's Period": allSums.gross_profit.correspondingSum,
      Difference:
        allSums.gross_profit.currentSum - allSums.gross_profit.correspondingSum,
    },
    {
      Category: "Gross Profit II",
      "This Year's Period": allSums.operating_profit.currentSum,
      "Last Year's Period": allSums.operating_profit.correspondingSum,
      Difference:
        allSums.operating_profit.currentSum -
        allSums.operating_profit.correspondingSum,
    },
    {
      Category: "Cost of Goods",
      "This Year's Period": allSums.cost.currentSum,
      "Last Year's Period": allSums.cost.correspondingSum,
      Difference: allSums.cost.currentSum - allSums.cost.correspondingSum,
    },
    {
      Category: "Delivery Cost",
      "This Year's Period": allSums.delivery_cost.currentSum,
      "Last Year's Period": allSums.delivery_cost.correspondingSum,
      Difference:
        allSums.delivery_cost.currentSum -
        allSums.delivery_cost.correspondingSum,
    },
    {
      Category: "Employee Cost",
      "This Year's Period": allSums.employee_cost.currentSum,
      "Last Year's Period": allSums.employee_cost.correspondingSum,
      Difference:
        allSums.employee_cost.currentSum -
        allSums.employee_cost.correspondingSum,
    },
    {
      Category: "Rent",
      "This Year's Period": allSums.rent.currentSum,
      "Last Year's Period": allSums.rent.correspondingSum,
      Difference: allSums.rent.currentSum - allSums.rent.correspondingSum,
    },
  ];

  const wsComparison = XLSX.utils.json_to_sheet(comparisonFormattedData);
  XLSX.utils.book_append_sheet(wb, wsComparison, "Comparison Overview");

  // Determine file name
  let fileName;
  if (Array.isArray(restaurantNames) && restaurantNames.length > 0) {
    fileName = `${restaurantNames.join("_")}.xlsx`;
  } else if (restaurantNames && typeof restaurantNames === "string") {
    fileName = `${restaurantNames}.xlsx`;
  } else {
    fileName = "Company Overall Data.xlsx";
  }

  // Write Excel file and trigger download
  if (wb.SheetNames.length > 0) {
    XLSX.writeFile(wb, fileName);
  } else {
    alert("No data available to download!");
  }
};

export default downloadComparisons;
