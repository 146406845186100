// export function getLineTickValues(dataSets) {
//   if (!dataSets || dataSets.length === 0) {
//     console.warn("No data provided");
//     return { tickValuesI: [], tickValuesO: [] };
//   }

//   const startingDate = dataSets[0]?.data[0]?.x
//     ? new Date(dataSets[0]?.data[0]?.x)
//     : new Date(); // Default to current date if not available

//   // Calculate the end date for both dataSets[0] and dataSets[1], if available
//   const endDate0 = dataSets[0]?.data[dataSets[0].data.length - 1]?.x
//     ? new Date(dataSets[0].data[dataSets[0].data.length - 1].x)
//     : new Date(); // Default to current date if not available

//   const endDate1 =
//     dataSets[1] && dataSets[1].data.length > 0
//       ? new Date(dataSets[1].data[dataSets[1].data.length - 1].x)
//       : endDate0; // Default to endDate0 if dataSets[1] is not available

//   // Choose the furthest (latest) end date as the endingDate
//   const endingDate = endDate1 > endDate0 ? endDate1 : endDate0;

//   let tickValuesI = [];
//   let tickValuesO = [];

//   if (startingDate && endingDate) {
//     const totalRange = endingDate.getTime() - startingDate.getTime();
//     const middle_date = new Date(startingDate.getTime() + totalRange / 2);

//     // Calculate tick values for large screens
//     tickValuesI = Array.from(
//       { length: 7 },
//       (_, i) => new Date(startingDate.getTime() + (totalRange / 6) * i)
//     );

//     tickValuesO = [startingDate, middle_date, endingDate];
//   } else {
//     console.warn("Starting or ending dates are not valid.");
//   }

//   return { tickValuesI, tickValuesO };
// }

export function getLineTickValues(dataSets) {
  if (!dataSets || dataSets.length === 0) {
    console.warn("No data provided");
    return { tickValuesI: [], tickValuesO: [] };
  }

  const startingDate = dataSets[0]?.data[0]?.x
    ? new Date(dataSets[0]?.data[0]?.x)
    : new Date(); // Default to current date if not available

  // Calculate the end date for the last dataset
  const lastDataset = dataSets[dataSets.length - 1];
  const endDateLast = lastDataset?.data[lastDataset.data.length - 1]?.x
    ? new Date(lastDataset.data[lastDataset.data.length - 1].x)
    : new Date(); // Default to current date if not available

  // Calculate the end date for the first dataset
  const endDateFirst = dataSets[0]?.data[dataSets[0].data.length - 1]?.x
    ? new Date(dataSets[0].data[dataSets[0].data.length - 1].x)
    : new Date(); // Default to current date if not available

  // Choose the furthest (latest) end date as the endingDate
  const endingDate = endDateLast > endDateFirst ? endDateLast : endDateFirst;

  let tickValuesI = [];
  let tickValuesO = [];

  if (startingDate && endingDate) {
    const totalRange = endingDate.getTime() - startingDate.getTime();
    const middle_date = new Date(startingDate.getTime() + totalRange / 2);

    // Calculate tick values for large screens
    tickValuesI = Array.from(
      { length: 7 },
      (_, i) => new Date(startingDate.getTime() + (totalRange / 6) * i)
    );

    tickValuesO = [startingDate, middle_date, endingDate];
  } else {
    console.warn("Starting or ending dates are not valid.");
  }

  return { tickValuesI, tickValuesO };
}
