import React from "react";
import { Typography, Tooltip } from "@mui/material";

const LastUpdate = () => {
  const latest_created_at = localStorage.getItem("latest_records");
  const company = localStorage.getItem("company"); // Example: get company name from localStorage

  // Ensure we have a valid date string in latest_created_at
  if (!latest_created_at) {
    return null; // or return a placeholder message if needed
  }

  const dateObject = new Date(latest_created_at);
  const formattedDate = dateObject.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const formattedTime = dateObject
    .toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "2-digit",
      hour12: false,
    })
    .toLowerCase();
    const tooltipMessage =
    company === "Fisketorget"
      ? "Date and time of your latest sales. The data itself is fetched 07:00 AM everyday."
      : company === "Los Tacos"
      ? "Date and time of your latest sales. The data itself is fetched approximately every 10 minutes."
      : "Date and time of your latest sales.";

  return (
    <Tooltip title={tooltipMessage} arrow>
      <Typography
        sx={{
          fontSize: "12px",
          textAlign: "center",
        }}
      >
        Last Updated: {`${formattedDate} ${formattedTime}`}
      </Typography>
    </Tooltip>
  );
};

export default LastUpdate;
