import React, { useState, useEffect, useCallback } from "react";
import downloadSalesPred from "../utils/ExcelDownloads/DownloadSalesPredictionExcel";
import {
  Grid,
  //    Container,
  // Card,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Zoom,
  Switch,
  InputAdornment,
  Collapse,
  DialogTitle,
  DialogContentText,
  useMediaQuery,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  // MenuProps,
  OutlinedInput,
  // ListItemText,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

// import HourlyLineChart from "../components/HourlyLineChart";
import ChartLoading from "../components/Loading/ChartLoading";
import HolidayTable from "../HolidayTable";
import SnackbarAlert from "../utils/SnackbarAlert";
// import LineChart from "../components/LineCharts/LineChart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import CloudDownlorg2adIcon from "@mui/icons-material/CloudDownload";
// import ProdBarChart from "../components/ProdBarChart";
import PointHourlyLineChart from "../components/LineCharts/PointHourlyLineChart";
// import EventTable from "../components/Tables/EventTable";
import HolidayParamsTable from "../components/Tables/HolidayParamsTable";
import getHighlightedStyle from "../utils/HighlightedStyle";
import NavigationButtons from "../utils/HelpButton";
import Prefix from "../utils/PrefixLink";
import Colors from "../utils/Colors";
import FormatDesiredDate from "../utils/FormatDesiredDate";

import EventCategory from "./DashboardComponents/EventCategory";
import Weather from "../components/Weather";
import SettingsDialog from "../components/SettingsDialog";
import SalesPredLineChart from "../components/LineCharts/SalesPredLineChart";
import TooltipCreate from "../components/Tooltips/Tooltip";
import TitleStyle from "../components/Style/Title";
import StyledButton from "../components/Style/ButtonStyle";
import HelpModeButton from "../components/HelpModeButton";

// import { useNavigate } from "react-router-dom";
const user = JSON.parse(localStorage.getItem("user"));

// const comp = localStorage.getItem("company");
const token = localStorage.getItem("token");
const role = JSON.parse(localStorage.getItem("user"))?.role;

const prefix = Prefix();
const isAdmin = JSON.parse(localStorage.getItem("user"))?.company_admin;

const ITEM_HEIGHT = "1rem";
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function SalesPrediction({
  setSelectedRestaurant,
  selectedRestaurant,
  selectedPage,
  setSelectedPage,
  company,
  setCompany,
  chartWeatherData,
  // startDate,
  // endDate,
  // setStartDate,
  // setEndDate,
}) {
  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Make sure to declare these with 'let' if they need to be reassigned
  let defaultStartDate = new Date();
  let defaultEndDate = new Date();

  function getMonday(d) {
    let date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    return new Date(date.setDate(diff));
  }

  function getNextSunday(d) {
    let date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() + ((7 - day) % 7);
    return new Date(date.setDate(diff));
  }

  if (isXSScreen) {
    // Adjust start and end dates to match Monday to Sunday for XS screens
    defaultStartDate = getMonday(new Date()); // Sets to this week's Monday
    defaultEndDate = getNextSunday(new Date(defaultStartDate)); // Sets to the next week's Sunday
    defaultEndDate.setDate(defaultEndDate.getDate() + 7); // Move to the end of the following week
  } else {
    // Sets the range from two weeks before to two weeks after today
    defaultStartDate.setDate(defaultStartDate.getDate() - 14); // Starting 14 days before today
    defaultEndDate.setDate(defaultEndDate.getDate() + 14); // Ending 14 days after today
  }

  let hourStartDate = new Date();
  let hourEndDate = new Date();

  hourStartDate.setDate(hourStartDate.getDate() - 1);
  hourEndDate.setDate(hourEndDate.getDate() + 1);
  // defaultStartDate.setDate(defaultStartDate.getDate() - 14);
  // const defaultEndDate = new Date();
  // defaultEndDate.setDate(defaultEndDate.getDate() + 14);

  const [startDate, setStartDate] = useState(
    defaultStartDate.toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    defaultEndDate.toISOString().split("T")[0]
  );

  const [startingDate, setStartingDate] = useState(
    hourStartDate.toISOString().split("T")[0]
  );
  const [endingDate, setEndingDate] = useState(
    hourEndDate.toISOString().split("T")[0]
  );
  const isSingleDay = startDate === endDate;
  const [viewMode, setViewMode] = useState(isSingleDay ? "Day" : "Category");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [newStartDate, setNewStartDate] = useState("");
  const [newEndDate, setNewEndDate] = useState("");
  const [disableInput, setDisableInput] = useState("");
  const [load, setLoad] = useState(false);
  const [salesLoad, setSalesLoad] = useState(false);
  // const [predictLoad, setPredictLoad] = useState(false);

  const [helpMode, setHelpMode] = useState(false);
  const [counter, setCounter] = useState(1);
  // const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [hasFetched, setHasFetched] = useState(false);

  const [combinedData, setCombinedData] = useState([]);
  const [prodCombinedData, setProdCombinedData] = useState([]);
  const [salesPredData, setSalesPredData] = useState([]);
  const [recommendedHours, setRecommendedHours] = useState([]);

  const [holidayData, setHolidayData] = useState([]);

  const [hourlySalesData, setHourlySalesData] = useState([]);
  const [hourlyPredictionData, setHourlyPredictionData] = useState([]);
  const [singlePredictionDate, setSinglePredictionDate] = useState("");
  const [combinedHourlyData, setCombinedHourlyData] = useState([]);
  const [singlePredictionTotalGross, setSinglePredictionTotalGross] =
    useState("");
  const [barStartDate, setBarStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [barEndDate, setBarEndDate] = useState("");
  const [userComment, setUserComment] = useState();
  const [additionalComments, setAdditionalComments] = useState("");

  const [adjustOpen, setAdjustOpen] = useState(false);

  const [isFirstChartVisible, setIsFirstChartVisible] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const [extraPercentageInput, setExtraPercentageInput] = useState("");

  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  // const [company, setCompany] = useState(comp);

  // const [collapseOpen, setCollapseOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("warning");
  const [message, setMessage] = useState("");
  const [defaultRestaurants, setDefaultRestaurants] = useState([]);
  const getRestaurantNameById = (restaurantName) => {
    const restaurant = defaultRestaurants.find(
      (r) => r.name === restaurantName
    );
    return restaurant ? restaurant.name : "";
  };
  const email = user?.email;

  const primaryRestaurant = user?.primary_restaurant;
  // const [isPrimaryRestaurant, setIsPrimaryRestaurant] = useState();
  // const user = JSON.parse(localStorage.getItem("user"));
  // const primaryRestaurant = user?.primary_restaurant;

  // const [restaurantNames, setRestaurantNames] =
  //   useState(default_restaurant)

  // const [selectedRestaurant, setSelectedRestaurant] = useState("");
  // const [restaurants, setRestaurants] = useState([]);
  const maxSteps = 7;

  const handleClose = () => {
    setOpen(false);
  };

  const downloadOptions = [
    () =>
      downloadSalesPred(
        salesPredData,
        hourlySalesData,
        prodCombinedData,
        hourlyPredictionData,
        combinedData,
        recommendedHours,
        selectedRestaurant,
        holidayData,
        company
      ),
    // Add other function calls as necessary
  ];
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const yyyy = tomorrow.getFullYear();
  const mm = String(tomorrow.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we need to add 1
  const dd = String(tomorrow.getDate()).padStart(2, "0");

  const minDate = `${yyyy}-${mm}-${dd}`;

  const handlePageChange = (newValue) => {
    if (newValue === "company" && !isAdmin) {
      newValue = "multiple";
    }
    setSelectedPage(newValue); // Update the state with the new value

    // if (!newValue) return; // If no button is selected, do nothing

    if (newValue === "multiple") {
    } else if (newValue === "company") {
      setSelectedRestaurant([]);
    }
  };

  const handleAdjustClick = () => {
    setAdjustOpen(true);
  };

  const handleAdjustClose = () => {
    setUserComment("");

    setAdjustOpen(false);
  };

  // Function to handle backward click
  const handleBackwardClick = () => {
    setCombinedData([]);
    setProdCombinedData([]);
    setSalesPredData([]);
    setRecommendedHours([]);

    const newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() - 7); // Subtract 7 days

    const newEndDate = new Date(endDate);
    newEndDate.setDate(newEndDate.getDate() - 7); // Subtract 7 days

    const newStartingDate = new Date(startingDate);
    newStartingDate.setDate(newStartingDate.getDate() - 1); // Subtract 7 days

    const newEndingDate = new Date(endingDate);
    newEndingDate.setDate(newEndingDate.getDate() - 1);

    setStartDate(newStartDate.toISOString().split("T")[0]);
    setEndDate(newEndDate.toISOString().split("T")[0]);

    setStartingDate(newStartingDate.toISOString().split("T")[0]);
    setEndingDate(newEndingDate.toISOString().split("T")[0]);

    setNewStartDate(newStartDate.toISOString().split("T")[0]);
    setNewEndDate(newEndDate.toISOString().split("T")[0]);
  };

  // Function to handle forward click
  const handleForwardClick = () => {
    setCombinedData([]);
    setProdCombinedData([]);
    setSalesPredData([]);
    setRecommendedHours([]);
    const newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() + 7); // Add 7 days

    const newEndDate = new Date(endDate);
    newEndDate.setDate(newEndDate.getDate() + 7); // Add 7 days

    const newStartingDate = new Date(startingDate);
    newStartingDate.setDate(newStartingDate.getDate() + 1); // Subtract 7 days

    const newEndingDate = new Date(endingDate);
    newEndingDate.setDate(newEndingDate.getDate() + 1);

    setStartingDate(newStartingDate.toISOString().split("T")[0]);
    setEndingDate(newEndingDate.toISOString().split("T")[0]);

    setStartDate(newStartDate.toISOString().split("T")[0]);
    setEndDate(newEndDate.toISOString().split("T")[0]);
    setNewStartDate(newStartDate.toISOString().split("T")[0]);
    setNewEndDate(newEndDate.toISOString().split("T")[0]);
  };

  const resetUserPrediction = async () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    let delete_start_date;
    let delete_end_date;
    const isDateRangeEmpty = !newStartDate || !newEndDate;
    const isSingleDateEmpty = !singlePredictionDate;
    if (isDateRangeEmpty && isSingleDateEmpty) {
      setOpen(true);
      setSeverity("error");
      setMessage("You need to select either the date interval or a date");
      return;
    }
    if (!isDateRangeEmpty) {
      delete_start_date = newStartDate;
      delete_end_date = newEndDate;
    } else if (!isSingleDateEmpty) {
      delete_start_date = singlePredictionDate;
      delete_end_date = singlePredictionDate;
    }
    fetch(
      `${prefix}/api/userpredictions/reset_user_predictions/?start_date=${delete_start_date}&end_date=${delete_end_date}&restaurant=${selectedRestaurant}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Assuming the response is JSON
      })
      .then((data) => {
        if (data.success) {
          setOpen(true);
          setSeverity("success");
          setMessage(`${data.success}`);
        } else if (data.error) {
          setOpen(true);
          setSeverity("error");
          setMessage(`${data.error}`);
        }
      })
      .catch((err) => {
        setOpen(true);
        setSeverity("error");
        setMessage("Server Error");
      });

    getSalesData();
    // getRecommendedHours();
  };

  const setDate = (date) => {
    setSinglePredictionDate(date);
    if (date < minDate) {
      setDisableInput(true);
      setAdditionalComments(
        "Your predictions will not be registered for historical data but you can still add comments regarding the data."
      );
      setSinglePredictionTotalGross(0);
    } else {
      setDisableInput(false);
      setAdditionalComments("");
    }
  };

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // const handleSubmit = () => {
  //   if (!selectedRestaurant) {
  //     window.alert("Select a Restaurant First");
  //   } else {
  //     // getMixData();
  //     // getTypeData();
  //     getSalesData();
  //     // getRecommendedHours();
  //     fetchHolidayData();
  //     // getHourlyData();
  //     handleHolidayBar(new Date());
  //     fetchData();
  //   }
  // };

  const handleHolidayBar = (date) => {
    setBarStartDate(date.toISOString().split("T")[0]);
    setBarEndDate(date.toISOString().split("T")[0]);
  };

  const handleChange = (event) => {
    // setRestaurantNames(event.target.value);
    setSelectedRestaurant([event.target.value]);
    setCombinedData([]);
    setProdCombinedData([]);
    setSalesPredData([]);
    setRecommendedHours([]);
    // setHourlySalesData([]);
    // setHourlyPredictionData([]);
    // console.log(selectedRestaurant, "ayo selected");
  };
  const tooltipContent = {
    1: (
      <Typography variant="body2">
        Choose the restaurant you want to look at from the drop down menu. Click
        the download button to download the predictions and other data shown on
        the page
      </Typography>
    ),
    2: (
      <Typography variant="body2">
        Choose to filter the dashboard by the given interval. You can easily
        navigate back and forth with the arrows.
        {/* Click on “Show filters” to set your own custom start and end date. You
        can see salesdata and prediction all the way back to the beginning of
        your restaurants’ sales */}
      </Typography>
    ),
    3: (
      <Typography variant="body2">
        Click on “Adjust prediction” in the toolbar area to add your own
        assessment of the sales predictions. You can adjust a single day or a
        date interval. The adjustments can easily be reset by choosing the
        relevant date and click on the reset button
      </Typography>
    ),
    4: (
      <Typography variant="body2">
        Understand why the prediction for a specific day is what it is. This
        graph shows you how various parameters such as concerts, weather,
        marketing campaigns etc. is expected to affect sales.
      </Typography>
    ),
    5: (
      <Typography variant="body2">
        {/* This graphs shows how much each product category or product is selling
        for, as a percentage of total sales You can select product category to
        see the products in that category by clicking on the toggle button on
        the upper right corner */}
        View all upcoming events to have an overview of what's coming{" "}
      </Typography>
    ),
    6: (
      <Typography variant="body2">
        Understand why the prediction for a specific day is what it is. This
        graph shows you how weather, is expected to affect sales.
      </Typography>
    ),

    // ... add more steps as needed
  };
  if (viewMode === "Day") {
    tooltipContent[6] = (
      <Typography variant="body2">
        All Factors displays the impact on sales resulting from various elements
        such as events and weather.
      </Typography>
    );
  }

  //tutorial guide

  const handleHelpMode = () => {
    setHelpMode(!helpMode);
    setCounter(1); // Reset counter when toggling helpMode
  };

  const handleNext = () => {
    setCounter((prev) => {
      let newCounter = prev + 1;
      if (selectedPage === "company" && newCounter === 3) {
        newCounter = 4; // Skip the counter value for "Adjust Prediction"
      }
      if (newCounter >= maxSteps) {
        setHelpMode(false);
      } else {
        // Scroll logic for mobile view in helpMode, only after counter reaches 4
        if (helpMode && newCounter > 4) {
          const remInPixels = parseFloat(
            getComputedStyle(document.documentElement).fontSize
          );
          window.scrollBy({
            top: 30 * remInPixels, // Scroll by 30rem in pixels
            behavior: "smooth",
          });
        }
      }
      return newCounter;
    });
  };

  const handlePrevious = () => {
    setCounter((prev) => {
      let newCounter = Math.max(prev - 1, 1);
      if (selectedPage === "company" && newCounter === 3) {
        newCounter = 2; // Skip the counter value for "Adjust Prediction"
      }

      // Scroll logic for mobile view in helpMode, only if counter was above 4
      if (helpMode && prev > 4) {
        const remInPixels = parseFloat(
          getComputedStyle(document.documentElement).fontSize
        );
        window.scrollBy({
          top: -30 * remInPixels, // Scroll upwards by 30rem in pixels
          behavior: "smooth",
        });
      }

      return newCounter;
    });
  };

  const handleToggleChart = () => {
    setIsFirstChartVisible((prev) => !prev);
  };

  const handleAddUserPrediction = () => {
    const isDateRangeEmpty =
      !newStartDate || !newEndDate || !extraPercentageInput;
    const isSingleDateEmpty =
      !singlePredictionDate || !singlePredictionTotalGross;

    if (isDateRangeEmpty && isSingleDateEmpty) {
      alert("Please fill in all fields.");
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };

    if (!isDateRangeEmpty) {
      // Handle date range predictions
      const startDateObj = new Date(newStartDate);
      startDateObj.setHours(0, 0, 0, 0);

      const endDateObj = new Date(newEndDate);

      while (startDateObj <= endDateObj) {
        const predictionEntry = salesPredData.prediction.data.find((entry) => {
          return entry.x.getTime() === startDateObj.getTime();
        });

        const adjustedPrediction = predictionEntry
          ? predictionEntry.y * (1 + parseFloat(extraPercentageInput) / 100)
          : parseFloat(extraPercentageInput); // Use the percentage as the default value
        const localDateAsString = `${startDateObj.getFullYear()}-${String(
          startDateObj.getMonth() + 1
        ).padStart(2, "0")}-${String(startDateObj.getDate()).padStart(2, "0")}`;

        requestOptions.body = JSON.stringify({
          date: localDateAsString,
          total_gross: Math.round(adjustedPrediction),
          restaurant: Array.isArray(selectedRestaurant)
            ? selectedRestaurant[0]
            : selectedRestaurant,
          comment: userComment,
          company: company,
        });

        fetch(`${prefix}/api/userpredictions/`, requestOptions)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json(); // Assuming the response is JSON
          })
          .then((data) => {
            // Handle the data here
          })
          .catch((err) => console.log(err));
        startDateObj.setDate(startDateObj.getDate() + 1);
      }
      getSalesData();
    }

    if (!isSingleDateEmpty) {
      // Handle single date prediction
      const predictionDateObj = new Date(singlePredictionDate);
      predictionDateObj.setHours(0, 0, 0, 0);
      const predictionDate = `${predictionDateObj.getFullYear()}-${String(
        predictionDateObj.getMonth() + 1
      ).padStart(2, "0")}-${String(predictionDateObj.getDate()).padStart(
        2,
        "0"
      )}`;
      // console.log(predictionDate, "entry");

      // Log the entire prediction data structure
      // console.log(salesPredData.prediction.data, "prediction data structure");

      // Check the structure of a single entry
      if (salesPredData.prediction.data.length > 0) {
        // console.log(salesPredData.prediction.data[0], "first entry structure");
      }

      // Find the prediction entry for the specific date
      const predictionEntry = salesPredData.prediction.data.find((entry) => {
        return entry.x.getTime() === predictionDateObj.getTime();
      });
      // console.log(predictionEntry, "entry1");
      // console.log(salesPredData, "entry2");

      // Calculate the adjusted prediction
      const adjustedPrediction = predictionEntry
        ? predictionEntry.y * (1 + parseFloat(singlePredictionTotalGross) / 100)
        : parseFloat(singlePredictionTotalGross); // Use the provided value as default if no prediction

      requestOptions.body = JSON.stringify({
        date: predictionDate,
        total_gross: Math.round(adjustedPrediction),
        restaurant: Array.isArray(selectedRestaurant)
          ? selectedRestaurant[0]
          : selectedRestaurant,
        comment: userComment,
        company: company,
      });

      // Submit adjusted prediction
      fetch(`${prefix}/api/userpredictions/`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then(() => {
          getSalesData();
        })
        .catch((error) => {
          console.error(
            "Error submitting user prediction for single date:",
            error
          );
        });
    }

    // Clear the input fields after submission
    setUserComment("");
    setNewStartDate("");
    setNewEndDate("");
    setSinglePredictionDate("");
    setSinglePredictionTotalGross("");
    setExtraPercentageInput("");
  };

  const fetchRestaurants = useCallback(async () => {
    // Replace the URL with the appropriate API endpoint for fetching a single task
    // setLoad(true);
    const restro_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();

      const userDefaultRestaurants = JSON.parse(
        localStorage.getItem("user")
      )?.default_restaurants;

      let filteredRestaurants = restro_data.results.filter(
        (restaurant) =>
          userDefaultRestaurants.includes(restaurant.id) &&
          restaurant.name !== "Fisketorget Stavanger"
      );
      if (userDefaultRestaurants && userDefaultRestaurants.length > 0) {
        // Filter restaurants based on default_restaurants IDs
        filteredRestaurants = restro_data.results.filter((restaurant) =>
          userDefaultRestaurants.includes(restaurant.id)
        );
      }
      if (!userDefaultRestaurants || userDefaultRestaurants.length === 0) {
        filteredRestaurants = restro_data.results.filter(
          (restaurant) => restaurant.name !== "Fisketorget Stavanger"
        );
      }
      // Apply sorting to move 'Food Truck' to the bottom of the list
      filteredRestaurants.sort((a, b) => {
        const isAFoodTruck = a.name.includes("Food Truck");
        const isBFoodTruck = b.name.includes("Food Truck");

        if (isAFoodTruck && !isBFoodTruck) {
          return 1; // Place 'a' after 'b'
        } else if (!isAFoodTruck && isBFoodTruck) {
          return -1; // Place 'a' before 'b'
        }
        return 0; // Keep original order for other restaurants
      });
      setDefaultRestaurants(filteredRestaurants);

      if (primaryRestaurant) {
        const mainRestaurant = filteredRestaurants.filter(
          (restaurant) => restaurant.id === primaryRestaurant
        );
        setSelectedRestaurant([mainRestaurant[0]?.name]);
      }
    }
  }, [primaryRestaurant, setSelectedRestaurant, company]);

  const handlePreviousDay = () => {
    const prevDate = new Date(
      new Date(barStartDate).getTime() - 24 * 60 * 60 * 1000
    );
    setBarStartDate(prevDate.toISOString().split("T")[0]);
    setBarEndDate(prevDate.toISOString().split("T")[0]);
  };

  const handleNextDay = () => {
    const nextDate = new Date(
      new Date(barStartDate).getTime() + 24 * 60 * 60 * 1000
    );
    setBarStartDate(nextDate.toISOString().split("T")[0]);
    setBarEndDate(nextDate.toISOString().split("T")[0]);
  };
  // const handlePreviousDay = () => {
  //   const prevDate = new Date(barStartDate);
  //   prevDate.setDate(prevDate.getDate() - 1);
  //   const formattedDate = prevDate.toISOString().split("T")[0];
  //   setBarStartDate(formattedDate);
  //   setBarEndDate(formattedDate);
  // };

  // const handleNextDay = () => {
  //   const nextDate = new Date(barStartDate);
  //   nextDate.setDate(nextDate.getDate() + 1);
  //   const formattedDate = nextDate.toISOString().split("T")[0];
  //   setBarStartDate(formattedDate);
  //   setBarEndDate(formattedDate);
  // };

  //API fetch for hourly salesdata
  const getHourlyData = useCallback(async () => {
    if (
      selectedPage === "multiple" &&
      !selectedRestaurant &&
      selectedRestaurant.length < 0
    ) {
      return;
    }

    setLoad(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      let hourlySalesUrl = `${prefix}/api/salesdata_hourly/sales_data_hourly/`;
      let hourlyPredictionUrl = `${prefix}/api/predictions_by_hour/hourly_predictions/`;
      if (company) {
        hourlySalesUrl += `?company=${company}`;
        hourlyPredictionUrl += `?company=${company}`;
      }
      if (
        selectedPage === "multiple" &&
        selectedRestaurant &&
        selectedRestaurant.length > 0
      ) {
        hourlySalesUrl += `&restaurant=${selectedRestaurant}`;
        hourlyPredictionUrl += `&restaurant=${selectedRestaurant}`;
      }

      if (startingDate && endingDate) {
        hourlySalesUrl += `&start_date=${startingDate}&end_date=${endingDate}`;
        hourlyPredictionUrl += `&start_date=${startingDate}&end_date=${endingDate}`;
      }

      const hourlySalesDataResponse = await fetch(hourlySalesUrl, { headers });
      const hourlySalesData = await hourlySalesDataResponse.json();

      const hourlyPredictionDataResponse = await fetch(hourlyPredictionUrl, {
        headers,
      });
      const hourlyPredictionData = await hourlyPredictionDataResponse.json();

      const salesProcessedData = hourlySalesData.error
        ? null
        : {
            id: "Total Hourly Sales",
            data: hourlySalesData.results.map((entry) => ({
              x: entry.date.replace("T", " ").slice(0, -3),
              y: parseFloat(entry.total_net),
            })),
          };

      const predictionProcessedData = hourlyPredictionData.error
        ? null
        : {
            id: "Predictions",
            data: hourlyPredictionData.results.map((entry) => ({
              x: entry.date.replace("T", " ").slice(0, -3),
              y: parseFloat(entry.total_gross),
            })),
          };

      if (salesProcessedData) setHourlySalesData(salesProcessedData);
      if (predictionProcessedData)
        setHourlyPredictionData(predictionProcessedData);

      const combinedData = [salesProcessedData, predictionProcessedData].filter(
        Boolean
      );
      if (combinedData.length > 0) {
        setCombinedHourlyData(combinedData);
      } else {
        setCombinedHourlyData([]);
      }
    } catch (error) {
      setCombinedHourlyData([]);
      console.error("Error fetching data:", error);
    } finally {
      setLoad(false);
    }
  }, [selectedRestaurant, startingDate, endingDate, selectedPage, company]);

  const fetchData = useCallback(async (url) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const response = await fetch(url, { headers: headers });
    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }
    return response.json();
  }, []); // Dependencies for useCallback

  const processSalesData = (data) => {
    return data.results.reduce((result, entry) => {
      const { gastronomic_day, total_net } = entry;
      return {
        ...result,
        total_sales: {
          id: "Total Sales",
          color: "#543c8c",
          data: [
            ...(result["total_sales"]?.data || []),
            {
              x: new Date(gastronomic_day),
              y: parseFloat(total_net),
            },
          ],
        },
      };
    }, {});
  };

  const processPredictionData = (data, label, id) => {
    return data.results.reduce((result, entry) => {
      const { date, total_gross, comment } = entry;
      return {
        ...result,
        [id]: {
          id: label,
          color: "#e394b4",
          data: [
            ...(result[id]?.data || []),
            {
              x: new Date(date),
              y: Math.floor(parseFloat(total_gross) / 500) * 500,
              comment: comment,
            },
          ],
        },
      };
    }, {});
  };

  const getSalesData = useCallback(() => {
    (async () => {
      setSalesLoad(true);
      try {
        let url = `${prefix}/api/salesdata/sales_data/`;
        let urlU = `${prefix}/api/userpredictions/user_predictions`;
        let urlP = `${prefix}/api/predictions/daily_predictions/`;
        if (company) {
          url += `?company=${company}`;
          urlP += `?company=${company}`;
          urlU += `?company=${company}`;
        }
        if (
          selectedPage !== "company" &&
          selectedRestaurant &&
          selectedRestaurant.length > 0
        ) {
          url += `&restaurant=${selectedRestaurant}`;
          urlP += `&restaurant=${selectedRestaurant}`;
          urlU += `&restaurant=${selectedRestaurant}`;
        }

        if (startDate && endDate) {
          url += `&start_date=${startDate}&end_date=${endDate}`;
          urlU += `&start_date=${startDate}&end_date=${endDate}`;
          urlP += `&start_date=${startDate}&end_date=${endDate}`;
        }

        const salesData = await fetchData(url);
        const predictionData = await fetchData(urlP);
        const userPredictionData = await fetchData(urlU);

        const salesProcessedData = !salesData.error
          ? processSalesData(salesData)
          : null;
        const predictionProcessedData = !predictionData.error
          ? processPredictionData(predictionData, "Prediction", "prediction")
          : null;

        // console.log(salesProcessedData, "osmo");
        const userPredictionProcessedData = !userPredictionData.error
          ? processPredictionData(
              userPredictionData,
              "User Prediction",
              "user_prediction"
            )
          : null;

        const userPredictionMap = {};
        if (userPredictionProcessedData) {
          userPredictionProcessedData.user_prediction?.data.forEach((entry) => {
            userPredictionMap[entry.x.toISOString()] = entry;
          });
        }
        // Merge user predictions with predictions, filling in the gaps
        const combinedUserPredictionData = predictionProcessedData
          ? predictionProcessedData.prediction.data.map((item) => {
              return userPredictionMap[item.x.toISOString()] || item;
            })
          : [];

        const combinedData = {
          ...(predictionProcessedData || {}),
          ...(combinedUserPredictionData.length > 0 &&
          selectedPage !== "company"
            ? {
                user_prediction: {
                  id: "User Prediction",
                  color: "#949494",
                  data: combinedUserPredictionData,
                },
              }
            : {}),
          ...(salesProcessedData || {}),
        };

        setSalesPredData(combinedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setSalesLoad(false);
      }
    })();
  }, [
    selectedRestaurant,
    startDate,
    endDate,
    fetchData,
    selectedPage,
    company,
  ]);

  const fetchHolidayData = useCallback(async () => {
    // if (!selectedRestaurant || selectedRestaurant.length === 0) {
    //   return;
    // }
    setIsLoading(true);
    let url = `${prefix}/api/holidayparameters/holiday_params?company=${company}`;

    if (
      selectedPage !== "company" &&
      selectedRestaurant &&
      selectedRestaurant.length > 0
    ) {
      url += `&restaurant=["${selectedRestaurant}"]`;
    }
    if (startDate && endDate) {
      url += `&start_date=${startDate}&end_date=${endDate}`;
    }
    const holidays_response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (holidays_response.ok) {
      const holiday_data = await holidays_response.json();
      setHolidayData(holiday_data);
      // console.log(holiday_data, "holid");
      if (holiday_data.results && holiday_data.results.length > 0) {
        setHolidayData(holiday_data);
      } else {
        setHolidayData([]);
        setIsLoading(false);
      }
    } else {
      console.error("Failed to fetch data");
      setIsLoading(false);
      setHolidayData([]);
    }
    setIsLoading(false);
  }, [selectedRestaurant, startDate, endDate, selectedPage, company]);

  useEffect(() => {
    setViewMode(isSingleDay ? "Day" : "Category");
  }, [isSingleDay]);
  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setHourlyPredictionData([]);
      setSalesPredData([]);
      setHolidayData([]);
      setHourlySalesData([]);
      setHourlyPredictionData([]);
      // setCombinedHourlyData([]);
      setCombinedData([]);
    } else {
      const timer = setTimeout(() => {
        getSalesData();
      }, 500);
      return () => clearTimeout(timer);

      // getAbsoluteStaffData();
      // getSickCostData();
    }
  }, [
    getSalesData,
    // getAbsoluteStaffData,
    // getSickCostData,
    selectedRestaurant,
    selectedPage,
  ]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setCombinedHourlyData([]);
    } else if (!isFirstChartVisible) {
      getHourlyData();
    }
  }, [
    isFirstChartVisible,
    getHourlyData,
    startingDate,
    endingDate,
    selectedPage,
    selectedRestaurant,
  ]);

  useEffect(() => {
    // getSalesData();
    // getRecommendedHours();
    fetchHolidayData();
    fetchData();
  }, [
    // getSalesData,
    // getRecommendedHours,
    fetchHolidayData,
    fetchData,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    fetchRestaurants();
    const today = new Date();
    handleHolidayBar(today);
  }, [fetchRestaurants]);

  useEffect(() => {
    if (Array.isArray(selectedRestaurant) && selectedRestaurant.length > 1) {
      const firstRestaurantArray = [selectedRestaurant[0]]; // Make it an array containing only the first restaurant
      setSelectedRestaurant(firstRestaurantArray);
    }
  }, [selectedRestaurant, setSelectedRestaurant]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Grid
          // className='centered-div'
          // This is the main container that encapsulates the whole page
          sx={{
            // backgroundColor: "#FFF", // Faint gray background
            // mx: "1rem",
            display: "flex",
            flexDirection: "column",
            ml: { lg: "70px", xs: 0 },
            justifyContent: "center",
            alignItems: "center",
            width: "90%",
            height: "100%",
            // outline: "solid red 1px",
          }}
        >
          <Grid
            // gap={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "1rem",
              justifyContent: "center",
              alignItems: "center",
              // marginBottom:'1rem',
              // outline: "solid 1px yellow",
              width: "100%",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                justifyContent: "center",
                alignItems: "center", // This ensures vertical alignment
                width: "100%",
                // height: "100%",
                // outline: "solid red 1px",
              }}
            >
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 1}
              >
                <Box
                  sx={{
                    ...(counter === 1 && helpMode ? getHighlightedStyle() : {}),
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column" },
                    alignItems: "center",
                    gap: { lg: 2, xs: 1 },
                  }}
                >
                  {/* Toggle Button Group */}
                  <FormControl>
                    <ToggleButtonGroup
                      value={selectedPage}
                      exclusive
                      onChange={(event) => {
                        handlePageChange(event.target.value);
                      }}
                      aria-label="View Selection"
                      sx={{
                        background: "#F8F8F8",
                        "& .MuiToggleButton-root": {
                          "&.Mui-selected": {
                            background: "#D6D6D6", // Darker shade for the selected button
                          },
                        },
                        mx: ".5rem",
                      }}
                    >
                      <ToggleButton value="company" aria-label="company">
                        Company
                      </ToggleButton>
                      <ToggleButton value="multiple" aria-label="multiple">
                        Restaurants
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>

                  {/* Restaurant Select Component */}
                  <FormControl
                    sx={{
                      width: 200,
                    }}
                  >
                    <InputLabel id="demo-single-label">
                      {selectedPage === "company"
                        ? company
                        : "Select a Restaurant"}
                    </InputLabel>
                    <Select
                      labelId="demo-single-label"
                      id="demo-single-checkbox"
                      single
                      value={
                        selectedPage === "company" ? [] : selectedRestaurant
                      }
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          label={
                            selectedPage === "company"
                              ? company
                              : "Select a Restaurant"
                          }
                        />
                      }
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map((name) => getRestaurantNameById(name))
                              .join(", ")
                          : getRestaurantNameById(selected)
                      }
                      MenuProps={MenuProps}
                      disabled={selectedPage === "company"}
                      sx={{
                        "& .MuiSelect-select": {
                          // height: '3rem',
                          padding: "0",
                          textIndent: "0.25em",
                          textAlign: "center",
                        },
                        // border: "solid red 1px",
                        background: "#F8F8F8",
                        mx: ".5rem",
                        height: "3rem",
                        color: "black",
                        boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                      }}
                    >
                      {defaultRestaurants.map((restaurant) => (
                        <MenuItem key={restaurant.id} value={restaurant.name}>
                          {restaurant.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Tooltip>
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center", // This ensures vertical alignment
                  // width: "100%",
                  // border: "solid red 1px",
                  mt: 1,
                  ml: { lg: 2, xs: 0 },
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleSubmit()}
                  sx={{
                    backgroundColor: Colors.krunch,
                    width: "5rem",
                    mt: 1,
                    mb: "1rem",
                    color: "#645E29",
                    "&:hover": {
                      backgroundColor: "#e3a902",
                      boxShadow: "none",
                    },
                    "&:active": {
                      backgroundColor: "#e3a902",
                    },
                    fontWeight: 700,
                  }}
                >
                  Submit
                </Button>
              </Box> */}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mt: 2,
                  // outline: "solid 1px green",
                  position: { lg: "absolute", xs: "static" },
                  right: 1,
                  // Adds bottom margin on xs screens
                }}
              >
                <SettingsDialog
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  // range={range}
                  downloadOptions={downloadOptions}
                  startingDate={startingDate}
                  endingDate={endingDate}
                  setStartingDate={setStartingDate}
                  setEndingDate={setEndingDate}
                  isSalesPrediction={true}
                  email={email}
                  company={company}
                  setCompany={setCompany}
                />
                <HelpModeButton
                  isXSScreen={isXSScreen}
                  handleHelpMode={handleHelpMode}
                  helpMode={helpMode}
                />
              </Box>
            </Box>

            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 2}
            >
              <Box
                sx={{
                  ...(counter === 2 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  // outline: "solid 1px blue",
                  height: "100%",
                  // border: "solid red 1px",
                  // width: { xs: "80%", lg: "100%" },
                }}
              >
                <ArrowBackIcon
                  onClick={handleBackwardClick}
                  sx={{
                    fontSize: "30px",
                    padding: "8px", // Adds space around the icon, increasing the border size
                    cursor: "pointer", // Ensures the cursor is a pointer // Ensures the cursor is a pointer
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                      borderRadius: "50%", // Makes the background round
                    },
                  }}
                />
                <FormatDesiredDate
                  startDate={!isFirstChartVisible ? startingDate : startDate}
                  endDate={!isFirstChartVisible ? endingDate : endDate}
                  dataRange={"thisweek"}
                  style={{
                    justifyContent: "center",
                    mt: 0,
                    px: 1,
                  }}
                />

                <ArrowForwardIcon
                  onClick={handleForwardClick}
                  sx={{
                    fontSize: "30px",

                    padding: "8px", // Adds space around the icon, increasing the border size
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                      borderRadius: "50%", // Makes the background round
                    },
                  }}
                />
              </Box>
            </Tooltip>
          </Grid>

          <Grid
            // gap={1}
            // spacing={1}
            sx={{
              // backgroundColor: "rgba(0, 0, 0, 0.05)", // Faint gray background
              // direction: 'column'
              // paddingTop: "1rem",
              display: "flex",
              flexDirection: "column",
              // outline: "solid 1px red",
              width: "100%",
              height: { lg: "200vh", xs: "120rem" },
            }}
          >
            {!selectedRestaurant.includes("Fisketorget Stavanger") &&
              selectedPage === "multiple" &&
              role !== "basic" && (
                <>
                  <Grid
                    lg={12}
                    md={12}
                    xs={12}
                    sx={{
                      boxShadow: "none",
                      borderRadius: "1rem",
                      marginBottom: "1rem",
                      // outline: "solid 5px yellow",
                    }}
                  >
                    <Tooltip
                      arrow
                      title={tooltipContent[counter]}
                      open={helpMode && counter === 3}
                    >
                      <Button
                        variant="text"
                        onClick={handleExpandToggle}
                        sx={{
                          ...(counter === 3 && helpMode
                            ? getHighlightedStyle()
                            : {}),

                          width: "20ch",
                          mt: ".5rem",
                          justifyContent: "center",
                          color: "#515151",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {isExpanded ? "Hide" : "Adjust prediction"}
                      </Button>
                    </Tooltip>
                  </Grid>

                  <Collapse
                    in={isExpanded}
                    sx={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0)", // Faint gray background
                        boxShadow: "none",
                        // border: "1px solid red",
                        justifyContent: "center",
                        // ml: { lg: "20vw", xs: "13vw" },
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Grid
                          lg={12}
                          md={12}
                          xs={12}
                          sx={{
                            display: "flex",
                            flexDirection: ["column", "row"],
                            alignItems: "center",
                            backgroundColor: "rgba(0, 0, 0, 0)", // Faint gray background
                            marginBottom: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 700,
                              // mr: "1ch",
                              display: "flex",
                              alignItems: "flex-start",
                              flexDirection: "row",
                              ml: {
                                xs: "4rem",
                                lg: 0,
                              },
                              fontFamily: "Montserrat",
                            }}
                          >
                            Adjust Single Date
                          </Typography>
                          <FormControl
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              // my:['none', '1rem'],
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FormControl
                              sx={{ m: 1, width: "15ch" }}
                              variant="outlined"
                            >
                              <TextField
                                label="Date"
                                type="date"
                                id="UserPredictionDate"
                                value={singlePredictionDate}
                                onChange={(event) =>
                                  setDate(event.target.value)
                                }
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  min: minDate,
                                }}
                                sx={{
                                  "& input": {
                                    height: "3em", // Adjust this value to your desired height
                                    padding: "0", // Remove padding to avoid extra space inside the input
                                    fontSize: "1em",
                                    textIndent: "0.25em", // Add a small margin to the left of the text inside the input field
                                  },
                                  background: "#F8F8F8",
                                }}
                              />
                            </FormControl>

                            <FormControl
                              sx={{ m: 1, width: "15ch" }}
                              variant="outlined"
                            >
                              <TextField
                                label="Total Gross"
                                type="number"
                                id="UserPredictionTotalGross"
                                value={singlePredictionTotalGross}
                                onChange={(event) =>
                                  setSinglePredictionTotalGross(
                                    event.target.value
                                  )
                                }
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  disabled: disableInput,
                                  endAdornment: (
                                    <InputAdornment position="flex-end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                sx={{
                                  "& input": {
                                    height: "3em", // Adjust this value to your desired height
                                    padding: "0", // Remove padding to avoid extra space inside the input
                                    fontSize: "1em",
                                    textIndent: "0.5em", // Add a small margin to the left of the text inside the input field
                                  },
                                  background: "#F8F8F8",
                                }}
                              />
                            </FormControl>
                          </FormControl>
                          <FormControl
                            sx={{
                              m: 1,
                              width: "15ch",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              ml: {
                                lg: 0,
                                xs: "20vw",
                              },
                              gap: 1,
                            }}
                            variant="outlined"
                          >
                            <StyledButton onClick={handleAdjustClick}>
                              Adjust
                            </StyledButton>

                            <StyledButton onClick={resetUserPrediction}>
                              Reset
                            </StyledButton>
                          </FormControl>
                        </Grid>
                        <Grid
                          lg={12}
                          md={12}
                          xs={12}
                          sx={{
                            display: "flex",
                            flexDirection: ["column", "row"],
                            // alignItems: "center",
                            backgroundColor: "rgba(0, 0, 0, 0)", // Faint gray background
                            boxShadow: "none",
                            // border: "1px solid red",
                            borderRadius: "1rem",
                            marginBottom: "1rem",
                            // justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 700,
                              mr: "1ch",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              ml: {
                                xs: "4rem",
                                lg: 0,
                              },
                              fontFamily: "Montserrat",
                            }}
                          >
                            Adjust Date Interval
                          </Typography>
                          <FormControl
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              // my:['none', '1rem'],
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FormControl
                              sx={{ m: 1, width: "15ch" }}
                              variant="outlined"
                            >
                              <TextField
                                label="Start Date"
                                type="date"
                                id="newStartDate"
                                value={newStartDate}
                                onChange={(event) =>
                                  setNewStartDate(event.target.value)
                                }
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  min: minDate,
                                }}
                                sx={{
                                  "& input": {
                                    height: "3em", // Adjust this value to your desired height
                                    padding: "0", // Remove padding to avoid extra space inside the input
                                    fontSize: "1em",
                                    textIndent: "0.25em", // Add a small margin to the left of the text inside the input field
                                  },
                                  background: "#F8F8F8",
                                }}
                              />
                            </FormControl>
                            <FormControl
                              sx={{ m: 1, width: "15ch" }}
                              variant="outlined"
                            >
                              <TextField
                                label="End Date"
                                type="date"
                                id="newEndDate"
                                variant="outlined"
                                value={newEndDate}
                                onChange={(event) =>
                                  setNewEndDate(event.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  min: minDate,
                                }}
                                sx={{
                                  "& input": {
                                    height: "3em", // Adjust this value to your desired height
                                    padding: "0", // Remove padding to avoid extra space inside the input
                                    fontSize: "1em",
                                    textIndent: "0.25em", // Add a small margin to the left of the text inside the input field
                                  },
                                  background: "#F8F8F8",
                                }}
                              />
                            </FormControl>
                          </FormControl>
                          <FormControl
                            sx={{ m: 1, width: "15ch" }}
                            variant="outlined"
                          >
                            <TextField
                              label="Percent change"
                              type="number"
                              id="extraPercentageInput"
                              variant="outlined"
                              value={extraPercentageInput}
                              onChange={(event) =>
                                setExtraPercentageInput(event.target.value)
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="flex-end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              sx={{
                                "& input": {
                                  height: "3em", // Adjust this value to your desired height
                                  padding: "0", // Remove padding to avoid extra space inside the input
                                  fontSize: "1em",
                                  textIndent: "0.5em", // Add a small margin to the left of the text inside the input field
                                },
                                background: "#F8F8F8",
                              }}
                            />
                          </FormControl>
                          <FormControl
                            sx={{
                              m: 1,
                              width: "15ch",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              ml: {
                                lg: 0,
                                xs: "20vw",
                              },
                              gap: 1,
                            }}
                            variant="outlined"
                          >
                            <StyledButton onClick={handleAdjustClick}>
                              Adjust
                            </StyledButton>

                            <StyledButton onClick={resetUserPrediction}>
                              Adjust
                            </StyledButton>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Collapse>
                </>
              )}
            {/*Sales Prediction graph*/}
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 4}
            >
              <Grid
                // container
                // direction="row"
                // justifyContent="center"
                // spacing={1}
                // alignItems="center"
                lg={12}
                md={12}
                xs={12}
                sx={{
                  ...(counter === 4 && helpMode ? getHighlightedStyle() : {}),

                  borderRadius: "1rem",
                  marginBottom: "1rem",
                  boxShadow:
                    counter === 4 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  // outline: "1px solid red",
                  height: { lg: "80vh", xs: "40rem" },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {isFirstChartVisible ? (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: "80%",
                        // outline: "solid 1px green",
                      }}
                    >
                      <TitleStyle
                        tooltipKey="sales_prediction"
                        style={{ fontSize: "24px", mt: 1 }}
                        position="bottom"
                      >
                        Sales Prediction
                      </TitleStyle>

                      <Grid sx={{ height: "100%" }}>
                        {salesLoad ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <ChartLoading />
                          </Box>
                        ) : (
                          // Adjust with your actual loading component
                          <SalesPredLineChart
                            data={salesPredData}
                            isNotPercentage={true}
                            range={"day"}
                            isXSScreen={isXSScreen}
                            isCurrency={true}
                            // useKpiFormat={true}
                          />
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: "80%",
                        // outline: "solid 1px green",
                      }}
                    >
                      <TooltipCreate
                        tooltipKey="sales_prediction"
                        width="400px"
                        position="bottom"
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            margin: "1rem",
                            fontWeight: 500,
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                        >
                          Sales Prediction
                        </Typography>
                      </TooltipCreate>

                      <Grid sx={{ height: "100%" }}>
                        {load ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <ChartLoading />
                          </Box>
                        ) : (
                          <PointHourlyLineChart
                            data={combinedHourlyData}
                            isNotPercentage={true}
                            isMinimal={true}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    // height: "5rem",
                    // outline: "dashed 1px red",
                  }}
                >
                  <Typography variant="h7">Hourly</Typography>
                  <Switch
                    checked={isFirstChartVisible}
                    onChange={handleToggleChart}
                  />
                  <Typography variant="h7">Daily</Typography>
                </Box>
              </Grid>
            </Tooltip>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                // outline: "solid 1px blue",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  // mt: 3,
                  fontSize: {
                    xs: "1.2rem", // Smaller font size on extra-small screens
                    sm: "1.25rem", // Slightly larger font size on small screens
                    md: "1.5rem", // Default font size on medium and larger screens
                  },
                }}
                gutterBottom
              >
                Factors That Affect Predicted Sales
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  width: "100%",
                  mb: 3,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    color: "#515151",
                    fontSize: "16px",
                  }}
                >
                  Day
                </Typography>
                <Switch
                  checked={viewMode === "Category"}
                  onChange={(e) =>
                    setViewMode(e.target.checked ? "Category" : "Day")
                  }
                  name="viewModeToggle"
                  color="primary"
                />

                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    color: "#515151",
                    fontSize: "16px",
                  }}
                >
                  Category
                </Typography>
              </Box>
            </Box>

            {viewMode === "Day" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  height: { lg: "100vh", xs: "80rem" },
                  width: "100%",
                  // outline: "solid 1px red",
                }}
              >
                <Tooltip
                  arrow
                  title={tooltipContent[counter]}
                  open={helpMode && counter === 5}
                >
                  <Grid
                    // container

                    sx={{
                      ...(counter === 5 && helpMode
                        ? getHighlightedStyle()
                        : {}),
                      borderRadius: "1rem",
                      marginBottom: "1rem",
                      height: { lg: "100%", xs: "40rem" },
                      boxShadow:
                        counter === 5 && helpMode
                          ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                          : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                      background: "#FFF",
                      width: { lg: "50%", xs: "100%" },
                      // outline: "solid 1px red",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        height: { lg: "100%", xs: "100%" },
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",

                        // outline: "solid 1px blue",
                      }}
                      // sm={6} md={6} lg={6}
                    >
                      <TitleStyle
                        tooltipKey="factors"
                        style={{ fontSize: "24px" }}
                      >
                        Factors for a specific day
                      </TitleStyle>
                      <Box
                        // onClick={()=>{handleRedirect(diningData)}}
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // border: "solid 1px red",
                          }}
                        >
                          <FormControl
                            sx={{ m: 1, flexDirection: "row" }}
                            variant="contained"
                          >
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: Colors.krunch,
                                "&:hover": {
                                  backgroundColor: "#e3a902", // You can adjust this color based on your preference
                                  // Prevent MUI Button from changing the color on hover
                                  boxShadow: "none",
                                },
                                "&:active": {
                                  backgroundColor: "#e3a902", // Adjust this color based on your preference
                                },
                              }}
                              onClick={handlePreviousDay}
                            >
                              <ArrowBackIcon sx={{ color: "#645e29" }} />{" "}
                              {/* Left arrow icon */}
                            </Button>
                          </FormControl>

                          <FormControl
                            sx={{
                              m: 1,
                              flexDirection: "row",
                              ml: 1,
                            }}
                            variant="outlined"
                          >
                            <TextField
                              label="Select Date"
                              type="date"
                              id="selectDate"
                              value={barStartDate}
                              onChange={(event) => {
                                setBarStartDate(event.target.value);
                                setBarEndDate(event.target.value);
                              }}
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                "& input": {
                                  height: "3em", // Adjust this value to your desired height
                                  padding: "0", // Remove padding to avoid extra space inside the input
                                  fontSize: "1em",
                                  textIndent: "0.25em", // Add a small margin to the left of the text inside the input field
                                },
                              }}
                            />
                          </FormControl>

                          <FormControl
                            sx={{ m: 1, flexDirection: "row" }}
                            variant="outlined"
                          >
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: Colors.krunch,
                                "&:hover": {
                                  backgroundColor: "#e3a902", // You can adjust this color based on your preference
                                  // Prevent MUI Button from changing the color on hover
                                  boxShadow: "none",
                                },
                                "&:active": {
                                  backgroundColor: "#e3a902", // Adjust this color based on your preference
                                },
                              }}
                              onClick={handleNextDay}
                            >
                              <ArrowForwardIcon sx={{ color: "#645e29" }} />{" "}
                              {/* Right arrow icon */}
                            </Button>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          height: "80%",
                          width: "100%",
                          // border: "solid 1px green",
                          // mt: 2,
                        }}
                      >
                        <HolidayTable
                          restaurant={selectedRestaurant}
                          startDate={barStartDate}
                          endDate={barEndDate}
                          isXSScreen={isXSScreen}
                          selectedPage={selectedPage}
                        />
                        {/* )} */}
                      </Box>
                    </Box>
                  </Grid>
                </Tooltip>
                <Tooltip
                  arrow
                  title={tooltipContent[counter]}
                  open={helpMode && counter === 6}
                >
                  <Grid
                    // container

                    lg={6}
                    md={12}
                    xs={12}
                    sx={{
                      ...(counter === 6 && helpMode
                        ? getHighlightedStyle()
                        : {}),
                      height: { lg: "100%", xs: "35rem" },
                      borderRadius: "1rem",
                      marginBottom: "1rem",
                      boxShadow:
                        counter === 6 && helpMode
                          ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                          : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                      background: "#FFF",
                      overflowY: "auto",
                      width: { lg: "50%", xs: "100%" },

                      // border: "solid 1px red",
                    }}
                  >
                    <Grid
                      item
                      xs={12}

                      // sm={6} md={6} lg={6}
                    >
                      <Tooltip
                        title={
                          <Typography>
                            This table shows all factors that affect the sales
                            predictions. You can filter the table to focus on
                            factors for a specific restaurant, time period, type
                            of factor and more
                          </Typography>
                        }
                        arrow
                        TransitionComponent={Zoom}
                        placement="top"
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            margin: "1rem",
                            fontWeight: 500,
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: {
                              xs: "1.7rem",
                            },
                            // border: "solid red 1px",
                          }}
                        >
                          All Factors
                        </Typography>
                      </Tooltip>

                      <Box
                        sx={{
                          height: { lg: "50rem", xs: "35rem" },
                          overflowY: "auto",
                          overflowX: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",

                          // width: "90%",
                          // border: "solid 1px red",
                        }}
                      >
                        <HolidayParamsTable
                          restaurant={selectedRestaurant}
                          start_date={startDate}
                          end_date={endDate}
                          selectedPage={selectedPage}
                          // data={filteredData}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Tooltip>
              </Box>
            )}
            {viewMode === "Category" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  height: { lg: "80vh", xs: "80rem" },
                  width: "100%",
                  // outline: "solid 1px red",
                }}
              >
                <Tooltip
                  arrow
                  title={tooltipContent[counter]}
                  open={helpMode && counter === 5}
                >
                  <Grid
                    // container

                    lg={6}
                    md={12}
                    xs={12}
                    sx={{
                      ...(counter === 5 && helpMode
                        ? getHighlightedStyle()
                        : {}),
                      borderRadius: "1rem",
                      marginBottom: "1rem",
                      height: { lg: "100%", xs: "40rem" },
                      boxShadow:
                        counter === 5 && helpMode
                          ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                          : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                      background: "#FFF",
                      width: { lg: "50%", xs: "100%" },
                      // border: "solid 1px red",
                    }}
                  >
                    <TitleStyle
                      tooltipKey="events"
                      style={{ fontSize: "24px" }}
                    >
                      Events
                    </TitleStyle>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // border: "solid 1px red",
                        width: "100%",
                        height: "80%",
                      }}
                    >
                      <EventCategory
                        restaurant={selectedRestaurant}
                        start_date={startDate}
                        end_date={endDate}
                        type={"event"}
                        isXSScreen={isXSScreen}
                        holidayData={holidayData}
                        isLoading={isLoading}
                        selectedPage={selectedPage}
                      />
                    </Box>
                  </Grid>
                </Tooltip>
                <Tooltip
                  arrow
                  title={tooltipContent[counter]}
                  open={helpMode && counter === 6}
                >
                  <Grid
                    // container

                    lg={6}
                    md={12}
                    xs={12}
                    sx={{
                      ...(counter === 6 && helpMode
                        ? getHighlightedStyle()
                        : {}),
                      borderRadius: "1rem",
                      marginBottom: "1rem",
                      height: { lg: "100%", xs: "35rem" },
                      boxShadow:
                        counter === 6 && helpMode
                          ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                          : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                      background: "#FFF",
                      // outline: "solid 1px red",

                      width: { lg: "50%", xs: "100%" },
                    }}
                  >
                    <TitleStyle
                      tooltipKey="weather"
                      style={{ fontSize: "24px", mt: 1 }}
                    >
                      Weather
                    </TitleStyle>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // border: "solid 1px red",
                        width: "100%",
                        height: "80%",
                      }}
                    >
                      <Weather
                        restaurant={selectedRestaurant}
                        start_date={startDate}
                        end_date={endDate}
                        isXSScreen={isXSScreen}
                        holidayData={holidayData}
                        isLoading={isLoading}
                        selectedPage={selectedPage}
                      />
                    </Box>

                    {/* Recommended Employee Hours Graph */}
                  </Grid>
                </Tooltip>
              </Box>
            )}

            <Dialog onClose={handleAdjustClose} open={adjustOpen}>
              <DialogTitle
                id="customized-dialog-title"
                onClose={handleAdjustClose}
              >
                Adjust Prediction
              </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  {disableInput
                    ? additionalComments
                    : "Add an one sentence explanation for the change here (not required)"}
                </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  id="comment"
                  label="Comment"
                  type="text"
                  fullWidth
                  value={userComment}
                  onChange={(e) => setUserComment(e.target.value)}
                  inputProps={{ maxLength: 100 }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAdjustClose}>Cancel</Button>
                <Button
                  autoFocus
                  onClick={() => {
                    handleAddUserPrediction();
                    handleAdjustClose();
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openHelpDialog}
              onClose={() => setOpenHelpDialog(false)}
              aria-labelledby="help-dialog-title"
              aria-describedby="help-dialog-description"
            >
              <DialogTitle id="help-dialog-title">
                {"Help Information"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="help-dialog-description">
                  This help button is designed to provide you with additional
                  information and guidance on how to use the features of this
                  application. Clicking this button will display useful tips and
                  FAQs to help you navigate and make the most of the app's
                  functionalities.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenHelpDialog(false)}
                  color="primary"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <SnackbarAlert
            open={open}
            onClose={handleClose}
            severity={severity}
            message={message}
          />
          {helpMode && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                width: { lg: "180px", xs: "280px" },
                position: "fixed", // Make it absolute to position at the bottom
                bottom: 20,
                right: 20,
                padding: "10px",
                // border:"solid 1px red"
              }}
            >
              <NavigationButtons
                isXSScreen={isXSScreen}
                setHelpMode={setHelpMode}
                handlePrevious={handlePrevious}
                handleNext={handleNext}
                counter={counter}
                maxSteps={maxSteps}
              />
            </Box>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default SalesPrediction;
