import React, { useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import formatNumber from "../../utils/FormatNumber";
const latest_created_at = localStorage.getItem("latest_records");
const company = localStorage.getItem("company");

const HourlySlicesLineChart = ({
  data,
  isDetailedChart,
  isCombinedChart = false,
  isStaffChart = false,
  isNotPercentage = false,
  isSecondaryChart = false,
  secondaryData = null,
  range = null,
  isOverlapChart = false,
  isXSScreen = false,
  allowNegativeY = false,
  showZeroLine = false,
  isMinimal = false,
  singleTooltip = false,
  hasPredictionLine = false,
  predictionLineDate = null,
  isMinNonZero = false,
  isDailyData = false,
}) => {
  //SANDBOX
  //SANDBOX

  let newVar;
  let secondVar;
  let interval;
  const [hiddenIds, setHiddenIds] = useState([]);

  const format = !isNotPercentage ? (v) => `${v}%` : (v) => `${v} NOK`;
  if (!isDetailedChart) {
    newVar = [];
  }
  for (const property in data) {
    newVar.push(data[property]);
  }
  const lineData =
    newVar && Object.entries(newVar).length !== 0 ? [newVar] : [];
  if (isCombinedChart) {
    //combined chart
    newVar = newVar[0];
  }
  if (isDailyData) {
    interval = 2;
  } else {
    interval = isXSScreen ? 6 : 2;
  }
  let filteredData = newVar?.filter((serie) => !hiddenIds.includes(serie.id));



  const goalPercentage =
    company === "Fisketorget" ? 32 : company === "Burgerheim" ? 20 : 28;

  if (secondaryData) {
    secondVar = [];
    for (const property in secondaryData) {
      secondVar.push(secondaryData[property]);
    }
  }
  let minYValue;
  let maxYValue;
  if (newVar && newVar.length > 0) {
    minYValue = Math.min(
      ...newVar.map((series) =>
        Math.min(...series.data.map((point) => point.y))
      )
    );
    maxYValue = Math.max(
      ...newVar.map((series) =>
        Math.max(...series.data.map((point) => point.y))
      )
    );
  }
  if (isNotPercentage) {
    // For NOK
    minYValue = Math.round(minYValue / 10000) * 10000;
    maxYValue = Math.round(maxYValue / 10000) * 10000;
  } else {
    minYValue = Math.round(minYValue / 10) * 10;
    maxYValue = Math.round(maxYValue / 10) * 10;
  }

  const axisProps = !isSecondaryChart
    ? {
        axisLeft: {
          format,
          legendOffset: 12,
          tickValues: [minYValue, goalPercentage, maxYValue], // Add goalPercentage to tickValues
        },
        colors: [
          "#70335D",
          "#4169E1",
          "#bcbd22",
          "#d62728",
          "#9467bd",
          "#8c564b",
          "#e377c2",
          "#7f7f7f",
        ],
        sliceTooltip: ({ slice }) => {
          const point = slice.points[0]; // Directly access the hovered point
          const date = new Date(point.data.x);
          const formattedDate =
            `${date.toLocaleString("default", { weekday: "long" })}, ` + // Adds weekday
            `${String(date.getDate()).padStart(2, "0")} ` +
            `${date.toLocaleString("default", { month: "short" })}, ` +
            `${date.getFullYear()} ` + // Adds the year
            `${String(date.getHours()).padStart(2, "0")}:` +
            `${String(date.getMinutes()).padStart(2, "0")}`;

          return (
            <div
              style={{
                background: "white",
                padding: "10px",
                borderRadius: "2px",
              }}
            >
              <strong>{formattedDate}</strong>
              <br />
              {slice.points.map((point) => (
                <strong key={point.id} style={{ color: point.serieColor }}>
                  {point.serieId}:
                  {isNotPercentage
                    ? new Intl.NumberFormat("nb-NO", {
                        style: "currency",
                        currency: "NOK",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(point.data.y)
                    : ` ${point.data.y}%`}
                  <br />
                  {point.data.comment && (
                    <strong style={{ color: "black" }}>
                      Comment: {point.data.comment}
                      <br />
                    </strong>
                  )}
                </strong>
              ))}
            </div>
          );
        },
      }
    : {
        colors: "#17CB1B",
        axisRight: {
          format,
          legendOffset: 12,
          tickValues: [minYValue, maxYValue],
        },
        sliceTooltip: ({ slice }) => {
          const currentDate = new Date(slice.points[0].data.x);
          let formattedDate =
            `${currentDate.toLocaleString("default", { weekday: "long" })}, ` +
            `${String(currentDate.getDate()).padStart(2, "0")} ` +
            `${currentDate.toLocaleString("default", { month: "short" })}, ` +
            `${currentDate.getFullYear()} ` +
            `${String(currentDate.getHours()).padStart(2, "0")}:` +
            `${String(currentDate.getMinutes()).padStart(2, "0")}`;

          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                borderRadius: "5px",
                boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
              }}
            >
              <strong>{formattedDate}</strong>
              {slice.points.map((point) => {
                const secondarySeries = secondVar.find(
                  (s) => s.id === point.serieId
                );
                const matchedData = secondarySeries?.data.find(
                  (d) =>
                    new Date(d.x).getTime() === new Date(point.data.x).getTime()
                );
                const secondaryYValue = matchedData ? matchedData.y : null;
                return (
                  <div
                    key={point.id}
                    style={{
                      color: "#17CB1B",
                      padding: "3px 0",
                    }}
                  >
                    <strong>
                      {point.serieId}:{" "}
                      {formatNumber(parseInt(point.data.yFormatted))}
                    </strong>
                    {secondaryYValue !== null ? (
                      <div
                        style={{
                          color: "#70335D",
                          padding: "3px 0",
                        }}
                      >
                        <strong>Staff Cost: {secondaryYValue}%</strong>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          );
        },
      };

  return !lineData || lineData.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    <ResponsiveLine
      animate
      enableGridX={false}
      enableGridY={isMinimal ? true : !isSecondaryChart}
      gridYValues={[minYValue, goalPercentage, maxYValue]} // Add goalPercentage to gridYValues
      axisBottom={{
        format: (value) => {
          const date = new Date(value);
          return date.toLocaleTimeString([], {
            hour: "2-digit",
            hour12: false,
          });
        },
        tickValues: `every ${interval} hours`,
      }}
      {...axisProps}
      axisLeft={
        !isSecondaryChart
          ? {
              tickValues: [minYValue, goalPercentage, maxYValue], // Add goalPercentage to tickValues
              format: isNotPercentage ? undefined : (v) => `${v}%`,
            }
          : null
      }
      curve="monotoneX"
      data={filteredData}
      margin={
        isMinimal
          ? {
              bottom: 30,
              left: 50,
              right: 50,
              top: 50,
            }
          : isStaffChart
          ? {
              bottom: 60,
              left: 50,
              right: 80,
              top: 30,
            }
          : {
              bottom: 30,
              left: 80,
              right: 20,
              top: 60,
            }
      }
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      lineWidth={1}
      markers={[
        // Remove the goal marker configuration
        // Add the marker for todays date here
        ...(isStaffChart && !isSecondaryChart
          ? [
              {
                axis: "y",
                value: goalPercentage,
                // legend: `Goal ${goalPercentage}%`,
                lineStyle: {
                  stroke: "#b877d9",
                  strokeWidth: 2,
                },
              },
            ]
          : []),
        hasPredictionLine
          ? {
              axis: "x", // Vertical line
              value: new Date(latest_created_at),
              lineStyle: {
                stroke: "#505050", // Line color
                strokeWidth: 2, // Line width
              },
              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
      ]}
      pointBorderWidth={1}
      pointSize={4}
      enableArea={true}
      areaBaselineValue={isMinNonZero ? minYValue : 0}
      areaOpacity={0.15}
      enableSlices="x"
      labelFormat={format}
      useMesh
      xScale={{
        type: "time",
        format: "%Y-%m-%d %H:%M",
        precision: "hour",
        useUTC: false,
      }}
      yScale={{
        type: "linear",
        min: allowNegativeY ? "auto" : 0,
        max: isStaffChart & (maxYValue < 120) ? 120 : "auto", // Set the calculated maxY as the max property
      }}
      legends={
        isMinimal
          ? []
          : [
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: isSecondaryChart ? 150 : 2,
                translateY: 45,
                itemsSpacing: 10,
                itemDirection: "left-to-right",
                itemWidth: 109,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
                onClick: (datum) => {
                  setHiddenIds((currentHiddenIds) => {
                    const isCurrentlyHidden = currentHiddenIds.includes(
                      datum.id
                    );
                    const newHiddenIds = isCurrentlyHidden
                      ? currentHiddenIds.filter((id) => id !== datum.id)
                      : [...currentHiddenIds, datum.id];

                    if (newHiddenIds.length >= newVar.length) {
                      return [];
                    }
                    return newHiddenIds;
                  });
                },
              },
            ]
      }
    />
  );
};

export default HourlySlicesLineChart;
