import React from "react";
import { Box, IconButton, Button } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const CustomButton = ({
  icon: Icon,
  label,
  handleClick,
  isBarExpanded,
  clickedIcon,
  currentIcon,
  isCollapsed,
  isParent,
  notShowDropdown,
}) => {
  const isActive = clickedIcon === currentIcon;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        margin: "6px 0",
        // outline: "solid red 1px",
      }}
    >
      {!isBarExpanded ? (
        <IconButton
          onClick={handleClick}
          sx={{
            color: "#645e29",
            display: "flex",
            backgroundColor: isActive && !isCollapsed ? "white" : "none",
            border: isActive && !isParent ? "2px solid #645e29" : "none",
          }}
        >
          <Icon />
        </IconButton>
      ) : (
        <Button
          onClick={handleClick}
          sx={{
            color: "#645e29",
            display: "flex",
            fontWeight: "bold",
            backgroundColor: isActive && !isCollapsed ? "white" : "none",
            fontSize: "12px",
            justifyContent: "flex-start",
            border: isActive && !isParent ? "2px solid #645e29" : "none",
          }}
          startIcon={<Icon />}
          endIcon={
            isCollapsed || notShowDropdown ? null : <ArrowDropDownIcon />
          }
        >
          {label}
        </Button>
      )}
    </Box>
  );
};

export default CustomButton;
