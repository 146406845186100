import React from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import getHighlightedButtonStyle from './HighlightedButton';
const NavigationButtons = ({ isXSScreen, setHelpMode, handlePrevious, handleNext, counter, maxSteps }) => {

  return (
    <>
      {isXSScreen && (
        <Button
          onClick={() => setHelpMode(false)}
          sx={{ ...getHighlightedButtonStyle(), zIndex: 1000 }}
          startIcon={<CloseIcon />}
        >
          Close
        </Button>
      )}
      <Button
        onClick={handlePrevious}
        disabled={counter === 1}
        startIcon={<ArrowBackIcon />}
        sx={{ ...getHighlightedButtonStyle(), zIndex: 1000 }}
        >
        Back
      </Button>

      <Button
        onClick={handleNext}
        disabled={counter === maxSteps}
        endIcon={counter === maxSteps-1 ? <CheckIcon /> : <ArrowForwardIcon />}
        sx={{ ...getHighlightedButtonStyle(), zIndex: 1000 }}
        >
        {counter === maxSteps - 1 ? "Done" : "Next"}
      </Button>
    </>
  );
};

export default NavigationButtons;
