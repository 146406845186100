import { React, useState, useEffect, useCallback } from "react";
import {
  //   InputLabel,
  Box,
  Grid,
  Link,
  //   Checkbox,
  //   FormControlLabel,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Typography,
  // Container,
  //   Select,
  //   MenuItem,
  //   FormControl,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import KPILoad from "../components/Loading/KPILoad";
// import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../utils/SnackbarAlert";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://krunch.no/">
        Krunch
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
let prefix = "";
if (process.env.NODE_ENV === "development") {
  prefix = "http://localhost:8000";
}

const theme = createTheme();

const Profile = () => {
  // const [employeeGroups, setEmployeeGroups] = React.useState([]);
  const [birthdate, setBirthdate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  const [emp, setEmp] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  // const { id } = useParams();

  const handleClose = () => {
    setOpen(false);
  };

  const fetchUser = useCallback(
    async (userId) => {
      setIsLoading(true);
      const user_response = await fetch(`${prefix}/api/users/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      const user_data = await user_response.json();
      setEmp(user_data);
      // console.log(user_data.birthdate)
      const adjustedDate = dayjs(user_data.birthdate);
      const originalDate = adjustedDate.subtract(
        adjustedDate.utcOffset(),
        "minute"
      );
      const formattedDate = originalDate.toDate();

      setSelectedDate(dayjs(formattedDate));
      setIsLoading(false);
    },
    [token]
  );

  useEffect(() => {
    fetchUser(user.id);
  }, [user.id, fetchUser]);

  const handleEmpChange = (e) => {
    setEmp({ ...emp, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      email: emp.email,
      first_name: emp.first_name,
      last_name: emp.last_name,
      birthdate: birthdate || null,
      phone: emp.phone,
      // employee_group: emp.employee_group || null,
      company_admin: emp.company_admin ? true : false,
    };
    const method = "PUT";
    const url = `${prefix}/api/users/${user.id}/`;
    const response = await fetch(url, {
      method: method,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    //Several things wrong here got to change the response in backend
    if (response.ok) {
      if (!response.error) {
        const responseData = await response.json();
        // Here, remove the current 'user' data from localStorage before updating it
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("company");

        const user = JSON.stringify(responseData);
        localStorage.setItem("user", user);
        window.location.href = "/";

        setOpen(true);
        setSeverity("success");
        setMessage("Profile Updated Successfully");
      } else {
        setOpen(true);
        setSeverity("error");
        setMessage("Error during profile update");
      }
    } else {
      setOpen(true);
      setSeverity("error");
      setMessage("Server Error");
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    const birthdate = dayjs(date.$d);
    const adjustedDate = birthdate.add(birthdate.utcOffset(), "minute");
    setBirthdate(adjustedDate.format("YYYY-MM-DD"));
  };
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            width: "100%",
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              maxWidth: { lg: "600px", xs: "400px" },
              boxShadow: "0px 5px 5px 3px rgba(0,0,0,0.12)", // Adds shadow to the form box
              borderRadius: 2, // Rounded corners for the form box
              backgroundColor: "background.paper",
              maxHeight: "800px",

              // border: "1px solid red",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                maxWidth: { lg: "600px", xs: "400px" },
                padding: 4,
                height: "700px",
                // boxShadow: "0 3px 5px 2px rgba(0,0,0,0.12)", // Adds shadow to the form box
                borderRadius: 2, // Rounded corners for the form box
                backgroundColor: "background.paper",
                // border: "solid red 1px",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "#CC9600" }}>
                <PermIdentityIcon />
              </Avatar>
              <Typography
                variant="h5"
                sx={{ mb: 2, fontFamily: "Montserrat", fontWeight: 600 }}
              >
                My Profile
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="first_name"
                      required
                      fullWidth
                      id="first_name"
                      label="First Name"
                      autoFocus
                      value={isLoading ? "" : emp.first_name || ""}
                      onChange={handleEmpChange}
                      InputProps={{
                        endAdornment: isLoading ? <KPILoad /> : null,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "10px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="last_name"
                      label="Last Name"
                      name="last_name"
                      autoComplete="family-name"
                      value={isLoading ? "" : emp.last_name || ""}
                      onChange={handleEmpChange}
                      InputProps={{
                        endAdornment: isLoading ? <KPILoad /> : null,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "10px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={isLoading ? "" : emp.email || ""}
                      onChange={handleEmpChange}
                      InputProps={{
                        endAdornment: isLoading ? <KPILoad /> : null,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "10px",
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      required
                      fullWidth
                      name="birthdate"
                      label="Fødselsdato"
                      id="birthdate"
                      // defaultValue={formData.first_name}
                      onChange={(value) => handleDateSelect(value)}
                      value={selectedDate}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="phone"
                      type="number"
                      label="Telefonnummer"
                      id="phone"
                      value={isLoading ? "" : emp.phone || ""}
                      onChange={handleEmpChange}
                      InputProps={{
                        endAdornment: isLoading ? <KPILoad /> : null,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "10px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                  <InputLabel>Employee Group</InputLabel>
                  <Select
                    required
                    fullWidth
                    name="employee_group"
                    defaultValue=""
                    onChange={handleEmpChange}
                    value={emp.employee_group || ""}
                  >
                    {employeeGroups.map((emp) => (
                      <MenuItem key={emp.id} value={emp.id}>
                        {emp.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid> */}
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    width: "6rem",
                    backgroundColor: "#FFBD00",
                    color: "#645e29",
                    mt: 3,
                    mb: 2,
                    "&:hover": {
                      backgroundColor: "#e3a902",
                      boxShadow: "none",
                    },
                    "&:active": {
                      backgroundColor: "#e3a902",
                    },
                  }}
                >
                  Update
                </Button>
              </Box>
            </Box>
            {/* <Box
              sx={{
                // marginTop: 2,
                display: { xs: "none", md: "flex" }, // Hide on xs, show on md+
                flexDirection: "row",
                alignItems: "flex-end",
                width: "100%",
                maxWidth: { lg: "600px", xs: "400px" },
                // boxShadow: "0 3px 5px 2px rgba(0,0,0,0.12)", // Adds shadow to the form box
                backgroundColor: "background.paper",
                height: "100%",
                borderRadius: 2,
                // border: "10px solid #FFBD00",
                // backgroundClip: "padding-box",

                // border: "1px solid red",
              }}
            >
              <Box
                sx={{
                  width: "100%", // Image takes roughly the other half
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",

                  height: "100%",
                }}
              >
                <img
                  src="userprofile.jpg" // Replace with your image path
                  alt="Descriptive Alt Text"
                  style={{
                    width: "100%",
                    height: "700px",
                    // borderRadius: "4px",
                  }}
                />
              </Box>
            </Box> */}
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Box>
        <SnackbarAlert
          open={open}
          onClose={handleClose}
          severity={severity}
          message={message}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default Profile;
