import React from "react";
import { ResponsiveLine } from "@nivo/line";
// import { getCompanyColors } from "../CompanyUtils/CompanyColor";
// import formatNumber from "../../utils/FormatNumber";
import formatKpiNumber from "../../utils/formatKpiNumber";
import formatNumber from "../../utils/FormatNumber";
// const latest_created_at = localStorage.getItem("latest_records");
const company = localStorage.getItem("company");

const GrossProfitHourlyLineChart = ({
  data,
  startDate,
  endDate,
  // isCombinedChart = false,
  isDailyData = false,
  isNotPercentage = false,
  isMinimal = false,
  isXSScreen = false,
  hasPredictionLine = false,
  predictionLineDate = null,
  isMultiRestaurant = false,
  isStaffChart = false,
  isEmployeeChart = false,
  isProfitGraph = false,
  useKpiFormat = false,
  isOverlapChart = false,
  Latest = null,
  hasPredictionData = false,
}) => {


  const format = !isNotPercentage ? (v) => `${v}%` : (v) => `${v}`;
  let interval;
  let newVar = data ? data : null;
  if (isMultiRestaurant) {
    newVar = [];
    for (const property in data) {
      newVar.push(data[property]);
    }
  }
  if (isDailyData) {
    interval = 2;
  } else {
    interval = isXSScreen ? 6 : 2;
  }


  // const colors = getCompanyColors();
  const goalPercentage = company === "Fisketorget" ? 32 : 28;

  if (newVar && newVar.length > 0) {
    newVar = newVar.map((series) => {
      return {
        ...series,
        data: series.data.map((point) => {
          return {
            ...point,
            y: isNaN(point.y) ? 0 : point.y,
          };
        }),
      };
    });
  }

  function processData(data, latestDate) {
    const latestDateTime = new Date(latestDate); // Retain the hour in the latest date
    latestDateTime?.setMinutes(0, 0, 0); // Set minutes, seconds, and milliseconds to zero while keeping the hour unchanged
    // latestDateTime.setHours(latestDateTime.getHours() + 1); // Add one hour to the current hour


    return data?.map((dataset) => {
      const isHistorical = dataset.id.includes("Historical");
      const isPrediction = dataset.id.includes("Prediction");

      const processedData = dataset.data.map((entry) => {
        const entryDateTime = new Date(entry.x); // Retain the hour in the entry date

        // For historical data, nullify 'y' for entries after the 'latestDateTime'
        // For prediction data, nullify 'y' for entries strictly before the 'latestDateTime'
        if (
          (isHistorical && entryDateTime > latestDateTime) ||
          (isPrediction && entryDateTime < latestDateTime)
        ) {
          return { ...entry, y: null };
        }

        return entry;
      });

      return { ...dataset, data: processedData };
    });
  }

  // const dateEnd = new Date(endDate);
  // const latestCompare = new Date(Latest);
  // function isBefore(date1, date2) {
  //   const dateOnly1 = new Date(
  //     date1.getFullYear(),
  //     date1.getMonth(),
  //     date1.getDate()
  //   );
  //   const dateOnly2 = new Date(
  //     date2.getFullYear(),
  //     date2.getMonth(),
  //     date2.getDate()
  //   );
  //   return dateOnly1 < dateOnly2;
  // }

  // const actualPred = isBefore(dateEnd, latestCompare);
  // console.log(actualPred, "xdd");

  // const lolData =
  //   hasPredictionData && !actualPred ? processData(newVar, Latest) : newVar;

  const filteredData = hasPredictionData ? processData(newVar, Latest) : newVar;
  // const filteredData = newVar;

  let minYValue;
  let maxYValue;
  if (newVar && newVar.length > 0) {
    // Extract all y-values from all data series and filter out NaN values
    const allYValues = newVar.flatMap((series) =>
      series.data.map((point) => point.y).filter((y) => !isNaN(y))
    );
    // Check if there are any valid values left after filtering
    if (allYValues.length > 0) {
      // Find the minimum and maximum y-values from the filtered array
      minYValue = Math.min(...allYValues);
      maxYValue = Math.max(...allYValues);
      if (minYValue === maxYValue) {
        minYValue = 0;
      }
    } else {
      // Default values if no valid data is available
      minYValue = 0;
      maxYValue = 0;
    }
  } else {
    // Default values if no data is available
    minYValue = 0;
    maxYValue = 0;
  }

  minYValue = minYValue.toFixed(0);
  maxYValue = maxYValue.toFixed(0);

  if (maxYValue < 10) {
  } else if (isNotPercentage) {
    maxYValue =
      maxYValue < 1000
        ? Math.round(maxYValue / 100) * 100
        : maxYValue < 9000
        ? Math.round(maxYValue / 1000) * 1000
        : Math.round(maxYValue / 10000) * 10000;
  } else {
    // For percentages or other units
    maxYValue = Math.round(maxYValue / 10) * 10; // Adjust rounding logic as needed
  }

  let predictionLineMarkerDate;
  if (hasPredictionLine && Latest) {
    predictionLineMarkerDate = new Date(Latest);
    // Set the time to midnight to align with the graph points
    predictionLineMarkerDate?.setHours(0, 0, 0, 0);
  }
  const pointTooltip = ({ point }) => {
    const date = new Date(point.data.x);
    const formattedDate =
      `${date.toLocaleString("default", { weekday: "long" })}, ` + // Adds weekday
      `${String(date.getDate()).padStart(2, "0")} ` +
      `${date.toLocaleString("default", { month: "short" })}, ` +
      `${date.getFullYear()} ` + // Adds the year
      `${String(date.getHours()).padStart(2, "0")}:` +
      `${String(date.getMinutes()).padStart(2, "0")}`;

    // Convert latest_created_at to a Date object and retain its original hour
    const latestCreatedAtDate = new Date(Latest);

    // Convert point date to Date object for comparison
    const pointDate = new Date(point.data.x);

    // Determine whether the tooltip should display "Historical" or "Prediction"
    let tooltipTitle;
    // Check if the chart is a staff chart and if the point's date and hour is before or equal to the latest created date and hour
    if (
      isOverlapChart &&
      pointDate.getTime() <= latestCreatedAtDate.getTime()
    ) {
      tooltipTitle = "Historical";
    } else {
      tooltipTitle = "Prediction";
    }


    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
        }}
      >
        <strong>{formattedDate}</strong>
        <br />
        {point.data.comment && (
          <strong style={{ color: "black", marginBottom: "10px" }}>
            Comment: {point.data.comment}
            <br />
          </strong>
        )}

        {/* Conditional rendering for Net Profit, Gross Profit at the bottom */}
        {(point.serieId === "Gross Profit II Historical" ||
          point.serieId === "Gross Profit II Prediction") && (
          <>
            <strong style={{ color: "gray", marginBottom: "5px" }}>
              Employee Cost:{" "}
              {isNotPercentage
                ? formatKpiNumber(point.data.emp_cost)
                : point.data.emp_cost}
              <br />
              Third Party Delivery Cost:{" "}
              {isNotPercentage
                ? formatKpiNumber(point.data.delivery_cost)
                : point.data.delivery_cost}
              <br />
              {/* Rent:{" "}
              {isNotPercentage
                ? new Intl.NumberFormat("nb-NO").format(point.data.rent)
                : point.data.rent}
              <br />
              Other Fixed Cost:{" "}
              {isNotPercentage
                ? new Intl.NumberFormat("nb-NO").format(point.data.fixed_cost)
                : point.data.fixed_cost} */}
              {/* <br /> */}
            </strong>
            <strong style={{ color: point.serieColor }}>
              {point.serieId}:{" "}
              {isNotPercentage
                ? formatKpiNumber(Math.round(point.data.y))
                : `${point.data.y}`}
            </strong>
            <br />
          </>
        )}
        {(point.serieId === "Gross Profit I Historical" ||
          point.serieId === "Gross Profit I Prediction") && (
          <>
            <strong style={{ color: "gray" }}>
              Cost of Goods:{" "}
              {isNotPercentage
                ? formatKpiNumber(point.data.cost)
                : point.data.cost}
              <br />
            </strong>
            <strong style={{ color: point.serieColor }}>
              {point.serieId}:{" "}
              {isNotPercentage
                ? formatKpiNumber(Math.round(point.data.y))
                : `${point.data.y}`}
            </strong>
            <br />
          </>
        )}
        {point.serieId !== "Gross Profit II Historical" &&
          point.serieId !== "Gross Profit I Historical" &&
          point.serieId !== "Gross Profit II Prediction" &&
          point.serieId !== "Gross Profit I Prediction" && (
            <strong
              style={{
                color:
                  tooltipTitle === "Historical" ? "#543c8c" : point.serieColor,
                marginTop: "10px",
              }}
            >
              {point.serieId}:{" "}
              {isNotPercentage
                ? useKpiFormat
                  ? formatNumber(Math.round(point.data.y))
                  : isOverlapChart
                  ? `${formatNumber(Math.round(point.data.y))} ${tooltipTitle}`
                  : formatKpiNumber(Math.round(point.data.y))
                : isOverlapChart
                ? `${point.data.y}% ${tooltipTitle}`
                : `${point.data.y}%`}
            </strong>
          )}
      </div>
    );
  };

  const yScaleConfig = isProfitGraph
    ? {
        type: "linear",
        min: isEmployeeChart
          ? 0
          : !isNotPercentage && minYValue < -100
          ? -100
          : isStaffChart
          ? 0
          : "auto",
        max: "auto",
        reverse: false,
      }
    : {
        tickValues: [0, minYValue, maxYValue],
        min: isEmployeeChart
          ? 0
          : !isNotPercentage && minYValue < -100
          ? -100
          : isStaffChart
          ? 0
          : "auto",
        max: isStaffChart ? 70 : "auto",
        type: "linear",
      };

  return !newVar || newVar.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    <ResponsiveLine
      animate
      enableGridX={!isMinimal}
      enableGridY={isMinimal}
      gridYValues={isProfitGraph ? undefined : [0, minYValue, maxYValue]}
      axisBottom={{
        format: (value) => {
          const date = new Date(value);
          // Using toLocaleTimeString to format the time in 12-hour format
          return date.toLocaleTimeString([], {
            hour: "2-digit",
            // minute: "2-digit",
            hour12: false, // Ensures 12-hour format
          });
        },
        tickValues: `every ${interval} hours`,
      }}
      yScale={yScaleConfig}
      axisLeft={{
        ...(isProfitGraph
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: "middle",
            }
          : {
              tickValues: [0, minYValue, isStaffChart ? 70 : maxYValue],
              format,
            }),
      }}
      curve="monotoneX"
      data={filteredData}
      margin={
        isMinimal
          ? {
              bottom: 40,
              left: maxYValue > 100000 ? 60 : 60,
              right: 50,
              top: 40,
            }
          : {
              bottom: 60,
              left: 80,
              right: 20,
              top: 20,
            }
      }
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      colors={{ datum: "color" }}
      lineWidth={1}
      pointBorderWidth={1}
      pointSize={4}
      tooltip={pointTooltip}
      enableArea={true}
      areaBaselineValue={0}
      areaOpacity={0.15}
      // labelFormat={format}
      useMesh
      markers={[
        ...(isStaffChart && !isXSScreen
          ? [
              {
                axis: "y",
                value: goalPercentage,
                legend: `Goal ${goalPercentage}%`,
                lineStyle: {
                  stroke: "gray",
                  strokeWidth: 2,
                },
              },
            ]
          : []),
        // Add the marker for todays date here
        hasPredictionData && hasPredictionLine
          ? {
              axis: "x", // Vertical line
              // legend: "prediction marker",
              value: new Date(Latest),
              lineStyle: {
                stroke: "#505050", // Line color
                strokeWidth: 2, // Line width
              },

              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
      ]}
      xScale={{
        type: "time",
        format: "%Y-%m-%d %H:%M",
        precision: "hour",
        useUTC: false,
      }}
      legends={
        // isMinimal
        //   ? []
        //   :
        [
          //   {
          //     anchor: "bottom",
          //     direction: "row",
          //     justify: false,
          //     translateX: 2,
          //     translateY: 45,
          //     itemsSpacing: 10,
          //     itemDirection: "left-to-right",
          //     itemWidth: 109,
          //     itemHeight: 20,
          //     itemOpacity: 0.75,
          //     symbolSize: 12,
          //     symbolShape: "circle",
          //     symbolBorderColor: "rgba(0, 0, 0, .5)",
          //     effects: [
          //       {
          //         on: "hover",
          //         style: {
          //           itemBackground: "rgba(0, 0, 0, .03)",
          //           itemOpacity: 1,
          //         },
          //       },
          //     ],
          //     // onClick: (datum) =>
          //     //   setActiveId(activeId === datum.id ? null : datum.id), // Add this line
          //     onClick: (datum) => {
          //       setHiddenIds((currentHiddenIds) => {
          //         const isCurrentlyHidden = currentHiddenIds.includes(datum.id);
          //         const newHiddenIds = isCurrentlyHidden
          //           ? currentHiddenIds.filter((id) => id !== datum.id)
          //           : [...currentHiddenIds, datum.id];
          //         // Ensure we don't hide all lines: if newHiddenIds length is equal to the number of lines, reset it
          //         if (newHiddenIds.length >= newVar.length) {
          //           return [];
          //         }
          //         return newHiddenIds;
          //       });
          //     },
          //   },
        ]
      }
    />
  );
};

export default GrossProfitHourlyLineChart;
