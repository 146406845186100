import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { RoundValue } from "../../utils/RoundValue";
const latest_created_at = localStorage.getItem("latest_records");

const ProdBarChart = ({
  data,
  isDetailedChart,
  range,
  isXSScreen = false,
  isMinimal = false,
  hasDifferentColor = false,
  isPercentage = false,
  isCurrency = false,
  dataRange = false,
  hasPredictionLine = false,
}) => {
  const format = isPercentage
    ? (v) => `${v}%`
    : isCurrency
    ? (v) => `${v} NOK`
    : (v) => `${v} units`;

  const colors = [
    // "#E63946",
    // "#40E0D0",
    // "#4169E1",
    // "#bcbd22",
    // "#2ca02c",
    // "#d62728",
    // "#9467bd",
    // "#8c564b",
    // "#e377c2",
    // "#7f7f7f",
    // "#17becf",
    // "#11E8DA", // Turquoise
    "#BA43B4", // Fuchsia
    // "#111052", // Dark Blue
    // "#2BC81C", // Bright Green
    // "#530C8E", // Purple
    // "#0970F6", // Bright Blue
    // "#6755DA", // Medium Purple
    // "#DD7C6C", // Salmon
    // "#B2A30B", // Olive
    "#563C8A", // Indigo
    // "#A3E4E8", // Light Blue

    "#E191B1", // Light Pink
    "#B783FD", // Lavender
    "#E5B6DE", // Pale Pink
    "#70335D", // Dark Plum

    // "#65D822", // Lime Green

    "#E390AA", // Pink
    "#C0C524", // Olive Green
    "#2A5F8B", // Steel Blue

    "#9FCAEB", // Sky Blue
    "#B69AA7", // Muted Pink
    "#0199C6", // Blue

    // "#E8BD42", // Mustard Yellow
    "#AA26FE", // Bright Purple
    "#55627B", // Slate Grey
    "#91FAE0", // Turquoise
    "#2FEBAA", // Mint Green
    "#B42DA3", // Magenta

    "#05E6C4", // Bright Turquoise
  ];

  // const formatTick = (value) => {
  //   if (range === "hour") {
  //     // Assuming your date is in a format that can be directly passed to the Date constructor
  //     const date = new Date(value);
  //     // Format the date to just show the time
  //     return date.toLocaleTimeString([], {
  //       hour: "2-digit",
  //       minute: "2-digit",
  //     });
  //   } else {
  //     // Return the value as is for other ranges
  //     return value;
  //   }
  // };
  // const multiplier = range === "week" ? 7 : range === "month" ? 30 : 1;

  const getBarTickValues = (data, range, isXSScreen) => {
    const allDates = data.map((d) => d.date);
    let tickValues = [];
    const numTicks = 7; // Define numTicks here

    if (range === "hour") {
      const totalDates = allDates.length;
      // Ensure there's at least one tick and avoid division by zero
      if (totalDates > numTicks) {
        const step = Math.floor(totalDates / (numTicks - 1));
        for (let i = 0; i < totalDates; i += step) {
          tickValues.push(allDates[i]);
        }
        // Ensure the last value is included
        if (tickValues[tickValues.length - 1] !== allDates[totalDates - 1]) {
          tickValues.push(allDates[totalDates - 1]);
        }
      } else {
        // If there are fewer dates than the number of desired ticks, show all dates
        tickValues = [...allDates];
      }
    } else if (isXSScreen) {
      // For small screens, get the start, middle, and end dates
      tickValues = [
        allDates[0],
        allDates[Math.floor(allDates.length / 2)],
        allDates[allDates.length - 1],
      ];
    } else {
      // For larger screens or other ranges, divide the dates into 6 segments and get them
      for (let i = 0; i < numTicks; i++) {
        const index = Math.floor((allDates.length - 1) * (i / (numTicks - 1)));
        tickValues.push(allDates[index]);
      }
    }
    return tickValues;
  };

  let bar_keys = [];

  data.forEach((data) => {
    const all_keys = Object.keys(data);
    all_keys.forEach((key) => {
      if (key !== "date" && key !== "Color" && !bar_keys.includes(key)) {
        bar_keys.push(key);
      }
    });
  });

  let predictionLineMarkerDate;
  if (hasPredictionLine && latest_created_at) {
    predictionLineMarkerDate = new Date(latest_created_at);
    // Set the time to midnight to align with the graph points
    predictionLineMarkerDate.setHours(0, 0, 0, 0);
  }
  const EmpCustomTooltip = ({ data }) => {
    // Create a new Date object
    const tooltipDate = new Date(data.date);

    // An array of weekday names
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // An array of month names
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get the weekday name, month name, day, and year
    const weekdayName = weekdays[tooltipDate.getDay()];
    const monthName = months[tooltipDate.getMonth()];
    const day = tooltipDate.getDate();
    const year = tooltipDate.getFullYear();

    // Construct the formatted date string
    const formattedDate = `${weekdayName}, ${monthName} ${day}, ${year}`;

    return (
      <div
        style={{
          background: "white",
          padding: "5px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        <strong>{formattedDate}</strong>
        <br />
        <span>{data.Values}</span>
      </div>
    );
  };

  const CustomTooltip = ({ id, value, color, indexValue, data }) => {
    const keys = Object.keys(data);
    let sales = 0;
    let percentages = [];

    for (const key of keys) {
      if (key !== "date") {
        sales += parseInt(data[key], 10);
      }
    }

    for (const key of keys) {
      if (key !== "date") {
        const percentage = parseInt(
          (parseInt(data[key], 10) / sales) * 100,
          10
        );
        if (percentage > 0) {
          // Only push if percentage is greater than 0
          percentages.push({ [key]: percentage });
        }
      }
    }
    let formattedDate;
    if (dataRange === "today") {
      const date = new Date(data.date);
      formattedDate =
        `${date.toLocaleString("default", { weekday: "long" })}, ` + // Adds weekday
        `${String(date.getDate()).padStart(2, "0")} ` +
        `${date.toLocaleString("default", { month: "long" })}, ` + // Adds month
        `${date.getFullYear()} ` + // Adds the year
        `${String(date.getHours()).padStart(2, "0")}:` + // Adds hours
        `${String(date.getMinutes()).padStart(2, "0")}`; // Adds minutes
      // Use formattedDate as needed
    } else if (range === "month") {
      const date = new Date(data.date);
      formattedDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
      });
    } else if (range === "week") {
      const date = new Date(data.date);
      const startOfYear = new Date(date.getFullYear(), 0, 1);
      const daysSinceStartOfYear = Math.floor(
        (date - startOfYear) / (24 * 60 * 60 * 1000)
      );
      const weekNumber = Math.ceil(
        (daysSinceStartOfYear + startOfYear.getDay()) / 7
      );

      formattedDate = `${date.toLocaleString("default", {
        month: "long",
      })} Week ${weekNumber}`;
    } else {
      const date = new Date(data.date);
      formattedDate = date.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
    const total = Object.keys(data).reduce((acc, key) => {
      return key !== "date" ? acc + parseInt(data[key], 10) : acc;
    }, 0);

    const percentage = ((value / total) * 100).toFixed(1);
    return (
      <div
        style={{
          background: "white",
          padding: "5px",
          // border: "1px solid #ccc",
          borderRadius: "5px",
          // color: colors,
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
        }}
      >
        <p
          style={{
            fontWeight: 700,
            margin: "10px 0 5px 0",
            // marginBottom: "1px", // Reduced bottom margin

            // borderBottom: "1px solid rgba(128, 128, 128, 0.3)", // Faint gray with 30% opacity
          }}
        >
          {formattedDate}
        </p>

        <p
          style={{
            display: "flex",
            justifyContent: "center",
            color: color,
            margin: "0",
          }}
        >
          <strong>
            {id}: {percentage}%
          </strong>
        </p>
      </div>
    );
  };

  let minYValue;
  let maxYValue;

  //Globalize later

  if (data && data.length > 0) {
    const computedData = data.map((entry) => {
      const sum = Object.keys(entry)
        .filter((key) => key !== "date")
        .reduce((acc, key) => acc + entry[key], 0);
      return { ...entry, value: sum };
    });

    if (computedData && computedData.length > 0) {
      maxYValue = Math.max(...computedData.map((entry) => entry.value));
      maxYValue = RoundValue(maxYValue); // Apply the rounding function
    }

    minYValue = 0; // Set minYValue
  }


  return data.length ? (
    <ResponsiveBar
      data={data}
      keys={bar_keys}
      indexBy="date"
      groupMode="stacked"
      margin={
        isMinimal
          ? {
              top: isXSScreen ? 50 : 65,
              right: 20,
              bottom: isXSScreen ? 50 : 30,
              left: maxYValue > 1000000 ? 90 : 90,
            }
          : {
              top: 50,
              right: isDetailedChart ? 35 : 35,
              bottom: 50,
              left: 70,
            }
      }
      padding={0.1}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={
        hasDifferentColor
          ? (data) => data.data.Color
          : // { scheme: "pastel2" }
            colors
      }
      layout="vertical"
      borderRadius={1}
      enableLabel={false}
      borderWidth={0}
      // borderColor={{
      //   from: "color",
      //   modifiers: [["darker", 1.6]],
      // }}
      axisTop={null}
      axisRight={null}
      enableGridY={isMinimal ? null : true}
      tooltip={
        isDetailedChart
          ? EmpCustomTooltip
          : ({ id, value, color, indexValue, data }) => (
              <CustomTooltip
                id={id}
                value={value}
                color={color}
                indexValue={indexValue}
                data={data}
              />
            )
      }
      // tooltip={isDetailedChart ? EmpCustomTooltip : CustomTooltip}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isMinimal ? undefined : range === "hour" ? "Time" : "Date",
        legendPosition: "middle",
        legendOffset: 32,
        format:
          dataRange === "today"
            ? (value) => {
                const date = new Date(value);
                // Using toLocaleTimeString to format the time in 12-hour format with AM/PM
                return date.toLocaleTimeString([], {
                  hour: "2-digit",
                  // minute: "2-digit",
                  hour12: false, // Ensures 12-hour format with AM/PM
                });
              }
            : (value) => {
                const date = new Date(value);
                const formattedDate = date.toLocaleDateString(undefined, {
                  // year: "numeric",
                  month: "short",
                  day: "numeric",
                });

                return formattedDate;
              },

        tickValues: getBarTickValues(data, range, isXSScreen),
      }}
      axisLeft={
        isMinimal
          ? {
              format, // Use the custom format function for the ticks
              tickValues: [minYValue, maxYValue],
            }
          : {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: isXSScreen
                ? undefined
                : isDetailedChart
                ? "Recommended Employee Hours"
                : "Sales per Day",
              legendPosition: "middle",
              legendOffset: -80,
              format,
              tickValues: "every 10",
            }
      }
      // markers={[
      //   hasPredictionLine
      //     ? {
      //         axis: "x", // Vertical line
      //         // legend: "prediction marker",
      //         value: predictionLineMarkerDate,
      //         lineStyle: {
      //           stroke: "#505050", // Line color
      //           strokeWidth: 2, // Line width
      //         },

      //         legendOrientation: "vertical",
      //         legendPosition: "top-right",
      //       }
      //     : [],
      // hasStaffMarker // change to hasStaffMarker
      //   ? {
      //       axis: "x", // Vertical line
      //       // legend: "prediction marker",
      //       value: staffMarkerDate,
      //       // Immediately invoke the function to get the new date
      //       lineStyle: {
      //         stroke: "green", // Line color
      //         strokeWidth: 2, // Line width
      //       },
      //       legendOrientation: "vertical",
      //       legendPosition: "top-right",
      //     }
      //   : [],
      // ]}
      labelSkipWidth={0}
      labelSkipHeight={12}
      labelTextColor="black"
      // innerPadding={1}
      legends={
        isXSScreen || isDetailedChart || isMinimal
          ? [] // No legends for small screens
          : [
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
      }
    />
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <h2> No data</h2>
    </div>
  );
};

export default ProdBarChart;
