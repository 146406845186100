import React, { useState, useCallback, useEffect } from "react";
import { Box, Grid, Typography, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ChartLoading from "../Loading/ChartLoading";
import InputAdornment from "@mui/material/InputAdornment";
import { formatRegressorValue } from "../../utils/FormatRegressor";

let prefix = "";

if (process.env.NODE_ENV === "development") {
  prefix = "http://127.0.0.1:8000";
}

const company = localStorage.getItem("company");
const token = localStorage.getItem("token");

function HolidayParamsTable({
  selectedPage,
  restaurant,
  start_date,
  end_date,
}) {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchData = useCallback(async () => {
    if (
      selectedPage !== "company" &&
      (!restaurant || restaurant.length === 0)
    ) {
      return;
    }
    setIsLoading(true);
    let url = `${prefix}/api/holidayparameters/holiday_params?company=${company}`;

    if (selectedPage !== "company" && restaurant && restaurant.length > 0) {
      url += `&restaurant=["${restaurant}"]`;
    }
    if (start_date && end_date) {
      url += `&start_date=${start_date}&end_date=${end_date}`;
    }
    const holidays_response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (holidays_response.ok) {
      const holidayData = await holidays_response.json();
      if (holidayData.results) {
        console.log(holidayData.results, "holiday");
        const processedData = holidayData.results
          .filter((row) => row.name !== "High/low season")
          .filter(
            (row) => row.restaurant !== "Trondheim"
            // ||
            //   !(row.event_category && row.restaurant && row.effect <= 2000)
          )
          .map((row) => ({
            ...row,
            name: row.name === null ? "None" : row.name,
            effect: row.effect === null ? "None" : row.effect,
            date: row.date === null ? "None" : row.date,
            event_category:
              row.event_category === null ? "None" : row.event_category,
            location: row.location === null ? "None" : row.location,
            type: row.type === null ? "None" : row.type,
          }));
        console.log(processedData, "after holiday");
        setTableData(processedData);
      }
    } else {
      setIsLoading(false);
      console.error("Failed to fetch data");
      setTableData([]);
    }
    setIsLoading(false);
  }, [restaurant, start_date, end_date, selectedPage]);

  useEffect(() => {
    if (selectedPage === "multiple" && restaurant.length === 0) {
      setTableData([]);
    } else {
      fetchData();
    }
  }, [fetchData, restaurant, selectedPage]);

  const getWeekdayName = (dateString) => {
    const date = new Date(dateString);
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return weekdays[date.getDay()];
  };

  const filteredData = tableData.filter((item) => {
    return (
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.event_category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.restaurant.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (item.date &&
        new Date(item.date)
          .toLocaleDateString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase()))
    );
  });

  return (
    <Grid
      // container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: { lg: "90%", xs: "80%" },
        height: "100%",
        // border: "solid red 1px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "0.5rem",
          display: "flex",
          justifyContent: "center",
          // mt: "5rem",
        }}
      >
        <TextField
          sx={{ width: { lg: "40%", xs: "50%" } }}
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 10rem)",
          overflowY: "auto",
          padding: "1rem",
        }}
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <ChartLoading />
          </div>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {Object.entries(filteredData).map(([date, detail]) => {
              return (
                <Box
                  key={date}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                    margin: ".1rem",
                    width: { xs: "100%", lg: "80%" },
                    borderRadius: "1rem",
                    minHeight: { lg: "30%" },
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Montserrat",
                    padding: "1rem",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 700,
                      textAlign: "center",
                      mt: "1rem",
                      marginBottom: "0.5rem",
                      fontSize: "1rem",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Name: {formatRegressorValue(detail.name)}
                  </Typography>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Montserrat",
                        textAlign: "center",
                        fontWeight: 600,
                        color: detail.effect < 0 ? "#cc1d2b " : "#15ad15",
                      }}
                    >
                      Effect: {detail.effect} NOK
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { lg: "row", xs: "column" },
                        justifyContent: "space-between",
                        width: "90%",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: 400,
                          marginBottom: "0.5rem",
                          fontSize: "1rem",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {getWeekdayName(detail.date)},&nbsp;
                        {new Date(detail.date).toLocaleString("en-GB", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 500,
                          marginBottom: "0.5rem",
                          fontSize: "1rem",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Event Category: {detail.event_category}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { lg: "row", xs: "column" },
                        justifyContent: "space-between",
                        width: "90%",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: 400,
                          fontSize: "1rem",
                          marginBottom: "0.5rem",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Restaurant: {detail.restaurant}
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 400,
                          fontSize: "1rem",
                          marginBottom: "0.5rem",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Location: {detail.location}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Grid>
  );
}

export default HolidayParamsTable;
