import React, { useState } from "react";
import { ResponsiveLine } from "@nivo/line";
// import { getCompanyColors } from "../CompanyUtils/CompanyColor";
// import formatNumber from "../../utils/FormatNumber";
import { getLineTickValues } from "../../utils/GetLineTickValues";
import dayjs from "dayjs";
import formatNumber from "../../utils/FormatNumber";

// const latest_created_at = localStorage.getItem("latest_records");

const SalesPredLineChart = ({
  data,

  isNotPercentage = false,
  isSecondaryChart = false,
  secondaryData = null,
  range = null,
  // isOverlapChart = false,
  isXSScreen = false,
  // allowNegativeY = false,
  // showZeroLine = false,
  isMinimal = false,
  // singleTooltip = false,
  hasPredictionLine = false,
  isMinNonZero = false,
  isCurrency = false,
  noPrefix = false,
  hasStaffMarker = false,
  isSalesPrediction = false,
  // useKpiFormat = false,
  isOverlapChart = false,
  Latest = null,
}) => {
  //SANDBOX
  //SANDBOX
  let newVar = [];
  let secondVar = [];
  // const [activeId, setActiveId] = useState(null);
  const [hiddenIds, setHiddenIds] = useState([]);
  const format = !isNotPercentage
    ? (v) => `${v}%`
    : isCurrency
    ? (v) => `${v} NOK`
    : (v) => `${v} units`;

  // Assuming staffchart is a boolean variable defined earlier
  for (const property in data) {
    if (isOverlapChart) {
      newVar.push(data[property]);
    } else {
      // If staffchart is false, keep your original structure
      newVar.push(data[property]);
    }
  }

  function removeRedundantPredictions(dataset) {
    let predictionData = [];
    let userPredictionData = [];

    // Extract prediction and user prediction data based on id
    dataset.forEach((item) => {
      if (item.id === "Prediction") {
        predictionData = item.data;
      } else if (item.id === "User Prediction") {
        userPredictionData = item.data;
      }
    });

    const predictionMap = new Map();

    // Build a map for quick lookup of prediction values
    predictionData.forEach((item) => {
      predictionMap.set(item.x, item.y);
    });

    // Create a new array for updated user prediction data
    const updatedUserPredictionData = userPredictionData.map((item) => {
      if (predictionMap.has(item.x) && predictionMap.get(item.x) === item.y) {
        return { ...item, y: null };
      }
      return item;
    });

    // Return the modified dataset
    return dataset.map((item) => {
      if (item.id === "User Prediction") {
        return { ...item, data: updatedUserPredictionData };
      }
      return item;
    });
  }

  const finalData = removeRedundantPredictions(newVar);

  const lineData =
    newVar && Object.entries(newVar).length !== 0 ? [newVar] : [];
  // if (isCombinedChart) {
  //   //combined chart
  //   newVar = newVar[0];
  // }

  const { tickValuesI, tickValuesO } = getLineTickValues(newVar);


  if (secondaryData) {
    secondVar = [];
    for (const property in secondaryData) {
      secondVar.push(secondaryData[property]);
    }
  }
  if (newVar && newVar.length > 0) {
    newVar = newVar.map((series) => {
      return {
        ...series,
        data: series.data.map((point) => {
          return {
            ...point,
            y: isNaN(point.y) ? 0 : point.y,
          };
        }),
      };
    });
  }

  let filteredData = finalData?.filter(
    (serie) => !hiddenIds.includes(serie.id)
  );

  let minYValue;
  let maxYValue;
  if (newVar && newVar.length > 0) {
    // Extract all y-values from all data series and filter out NaN values
    const allYValues = newVar.flatMap((series) =>
      series.data.map((point) => point.y).filter((y) => !isNaN(y))
    );

    // Check if there are any valid values left after filtering
    if (allYValues.length > 0) {
      // Find the minimum and maximum y-values from the filtered array
      minYValue = Math.min(...allYValues);
      maxYValue = Math.max(...allYValues);
      if (minYValue === maxYValue) {
        minYValue = 0;
      }
    } else {
      // Default values if no valid data is available
      minYValue = 0;
      maxYValue = 0;
    }
  } else {
    // Default values if no data is available
    minYValue = 0;
    maxYValue = 0;
  }

  minYValue = minYValue.toFixed(0);
  maxYValue = maxYValue.toFixed(0);
  // console.log(minYValue, maxYValue, "min max data");

  if (maxYValue < 10) {
  } else if (isNotPercentage) {
    maxYValue =
      maxYValue < 1000
        ? Math.round(maxYValue / 100) * 100
        : maxYValue < 9000
        ? Math.round(maxYValue / 1000) * 1000
        : Math.round(maxYValue / 10000) * 10000;
  } else {
    // For percentages or other units
    maxYValue = Math.round(maxYValue / 10) * 10; // Adjust rounding logic as needed
  }

  let staffMarkerDate = null;
  if (hasStaffMarker && Latest) {
    const latestCreatedAt = dayjs(Latest);
    const tickValues = getLineTickValues(newVar); // Assuming getLineTickValues is defined elsewhere and returns an object with tickValuesI and tickValuesO
    const firstTickValue = dayjs(tickValues.tickValuesI[0]); // Assuming tickValuesI is an array of date strings or timestamps
    const lastTickValue = dayjs(
      tickValues.tickValuesO[tickValues.tickValuesO.length - 1]
    );
    // Check if latest_created_at falls within the tick values range
    if (
      latestCreatedAt.isAfter(firstTickValue) &&
      latestCreatedAt.isBefore(lastTickValue)
    ) {
      const modifiedDate = latestCreatedAt.add(14, "day");

      staffMarkerDate = modifiedDate.toDate();
      staffMarkerDate.setHours(0, 0, 0, 0);
    }
  }

  // let start_date, end_date;
  let predictionLineMarkerDate;
  if (hasPredictionLine && Latest) {
    predictionLineMarkerDate = new Date(Latest);
    // Set the time to midnight to align with the graph points
    predictionLineMarkerDate?.setHours(0, 0, 0, 0);
  }

  const pointTooltip = ({ point }) => {
    const date = new Date(point.data.x);
    let formattedDate;

    if (range === "month") {
      formattedDate = date.toLocaleDateString(undefined, {
        month: "long",
        year: "numeric",
      });
    } else if (range === "week") {
      const sunday = new Date(date);
      sunday.setDate(sunday.getDate() - sunday.getDay());

      const startOfYear = new Date(sunday.getFullYear(), 0, 1);
      const dayDifference = (sunday - startOfYear) / (24 * 60 * 60 * 1000);
      const weekNumber =
        1 + Math.floor((dayDifference + startOfYear.getDay() + 5) / 7);

      formattedDate = `${date.toLocaleDateString(undefined, {
        month: "long",
      })} (Week ${weekNumber}) ${sunday.getFullYear()}`;
    } else {
      formattedDate = date.toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }

    // Ensure both dates are compared in the same format
    let isStaffMarkerDate =
      hasStaffMarker &&
      new Date(point.data.x)?.setHours(0, 0, 0, 0) ===
        staffMarkerDate?.setHours(0, 0, 0, 0);

    // Convert latest_created_at to a Date object and set hours to zero for accurate comparison
    const latestCreatedAtDate = new Date(Latest).setHours(0, 0, 0, 0);
    // Convert point date to the same format for accurate comparison
    const pointDate = new Date(point.data.x).setHours(0, 0, 0, 0);
    // console.log(latestCreatedAtDate, "point");
    // Determine whether the tooltip should display "Historical" or "Prediction"
    let tooltipTitle;
    if (isOverlapChart && pointDate <= latestCreatedAtDate) {
      tooltipTitle = "Historical";
    } else {
      tooltipTitle = "Prediction";
    }

    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          borderRadius: "5px",
        }}
      >
        <strong>{formattedDate}</strong>
        <br />
        {point.data.comment && (
          <strong style={{ color: "black", marginBottom: "10px" }}>
            Comment: {point.data.comment}
            <br />
          </strong>
        )}
        {isStaffMarkerDate && (
          <strong
            style={{ marginTop: "10px", display: "block", color: "#D2042D" }}
          >
            Planned staff cost ends here
            <br />
          </strong>
        )}
        {/* Conditional rendering for Net Profit, Gross Profit at the bottom */}

        {point.serieId !== "Gross Profit II" &&
          point.serieId !== "Gross Profit I" && (
            <strong
              style={{
                color:
                  tooltipTitle === "Historical" ? "#543c8c" : point.serieColor,
                marginTop: "10px",
              }}
            >
              {!isOverlapChart ? `${point.serieId}: ` : ""}
              {isNotPercentage
                ? formatNumber(Math.round(point.data.y))
                : `${point.data.y}%`}
            </strong>
          )}
      </div>
    );
  };

  const axisProps = !isSecondaryChart
    ? {
        axisLeft: {
          format,
          tickValues: [minYValue, maxYValue],
          legendOffset: 12,
        },
        // colors: getCompanyColors(),

        tooltip: pointTooltip,
      }
    : null;

  return !lineData || lineData.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        // border: "solid red 1px",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    <ResponsiveLine
      animate
      enableGridX={isMinimal ? false : !isSecondaryChart}
      enableGridY={isMinimal ? true : !isSecondaryChart}
      gridYValues={[0, minYValue, maxYValue]}
      axisBottom={{
        format: (value) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString(undefined, {
            // year: "numeric",
            month: "short",
            day: "numeric",
          });

          return formattedDate;
        },

        legendOffset: -12,
        tickValues: isXSScreen ? tickValuesO : tickValuesI, // Assuming tickValuesO and tickValuesI are defined elsewhere
      }}
      {...axisProps}
      axisLeft={
        !isSecondaryChart
          ? {
              tickValues: [0, minYValue, maxYValue].filter(
                (v, i, a) => a.indexOf(v) === i
              ), // Ensuring no duplicates
              format: noPrefix ? undefined : (v) => format(v),
              legendOffset: -40,
            }
          : null
      }
      curve="monotoneX"
      data={filteredData}
      margin={
        isMinimal
          ? {
              bottom: 50,
              left: noPrefix ? 50 : maxYValue > 100 ? 80 : 80,
              right: 50,
              top: 40,
            }
          : isSalesPrediction
          ? {
              bottom: 60,
              left: 80,
              right: 40,
              top: 80,
            }
          : {
              bottom: 60,
              left: 80,
              right: 40,
              top: 80,
            }
      }
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      lineWidth={1}
      markers={[
        // Add the marker for todays date here
        hasPredictionLine
          ? {
              axis: "x", // Vertical line
              // legend: "prediction marker",
              value: predictionLineMarkerDate,
              lineStyle: {
                stroke: "#505050", // Line color
                strokeWidth: 2, // Line width
              },

              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
        hasStaffMarker // change to hasStaffMarker
          ? {
              axis: "x", // Vertical line
              // legend: "prediction marker",
              value: staffMarkerDate,
              // Immediately invoke the function to get the new date
              lineStyle: {
                stroke: "green", // Line color
                strokeWidth: 2, // Line width
              },
              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
      ]}
      pointBorderWidth={1}
      pointSize={4}
      enableArea={true}
      colors={{ datum: "color" }}
      areaBaselineValue={isMinNonZero ? 0 : 0}
      areaOpacity={0.15}
      labelFormat={format}
      useMesh
      xFormat="time:%Y-%m-%d"
      xScale={{
        format: "%Y-%m-%d",
        precision: "day",
        type: "time",
        useUTC: true,
      }}
      yScale={{
        type: "linear",
        min: minYValue < 0 ? minYValue : 0, // Always start from 0
        max: "auto", // Set the calculated maxY as the max property
      }}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: isSecondaryChart ? 150 : 2,
          translateY: 50,
          itemsSpacing: 10,
          itemDirection: "left-to-right",
          itemWidth: 109,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
          onClick: (datum) => {
            setHiddenIds((currentHiddenIds) => {
              const isCurrentlyHidden = currentHiddenIds.includes(datum.id);
              const newHiddenIds = isCurrentlyHidden
                ? currentHiddenIds.filter((id) => id !== datum.id)
                : [...currentHiddenIds, datum.id];

              // Ensure we don't hide all lines: if newHiddenIds length is equal to the number of lines, reset it
              if (newHiddenIds.length >= newVar.length) {
                return [];
              }
              return newHiddenIds;
            });
          },
        },
      ]}
    />
  );
};

export default SalesPredLineChart;
