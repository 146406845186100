import React, { useState, useEffect, useCallback, useRef } from "react";

import {
  //   Grid,
  //    Container,
  // Card,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // CardContent,
  Tooltip,
  Zoom,
  useMediaQuery,
  // Collapse,
  OutlinedInput,
  // Checkbox,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  ListItemText,
  // Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useNavigate } from "react-router-dom";
import PerBarChart from "../components/BarCharts/PerBarChart";
import getRandomColor from "../utils/RandomColor";
import PieChart from "../components/PieChart";
import ProdBarChart from "../components/BarCharts/ProdBarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import downloadDashboardTwo from "../utils/ExcelDownloads/DownloadDashboardTwo";
import ChartLoading from "../components/Loading/ChartLoading";
import LineChart from "../components/LineCharts/LineChart";
// import CategoryIcon from "@mui/icons-material/Category";
// import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SnackbarAlert from "../utils/SnackbarAlert";
import LastUpdate from "../utils/LastUpdate";
import InfoIcon from "@mui/icons-material/Info";
import getHighlightedStyle from "../utils/HighlightedStyle";
import NavigationButtons from "../utils/HelpButton";
import Prefix from "../utils/PrefixLink";
import KPILoad from "../components/Loading/KPILoad";
import FormatDesiredDate from "../utils/FormatDesiredDate";
// import formatDateToDesiredFormat from "../utils/FormatDesiredDate";

const prefix = Prefix();

const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const company = localStorage.getItem("company");
const token = localStorage.getItem("token");
// const default_restaurant = JSON.parse(
//   localStorage.getItem("user")
// )?.restaurant_name;
const isAdmin = JSON.parse(localStorage.getItem("user"))?.company_admin;
const latest_created_at = localStorage.getItem("latest_records");

const NewSuperDashboardTwo = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
}) => {
  const now = latest_created_at ? new Date(latest_created_at) : new Date();

  // const [restaurants, setRestaurants] = useState([]);
  // const [restaurantNames, setRestaurantNames] = useState(
  //   default_restaurant ? default_restaurant : null
  // );
  // const [selectedRestaurant, setSelectedRestaurant] = useState([]);

  const [subPercentageProducts, setSubPercentageProducts] = useState([]);
  const [subPercentageProduct, setSubPercentageProduct] = useState(null);

  const [subGrossMarginProducts, setSubGrossMarginProducts] = useState([]);
  const [subGrossMarginProduct, setSubGrossMarginProduct] = useState(null);
  // const [dataRange, setDataRange] = useState("today");
  const [isCurrency, setIsCurrency] = useState(true);

  const [subProducts, setSubProducts] = useState([]);
  const [subProduct, setSubProduct] = useState(null);

  const [deliveryCost, setDeliveryCost] = useState([]);

  const [averageBasketCost, setAverageBasketCost] = useState([]);
  const [averageBasketCostData, setAverageBasketCostData] = useState([]);
  const [grossProfitKPI, setGrossProfitKPI] = useState([]);
  // const [highestSeller, setHighestSeller] = useState([]);

  const [productMixPercentageData, setProductMixPercentageData] = useState([]);
  const [grossProfitMarginGroupData, setGrossProfitMarginGroupData] = useState(
    []
  );
  const [percentageArticleGroupData, setPercentageArticleGroupData] = useState(
    []
  );
  const [highestSellerByValue, setHighestSellerByValue] = useState({
    product: "",
    value: 0,
  });
  const [highestSellerByQuantity, setHighestSellerByQuantity] = useState({
    product: "",
    quantity: 0,
  });
  const [productMixData, setProductMixData] = useState([]);

  const [articleGroupData, setArticleGroupData] = useState([]);

  const [subGrossProfitMarginData, setSubGrossProfitMarginData] = useState([]);

  const [grossProfitLoad, setGrossProfitLoad] = useState(false);
  const [prodBarLoad, setProdBarLoad] = useState(false);
  const [basketLoad, setBasketLoad] = useState(false);
  const [grossProfitMarginLoad, setGrossProfitMarginLoad] = useState(false);

  const [range, setRange] = useState("day");

  // const [selectedPage, setSelectedPage] = useState("individual");
  const [openDialog, setOpenDialog] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  const navigate = useNavigate();

  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState(null);

  const [helpMode, setHelpMode] = useState(false);
  const [counter, setCounter] = useState(1);
  const maxSteps = 9;
  const [isPieVisible, setIsPieVisible] = useState(false);
  const [isSubCategoryVisible, setIsSubCategoryVisible] = useState(false);
  const [isSubGrossMarginVisible, setIsSubGrossMarginVisible] = useState(false);
  const [takeAwayData, setTakeAwayData] = useState(null);
  const [defaultRestaurants, setDefaultRestaurants] = useState([]);

  const abortControllerRefForArticleGrossMargin = useRef(new AbortController());

  // const abortControllerRefForArticleGroupData = useRef(new AbortController());
  // const abortControllerRefForArticleGroupPercent = useRef(
  //   new AbortController()
  // );
  // const abortControllerRefForGrossProfitMargin = useRef(new AbortController());
  // const abortControllerRefForAverageBasketSize = useRef(new AbortController());
  // const abortControllerRefForHighestSeller = useRef(new AbortController());
  // const abortControllerRefForType = useRef(new AbortController());
  // const abortControllerRefForGrossMarginPercentage = useRef(
  //   new AbortController()
  // );

  const getRestaurantNameById = (restaurantName) => {
    const restaurant = defaultRestaurants.find(
      (r) => r.name === restaurantName
    );
    return restaurant ? restaurant.name : "";
  };
  const handleTogglePie = () => {
    setIsPieVisible((prev) => !prev);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleUnitCurrency = () => {
    setIsCurrency((prev) => !prev);
  };
  const handleToggleSubCat = () => {
    setIsSubCategoryVisible((prev) => !prev);
  };

  // setSelectedPage("individual");

  if (selectedPage === "multiple") {
    setSelectedPage("multiple");
  }

  const handleToggleSubGross = () => {
    setIsSubGrossMarginVisible((prev) => !prev);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOptionChange = (sub_product) => {
    setSubProduct(sub_product);
  };

  const handlePercentageChange = (sub_product) => {
    setSubPercentageProduct(sub_product);
  };

  const handleGrossProfitMargin = (sub_product) => {
    setSubGrossMarginProduct(sub_product);
  };
  const handleOpenSettingsDialog = () => {
    setOpenDialog(true);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // setRestaurantNames(value);
    setSelectedRestaurant(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  // function formatDateToDesiredFormat(dateString) {
  //   const date = new Date(dateString);
  //   if (dataRange === "today") {
  //     return date.toLocaleDateString(undefined, {
  //       year: "numeric",
  //       month: "short",
  //       weekday: "short",
  //       day: "numeric",
  //     });
  //   } else {
  //     return date.toLocaleDateString(undefined, {
  //       year: "numeric",
  //       month: "short",
  //       day: "numeric",
  //     });
  //   }
  // }

  const handlePageChange = (newValue) => {
    setSelectedPage(newValue); // Update the state with the new value

    if (!newValue) return; // If no button is selected, do nothing

    if (newValue === "individual") {
      navigate("/newdashboardtwo", {});
    } else if (newValue === "multiple" || newValue === "company") {
      navigate("/newsuperdashboardtwo", {});
    }
  };
  const tooltipContent = {
    1: (
      <Typography variant="body2">
        You can filter the dashboards to see the whole company, individual
        restaurants or a combination of multiple restaurants
      </Typography>
    ),
    2: (
      <Typography variant="body2">
        Choose to filter the dashboard by a single day, week or a whole month.
        You can easily navigate back and forth with the arrows
      </Typography>
    ),
    3: (
      <Typography variant="body2">
        Toggle to display values in NOK or units
      </Typography>
    ),
    4: (
      <Typography variant="body2">
        This card gives you a summary of the most important product numbers.
        Hover over each number to see an explanation of what it represents and
        how we calculate it
      </Typography>
    ),
    5: (
      <Typography variant="body2">
        This graphs shows how much each product category or product is selling
        for, as a percentage of total sales You can select product category to
        see the products in that category by clicking on the toggle button on
        the upper right corner
      </Typography>
    ),
    6: (
      <Typography variant="body2">
        This pie chart shows how much of gross profit each product category or
        product contributes with. This is a good way to focus on the product’s
        contribution to profit and not only its sales.
      </Typography>
    ),
    7: (
      <Typography variant="body2">
        Basket size is a number showing how much each customer is buying for.
        This is a key metric for most restaurants.
      </Typography>
    ),
    8: (
      <Typography variant="body2">
        This is a key metric to understand the profitability of each product and
        product category. You can use the toggle to switch between category and
        the products within a product category
      </Typography>
    ),
    // ... add more steps as needed
  };

  //tutorial guide

  const handleHelpMode = () => {
    setHelpMode(!helpMode);
    setCounter(1); // Reset counter when toggling helpMode
  };

  const handleNext = () => {
    setCounter((prev) => {
      const newCounter = prev + 1;
      if (newCounter >= 9) {
        setHelpMode(false);
      } else {
        // Scroll logic for mobile view in helpMode, only after counter reaches 4
        if (isXSScreen && helpMode && newCounter > 4) {
          const remInPixels = parseFloat(
            getComputedStyle(document.documentElement).fontSize
          );
          window.scrollBy({
            top: 30 * remInPixels, // Scroll by 30rem in pixels
            behavior: "smooth",
          });
        }
      }
      return newCounter;
    });
  };

  const handlePrevious = () => {
    setCounter((prev) => {
      const newCounter = Math.max(prev - 1, 1);

      // Scroll logic for mobile view in helpMode, only if counter was above 4
      if (isXSScreen && helpMode && prev > 4) {
        const remInPixels = parseFloat(
          getComputedStyle(document.documentElement).fontSize
        );
        window.scrollBy({
          top: -30 * remInPixels, // Scroll upwards by 30rem in pixels
          behavior: "smooth",
        });
      }

      return newCounter;
    });
  };

  const fetchRestaurants = useCallback(async () => {
    // Replace the URL with the appropriate API endpoint for fetching a single task
    // setLoad(true);
    const restro_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();

      const userDefaultRestaurants = JSON.parse(
        localStorage.getItem("user")
      )?.default_restaurants;

      let filteredRestaurants = restro_data.results.filter(
        (restaurant) =>
          userDefaultRestaurants.includes(restaurant.id) &&
          restaurant.name !== "Fisketorget Stavanger"
      );
      if (userDefaultRestaurants && userDefaultRestaurants.length > 0) {
        // Filter restaurants based on default_restaurants IDs
        filteredRestaurants = restro_data.results.filter((restaurant) =>
          userDefaultRestaurants.includes(restaurant.id)
        );
      }
      if (!userDefaultRestaurants || userDefaultRestaurants.length === 0) {
        filteredRestaurants = restro_data.results.filter(
          (restaurant) => restaurant.name !== "Fisketorget Stavanger"
        );
      }
      // Apply sorting to move 'Food Truck' to the bottom of the list
      filteredRestaurants.sort((a, b) => {
        const isAFoodTruck = a.name.includes("Food Truck");
        const isBFoodTruck = b.name.includes("Food Truck");

        if (isAFoodTruck && !isBFoodTruck) {
          return 1; // Place 'a' after 'b'
        } else if (!isAFoodTruck && isBFoodTruck) {
          return -1; // Place 'a' before 'b'
        }
        return 0; // Keep original order for other restaurants
      });

      setDefaultRestaurants(filteredRestaurants);
    }
  }, []);

  const handleRangeChange = (event) => {
    const newValue = event.target.value;
    if (newValue !== null) {
      setRange(newValue);
    }
  };

  const adjustDates = (date, dataRange, direction) => {
    let newDate = new Date(date);
    const adjustDay = direction === "forward" ? 1 : -1;
    const adjustWeek = direction === "forward" ? 7 : -7;
    const adjustMonth = direction === "forward" ? 1 : -1;

    switch (dataRange) {
      case "today":
        newDate.setDate(newDate.getDate() + adjustDay);
        break;
      case "thisweek":
        newDate.setDate(newDate.getDate() + adjustWeek);
        break;
      case "thismonth":
        newDate.setMonth(newDate.getMonth() + adjustMonth);
        break;
      default:
        break;
    }

    return newDate.toISOString().split("T")[0];
  };

  const handleBackwardClick = () => {
    const newStartDate = adjustDates(startDate, dataRange, "backward");
    setStartDate(newStartDate);

    if (dataRange === "thismonth") {
      // Set endDate to the last day of the new month
      const monthEndDate = new Date(newStartDate);
      monthEndDate.setMonth(monthEndDate.getMonth() + 1, 0); // Last day of the month
      setEndDate(monthEndDate.toISOString().split("T")[0]);
    } else {
      setEndDate(adjustDates(endDate, dataRange, "backward"));
    }
  };

  const handleForwardClick = () => {
    const newStartDate = adjustDates(startDate, dataRange, "forward");
    setStartDate(newStartDate);
    console.log(takeAwayData);

    if (dataRange === "thismonth") {
      // Set endDate to the last day of the new month
      const monthEndDate = new Date(newStartDate);
      monthEndDate.setMonth(monthEndDate.getMonth() + 1, 0); // Last day of the month
      setEndDate(monthEndDate.toISOString().split("T")[0]);
    } else {
      setEndDate(adjustDates(endDate, dataRange, "forward"));
    }
  };

  const buildFetchUrl = (baseURL, options) => {
    let {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      // dataRange,
      // hasPredictionData,
      newValue,
    } = options;

    let startingDate = startDate;
    let endingDate = endDate;

    // Formatting startDate and endDate
    let formattedStartDate = startingDate;
    let formattedEndDate = endingDate;

    if (
      (formattedEndDate && !formattedStartDate) ||
      (formattedStartDate && !formattedEndDate)
    ) {
      return;
    }
    if (formattedStartDate > formattedEndDate) {
      setOpen(true);
      setSeverity("error");
      setMessage("Start date cannot be further than end date");
      return;
    }

    // Constructing URL with query parameters
    let url = baseURL;
    let isFirstQueryParam = true;

    // Helper function to append parameters
    const appendParam = (key, value) => {
      if (isFirstQueryParam) {
        url += `?${key}=${encodeURIComponent(value)}`;
        isFirstQueryParam = false;
      } else {
        url += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // Append company if present
    if (company) {
      appendParam("company", company);
    }

    // Append start_date and end_date if present
    if (formattedStartDate && formattedEndDate) {
      appendParam("start_date", startDate);
      appendParam("end_date", endDate);
    }

    // Append restaurantNames if present
    if (selectedRestaurant.length > 0) {
      appendParam("restaurants", JSON.stringify(selectedRestaurant));
    }

    // Append newValue if present
    if (newValue) {
      appendParam("period", newValue);
    }

    // Log the formatted URL
    // console.log(url, "format url in function");

    // Return the URL as a string
    return url;
  };

  const getDeliveryCost = useCallback(() => {
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    const Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/take_out_cost/`,
      urlOptions
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.results) {
          const totalTakeOutFee = data.results.reduce(
            (acc, entry) => acc + entry.take_out_fee,
            0
          );
          const totalNet = data.results.reduce(
            (acc, entry) => acc + entry.total_net,
            0
          );
          const percentage = (totalTakeOutFee / totalNet) * 100;
          setDeliveryCost(percentage.toFixed(1)); // Replace setDeliveryCost with your actual state setter for the percentage
        } else {
          setDeliveryCost(0); // Or any default value you consider appropriate
        }
      })
      .catch((error) => {
        console.error("Error fetching delivery cost data:", error);
        setDeliveryCost(0); // Set to default or error state
      });
  }, [startDate, endDate, selectedRestaurant, range, dataRange]);

  //API fetch for gross profit margin for sub product
  const getArticleGrossMarginPercentage = useCallback(() => {
    const subProducts = subGrossMarginProduct;
    if (!selectedRestaurant) {
      return;
    }
    if (!subGrossMarginProduct) {
      return;
    }
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    let Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/sub_gross_profit_margin`,
      urlOptions
    );

    Url += `&sub_product=${subProducts}`;
    abortControllerRefForArticleGrossMargin.current.abort();
    abortControllerRefForArticleGrossMargin.current = new AbortController();

    setGrossProfitMarginLoad(true);
    fetch(Url, {
      headers: headers,
      signal: abortControllerRefForArticleGrossMargin.current.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          const chartData = Object.entries(data.message).map(
            ([product, value]) => ({
              Color: getRandomColor(),
              id: product, // Product name will be used as 'id' on the x-axis
              value: parseFloat(value.toFixed(2)), // Use the percentage with 2 decimal points as 'value'
            })
          );

          setSubGrossProfitMarginData(chartData);
        } else {
          setSubGrossProfitMarginData([]);
        }
        setGrossProfitMarginLoad(false);
      })

      .catch((error) => {
        // console.error("Error fetching sales data:", error);
        // setGrossProfitMarginLoad(false);
        if (error.name !== "AbortError") {
          console.error(error);
          setGrossProfitMarginLoad(false);
        }
      });
  }, [
    subGrossMarginProduct,
    startDate,
    endDate,
    range,
    selectedRestaurant,
    dataRange,
  ]);

  //API fetch for product mix data
  const getArticleData = useCallback(() => {
    if (!selectedRestaurant) {
      return;
    }
    let range = null;
    range = dataRange === "today" ? "hour" : "day";
    let newValue = range;
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      newValue,
    };
    const Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/product_mix`,
      urlOptions
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    setProdBarLoad(true);
    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          let resultsToUse = data.message.historical;
          if (resultsToUse.length <= 0) {
            setSubProducts([]);
            setProductMixData([]);
            setProdBarLoad(false);
            return;
          }
          let sub_products = [];
          Object.entries(resultsToUse).map((map) => {
            const sub_keys = Object.keys(map[1]);
            for (const index in sub_keys) {
              const key = sub_keys[index];
              if (!sub_products.includes(key)) {
                sub_products.push(key);
              }
            }
            return sub_products;
          });
          setSubProducts(sub_products);
          const chartData = Object.entries(resultsToUse).map(
            ([actual_date, values]) => {
              let date;
              const totalNetData = {};
              const totalQuantity = {};
              Object.entries(values).forEach(
                ([category, { total_net, quantity_sold }]) => {
                  totalNetData[category] = total_net;
                  totalQuantity[category] = quantity_sold;
                }
              );
              if (newValue === "week") {
                const [year, week] = actual_date.split(" ");
                const newDate = new Date(Date.UTC(year, 0)); // January 1st of the year
                const weekOffset = week * 7; // Calculate the number of days to add for the week
                newDate.setUTCDate(newDate.getUTCDate() + weekOffset);
                const transformed_date = new Date(newDate);
                const yyyy = transformed_date.getUTCFullYear();
                const mm = String(transformed_date.getUTCMonth() + 1).padStart(
                  2,
                  "0"
                ); // Month is 0-based, so we add 1 and pad with 0 if needed
                const dd = String(transformed_date.getUTCDate()).padStart(
                  2,
                  "0"
                );
                const formatted_date = `${yyyy}-${mm}-${dd}`;
                date = formatted_date;
              } else {
                date = actual_date;
              }
              return {
                date,
                ...(isCurrency ? totalNetData : totalQuantity),
              };
            }
          );
          setProductMixData(chartData);
        } else {
          setSubProducts([]);
          setProductMixData([]);
        }
        setProdBarLoad(false);
      })

      .catch((error) => {
        setProdBarLoad(false);
        console.error("Error fetching sales data:", error);
      });
  }, [startDate, endDate, selectedRestaurant, dataRange, isCurrency]);

  const getArticleGroupData = useCallback(() => {
    let range = null;
    range = dataRange === "today" ? "hour" : "day";
    let newValue = range;
    const subProducts = subProduct;

    if (!selectedRestaurant) {
      return;
    }
    if (!subProduct) {
      return;
    }
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      newValue,
    };

    let Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/sub_product_mix`,
      urlOptions
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    Url += `&sub_product=${subProducts}`;

    setProdBarLoad(true);

    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          let resultsToUse = data.message.historical;
          if (resultsToUse.length <= 0) {
            setArticleGroupData([]);
            setProdBarLoad(false);
            return;
          }
          const chartData = Object.entries(resultsToUse).map(
            ([actual_date, values]) => {
              let date;
              const totalNetData = {};
              Object.entries(values).forEach(([category, { total_net }]) => {
                totalNetData[category] = total_net;
              });
              if (newValue === "week") {
                const [year, week] = actual_date.split(" ");
                const newDate = new Date(Date.UTC(year, 0)); // January 1st of the year
                const weekOffset = week * 7; // Calculate the number of days to add for the week
                newDate.setUTCDate(newDate.getUTCDate() + weekOffset);
                const transformed_date = new Date(newDate);
                const yyyy = transformed_date.getUTCFullYear();
                const mm = String(transformed_date.getUTCMonth() + 1).padStart(
                  2,
                  "0"
                ); // Month is 0-based, so we add 1 and pad with 0 if needed
                const dd = String(transformed_date.getUTCDate()).padStart(
                  2,
                  "0"
                );
                const formatted_date = `${yyyy}-${mm}-${dd}`;
                date = formatted_date;
              } else {
                date = actual_date;
              }
              return {
                date,
                ...totalNetData,
              };
            }
          );
          setArticleGroupData(chartData);
        }
        setProdBarLoad(false);
      })

      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setProdBarLoad(false);
      });
  }, [startDate, endDate, selectedRestaurant, subProduct, dataRange]);

  //API fetch for product sub category
  const getArticleGroupPercentageData = useCallback(() => {
    const subProducts = subPercentageProduct;
    if (!selectedRestaurant) {
      return;
    }
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    let Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/percentage_sub_product_mix`,
      urlOptions
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    Url += `&sub_product=${subProducts}`;

    setGrossProfitLoad(true);

    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          const chartData = Object.entries(data.message).map(
            ([product, value]) => ({
              Color: getRandomColor(),
              id: product, // Product name will be used as 'id' on the x-axis
              value: parseFloat(value.toFixed(2)), // Use the percentage with 2 decimal points as 'value'
            })
          );

          setPercentageArticleGroupData(chartData);
        }
        setGrossProfitLoad(false);
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setGrossProfitLoad(false);
      });
  }, [
    subPercentageProduct,
    startDate,
    endDate,
    range,
    selectedRestaurant,
    dataRange,
  ]);

  //Gross Profit Margin Data
  const getGrossProfitMarginGroupData = useCallback(() => {
    if (!selectedRestaurant) {
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };

    const Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/gross_profit_margin`,
      urlOptions
    );

    setGrossProfitMarginLoad(true);
    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          let totalValue = 0;
          let count = 0;
          Object.values(data.message).forEach((value) => {
            totalValue += value;
            count++;
          });
          const overallAverageMargin = count > 0 ? totalValue / count : 0;
          let sub_products = [];
          // Modify the chart data here to have 'id' and 'value' properties
          const chartData = Object.entries(data.message).map(
            ([product, value]) => {
              sub_products.push(product);

              return {
                Color: getRandomColor(),
                id: product, // Product name will be used as 'id' on the x-axis
                value: parseFloat(value.toFixed(2)), // Use the percentage with 2 decimal points as 'value'
              };
            }
          );

          setSubGrossMarginProducts(sub_products);
          setGrossProfitMarginGroupData(chartData);
          setGrossProfitKPI(overallAverageMargin.toFixed(1));
        } else {
          setSubGrossMarginProducts([]);
          setGrossProfitMarginGroupData([]);
        }
        setGrossProfitMarginLoad(false);
      })

      .catch((error) => {
        setGrossProfitMarginLoad(false);
        console.error("Error fetching gross profit margin data:", error);
      });
  }, [startDate, endDate, range, selectedRestaurant, dataRange]);

  //API fetch for average basket size
  const getAverageBasketSize = useCallback(() => {
    if (!selectedRestaurant) {
      return;
    }
    let newValue = range;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    let Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/basket_cost/`,
      urlOptions
    );

    setBasketLoad(true);
    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          let totalAverage = 0;
          let count = 0;
          data.message.forEach((entry) => {
            if (entry.average) {
              totalAverage += entry.average;
              count++;
            }
          });
          const overallAverage = count > 0 ? totalAverage / count : 0;
          setAverageBasketCostData(overallAverage.toFixed(0));
          const absoluteBasketCostData = {
            ...data.message.reduce((result, entry) => {
              const { date, restaurant, average, average_units } = entry;
              let date_period;
              let y_value = isCurrency ? average : average_units;

              if (newValue === "week") {
                let [year, week] = date.split(" ");
                let actualYear = parseInt(year, 10);
                const firstJan = new Date(Date.UTC(actualYear, 0, 1)); // January 1st of the given year

                // Calculate the first Monday of the year
                const dayOffset = (1 - firstJan.getUTCDay() + 7) % 7;
                const firstMonday = new Date(firstJan);
                firstMonday.setDate(firstJan.getDate() + dayOffset);

                // If the date is before the first Monday, adjust the week and year

                const weekOffset = (week - 1) * 7;
                date_period = new Date(firstMonday);
                date_period.setUTCDate(firstMonday.getUTCDate() + weekOffset);
              } else if (newValue === "month") {
                const [year, month] = date.split(" ");

                date_period = new Date(year, parseInt(month) - 1, 23); // Set to the last day of the given month
              } else {
                date_period = new Date(date);
              }
              return {
                ...result,
                [restaurant]: {
                  id: restaurant,
                  data: [
                    ...(result[restaurant]?.data || []),
                    {
                      x: new Date(date_period),
                      y: y_value ? parseFloat(y_value).toFixed(0) : 0,
                    },
                  ],
                },
              };
            }, {}),
          };
          setAverageBasketCost(absoluteBasketCostData);
        } else {
          setAverageBasketCost([]);
        }
        setBasketLoad(false);
      })
      .catch((error) => {
        setBasketLoad(false);
        console.error("Error fetching staff data:", error);
      });
  }, [startDate, endDate, range, selectedRestaurant, dataRange, isCurrency]);
  // API to fetch highest selling product's amount
  const getHighestSellingProduct = useCallback(() => {
    if (!selectedRestaurant) {
      return;
    }
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    const Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/highest_selling_product`,
      urlOptions
    );

    // setHighestSellerValue(true)
    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          // Assume the highest selling product info is in data.message
          // setHighestSeller(data.message[0].article_group);
          // setHighestSellerValue(data.message[0].max);
          const highestValueProduct = data.message.find(
            (item) => item.total_sales_value
          );
          const highestQuantityProduct = data.message.find(
            (item) => item.quantity_sold
          );

          setHighestSellerByValue({
            product: highestValueProduct.total_sales_article_group,
            value: highestValueProduct.total_sales_value,
          });
          setHighestSellerByQuantity({
            product: highestQuantityProduct.total_quantity_article,
            quantity: highestQuantityProduct.quantity_sold,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }, [startDate, endDate, selectedRestaurant, dataRange]);
  //API fetch for the piechart data
  const getArticlePercentageData = useCallback(() => {
    if (!selectedRestaurant) {
      return;
    }
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    const Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/product_mix_percentage/`,
      urlOptions
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    setGrossProfitLoad(true);
    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          let sub_products = [];
          // Modify the chart data here to have 'id' and 'value' properties
          const chartData = Object.entries(data.message).map(
            ([product, value]) => {
              sub_products.push(product);

              return {
                Color: getRandomColor(),
                id: product, // Product name will be used as 'id' on the x-axis
                value: parseFloat(value.toFixed(2)), // Use the percentage with 2 decimal points as 'value'
              };
            }
          );
          setSubPercentageProducts(sub_products);
          setProductMixPercentageData(chartData);
        } else {
          setSubPercentageProducts([]);
          setProductMixPercentageData([]);
        }

        setGrossProfitLoad(false);
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setGrossProfitLoad(false);
      });
  }, [startDate, endDate, range, selectedRestaurant, dataRange]);

  const getTypeData = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    let temp_prediction_type = [];
    let temp_data_type = [];
    let urlP = `${prefix}/api/dining_type_predictions/type_predictions/`;
    let urlA = `${prefix}/api/salesdata_hourly/actual_take_out`;

    let formattedStartDate = startDate;
    let formattedEndDate = endDate;
    urlP += `?company=${company}`;
    urlA += `?company=${company}`;
    urlP += `&period=${range}`;
    urlA += `&period=${range}`;

    urlP += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
    urlA += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

    if (selectedRestaurant && selectedRestaurant.length > 0) {
      urlP += `&restaurants=${JSON.stringify(selectedRestaurant)}`;
      urlA += `&restaurants=${JSON.stringify(selectedRestaurant)}`;
    }

    // setLoad(true);

    fetch(urlP, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.results) {
          // Group data by period
          const groupedData = data.results.reduce(
            (acc, { period, take_out }) => {
              const dateKey = new Date(period).toISOString(); // Use ISO string for uniformity
              if (!acc[dateKey]) {
                acc[dateKey] = [];
              }
              acc[dateKey].push(parseFloat(take_out));
              return acc;
            },
            {}
          );

          // Sum and average the grouped data
          temp_prediction_type = Object.entries(groupedData).reduce(
            (result, [period, values]) => {
              const sum = values.reduce((acc, val) => acc + val, 0);
              const avg = sum / values.length; // Divide the sum by the number of values and then by 8
              return {
                ...result,
                take_out: {
                  id: "Prediction",
                  data: [
                    ...(result.take_out?.data || []),
                    {
                      x: new Date(period),
                      y: avg,
                    },
                  ],
                },
              };
            },
            {}
          );
        }
      })

      .catch((error) => console.error("Error fetching sales data:", error));
    //Second

    fetch(urlA, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          if (data.message) {
            const percentageData = data.message[0]; // Assuming the first item contains the relevant data
            setTakeAwayData(percentageData);
            // Group data by period
            const groupedData = data.message.reduce(
              (acc, { period, percentage }) => {
                const dateKey = new Date(period).toISOString(); // Ensure uniform date key
                if (!acc[dateKey]) {
                  acc[dateKey] = [];
                }
                acc[dateKey].push(parseFloat(percentage));
                return acc;
              },
              {}
            );

            // Sum and average the grouped data
            temp_data_type = Object.entries(groupedData).reduce(
              (result, [period, percentages]) => {
                const sum = percentages.reduce((acc, val) => acc + val, 0);
                const avg = sum / percentages.length; // Adjust division as necessary
                return {
                  ...result,
                  take_out: {
                    id: "Actual Data",
                    data: [
                      ...(result.take_out?.data || []),
                      {
                        x: new Date(period),
                        y: avg.toFixed(1), // Keep precision to 1 decimal place
                      },
                    ],
                  },
                };
              },
              {}
            );
          }
        }
        // setTakeAwayLoad(false);
        for (const property in temp_prediction_type) {
          // Example condition: Check if the property exists in temp_data
          if (temp_data_type.hasOwnProperty(property)) {
            temp_prediction_type[property] = [
              temp_prediction_type[property],
              temp_data_type[property],
            ];
          } else {
            // Handle the case where the property does not exist in temp_data
            // (you can modify this part as needed)
            temp_prediction_type[property] = [temp_prediction_type[property]];
          }
        }
        // console.log(takeAwayData, "type")
        // console.log(temp_prediction_type,'Type Prediction')
        // setCombinedData(temp_prediction_type);
      })

      .catch((error) => {
        // setTakeAwayLoad(false);
        console.error("Error fetching sales data:", error);
      });
  }, [startDate, endDate, selectedRestaurant, range]);

  useEffect(() => {
    fetchRestaurants();
    // const { startDate, endDate, range } = location.state || {};

    // if (startDate) {
    //   setStartDate(startDate);
    // }
    // if (endDate) {
    //   setEndDate(endDate);
    // }
    // if (range) {
    //   setRange(range);
    // }
  }, [fetchRestaurants]);
  useEffect(() => {
    if (Array.isArray(selectedRestaurant) && selectedRestaurant.length > 1) {
      const firstRestaurant = selectedRestaurant[0];
      setSelectedRestaurant(firstRestaurant);
    }
  }, [selectedRestaurant, setSelectedRestaurant]);
  useEffect(() => {
    getTypeData();
  }, [startDate, endDate, getTypeData]);
  useEffect(() => {
    if (selectedPage === "individual" && selectedRestaurant.length === 0) {
    } else {
      getArticlePercentageData();
      getAverageBasketSize();
      getGrossProfitMarginGroupData();
      getArticleData();
      getDeliveryCost();
      // getTypeData();
    }
  }, [
    getArticlePercentageData,
    getAverageBasketSize,
    getGrossProfitMarginGroupData,
    getArticleData,
    // getTypeData,
    selectedRestaurant,
    selectedPage,
    getDeliveryCost,
  ]);
  useEffect(() => {
    getArticleGroupData();
  }, [getArticleGroupData]);

  useEffect(() => {
    if (selectedPage === "individual" && selectedRestaurant.length === 0) {
    } else {
      getArticleGroupPercentageData();
    }
  }, [getArticleGroupPercentageData, selectedRestaurant, selectedPage]);

  useEffect(() => {
    if (selectedPage === "individual" && selectedRestaurant.length === 0) {
    } else {
      getTypeData();
    }
  }, [getTypeData, selectedRestaurant, selectedPage]);

  useEffect(() => {
    getArticleGrossMarginPercentage();
  }, [getArticleGrossMarginPercentage]);
  useEffect(() => {
    if (selectedPage === "individual" && selectedRestaurant.length === 0) {
      setHighestSellerByValue([]);
      setHighestSellerByQuantity([]);
    } else {
      getHighestSellingProduct();
    }
  }, [getHighestSellingProduct, selectedRestaurant, selectedPage]);

  useEffect(() => {
    return () => {
      // Abort any ongoing fetches for Article Data
      if (abortControllerRefForArticleGrossMargin.current) {
        abortControllerRefForArticleGrossMargin.current.abort();
      }
    };
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        // border: "solid 1px red",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: { lg: 1300, xl: 1600 },
          alignItems: "center",
          // border: "solid 1px blue",
          boxSizing: "border-box",
          height: { lg: "100vh", xs: "200vh" },
        }}
      >
        <Box
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            // border: "solid 1px green",
            height: "100%",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: { lg: 4, xs: 1 },
              // border: "solid 1px red",
              justifyContent: { lg: "space-evenly", xl: "space-evenly" },
              height: { lg: "10%", xs: "20%" },
              alignItems: "center",
              mt: { lg: 2, xs: 0 },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 1}
            >
              <Box
                sx={{
                  ...(counter === 1 && helpMode ? getHighlightedStyle() : {}),
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  alignItems: "center",
                  mt: { lg: 0, xs: 1 },
                  gap: { lg: 0, xs: 1 },
                  // border: "solid 1px blue",
                }}
              >
                {/* {selectedPage === "multiple" ? ( */}
                <FormControl sx={{ width: 200, m: 0 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    {selectedPage === "company" ? company : "All Restaurants"}
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    single
                    value={selectedPage === "company" ? [] : selectedRestaurant}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        label={
                          selectedPage === "company"
                            ? company
                            : "All Restaurants"
                        }
                      />
                    }
                    renderValue={(selected) =>
                      Array.isArray(selected)
                        ? selected
                            .map((name) => getRestaurantNameById(name))
                            .join(", ")
                        : getRestaurantNameById(selected)
                    }
                    // MenuProps={MenuProps}
                    disabled={selectedPage === "company"}
                    sx={{
                      "& .MuiSelect-select": {
                        // height: '3rem',
                        padding: "0",
                        textIndent: "0.25em",
                        textAlign: "center",
                      },
                      // border: "solid red 1px",
                      background: "#F8F8F8",
                      mx: ".5rem",
                      height: "3rem",
                      color: "black",
                      boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    }}
                  >
                    {defaultRestaurants.map((restaurant) => (
                      <MenuItem key={restaurant.id} value={restaurant.name}>
                        {/* <Checkbox
                          checked={selectedRestaurant.includes(restaurant.name)}
                        /> */}
                        <ListItemText primary={restaurant.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <ToggleButtonGroup
                    value={selectedPage}
                    exclusive
                    disabled={!isAdmin}
                    onChange={(event) => {
                      handlePageChange(event.target.value);
                      setSelectedRestaurant([]);
                      // setSelectedPage([]);
                    }}
                    sx={{
                      "& .MuiToggleButton-root": {
                        // height: "3rem",
                        background: "#F8F8F8",
                        "&.Mui-selected": {
                          background: "#D6D6D6", // Darker shade for the selected button
                        },
                      },
                      mx: ".5rem",
                    }}
                    aria-label="Range"
                  >
                    <ToggleButton value="company" aria-label="company">
                      Company
                    </ToggleButton>
                    <ToggleButton value="multiple" aria-label="multiple">
                      Restaurants
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 2}
            >
              <Box
                sx={{
                  ...(counter === 2 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  mt: { lg: 3 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "solid 1px red",
                    height: "100%",
                    width: { xs: "80%", lg: "100%" },
                  }}
                >
                  <ArrowBackIcon
                    onClick={!prodBarLoad ? handleBackwardClick : undefined}
                    sx={{
                      padding: "8px", // Adds space around the icon, increasing the border size
                      color: prodBarLoad ? "action.disabled" : "inherit",
                      cursor: prodBarLoad ? "default" : "pointer", // Ensures the cursor is a pointer // Ensures the cursor is a pointer
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                        borderRadius: "50%", // Makes the background round
                      },
                    }}
                  />
                  <FormControl sx={{ width: { lg: 250, xs: 300 }, m: 0 }}>
                    {/* <InputLabel id="range-select-label">Frequency</InputLabel> */}
                    <ToggleButtonGroup
                      value={dataRange}
                      // The range is the same. Just hour for today.
                      //  Otherwise it's just the startdate and enddate that need to be adjusted accordingly
                      exclusive
                      onChange={(e) => {
                        setDataRange(e.target.value);
                        let startingDate;
                        let endingDate;
                        switch (e.target.value) {
                          case "today":
                            startingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate()
                              )
                            );
                            endingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate()
                              )
                            );
                            break;
                          case "thisweek":
                            // Find the start of the week (Monday)
                            startingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate() -
                                  now.getUTCDay() +
                                  (now.getUTCDay() === 0 ? -6 : 1)
                              )
                            );
                            // Find the end of the week (Sunday)
                            endingDate = new Date(startingDate);
                            endingDate.setDate(startingDate.getDate() + 6); // Add 6 days to get to Sunday
                            break;

                          case "thismonth":
                            startingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                1
                              )
                            );
                            endingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth() + 1,
                                0
                              ) // Set to the last day of the current month
                            );
                            break;
                          default:
                            // Handle any other cases or default behavior
                            break;
                        }
                        setStartDate(startingDate?.toISOString().split("T")[0]);
                        setEndDate(endingDate?.toISOString().split("T")[0]);
                      }}
                      sx={{
                        "& .MuiToggleButton-root": {
                          height: "3rem",
                          background: "#F8F8F8",
                          "&.Mui-selected": {
                            background: "#D6D6D6", // Darker shade for the selected button
                          },
                        },
                      }}
                      aria-label="Range"
                    >
                      <ToggleButton value="today" aria-label="Today">
                        Day
                      </ToggleButton>
                      <ToggleButton value="thisweek" aria-label="This Week">
                        Week
                      </ToggleButton>
                      <ToggleButton value="thismonth" aria-label="This Month">
                        Month
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>
                  <ArrowForwardIcon
                    onClick={!prodBarLoad ? handleForwardClick : undefined}
                    sx={{
                      padding: "8px", // Adds space around the icon, increasing the border size
                      color: prodBarLoad ? "action.disabled" : "inherit",
                      cursor: prodBarLoad ? "default" : "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                        borderRadius: "50%", // Makes the background round
                      },
                    }}
                  />
                </Box>
                <FormatDesiredDate
                  startDate={startDate}
                  endDate={endDate}
                  dataRange={dataRange}
                />
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 3}
            >
              <Box
                sx={{
                  ...(counter === 3 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    color: "#515151",
                    fontSize: "12px",
                  }}
                >
                  Units
                </Typography>
                <Switch checked={isCurrency} onChange={handleUnitCurrency} />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    color: "#515151",
                    fontSize: "12px",
                  }}
                >
                  NOK
                </Typography>
              </Box>
            </Tooltip>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LastUpdate />
              {/* <Typography
                variant="h7"
                sx={{
                  fontFamily: "Montserrat",
                  color: "#515151",
                  fontSize: "12px",
                }}
              >
                {startDate &&
                  endDate &&
                  `${formatDateToDesiredFormat(
                    startDate
                  )} -  ${formatDateToDesiredFormat(endDate)}`}
              </Typography> */}
            </Box>
            <Box
              sx={{
                marginBottom: { xs: "1rem", lg: 0 }, // Adds bottom margin on xs screens
              }}
            >
              <Tooltip
                title="Click here to make custom settings for the page"
                arrow
              >
                <SettingsIcon
                  onClick={handleOpenSettingsDialog}
                  sx={{
                    // position: "absolute",
                    // top: { lg: "5%", xs: "8%" },
                    // right: { lg: "2%", xs: "4%" },
                    padding: "8px", // Adds space around the icon, increasing the border size

                    cursor: "pointer", // Ensures the cursor is a pointer
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                      borderRadius: "50%", // Makes the background round
                    },
                    // Rest of your styles if you have any
                  }}
                />
              </Tooltip>
              {isXSScreen ? (
                <Tooltip
                  title=" Get a tour of the page and how it works"
                  arrow
                  placement="bottom"
                >
                  <InfoIcon
                    onClick={handleHelpMode}
                    sx={{
                      color: helpMode ? "#FFBD00" : "initial",
                      padding: "8px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                        borderRadius: "50%",
                      },
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <Typography variant="body1">Click to Close</Typography>
                  }
                  open={helpMode}
                  arrow
                  placement="top"
                >
                  <Tooltip
                    title=" Get a tour of the page and how it works"
                    arrow
                    placement="bottom"
                  >
                    <InfoIcon
                      onClick={handleHelpMode}
                      sx={{
                        color: helpMode ? "#FFBD00" : "initial",
                        padding: "8px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                          borderRadius: "50%",
                        },
                      }}
                    />
                  </Tooltip>
                </Tooltip>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 1px red",
              justifyContent: { lg: "flex-start" },
              alignItems: { lg: "flex-start", xs: "center" },
              height: { lg: "45%", xs: "100vh" },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 4}
              placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
            >
              <Box
                sx={{
                  ...(counter === 4 && helpMode ? getHighlightedStyle() : {}),
                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "25%", xs: "90%" },
                  gap: { lg: 2, xs: 3 },
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 4 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "20rem" },
                  fontFamily: "Montserrat",
                  paddingY: { lg: 0, xs: 1 },
                }}
              >
                <Box>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      <Typography
                      // variant="h6"
                      // sx={{
                      //   fontSize: "0.8rem",
                      // }}
                      >
                        Snapshot of key performance indicators{" "}
                      </Typography>
                    }
                    placement="top"
                    arrow
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        my: "0.5rem",
                        fontWeight: 400,
                        fontFamily: "'Montserrat', sans-serif",
                        flexGrow: "1",
                      }}
                    >
                      Quick Overview
                    </Typography>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    paddingBottom: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    height: "100%",
                    width: "90%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        fontWeight: 400,
                        marginRight: 2,
                      }}
                    >
                      {isCurrency ? "Basket Size" : "Units per Basket"}
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          Basket Size
                        </Typography>
                      }
                      placement="top"
                      arrow
                    >
                      <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        {prodBarLoad ? (
                          <KPILoad />
                        ) : isCurrency ? (
                          new Intl.NumberFormat("nb-NO", {
                            style: "currency",
                            currency: "NOK",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(averageBasketCostData)
                        ) : (
                          `${averageBasketCostData} units`
                        )}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        fontWeight: 400,
                        marginRight: 2,
                      }}
                    >
                      Gross Profit Margin
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          Gross Profit Margin
                        </Typography>
                      }
                      placement="top"
                      arrow
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        {prodBarLoad ? <KPILoad /> : `${grossProfitKPI} %`}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        fontWeight: 400,
                        marginRight: 2,
                      }}
                    >
                      Third Party Delivery
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          Third Party Delivery
                        </Typography>
                      }
                      placement="top"
                      arrow
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        {prodBarLoad ? <KPILoad /> : `${deliveryCost} %`}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        fontWeight: 400,
                        marginRight: 2,
                        textAlign: "left",
                      }}
                    >
                      Highest Seller
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          Highest selling product by value
                        </Typography>
                      }
                      placement="top"
                      arrow
                    >
                      <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        {prodBarLoad ? (
                          <KPILoad />
                        ) : isCurrency ? (
                          <>
                            {highestSellerByValue.product}
                            <br />(
                            {new Intl.NumberFormat("nb-NO", {
                              style: "currency",
                              currency: "NOK",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(
                              Math.round(highestSellerByValue.value / 500) * 500
                            )}
                            )
                          </>
                        ) : (
                          <>
                            {highestSellerByQuantity.product} <br />(
                            {highestSellerByQuantity.quantity} units)
                          </>
                        )}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
                {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {statStaffData}%
                </Typography>

                <Typography
                  variant="h7"
                  sx={{
                    fontWeight: 400,
                  }}
                >
                  Average Staff Cost
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {averageEmployeeProductivity} NOK
                </Typography>

                <Typography
                  variant="h7"
                  sx={{
                    fontWeight: 400,
                  }}
                >
                  Employee Productivity /hr
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {averageEmployeeCost} NOK
                </Typography>

                <Typography
                  variant="h7"
                  sx={{
                    fontWeight: 400,
                  }}
                >
                  Av. Employee Cost
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {averageDeliveryCost} NOK
                </Typography>

                <Typography
                  variant="h7"
                  sx={{
                    fontWeight: 400,
                  }}
                >
                  Av. Delivery Cost
                </Typography>
              </Box> */}
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 5}
              placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
            >
              <Box
                sx={{
                  ...(counter === 5 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "75%", xs: "90%" },
                  // gap: 2,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 5 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "30rem" },
                  // border: "solid 1px red",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontFamily: "Montserrat",
                    width: "100%",
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    title={
                      <Typography>
                        Overview of product sales performance
                      </Typography>
                    }
                    TransitionComponent={Zoom}
                    placement="top"
                    arrow
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        my: "0.5rem",
                        fontWeight: 400,
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      Product Sales
                    </Typography>
                  </Tooltip>
                  {(productMixData.length > 0 || isEmpty(productMixData)) && (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Toggle between Product Category and Product"
                      placement="top"
                      arrow
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 0,
                        }}
                      >
                        <Typography variant="h7">
                          {/* <CategoryIcon /> */}
                        </Typography>
                        <Switch
                          checked={isSubCategoryVisible}
                          onChange={handleToggleSubCat}
                        />
                        <Typography variant="h7">
                          {/* <LocalOfferIcon /> */}
                        </Typography>
                      </Box>
                    </Tooltip>
                  )}
                </Box>

                {isSubCategoryVisible ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <FormControl sx={{ minWidth: 140 }}>
                        <InputLabel id="select-label">Select Option</InputLabel>
                        <Select
                          labelId="select-label"
                          id="select"
                          size="small"
                          onChange={(e) => {
                            handleOptionChange(e.target.value);
                          }}
                        >
                          {subProducts.map((sub) => (
                            <MenuItem value={sub}>{sub}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        height: { lg: "80%", xs: "25rem" },
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {prodBarLoad ? (
                        <Box
                          sx={{
                            display: "flex",
                            mt: "5rem",
                          }}
                        >
                          <ChartLoading />
                        </Box>
                      ) : (
                        <ProdBarChart
                          data={articleGroupData}
                          isDetailedChart={false}
                          range={range}
                          isXSScreen={isXSScreen}
                          isMinimal={true}
                          isCurrency={isCurrency}
                        />
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      height: { lg: "80%", xs: "25rem" },
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    {prodBarLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          mt: "5rem",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ProdBarChart
                        data={productMixData}
                        isDetailedChart={false}
                        range={range}
                        isXSScreen={isXSScreen}
                        isMinimal={true}
                        isCurrency={isCurrency}
                        dataRange={dataRange}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 1px red",
              justifyContent: "flex-start",
              alignItems: { lg: "flex-start", xs: "center" },
              height: "48%",
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 6}
              placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
            >
              <Box
                sx={{
                  ...(counter === 6 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "30%", xs: "90%" },
                  gap: 2,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 6 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "90%", xs: "30rem" },
                  // border: "solid 1px red",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontFamily: "Montserrat",
                    width: "100%",
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    title={
                      <Typography>
                        Detailed breakdown of gross profit contribution
                      </Typography>
                    }
                    TransitionComponent={Zoom}
                    placement="top"
                    arrow
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        my: "0.5rem",
                        fontWeight: 400,
                        fontFamily: "'Montserrat', sans-serif",
                        mr: "10%",
                      }}
                    >
                      Gross Profit Contribution
                    </Typography>
                  </Tooltip>
                  {(productMixPercentageData.length > 0 ||
                    isEmpty(productMixPercentageData)) && (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Toggle between Product Category and Product"
                      placement="top"
                      arrow
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 0,
                        }}
                      >
                        <Typography variant="h7"></Typography>
                        <Switch
                          checked={isPieVisible}
                          onChange={handleTogglePie}
                        />
                        <Typography variant="h7"></Typography>
                      </Box>
                    </Tooltip>
                  )}
                </Box>
                {isPieVisible ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: "30rem",
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <FormControl sx={{ minWidth: 140 }}>
                        <InputLabel id="select-label">Select Option</InputLabel>
                        <Select
                          labelId="select-label"
                          id="select"
                          size="small"
                          onChange={(e) => {
                            handlePercentageChange(e.target.value);
                          }}
                        >
                          {subPercentageProducts.map((sub) => (
                            <MenuItem value={sub}>{sub}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        height: { lg: "15rem", xs: "25rem" },
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {grossProfitLoad ? (
                        <Box
                          sx={{
                            display: "flex",
                            mt: "7rem",
                          }}
                        >
                          <ChartLoading />
                        </Box>
                      ) : (
                        <PieChart
                          data={percentageArticleGroupData}
                          isDetailedChart={false}
                          hasDifferentColor={true}
                          isMinimal={true}
                        />
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      height: "25rem",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        height: "15rem",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {grossProfitLoad ? (
                        <Box
                          sx={{
                            display: "flex",
                            mt: "5rem",
                          }}
                        >
                          <ChartLoading />
                        </Box>
                      ) : (
                        <PieChart
                          data={productMixPercentageData}
                          isDetailedChart={false}
                          hasDifferentColor={true}
                          isMinimal={true}
                        />
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 8}
            >
              <Box
                sx={{
                  ...(counter === 8 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "35%", xs: "90%" },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 8 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "90%", xs: "30rem" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontFamily: "Montserrat",
                    width: "100%",
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    title={<Typography>Gross Profit Margins</Typography>}
                    placement="top"
                    arrow
                    TransitionComponent={Zoom}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        my: "0.5rem",
                        fontWeight: 400,
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      Gross Profit Margins
                    </Typography>
                  </Tooltip>

                  {(grossProfitMarginGroupData.length > 0 ||
                    isEmpty(grossProfitMarginGroupData)) && (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Toggle between Product Category and Product"
                      placement="top"
                      arrow
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 0,
                        }}
                      >
                        <Switch
                          checked={isSubGrossMarginVisible}
                          onChange={handleToggleSubGross}
                        />
                      </Box>
                    </Tooltip>
                  )}
                </Box>

                {isSubGrossMarginVisible && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <FormControl sx={{ minWidth: 140 }}>
                      <InputLabel id="select-label">Select Option</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        onChange={(e) =>
                          handleGrossProfitMargin(e.target.value)
                        }
                        value={subGrossMarginProduct}
                        size="small"
                        label="Select Option"
                      >
                        {subGrossMarginProducts.map((sub) => (
                          <MenuItem key={sub} value={sub}>
                            {sub}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}

                {/* Loading or Chart Rendering */}
                {grossProfitMarginLoad ? (
                  <Box
                    sx={{
                      display: "flex",
                      mt: "7rem",
                    }}
                  >
                    <ChartLoading />
                  </Box>
                ) : isSubGrossMarginVisible ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: isSubGrossMarginVisible
                        ? { lg: "70%", xs: "20rem" }
                        : { lg: "80%", xs: "25rem" },
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <PerBarChart
                      data={subGrossProfitMarginData}
                      isDetailedChart={false}
                      isHorizontal={true}
                      hasDifferentColor={true}
                      isMinimal={true}
                      // isGrossProfit={true}
                      // isNewTooltip={true}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      height: { lg: "80%", xs: "25rem" },
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <PerBarChart
                      data={grossProfitMarginGroupData}
                      isDetailedChart={false}
                      hasDifferentColor={true}
                      isMinimal={true}
                      isGrossProfit={true}
                    />
                  </Box>
                )}
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 7}
            >
              <Box
                sx={{
                  ...(counter === 7 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "50%", xs: "90%" },
                  // gap: 2,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 7 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "90%", xs: "30rem" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontFamily: "Montserrat",
                    width: "100%",
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    title={
                      <Typography>
                        The average number of items per purchase
                      </Typography>
                    }
                    TransitionComponent={Zoom}
                    placement="top"
                    arrow
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        my: "0.5rem",
                        fontWeight: 400,
                        fontFamily: "'Montserrat', sans-serif",
                        // flexGrow: "1",
                      }}
                    >
                      {isCurrency
                        ? "Average Basket Size"
                        : "Average Units per Basket"}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    height: "85%",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  {basketLoad ? (
                    <Box
                      sx={{
                        display: "flex",
                        mt: "7rem",
                      }}
                    >
                      <ChartLoading />
                    </Box>
                  ) : (
                    <LineChart
                      data={averageBasketCost}
                      isNotPercentage={true}
                      range={range}
                      startDate={startDate}
                      endDate={endDate}
                      isXSScreen={isXSScreen}
                      isMinimal={true}
                      isCurrency={isCurrency}
                    />
                  )}
                </Box>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            width: { lg: "50vw", xs: "95vw" },
            height: { lg: "70vh", xs: "80vh" },
            maxWidth: "none", // This will help override default maxWidth values
            maxHeight: "none", // This will help override default maxHeight values
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "space-between",
            width: "100%",
          }}
        >
          <DialogTitle sx={{ flexGrow: 1 }}>Settings</DialogTitle>

          <CloseIcon
            onClick={handleCloseDialog}
            sx={{
              mr: "1rem",
              cursor: "pointer", // To indicate that the icon is clickable
              borderRadius: "50%", // To make the background change rounded, like an icon
              p: "8px",
              transition: "background-color 0.3s", // Smooth transition for the hover effect
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)", // Slight gray on hover
              },
            }}
          />
        </Box>
        <DialogContent>
          <DialogContentText>
            {/* Hourly Sales Prediction Graph */}
            <Typography
              variant="h5"
              sx={{
                margin: "1rem",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              Filters
            </Typography>
            <Box sx={{}}>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl
                  lg={12}
                  md={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: { lg: "row", xs: "row" },
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    mx: ".5rem",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputProps={{
                      inputProps: {
                        min: startDate,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
                <Button
                  onClick={() => {
                    downloadDashboardTwo(
                      productMixData,
                      productMixPercentageData,
                      articleGroupData,
                      percentageArticleGroupData,
                      averageBasketCost,
                      grossProfitMarginGroupData,
                      subGrossProfitMarginData,
                      selectedRestaurant
                    );
                  }}
                  variant="contained"
                  sx={{
                    backgroundColor: "#FFBD00",

                    color: "#645e29",
                    width: "1ch",
                    // ml: "1rem",
                    // mt: ".5rem",
                    // mx: ".5rem",
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    // mb: "1.5rem",
                    "&:hover": {
                      backgroundColor: "#e3a902", // You can adjust this color based on your preference
                      // Prevent MUI Button from changing the color on hover
                      boxShadow: "none",
                    },
                    "&:active": {
                      backgroundColor: "#e3a902", // Adjust this color based on your preference
                    },
                  }}
                >
                  <CloudDownloadIcon />
                </Button>
              </FormControl>
            </Box>

            {/* Factors built into the prediction */}
            {/* <Typography
              variant="h5"
              sx={{ margin: "1rem", fontWeight: 500, textAlign: "center" }}
            >
              Aggregations
            </Typography> */}
            <Box
              sx={{
                height: "5rem",
                display: "flex",
                justifyContent: "center",
                // border: "solid 1px red",
              }}
            >
              <FormControl
                sx={{
                  width: { xs: "100%", lg: 400 },
                  alignItems: "center",
                  m: 0,
                }}
              >
                {/* <InputLabel id="range-select-label">Frequency</InputLabel> */}
                <ToggleButtonGroup
                  value={range}
                  exclusive
                  onClick={handleRangeChange}
                  sx={{
                    width: "100%",
                    "& .MuiToggleButton-root": {
                      height: "3rem",
                      background: "#F8F8F8",
                      fontSize: { xs: "0.7rem", sm: "1rem" },
                      "&.Mui-selected": {
                        background: "#D6D6D6", // Darker shade for the selected button
                      },
                    },
                  }}
                  aria-label="Range"
                >
                  {/* <ToggleButton value="hour" aria-label="Hourly">
                    Hourly
                  </ToggleButton>
                  <ToggleButton value="day" aria-label="Daily">
                    Daily
                  </ToggleButton>
                  <ToggleButton value="week" aria-label="Weekly">
                    Weekly
                  </ToggleButton>
                  <ToggleButton value="month" aria-label="Monthly">
                    Monthly
                  </ToggleButton> */}
                </ToggleButtonGroup>
              </FormControl>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openHelpDialog}
        onClose={() => setOpenHelpDialog(false)}
        aria-labelledby="help-dialog-title"
        aria-describedby="help-dialog-description"
      >
        <DialogTitle id="help-dialog-title">{"Help Information"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="help-dialog-description">
            This help button is designed to provide you with additional
            information and guidance on how to use the features of this
            application. Clicking this button will display useful tips and FAQs
            to help you navigate and make the most of the app's functionalities.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenHelpDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={open}
        severity={severity}
        message={message}
        onClose={handleClose}
      />
      {helpMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            width: { lg: "180px", xs: "280px" },
            position: "fixed", // Make it absolute to position at the bottom
            bottom: 20,
            right: 20,
            padding: "10px",
            // border:"solid 1px red"
          }}
        >
          <NavigationButtons
            isXSScreen={isXSScreen}
            setHelpMode={setHelpMode}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            counter={counter}
            maxSteps={maxSteps}
          />
        </Box>
      )}
    </Box>
  );
};

export default NewSuperDashboardTwo;
