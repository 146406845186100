import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  // IconButton,
  Typography,
  Button,
  Divider,
  Collapse,

  // IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";

const theme = createTheme({
  typography: {
    fontFamily: "Lexend, sans-serif",
  },
});

const Faq = () => {
  const faqs = [
    {
      question: "Hvor mye koster det?",
      answer:
        "Krunch tar betalt per restaurant per måned. Prisen avhenger av hvor mange restauranter du har. Jo flere\n" +
        "restauranter, jo lavere pris per restaurant. Ta kontakt med oss for å få et tilbud.",
    },
    {
      question: "Hva skjer hvis jeg ikke er fornøyd?",
      answer:
        "Hvis du ikke er fornøyd med Krunch har vi kun 30 dagers oppsigelsestid. Det gjør vi fordi vi ønsker å redusere \n" +
        "din nedsiderisiko, samtidig som at vi har så stor tro på produktet at vi er villige til å ta risikoen",
    },
    {
      question: "Passer Krunch for alle restauranter?",
      answer:
        "Krunch passer best for dem som eier eller drifter flere restauranter eller dem som har \n" +
        "med relativt høyt antall ansatte og salg",
    },
    {
      question: "Hvor gode er prediksjonene?",
      answer:
        "Salgsprediksjonene treffer som regel med 0-20% avvik fra faktisk salg, men enkelte dager kan ha \n" +
        "høyere avvik pga hendelser vi ikke har fanget opp.",
    },
    {
      question: "Hva predikerer dere?",
      answer:
        "Vi har en rekke prediksjoner. Målet er at du som restaurantleder skal ha en helhetlig oversikt \n" +
        "over hvordan din aktivitet og dine behov ser ut både bak i tid, akkurat nå og frem i tid. \n" +
        "Vi predikerer blant annet salg, produktmiks, take-away andel, sykefravær, bemanningsbehov, \n" +
        "og behov for vareinnkjøp",
    },
    {
      question: "Er dataen min sikker hos Krunch?",
      answer:
        "Ja. All din data ligger i vår Microsoft Azure database lokalisert i Norge. Microsoft opererer med \n" +
        "en av bransjens høyeste IT-sikkerhetsstandarer, og er kjent for god sikkerhet",
    },
  ];

  const navigate = useNavigate();

  const [open, setOpen] = useState(Array(faqs.length).fill(false));

  const handleToggle = (index) => {
    const updatedOpen = [...open];
    updatedOpen[index] = !updatedOpen[index];
    setOpen(updatedOpen);
  };
  const handlenewContact = () => {
    navigate("/contact");
    //window.scrollTo(0, 0);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box // Main Parent Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignContent: "center",
          //border: "1px solid red",
          //boxSizing: "//border-box",
          alignItems: "center",
          background:
            "linear-gradient(to right, #FFF4BF 0%, #FFF4BF 50%, white 50%, white 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // border: "solid 1px green",
            width: "100%",
            boxSizing: "//border-box",
            alignItems: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              // border: "solid 1px purple",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "130vh" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: { xs: 1300, xl: 1600 },
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column" },
                }}
              >
                <Grid
                  item
                  lg={4}
                  xs={12}
                  sx={{
                    // border: "solid 1px blue",
                    display: "flex",
                    justifyContent: {
                      lg: "center",
                      xs: "center",
                      xl: "flex-start",
                    },
                    alignItems: { lg: "center", xs: "center", xl: "" },
                    flexDirection: { xs: "column", lg: "row" },
                    background: "#FFF4BF",
                    minHeight: { lg: "100%", xs: "60vh" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      // border: "solid 1px red",
                      height: { lg: "40%", xs: "90%" },
                      width: { xs: "80%", xl: "100%" },
                      gap: { xs: 2, xl: 3 },
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: { xs: "25px", xl: "35px" },
                        wordWrap: "break-word", // This makes it break
                        hyphens: "auto", // This adds hyphens if needed and supported
                      }}
                    >
                      Kontakt oss for ytterligere støtte
                    </Typography>

                    <Typography
                      color={"#4A4C50"}
                      sx={{
                        textAlign: "left",
                        fontSize: { xs: "14px", xl: "20px" },
                      }}
                    >
                      Teamet vårt er klar til å støtte deg 24/7
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={handlenewContact}
                        sx={{
                          width: { xs: "13ch", xl: "15ch" },
                          background: "#FFBD00",
                          color: "black",
                          "&:hover": {
                            backgroundColor: "#e3a902", // You can adjust this color based on your preference
                            // Prevent MUI Button from changing the color on hover
                            boxShadow: "none",
                          },
                          "&:active": {
                            backgroundColor: "#e3a902", // Adjust this color based on your preference
                          },
                          fontWeight: 700,
                          fontSize: { xs: "14px", xl: "18px" },
                          //borderRadius: "5px",
                        }}
                      >
                        Kontakt oss
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={8}
                  xs={12}
                  sx={{
                    display: { lg: "flex", xs: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "solid 1px blue",
                    flexDirection: "column",
                    minHeight: { lg: "100%", xs: "100vh" },
                    background: "#FFF",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "10%",
                      width: "70%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: { xs: "30px", xl: "50px" },
                        wordWrap: "break-word", // This makes it break
                        hyphens: "auto", // This adds hyphens if needed and supported
                      }}
                    >
                      Frequently Asked Questions
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: { xs: "80%", xl: "70%" },
                      width: "85%",
                      justifyContent: { xs: "center", xl: "flex-start" },
                      alignItems: "center",
                      // border: "solid 1px green",
                      mt: { xs: 0, xl: "8rem" },
                    }}
                  >
                    {faqs.map((faq, index) => (
                      <React.Fragment key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            flexDirection: "column",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                textAlign: "left",
                                fontSize: { xs: "16px", xl: "25px" },
                                // border:"1px solid orange"
                              }}
                            >
                              {faq.question}
                            </Typography>
                            <IconButton
                              sx={{
                                width: "10%",
                              }}
                              onClick={() => handleToggle(index)}
                            >
                              <ExpandMoreIcon
                                style={{
                                  transform: open[index]
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                }}
                              />
                            </IconButton>
                          </Box>
                        </Box>
                        <Collapse in={open[index]}>
                          <Typography
                            sx={{
                              mb: 1,
                              textAlign: "left",
                              fontSize: { xs: "14px", xl: "20px" },
                            }}
                            variant="body2"
                          >
                            {faq.answer}
                          </Typography>
                        </Collapse>
                        {index !== faqs.length - 1 && (
                          <Divider
                            sx={{ width: "100%", my: 1, height: "1px" }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Faq;
