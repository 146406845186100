import React from "react";
import { ResponsiveBar } from "@nivo/bar";
// import { RoundValue } from "../utils/RoundValue";

const TopTenBarChart = ({
  hasDifferentColor = false,
  data,
  // range,
  isPercentage = false,
  isHorizontal = false,
  isXSScreen = false,
  isProductivityGraph = false,
  productLabel = false,
  isMinimal = false,
  isCurrency = false,
  // dataRange = false,
  // isGrossProfit = false,
}) => {

  const format = isPercentage ? (v) => `${v}%` : (v) => `${v} NOK`;
  const lineData = data && Object.entries(data).length !== 0 ? [data] : [];
  const sortedData = [...data].sort((a, b) => a.value - b.value);
  const margin = isMinimal
    ? { top: 30, right: 50, bottom: 60, left: 80 } // Adjust these values as needed for minimal view
    : { top: 30, right: 80, bottom: 100, left: isPercentage ? 100 : 120 };

  const getBarTickValues = (data, isXSScreen, productLabel) => {
    // Extract all 'id' values from the dataset
    const allIds = data.map((d) => d.id);

    let tickValues;
    if (isXSScreen) {
      // For small screens, get the start, middle, and end ids
      tickValues = [
        allIds[0],
        allIds[Math.floor(allIds.length / 2)],
        allIds[allIds.length - 1],
      ];
    } else {
      if (productLabel) {
        return allIds;
      }
      // For larger screens, divide the ids into 6 segments and get them
      tickValues = [];
      for (let i = 0; i <= 5; i++) {
        tickValues.push(allIds[Math.floor((allIds.length - 1) * (i / 5))]);
      }
    }
    return tickValues;
  };
  const isValidDate =
    data &&
    data.length > 0 &&
    data.every((entry) => !isNaN(new Date(entry.id).getTime()));

  // Conditionally set the tickValues for the x-axis based on isValidDate

  const EmpProductivityTooltip = ({ data }) => {
    // Check if 'id' is a valid date
    const tooltipDate = new Date(data.id);
    const isValidDate = !isNaN(tooltipDate.getTime());

    if (isValidDate) {
      // Formatting the date
      const formattedDate = tooltipDate.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      // Formatting the value as NOK currency
      const formattedValue = new Intl.NumberFormat("nb-NO", {
        style: "currency",
        currency: "NOK",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(data.value);

      return (
        <div
          style={{
            background: "white",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          }}
        >
          <p style={{ fontWeight: 700, margin: "0 0 5px 0" }}>
            {formattedDate}
          </p>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              color: data.Color,
              margin: "0",
            }}
          >
            <strong>{formattedValue}</strong>
          </p>
        </div>
      );
    } else {
      // Default content when 'id' is not a valid date
      return (
        <div
          style={{
            background: "white",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          }}
        >
          <p style={{ margin: 0, fontWeight: 700, color: data.Color }}>
            <strong>
              {data.id}:{" "}
              {!isCurrency
                ? `${data.value} units`
                : new Intl.NumberFormat("nb-NO", {
                    style: "currency",
                    currency: "NOK",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(data.value)}
            </strong>
          </p>
        </div>
      );
    }
  };

  const CustomTooltip = ({ data }) => {
    return (
      <div
        style={{
          background: "white",
          padding: "5px",
          // border: "1px solid #ccc",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          // Center the content vertically
        }}
      >
        {/* Container for colored square and text to align them horizontally */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center", // Center the content vertically in this row
            width: "100%",
          }}
        >
          {/* Display a colored square using data.Color */}
          <span
            style={{
              width: "20px",
              height: "20px",
              background: data.Color,
              marginRight: "10px", // Add some space to the right of the colored square
            }}
          ></span>

          {/* Display the text as data.id : data.value */}
          <span
            style={{
              fontWeight: 700,
              borderBottom: "1px solid rgba(128, 128, 128, 0.3)", // Faint gray with 30% opacity
            }}
          >
            {`${data.id} : `}
            {isCurrency
              ? new Intl.NumberFormat("nb-NO", {
                  style: "currency",
                  currency: "NOK",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(data.value)
              : `${data.value}`}
          </span>
        </div>

        {/* Display a small message using data.day_count */}
        <small>There were {data.day_count} days in this interval</small>
      </div>
    );
  };

  // const secondaryTickValues = data.map((entry) => entry.id);

  // Conditionally set the tickValues for the x-axis based on isValidDate
  const tickValues = isValidDate
    ? getBarTickValues(data, isXSScreen, productLabel)
    : [];
  const layoutProps = isMinimal
    ? {
        axisLeft: null,
        enableGridX: false,
        enableGridY: false,
        axisBottom: {
          // tickValues: undefined,
        },
      }
    : isHorizontal
    ? {
        layout: "horizontal",
        axisBottom: null,
        axisLeft: {
          format,
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legend: isXSScreen
            ? undefined
            : isPercentage
            ? "Percentage"
            : "Productivity Per Hour",
          legendPosition: "middle",
          legendOffset: isPercentage ? -40 : -90,
        },
        margin: { top: 50, right: 50, bottom: 0, left: 250 }, // Add more right margin for horizontal layout
      }
    : {
        layout: "horizontal",
        axisLeft: {
          format,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: isXSScreen
            ? undefined
            : isPercentage
            ? "Percentage"
            : "Productivity Per Hour",
          legendPosition: "middle",
          legendOffset: isPercentage ? -40 : -80,
        },
        axisBottom: {
          undefined,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: isPercentage ? "Product Categories" : "Restaurants",
          legendPosition: "middle",
          legendOffset: 32,

          tickValues: tickValues,
          // tickValues: data.map((entry) => entry.id),
        },
        // axisBottom: null,
      };

  let minYValue;
  let maxYValue;
  if (data && data.length > 0) {
    minYValue = Math.min(...data.map((entry) => entry.value));
    maxYValue = Math.max(...data.map((entry) => entry.value));
  }
  if (!isPercentage) {
    // For NOK
    minYValue = Math.round(minYValue / 10000) * 10000;
    if (maxYValue < 5500) {
      maxYValue = Math.round(maxYValue / 100) * 100; // Round to the nearest 100
    } else {
      maxYValue = Math.round(maxYValue / 10000) * 10000; // Round to the nearest 10000
    }
  } else {
    minYValue = 0;
    maxYValue = Math.round(maxYValue / 10) * 10;
  }


  return !lineData || lineData.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        // border: "solid red 1px",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    <ResponsiveBar
      data={sortedData}
      keys={["value"]}
      indexBy="id"
      layout="horizontal"
      padding={0.3}
      enableGridX={false}
      enableGridY={false}
      reverse={false}
      // legends={[
      //   {
      //     dataFrom: "indexes",
      //     anchor: "bottom-right",
      //     direction: "column",
      //     justify: false,
      //     translateX: 10,
      //     translateY: 10,
      //     itemsSpacing: 3,
      //     itemWidth: 80,
      //     itemHeight: 15,
      //     itemDirection: "left-to-right",
      //     itemOpacity: 0.85,
      //     symbolSize: 10,
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemOpacity: 1,
      //         },
      //       },
      //     ],
      //   },
      // ]}
      //   valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={
        hasDifferentColor ? (data) => data.data.Color : { scheme: "pastel2" }
      }
      borderRadius={5}
      margin={margin}
      // borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      {...layoutProps}
      //   // gridYValues={[minYValue, maxYValue]}
      // axisBottom={
      //   isGrossProfit
      //     ? {
      //         tickValues:
      //           secondaryTickValues.length < 3 ? secondaryTickValues : [],
      //       }
      //     : {
      //         tickSize: 5,
      //         tickPadding: 5,
      //         tickRotation: 0, // Adjust the rotation angle to make labels visible
      //         legendPosition: "middle",
      //         format: (value) => {
      //           if (isValidDate) {
      //             const date = new Date(value);
      //             const formattedDate = date.toLocaleDateString(undefined, {
      //               // year: "numeric",
      //               month: "short",
      //               day: "numeric",
      //             });
      //             return formattedDate;
      //           }
      //           return ""; // Return an empty string if there are no valid dates
      //         },
      //         tickValues: undefined,
      //       }
      // }
      axisBottom={{
        tickValues: [minYValue, maxYValue],
      }}
      axisLeft={{
        format: (value) =>
          value.length > 10 ? `${value.substring(0, 10)}...` : value,
        tickSize: 5,
        tickPadding: 10,
        tickRotation: 0,
        tickValues: data.map((d) => d.id), // Use the id from each data point as tick values
      }}
      valueScale={{ type: "linear", min: "auto", max: "auto", reverse: false }}
      enableLabel={false}
      labelSkipWidth={0}
      labelSkipHeight={0}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltip={isProductivityGraph ? CustomTooltip : EmpProductivityTooltip}
    />
  );
};
export default TopTenBarChart;
