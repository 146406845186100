import * as XLSX from "xlsx";

const downloadTrends = (
  positiveAmountTrendData,
  negativeAmountTrendData,
  positiveQuantityTrendData,
  negativeQuantityTrendData,
  superGroupProductMixData,
  groupProductMixData,
  barChartData, // Add this parameter
  restaurantNames
) => {
  const fileName =
    restaurantNames && restaurantNames.length > 0
      ? restaurantNames
      : "Company Overall Data";
  const wb = XLSX.utils.book_new();

  // Process Positive Amount Trend Data
  const processedPositiveAmountData = positiveAmountTrendData.map((item) => ({
    "Article Group": item.article_group,
    "Percentage Difference": item.percentageDifference,
  }));

  if (processedPositiveAmountData.length > 0) {
    const wsPositiveAmount = XLSX.utils.json_to_sheet(
      processedPositiveAmountData
    );
    XLSX.utils.book_append_sheet(wb, wsPositiveAmount, "Positive Amount Trend");
  }

  // Process Negative Amount Trend Data
  const processedNegativeAmountData = negativeAmountTrendData.map((item) => ({
    "Article Group": item.article_group,
    "Percentage Difference": item.percentageDifference,
  }));

  if (processedNegativeAmountData.length > 0) {
    const wsNegativeAmount = XLSX.utils.json_to_sheet(
      processedNegativeAmountData
    );
    XLSX.utils.book_append_sheet(wb, wsNegativeAmount, "Negative Amount Trend");
  }

  // Process Positive Quantity Trend Data
  const processedPositiveQuantityData = positiveQuantityTrendData.map(
    (item) => ({
      "Article Group": item.article_group,
      "Sale Quantity": item.qty_sold,
      "Average Quantity Sold": item.average_qty_sold,
      "Sale Amount": item.sale_amount,
      "Net Quantity Trend": item.net_qty_trend,
      "Percentage Difference": (
        ((item.qty_sold - item.average_qty_sold) / item.average_qty_sold) *
        100
      ).toFixed(2),
    })
  );

  if (processedPositiveQuantityData.length > 0) {
    const wsPositiveQuantity = XLSX.utils.json_to_sheet(
      processedPositiveQuantityData
    );
    XLSX.utils.book_append_sheet(
      wb,
      wsPositiveQuantity,
      "Positive Quantity Trend"
    );
  }

  // Process Negative Quantity Trend Data
  const processedNegativeQuantityData = negativeQuantityTrendData.map(
    (item) => ({
      "Article Group": item.article_group,
      "Sale Quantity": item.qty_sold,
      "Average Quantity Sold": item.average_qty_sold,
      "Sale Amount": item.sale_amount,
      "Net Quantity Trend": item.net_qty_trend,
      "Percentage Difference": (
        ((item.qty_sold - item.average_qty_sold) / item.average_qty_sold) *
        100
      ).toFixed(2),
    })
  );

  if (processedNegativeQuantityData.length > 0) {
    const wsNegativeQuantity = XLSX.utils.json_to_sheet(
      processedNegativeQuantityData
    );
    XLSX.utils.book_append_sheet(
      wb,
      wsNegativeQuantity,
      "Negative Quantity Trend"
    );
  }

  // Process Super Group Product Mix Data
  if (superGroupProductMixData?.length > 0) {
    const wsSuperGroupProductMixData = XLSX.utils.json_to_sheet(
      superGroupProductMixData
    );
    XLSX.utils.book_append_sheet(
      wb,
      wsSuperGroupProductMixData,
      "Product Mix Data"
    );
  }

  // Process Group Product Mix Data
  if (groupProductMixData?.length > 0) {
    const wsProductMixData = XLSX.utils.json_to_sheet(groupProductMixData);
    XLSX.utils.book_append_sheet(wb, wsProductMixData, "Sub Product Mix Data");
  }

  // Process Bar Chart Data
  if (barChartData?.length > 0) {
    const processedBarChartData = barChartData.map((item) => ({
      ID: item.id,
      Value: item.value,
    }));

    if (processedBarChartData.length > 0) {
      const wsBarChartData = XLSX.utils.json_to_sheet(processedBarChartData);
      XLSX.utils.book_append_sheet(wb, wsBarChartData, "Top Ten Sellers");
    }
  }

  // Write Excel file and trigger download
  if (wb.SheetNames.length > 0) {
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  } else {
    alert("No data available to download!");
  }
};

export default downloadTrends;
