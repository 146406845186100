import React from "react";
import { ResponsiveLine } from "@nivo/line";
import formatNumber from "../../utils/FormatNumber";

const HourlyLineChart = ({
  data,
  startDate,
  endDate,
  isCombinedChart = false,
  isDailyData = false,
  isNotPercentage = false,
  isXSScreen = false,
}) => {
  const format = !isNotPercentage ? (v) => `${v}%` : (v) => `${v} NOK`;
  let interval;

  let combinedData = data ? data : null;
  if (isDailyData) {
    interval = 2;
  } else {
    if (!(startDate && endDate)) {
      interval = 28;
    } else {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      interval = (endDateObj - startDateObj) / (1000 * 60 * 60 * 24) + 1;
      if (interval === 0) {
        interval = 1; // Default value if dates are the same
      }
    }
  }

  const colors = [
    // "#E191B1", // Light Pink
    // "#2FEBAA", // Mint Green
    "#543c8c",
    "#e394b4",

    "#949494",

    "#70335D", // Dark Plum
    // "#91FAE0", // Turquoises
    "#17CB1B",

    "#2A5F8B", // Steel Blue

    // "#E63946",
    // "#40E0D0",
    // "#4169E1",
    "#bcbd22", //green
    // "#2ca02c",
    // "#d62728",
    // "#9467bd",
    // "#8c564b",
    "#e377c2",
    "#7f7f7f",
    "#17becf",
  ];

  const sliceTooltip = ({ slice }) => {
    const date = new Date(slice.points[0].data.x);
    const formattedDate =
      `${date.toLocaleString("default", { weekday: "long" })}, ` + // Adds weekday
      `${String(date.getDate()).padStart(2, "0")} ` +
      `${date.toLocaleString("default", { month: "short" })}, ` +
      `${date.getFullYear()} ` + // Adds the year
      `${String(date.getHours()).padStart(2, "0")}:` +
      `${String(date.getMinutes()).padStart(2, "0")}`;

    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          borderRadius: "2px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
        }}
      >
        <strong>{formattedDate}</strong>
        <br />
        {slice.points.map((point) => (
          <strong key={point.id} style={{ color: point.serieColor }}>
            {point.serieId}:{" "}
            {isNotPercentage
              ? formatNumber(point.data.y)
              : `${point.data.yFormatted}%`}
            <br />
          </strong>
        ))}
      </div>
    );
  };

  return (
    combinedData && (
      <ResponsiveLine
        animate
        // enableGridX={true}
        // enableGridY={true}
        axisBottom={{
          format: (value) => {
            const date = new Date(value);
            // Using toLocaleTimeString to format the time in 12-hour format
            return date.toLocaleTimeString([], {
              hour: "2-digit",
              // minute: "2-digit",
              hour12: false, // Ensures 12-hour format
            });
          },
          tickValues: `every ${interval} hours`,
        }}
        axisLeft={{ format }}
        curve="monotoneX"
        // colors={{ scheme: "category10" }}
        data={combinedData}
        margin={{
          bottom: 60,
          left: 80,
          right: 20,
          top: 20,
        }}
        pointBorderColor={{
          from: "color",
          modifiers: [["darker", 0.3]],
        }}
        lineWidth={1}
        colors={colors}
        pointBorderWidth={1}
        pointSize={4}
        enableSlices="x"
        sliceTooltip={sliceTooltip}
        enableArea={true}
        areaBaselineValue={0}
        areaOpacity={0.15}
        useMesh
        xScale={{
          type: "time",
          format: "%Y-%m-%d %H:%M",
          precision: "hour",
          useUTC: false,
        }}
        yScale={{
          type: "linear",
        }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 2,
            translateY: 45,
            itemsSpacing: 10,
            itemDirection: "left-to-right",
            itemWidth: 109,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    )
  );
};

export default HourlyLineChart;
