import * as XLSX from "xlsx";

const downloadExcel = (raw_data, fileName) => {
  const wb = XLSX.utils.book_new();

  // Assuming raw_data is an array of objects
  const processedData = raw_data.map((item) => {
    // Process each item as needed
    const formattedDate = new Date(item.date)
      .toISOString()
      .replace("T", " ")
      .slice(0, 19);

    return {
      "Invoice Id": item.invoice_id,
      "Invoice Type": item.invoice_type,
      Rescue: item.rescue,
      "Transaction Time": formattedDate,
      "Gastronomic Day": item.gastronomic_day,
      Table: item.table,
      "Table Party": item.table_party,
      "Number of Guests": item.number_of_guests,
      "Waiter Id": item.waiter_id,
      "Waiter Name": item.waiter_name,
      "Article Name": item.article_name,
      "Article Shortname": item.article_shortname,
      "Article Group": item.article_group,
      "Article Supergroup": item.article_supergroup,
      "Article Supergroup Id": item.article_supergroup_id,
      Unit: item.unit,
      "Measurement Amount": item.measurement_amount,
      Quantity: item.qty,
      PLU: item.plu,
      "Unit Price Listed": item.unit_list_price,
      "VAT Rate": `${item.vat_rate}%`,
      "Discount Applied": item.discount_applied,
      "Discount Names": item.discount_name,
      "Total Gross": item.total_gross,
      "Total Net": item.total_net,
      Tax: item.tax,
      Currency: item.currency,
      "Settlement Forms": item.settlement_forms,
      "Take Out": item.take_out,
      "None Turnover Article": item.non_turnover_article,
      "Article Mode": item.article_mode,
      "Day Name": item.day_name,
      Month: item.month,
      Cost: item.cost,
      "User Name": item.user_name,
      "Register Name": item.register_name,
      Variant: item.variant,
      "Retail Price": item.retail_price,
      "Discount Description": item.discount_description,
      "Discount Percentage": item.discount_percentage,
      "Customer Name": item.customer_name,
      Restaurant: item.restaurant,
      Company: item.company,
    };
  });

  // Check if there is data to add
  if (processedData.length > 0) {
    const ws = XLSX.utils.json_to_sheet(processedData);
    XLSX.utils.book_append_sheet(wb, ws, "Data Sheet");
  }

  // Check if workbook has any sheets before saving
  if (wb.SheetNames.length > 0) {
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  } else {
    alert("No data available to download!");
  }
};

export default downloadExcel;
