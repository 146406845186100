// TooltipSheet.js
const TooltipSheet = {
  //profit and loss
  key_metrics_profit: {
    title: "Key Metrics",
    content: "Detailed breakdown of employee-related expenses",
  },
  sales_and_profit: {
    title: "Sales and Profit",
    content:
      "See how your sales and profit develop in a daily, weekly and monthly basis. Sales at e.g 12 (12pm) represents the sales between 12:00-12:59. The second line indicates the predicted staff cost between (15-30)days",
  },
  product_sales_profit: {
    title: "Product Sales",
    content:
      "See the percentage share of sales from each product category or product",
  },
  staff_cost: {
    title: "Are we keeping staff cost low",
    content:
      " Staff cost percentage is often one of the most important KPIs for food & beverage companies. This tells you how many percent of the net sales goes directly to staff cost. We normally use staff cost with social costs, unless your company has chosen otherwise",
  },
  // sales_metrics: {
  //   title: "Sales",
  //   content: "The sales without MVA for the select restaurants and date period",
  // },
  //sales prediction
  sales_prediction: {
    title: "Sales Prediction",
    content:
      "This graph shows you how various parameters such as concerts, weather, marketing campaigns etc. is expected to affect sales.",
  },
  events: {
    title: "Events",
    content:
      "Use this graph to see all factors for a specific day and how they affect the sales prediction",
  },
  weather: {
    title: "Weather",
    content:
      "Use this graph to see all factors for a specific day and how they affect the sales prediction",
  },
  factors: {
    title: "Factors for a specific day",
    content:
      "Use this graph to see all factors for a specific day and how they affect the sales prediction",
  },
  all_factors: {
    title: "All Factors",
    content:
      "This table shows all factors that affect the sales predictions. You can filter the table to focus on factors for a specific restaurant, time period, type of factor and more",
  },
  //comparison
  sales: {
    title: "Sales",
    content:
      " The sales without MVA for the select restaurants and date period",
  },
  gross_profit_i: {
    title: "Gross Profit I",
    content: "Gross Profit I is total sales minus cost of goods",
  },
  gross_profit_ii: {
    title: "Gross Profit II",
    content:
      "Gross Profit II is Gross Profit I minus employee cost, third party delivery cost and other fixed costs",
  },
  cost_of_goods: {
    title: "Cost of Goods",
    content:
      "Cost of Goods is the amount of goods that were sold during the period.",
  },
  employee_cost_comp: {
    title: "Employee Cost",
    content: "Detailed breakdown of employee-related expenses",
  },
  //staff insight page
  employee_cost: {
    title: "Employee Cost",
    content: "Detailed breakdown of employee-related expenses",
  },
  sick_cost: {
    title: "Sick Cost",
    content:
      "Sick cost is the compensation paid to the employees during sick leave.",
  },
  staff_percent: {
    title: "Staff Cost Percentage",
    content:
      "Staff Cost is the percentage of employee cost to total sales (without VAT/MVA). This graph shows how staff cost varies with sales. The staff Cost Percentage goal is 28%.",
  },
  emp_productivity: {
    title: "Average Employee Productivity",
    content: "Measure of output per work hour",
  },
  key_metrics: {
    title: "Average Employee Productivity",
    content: "This is the most important metrics related to staff",
  },
  // product insight
  quick_overview: {
    title: "Quick Overview",
    content: "Snapshot of key performance indicators",
  },
  product_sales: {
    title: "Product Sales",
    content: "Overview of product sales performance",
  },
  gross_profit_contribution: {
    title: "Gross Profit Contribution",
    content: "Detailed breakdown of gross profit contribution",
  },
  gross_profit_margin: {
    title: "Gross Profit Margin",
    content:
      "Gross Profit Margin shows the portion of earning after subtracting the cost of goods sold.",
  },
  basket_size: {
    title: "Average Basket Size",
    content: "The average number of items per purchase",
  },
  basket_size_kpi: {
    title: "Basket Size",
    content: "The average number of items per purchase",
  },
  third_party_delivery_kpi: {
    title: "Third Party Delivert Cost ",
    content: "Third Party Delivery Cost",
  },
  //trends
  top_ten: {
    title: "Top 10 Sellers",
    content: "See top ten sellers",
  },
  product_sales_overtime: {
    title: "Product Sales OverTime",
    content:
      "Compare current products against the same relevant weekday last year",
  },
  take_away: {
    title: "Take Away vs Dine",
    content:
      "Take-out share versus dine-in. this can help you understand how many employees you need at work, as high take-away share normally requires fewer employees at work. Combine this with the sales prediction to create a great staff plan",
  },
  delivery_cost: {
    title: "Third Party Delivery Cost",
    content: "Cost associated with the delivery of products",
  },
  //Admin page
  fixed_cost: {
    title: "Fixed cost",
    content: "Set the fixed costs of your restaurant",
  },
  financial_goal: {
    title: "Financial Goal",
    content: "Set the financial goals of your restaurant",
  },
  opening_hours: {
    title: "Opening Hours",
    content: "Set opening and closing hours of your restaurant",
  },
  prediction: {
    title: "Prediction",
    content: "Set the prediction of your restaurant",
  },
};

export default TooltipSheet;
