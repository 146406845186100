import React from "react";
import Typography from "@mui/material/Typography"; // Assuming you're using MUI for Typography

const FormatDesiredDate = ({ dataRange, startDate, endDate, style }) => {
  const formatDateToDesiredFormat = (dateString) => {
    const date = new Date(dateString);
    const formatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    if (dataRange === "today") {
      formatOptions.weekday = "short";
    }
    // You could add more conditions here for different ranges if needed

    return date.toLocaleDateString(undefined, formatOptions);
  };

  // Default styles are defined here
  const defaultStyles = {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Montserrat",
    color: "#515151",
    fontSize: "12px",
    alignItems: "center",
    justifyContent: "flex-end",
    mt: 1,
    fontWeight: 700,
  };

  return (
    <Typography
      variant="h6" // Changed from h7 to h6 as h7 is not a valid variant in MUI
      sx={{ ...defaultStyles, ...style }} // Merging default styles with the style prop
    >
      {dataRange === "today"
        ? formatDateToDesiredFormat(startDate)
        : startDate &&
          endDate &&
          `${formatDateToDesiredFormat(
            startDate
          )} - ${formatDateToDesiredFormat(endDate)}`}
    </Typography>
  );
};

export default FormatDesiredDate;
