import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import formatKpiNumber from "../../utils/formatKpiNumber";
import ChartLoading from "../../components/Loading/ChartLoading";

const BasicOverviewTable = ({
  TotalData = [],
  isFlatKPIVisible = true,
  profitLoad = false,
  staffLoad = false,
  deliveryCostLoad = false,
  averageEmployeeCost = null,
  averageEmployeeProductivity = null,
  statStaffData = null,
  GrossProfitPercentage = null,
  GrossProfitIIPercentage = null,
  totalSalesStatGoal = null,
  totalGrossProfitStatGoal = null,
  totalNetProfitStatGoal = null,
  load = false,
  dailyStaffLoad = false,
  productivityLoad = false,
}) => {
  // console.log(statStaffData, "total a here");

  // console.log(averageEmployeeCost, "total d here");
  // console.log(averageEmployeeProductivity, "total e here");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timer;
    if (
      !profitLoad &&
      !deliveryCostLoad &&
      !staffLoad &&
      !load &&
      !dailyStaffLoad &&
      !productivityLoad
    ) {
      timer = setTimeout(() => {
        setLoading(false);
      }, 4000);
    } else {
      setLoading(true);
    }

    return () => clearTimeout(timer);
  }, [
    profitLoad,
    deliveryCostLoad,
    staffLoad,
    load,
    dailyStaffLoad,
    productivityLoad,
  ]);

  if (
    profitLoad ||
    deliveryCostLoad ||
    staffLoad ||
    load ||
    dailyStaffLoad ||
    productivityLoad
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {/* Adjust height as needed */}
        <ChartLoading />
      </Box>
    );
  }

  if (
    TotalData.length === 0 ||
    !TotalData ||
    !averageEmployeeCost ||
    averageEmployeeCost.length === 0 ||
    !statStaffData ||
    statStaffData.length === 0 ||
    !averageEmployeeProductivity
    // averageEmployeeProductivity.length === 0
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {loading ? <ChartLoading /> : <h2>No Data</h2>}
        {/* Adjust height as needed */}
      </Box>
    );
  }

  //Styles
  const nameCellStyle = {
    py: 0.5,
    fontFamily: "'Montserrat', sans-serif",
    backgroundColor: "transparent",
    padding: {
      lg: "4px 6px",
      xs: "6px 8px",
    },
    fontSize: "0.875rem",
    overflow: "hidden",
  };

  const valueCellStyle = {
    py: 0.5,
    fontWeight: 700,
    fontFamily: "'Montserrat', sans-serif",
    backgroundColor: "transparent",
    padding: {
      lg: "4px 6px",
      xs: "6px 8px",
    },
    overflow: "hidden",
  };

  const boxStyle = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  };

  const generateTooltipContent = (
    currentValue,
    goalValue,
    dataType,
    isPrediction
  ) => {
    // Function to format the goal achievement text
    const formatGoalText = (current, goal) => {
      const percentageReached = (current / goal) * 100;
      const predictionText = isPrediction ? "predicted total" : "actual total";
      return `Your ${predictionText} is ${percentageReached.toFixed(
        1
      )}% of the ${new Intl.NumberFormat("nb-NO", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(goal)} goal.`;
    };

    // Check for goal value existence and validity
    if (goalValue && !isNaN(goalValue) && goalValue !== 0) {
      return (
        <Typography sx={{ fontSize: "0.875rem" }}>
          {formatGoalText(currentValue, goalValue)}
        </Typography>
      );
    } else {
      switch (dataType) {
        case "sales":
          return (
            <Typography sx={{ fontSize: "0.875rem" }}>
              The sales without MVA for the select restaurants and date period
            </Typography>
          );
        case "gross profit":
          return (
            <Typography sx={{ fontSize: "0.875rem" }}>
              Gross profit is sales without MVA minus the cost of goods
            </Typography>
          );
        case "net profit":
          return (
            <Typography sx={{ fontSize: "0.875rem" }}>
              Net profit is gross profit minus all fixed costs, like employee
              cost, rent, delivery cost and other fixed cost elements{" "}
            </Typography>
          );
        default:
          return (
            <Typography sx={{ fontSize: "0.875rem" }}>
              Not Recognized
            </Typography>
          );
      }
    }
  };

  const compareValues = (currentValue, goalValue) => {
    if (profitLoad) {
      return null; // or your preferred loading component
    }
    // Check if either of the parameters is null or NaN
    if (
      currentValue === null ||
      goalValue === null ||
      currentValue === 0 ||
      goalValue === 0 ||
      isNaN(currentValue) ||
      isNaN(goalValue)
    ) {
      // Return null, an empty fragment, or a specific error icon/message
      return;
    }
    if (currentValue >= goalValue) {
      return <KeyboardDoubleArrowUpIcon sx={{ color: "green" }} />;
    } else {
      return <KeyboardDoubleArrowDownIcon sx={{ color: "red" }} />;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        fontFamily: "'Montserrat', sans-serif",
        background: "#FFF",
        // outline: "solid 1px red",
        borderRadius: "0 0 1rem 1rem",
      }}
    >
      <TableContainer sx={{}}>
        <Table sx={{}} aria-label="KPI data table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#ededed", // light gray color for alternate rows

                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  padding: "6px 8px",
                  fontSize: "0.875rem",
                  // width: "23%", // Set strict widths
                  // minWidth: "13%",
                  // maxWidth: "13%",
                  // whiteSpace: "nowrap",
                  overflow: "hidden",
                  // outline: "solid red 1px",
                },
              }}
            >
              <TableCell
                align="center"
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  py: 0.5,
                  padding: "6px 8px",
                  fontSize: "0.875rem",
                  // width: "23%", // Set strict widths
                  // minWidth: "13%",
                  // maxWidth: "13%",
                  // whiteSpace: "nowrap",
                  overflow: "hidden",
                  // outline: "solid red 1px",
                }}
              >
                KPI
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  py: 0.5,
                }}
              >
                Actual
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  py: 0.5,
                }}
              >
                Prediction
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Applying alternate row colors */}
            <TableRow
              sx={{
                backgroundColor: "white",
                "& > *": {
                  py: 1.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <TableCell sx={{ ...nameCellStyle }}>Sales</TableCell>
              <Tooltip
                title={generateTooltipContent(
                  TotalData.historical.totalSales,
                  totalSalesStatGoal,
                  "sales",
                  false
                )}
                placement="top"
                arrow
              >
                <TableCell
                  align="center"
                  sx={{
                    ...valueCellStyle,
                  }}
                >
                  <Box
                    sx={{
                      ...boxStyle,
                    }}
                  >
                    {isFlatKPIVisible
                      ? compareValues(
                          TotalData.historical.totalSales,
                          totalSalesStatGoal,
                          profitLoad
                        )
                      : ""}
                    {isFlatKPIVisible
                      ? formatKpiNumber(TotalData.historical.totalSales)
                      : " - "}
                  </Box>
                </TableCell>
              </Tooltip>
              <Tooltip
                title={generateTooltipContent(
                  TotalData.prediction.totalSales,
                  totalSalesStatGoal,
                  "sales",
                  true
                )}
                placement="top"
                arrow
              >
                <TableCell
                  align="center"
                  sx={{
                    ...valueCellStyle,
                  }}
                >
                  <Box
                    sx={{
                      ...boxStyle,
                    }}
                  >
                    {isFlatKPIVisible
                      ? compareValues(
                          TotalData.prediction.totalSales,
                          totalSalesStatGoal,
                          profitLoad
                        )
                      : ""}
                    {isFlatKPIVisible
                      ? formatKpiNumber(TotalData.prediction.totalSales)
                      : " - "}
                  </Box>
                </TableCell>
              </Tooltip>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "#f7f7f7", // light gray color for alternate rows
                "& > *": {
                  py: 1.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <TableCell sx={nameCellStyle}>Gross Profit I</TableCell>
              <Tooltip
                title={generateTooltipContent(
                  TotalData.historical.totalGrossProfitI,
                  totalGrossProfitStatGoal,
                  "gross profit",
                  false
                )}
                placement="top"
                arrow
              >
                <TableCell align="center" sx={{ ...valueCellStyle }}>
                  <Box
                    sx={{
                      ...boxStyle,
                    }}
                  >
                    {compareValues(
                      TotalData.historical.totalGrossProfitI,
                      totalGrossProfitStatGoal,
                      profitLoad
                    )}
                    {isFlatKPIVisible
                      ? formatKpiNumber(TotalData.historical.totalGrossProfitI)
                      : isNaN(GrossProfitPercentage[0])
                      ? 0
                      : GrossProfitPercentage[0]}
                  </Box>
                </TableCell>
              </Tooltip>
              <Tooltip
                title={generateTooltipContent(
                  TotalData.prediction.totalGrossProfitI,
                  totalGrossProfitStatGoal,
                  "gross profit",
                  true
                )}
                placement="top"
                arrow
              >
                <TableCell align="center" sx={{ ...valueCellStyle }}>
                  <Box
                    sx={{
                      ...boxStyle,
                    }}
                  >
                    {compareValues(
                      TotalData.prediction.totalGrossProfitI,
                      totalGrossProfitStatGoal,
                      profitLoad
                    )}
                    {isFlatKPIVisible
                      ? formatKpiNumber(TotalData.prediction.totalGrossProfitI)
                      : isNaN(GrossProfitPercentage[1])
                      ? 0
                      : GrossProfitPercentage[1]}
                  </Box>
                </TableCell>
              </Tooltip>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "white",
                "& > *": {
                  py: 1.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <TableCell sx={nameCellStyle}>Gross Profit II</TableCell>
              <Tooltip
                title={generateTooltipContent(
                  TotalData.historical.totalGrossProfitII,
                  totalNetProfitStatGoal,
                  "net profit",
                  false
                )}
                placement="top"
                arrow
              >
                <TableCell align="center" sx={{ ...valueCellStyle }}>
                  <Box
                    sx={{
                      ...boxStyle,
                    }}
                  >
                    {compareValues(
                      TotalData.historical.totalGrossProfitII,
                      totalNetProfitStatGoal,
                      profitLoad
                    )}
                    {isFlatKPIVisible
                      ? formatKpiNumber(TotalData.historical.totalGrossProfitII)
                      : isNaN(GrossProfitIIPercentage[0])
                      ? 0
                      : GrossProfitIIPercentage[0]}
                  </Box>
                </TableCell>
              </Tooltip>
              <Tooltip
                title={generateTooltipContent(
                  TotalData.prediction.totalGrossProfitII,
                  totalNetProfitStatGoal,
                  "net profit",
                  true
                )}
                placement="top"
                arrow
              >
                <TableCell align="center" sx={{ ...valueCellStyle }}>
                  <Box
                    sx={{
                      ...boxStyle,
                    }}
                  >
                    {compareValues(
                      TotalData.prediction.totalGrossProfitII,
                      totalNetProfitStatGoal,
                      profitLoad
                    )}
                    {isFlatKPIVisible
                      ? formatKpiNumber(TotalData.prediction.totalGrossProfitII)
                      : isNaN(GrossProfitIIPercentage[1])
                      ? 0
                      : GrossProfitIIPercentage[1]}
                  </Box>
                </TableCell>
              </Tooltip>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "#f7f7f7",
                "& > *": {
                  py: 1.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <TableCell sx={nameCellStyle}>Total Employee Cost</TableCell>
              <TableCell align="center" sx={{ ...valueCellStyle }}>
                {isFlatKPIVisible
                  ? formatKpiNumber(averageEmployeeCost[0])
                  : "N/A"
                  ? statStaffData[0]
                  : "N/A"}
              </TableCell>
              <TableCell align="center" sx={{ ...valueCellStyle }}>
                {isFlatKPIVisible
                  ? formatKpiNumber(averageEmployeeCost[1])
                  : "N/A"
                  ? statStaffData[1]
                  : "N/A"}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "white",
                "& > *": {
                  py: 1.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <TableCell sx={nameCellStyle}>Emp Productivity/hr</TableCell>
              <TableCell align="center" sx={{ ...valueCellStyle }}>
                {isFlatKPIVisible
                  ? formatKpiNumber(averageEmployeeProductivity)
                  : " - "}
              </TableCell>
              <TableCell align="center" sx={{ ...valueCellStyle }}>
                N/A
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BasicOverviewTable;
