import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

const videoDetails = {
  landingPage: {
    title: "Gjennomgang av landingssiden",
    description: "Learn about profit and loss statements.",
    videoSrc:
      "https://salespredictionstorage.blob.core.windows.net/videos/Gjennomgang_av_landingssiden.mp4",
    thumbnailSrc:
      "https://salespredictionstorage.blob.core.windows.net/videos/landing_thumbnail.png",
  },
  salesPrediction: {
    title: "Sales Prediction",
    description: "Predict your sales",
    videoSrc:
      "https://salespredictionstorage.blob.core.windows.net/videos/Krunch_salgsprediksjon_v9_kunpredictionpage.mp4",
    thumbnailSrc:
      "https://salespredictionstorage.blob.core.windows.net/videos/sales_prediction_thumbnail.png",
  },
  staffInsight: {
    title: "Staff Insight",
    description: "Get insights on staff performance.",
    videoSrc:
      "https://salespredictionstorage.blob.core.windows.net/videos/Bemanningstal_och_kostnader_En_analys_av_ö_skostnader.mp4", // Replace with actual video source
    thumbnailSrc:
      "https://salespredictionstorage.blob.core.windows.net/videos/staff_thumbnail.png",
  },
  productInsight: {
    title: "Product Insight",
    description: "Dive deep into product analytics.",
    videoSrc:
      "https://salespredictionstorage.blob.core.windows.net/videos/Product_insight_Overview_page.mp4", // Replace with actual video source
    thumbnailSrc:
      "https://salespredictionstorage.blob.core.windows.net/videos/product_thumbnail.png",
  },
  managerPage: {
    title: "Manager Page",
    description:
      "Offer precise tools and insights for optimizing operations and oversight.",
    videoSrc:
      "https://salespredictionstorage.blob.core.windows.net/videos/Manager_page.mp4", // Replace with actual video source
    thumbnailSrc:
      "https://salespredictionstorage.blob.core.windows.net/videos/manager_thumbnail.png",
  },
  adminPage: {
    title: "Admin Page",
    description:
      "Provide comprehensive administrative functionalities for efficient management.",
    videoSrc:
      "https://salespredictionstorage.blob.core.windows.net/videos/Admin_pages.mp4", // Replace with actual video source
    thumbnailSrc:
      "https://salespredictionstorage.blob.core.windows.net/videos/admin_thumbnail.png",
  },
};
const role = JSON.parse(localStorage.getItem("user"))?.role;
const subscriptionLevel = JSON.parse(
  localStorage.getItem("user")
)?.company_subscription_level;
const GetStarted = () => {
  const [currentVideo, setCurrentVideo] = useState(videoDetails.landingPage);

  const handleVideoChange = (videoKey) => {
    setCurrentVideo(videoDetails[videoKey]);
  };
  useEffect(() => {
    if (role === "basic") {
      setCurrentVideo(videoDetails.landingPage);
    } else {
      setCurrentVideo(videoDetails.landingPage);
    }
  }, []);

  const activeBoxStyle = {
    border: "3px solid #FFBD00",
    height: { lg: "30%", xs: "95%" },
    boxShadow: "0 0 8px 4px rgba(255, 165, 0, 0.6)",
  };
  const boxStyle = {
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    // flexGrow: 1,
    border: "3px solid rgba(0, 0, 0, 0.05)",
    boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.21)",
    backgroundColor: "#FFF",
    margin: ".5rem",
    width: { lg: "90%", xs: "18rem" },
    borderRadius: "1rem",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    cursor: "pointer",
    height: { lg: "30%", xs: "90%" },
    overflow: "hidden",
    "&:hover": { backgroundColor: "#DFDFDF" },
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        // border: "solid 1px red",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: { lg: 1300, xl: 1600 },
          alignItems: "center",
          // border: "solid 1px blue",
          boxSizing: "border-box",
          height: { lg: "100vh", xs: "100vh" },
        }}
      >
        <Box
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            // border: "solid 1px green",
            height: "100%",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: { lg: 4, xs: 1 },
              // border: "solid 1px red",
              justifyContent: { lg: "space-evenly", xl: "space-evenly" },
              height: { lg: "15%", xs: "5%" },
              alignItems: "center",
              mt: { lg: 2, xs: 2 },
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              Get Started With Krunch
            </Typography>
          </Box>
          {/* top bar ends here */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "column", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 3px orange",
              justifyContent: "flex-start",
              alignItems: { lg: "flex-start", xs: "center" },
              height: { lg: "90%", xs: "100%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { lg: "row", xs: "column" },
                width: "100%",
                gap: 4,
                // border: "solid 3px orange",
                justifyContent: "flex-start",
                alignItems: { lg: "flex-start", xs: "center" },
                height: { lg: "95%", xs: "90%" },
                // position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  width: { lg: "80%", xs: "90%" },
                  gap: 4,
                  // border: "solid 3px orange",
                  borderRadius: "10px",
                  background: "#fff",
                  boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  justifyContent: "flex-start",
                  alignItems: { lg: "flex-start", xs: "center" },
                  height: { lg: "100%", xs: "60%" },
                }}
              >
                {/* for video */}
                <Box
                  sx={{
                    width: { lg: "100%", xs: "90%" }, // boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    background: "#FFF",
                    borderRadius: "1rem",
                    padding: "1rem",
                    height: { lg: "85%", xs: "60%" },
                    // border: "solid red 1px",
                  }}
                >
                  <video
                    key={currentVideo.videoSrc}
                    width="100%"
                    height="100%"
                    controls
                    autoPlay
                    muted
                    playsInline
                  >
                    <source src={currentVideo.videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  {/* Title and Description */}
                  <Typography
                    variant="h5"
                    sx={{
                      mt: 2,
                      fontWeight: "bold",
                      fontSize: { lg: "22px", xs: "17px" },
                    }}
                  >
                    {currentVideo.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1, mb: 4, fontSize: { lg: "18px", xs: "15px" } }}
                  >
                    {currentVideo.description}
                  </Typography>
                </Box>
              </Box>

              {/* list of pages */}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { lg: "column", xs: "row" },
                  width: { lg: "25%", xs: "90%" },
                  gap: { lg: 1, xs: 1 },
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "1rem",
                  // boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  // background: "#FFF",
                  height: { lg: "100%", xs: "40%" },
                  fontFamily: "'Montserrat', sans-serif",
                  paddingY: { lg: 0, xs: 1 },
                  overflow: "hidden",
                  // minHeight: { xs: "100vh" },
                  // border: "red solid 1px",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    overflowY: { lg: "auto", xs: "hidden" },
                    overflowX: "auto",
                    width: "100%",
                    // whiteSpace: "nowrap",
                    display: "flex",
                    flexDirection: { lg: "column", xs: "row" },
                  }}
                >
                  {/* Landing */}
                  {subscriptionLevel === "basic" ? (
                    <>
                      <Box
                        onClick={() => handleVideoChange("landingPage")}
                        sx={{
                          ...boxStyle,
                          ...(currentVideo.title ===
                          "Gjennomgang av landingssiden"
                            ? activeBoxStyle
                            : {}),
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: 700,
                            textAlign: "center",
                            mt: "1rem",
                            marginBottom: "0.5rem",
                            fontSize: "1rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Gjennomgang av landingssiden
                        </Typography>
                        <img
                          src={videoDetails.landingPage.thumbnailSrc}
                          alt="Gjennomgang av landingssiden"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "8px",
                          }}
                        />
                      </Box>
                      <Box
                        onClick={() => handleVideoChange("salesPrediction")}
                        sx={{
                          ...boxStyle,
                          ...(currentVideo.title === "Sales Prediction"
                            ? activeBoxStyle
                            : {}),
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: 700,
                            textAlign: "center",
                            mt: "1rem",
                            marginBottom: "0.5rem",
                            fontSize: "1rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Sales Prediction
                        </Typography>
                        <img
                          src={videoDetails.salesPrediction.thumbnailSrc}
                          alt="Sales Prediction"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "8px",
                          }}
                        />
                      </Box>
                      <Box
                        onClick={() => handleVideoChange("adminPage")}
                        sx={{
                          ...boxStyle,
                          ...(currentVideo.title === "Admin Page"
                            ? activeBoxStyle
                            : {}),
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: 700,
                            textAlign: "center",
                            mt: "1rem",
                            marginBottom: "0.5rem",
                            fontSize: "1rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Admin Page
                        </Typography>
                        <img
                          src={videoDetails.adminPage.thumbnailSrc}
                          alt="Admin Page"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "8px",
                          }}
                        />
                      </Box>
                    </>
                  ) : (
                    // Show all except Admin Page for manager, everything for other roles
                    Object.entries(videoDetails).map(([key, value]) => {
                      if (role === "manager" && key === "adminPage") {
                        return null;
                      }
                      return (
                        <Box
                          key={key}
                          onClick={() => handleVideoChange(key)}
                          sx={{
                            ...boxStyle,
                            ...(currentVideo.title === value.title
                              ? activeBoxStyle
                              : {}),
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: 700,
                              textAlign: "center",
                              mt: "1rem",
                              marginBottom: "0.5rem",
                              fontSize: "1rem",
                              fontFamily: "Montserrat",
                            }}
                          >
                            {value.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "Montserrat",
                              fontWeight: 300,
                              textAlign: "center",
                            }}
                          >
                            {currentVideo.title === value.title
                              ? currentVideo.description
                              : "View details about " +
                                value.title.toLowerCase() +
                                "."}
                          </Typography>
                          <img
                            src={value.thumbnailSrc}
                            alt={value.title}
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "8px",
                            }}
                          />
                        </Box>
                      );
                    })
                  )}
                </Box>
                {/* Admin Page */}
              </Box>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                height: "3rem",
                flexDirection: { lg: "row", xs: "column" },
                justifyContent: "space-around",
                m: 1,
                alignItems: "center",
                width: { lg: "20%", xs: "100%" },
                position: "absolute",
                right: 130,
                bottom: 50,
                border: "solid 1px red",
                padding: 2,
              }}
            ></Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GetStarted;
