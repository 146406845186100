import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";

import ChartLoading from "./Loading/ChartLoading";
import WeatherLineChart from "./LineCharts/WeatherLineChart";
// let prefix = "";

// if (process.env.NODE_ENV === "development") {
//   prefix = "http://127.0.0.1:8000";
// }
// const company = localStorage.getItem("company");
// const token = localStorage.getItem("token");

const Weather = ({
  // restaurant,
  // start_date,
  // end_date,
  // type,
  isXSScreen,
  holidayData,
  isLoading,
  selectedPage,
  selectedRestaurant,
  restaurant,
}) => {
  // const [tableData, setTableData] = useState([]);
  const [chartWeatherData, setChartWeatherData] = useState([]);
  const fetchData = useCallback(async () => {
    if (holidayData) {
      const processedData = holidayData?.results

        .filter((row) => row.name !== "High/low season")
        .filter(
          (row) => !(row.event_category && row.location && row.effect <= 2000)
        )
        .map((row) => ({
          ...row,
          name: row.name === null ? "None" : row.name,
          effect: row.effect === null ? "None" : row.effect,
          date: row.date === null ? "None" : row.date,
          event_category:
            row.event_category === null ? "None" : row.event_category,
          location: row.location === null ? "None" : row.location,
          type: row.type === null ? "None" : row.type,
        }))
        .filter((row) => row.type === "weather") // Keep only weather type
        .reverse();

      if (processedData && processedData.length !== 0) {
        // Group by date
        const groupedByDate = processedData.reduce((acc, row) => {
          const date = row.date;
          if (!acc[date]) {
            acc[date] = {
              effects: [],
              totalEffect: 0,
            };
          }
          acc[date].effects.push({ name: row.name, effect: row.effect });
          acc[date].totalEffect += row.effect;
          return acc;
        }, {});

        // Prepare the data for the chart
        const chartData = Object.entries(groupedByDate).map(
          ([date, { effects, totalEffect }]) => ({
            date,
            totalEffect,
            details: effects,
          })
        );

        const weatherDataForChart = [
          {
            id: "weather",
            data: chartData.map(({ date, totalEffect }) => ({
              x: date,
              y: totalEffect,
              details: chartData.find((d) => d.date === date).details, // Attach the individual effects and names for tooltips
            })),
          },
        ];

        setChartWeatherData(weatherDataForChart);
      } else {
        setChartWeatherData([]);
      }
    } else {
      setChartWeatherData([]);
    }
  }, [holidayData]);

  useEffect(() => {
    if (!holidayData || holidayData.length === 0) {
      setChartWeatherData([]);
    }
  }, [restaurant, holidayData]);
  useEffect(() => {
    if (
      selectedPage === "multiple" &&
      (!restaurant || restaurant.length === 0)
    ) {
      setChartWeatherData([]);
    } else {
      fetchData();
    }
  }, [fetchData, restaurant, selectedPage]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90%",
        height: "100%",
      }}
    >
      {isLoading ? (
        <ChartLoading />
      ) : (
        <WeatherLineChart
          data={chartWeatherData}
          isNotPercentage={true}
          isCurrency={true}
          isXSScreen={isXSScreen}
        />
      )}
    </Box>
  );
};

export default Weather;

// const fetchData = useCallback(async () => {
//   setIsLoading(true);
//   let url = `${prefix}/api/holidayparameters/holiday_params?company=${company}&restaurant=["${restaurant}"]`;
//   if (start_date && end_date) {
//     url += `&start_date=${start_date}&end_date=${end_date}`;
//   }
//   const holidays_response = await fetch(url, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Token ${token}`,
//     },
//   });
//   if (holidays_response.ok) {
//     const holidaysData = await holidays_response.json();
//     if (holidaysData.results) {
//       console.log(holidaysData, "weather data");
//       const processedData = holidaysData.results
//         .filter((row) => row.name !== "High/low season")
//         .filter(
//           (row) => !(row.event_category && row.location && row.effect <= 2000)
//         )

//         .map((row) => ({
//           ...row,
//           name: row.name === null ? "None" : row.name,
//           effect: row.effect === null ? "None" : row.effect,
//           date: row.date === null ? "None" : row.date,
//           event_category:
//             row.event_category === null ? "None" : row.event_category,
//           location: row.location === null ? "None" : row.location,
//           type: row.type === null ? "None" : row.type,
//         }))
//         .reverse();

//       // Group weather data by name and map each group to the required structure
//       const weatherDataGroups = processedData
//         .filter((row) => row.type === "weather") // Filter out only weather type
//         .reduce((acc, row) => {
//           // Use the name as the id for grouping
//           const groupName = row.name;
//           if (!acc[groupName]) {
//             acc[groupName] = { id: groupName, data: [] };
//           }
//           acc[groupName].data.push({
//             x: row.date,
//             y: row.effect,
//           });
//           return acc;
//         }, {});

//       // Convert the grouped data into an array format suitable for Nivo
//       const weatherDataArray = Object.values(weatherDataGroups);

//       // Assume setChartData is a state setter function that you'll use to store the data for the Nivo line chart
//       setChartWeatherData(weatherDataArray);
//       // setTableData(processedData);
//       // console.log(processedData, "ruru");
//     }
//   } else {
//     console.error("Failed to fetch data");
//     setChartWeatherData([]);
//     // setTableData([]);
//     setIsLoading(false);
//   }
//   setIsLoading(false);
// }, [restaurant, start_date, end_date]);
