const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";

  // Function to generate a random color
  const generateColor = () => {
    let newColor = "#";
    for (let i = 0; i < 6; i++) {
      newColor += letters[Math.floor(Math.random() * 16)];
    }
    return newColor;
  };

  // Generate the color and check if it is black
  do {
    color = generateColor();
  } while (color === "#000000");

  return color;
};

export default getRandomColor;
