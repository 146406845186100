
import { React, useState } from "react";

import AppBar from "@mui/material/AppBar";
import {
  Box,
  Toolbar,
  // Typography,
  Button,
  // Container,
  // Menu,
  // MenuItem,
  IconButton,
  Drawer,
  // List,
  // ListItem,
} from "@mui/material/";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

function PublicNavbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };
  const navigate = useNavigate();

  const handlenewContact = () => {
    // setAnchorElNav(null);

    navigate("/contact");
    //window.scrollTo(0, 0);
  };
  const handleSignIn = () => {
    setDrawerOpen(false);

    navigate("/signin");
  };
  const handleAboutUs = () => {
    setDrawerOpen(false);
    navigate("/aboutus");
    window.scrollTo(0, 0);
  };
  const handlecontact = () => {
    setDrawerOpen(false);
    navigate("/contact");
  };
  const handleFAQ = () => {
    setDrawerOpen(false);
    navigate("/FAQ");
    window.scrollTo(0, 0);
  };

  const list = () => (
    <Box
      sx={{
        width: 260,
        height: "100vh",
        backgroundColor: "#FFBD00",
        display: "flex",
        flexDirection: "column",
      }}
      role="presentation"
      // onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box
        sx={{
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mr: ".5rem",
        }}
      >
        <CloseIcon
          sx={{
            height: "28px",
            width: "28px",
          }}
          onClick={toggleDrawer(false)}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "flex-start",
          // border: "1px solid red",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Button sx={{ color: "#645e29" }} onClick={handleSignIn}>
            Sign in
          </Button>
        </Box>
        <Box>
          <Button sx={{ color: "#645e29" }} onClick={handleFAQ}>
            FAQ
          </Button>
        </Box>
        <Box>
          <Button sx={{ color: "#645e29" }} onClick={handleAboutUs}>
            About Us
          </Button>
        </Box>
        <Box>
          <Button sx={{ color: "#645e29" }} onClick={handlecontact}>
            Contact Us
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{
        maxHeight: "64px",
        background: "#FFBD00",
        color: "#645e29",
        marginBottom: "0px",
        width: "100%",
      }}
    >
      <Toolbar
      // sx={{
      //   justifyContent: "space-between",
      //   alignItems: "center",
      //   width: "100%",
      // }}
      >
        <Box
          sx={{
            display: "flex",
            // alignItems="center"
            width: "100%",
            justifyItems: "space-between",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Link to="/">
              <img
                src="transparent_logo.png"
                alt="Cover"
                style={{
                  maxHeight: "40px",
                  maxWidth: "120px",
                  marginRight: "8px",
                }}
              />
            </Link>
          </Box>
          <Box sx={{ display: { lg: "none", xs: "flex" } }}>
            <IconButton onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>

            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {list()}
            </Drawer>
          </Box>
          {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="#645e29"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorElNav(null);

                      navigate("/signin");
                    }}
                  >
                    <Typography textAlign="center">Sign in</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleFAQ}>
                    <Typography textAlign="center">FAQ</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleAboutUs}>
                    <Typography textAlign="center">About Us</Typography>
                  </MenuItem>
                  <MenuItem onClick={handlenewContact}>
                    <Typography textAlign="center">Contact Us</Typography>
                  </MenuItem>
                </Menu>
              </Box> */}

          {/* ENDS */}
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Box sx={{ flexGrow: 1 }}>
            <Button
              onClick={() => {
                navigate("/signin");
              }}
              sx={{
                my: 2,
                color: "#645e29",
                display: "block",
                fontWeight: 550,
                whiteSpace: "nowrap",
                maxWidth: "5rem",
              }}
            >
              Sign In
            </Button>
          </Box>
          <Button
            onClick={handleFAQ}
            sx={{
              my: 2,
              color: "#645e29",
              display: "block",
              fontWeight: 550,
              whiteSpace: "nowrap",
              maxWidth: "2rem",
            }}
          >
            FAQ
          </Button>
          <Button
            onClick={handleAboutUs}
            sx={{
              my: 2,
              color: "#645e29",
              display: "block",
              fontWeight: 550,
              whiteSpace: "nowrap",
              maxWidth: "7rem",
            }}
          >
            About us
          </Button>
          <Button
            onClick={handlenewContact}
            sx={{
              my: 2,
              color: "#645e29",
              display: "block",
              fontWeight: 550,
              whiteSpace: "nowrap",
              minWidth: "7rem",
            }}
          >
            Contact Us
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default PublicNavbar;
