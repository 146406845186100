import React, { useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { getCompanyColors } from "../CompanyUtils/CompanyColor";
import formatNumber from "../../utils/FormatNumber";
import dayjs from "dayjs";

const latest_created_at = localStorage.getItem("latest_records");

const ComparisonLineChart = ({
  data,
  isDetailedChart,
  isCombinedChart = false,
  isStaffChart = false,
  isNotPercentage = false,
  isSecondaryChart = false,
  secondaryData = null,
  range = null,
  // isOverlapChart = false,
  isXSScreen = false,
  // allowNegativeY = false,
  // showZeroLine = false,
  isMinimal = false,
  // singleTooltip = false,
  hasPredictionLine = false,
  predictionLineDate = null,
  isMinNonZero = false,
  isCurrency = false,
  noPrefix = false,
  hasStaffMarker = false,
  dataRange = null,
}) => {
  //SANDBOX
  //SANDBOX
  let newVar;
  let secondVar;

  // const [activeId, setActiveId] = useState(null);
  const [hiddenIds, setHiddenIds] = useState([]);

  function formatSerieId(serieId) {
    return serieId
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const format = !isNotPercentage
    ? (v) => `${v}%`
    : isCurrency
    ? (v) => `${v} NOK`
    : (v) => `${v} units`;

  if (!isDetailedChart) {
    newVar = [];
  }
  for (const property in data) {
    newVar.push(data[property]);
  }
  const lineData =
    newVar && Object.entries(newVar).length !== 0 ? [newVar] : [];
  if (isCombinedChart) {
    //combined chart
    newVar = newVar[0];
  }

  // Calculate tick values for small screens

  if (secondaryData) {
    secondVar = [];
    for (const property in secondaryData) {
      secondVar.push(secondaryData[property]);
    }
  }
  if (newVar && newVar.length > 0) {
    newVar = newVar.map((series) => {
      return {
        ...series,
        data: series.data.map((point) => {
          return {
            ...point,
            y: isNaN(point.y) ? 0 : point.y,
          };
        }),
      };
    });
  }

  // Filter data based on active legend item

  let minYValue;
  let maxYValue;
  if (newVar && newVar.length > 0) {
    // Extract all y-values from all data series and filter out NaN values
    const allYValues = newVar.flatMap((series) =>
      series.data.map((point) => point.y).filter((y) => !isNaN(y))
    );

    // Check if there are any valid values left after filtering
    if (allYValues.length > 0) {
      // Find the minimum and maximum y-values from the filtered array
      minYValue = Math.min(...allYValues);
      maxYValue = Math.max(...allYValues);
      if (minYValue === maxYValue) {
        minYValue = 0;
      }
    } else {
      // Default values if no valid data is available
      minYValue = 0;
      maxYValue = 0;
    }
  } else {
    // Default values if no data is available
    minYValue = 0;
    maxYValue = 0;
  }

  minYValue = minYValue.toFixed(0);
  maxYValue = maxYValue.toFixed(0);

  if (maxYValue < 10) {
  } else if (isNotPercentage) {
    maxYValue =
      maxYValue < 1000
        ? Math.round(maxYValue / 100) * 100
        : maxYValue < 9000
        ? Math.round(maxYValue / 1000) * 1000
        : Math.round(maxYValue / 10000) * 10000;
  } else {
    // For percentages or other units
    maxYValue = Math.round(maxYValue / 10) * 10; // Adjust rounding logic as needed
  }
  if (newVar && newVar.length > 0) {
    newVar = newVar.map((series) => {
      // Assuming `actualDate` is a representative date for the series to extract the year
      const representativeDate = new Date(series.data[0]?.actualDate);
      const year = representativeDate.getFullYear();
      return {
        ...series,
        id: `${year}`, // Set the series ID to the year
      };
    });
  }


  // let filteredData = newVar; // Assuming newVar is the full data set
  // if (activeId !== null) {
  //   const foundSerie = newVar.find((serie) => serie.id === activeId);
  //   // console.log("Found Serie:", foundSerie); // Debugging line to see found series
  //   filteredData = foundSerie ? [foundSerie] : [];
  // }


  // let minYValue;
  // let maxYValue;

  // if (newVar && newVar.length > 0) {
  //   minYValue = Math.min(
  //     ...newVar.map((series) =>
  //       Math.min(...series.data.map((point) => point.y))
  //     )
  //   );
  //   maxYValue = Math.max(
  //     ...newVar.map((series) =>
  //       Math.max(...series.data.map((point) => point.y))
  //     )
  //   );
  // }

  // if (isNotPercentage) {
  //   // For NOK
  //   minYValue = Math.round(minYValue / 10000) * 10000;
  //   if (maxYValue < 1000) {
  //     maxYValue = Math.round(maxYValue / 100) * 100; // Round to the nearest 100
  //   } else if (maxYValue < 9000) {
  //     maxYValue = Math.round(maxYValue / 1000) * 1000; // Round to the nearest 1000
  //   } else {
  //     maxYValue = Math.round(maxYValue / 10000) * 10000; // Round to the nearest 10000
  //   }
  // } else {
  //   minYValue = 0;
  //   maxYValue = Math.round(maxYValue / 10) * 10;
  // }

  // console.log("Calculated maxYValue:", minYValue);
  let staffMarkerDate;
  if (hasStaffMarker && latest_created_at) {
    staffMarkerDate = new Date(latest_created_at);
    staffMarkerDate.setDate(staffMarkerDate.getDate() + 14);
    staffMarkerDate.setHours(0, 0, 0, 0);
  }
  let predictionLineMarkerDate;
  if (hasPredictionLine && latest_created_at) {
    predictionLineMarkerDate = new Date(latest_created_at);
    // Set the time to midnight to align with the graph points
    predictionLineMarkerDate.setHours(0, 0, 0, 0);
  }
  const pointTooltip = ({ point }) => {
    // console.log("Cost of Goods:", point.data.cost);
    // console.log("Is Not Percentage:", isNotPercentage)

    // const roundToNearest500 = (value) => {
    //   if (value < 100) {
    //     // Round to the nearest 10 for values less than 100
    //     return Math.round(value / 10) * 10;
    //   } else if (value < 500) {
    //     // Round to the nearest 100 for values between 100 and 499
    //     return Math.round(value / 100) * 100;
    //   } else {
    //     // Round to the nearest 500 for values 500 and above
    //     return Math.round(value / 500) * 500;
    //   }
    // };

    const date = new Date(point.data.actualDate);
    let formattedDate;

    if (range === "month") {
      formattedDate = date.toLocaleDateString(undefined, {
        month: "long",
        year: "numeric",
      });
    } else if (range === "week") {
      const sunday = new Date(date);
      sunday.setDate(sunday.getDate() - sunday.getDay());

      const startOfYear = new Date(sunday.getFullYear(), 0, 1);
      const dayDifference = (sunday - startOfYear) / (24 * 60 * 60 * 1000);
      const weekNumber =
        1 + Math.floor((dayDifference + startOfYear.getDay() + 5) / 7);

      formattedDate = `${date.toLocaleDateString(undefined, {
        month: "long",
      })} (Week ${weekNumber}) ${sunday.getFullYear()}`;
    } else {
      formattedDate = date.toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
    // Ensure both dates are compared in the same format

    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          borderRadius: "5px",
          // position: 'absolute',
          // left: `${point.x - 50}px`, // Adjust the left position based on the point’s x-coordinate
          // top: `${point.y + 20}px`, // Adjust the top position to place the tooltip below the point
        }}
      >
        <strong>{formattedDate}</strong>
        <br />
        <strong style={{ color: point.serieColor }}>
          {formatSerieId(point.serieId)}:{" "}
          {isNotPercentage
            ? formatNumber(Math.round(point.data.y))
            : `${point.data.y} units`}
          <br />
          {point.data.comment && (
            <strong style={{ color: "black" }}>
              Comment: {point.data.comment}
              <br />
            </strong>
          )}
        </strong>
        {/* <br/> */}
      </div>
    );
  };

  const axisProps = !isSecondaryChart
    ? {
        axisLeft: {
          format,
          tickValues: [minYValue, maxYValue],
          legendOffset: 12,
        },
        colors: getCompanyColors(),
        // colors: [

        //   // "#E191B1", // Light Pink
        //   // "#2FEBAA", // Mint Green
        //   "#543c8c",
        //   "#e394b4",

        //   "#949494",

        //   "#70335D", // Dark Plum
        //   // "#91FAE0", // Turquoises
        //   "#17CB1B",

        //   "#2A5F8B", // Steel Blue

        //   // "#E63946",
        //   // "#40E0D0",
        //   // "#4169E1",
        //   "#bcbd22", //green
        //   // "#2ca02c",
        //   // "#d62728",
        //   // "#9467bd",
        //   // "#8c564b",
        //   "#e377c2",
        //   "#7f7f7f",
        //   "#17becf",
        // ],
        tooltip: pointTooltip,
      }
    : {
        colors: "#4169E1",
        axisRight: {
          format,
          legendOffset: 12,
        },
        sliceTooltip: ({ slice }) => {
          const currentDate = new Date(slice.points[0].data.x);
          let formattedDate;

          if (range === "month") {
            formattedDate = currentDate.toLocaleDateString(undefined, {
              month: "long",
              year: "numeric",
            });
          } else if (range === "week") {
            // Adjust to Sunday of the current week
            const sunday = new Date(currentDate);
            sunday.setDate(sunday.getDate() - sunday.getDay());

            // January 4th is always in week 1 according to ISO standard
            const startOfYear = new Date(sunday.getFullYear(), 0, 1);

            // Calculate the number of days between the date and the start of the year
            const dayDifference =
              (sunday - startOfYear) / (24 * 60 * 60 * 1000);

            // Calculate ISO week number
            const weekNumber =
              1 + Math.floor((dayDifference + startOfYear.getDay() + 5) / 7);
            formattedDate = `${currentDate.toLocaleDateString(undefined, {
              month: "long",
            })} (Week ${weekNumber}) ${sunday.getFullYear()}`;
          } else {
            formattedDate = currentDate.toLocaleDateString(undefined, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }

          // Find the object with the matching x value in secondarySalesData
          const matchedData = secondVar[0].data.find(
            (d) =>
              d.x.toISOString().split("T")[0] ===
              currentDate.toISOString().split("T")[0]
          );

          // If matchedData is found, extract the y value, else set it to null
          const secondaryYValue = matchedData ? matchedData.y : null;

          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              <strong>{formattedDate}</strong>
              {slice.points.map((point, index) => (
                <div
                  key={index}
                  style={{
                    color: "#4169E1",

                    padding: "3px 0",
                  }}
                >
                  <strong>{point.serieId}:</strong>{" "}
                  {parseInt(point.data.yFormatted)}
                  {secondaryYValue && (
                    <div
                      style={{
                        color: "#E63946", // or any color you prefer for secondary data
                        padding: "3px 0",
                      }}
                    >
                      <strong>Staff Cost Percentage:</strong> {secondaryYValue}%
                    </div>
                  )}
                </div>
              ))}
            </div>
          );
        },
      };

  // Helper function to safely parse dates from the actualDate property
  const safeParseDate = (actualDate) => {
    try {
      const date = new Date(actualDate);
      if (!isNaN(date)) {
        return date;
      }
    } catch (e) {
      console.error("Error parsing date:", e);
    }
    return null;
  };

  // Helper function to merge x values from both data arrays, remove duplicates, and sort them
  const getSortedUniqueXValues = (data1, data2) => {
    const allXValues = [
      ...(data1 || []).map((item) => item.x),
      ...(data2 || []).map((item) => item.x),
    ];
    const uniqueXValuesSet = new Set(allXValues);

    return Array.from(uniqueXValuesSet)
      .map((x) => {
        return {
          value: x,
          date: (
            data1.find((item) => item.x === x) ||
            data2.find((item) => item.x === x)
          )?.actualDate,
        };
      })
      .map(({ value, date }) => ({ value, date: safeParseDate(date) }))
      .filter(({ date }) => date !== null) // Exclude any invalid dates
      .sort((a, b) => a.date - b.date) // Sort by date
      .map(({ value }) => value); // Extract the sorted x values
  };

  // Helper function to convert date strings to Date objects
  const parseDate = (dateString) => {
    const [month, day] = dateString.split("-").map(Number);
    const year = new Date(
      newVar[0]?.data[0]?.actualDate || new Date()
    ).getFullYear();
    return new Date(year, month - 1, day);
  };

  // Assuming newVar is an array with at least two items and each item has a data array
  const sortedUniqueXValues = getSortedUniqueXValues(
    newVar[0]?.data,
    newVar[1]?.data
  );

  // Convert sorted unique x values to Date objects
  const tickValues = sortedUniqueXValues.map(parseDate);
  // Format the tick labels as 'MM-DD'
  const formatTickLabel = (date) => {
    return `${date.getMonth() + 1}-${date
      .getDate()
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  // Conditional xScale configuration based on dataRange
  // const xScaleConfig =
  //   dataRange === "thisweek"
  //     ? {
  //         type: "point",
  //       }
  //     : {
  //         type: "time",
  //         format: "%Y-%m-%d", // Adjust this format to match your actual date format
  //         precision: "day",
  //       };

  // function completeWeekOrMonthData(dataSets, dataRange) {
  //   return dataSets.map((dataset) => {
  //     const newData = [];
  //     const originalData = dataset.data;
  //     const seenDates = new Set(
  //       originalData.map((dataPoint) =>
  //         dayjs(dataPoint?.actualDate).format("YYYY-MM-DD")
  //       )
  //     );

  //     if (originalData?.length > 0) {
  //       const minDate = new Date(
  //         Math.min(
  //           ...originalData.map((dataPoint) => new Date(dataPoint.actualDate))
  //         )
  //       );
  //       const maxDate = new Date(
  //         Math.max(
  //           ...originalData.map((dataPoint) => new Date(dataPoint.actualDate))
  //         )
  //       );

  //       let startDate, endDate;
  //       if (dataRange === "thisweek") {
  //         startDate = dayjs(originalData[0].actualDate)
  //           .startOf("week")
  //           .add(1, "day")
  //           .toDate(); // Adjust to start from Monday
  //         endDate = dayjs(startDate).add(6, "days").toDate(); // End on Sunday
  //       } else if (dataRange === "thismonth") {
  //         startDate = dayjs(minDate).toDate();
  //         endDate = dayjs(maxDate).toDate();
  //       }

  //       const dateXMap = new Map();
  //       originalData.forEach((dataPoint) => {
  //         const formattedDate = dayjs(dataPoint.actualDate).format(
  //           "YYYY-MM-DD"
  //         );
  //         dateXMap.set(formattedDate, dataPoint.x);
  //       });

  //       let lastX = null;
  //       for (
  //         let currentDate = startDate;
  //         currentDate <= endDate;
  //         currentDate = dayjs(currentDate).add(1, "day").toDate()
  //       ) {
  //         const formattedDate = dayjs(currentDate).format("YYYY-MM-DD");
  //         let xValue = dateXMap.get(formattedDate);

  //         if (!xValue && lastX && dataRange === "thismonth") {
  //           // Extract year, month, and day from lastX
  //           const [lastYear, lastMonth, lastDay] = lastX.split("-");
  //           const newDate = dayjs(`${lastYear}-${lastMonth}-${lastDay}`).add(
  //             1,
  //             "day"
  //           );
  //           xValue = newDate.format("YYYY-MM-DD");
  //         }
  //         // console.log(xValue, "history");
  //         // console.log(lastX, "historys");

  //         if (!seenDates.has(formattedDate)) {
  //           newData.push({
  //             x: xValue || "Undefined", // Use calculated x or "Undefined" if something fails
  //             y: "0",
  //             actualDate: currentDate,
  //           });
  //         }
  //         lastX = xValue; // Update lastX for the next iteration
  //       }

  //       originalData.forEach((dataPoint) => {
  //         const formattedDate = dayjs(dataPoint.actualDate).format(
  //           "YYYY-MM-DD"
  //         );
  //         const dataToAdd = {
  //           x: dateXMap.get(formattedDate),
  //           y: dataPoint.y,
  //           actualDate: dataPoint.actualDate,
  //         };

  //         const index = newData.findIndex(
  //           (point) =>
  //             dayjs(point.actualDate).format("YYYY-MM-DD") === formattedDate
  //         );
  //         if (index !== -1) {
  //           newData[index] = dataToAdd;
  //         } else {
  //           newData.push(dataToAdd);
  //         }
  //       });

  //       if (dataRange === "thisweek") {
  //         const dayOrder = [
  //           "Monday",
  //           "Tuesday",
  //           "Wednesday",
  //           "Thursday",
  //           "Friday",
  //           "Saturday",
  //           "Sunday",
  //         ];
  //         newData.sort((a, b) => dayOrder.indexOf(a.x) - dayOrder.indexOf(b.x));
  //       } else if (dataRange === "thismonth") {
  //         newData.sort(
  //           (a, b) => new Date(a.actualDate) - new Date(b.actualDate)
  //         );
  //       }
  //     } else {
  //       console.log("No data available to determine the range.");
  //       return { ...dataset, data: [] };
  //     }

  //     return { ...dataset, data: newData };
  //   });
  // }

  function completeWeekOrMonthData(dataSets, dataRange) {
    return dataSets.map((dataset) => {
      const newData = [];
      const originalData = dataset.data;
      const seenDates = new Set(
        originalData.map((dataPoint) =>
          dayjs(dataPoint?.actualDate).format("YYYY-MM-DD")
        )
      );

      if (originalData?.length > 0) {
        const minDate = new Date(
          Math.min(
            ...originalData.map((dataPoint) => new Date(dataPoint.actualDate))
          )
        );
        const maxDate = new Date(
          Math.max(
            ...originalData.map((dataPoint) => new Date(dataPoint.actualDate))
          )
        );

        let startDate, endDate;
        if (dataRange === "thisweek") {
          startDate = dayjs(originalData[0].actualDate)
            .startOf("week")
            .add(1, "day")
            .toDate(); // Adjust to start from Monday
          endDate = dayjs(startDate).add(6, "days").toDate(); // End on Sunday
        } else if (dataRange === "thismonth") {
          startDate = dayjs(minDate).toDate();
          endDate = dayjs(maxDate).toDate();
        }

        const dateXMap = new Map();
        originalData.forEach((dataPoint) => {
          const formattedDate = dayjs(dataPoint.actualDate).format(
            "YYYY-MM-DD"
          );
          dateXMap.set(formattedDate, dataPoint.x);
        });

        let lastX = null;
        for (
          let currentDate = startDate;
          currentDate <= endDate;
          currentDate = dayjs(currentDate).add(1, "day").toDate()
        ) {
          const formattedDate = dayjs(currentDate).format("YYYY-MM-DD");
          let xValue = dateXMap.get(formattedDate);

          if (!xValue && lastX && dataRange === "thismonth") {
            // Extract year, month, and day from lastX
            const [lastYear, lastMonth, lastDay] = lastX.split("-");
            const newDate = dayjs(`${lastYear}-${lastMonth}-${lastDay}`).add(
              1,
              "day"
            );
            xValue = newDate.format("YYYY-MM-DD");
          }

          if (!seenDates.has(formattedDate)) {
            xValue = xValue || dayjs(currentDate).format("dddd"); // Set x to the weekday name
            newData.push({
              x: xValue, // Use the weekday name
              y: "0",
              actualDate: currentDate,
            });
          }
          lastX = xValue; // Update lastX for the next iteration
        }

        originalData.forEach((dataPoint) => {
          const formattedDate = dayjs(dataPoint.actualDate).format(
            "YYYY-MM-DD"
          );
          const dataToAdd = {
            x: dateXMap.get(formattedDate),
            y: dataPoint.y,
            actualDate: dataPoint.actualDate,
          };

          const index = newData.findIndex(
            (point) =>
              dayjs(point.actualDate).format("YYYY-MM-DD") === formattedDate
          );
          if (index !== -1) {
            newData[index] = dataToAdd;
          } else {
            newData.push(dataToAdd);
          }
        });

        if (dataRange === "thisweek") {
          const dayOrder = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ];
          newData.sort((a, b) => dayOrder.indexOf(a.x) - dayOrder.indexOf(b.x));
        } else if (dataRange === "thismonth") {
          newData.sort(
            (a, b) => new Date(a.actualDate) - new Date(b.actualDate)
          );
        }
      } else {
        console.log("No data available to determine the range.");
        return { ...dataset, data: [] };
      }

      return { ...dataset, data: newData };
    });
  }

  const finalData = completeWeekOrMonthData(newVar, dataRange);
  let filteredData = finalData?.filter(
    (serie) => !hiddenIds.includes(serie.id)
  );

  const axisBottom =
    dataRange === "thisweek"
      ? {
          format: (value) => {
            if (isXSScreen) {
              return value.slice(0, 3); // Shorten weekday names to first three letters (Mon, Tue, etc.)
            }
            return value; // Use the full weekday names otherwise
          },
          legendOffset: -12,
          tickValues: filteredData[1]?.data.map((item) => item.x), // Use x values from the first array for "thisweek"
        }
      : {
          format: formatTickLabel,
          legendOffset: -12,
          tickValues: tickValues, // Use the converted Date objects as tick values
        };

  return !lineData || lineData.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    <ResponsiveLine
      animate
      enableGridX={isMinimal ? false : !isSecondaryChart}
      enableGridY={isMinimal ? true : !isSecondaryChart}
      gridYValues={[0, minYValue, maxYValue]}
      xScale={
        dataRange === "thisweek"
          ? {
              type: "point", // Continue using 'point' scale for "thisweek"
            }
          : {
              format: "%Y-%m-%d",
              precision: "day",
              type: "time",
              useUTC: false,
            }
      }
      axisBottom={axisBottom}
      {...axisProps}
      axisLeft={
        !isSecondaryChart
          ? {
              tickValues: [0, minYValue, isStaffChart ? 70 : maxYValue].filter(
                (v, i, a) => a.indexOf(v) === i
              ), // Ensuring no duplicates
              format: noPrefix ? undefined : (v) => format(v),
              legendOffset: -40,
            }
          : null
      }
      curve="monotoneX"
      data={filteredData}
      // enablePointLabel
      // height={400}
      margin={
        isMinimal
          ? {
              bottom: 80,
              left: noPrefix ? 80 : maxYValue > 100 ? 80 : 80,
              right: 50,
              top: 60,
            }
          : isStaffChart
          ? {
              bottom: 60,
              left: 80,
              right: 90,
              top: 20,
            }
          : {
              bottom: 60,
              left: 80,
              right: 40,
              top: 30,
            }
      }
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      lineWidth={1}
      markers={[
        hasPredictionLine
          ? {
              axis: "x", // Vertical line
              // legend: "prediction marker",
              value: predictionLineMarkerDate,
              lineStyle: {
                stroke: "#505050", // Line color
                strokeWidth: 2, // Line width
              },

              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
      ]}
      pointBorderWidth={1}
      pointSize={4}
      enableArea={true}
      areaBaselineValue={isMinNonZero ? 0 : 0}
      areaOpacity={0.15}
      // enableSlices={singleTooltip ? undefined : "x"}
      labelFormat={format}
      useMesh
      //   xFormat="time:%Y-%m-%d"

      yScale={{
        type: "linear",
        // min: allowNegativeY ? "auto" : 0,
        min: minYValue < 0 ? minYValue : 0, // Always start from 0
        max: isStaffChart ? 70 : "auto", // Set the calculated maxY as the max property
      }}
      legends={
        isMinimal
          ? [
              {
                anchor: "bottom",
                direction: "row",
                translateY: 60,
                itemsSpacing: 2,
                itemDirection: "left-to-right",
                itemWidth: 109,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                onClick: (datum) => {
                  setHiddenIds((currentHiddenIds) => {
                    const isCurrentlyHidden = currentHiddenIds.includes(
                      datum.id
                    );
                    const newHiddenIds = isCurrentlyHidden
                      ? currentHiddenIds.filter((id) => id !== datum.id)
                      : [...currentHiddenIds, datum.id];

                    // Ensure we don't hide all lines: if newHiddenIds length is equal to the number of lines, reset it
                    if (newHiddenIds.length >= newVar.length) {
                      return [];
                    }
                    return newHiddenIds;
                  });
                },
              },
            ]
          : [
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: isSecondaryChart ? 150 : 2,
                translateY: 145,
                itemsSpacing: 10,
                itemDirection: "left-to-right",
                itemWidth: 109,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",

                symbolBorderColor: "rgba(0, 0, 0, .5)",

                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
      }
    />
  );
};

export default ComparisonLineChart;

// // DATE ADDER FUNCTIONALITTY
// // Function to create an array of string tick values for each day of a given month in MM-DD format
// const createMonthTickValues = (year, month) => {
//   const numberOfDays = new Date(year, month + 1, 0).getDate();
//   const tickValues = [];
//   for (let day = 1; day <= numberOfDays; day++) {
//     tickValues.push(`${(month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`);
//   }
//   return tickValues;
// };

// // Extract the year and month from the first actualDate in your dataset
// // Assuming actualDate is in a format from which we can reliably extract the year and month
// const actualDate = newVar[0]?.data[0]?.actualDate;
// const sampleDate = new Date(actualDate);
// const year = sampleDate.getFullYear();
// const month = sampleDate.getMonth(); // getMonth is zero-indexed (0 = January, 11 = December)

// // Create tick values for each day of the month
// const monthTickValues = createMonthTickValues(year, month);

// // Nivo chart axisBottom configuration
// axisBottom={
//   dataRange === "thisweek"
//     ? {
//         format: (value) => value, // Use the value directly for "thisweek"
//         legendOffset: -12,
//         tickValues: newVar[0]?.data.map((item) => item.x), // Map weekday names directly from your data
//       }
//     : {
//         format: (value) => value, // Keep the string format for "month" dataRange
//         legendOffset: -12,
//         tickValues: monthTickValues, // Use the generated list of string tick values in MM-DD format
//       }
// }

//FOR REFERENCE

// function completeWeekOrMonthData(dataSets, dataRange) {
//   return dataSets.map((dataset) => {
//     const newData = [];
//     const originalData = dataset.data;
//     const seenDates = new Set(
//       originalData.map((dataPoint) =>
//         dayjs(dataPoint.actualDate).format("YYYY-MM-DD")
//       )
//     );

//     if (originalData.length > 0) {
//       const minDate = new Date(
//         Math.min(
//           ...originalData.map((dataPoint) => new Date(dataPoint.actualDate))
//         )
//       );
//       const maxDate = new Date(
//         Math.max(
//           ...originalData.map((dataPoint) => new Date(dataPoint.actualDate))
//         )
//       );

//       let startDate, endDate;
//       if (dataRange === "thisweek") {
//         startDate = dayjs(originalData[0].actualDate)
//           .startOf("week")
//           .add(1, "day")
//           .toDate();
//         endDate = dayjs(startDate).add(6, "days").toDate();
//       } else if (dataRange === "thismonth") {
//         startDate = dayjs(minDate).toDate();
//         endDate = dayjs(maxDate).toDate();
//       }

//       const dateXMap = new Map();
//       originalData.forEach((dataPoint) => {
//         const formattedDate = dayjs(dataPoint.actualDate).format(
//           "YYYY-MM-DD"
//         );
//         dateXMap.set(formattedDate, dataPoint.x);
//       });

//       let lastX = null;
//       for (
//         let currentDate = startDate;
//         currentDate <= endDate;
//         currentDate = dayjs(currentDate).add(1, "day").toDate()
//       ) {
//         const formattedDate = dayjs(currentDate).format("YYYY-MM-DD");
//         let xValue = dateXMap.get(formattedDate);

//         if (!xValue && lastX && dataRange === "thismonth") {
//           const lastXDateParts = lastX.split("-");
//           const newDay = parseInt(lastXDateParts[1], 10) + 1;
//           xValue = `${lastXDateParts[0]}-${newDay
//             .toString()
//             .padStart(2, "0")}`;
//         }

//         if (!seenDates.has(formattedDate)) {
//           newData.push({
//             x: xValue || "Undefined",
//             y: "0",
//             actualDate: currentDate,
//           });
//         }
//         lastX = xValue; // Update lastX for the next iteration
//       }

//       originalData.forEach((dataPoint) => {
//         const formattedDate = dayjs(dataPoint.actualDate).format(
//           "YYYY-MM-DD"
//         );
//         const dataToAdd = {
//           x: dateXMap.get(formattedDate),
//           y: dataPoint.y,
//           actualDate: dataPoint.actualDate,
//         };

//         const index = newData.findIndex(
//           (point) =>
//             dayjs(point.actualDate).format("YYYY-MM-DD") === formattedDate
//         );
//         if (index !== -1) {
//           newData[index] = dataToAdd;
//         } else {
//           newData.push(dataToAdd);
//         }
//       });

//       if (dataRange === "thisweek") {
//         const dayOrder = [
//           "Monday",
//           "Tuesday",
//           "Wednesday",
//           "Thursday",
//           "Friday",
//           "Saturday",
//           "Sunday",
//         ];
//         newData.sort((a, b) => dayOrder.indexOf(a.x) - dayOrder.indexOf(b.x));
//       } else if (dataRange === "thismonth") {
//         newData.sort(
//           (a, b) => new Date(a.actualDate) - new Date(b.actualDate)
//         );
//       }
//     } else {
//       console.log("No data available to determine the range.");
//       return { ...dataset, data: [] };
//     }

//     return { ...dataset, data: newData };
//   });
// }
