import React from "react";
import "../App.css";
import { Routes, Route } from "react-router-dom";
import GetStarted from "../dashboard/GetStarted";
import Admin from "../user/Admin";
import EmployeeCreate from "../user/EmployeeCreate";
import EmployeeList from "../user/EmployeeList";

import SalesPrediction from "../dashboard/SalesPrediction";
import Landingv2 from "../Landingv2/Landingv2";
import ContactUs from "../Landingv2/ContactUs";
import Features from "../Landingv2/Features";
import FAQ from "../Landingv2/FAQ";
import NewAboutPage from "../Landingv2/AboutUs";
import ForgotPassword from "../user/ForgotPassword";
import ChangePassword from "../user/ChangePassword";
import Profile from "../user/Profile";
import ErrorPage from "../user/ErrorPage"; // Import your 404 page component
import NewProfitLoss from "../dashboard/NewProfitLoss";
import SupportUs from "../user/SupportUs";

function SalesPredictionMain({
  selectedRestaurant,
  setSelectedRestaurant,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  selectedPage,
  setSelectedPage,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <SalesPrediction
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}

function PasswordForgot() {
  return (
    <div className="App">
      <main>
        <ForgotPassword />
      </main>
    </div>
  );
}

const EmployeeCreatePage = () => {
  return (
    <div className="App">
      <main>
        <EmployeeCreate />
      </main>
    </div>
  );
};
const EmployeeListPage = () => {
  return (
    <div className="App">
      <main>
        <EmployeeList />
      </main>
    </div>
  );
};
function PasswordChange() {
  return (
    <div className="App">
      <main>
        <ChangePassword />
      </main>
    </div>
  );
}

function LandingNewPage() {
  return (
    <div className="App">
      <main>
        <Landingv2 />
      </main>
    </div>
  );
}

function NewContactPage() {
  return (
    <div className="App">
      <main>
        <ContactUs />
      </main>
    </div>
  );
}
function FeaturesPage() {
  return (
    <div className="App">
      <main>
        <Features />
      </main>
    </div>
  );
}
function FAQPage() {
  return (
    <div className="App">
      <main>
        <FAQ />
      </main>
    </div>
  );
}

function ProfilePage() {
  return (
    <div className="App">
      <main>
        <Profile />
      </main>
    </div>
  );
}
function ErrorRoute() {
  return (
    <div className="App">
      <main>
        <ErrorPage />
      </main>
    </div>
  );
}
function AdminPage() {
  return (
    <div className="App">
      <main>
        <Admin />
      </main>
    </div>
  );
}
function NewLayoutPage({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <NewProfitLoss
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dataRange={dataRange}
          setDataRange={setDataRange}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          hasPredictionData={hasPredictionData}
          setHasPredictionData={setHasPredictionData}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}
const BasicAuthenticatedRoutes = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurants,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <NewLayoutPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            restaurant={restaurants}
            setRestaurant={setRestaurant}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />

      <Route
        path="/salesprediction"
        element={
          <SalesPredictionMain
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route
        path="/profitloss"
        element={
          <NewLayoutPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            restaurant={restaurants}
            setRestaurant={setRestaurant}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />

      {/* <Route path="/superdashboard" element={<SuperDash />} /> */}
      {/* <Route path="/salesprediction" element={<Main />} /> */}
      {/* <Route path="/myrestaurant" element={<RestaurantMain />} /> */}
      {/* <Route path="/superdashboardtwo" element={<MultipleDashboardTwo />} /> */}
      <Route path="/forget_password" element={<PasswordForgot />} />
      <Route path="/change_password" element={<PasswordChange />} />
      {/* <Route path="/restaurants" element={<RestaurantPage />} /> */}
      <Route path="/contact" element={<ContactUs />} />
      {/* <Route path="/admin" element={<AdminPage />} /> */}
      <Route path="/newlandingpage" element={<LandingNewPage />} />
      <Route path="/newcontact" element={<NewContactPage />} />
      <Route path="/features" element={<FeaturesPage />} />
      <Route path="/FAQ" element={<FAQPage />} />
      <Route path="/aboutus" element={<NewAboutPage />} />
      <Route path="/admin" element={<AdminPage />} />
      <Route path="/employee_create/:id" element={<EmployeeCreatePage />} />
      <Route path="/employee_list" element={<EmployeeListPage />} />
      <Route path="/employee_create" element={<EmployeeCreatePage />} />

      {/* <Route path="/newpage" element={<NewLayoutPage />} /> */}
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="*" element={<ErrorRoute />} />
      <Route path="/getstarted" element={<GetStarted />} />
      <Route path="/supportus" element={<SupportUs />} />

      {/* <Route path="/getstarted" element={<GetStarted />} /> */}
    </Routes>
  );
};

export default BasicAuthenticatedRoutes;
