import { React, useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Box,
  Toolbar,
  // List,
  // Button,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  IconButton,
  Drawer,
  AppBar,
  // ThemeProvider,
  Collapse,
  // Typography,
} from "@mui/material/";
import {
  Close as CloseIcon,
  Menu as MenuIcon,
  Logout as LogoutIcon,
  VpnKey as VpnKeyIcon,
  AccountCircle as AccountCircleIcon,
  ArrowDropDown as ArrowDropDownIcon,
  PersonPin as PersonPinIcon,
  Insights as InsightsIcon,
  MonetizationOn as MonetizationOnIcon,
  ShowChart as ShowChartIcon,
  RestaurantMenu as RestaurantMenuIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Restaurant as RestaurantIcon,
  Group as GroupIcon,
  GroupAdd as GroupAddIcon,
} from "@mui/icons-material";
// import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";

import Prefix from "../../utils/PrefixLink";

const prefix = Prefix();

export default function BasicLevelNavBar() {
  //   const [adminCollapseOpen, setAdminCollapseOpen] = useState(false);
  //   const handleClick = () => {
  //     setAdminCollapseOpen(!adminCollapseOpen);
  //   };
  const appBarRef = useRef(null);
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [clickedIcon, setClickedIcon] = useState(null);
  //   const [adminOpen, setAdminOpen] = useState(false);
  const [isBarExpanded, setIsBarExpanded] = useState(false);
  //   const [productCollapseOpen, setProductCollapseOpen] = useState(false);
  //   const [productOpen, setProductOpen] = useState(false);
  const [ignoreReduction, setIgnoreReduction] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [profitOpen, setProfitOpen] = useState(false);
  const [profitCollapseOpen, setProfitCollapseOpen] = useState(false);
  const [adminCollapseOpen, setAdminCollapseOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);

  const navigatingRef = useRef(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const userName = user ? `${user.first_name} ${user.last_name}` : "USER";

  // Extract the first name and last name from the user object
  //   const userName = user ? `${user.first_name} ${user.last_name}` : "USER";
  const handleBarClick = () => {
    if (!navigatingRef.current) {
      setIsBarExpanded(true);
    }
  };
  const handleUserToggle = () => {
    setUserOpen(!userOpen);
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };
  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${prefix}/api/users/logout/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        // Successfully logged out
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("company");
        window.location.href = "/";
      } else {
        // Handle any errors that occur during the logout process
        console.error("Failed to log out");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //   const handleAdminToggle = () => {
  //     setAdminOpen((prevState) => !prevState);
  //     setClickedIcon(null);

  // const handleProductToggle = () => {
  //   setProductOpen((prevState) => !prevState);
  //   setClickedIcon(null);
  // };
  // handle click event on navbar icon to navaigate link

  const handleLogoClick = () => {
    navigatingRef.current = true;
    navigate("/");
  };
  const handleClick = () => {
    setAdminCollapseOpen(!adminCollapseOpen);
  };
  const handleAdminNavigate = () => {
    navigatingRef.current = true;

    navigate("/admin");
  };
  const handleAdminToggle = () => {
    setAdminOpen((prevState) => !prevState);
    setClickedIcon(null);
  };
  // const handleReportPage = () => {
  //   navigatingRef.current = true;

  //   navigate("/reportissue");
  // };
  const handleProfitClick = () => {
    setProfitCollapseOpen(!profitCollapseOpen);
  };

  const handleProfitToggle = () => {
    setProfitOpen((prevState) => !prevState);
    setClickedIcon(null);
  };

  const handleHighLevelNavigate = () => {
    navigatingRef.current = true;

    navigate("/profitloss");
  };
  const handlePredictionNavigate = () => {
    navigatingRef.current = true;
    setIgnoreReduction(true);

    navigate("/salesprediction");
  };
  const handleGetStarted = () => {
    navigatingRef.current = true;
    navigate("/getstarted");
  };

  const handleNavigate = (path) => {
    setIsOpen(false);
    navigate(path);
  };
  const userroutes = [
    { text: "Profile", path: "/profile" },
    { text: "Change Password", path: "/change_password" },
    // { text: "Contact Us", path: "/reportissue" },
    { text: "Logout", path: "/" },
  ];
  const profitroutes = [
    { text: "Overview", path: "/profitloss" },
    { text: "Sales Prediction", path: "/salesprediction" },
  ];

  const adminroutes = [
    { text: "Restaurant", path: "/admin" },
    { text: "Create User", path: "/employee_create" },
    { text: "All Users", path: "/employee_list" },
  ];
  const handleChangePassword = () => {
    navigatingRef.current = true;

    navigate("/change_password");
  };
  const handleProfileNavigate = () => {
    navigatingRef.current = true;

    navigate("/profile");
  };
  const handleCreateNavigate = () => {
    navigatingRef.current = true;
    navigate("/employee_create");
  };
  const handleAllUserNavigate = () => {
    navigatingRef.current = true;

    navigate("/employee_list");
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (appBarRef.current && !appBarRef.current.contains(event.target)) {
        // The click was outside the AppBar, set isBarExpanded to false
        setIsBarExpanded(false);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    // Collapse the bar on location change if not navigating
    if (ignoreReduction) {
      setIsBarExpanded(true);
    } else {
      setIsBarExpanded(false);
    }

    // Reset the navigating flag after the location change is handled
    navigatingRef.current = false;
  }, [location, ignoreReduction]);

  const list = () => (
    <Box
      sx={{
        width: 260,
        height: "110vh",
        backgroundColor: "#FFBD00",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      role="presentation"
      // onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box
        sx={{
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mr: ".5rem",
        }}
      >
        <CloseIcon
          sx={{
            height: "28px",
            width: "28px",
          }}
          onClick={toggleDrawer(false)}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Box
          aria-controls="dropdown-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          padding="10px"
        >
          <Button
            sx={{ color: "#645e29", width: "100%" }}
            onClick={handleProfitClick}
            endIcon={
              <ArrowDropDownIcon
                sx={{
                  transition: "transform 0.5s", // smooth transition for rotation
                  transform: profitCollapseOpen
                    ? "rotate(-90deg)"
                    : "rotate(0deg)", // decides the rotation based on collapseOpen state
                }}
              />
            }
          >
            Profit and Loss
          </Button>
        </Box>
        <Collapse in={profitCollapseOpen}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              // alignItems: "start",
              marginLeft: "2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { lg: "row", xs: "row" },
              }}
            >
              <Box>
                {profitroutes.map(({ text, path }) => (
                  <ListItem
                    key={text}
                    disablePadding
                    onClick={() => handleNavigate(path)}
                  >
                    <ListItemButton sx={{ color: "#645e29" }}>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          // flexGrow: 1,
          // border: "solid red 1px",
        }}
      >
        <Box
          aria-controls="dropdown-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          padding="10px"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <Button
            sx={{ color: "#645e29", width: "100%" }}
            onClick={handleClick}
            endIcon={
              <ArrowDropDownIcon
                sx={{
                  transition: "transform 0.5s", // smooth transition for rotation
                  transform: adminCollapseOpen
                    ? "rotate(-90deg)"
                    : "rotate(0deg)", // decides the rotation based on collapseOpen state
                }}
              />
            }
          >
            Admin
          </Button>
        </Box>
        <Collapse
          in={adminCollapseOpen}
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              // alignItems: "start",
              marginLeft: "2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { lg: "row", xs: "row" },
              }}
            >
              <Box>
                {adminroutes.map(({ text, path }) => (
                  <ListItem
                    key={text}
                    disablePadding
                    onClick={() => handleNavigate(path)}
                  >
                    <ListItemButton sx={{ color: "#645e29" }}>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>
      {/* Profit and loss finished staff insight started */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          backgroundColor: "#FFBD00",
          // border: "solid red 1px",
          flexGrow: 1,
        }}
      >
        <Box>
          <Box variant="contained" color="primary" padding="10px">
            <Button
              sx={{ color: "#645e29", width: "100%" }}
              onClick={() => {
                setIsOpen(false);
                navigate("/getstarted");
              }}
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </Box>
      {/* User ends here */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          alignItems: "flex-start",
          position: "absolute",
          bottom: 20,
        }}
      >
        <Box
          aria-controls="dropdown-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          padding="10px"
        >
          <Button
            sx={{ color: "#645e29", width: "100%" }}
            onClick={handleUserToggle}
            endIcon={
              <ArrowDropDownIcon
                sx={{
                  transition: "transform 0.5s", // smooth transition for rotation
                  transform: userOpen ? "rotate(-90deg)" : "rotate(0deg)", // decides the rotation based on collapseOpen state
                }}
              />
            }
          >
            {userName}
          </Button>
        </Box>
        <Collapse in={userOpen}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              // alignItems: "start",
              marginLeft: "2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { lg: "row", xs: "row" },
              }}
            >
              <Box>
                {userroutes.map(({ text, path }) => (
                  <ListItem
                    key={text}
                    disablePadding
                    onClick={() => {
                      if (text === "Logout") {
                        handleLogout(); // Call handleLogout when "Logout" is clicked
                      } else {
                        handleNavigate(path);
                      }
                    }}
                  >
                    <ListItemButton sx={{ color: "#645e29" }}>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: { xs: "flex", lg: "none" },
        }}
      >
        <AppBar
          position="static"
          sx={{ backgroundColor: "#FFBD00", width: "100%" }}
        >
          <Toolbar
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Link to="/">
              <img
                src="transparent_logo.png"
                alt="Cover"
                style={{
                  maxHeight: "40px",
                  maxWidth: "120px",
                  marginRight: "8px",
                }}
              />
            </Link>

            <IconButton
              color="Secondary"
              aria-label="menu"
              sx={{ fontSize: "1rem", width: "2px" }}
            >
              <MenuIcon onClick={toggleDrawer(true)} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </Box>

      {/* NAV Bar for Large screens */}
      <AppBar
        onClick={handleBarClick}
        ref={appBarRef}
        sx={{
          display: { lg: "flex", xs: "none" },
          backgroundColor: "#FFBD00",
          width: isBarExpanded ? "12rem" : "4rem", // Same width as the Box
          height: "1",
          flexDirection: "column",
          position: "fixed", // It stays on the side as user scrolls
          top: 0,
          left: 0,
          zIndex: 1000,
          overflow: "auto", // Allow scrolling
          // Hide scrollbars
          "&::-webkit-scrollbar": {
            width: "0px",
            background: "transparent",
          },
          msOverflowStyle: "none", // for Internet Explorer and Edge
          scrollbarWidth: "none", // for Firefox
          transition: "width 0.1s ease-in-out", // This line adds the transition
        }}
      >
        <Toolbar
          sx={{
            flexDirection: "column",
            //   justifyContent: "start",
            alignItems: "center",
            gap: 4, // Spacing between items
            height: "100%",
            paddingLeft: { sm: 0 },
            paddingRight: { sm: 0 },
            width: "100%",
          }}
        >
          {/* <Link to="/.">
                <img
                  src="https://restostagingstorage3.blob.core.windows.net/static/cover_photo_big.png"
                  alt="Cover"
                  style={{
                    maxHeight: "40px",
                    maxWidth: "120px",
                    marginRight: "8px",
                  }}
                />
              </Link> */}

          <Box onClick={handleLogoClick} sx={{ cursor: "pointer" }}>
            <img
              src="small_logo_transparent.png"
              alt="Cover"
              style={{
                maxHeight: "40px",
                maxWidth: "60px",
                // marginRight: "8px",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // width: "100%",
              height: "100%",
              justifyContent: "space-between",
              alignItems: isBarExpanded ? "left" : "center",
              width: "100%",
              // gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                // border: "solid red 1px",
                flexGrow: 1,
              }}
            >
              {/* Profit And Loss starts from here */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  // gap: 1,
                }}
              >
                {!isBarExpanded ? (
                  <IconButton
                    onClick={() => {
                      handleProfitToggle();
                      setClickedIcon("Profit");
                    }}
                    sx={{
                      color: "#645e29",
                      display: "flex",
                      backgroundColor:
                        clickedIcon === "Profit" ? "white" : "none",
                      // my: "1.5rem",
                      // width: isBarExpanded ? "50%" : "100%",
                    }}
                  >
                    <MonetizationOnIcon />
                  </IconButton>
                ) : (
                  <Button
                    onClick={() => {
                      handleProfitToggle();
                      setClickedIcon("Profit");
                    }}
                    sx={{
                      color: "#645e29",
                      display: "flex",
                      fontWeight: "bold",
                      backgroundColor:
                        clickedIcon === "Profit" ? "white" : "none",
                      // my: "1.5rem",
                      // width: isBarExpanded ? "50%" : "100%",
                      fontSize: "12px",
                      justifyContent: "flex-start",
                    }}
                    startIcon={<MonetizationOnIcon />}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    Profit & Loss
                  </Button>
                )}
              </Box>
              <Collapse
                in={profitOpen}
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "#645e29",
                  }}
                >
                  {/* <ArrowDropDownIcon
                    sx={{
                      transition: "transform 0.5s",
                      transform: profitOpen ? "rotate(0deg)" : "rotate(-90deg)",
                    }}
                  /> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      // gap: 1,
                    }}
                  >
                    {!isBarExpanded ? (
                      <IconButton
                        onClick={() => {
                          handleHighLevelNavigate();
                          setClickedIcon("High");
                        }}
                        sx={{
                          border:
                            clickedIcon === "High"
                              ? "2px solid #645e29"
                              : "none",
                          margineY: "0.5rem",
                        }}
                      >
                        <ShowChartIcon />
                      </IconButton>
                    ) : (
                      <>
                        <Button
                          onClick={() => {
                            handleHighLevelNavigate();
                            setClickedIcon("High");
                          }}
                          sx={{
                            color: "#645e29",
                            display: "flex",
                            fontWeight: "bold",
                            border:
                              clickedIcon === "High"
                                ? "2px solid #645e29"
                                : "none",
                            fontSize: "12px",
                            justifyContent: "flex-start",
                          }}
                          startIcon={<ShowChartIcon />}
                        >
                          Overview
                        </Button>
                      </>
                    )}

                    {/* {isBarExpanded ? (
                        <Typography
                          sx={{
                            color: "#645e29",
                            fontSize: "12px",
                            fontWeight: 700,
                          }}
                        >
                          High-level Insight
                        </Typography>
                      ) : null} */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      // gap: 1,
                    }}
                  >
                    {!isBarExpanded ? (
                      <IconButton
                        onClick={() => {
                          handlePredictionNavigate();
                          setClickedIcon("Prediction");
                        }}
                        sx={{
                          border:
                            clickedIcon === "Prediction"
                              ? "2px solid #645e29"
                              : "none",
                          marginY: "0.5rem",
                          // width: isBarExpanded ? "50%" : "100%",
                        }}
                      >
                        <InsightsIcon />
                      </IconButton>
                    ) : (
                      <Button
                        onClick={() => {
                          handlePredictionNavigate();
                          setClickedIcon("Prediction");
                        }}
                        sx={{
                          color: "#645e29",
                          display: "flex",
                          fontWeight: "bold",
                          border:
                            clickedIcon === "Prediction"
                              ? "2px solid #645e29"
                              : "none",
                          // my: "1.5rem",
                          // width: isBarExpanded ? "50%" : "100%",
                          fontSize: "12px",
                          justifyContent: "flex-start",
                        }}
                        startIcon={<InsightsIcon />}
                      >
                        Sales prediction
                      </Button>
                    )}
                    {/* {isBarExpanded ? (
                        <Typography
                          sx={{
                            color: "#645e29",
                            fontSize: "12px",
                            fontWeight: 700,
                          }}
                        >
                          Prediction Deep Dive
                        </Typography>
                      ) : null} */}
                  </Box>
                </Box>
              </Collapse>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  // gap : 1
                }}
              >
                {!isBarExpanded ? (
                  <IconButton
                    onClick={() => {
                      handleAdminToggle();
                      setClickedIcon("Admin");
                    }}
                    sx={{
                      color: "#645e29",
                      display: "flex",
                      backgroundColor:
                        clickedIcon === "Admin" ? "white" : "none",
                      // width: isBarExpanded ? "50%" : "100%",
                    }}
                  >
                    <AdminPanelSettingsIcon />
                  </IconButton>
                ) : (
                  <Button
                    onClick={() => {
                      handleAdminToggle();
                      setClickedIcon("Admin");
                    }}
                    sx={{
                      color: "#645e29",
                      display: "flex",
                      fontWeight: "bold",
                      backgroundColor:
                        clickedIcon === "Admin" ? "white" : "none",
                      // my: "1.5rem",
                      // width: isBarExpanded ? "50%" : "100%",
                      fontSize: "12px",
                      justifyContent: "flex-start",
                    }}
                    startIcon={<AdminPanelSettingsIcon />}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    Admin
                  </Button>
                )}
                {/* {isBarExpanded ? (
                    <Typography
                      sx={{
                        color: "#645e29",
                        fontSize: "12px",
                        fontWeight: 700,
                      }}
                    >
                      Admin
                    </Typography>
                  ) : null} */}
              </Box>
              <Collapse
                in={adminOpen}
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    color: "#645e29",
                    // mt: -2,
                    width: "100%",
                  }}
                >
                  {/* <ArrowDropDownIcon
                    sx={{
                      transition: "transform 0.5s",
                      transform: adminOpen ? "rotate(0deg)" : "rotate(-90deg)",
                    }}
                  /> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      // gap: 1,
                    }}
                  >
                    {!isBarExpanded ? (
                      <IconButton
                        onClick={() => {
                          handleAdminNavigate();
                          setClickedIcon("restaurant");
                        }}
                        sx={{
                          border:
                            clickedIcon === "restaurant"
                              ? "2px solid #645e29"
                              : "none",
                          marginY: "0.5rem",
                          // width: isBarExpanded ? "50%" : "100%",
                        }}
                      >
                        <RestaurantMenuIcon />
                      </IconButton>
                    ) : (
                      <Button
                        onClick={() => {
                          handleAdminNavigate();
                          setClickedIcon("restaurant");
                        }}
                        sx={{
                          color: "#645e29",
                          display: "flex",

                          border:
                            clickedIcon === "restaurant"
                              ? "2px solid #645e29"
                              : "none",
                          // my: "1.5rem",
                          // width: isBarExpanded ? "50%" : "100%",
                          fontSize: "12px",
                          fontWeight: "bold",
                          justifyContent: "flex-start",
                        }}
                        startIcon={<RestaurantIcon />}
                      >
                        Restaurant
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    color: "#645e29",
                    // mt: -2,
                    width: "100%",
                  }}
                >
                  {/* <ArrowDropDownIcon
                            sx={{
                              transition: "transform 0.5s",
                              transform: userAdminOpen
                                ? "rotate(0deg)"
                                : "rotate(-90deg)",
                            }}
                          /> */}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      // gap: 1,
                    }}
                  >
                    {!isBarExpanded ? (
                      <IconButton
                        onClick={() => {
                          handleCreateNavigate();
                          setClickedIcon("create");
                        }}
                        sx={{
                          border:
                            clickedIcon === "create"
                              ? "2px solid #645e29"
                              : "none",
                          marginY: "0.5rem",

                          // width: isBarExpanded ? "50%" : "100%",
                        }}
                      >
                        <GroupAddIcon />
                      </IconButton>
                    ) : (
                      <Button
                        onClick={() => {
                          handleCreateNavigate();
                          setClickedIcon("create");
                        }}
                        sx={{
                          color: "#645e29",
                          display: "flex",
                          border:
                            clickedIcon === "create"
                              ? "2px solid #645e29"
                              : "none",
                          // my: "1.5rem",
                          // width: isBarExpanded ? "50%" : "100%",
                          fontSize: "12px",
                          fontWeight: "bold",
                          justifyContent: "flex-start",
                        }}
                        startIcon={<GroupAddIcon />}
                      >
                        Create User
                      </Button>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      // gap: 1,
                    }}
                  >
                    {!isBarExpanded ? (
                      <IconButton
                        onClick={() => {
                          handleAllUserNavigate();
                          setClickedIcon("allUser");
                        }}
                        sx={{
                          border:
                            clickedIcon === "allUser"
                              ? "2px solid #645e29"
                              : "none",
                          // marginY: "0.5rem",
                          // width: isBarExpanded ? "50%" : "100%",
                        }}
                      >
                        <GroupIcon />
                      </IconButton>
                    ) : (
                      <Button
                        onClick={() => {
                          handleAllUserNavigate();
                          setClickedIcon("allUser");
                        }}
                        sx={{
                          color: "#645e29",
                          display: "flex",
                          fontWeight: "bold",
                          border:
                            clickedIcon === "allUser"
                              ? "2px solid #645e29"
                              : "none",
                          // my: "1.5rem",
                          // width: isBarExpanded ? "50%" : "100%",
                          fontSize: "12px",
                          justifyContent: "flex-start",
                        }}
                        startIcon={<GroupIcon />}
                      >
                        All Users
                      </Button>
                    )}
                  </Box>
                </Box>
              </Collapse>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "flex-start",
                  justifyContent: "space-evenly",
                  flexGrow: 1,

                  // gap : 1
                }}
              >
                {!isBarExpanded ? (
                  <IconButton
                    onClick={() => {
                      handleGetStarted();
                      setClickedIcon("GetStarted");
                    }}
                    sx={{
                      color: "#645e29",
                      display: "flex",
                      justifyContent: "column",
                      backgroundColor:
                        clickedIcon === "GetStarted" ? "white" : "none",
                      // my: "2rem",
                      // width: isBarExpanded ? "50%" : "100%",
                    }}
                  >
                    <PlayCircleFilledIcon />
                  </IconButton>
                ) : (
                  <Button
                    onClick={() => {
                      handleGetStarted();
                      setClickedIcon("GetStarted");
                    }}
                    sx={{
                      color: "#645e29",
                      display: "flex",
                      fontWeight: "bold",
                      backgroundColor:
                        clickedIcon === "GetStarted" ? "white" : "none",
                      // my: "1.5rem",
                      // width: isBarExpanded ? "50%" : "100%",
                      fontSize: "12px",
                      justifyContent: "flex-start",
                    }}
                    startIcon={<PlayCircleFilledIcon />}
                  >
                    Get Started
                  </Button>
                )}
                {/* {isBarExpanded ? (
                  <Typography
                    sx={{
                      color: "#645e29",
                      fontSize: "12px",
                      fontWeight: 700,
                    }}
                  >
                    Staff Insight
                  </Typography>
                ) : null} */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                  // border: "solid red 1px",
                  // flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    flexDirection: "column-reverse",
                    // border: "solid red 1px",
                    display: "flex",
                    my: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      // border: "solid red 1px",

                      gap: 2,
                    }}
                  >
                    {!isBarExpanded ? (
                      <IconButton
                        onClick={() => {
                          handleUserToggle();
                          setClickedIcon("user");
                        }}
                        sx={{
                          color: "#645e29",
                          display: "flex",
                          justifyContent: "column",
                          backgroundColor:
                            clickedIcon === "user" ? "white" : "none",
                          my: "1.5rem",
                          // width: isBarExpanded ? "50%" : "100%",
                        }}
                      >
                        <AccountCircleIcon />
                      </IconButton>
                    ) : (
                      <Button
                        onClick={() => {
                          handleUserToggle();
                          setClickedIcon("user");
                        }}
                        sx={{
                          color: "#645e29",
                          display: "flex",
                          backgroundColor:
                            clickedIcon === "user" ? "white" : "none",
                          // my: "1.5rem",
                          // width: isBarExpanded ? "50%" : "100%",
                          fontSize: "12px",
                          fontWeight: "bold",
                          justifyContent: "flex-start",
                        }}
                        startIcon={<AccountCircleIcon />}
                      >
                        {userName}
                      </Button>
                    )}
                    {/* {isBarExpanded ? (
                      <Typography
                        sx={{
                          color: "#645e29",
                          fontSize: "12px",
                          fontWeight: 700,
                        }}
                      >
                        User
                      </Typography>
                    ) : null} */}
                  </Box>
                  <Collapse
                    in={userOpen}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        color: "#645e29",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          // gap : 1
                        }}
                      >
                        {!isBarExpanded ? (
                          <IconButton
                            onClick={() => {
                              handleProfileNavigate();
                              setClickedIcon("Profile");
                            }}
                            sx={{
                              border:
                                clickedIcon === "Profile"
                                  ? "2px solid #645e29"
                                  : "none",
                              marginY: "0.5rem",
                              // width: isBarExpanded ? "50%" : "100%",
                            }}
                          >
                            <PersonPinIcon />
                          </IconButton>
                        ) : (
                          <Button
                            onClick={() => {
                              handleProfileNavigate();
                              setClickedIcon("Profile");
                            }}
                            sx={{
                              color: "#645e29",
                              display: "flex",

                              border:
                                clickedIcon === "Profile"
                                  ? "2px solid #645e29"
                                  : "none",
                              // my: "1.5rem",
                              // width: isBarExpanded ? "50%" : "100%",
                              fontSize: "12px",
                              justifyContent: "flex-start",
                            }}
                            startIcon={<PersonPinIcon />}
                          >
                            Profile
                          </Button>
                        )}
                        {/* {isBarExpanded ? (
                          <Typography
                            sx={{
                              color: "#645e29",
                              fontSize: "12px",
                              fontWeight: 700,
                            }}
                          >
                            Profile
                          </Typography>
                        ) : null} */}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          // gap : 1
                        }}
                      >
                        {!isBarExpanded ? (
                          <IconButton
                            onClick={() => {
                              handleChangePassword();
                              setClickedIcon("Password Change");
                            }}
                            sx={{
                              border:
                                clickedIcon === "Password Change"
                                  ? "2px solid #645e29"
                                  : "none",
                              marginY: "0.5rem",
                              // width: isBarExpanded ? "50%" : "100%",
                            }}
                          >
                            <VpnKeyIcon />
                          </IconButton>
                        ) : (
                          <Button
                            onClick={() => {
                              handleChangePassword();
                              setClickedIcon("Password Change");
                            }}
                            sx={{
                              color: "#645e29",
                              display: "flex",

                              border:
                                clickedIcon === "Password Change"
                                  ? "2px solid #645e29"
                                  : "none",
                              // my: "1.5rem",
                              // width: isBarExpanded ? "50%" : "100%",
                              fontSize: "12px",
                              justifyContent: "flex-start",
                            }}
                            startIcon={<VpnKeyIcon />}
                          >
                            Change Password
                          </Button>
                        )}
                        {/* {isBarExpanded ? (
                          <Typography
                            sx={{
                              color: "#645e29",
                              fontSize: "12px",
                              fontWeight: 700,
                            }}
                          >
                            Change Password
                          </Typography>
                        ) : null} */}
                      </Box>
                      {/* <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          // gap : 1
                        }}
                      >
                        {!isBarExpanded ? (
                          <IconButton
                            onClick={() => {
                              handleReportPage();
                              setClickedIcon("Report Page");
                            }}
                            sx={{
                              border:
                                clickedIcon === "Report Page"
                                  ? "2px solid #645e29"
                                  : "none",
                              marginY: "0.5rem",
                              // width: isBarExpanded ? "50%" : "100%",
                            }}
                          >
                            <ContactSupportIcon />
                          </IconButton>
                        ) : (
                          <Button
                            onClick={() => {
                              handleReportPage();
                              setClickedIcon("Report Page");
                            }}
                            sx={{
                              color: "#645e29",
                              display: "flex",

                              border:
                                clickedIcon === "Report Page"
                                  ? "2px solid #645e29"
                                  : "none",
                              // my: "1.5rem",
                              // width: isBarExpanded ? "50%" : "100%",
                              fontSize: "12px",
                              justifyContent: "flex-start",
                            }}
                            startIcon={<ContactSupportIcon />}
                          >
                            Contact Us
                          </Button>
                        )}
                      
                      </Box> */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          // gap : 1
                        }}
                      >
                        {!isBarExpanded ? (
                          <IconButton
                            onClick={() => {
                              handleLogout();
                            }}
                            sx={{
                              // border:
                              //   clickedIcon === "Logou"
                              //     ? "2px solid #645e29"
                              //     : "none",
                              marginY: "0.5rem",
                              // width: isBarExpanded ? "50%" : "100%",
                            }}
                          >
                            <LogoutIcon />
                          </IconButton>
                        ) : (
                          <Button
                            onClick={() => {
                              handleLogout();
                            }}
                            sx={{
                              color: "#645e29",
                              display: "flex",

                              // border:
                              //   clickedIcon === "Password Change"
                              //     ? "2px solid #645e29"
                              //     : "none",
                              // my: "1.5rem",
                              // width: isBarExpanded ? "50%" : "100%",
                              fontSize: "12px",
                              justifyContent: "flex-start",
                            }}
                            startIcon={<LogoutIcon />}
                          >
                            Logout
                          </Button>
                        )}
                        {/* {isBarExpanded ? (
                          <Typography
                            sx={{
                              color: "#645e29",
                              fontSize: "12px",
                              fontWeight: 700,
                            }}
                          >
                            Logout
                          </Typography>
                        ) : null} */}
                      </Box>
                    </Box>
                  </Collapse>
                </Box>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
