import React, { useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { getCompanyColors } from "../CompanyUtils/CompanyColor";

import { formatRegressorValue } from "../../utils/FormatRegressor";
import { getLineTickValues } from "../../utils/GetLineTickValues";
import formatNumber from "../../utils/FormatNumber";

const latest_created_at = localStorage.getItem("latest_records");
const company = localStorage.getItem("company");

const WeatherLineChart = ({
  data,
  isDetailedChart,
  isCombinedChart = false,
  isStaffChart = false,
  isNotPercentage = false,
  isSecondaryChart = false,
  secondaryData = null,
  range = null,
  isXSScreen = false,
  isMinimal = false,
  hasPredictionLine = false,
  predictionLineDate = null,
  isMinNonZero = false,
  isCurrency = false,
  noPrefix = false,
  hasStaffMarker = false,
  isSalesPrediction = false,
}) => {
  const [activeId, setActiveId] = useState(null);

  // console.log(data, "data hera");
  const format = !isNotPercentage
    ? (v) => `${v}%`
    : isCurrency
    ? (v) => `${v} NOK`
    : (v) => `${v} units`;

  const goalPercentage = company === "Fisketorget" ? 32 : 28;

  const lineData = data && Object.entries(data).length !== 0 ? [data] : [];
  const { tickValuesI, tickValuesO } = getLineTickValues(data);

  if (data && data.length > 0) {
    data = data.map((series) => ({
      ...series,
      data: series.data.map((point) => ({
        ...point,
        y: isNaN(point.y) ? 0 : point.y,
      })),
    }));
  }

  let filteredData = data;
  if (activeId !== null) {
    const foundSerie = data.find((serie) => serie.id === activeId);
    filteredData = foundSerie ? [foundSerie] : [];
  }

  const allYValues = filteredData?.flatMap((series) =>
    series.data.map((point) => point.y)
  );

  const minYValue = Math.min(...allYValues);
  const maxYValue = Math.max(...allYValues);

  let yScaleMin, yScaleMax;

  if (minYValue < 0 && maxYValue <= 0) {
    yScaleMin = minYValue;
    yScaleMax = Math.abs(minYValue);
  } else if (minYValue < 0 && maxYValue > 0) {
    yScaleMin = minYValue;
    yScaleMax = maxYValue;
  } else {
    yScaleMin = 0;
    yScaleMax = maxYValue;
  }

  let staffMarkerDate;
  if (hasStaffMarker && latest_created_at) {
    staffMarkerDate = new Date(latest_created_at);
    staffMarkerDate?.setDate(staffMarkerDate.getDate() + 14);
    staffMarkerDate?.setHours(0, 0, 0, 0);
  }
  let predictionLineMarkerDate;
  if (hasPredictionLine && latest_created_at) {
    predictionLineMarkerDate = new Date(latest_created_at);
    predictionLineMarkerDate?.setHours(0, 0, 0, 0);
  }

  const pointTooltip = ({ point }) => {
    const date = new Date(point.data.x);
    let formattedDate;

    if (range === "month") {
      formattedDate = date.toLocaleDateString(undefined, {
        month: "long",
        year: "numeric",
      });
    } else if (range === "week") {
      // Logic to format the date as week
    } else {
      formattedDate = date.toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }

    const latestCreatedAtDate = new Date(latest_created_at)?.setHours(
      0,
      0,
      0,
      0
    );
    const pointDate = new Date(point.data.x)?.setHours(0, 0, 0, 0);

    let tooltipTitle;
    if (isStaffChart && pointDate <= latestCreatedAtDate) {
      tooltipTitle = "Historical";
    } else {
      tooltipTitle = "Prediction";
    }

    const aggregatedDetails = point.data.details.reduce((acc, detail) => {
      if (acc[detail.name]) {
        acc[detail.name] += detail.effect;
      } else {
        acc[detail.name] = detail.effect;
      }
      return acc;
    }, {});

    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          borderRadius: "5px",
        }}
      >
        <strong>{formattedDate}</strong>
        <br />
        <strong
          style={{
            color: tooltipTitle === "Historical" ? "#543c8c" : point.serieColor,
            marginTop: "10px",
          }}
        >
          {formatRegressorValue(point.serieId)}:{" "}
          {isNotPercentage
            ? formatNumber(Math.round(point.data.y))
            : `${point.data.y}%` + (isStaffChart ? ` ${tooltipTitle}` : "")}
        </strong>
        {Object.keys(aggregatedDetails).length > 0 && (
          <>
            {Object.keys(aggregatedDetails).map((name, index) => (
              <div key={index} style={{}}>
                <strong>
                  {formatRegressorValue(name)}:{" "}
                  {formatNumber(aggregatedDetails[name])}{" "}
                </strong>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  const axisProps = !isSecondaryChart
    ? {
        axisLeft: {
          tickValues: [0, minYValue, maxYValue],
          legendOffset: -40,
        },
        colors: getCompanyColors(),
        tooltip: pointTooltip,
      }
    : {
        colors: "#4169E1",
        axisRight: {
          format,
          legendOffset: 12,
        },
        sliceTooltip: ({ slice }) => {
          const currentDate = new Date(slice.points[0].data.x);
          let formattedDate;

          if (range === "month") {
            formattedDate = currentDate.toLocaleDateString(undefined, {
              month: "long",
              year: "numeric",
            });
          } else if (range === "week") {
            const sunday = new Date(currentDate);
            sunday.setDate(sunday.getDate() - sunday.getDay());

            const startOfYear = new Date(sunday.getFullYear(), 0, 1);
            const dayDifference =
              (sunday - startOfYear) / (24 * 60 * 60 * 1000);

            const weekNumber =
              1 + Math.floor((dayDifference + startOfYear.getDay() + 5) / 7);
            formattedDate = `${currentDate.toLocaleDateString(undefined, {
              month: "long",
            })} (Week ${weekNumber}) ${sunday.getFullYear()}`;
          } else {
            formattedDate = currentDate.toLocaleDateString(undefined, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }

          const matchedData = secondaryData[0].data.find(
            (d) =>
              d.x.toISOString().split("T")[0] ===
              currentDate.toISOString().split("T")[0]
          );

          const secondaryYValue = matchedData ? matchedData.y : null;

          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              <strong>{formattedDate}</strong>
              {slice.points.map((point, index) => (
                <div
                  key={index}
                  style={{
                    color: "#4169E1",
                    padding: "3px 0",
                  }}
                >
                  <strong>{point.serieId}:</strong>{" "}
                  {parseInt(point.data.yFormatted)}
                  {secondaryYValue && (
                    <div
                      style={{
                        color: "#E63946",
                        padding: "3px 0",
                      }}
                    >
                      <strong>Staff Cost Percentage:</strong> {secondaryYValue}%
                    </div>
                  )}
                </div>
              ))}
            </div>
          );
        },
      };

  return !lineData || lineData.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    <ResponsiveLine
      animate
      enableGridX={isMinimal ? false : !isSecondaryChart}
      enableGridY={isMinimal ? true : !isSecondaryChart}
      gridYValues={[0, minYValue, maxYValue]}
      axisBottom={{
        format: (value) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString(undefined, {
            month: "short",
            day: "numeric",
          });

          return formattedDate;
        },

        legendOffset: -12,
        tickValues: isXSScreen ? tickValuesO : tickValuesI,
      }}
      curve="monotoneX"
      data={filteredData}
      {...axisProps}
      margin={
        isMinimal
          ? {
              bottom: 50,
              left: noPrefix ? 50 : maxYValue > 100 ? 80 : 80,
              right: 50,
              top: 30,
            }
          : isStaffChart
          ? {
              bottom: 60,
              left: 80,
              right: 90,
              top: 30,
            }
          : isSalesPrediction
          ? {
              bottom: 60,
              left: maxYValue > 100000 ? 80 : 40,
              right: 40,
              top: 80,
            }
          : {
              bottom: 60,
              left: maxYValue > 100000 ? 80 : 40,
              right: 40,
              top: 50,
            }
      }
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      lineWidth={1}
      markers={[
        ...(isStaffChart && !isSecondaryChart
          ? [
              {
                axis: "y",
                value: goalPercentage,
                legend: `Goal ${goalPercentage}%`,
                lineStyle: {
                  stroke: "gray",
                  strokeWidth: 2,
                },
              },
            ]
          : []),
        hasPredictionLine
          ? {
              axis: "x",
              value: predictionLineMarkerDate,
              lineStyle: {
                stroke: "#505050",
                strokeWidth: 2,
              },

              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
        hasStaffMarker
          ? {
              axis: "x",
              value: staffMarkerDate,
              lineStyle: {
                stroke: "green",
                strokeWidth: 2,
              },
              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
      ]}
      pointBorderWidth={1}
      pointSize={4}
      enableArea={true}
      areaBaselineValue={isMinNonZero ? 0 : 0}
      areaOpacity={0.15}
      labelFormat={format}
      useMesh
      xFormat="time:%Y-%m-%d"
      xScale={{
        format: "%Y-%m-%d",
        precision: "day",
        type: "time",
        useUTC: true,
      }}
      yScale={{
        type: "linear",
        min: yScaleMin,
        max: yScaleMax,
      }}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: isSecondaryChart ? 150 : 2,
          translateY: 50,
          itemsSpacing: 10,
          itemDirection: "left-to-right",
          itemWidth: 109,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
          onClick: (datum) =>
            setActiveId(activeId === datum.id ? null : datum.id),
        },
      ]}
    />
  );
};

export default WeatherLineChart;
