import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  // Grid,
  //    Container,
  // Card,
  Typography,
  // TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // CardContent,
  Tooltip,
  Zoom,
  useMediaQuery,
  // Collapse,
  OutlinedInput,
  // Checkbox,
  // ListItemText,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  Divider,
  // styled,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LastUpdate from "../utils/LastUpdate";
import TakeAway from "../components/TakeAway";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChartLoading from "../components/Loading/ChartLoading";
import downloadTrends from "../utils/ExcelDownloads/DownloadTrends";
import ComparisonHourlyChart from "../components/LineCharts/ComparisonHourlyChart";
import ComparisonLineChart from "../components/LineCharts/ComparisonLineChart";
import getHighlightedStyle from "../utils/HighlightedStyle";
import InfoIcon from "@mui/icons-material/Info";
import NavigationButtons from "../utils/HelpButton";
import dayjs from "dayjs";
import getRandomColor from "../utils/RandomColor";
import DeliveryChart from "../components/BarCharts/DeliveryBarChart";
import TopTenBarChart from "../components/BarCharts/TopTenBarChart";
import SettingsDialog from "../components/SettingsDialog";
import SnackbarAlert from "../utils/SnackbarAlert";
import FormatDesiredDate from "../utils/FormatDesiredDate";
import UpwardTrends from "./DashboardComponents/UpwardTrends";
import DownwardTrends from "./DashboardComponents/DownwardTrends";
import CloseIcon from "@mui/icons-material/Close";
import KPILoad from "../components/Loading/KPILoad";
import TitleStyle from "../components/Style/Title";

let prefix = "";

if (process.env.NODE_ENV === "development") {
  prefix = "http://127.0.0.1:8000";
}
// const comp = localStorage.getItem("company");
const token = localStorage.getItem("token");
// const default_restaurant = JSON.parse(
//   localStorage.getItem("user")
// )?.restaurant_name;
const isAdmin = JSON.parse(localStorage.getItem("user"))?.company_admin;

const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
const latest_created_at = localStorage.getItem("latest_records");

const ITEM_HEIGHT = "1rem";
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const isAdmin = JSON.parse(localStorage.getItem("user"))?.company_admin;
// const isEmpty = (obj) => {
//   return Object.keys(obj).length === 0 && obj.constructor === Object;
// };
const Trends = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  hasPredictionData,
  setHasPredictionData,
  range,
  company,
  setCompany,
}) => {
  const now = latest_created_at ? new Date(latest_created_at) : new Date();
  const email = JSON.parse(localStorage.getItem("user"))?.email;

  // const [company, setCompany] = useState(comp);
  const [selectedSupergroup, setSelectedSupergroup] = useState("All");

  const [selectedThirdPartySupergroup, setSelectedThirdPartySupergroup] =
    useState("All");

  const [articleSupergroups, setArticleSupergroups] = useState([]);
  const [selectedComparisonSupergroup, setSelectedComparisonSupergroup] =
    useState("");
  const [comparisonArticleSupergroups, setComparisonArticleSupergroups] =
    useState([]);
  // const [isSubCategoryVisible, setIsSubCategoryVisible] = useState(false);
  const [isComparisonSubCategoryVisible, setIsComparisonSubCategoryVisible] =
    useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  // const [range, setRange] = useState("day");

  // const [showChart, setShowChart] = useState(false); // New state to control chart visibility
  // const [selectedSubgroup, setSelectedSubgroup] = useState([]);
  // const [articleSubgroups, setArticleSubgroups] = useState([]);
  const [trendsLoad, setTrendsLoad] = useState([]);
  const [selectedComparisonSubgroup, setSelectedComparisonSubgroup] = useState(
    []
  );
  const [comparisonArticleSubgroups, setComparisonArticleSubgroups] = useState(
    []
  );

  // const [topTenSellers, setTopTenSellers] = useState([]);
  // const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [topTenLoad, setTopTenLoad] = useState([]);

  // const [filteredChartData, setFilteredChartData] = useState([]);
  const [comparisonFilteredChartData, setComparisonFilteredChartData] =
    useState([]);

  const [subProductChartData, setSubProductChartData] = useState([]); // Chart data for sub-products
  const [comparisonSubProductChartData, setComparisonSubProductChartData] =
    useState([]); // Chart data for sub-products

  // const [prodLineLoad, setProdLineLoad] = useState(false);
  // const [subprodLineLoad, setSubProdLineLoad] = useState(false);

  const [prodComparisonLineLoad, setProdComparisonLineLoad] = useState(false);
  const [subProdComparisonLineLoad, setSubProdComparisonLineLoad] =
    useState(false);

  const [positiveAmountTrendData, setPositiveAmountTrendData] = useState([]);
  const [negativeAmountTrendData, setNegativeAmountTrendData] = useState([]);
  const [positiveQuantityTrendData, setPositiveQuantityTrendData] = useState(
    []
  );
  const [negativeQuantityTrendData, setNegativeQuantityTrendData] = useState(
    []
  );

  // const [averageDeliveryCost, setAverageDeliveryCost] = useState([]);
  const [deliveryCostLoad, setDeliveryCostLoad] = useState(false);
  const [deliveryCost, setDeliveryCost] = useState([]);
  const [hasProductChanged, setHasProductChanged] = useState(false);
  const [defaultRestaurants, setDefaultRestaurants] = useState([]);
  const [helpMode, setHelpMode] = useState(false);
  const [counter, setCounter] = useState(1);
  const maxSteps = 11;
  // const abortControllerRefForProductMix = useRef(new AbortController());
  const abortControllerRefForCompProductMix = useRef(new AbortController());

  const getRestaurantNameById = (restaurantName) => {
    const restaurant = defaultRestaurants.find(
      (r) => r.name === restaurantName
    );
    return restaurant ? restaurant.name : "";
  };
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState(null);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isCurrency, setIsCurrency] = useState(true);
  // const [hasPredictionData, setHasPredictionData] = useState(false);
  const [productMixData, setProductMixData] = useState([]);
  const [comparisonProductMixData, setComparisonProductMixData] = useState([]);
  // const [subproductMixData, setSubproductMixData] = useState([]);
  const [comparisonSubProductMixData, setComparisonSubProductMixData] =
    useState([]);

  const [firstProductSum, setFirstProductSum] = useState(0);

  const [secondProductSum, setSecondProductSum] = useState(0);
  // const [productSumKPI, setProductSumKPI] = useState(0);
  const [productPercentageDifference, setProductPercentageDifference] =
    useState(0);
  const [firstSubProductSum, setFirstSubProductSum] = useState(0);

  const [secondSubProductSum, setSecondSubProductSum] = useState(0);

  // const [subProductSumKPI, setSubProductSumKPI] = useState(0);
  const [subProductPercentageDifference, setSubProductPercentageDifference] =
    useState(0);
  console.log(
    firstSubProductSum,
    productPercentageDifference,
    subProductPercentageDifference,
    secondSubProductSum,
    "xdd"
  );

  const [barChartData, setBarChartData] = useState([]);
  const [trendsToggle, setTrendsToggle] = useState(true);
  const superGroupProductMixData = useMemo(() => {
    return productMixData
      .find((data) => data.id === selectedSupergroup)
      ?.data?.map((_data) => ({
        date: _data.x,
        category: selectedSupergroup,
        value: _data.y,
      }));
  }, [selectedSupergroup, productMixData]);

  // const groupProductMixData = useMemo(() => {
  //   if (selectedSubgroup.length === 1) {
  //     return subproductMixData
  //       .find((data) => data.id === selectedSubgroup[0])
  //       .data.map((_data) => ({
  //         date: _data.x,
  //         category: selectedSubgroup[0],
  //         value: _data.y,
  //       }));
  //   }
  // }, [selectedSubgroup, subproductMixData]);
  const getTooltipContent = () => {
    let comparisonText = trendsToggle
      ? "last year"
      : "average sales of the last";
    switch (dataRange) {
      case "today":
        return `Showing sales of the relevant day compared against ${comparisonText} 14 days`;
      case "thisweek":
        return `Showing sales of the relevant week compared against ${comparisonText} 4 weeks`;
      case "thismonth":
        return `Showing sales of the relevant month compared against ${comparisonText} 3 months`;
      default:
        return "Showing sales of today";
    }
  };

  const tooltipContent = {
    1: (
      <Typography variant="body2">
        You can filter the dashboards to see the whole company, individual
        restaurants or a combination of multiple restaurants
      </Typography>
    ),
    2: (
      <Typography variant="body2">
        Choose to filter the dashboard by a single day, week or a whole month.
        You can easily navigate back and forth with the arrows
      </Typography>
    ),

    3: (
      <Typography variant="body2">
        Choose to only see actual sales (To Date) or to include the projected
        sales. When you choose the projected view you will see the actual sales
        when there is actual sales, and predicted sales for all dates or hours
        in the future. Look for the vertical line in the graphs to see where the
        actual sales stop and the projected begins
      </Typography>
    ),
    4: (
      <Typography variant="body2">
        This is the toggle between units and NOK.
      </Typography>
    ),
    5: (
      <Typography variant="body2">
        This chart shows Top Ten selling products over the time period.
      </Typography>
    ),
    6: <Typography variant="body2">This table shows upwards trends</Typography>,
    7: (
      <Typography variant="body2">This table shows downwards trends</Typography>
    ),
    8: (
      <Typography variant="body2">
        This graph shows comparison between products
      </Typography>
    ),
    9: (
      <Typography variant="body2">
        This graph shows Take-out share versus dine-in. this can help you
        understand how many employees you need at work, as high take-away share
        normally requires fewer employees at work. Combine this with the sales
        prediction to create a great staff plan
      </Typography>
    ),
    10: (
      <Typography variant="body2">
        Delivery cost is what the restaurant has to pay to companies like
        Foodora and Wolt for their delivery services.{" "}
      </Typography>
    ),
    // 11: (
    //   <Typography variant="body2">
    //     Delivery cost is what the restaurant has to pay to companies like
    //     Foodora and Wolt for their delivery services.
    //   </Typography>
    // ),
  };

  const downloadOptions = [
    () =>
      downloadTrends(
        positiveAmountTrendData,
        negativeAmountTrendData,
        positiveQuantityTrendData,
        negativeQuantityTrendData,
        isComparisonSubCategoryVisible ? [] : superGroupProductMixData,
        // isSubCategoryVisible ? groupProductMixData : [],
        selectedRestaurant,
        barChartData
      ),
    // Add other function calls as necessary
  ];
  const handleHelpMode = () => {
    setHelpMode(!helpMode);
    setCounter(1); // Reset counter when toggling helpMode
  };

  const handleNext = () => {
    setCounter((prev) => {
      const newCounter = prev + 1;
      if (newCounter >= 11) {
        setHelpMode(false);
      } else {
        // Scroll logic for mobile view in helpMode, only after counter reaches 4
        if (
          (isXSScreen && helpMode && newCounter > 4) ||
          (!isXSScreen && helpMode && newCounter > 7)
        ) {
          const remInPixels = parseFloat(
            getComputedStyle(document.documentElement).fontSize
          );
          window.scrollBy({
            top: 30 * remInPixels, // Scroll by 30rem in pixels
            behavior: "smooth",
          });
        }
      }
      return newCounter;
    });
  };

  const handlePrevious = () => {
    setCounter((prev) => {
      const newCounter = Math.max(prev - 1, 1);

      // Scroll logic for mobile view in helpMode, only if counter was above 4
      if (helpMode && prev > 4) {
        const remInPixels = parseFloat(
          getComputedStyle(document.documentElement).fontSize
        );
        window.scrollBy({
          top: -30 * remInPixels, // Scroll upwards by 30rem in pixels
          behavior: "smooth",
        });
      }

      return newCounter;
    });
  };

  // const downloadOptions = [
  //   () =>
  //     downloadTrends(
  //       positiveAmountTrendData,
  //       negativeAmountTrendData,
  //       positiveQuantityTrendData,
  //       negativeQuantityTrendData,
  //       isSubCategoryVisible ? [] : superGroupProductMixData,
  //       // isSubCategoryVisible ? groupProductMixData : [],
  //       selectedRestaurant
  //     ),
  //   // Add other function calls as necessary
  // ];
  const handlePredictionData = () => {
    setHasPredictionData((prev) => !prev);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };
  const handleUnitCurrency = () => {
    setIsCurrency((prev) => !prev);
  };

  // const handleOpenSettingsDialog = () => {
  //   setOpenDialog(true);
  // };
  // const handleToggleSubCat = () => {
  //   setIsSubCategoryVisible((prevState) => {
  //     if (!prevState && hasProductChanged) {
  //       // If we are toggling to sub-products and the product has changed,
  //       // clear the sub-product chart data
  //       setSubProductChartData([]);
  //       // setSelectedSubgroup([]);
  //       setHasProductChanged(false);
  //     }
  //     return !prevState;
  //   });
  // };

  const handleToggleComparisonSubCat = () => {
    setIsComparisonSubCategoryVisible((prevState) => {
      if (!prevState && hasProductChanged) {
        // If we are toggling to sub-products and the product has changed,
        // clear the sub-product chart data
        setComparisonSubProductChartData([]);
        // setSelectedComparisonSubgroup([]);
        setHasProductChanged(false);
        // console.log(highestSellerByQuantity, highestSellerByValue);
      }
      return !prevState;
    });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRestaurant(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const difference = firstProductSum - secondProductSum;
  console.log(difference);

  // const updateChartDataForSelectedSupergroup = useCallback(
  //   (supergroup, productData) => {
  //     setFilteredChartData([]);
  //     const dataForChart = productData.find((item) => item.id === supergroup);
  //     setFilteredChartData(dataForChart ? [dataForChart] : []);
  //   },

  //   []
  // );

  const getCorrespondingDates = (date1, date2 = null) => {
    const getISOWeek = (date) => {
      const tempDate = new Date(date.getTime());
      tempDate.setHours(0, 0, 0, 0);
      // Thursday in current week decides the year.
      tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
      // January 4 is always in week 1.
      const week1 = new Date(tempDate.getFullYear(), 0, 4);
      // Adjust to Thursday in week 1 and count number of weeks from date to week1.
      return (
        1 +
        Math.round(
          ((tempDate.getTime() - week1.getTime()) / 86400000 -
            3 +
            ((week1.getDay() + 6) % 7)) /
            7
        )
      );
    };

    const getCorrespondingDate = (date) => {
      const weekNumber = getISOWeek(date);
      const dayOfWeek = (date.getDay() + 6) % 7; // Adjust for Monday-based week

      const previousYearStart = new Date(date.getFullYear() - 1, 0, 1);
      const daysToNextMonday = (7 - previousYearStart.getDay() + 1) % 7;
      const correspondingDate = new Date(
        previousYearStart.getFullYear(),
        0,
        1 + daysToNextMonday + (weekNumber - 1) * 7 + dayOfWeek
      );

      const formattedDate = `${correspondingDate.getFullYear()}-${(
        correspondingDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${correspondingDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      return formattedDate;
    };

    // Check if two dates are provided or just one
    if (date2) {
      const start = new Date(date1);
      const end = new Date(date2);
      return [getCorrespondingDate(start), getCorrespondingDate(end)];
    } else {
      return getCorrespondingDate(new Date(date1));
    }
  };

  //comparison functions
  const updateComparisonChartDataForSelectedSupergroup = (
    supergroup,
    productData
  ) => {
    // Filter the data for the selected supergroup
    const dataForChart = productData?.filter(
      (item) => item.id === supergroup || item.id === `${supergroup} `
    );

    // Initialize sums
    let firstProductSum = 0;
    let secondProductSum = 0;

    // Calculate sums for each dataset
    dataForChart.forEach((dataset, index) => {
      const sum = dataset.data?.reduce((acc, item) => acc + Number(item.y), 0);
      if (index === 0) {
        firstProductSum = sum;
      } else if (index === 1) {
        secondProductSum = sum;
      }
    });

    // Calculate the total sum of both products

    // Store the sums in state variables
    // Assuming you have a way to set these state variables, e.g., setFirstProductSum, setSecondProductSum, setProductSumKPI
    setFirstProductSum(firstProductSum);
    setSecondProductSum(secondProductSum);
    const calculatePercentageDifference = (firstSum, secondSum) => {
      if (secondSum === 0) return "No Data for Corresponding year";
      else if (firstSum === 0) return "No Data for Current year";
      return parseInt((Math.abs(firstSum - secondSum) / secondSum) * 100);
    };

    setProductPercentageDifference(
      calculatePercentageDifference(firstProductSum, secondProductSum)
    );

    // Set the filtered chart data
    setComparisonFilteredChartData(dataForChart ? dataForChart : []);
    // console.log(dataForChart, "data ayo");
  };

  const updateComparisonChartDataForSubgroup = (subgroup, productData) => {
    // Convert subgroup to string and trim
    const subgroupStr = String(subgroup).trim();

    const dataForChart = productData?.filter(
      (item) => String(item.id).trim() === subgroupStr
    );

    // Initialize sums
    let firstSubProductSum = 0;
    let secondSubProductSum = 0;

    // Calculate sums for each dataset
    dataForChart.forEach((dataset, index) => {
      const sum = dataset.data?.reduce((acc, item) => acc + Number(item.y), 0);
      if (index === 0) {
        firstSubProductSum = sum;
      } else if (index === 1) {
        secondSubProductSum = sum;
      }
    });
    // Calculate the total sum

    // Function to calculate percentage difference
    const calculatePercentageDifference = (firstSum, secondSum) => {
      if (secondSum === 0) return "No Data for Corresponding year";
      else if (firstSum === 0) return "No Data for Current year";
      return parseInt((Math.abs(firstSum - secondSum) / secondSum) * 100);
    };

    // Update the state variables
    setFirstSubProductSum(firstSubProductSum);
    setSecondSubProductSum(secondSubProductSum);
    setSubProductPercentageDifference(
      calculatePercentageDifference(firstSubProductSum, secondSubProductSum)
    );

    setComparisonSubProductChartData(
      dataForChart.length > 0 ? dataForChart : []
    );
  };

  // const compareTrendValues = (todaysSaleAmount, averageAmountSold) => {
  //   if (
  //     todaysSaleAmount === null ||
  //     averageAmountSold === null ||
  //     isNaN(todaysSaleAmount) ||
  //     isNaN(averageAmountSold)
  //   ) {
  //     return null;
  //   }

  //   const netProductTrend = todaysSaleAmount - averageAmountSold;
  //   const percentageChange =
  //     averageAmountSold !== 0 ? (netProductTrend / averageAmountSold) * 100 : 0;

  //   // Conditionally render the arrow only when percentageChange is not zero
  //   const arrowIcon =
  //     percentageChange !== 0 ? (
  //       netProductTrend >= 0 ? (
  //         <KeyboardDoubleArrowUpIcon sx={{ color: "green" }} />
  //       ) : (
  //         <KeyboardDoubleArrowDownIcon sx={{ color: "red" }} />
  //       )
  //     ) : null;

  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         // border: "solid 1px red",
  //         width: "100%",
  //       }}
  //     >
  //       <span>{percentageChange.toFixed(1)}%</span>
  //       {arrowIcon}
  //     </Box>
  //   );
  // };
  const calculatePercentageChange = (todaysSaleAmount, averageAmountSold) => {
    if (
      todaysSaleAmount === null ||
      averageAmountSold === null ||
      isNaN(todaysSaleAmount) ||
      isNaN(averageAmountSold) ||
      averageAmountSold === 0
    ) {
      return 0; // Return 0 or some other value indicating no change or invalid calculation
    }

    const netProductTrend = todaysSaleAmount - averageAmountSold;
    return (netProductTrend / averageAmountSold) * 100;
  };

  // Example: Assuming positiveAmountTrendData is your data array
  positiveAmountTrendData.forEach((row) => {
    // Calculate percentage change for each row
    row.percentageDifference = calculatePercentageChange(
      row.sale_amount,
      row.average_amount_sold
    );
  });

  // Sort the data based on the calculated percentage difference
  positiveAmountTrendData.sort(
    (a, b) => b.percentageDifference - a.percentageDifference
  );

  negativeAmountTrendData.forEach((row) => {
    // Calculate percentage change for each row
    row.percentageDifference = calculatePercentageChange(
      row.sale_amount,
      row.average_amount_sold
    );
  });

  // Sort the data based on the calculated percentage difference
  negativeAmountTrendData.sort(
    (a, b) => a.percentageDifference - b.percentageDifference
  );

  const handleDropdownChange = (event) => {
    // const selectedGroup = ;
    setSelectedSupergroup(event.target.value);
    // updateChartDataForSelectedSupergroup(e.target.value, productMixData);
  };
  const handleThirdPartyDropdownChange = (event) => {
    // const selectedGroup = ;
    setSelectedThirdPartySupergroup(event.target.value);
    // updateChartDataForSelectedSupergroup(e.target.value, productMixData);
  };

  const handleComparisonDropdownChange = (event) => {
    // console.log(selectedComparisonSupergroup, "group ayo");
    setSelectedComparisonSupergroup(event.target.value);
    // updateChartDataForSelectedSupergroup(event.target.value, productMixData);
  };

  // const updateChartDataForSubgroup = useCallback((supergroups, productData) => {
  //   // Filter the productData to include only those items whose id is in the supergroups array
  //   const dataForChart = productData?.filter((item) =>
  //     supergroups.includes(item.id)
  //   );
  //   // This array will contain multiple objects if multiple supergroups are selected.
  //   setSubProductChartData(dataForChart.length > 0 ? dataForChart : []);
  // }, []);

  // const handleSubProduct = (event) => {
  //   const value = event.target.value;
  //   let newSelectedSubGroup;
  //   if (typeof value === "string") {
  //     newSelectedSubGroup = [value];
  //   } else {
  //     newSelectedSubGroup = value;
  //   }
  //   setSelectedSubgroup(newSelectedSubGroup);
  //   // updateChartDataForSubgroup(newSelectedSubGroup, subproductMixData);
  // };

  const handleComparisonSubProduct = (event) => {
    const value = event.target.value;
    let newSelectedSubGroup;
    if (typeof value === "string") {
      newSelectedSubGroup = [value];
    } else {
      newSelectedSubGroup = value;
    }
    setSelectedComparisonSubgroup(newSelectedSubGroup);
  };

  const handlePageChange = (newValue) => {
    if (newValue === "company" && !isAdmin) {
      newValue = "multiple";
    }
    setSelectedPage(newValue); // Update the state with the new value

    // if (!newValue) return; // If no button is selected, do nothing

    if (newValue === "multiple") {
    } else if (newValue === "company") {
      setSelectedRestaurant([]);
    }
  };

  const fetchRestaurants = useCallback(async () => {
    // Replace the URL with the appropriate API endpoint for fetching a single task
    // setLoad(true);

    const restro_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();

      const userDefaultRestaurants = JSON.parse(
        localStorage.getItem("user")
      )?.default_restaurants;

      let filteredRestaurants = restro_data.results?.filter(
        (restaurant) =>
          userDefaultRestaurants.includes(restaurant.id) &&
          restaurant.name !== "Fisketorget Stavanger"
      );
      if (userDefaultRestaurants && userDefaultRestaurants.length > 0) {
        // Filter restaurants based on default_restaurants IDs
        filteredRestaurants = restro_data.results?.filter((restaurant) =>
          userDefaultRestaurants.includes(restaurant.id)
        );
      }
      if (!userDefaultRestaurants || userDefaultRestaurants.length === 0) {
        filteredRestaurants = restro_data.results?.filter(
          (restaurant) => restaurant.name !== "Fisketorget Stavanger"
        );
      }

      // Apply sorting to move 'Food Truck' to the bottom of the list
      filteredRestaurants.sort((a, b) => {
        const isAFoodTruck = a.name.includes("Food Truck");
        const isBFoodTruck = b.name.includes("Food Truck");

        if (isAFoodTruck && !isBFoodTruck) {
          return 1; // Place 'a' after 'b'
        } else if (!isAFoodTruck && isBFoodTruck) {
          return -1; // Place 'a' before 'b'
        }
        return 0; // Keep original order for other restaurants
      });

      setDefaultRestaurants(filteredRestaurants);
    }
  }, [company]);

  const adjustDates = (date, dataRange, direction) => {
    let newDate = new Date(date);
    const adjustDay = direction === "forward" ? 1 : -1;
    const adjustWeek = direction === "forward" ? 7 : -7;
    const adjustMonth = direction === "forward" ? 1 : -1;

    switch (dataRange) {
      case "today":
        newDate.setDate(newDate.getDate() + adjustDay);
        break;
      case "thisweek":
        newDate.setDate(newDate.getDate() + adjustWeek);
        break;
      case "thismonth":
        newDate.setMonth(newDate.getMonth() + adjustMonth);
        break;
      default:
        break;
    }

    return newDate.toISOString().split("T")[0];
  };

  const handleBackwardClick = () => {
    setBarChartData([]);
    setProductMixData([]);
    setComparisonProductMixData([]);
    setComparisonArticleSupergroups([]);
    setComparisonArticleSubgroups([]);
    setComparisonSubProductMixData([]);
    setComparisonSubProductChartData([]);
    setPositiveAmountTrendData([]);
    setNegativeAmountTrendData([]);
    setPositiveQuantityTrendData([]);
    setNegativeQuantityTrendData([]);
    setDeliveryCost([]);
    const newStartDate = adjustDates(startDate, dataRange, "backward");
    setStartDate(newStartDate);

    if (dataRange === "thismonth") {
      // Set endDate to the last day of the new month
      const monthEndDate = new Date(newStartDate);
      monthEndDate.setMonth(monthEndDate.getMonth() + 1, 0); // Last day of the month
      setEndDate(monthEndDate.toISOString().split("T")[0]);
    } else {
      setEndDate(adjustDates(endDate, dataRange, "backward"));
    }
  };

  const handleForwardClick = () => {
    setBarChartData([]);
    setProductMixData([]);
    setComparisonProductMixData([]);
    setComparisonArticleSupergroups([]);
    setComparisonArticleSubgroups([]);
    setComparisonSubProductMixData([]);
    setComparisonSubProductChartData([]);
    setPositiveAmountTrendData([]);
    setNegativeAmountTrendData([]);
    setPositiveQuantityTrendData([]);
    setNegativeQuantityTrendData([]);
    setDeliveryCost([]);
    const newStartDate = adjustDates(startDate, dataRange, "forward");
    setStartDate(newStartDate);

    if (dataRange === "thismonth") {
      // Set endDate to the last day of the new month
      const monthEndDate = new Date(newStartDate);
      monthEndDate.setMonth(monthEndDate.getMonth() + 1, 0); // Last day of the month
      setEndDate(monthEndDate.toISOString().split("T")[0]);
    } else {
      setEndDate(adjustDates(endDate, dataRange, "forward"));
    }
  };
  const buildFetchUrl = (baseURL, options) => {
    let {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      // hasPredictionData,
      newValue,
      selectedSupergroup,
    } = options;

    let startingDate = startDate;
    let endingDate = endDate;

    // Formatting startDate and endDate
    let formattedStartDate = startingDate;
    let formattedEndDate = endingDate;

    if (
      (formattedEndDate && !formattedStartDate) ||
      (formattedStartDate && !formattedEndDate)
    ) {
      return;
    }
    if (formattedStartDate > formattedEndDate) {
      setOpen(true);
      setSeverity("error");
      setMessage("Start date cannot be further than end date");
      return;
    }

    // Constructing URL with query parameters
    let url = baseURL;
    let isFirstQueryParam = true;

    // Helper function to append parameters
    const appendParam = (key, value) => {
      if (isFirstQueryParam) {
        url += `?${key}=${encodeURIComponent(value)}`;
        isFirstQueryParam = false;
      } else {
        url += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // Append company if present
    if (company) {
      appendParam("company", company);
    }

    // Append start_date and end_date if present
    if (formattedStartDate && formattedEndDate) {
      appendParam("start_date", startDate);
      appendParam("end_date", endDate);
    }

    // Append restaurantNames if present
    if (selectedRestaurant.length > 0) {
      appendParam("restaurants", JSON.stringify(selectedRestaurant));
    }

    // Append newValue if present
    if (newValue) {
      appendParam("period", newValue);
    }
    if (selectedSupergroup && selectedSupergroup !== "All") {
      appendParam("sub_product", selectedSupergroup);
    }

    // Log the formatted URL
    // console.log(url, "format url in function");

    // Return the URL as a string
    return url;
  };

  // const getProductMix = useCallback(async () => {
  //   if (
  //     (!selectedRestaurant || selectedRestaurant.length === 0) &&
  //     selectedPage === "multiple"
  //   ) {
  //     return;
  //   }

  //   let url = `${prefix}/api/salesdata_hourly/product_mix`;

  //   // Determine the date range
  //   let newValue = dataRange === "today" ? "hour" : "day";

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `Token ${token}`,
  //   };

  //   // Format the start and end dates
  //   let formattedStartDate = startDate
  //     ? new Date(startDate).toISOString().split("T")[0]
  //     : null;
  //   let formattedEndDate = endDate
  //     ? new Date(endDate).toISOString().split("T")[0]
  //     : null;

  //   // URL parameter checks
  //   if (company) {
  //     url += `?company=${company}`;
  //   }
  //   if (formattedStartDate && formattedEndDate) {
  //     url += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
  //   }
  //   if (selectedRestaurant.length > 0) {
  //     url += `&restaurants=${JSON.stringify(selectedRestaurant)}`;
  //   }
  //   if (newValue) {
  //     url += `&period=${newValue}`;
  //   }
  //   // url += "&chartType=line";
  //   abortControllerRefForProductMix.current.abort();
  //   abortControllerRefForProductMix.current = new AbortController();

  //   setProdLineLoad(true);
  //   setProductMixData([]);
  //   // setArticleSupergroups([]);

  //   const transformDataForChart = (data) => {
  //     const chartData = {};
  //     const articleSupers = new Set();

  //     Object.entries(data).forEach(([date, groups]) => {
  //       Object.entries(groups).forEach(([group, values]) => {
  //         if (!chartData[group]) {
  //           chartData[group] = { id: group, data: [] };
  //         }

  //         let valueToShow;
  //         if (isCurrency) {
  //           // Plot total_net when isCurrency toggle is active
  //           valueToShow = values.total_net.toFixed(0);
  //         } else {
  //           // Plot quantity_sold when isCurrency is not active
  //           valueToShow = values.quantity_sold.toFixed(0);
  //         }

  //         // if (isCurrency) {
  //         //   // Display total_net when isCurrency toggle is active
  //         //   valueToShow = values.total_net.toFixed(0);
  //         // } else {
  //         //   // Calculate and display percentages
  //         //   const totalNet = Object.values(groups)?.reduce(
  //         //     (total, group) => total + group.total_net,
  //         //     0
  //         //   );
  //         //   valueToShow = ((values.total_net / totalNet) * 100).toFixed(1);
  //         // }

  //         chartData[group].data.push({ x: date, y: valueToShow });
  //         articleSupers.add(group);
  //       });
  //     });

  //     return {
  //       chartData: Object.values(chartData),
  //       articleSupers: ["All", ...Array.from(articleSupers)],
  //     };
  //   };

  //   fetch(url, {
  //     headers: headers,
  //     signal: abortControllerRefForProductMix.current.signal,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.message) {
  //         let resultsToUse = hasPredictionData
  //           ? data.message.prediction
  //           : data.message.historical;

  //         if (resultsToUse.length <= 0) {
  //           setComparisonArticleSupergroups([]);

  //           return;
  //         }
  //         const { chartData, articleSupers } =
  //           transformDataForChart(resultsToUse);
  //         setProductMixData(chartData);

  //         setArticleSupergroups(articleSupers);
  //         // if (articleSupers.length < 0) {
  //         //   setSelectedSupergroup([]);
  //         // }
  //       } else {
  //         setArticleSupergroups([]);
  //       }
  //       setProdLineLoad(false);
  //     })
  //     .catch((error) => {
  //       if (error.name !== "AbortError") {
  //         console.error(error);
  //         setProdLineLoad(false);
  //       }
  //     });
  // }, [
  //   dataRange,
  //   startDate,
  //   endDate,
  //   selectedRestaurant,
  //   hasPredictionData,
  //   selectedPage,
  //   isCurrency,
  //   company,
  //   // selectedSupergroup,
  //   // updateChartDataForSelectedSupergroup,
  // ]);

  const getDeliveryCost = useCallback(() => {
    // let newValue = range;
    let newValue = dataRange === "today" ? "hour" : "day";

    // const urlOptions = {
    //   startDate,
    //   endDate,
    //   company,
    //   selectedRestaurant,
    //   dataRange,
    //   range,
    //   selectedThirdPartySupergroup,
    // };
    let url = `${prefix}/api/salesdata_hourly/take_out_cost`;

    // const Url = buildFetchUrl(
    //   `${prefix}/api/salesdata_hourly/take_out_cost/`,
    //   urlOptions
    // );

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    setDeliveryCostLoad(true);
    let formattedStartDate = startDate
      ? new Date(startDate).toISOString().split("T")[0]
      : null;
    let formattedEndDate = endDate
      ? new Date(endDate).toISOString().split("T")[0]
      : null;

    // URL parameter checks
    if (company) {
      url += `?company=${company}`;
    }
    if (
      selectedThirdPartySupergroup &&
      selectedThirdPartySupergroup !== "All"
    ) {
      url += `&sub_product=${selectedThirdPartySupergroup}`;
    }
    if (formattedStartDate && formattedEndDate) {
      url += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
    }
    if (selectedRestaurant.length > 0) {
      url += `&restaurants=${JSON.stringify(selectedRestaurant)}`;
    }
    if (newValue) {
      url += `&period=${newValue}`;
    }

    fetch(url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.results) {
          // Transform data for the bar chart
          const deliveryBarChartData = data.results.map((entry) => {
            const { take_out_fee, date } = entry;
            // let barLabel = restaurant; // Use restaurant as the label
            // ... your existing logic for date_period calculations
            // Replace the following line with your date_period calculation logic
            let date_period;
            if (newValue === "week") {
              const [year, week] = date.split(" ");
              const firstJan = new Date(Date.UTC(year, 0, 1)); // January 1st of the year
              const firstThursday = new Date(firstJan);
              firstThursday.setDate(
                firstJan.getDate() + (3 - ((firstJan.getUTCDay() + 6) % 7))
              ); // Find the first Thursday of the year
              const weekOffset = (week - 1) * 7; // Calculate the number of days to add for the week
              date_period = new Date(firstThursday);
              date_period.setUTCDate(firstThursday.getUTCDate() + weekOffset); // Add the offset to get the desired week
            } else if (newValue === "month") {
              const [year, month] = date.split(" ");
              date_period = new Date(year, parseInt(month), 23); // Set to the last day of the given month
            } else {
              date_period = new Date(date);
            }
            return {
              // barLabel, // This will be used in the 'indexBy' prop in the Nivo bar chart
              date: date_period.toISOString().slice(0, 10), // Format as a string 'YYYY-MM-DD'
              take_out_fee: parseFloat(take_out_fee),
              Color: getRandomColor(),
            };
          });
          // console.log(deliveryBarChartData, "rurupi");
          setDeliveryCost(deliveryBarChartData);
        } else {
          setDeliveryCost([]);
        }
        setDeliveryCostLoad(false);
      })
      .catch((error) => {
        console.error("Error fetching profit data:", error);
        setDeliveryCostLoad(false);
      });
  }, [
    startDate,
    endDate,
    selectedRestaurant,
    // range,
    company,
    dataRange,
    selectedThirdPartySupergroup,
  ]);

  const getTrends = useCallback(async () => {
    if (
      (!selectedRestaurant || selectedRestaurant.length === 0) &&
      selectedPage === "multiple"
    ) {
      return;
    }

    let url = `${prefix}/api/salesdata_hourly/product_trend`;

    // Determine the date range
    let newValue =
      dataRange === "today"
        ? "day"
        : dataRange === "thisweek"
        ? "week"
        : "month";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    // Format the start and end dates
    let formattedStartDate = startDate
      ? new Date(startDate).toISOString().split("T")[0]
      : null;
    let formattedEndDate = endDate
      ? new Date(endDate).toISOString().split("T")[0]
      : null;

    // const newStartDate = getCorrespondingDates(startDate);
    // const newEndDate = getCorrespondingDates(endDate);

    // URL parameter checks
    if (company) {
      url += `?company=${company}`;
    }
    if (formattedStartDate && formattedEndDate) {
      url += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
    }
    if (selectedRestaurant.length > 0) {
      url += `&restaurants=${JSON.stringify(selectedRestaurant)}`;
    }
    if (newValue) {
      url += `&period=${newValue}`;
    }
    if (selectedSupergroup && selectedSupergroup !== "All") {
      url += `&sub_product=${selectedSupergroup}`;
    }

    //add the toggle logic to the if block and only send the dates below when comparing against last year
    if (trendsToggle) {
      const newStartDate = getCorrespondingDates(startDate);
      const newEndDate = getCorrespondingDates(endDate);
      if (newStartDate && newEndDate) {
        url += `&compare_start_date=${newStartDate}&compare_end_date=${newEndDate}`;
      }
    }

    setTrendsLoad(true);
    fetch(url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.results) {
          const negative_amount_data = data.results.negative_amount_trend;
          const positive_qty_data = data.results.positive_qty_trend;
          const negative_qty_data = data.results.negative_qty_trend;
          const positive_amount_data = data.results.positive_amount_trend;
          setPositiveAmountTrendData(positive_amount_data);
          setNegativeAmountTrendData(negative_amount_data);
          setPositiveQuantityTrendData(positive_qty_data);
          setNegativeQuantityTrendData(negative_qty_data);
        } else {
          setPositiveAmountTrendData([]);
          setNegativeAmountTrendData([]);
          setPositiveQuantityTrendData([]);
          setNegativeQuantityTrendData([]);
          setTrendsLoad(false);
        }
        setTrendsLoad(false);
      })

      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error(error);
          setTrendsLoad(false);
        }
      });
  }, [
    dataRange,
    startDate,
    endDate,
    company,
    selectedRestaurant,
    selectedPage,
    trendsToggle,
    // selectedComparisonSupergroup,
    selectedSupergroup,
  ]);

  const getHighestSellingProduct = useCallback(() => {
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      selectedSupergroup,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    const Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/highest_selling_product`,
      urlOptions
    );
    setTopTenLoad(true);
    // setHighestSellerValue(true)
    // abortControllerRefForHighestSeller.current.abort();
    // abortControllerRefForHighestSeller.current = new AbortController();
    fetch(Url, {
      headers: headers,
      // signal: abortControllerRefForHighestSeller.current.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data, "highest seller data");
        if (data.message) {
          const highest_selling_product_amount = data.message.amount;
          const highest_selling_product_quantity = data.message.quantity;
          console.log(
            highest_selling_product_amount,
            highest_selling_product_quantity,
            "xd"
          );

          const topTenSellers = (
            isCurrency ? data.message.amount : data.message.quantity
          )
            .sort((a, b) => b.total_sales_value - a.total_sales_value)
            .slice(0, 10);

          // Now you can set this array in your state and use it to display the bar chart
          // setTopTenSellers(topTenSellers);
          const formatBarChartData = (topSellers) => {
            return topSellers.map((seller) => ({
              id: seller.article_group,
              value: seller.total_sales_value,
              Color: getRandomColor(),
            }));
          };

          const temp_bar = formatBarChartData(topTenSellers);
          setBarChartData(temp_bar);
        } else {
          setBarChartData([]);
          setTopTenLoad(false);
        }
        setTopTenLoad(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error(error);
          setTopTenLoad(false);

          setBarChartData([]);
        }
      });
  }, [
    startDate,
    company,
    endDate,
    selectedRestaurant,
    dataRange,
    selectedSupergroup,
    isCurrency,
  ]);

  const getComparisonProductMix = useCallback(async () => {
    if (
      (!selectedRestaurant || selectedRestaurant.length === 0) &&
      selectedPage === "multiple"
    ) {
      return;
    }

    const newStartDate = getCorrespondingDates(startDate);
    const newEndDate = getCorrespondingDates(endDate);

    const buildUrl = (isCorresponding = false) => {
      let url = `${prefix}/api/salesdata_hourly/product_mix`;

      if (company) {
        url += `?company=${company}`;
      }

      let startDateToUse = isCorresponding ? newStartDate : startDate;
      let endDateToUse = isCorresponding ? newEndDate : endDate;

      if (startDateToUse && endDateToUse) {
        url += `&start_date=${startDateToUse}&end_date=${endDateToUse}`;
      }

      if (selectedRestaurant.length > 0) {
        url += `&restaurants=${JSON.stringify(selectedRestaurant)}`;
      }
      if (dataRange === "today") {
        url += `&period=${"hour"}`;
      } else {
        url += `&period=${"day"}`;
      }

      return url;
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    abortControllerRefForCompProductMix.current.abort();
    abortControllerRefForCompProductMix.current = new AbortController();

    setProdComparisonLineLoad(true);
    // setComparisonArticleSupergroups([]);
    setComparisonProductMixData([]);

    const transformDataForChart = (data, second = false) => {
      const chartData = {};
      const articleSupers = new Set();

      Object.entries(data).forEach(([date, groups]) => {
        Object.entries(groups).forEach(([group, values]) => {
          let date_period = new Date(date);
          let actualDate = new Date(date); // Always preserve the actual date
          if (second) {
            const startYear = new Date(startDate).getFullYear();
            date_period.setFullYear(startYear);
            // Calculate the difference in days of the week and adjust the date
            let dayDifference = actualDate.getDay() - date_period.getDay();
            date_period.setDate(date_period.getDate() + dayDifference);
          }
          if (second) {
            const startYear = new Date(startDate).getFullYear();
            date_period.setFullYear(startYear);
          }
          // Convert date_period to a weekday name if dataRange is 'thisweek'
          if (dataRange === "thisweek") {
            date_period = date_period.toLocaleDateString("en-US", {
              weekday: "long",
            });
          } else if (dataRange === "today") {
            const newDate = dayjs(date);
            // Include the day of the month in the formatted string
            const formattedDate = newDate.format("HH:mm");
            date_period = formattedDate;
          } else if (dataRange === "thismonth") {
            let newDate = dayjs(date);

            if (second) {
              // Initialize subtraction value
              let a = 1;

              // Convert the entry date, start date, and end date from strings or JavaScript Date to dayjs objects
              const start_date = dayjs(startDate);
              const end_date = dayjs(endDate);

              // Extract month and day from the date
              const monthDay = newDate.format("MM-DD");

              // Check if the date is during February (from "02-01" to "02-29")
              if (monthDay >= "02-01" && monthDay <= "02-29") {
                // The date is within February, so keep a as 1
                a = 1;
              } else if (monthDay < "02-01") {
                // The date is before February, change a to 1
                a = 1;
              } else {
                // The date is after February, change a to 2
                a = 2;
              }

              // Additional check if both start_date and end_date are within the year 2022
              if (start_date.year() === 2023 && end_date.year() === 2023) {
                a = 1;
              }

              // Subtract days based on the value of a
              newDate = newDate.subtract(a, "day");
              newDate = newDate.add(1, "year");
            }

            const formattedDate = newDate.format("YYYY-MM-DD"); // Format to retain only month and day
            date_period = formattedDate;
          }
          if (!chartData[group] && second === true) {
            chartData[group] = { id: `${group} `, data: [] };
          } else if (!chartData[group]) {
            chartData[group] = { id: group, data: [] };
          }
          // console.log(
          //   `Original date: ${date}, Converted to weekday: ${date_period} corr`
          // ); // Debugging log
          let valueToShow;

          // if (isCurrency) {
          //   valueToShow = values.total_net.toFixed(0);
          // } else {
          //   const totalNet = Object.values(groups).reduce(
          //     (total, group) => total + group.total_net,
          //     0
          //   );
          //   valueToShow = ((values.total_net / totalNet) * 100).toFixed(1);
          // }

          if (isCurrency) {
            valueToShow = values.total_net.toFixed(0); // Show total_net if isCurrency is true
          } else {
            valueToShow = values.quantity_sold.toFixed(0); // Show quantity_sold if isCurrency is false
          }
          // valueToShow = values.total_net.toFixed(0);

          chartData[group].data.push({
            x: date_period,
            y: valueToShow,
            actualDate: actualDate,
          });
          articleSupers.add(group);
        });
      });
      return {
        chartData: Object.values(chartData),
        articleSupers: Array.from(articleSupers),
      };
    };

    try {
      // First fetch with normal start and end dates
      let url = buildUrl();
      const response = await fetch(url, {
        headers: headers,
        signal: abortControllerRefForCompProductMix.current.signal,
      });

      const data = await handleFetchResponse(response);
      if (!data.message) {
        setComparisonProductMixData([]);
        setComparisonArticleSupergroups([]);
        return;
      }
      const resultsToUse = hasPredictionData
        ? data.message.prediction
        : data.message.historical;
      const { chartData, articleSupers } = transformDataForChart(resultsToUse);
      // Second fetch with correspondingStartDate and correspondingEndDate
      let urlWithCorrespondingDates = buildUrl(true); // Fix: Pass true to indicate corresponding dates
      const correspondingResponse = await fetch(urlWithCorrespondingDates, {
        headers: headers,
        signal: abortControllerRefForCompProductMix.current.signal,
      });
      const correspondingData = await handleFetchResponse(
        correspondingResponse
      );
      const secondaryResultsToUse = hasPredictionData
        ? correspondingData.message.prediction
        : correspondingData.message.historical;
      const { chartData: secondChartData, articleSupers: secondArticleSupers } =
        transformDataForChart(secondaryResultsToUse, true);
      const combinedData = Array.from(
        new Set([...chartData, ...secondChartData])
      );
      const combinedArticleSupers = Array.from(
        new Set([
          ...Array.from(secondArticleSupers),
          ...Array.from(articleSupers),
        ])
      );
      const articleSuperGroup = ["All", ...Array.from(new Set(articleSupers))];
      setArticleSupergroups(articleSuperGroup);

      // Find the product with the highest total net value
      const productTotals = {};
      articleSupers.forEach((superGroup) => {
        chartData.forEach(({ id, data }) => {
          if (id === superGroup) {
            productTotals[superGroup] = data?.reduce(
              (acc, { y }) => acc + parseFloat(y),
              0
            );
          }
        });
      });

      const highestValueProduct = Object.entries(productTotals)?.reduce(
        (a, b) => (a[1] > b[1] ? a : b)
      )[0];
      setSelectedComparisonSupergroup(highestValueProduct);

      setComparisonArticleSupergroups(combinedArticleSupers);
      setComparisonProductMixData(combinedData);
    } catch (error) {
      handleFetchError(error);
      setComparisonProductMixData([]);
      setComparisonArticleSupergroups([]);
    } finally {
      setProdComparisonLineLoad(false);
    }

    function handleFetchResponse(response) {
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }
      return response.json();
    }

    function handleFetchError(error) {
      if (error.name !== "AbortError") {
        console.error(error);
        setProdComparisonLineLoad(false);
      }
    }
  }, [
    dataRange,
    company,
    startDate,
    endDate,
    selectedRestaurant,
    hasPredictionData,
    selectedPage,
    isCurrency,
  ]);

  const getComparisonSubProductMix = useCallback(async () => {
    if (
      (!selectedRestaurant || selectedRestaurant.length === 0) &&
      selectedPage === "multiple"
    ) {
      return;
    }

    if (
      !selectedComparisonSupergroup ||
      selectedComparisonSupergroup.length === 0
    ) {
      return;
    }

    const newStartDate = getCorrespondingDates(startDate);
    const newEndDate = getCorrespondingDates(endDate);

    const buildUrl = (isCorresponding = false) => {
      let url = `${prefix}/api/salesdata_hourly/sub_product_mix`;

      let superGroup = selectedComparisonSupergroup;

      if (company) {
        url += `?company=${company}`;
      }

      let startDateToUse = isCorresponding ? newStartDate : startDate;
      let endDateToUse = isCorresponding ? newEndDate : endDate;

      if (startDateToUse && endDateToUse) {
        url += `&start_date=${startDateToUse}&end_date=${endDateToUse}`;
      }

      if (selectedRestaurant.length > 0) {
        url += `&restaurants=${JSON.stringify(selectedRestaurant)}`;
      }
      if (superGroup) {
        url += `&sub_product=${superGroup}`;
      }
      if (dataRange === "today") {
        url += `&period=${"hour"}`;
      } else {
        url += `&period=${"day"}`;
      }

      return url;
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    setSubProdComparisonLineLoad(true);
    setComparisonArticleSubgroups([]);
    if (
      !selectedComparisonSupergroup ||
      selectedComparisonSupergroup.length === 0
    ) {
      return;
    }
    // setIsComparisonSubCategoryVisible(false);

    const transformDataForChart = (data, second = false) => {
      const chartData = {};
      const articleSubs = new Set();
      Object.entries(data).forEach(([date, groups]) => {
        Object.entries(groups).forEach(([group, values]) => {
          let date_period = new Date(date);
          let actualDate = new Date(date); // Always preserve the actual date
          if (second) {
            const startYear = new Date(startDate).getFullYear();
            date_period.setFullYear(startYear);
            // Calculate the difference in days of the week and adjust the date
            let dayDifference = actualDate.getDay() - date_period.getDay();
            date_period.setDate(date_period.getDate() + dayDifference);
          }
          if (second) {
            const startYear = new Date(startDate).getFullYear();
            date_period.setFullYear(startYear);
          }
          // Convert date_period to a weekday name if dataRange is 'thisweek'
          if (dataRange === "thisweek") {
            date_period = date_period.toLocaleDateString("en-US", {
              weekday: "long",
            });
          } else if (dataRange === "today") {
            const newDate = dayjs(date);
            const formattedDate = newDate.format("HH:mm");
            date_period = formattedDate;
          } else if (dataRange === "thismonth") {
            let newDate = dayjs(date);
            if (second) {
              // Initialize subtraction value
              let a = 1;

              // Convert the entry date, start date, and end date from strings or JavaScript Date to dayjs objects
              const start_date = dayjs(startDate);
              const end_date = dayjs(endDate);

              // Extract month and day from the date
              const monthDay = newDate.format("MM-DD");

              // Check if the date is during February (from "02-01" to "02-29")
              if (monthDay >= "02-01" && monthDay <= "02-29") {
                // The date is within February, so keep a as 1
                a = 1;
              } else if (monthDay < "02-01") {
                // The date is before February, change a to 1
                a = 1;
              } else {
                // The date is after February, change a to 2
                a = 2;
              }

              // Additional check if both start_date and end_date are within the year 2022
              if (start_date.year() === 2023 && end_date.year() === 2023) {
                a = 1;
              }

              // Subtract days based on the value of a
              newDate = newDate.subtract(a, "day");
              newDate = newDate.add(1, "year");
            }
            const formattedDate = newDate.format("YYYY-MM-DD"); // Format to retain only month and day
            date_period = formattedDate;
          }
          if (!chartData[group] && second === true) {
            chartData[group] = { id: `${group} `, data: [] };
          } else if (!chartData[group]) {
            chartData[group] = { id: group, data: [] };
          }
          // console.log(
          //   `Original date: ${date}, Converted to weekday: ${date_period} corr`
          // ); // Debugging log
          let valueToShow;

          if (isCurrency) {
            valueToShow = values.total_net.toFixed(0);
          } else {
            const totalNet = Object.values(groups)?.reduce(
              (total, group) => total + group.total_net,
              0
            );
            valueToShow = ((values.total_net / totalNet) * 100).toFixed(1);
          }

          chartData[group].data.push({
            x: date_period,
            y: valueToShow,
            actualDate: actualDate,
          });
          articleSubs.add(group);
        });
      });

      return { chartData, articleSubs };
    };

    try {
      // First fetch with normal start and end dates
      let url = buildUrl();
      const response = await fetch(url, { headers: headers });
      const data = await handleFetchResponse(response);

      if (!data.message) {
        setComparisonArticleSubgroups([]);
      }

      const resultsToUse = hasPredictionData
        ? data.message?.prediction
        : data.message?.historical;
      const { chartData, articleSubs } = transformDataForChart(resultsToUse);

      // Second fetch with correspondingStartDate and correspondingEndDate
      let urlWithCorrespondingDates = buildUrl(true); // Pass true to indicate corresponding dates
      const correspondingResponse = await fetch(urlWithCorrespondingDates, {
        headers: headers,
      });
      const correspondingData = await handleFetchResponse(
        correspondingResponse
      );
      let secondaryResultsToUse =
        hasPredictionData && correspondingData.message?.prediction?.length > 0
          ? correspondingData.message.prediction
          : correspondingData.message.historical;

      const { chartData: secondChartData, articleSubs: secondArticleSubs } =
        transformDataForChart(secondaryResultsToUse, true);

      const combineChartData = (chartData1, chartData2) => {
        let combined = { ...chartData1 }; // Spread into a new object

        // Merge the second chart data into the combined object
        for (const group in chartData2) {
          const groupWithSpace = `${group} `; // Add whitespace to the second dataset's group ID

          if (combined[groupWithSpace]) {
            combined[groupWithSpace].data = [
              ...combined[groupWithSpace].data,
              ...chartData2[group].data,
            ];
          } else {
            combined[groupWithSpace] = {
              id: groupWithSpace,
              data: chartData2[group].data,
            };
          }
        }

        // Transform the combined object into an array of objects
        return Object.keys(combined).map((key) => ({
          id: key,
          data: combined[key].data,
        }));
      };

      const combinedData = combineChartData(chartData, secondChartData);

      const combinedArticleSubs = Array.from(
        new Set([...Array.from(articleSubs), ...Array.from(secondArticleSubs)])
      );

      // Check if combinedArticleSubs is empty and set to null if true
      if (combinedArticleSubs.length === 0) {
        setComparisonArticleSubgroups([]);
      } else {
        setComparisonArticleSubgroups(combinedArticleSubs);
        // setSelectedComparisonSubgroup(combinedArticleSubs[0]);
      }

      setComparisonSubProductMixData(combinedData);
    } catch (error) {
      handleFetchError(error);
      setComparisonSubProductMixData([]);
    } finally {
      setSubProdComparisonLineLoad(false);
    }

    function handleFetchResponse(response) {
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }
      return response.json();
    }

    function handleFetchError(error) {
      if (error.name !== "AbortError") {
        console.error(error);
        setSubProdComparisonLineLoad(false);
      }
    }
  }, [
    dataRange,
    startDate,
    endDate,
    selectedRestaurant,
    hasPredictionData,
    isCurrency,
    selectedPage,
    selectedComparisonSupergroup,
    company,
  ]);

  useEffect(() => {
    if (Array.isArray(selectedRestaurant) && selectedRestaurant.length > 1) {
      const firstRestaurant = selectedRestaurant[0];
      setSelectedRestaurant(firstRestaurant);
    }
  }, [selectedRestaurant, setSelectedRestaurant, company]);

  // useEffect(() => {
  //   getHighestSellingProduct();
  // }, [startDate, endDate, getHighestSellingProduct]);

  // useEffect(() => {
  //   setSelectedSubgroup([]); // Reset subproduct selection
  //   // Optionally, reset other related states as needed
  // }, [selectedComparisonSupergroup]);

  useEffect(() => {
    if (
      comparisonArticleSupergroups.length !== 0 &&
      !selectedComparisonSupergroup
    ) {
      setSelectedComparisonSupergroup(comparisonArticleSupergroups[0]);
    }
  }, [
    comparisonArticleSupergroups,
    setSelectedComparisonSupergroup,
    selectedComparisonSupergroup,
  ]);

  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);

  // useEffect(() => {
  //   if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
  //     setProductMixData([]);
  //     setArticleSupergroups([]);
  //   } else {
  //     getProductMix();
  //   }
  // }, [getProductMix, selectedPage, selectedRestaurant]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setComparisonProductMixData([]);
      setComparisonArticleSupergroups([]);
    } else {
      getComparisonProductMix();
    }
  }, [getComparisonProductMix, selectedPage, selectedRestaurant]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setComparisonSubProductChartData([]);
    } else {
      getComparisonSubProductMix();
    }
  }, [getComparisonSubProductMix, selectedPage, selectedRestaurant]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setBarChartData([]);
    } else {
      getHighestSellingProduct();
    }
  }, [getHighestSellingProduct, selectedPage, selectedRestaurant]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setPositiveAmountTrendData([]);
      setNegativeAmountTrendData([]);
      setPositiveQuantityTrendData([]);
      setNegativeQuantityTrendData([]);
    } else {
      getTrends();
    }
  }, [getTrends, selectedPage, selectedRestaurant]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setDeliveryCost([]);
    } else {
      const timer = setTimeout(() => {
        getDeliveryCost();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [getDeliveryCost, selectedPage, selectedRestaurant]);

  useEffect(() => {
    if (
      (comparisonArticleSubgroups.length > 0 && !prodComparisonLineLoad) ||
      subProdComparisonLineLoad
    ) {
      setIsComparisonSubCategoryVisible(false);
    }
  }, [
    comparisonArticleSubgroups,
    prodComparisonLineLoad,
    subProdComparisonLineLoad,
  ]);

  // useEffect(() => {
  //   if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
  //     setDeliveryCost([]);
  //     setPositiveAmountTrendData([]);
  //     setNegativeAmountTrendData([]);
  //     setPositiveQuantityTrendData([]);
  //     setNegativeQuantityTrendData([]);
  //     setBarChartData([]);
  //     setSubProductChartData([]);
  //     setComparisonSubProductChartData([]);
  //     setProductMixData([]);
  //     setComparisonProductMixData([]);
  //     // setFilteredChartData([]);
  //     setComparisonFilteredChartData([]);
  //   } else {
  //     const timer = setTimeout(() => {
  //       getDeliveryCost();
  //       getTrends();
  //       getHighestSellingProduct();
  //       getComparisonProductMix();
  //       getProductMix();
  //       getComparisonSubProductMix();
  //     }, 500);
  //     return () => clearTimeout(timer);
  //   }
  // }, [
  //   selectedPage,
  //   selectedRestaurant,
  //   getDeliveryCost,
  //   getTrends,
  //   getHighestSellingProduct,
  //   getComparisonProductMix,
  //   getComparisonSubProductMix,
  //   getProductMix,
  // ]);

  useEffect(() => {
    updateComparisonChartDataForSelectedSupergroup(
      selectedComparisonSupergroup,
      comparisonProductMixData
    );
  }, [selectedComparisonSupergroup, comparisonProductMixData, dataRange]);

  useEffect(() => {
    updateComparisonChartDataForSubgroup(
      selectedComparisonSubgroup,
      comparisonSubProductMixData
    );
  }, [selectedComparisonSubgroup, comparisonSubProductMixData, dataRange]);

  //abort controllers

  useEffect(() => {
    return () => {
      // Abort any ongoing fetches for Article Data
      if (abortControllerRefForCompProductMix.current) {
        abortControllerRefForCompProductMix.current.abort();
      }
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        // border: "solid 1px red",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: { lg: 1300, xl: 1600 },
          alignItems: "center",
          // outline: "solid 1px blue",
          boxSizing: "border-box",
          height: {
            lg: company === "Fisketorget" ? "160vh" : "250vh", // Conditional height for 'lg' breakpoint
            xs: company === "Fisketorget" ? "1" : "1", // Static height for 'xs' breakpoint, adjust as needed
          }, // height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            // border: "solid 1px green",
            height: "100%",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: { lg: 4, xs: 1 },
              // border: "solid 1px red",
              justifyContent: { lg: "space-evenly", xl: "space-evenly" },
              height: { lg: "10vh", xs: "40vh" },
              alignItems: "center",
              mt: { lg: 2, xs: 0 },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 1}
            >
              <Box
                sx={{
                  ...(counter === 1 && helpMode ? getHighlightedStyle() : {}),
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  alignItems: "center",
                  mt: { lg: 0, xs: 1 },
                  gap: { lg: 0, xs: 1 },
                  // border: "solid 1px red ",
                }}
              >
                {/* {selectedPage === "multiple" ? ( */}
                <FormControl sx={{ width: 200, m: 0 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    {selectedPage === "company" ? company : "All Restaurants"}
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    single
                    value={selectedPage === "company" ? [] : selectedRestaurant}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        label={
                          selectedPage === "company"
                            ? company
                            : "All Restaurants"
                        }
                      />
                    }
                    // renderValue={(selected) => selected.join(", ")}
                    renderValue={(selected) =>
                      Array.isArray(selected)
                        ? selected
                            .map((name) => getRestaurantNameById(name))
                            .join(", ")
                        : getRestaurantNameById(selected)
                    }
                    MenuProps={MenuProps}
                    disabled={selectedPage === "company"}
                    sx={{
                      "& .MuiSelect-select": {
                        // height: '3rem',
                        padding: "0",
                        textIndent: "0.25em",
                        textAlign: "center",
                      },
                      // border: "solid red 1px",
                      background: "#F8F8F8",
                      mx: ".5rem",
                      height: "3rem",
                      color: "black",
                      boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    }}
                  >
                    {defaultRestaurants.map((restaurant) => (
                      <MenuItem key={restaurant.id} value={restaurant.name}>
                        {restaurant.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <ToggleButtonGroup
                    value={selectedPage}
                    exclusive
                    disabled={!isAdmin}
                    onChange={(event) => {
                      handlePageChange(event.target.value);
                      // setSelectedPage([]);
                    }}
                    sx={{
                      "& .MuiToggleButton-root": {
                        // height: "3rem",
                        background: "#F8F8F8",
                        "&.Mui-selected": {
                          background: "#D6D6D6", // Darker shade for the selected button
                        },
                      },
                      // mx: ".5rem",
                    }}
                    aria-label="Range"
                  >
                    <ToggleButton value="company" aria-label="company">
                      Company
                    </ToggleButton>
                    <ToggleButton value="multiple" aria-label="multiple">
                      Restaurants
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 2}
            >
              <Box
                sx={{
                  ...(counter === 2 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  mt: { lg: 3 },
                  // border: "solid 1px red",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "solid 1px red",
                    height: "100%",
                    width: { xs: "80%", lg: "100%" },
                  }}
                >
                  <ArrowBackIcon
                    onClick={handleBackwardClick}
                    sx={{
                      padding: "8px", // Adds space around the icon, increasing the border size

                      cursor: "pointer", // Ensures the cursor is a pointer
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                        borderRadius: "50%", // Makes the background round
                      },
                    }}
                  />
                  <FormControl sx={{ width: { lg: 250, xs: 300 }, m: 0 }}>
                    {/* <InputLabel id="range-select-label">Frequency</InputLabel> */}
                    <ToggleButtonGroup
                      value={dataRange}
                      // The range is the same. Just hour for today.
                      //  Otherwise it's just the startdate and enddate that need to be adjusted accordingly

                      exclusive
                      onChange={(e) => {
                        setSubProductChartData([]);
                        setComparisonSubProductChartData([]);
                        setProductMixData([]);
                        setComparisonProductMixData([]);
                        // setFilteredChartData([]);
                        setComparisonFilteredChartData([]);
                        // setIsSubCategoryVisible(false);
                        // setIsComparisonSubCategoryVisible(false);

                        setDataRange(e.target.value);
                        let startingDate;
                        let endingDate;
                        switch (e.target.value) {
                          case "today":
                            startingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate()
                              )
                            );
                            endingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate()
                              )
                            );
                            break;
                          case "thisweek":
                            // Find the start of the week (Monday)
                            startingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate() -
                                  now.getUTCDay() +
                                  (now.getUTCDay() === 0 ? -6 : 1)
                              )
                            );
                            // Find the end of the week (Sunday)
                            endingDate = new Date(startingDate);
                            endingDate.setDate(startingDate.getDate() + 6); // Add 6 days to get to Sunday
                            break;

                          case "thismonth":
                            startingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                1
                              )
                            );
                            endingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth() + 1,
                                0
                              ) // Set to the last day of the current month
                            );
                            break;
                          default:
                            // Handle any other cases or default behavior
                            break;
                        }
                        setStartDate(startingDate.toISOString().split("T")[0]);
                        setEndDate(endingDate.toISOString().split("T")[0]);
                      }}
                      sx={{
                        "& .MuiToggleButton-root": {
                          height: "3rem",
                          background: "#F8F8F8",
                          "&.Mui-selected": {
                            background: "#D6D6D6", // Darker shade for the selected button
                          },
                        },
                      }}
                      aria-label="Range"
                      disabled={trendsLoad}
                    >
                      <ToggleButton value="today" aria-label="Today">
                        Day
                      </ToggleButton>
                      <ToggleButton value="thisweek" aria-label="This Week">
                        Week
                      </ToggleButton>
                      <ToggleButton value="thismonth" aria-label="This Month">
                        Month
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>
                  <ArrowForwardIcon
                    onClick={handleForwardClick}
                    sx={{
                      padding: "8px", // Adds space around the icon, increasing the border size
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                        borderRadius: "50%", // Makes the background round
                      },
                    }}
                  />
                </Box>
                <FormatDesiredDate
                  startDate={startDate}
                  endDate={endDate}
                  dataRange={dataRange}
                />
              </Box>
            </Tooltip>

            <Box
              sx={{
                display: "flex",
                flexDirection: isXSScreen ? "column" : "row",
                justifyContent: "space-between",
                width: { xs: "90%", lg: "40%" },
              }}
            >
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 3}
              >
                <Tooltip
                  title="Show only numbers back in time or include predicted
                 numbers as well"
                  arrow
                  placement="bottom"
                >
                  <Box
                    sx={{
                      ...(counter === 3 && helpMode
                        ? getHighlightedStyle()
                        : {}),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                      }}
                    >
                      To Date
                    </Typography>
                    <Switch
                      checked={hasPredictionData}
                      onChange={handlePredictionData}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                      }}
                    >
                      Projected
                    </Typography>
                  </Box>
                </Tooltip>
              </Tooltip>
              {!isXSScreen && (
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  flexItem
                  sx={{
                    mx: ".1rem",
                  }}
                />
              )}

              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 4}
              >
                <Tooltip
                  title="Toggle between units and NOK"
                  arrow
                  placement="bottom"
                >
                  <Box
                    sx={{
                      ...(counter === 4 && helpMode
                        ? getHighlightedStyle()
                        : {}),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                      //   px: ".5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#515151",
                        fontSize: "12px",
                      }}
                    >
                      Units
                    </Typography>
                    <Switch
                      checked={isCurrency}
                      onChange={handleUnitCurrency}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#515151",
                        fontSize: "12px",
                      }}
                    >
                      NOK
                    </Typography>
                  </Box>
                </Tooltip>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!isXSScreen && <LastUpdate />}
            </Box>

            <Box
              sx={{
                marginBottom: { xs: 0, lg: 0 }, // Adds bottom margin on xs screens
              }}
            >
              <SettingsDialog
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                range={range}
                downloadOptions={downloadOptions}
                trendsToggle={trendsToggle}
                setTrendsToggle={setTrendsToggle}
                // showPredictionOptions={false}
                showTrendsOptions={true}
                email={email}
                company={company}
                setCompany={setCompany}
              />

              {isXSScreen ? (
                <Tooltip
                  title=" Get a tour of the page and how it works"
                  arrow
                  placement="bottom"
                >
                  <InfoIcon
                    onClick={handleHelpMode}
                    sx={{
                      color: helpMode ? "#FFBD00" : "initial",
                      padding: "8px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                        borderRadius: "50%",
                      },
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <Typography variant="body1">Click to Close</Typography>
                  }
                  open={helpMode}
                  arrow
                  placement="top"
                >
                  <Tooltip
                    title="Get a tour of the page and how it works"
                    arrow
                    placement="bottom"
                  >
                    <InfoIcon
                      onClick={handleHelpMode}
                      sx={{
                        color: helpMode ? "#FFBD00" : "initial",
                        padding: "8px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                          borderRadius: "50%",
                        },
                      }}
                    />
                  </Tooltip>
                </Tooltip>
              )}
            </Box>
          </Box>
          {/* top bar ends here */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "column", xs: "column" },
              width: "100%",
              gap: 4,
              // outline: "solid 1px red",
              justifyContent: { lg: "flex-start" },
              alignItems: { lg: "flex-start", xs: "center" },
              height: {
                lg: company === "Fisketorget" ? "45%" : "33%", // Conditional height for 'lg' breakpoint
                xs: company === "Fisketorget" ? "205vh" : "205vh",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: { xs: "20px", lg: "25px" },
                }}
                gutterBottom
              >
                Trends and Top Sellers
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: { lg: "space-between", xs: "center" },
                alignItems: "flex-start",
                // border: "solid 1px red",
                width: "100%",
              }}
            >
              <FormControl sx={{ minWidth: 140, mr: "1rem" }}>
                <InputLabel id="article-supergroup-label">Products</InputLabel>
                <Select
                  labelId="article-supergroup-label"
                  id="article-supergroup-select"
                  value={selectedSupergroup}
                  onChange={handleDropdownChange}
                  label="Products"
                >
                  {articleSupergroups.map((group) => (
                    <MenuItem key={group} value={group}>
                      {group}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { lg: "row", xs: "column" },
                width: "100%",
                gap: 4,
                // border: "solid 1px red",
                justifyContent: "flex-start",
                alignItems: { lg: "flex-start", xs: "center" },
                height: {
                  lg: company === "Fisketorget" ? "70%" : "65%", // Conditional height for 'lg' breakpoint
                  xs: company === "Fisketorget" ? "100%" : "100%", // Static height for 'xs' breakpoint, adjust as needed
                },
              }}
            >
              {/* <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 5}
              placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
            >
              <Box
                sx={{
                  ...(counter === 5 && helpMode ? getHighlightedStyle() : {}),
                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "25%", xs: "90%" },
                  // gap: { lg: 2, xs: 3 },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 5 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "17rem" },
                  fontFamily: "Montserrat",
                  paddingY: { lg: 0, xs: 1 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: "10%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "solid 1px red",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 400,
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    Quick Overview
                  </Typography>
                </Box>
                {!isComparisonSubCategoryVisible ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "10%",
                        width: "90%",
                        // border: "solid 1px green",
                      }}
                    >
                      <Typography
                        // variant="h7"
                        sx={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontWeight: 600,
                        }}
                      >
                        {selectedComparisonSupergroup} Total
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "30%",
                        width: "90%",
                        // border: "solid 1px red",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h7"
                          sx={{
                            fontWeight: 400,
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                        >
                          This Period
                        </Typography>
                        <Typography
                          // variant="h6"
                          sx={{
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                        >
                          {new Intl.NumberFormat("nb-NO", {
                            //style: "currency",
                            //currency: "NOK",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(firstProductSum)}{" "}
                          NOK
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h7"
                          sx={{
                            fontWeight: 400,
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                        >
                          Last Period
                        </Typography>
                        <Typography
                          // variant="h6"
                          sx={{
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                        >
                          {new Intl.NumberFormat("nb-NO", {
                            //style: "currency",
                            //currency: "NOK",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(secondProductSum)}{" "}
                          NOK
                        </Typography>
                      </Box>

                      <Tooltip
                        placement="top"
                        arrow
                        title={
                          typeof productPercentageDifference === "string" ? (
                            <Typography variant="body2">
                              {productPercentageDifference}
                            </Typography>
                          ) : (
                            <Typography>
                              {productPercentageDifference.toFixed(2)}%
                              Difference
                            </Typography>
                          )
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography
                            variant="h7"
                            sx={{
                              fontFamily: "'Montserrat', sans-serif",
                            }}
                          >
                            Difference
                          </Typography>
                          <Typography
                            // variant="h6"
                            sx={{
                              fontFamily: "'Montserrat', sans-serif",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {firstProductSum === 0 ||
                            secondProductSum === 0 ? null : difference > 0 ? (
                              <KeyboardDoubleArrowUpIcon color="success" />
                            ) : difference < 0 ? (
                              <KeyboardDoubleArrowDownIcon color="error" />
                            ) : null}
                            {new Intl.NumberFormat("nb-NO", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(difference)}{" "}
                            NOK
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "10%",
                        width: "90%",
                        // border: "solid 1px green",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontWeight: 600,
                        }}
                      >
                        {selectedComparisonSubgroup} Total
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "30%",
                        width: "90%",
                        // border: "solid 1px red",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h7"
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          This Period
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                        >
                          {new Intl.NumberFormat("nb-NO", {
                            //style: "currency",
                            //currency: "NOK",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(firstSubProductSum)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h7"
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          Last Period
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                        >
                          {new Intl.NumberFormat("nb-NO", {
                            //style: "currency",
                            //currency: "NOK",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(secondSubProductSum)}
                        </Typography>
                      </Box>
                      <Tooltip
                        placement="top"
                        arrow
                        title={
                          typeof subProductPercentageDifference === "string" ? (
                            <Typography variant="body2">
                              {subProductPercentageDifference}
                            </Typography>
                          ) : (
                            <Typography>
                              {subProductPercentageDifference.toFixed(2)}%
                              Difference
                            </Typography>
                          )
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "'Montserrat', sans-serif",
                            }}
                          >
                            Difference
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "'Montserrat', sans-serif",
                            }}
                          >
                            {new Intl.NumberFormat("nb-NO", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(firstSubProductSum - secondSubProductSum)}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Box>
                  </>
                )}
              </Box>
            </Tooltip> */}
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 5}
                placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
              >
                <Box
                  sx={{
                    ...(counter === 5 && helpMode ? getHighlightedStyle() : {}),
                    display: "flex",
                    flexDirection: "column",
                    width: { lg: "45%", xs: "90%" },
                    // gap: { lg: 2, xs: 3 },
                    justifyContent: "flex-start",
                    alignItems: "center",
                    borderRadius: "1rem",
                    boxShadow:
                      counter === 5 && helpMode
                        ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                        : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    background: "#FFF",
                    height: { lg: "100%", xs: "30rem" },
                    // fontFamily: "Montserrat",
                    paddingY: { lg: 0, xs: 1 },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "10%",
                      // border: "solid red 1px",
                    }}
                  >
                    <TitleStyle tooltipKey="top_ten">Top 10 Sellers</TitleStyle>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      // border: "1px solid",
                    }}
                  >
                    {topTenLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <TopTenBarChart
                        data={barChartData}
                        isCurrency={isCurrency}
                        // isPercentage={true}
                        hasDifferentColor={true}
                        isXSScreen={true}
                      />
                    )}
                  </Box>
                </Box>
              </Tooltip>
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 6}
                placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
              >
                <Box
                  sx={{
                    ...(counter === 6 && helpMode ? getHighlightedStyle() : {}),
                    display: "flex",
                    flexDirection: "column",
                    width: { lg: "25%", xs: "90%" },
                    // gap: { lg: 2, xs: 3 },
                    justifyContent: "flex-start",
                    alignItems: "center",
                    borderRadius: "1rem",
                    boxShadow:
                      counter === 6 && helpMode
                        ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                        : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    background: "#FFF",
                    height: { lg: "100%", xs: "30rem" },
                    fontFamily: "Montserrat",
                    paddingY: { lg: 0, xs: 1 },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <TitleStyle
                      content={<Typography>{getTooltipContent()}</Typography>}
                    >
                      Upward Trending
                    </TitleStyle>

                    <UpwardTrends
                      positiveAmountTrendData={positiveAmountTrendData}
                      trendsLoad={trendsLoad}
                    />
                  </Box>
                </Box>
              </Tooltip>
              {/* first table ends here */}
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 7}
                placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
              >
                <Box
                  sx={{
                    ...(counter === 7 && helpMode ? getHighlightedStyle() : {}),
                    display: "flex",
                    flexDirection: "column",
                    width: { lg: "25%", xs: "90%" },
                    // gap: { lg: 2, xs: 3 },
                    justifyContent: "flex-start",
                    alignItems: "center",
                    borderRadius: "1rem",
                    boxShadow:
                      counter === 7 && helpMode
                        ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                        : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    background: "#FFF",
                    height: { lg: "100%", xs: "30rem" },
                    fontFamily: "Montserrat",
                    paddingY: { lg: 0, xs: 1 },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <TitleStyle
                      content={<Typography>{getTooltipContent()}</Typography>}
                    >
                      Downward Trending
                    </TitleStyle>
                    <DownwardTrends
                      negativeAmountTrendData={negativeAmountTrendData}
                      trendsLoad={trendsLoad}
                    />
                  </Box>
                </Box>
              </Tooltip>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "column", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 1px red",
              justifyContent: { lg: "flex-start" },
              alignItems: { lg: "flex-start", xs: "center" },
              height: {
                lg: company === "Fisketorget" ? "45%" : "30%", // Conditional height for 'lg' breakpoint
                xs: company === "Fisketorget" ? "80vh" : "70vh", // Static height for 'xs' breakpoint, adjust as needed
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: { lg: "flex-start", xs: "center" },
                alignItems: "flex-start",
                // border: "solid 1px red",
                width: "100%",
              }}
            >
              <FormControl sx={{ minWidth: 140, mr: "1rem" }}>
                <InputLabel id="article-supergroup-label">Products</InputLabel>
                <Select
                  labelId="article-supergroup-label"
                  id="article-supergroup-select"
                  value={selectedComparisonSupergroup}
                  onChange={handleComparisonDropdownChange}
                  label="Products"
                >
                  {comparisonArticleSupergroups.map((group) => (
                    <MenuItem key={group} value={group}>
                      {group}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { lg: "row", xs: "column" },
                width: "100%",
                gap: 4,
                // border: "solid 1px red",
                justifyContent: { lg: "flex-start" },
                alignItems: { lg: "flex-start", xs: "center" },
                height: {
                  lg: company === "Fisketorget" ? "70%" : "80%", // Conditional height for 'lg' breakpoint
                  xs: company === "Fisketorget" ? "80%" : "80%", // Static height for 'xs' breakpoint, adjust as needed
                },
              }}
            >
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 8}
                placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
              >
                <Box
                  sx={{
                    ...(counter === 8 && helpMode ? getHighlightedStyle() : {}),
                    display: "flex",
                    flexDirection: "column",
                    width: { lg: "100%", xs: "90%" },
                    // gap: 2,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    borderRadius: "1rem",
                    boxShadow:
                      counter === 8 && helpMode
                        ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                        : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    background: "#FFF",
                    height: { lg: "100%", xs: "35rem" },
                    // outline: "solid 1px red",
                    // border: "1px solid red",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column", // Change to column for vertical stacking
                      height: { lg: "20%", xs: "7rem", xl: "20%" },
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "flex-start", // Align items to the start of the flex container
                      // outline: "solid red 1px",
                    }}
                  >
                    <TitleStyle tooltipKey="product_sales_overtime">
                      {" "}
                      Product Sales Over Time
                    </TitleStyle>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          width: "15%",
                          // mt: "1rem",
                          mr: "2rem",
                          // outline: "solid red 1px",
                        }}
                      >
                        {isComparisonSubCategoryVisible ? (
                          <FormControl sx={{ minWidth: 140 }}>
                            <InputLabel id="article-subgroup-label">
                              Sub Products
                            </InputLabel>
                            <Select
                              labelId="article-subgroup-label"
                              id="article-subgroup-select"
                              value={selectedComparisonSubgroup}
                              onChange={handleComparisonSubProduct}
                              label="Sub Products"
                            >
                              {comparisonArticleSubgroups.map((group) => (
                                <MenuItem key={group} value={group}>
                                  {group}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : comparisonArticleSupergroups.length > 0 ? (
                          <FormControl sx={{ minWidth: 140, mr: "1rem" }}>
                            {/* Uncomment and use the following code if needed */}
                            {/* <InputLabel id="article-supergroup-label">Products</InputLabel>
        <Select
          labelId="article-supergroup-label"
          id="article-supergroup-select"
          value={selectedComparisonSupergroup}
          onChange={handleComparisonDropdownChange}
          label="Products"
        >
          {comparisonArticleSupergroups.map((group) => (
            <MenuItem key={group} value={group}>
              {group}
            </MenuItem>
          ))}
        </Select> */}
                          </FormControl>
                        ) : null}

                        {(comparisonArticleSubgroups.length > 0 &&
                          !prodComparisonLineLoad) ||
                        subProdComparisonLineLoad ? (
                          <Tooltip
                            TransitionComponent={Zoom}
                            title="Toggle between Product Category and Product"
                            placement="top"
                            arrow
                          >
                            <Box
                              sx={{
                                transform: "translateY(-50%)",
                                right: 0,
                              }}
                            >
                              {prodComparisonLineLoad ||
                              subProdComparisonLineLoad ? (
                                <KPILoad />
                              ) : (
                                <Switch
                                  checked={isComparisonSubCategoryVisible}
                                  onChange={handleToggleComparisonSubCat}
                                />
                              )}
                            </Box>
                          </Tooltip>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column", // Change to column for vertical stacking
                      height: { lg: "80%", xs: "25rem", xl: "80%" },
                      alignItems: "center",
                      width: "100%",
                      // mt: { lg: 0, xs: "2rem" },
                      // outline: "solid red 1px",
                      justifyContent: "center", // Align items to the start of the flex container
                    }}
                  >
                    {isComparisonSubCategoryVisible ? (
                      subProductChartData.length > 0 ||
                      !isEmpty(comparisonSubProductMixData) ? (
                        prodComparisonLineLoad ? (
                          <Box
                            sx={{
                              display: "flex",
                              height: "50%",
                            }}
                          >
                            <ChartLoading />
                          </Box>
                        ) : dataRange === "today" ? (
                          <ComparisonHourlyChart
                            data={comparisonSubProductChartData}
                            isCurrency={isCurrency}
                            isXSScreen={isXSScreen}
                            isNotPercentage={true}
                            dataRange={dataRange}
                            // isMultiRestaurant={true}
                            isMinimal={true}
                            isProfitGraph={
                              company === "Los Tacos" || company === "Krunch"
                                ? true
                                : false
                            }
                          />
                        ) : (
                          <ComparisonLineChart
                            data={comparisonSubProductChartData}
                            isMinimal={true}
                            isNotPercentage={true}
                            isCurrency={isCurrency}
                            noPrefix={true}
                            hasPredictionLine={false}
                            isXSScreen={isXSScreen}
                            dataRange={dataRange}

                            // dataRange={dataRange}
                          />
                        )
                      ) : null
                    ) : prodComparisonLineLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : dataRange === "today" ? (
                      <ComparisonHourlyChart
                        data={comparisonFilteredChartData}
                        // isCurrency={isCurrency}
                        isXSScreen={isXSScreen}
                        isNotPercentage={isCurrency}
                        dataRange={dataRange}
                        // isMultiRestaurant={true}
                        isMinimal={true}
                        isProfitGraph={
                          company === "Los Tacos" || company === "Krunch"
                            ? true
                            : false
                        }
                      />
                    ) : (
                      <ComparisonLineChart
                        data={comparisonFilteredChartData}
                        isMinimal={true}
                        isNotPercentage={isCurrency}
                        // isCurrency={isCurrency}
                        noPrefix={true}
                        hasPredictionLine={false}
                        isXSScreen={isXSScreen}
                        dataRange={dataRange}
                      />
                    )}
                  </Box>
                </Box>
              </Tooltip>
            </Box>
          </Box>

          {company !== "Fisketorget" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { lg: "column", xs: "column" },
                  width: "100%",
                  gap: 4,
                  // border: "solid 1px red",
                  justifyContent: { lg: "flex-start" },
                  alignItems: { lg: "flex-start", xs: "center" },
                  height: {
                    lg: company === "Fisketorget" ? "45%" : "30%", // Conditional height for 'lg' breakpoint
                    xs: company === "Fisketorget" ? "160vh" : "140vh", // Static height for 'xs' breakpoint, adjust as needed
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    // variant="h5"
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: { xs: "18px", lg: "25px" },
                    }}
                    gutterBottom
                  >
                    Take-Away and Third Party Delivery
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { lg: "flex-start", xs: "center" },
                    alignItems: "flex-start",
                    // border: "solid 1px red",
                    width: "100%",
                  }}
                >
                  <FormControl sx={{ minWidth: 140, mr: "1rem" }}>
                    <InputLabel id="article-supergroup-label">
                      Products
                    </InputLabel>
                    <Select
                      labelId="article-supergroup-label"
                      id="article-supergroup-select"
                      value={selectedThirdPartySupergroup}
                      onChange={handleThirdPartyDropdownChange}
                      label="Products"
                    >
                      {articleSupergroups.map((group) => (
                        <MenuItem key={group} value={group}>
                          {group}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column" },
                    width: "100%",
                    gap: 4,
                    // border: "solid 1px red",
                    justifyContent: "flex-start",
                    alignItems: { lg: "flex-start", xs: "center" },
                    height: "100%",
                  }}
                >
                  <Tooltip
                    arrow
                    title={tooltipContent[counter]}
                    open={helpMode && counter === 9}
                    placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
                  >
                    <Box
                      sx={{
                        ...(counter === 9 && helpMode
                          ? getHighlightedStyle()
                          : {}),
                        display: "flex",
                        flexDirection: "column",
                        width: { lg: "50%", xs: "90%" },
                        // gap: 2,
                        justifyContent: "flex-start",
                        alignItems: "center",
                        borderRadius: "1rem",
                        boxShadow:
                          counter === 9 && helpMode
                            ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                            : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                        background: "#FFF",
                        height: { lg: "90%", xs: "30rem" },
                        // fontFamily: "Montserrat",
                        // border: "1px solid red",
                      }}
                    >
                      <TitleStyle tooltipKey="take_away">
                        Take-away vs Dine-in
                      </TitleStyle>

                      <Box sx={{ height: "100%", width: "100%" }}>
                        <TakeAway
                          startDate={startDate}
                          endDate={endDate}
                          selectedRestaurant={selectedRestaurant}
                          company={company}
                          period={dataRange}
                          selectedThirdPartySupergroup={
                            selectedThirdPartySupergroup
                          }
                          selectedPage={selectedPage}
                        />
                      </Box>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    arrow
                    title={tooltipContent[counter]}
                    open={helpMode && counter === 10}
                    placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
                  >
                    <Box
                      sx={{
                        ...(counter === 10 && helpMode
                          ? getHighlightedStyle()
                          : {}),

                        display: "flex",
                        flexDirection: "column",
                        width: { lg: "50%", xs: "90%" },
                        // gap: 2,
                        justifyContent: "flex-start",
                        alignItems: "center",
                        borderRadius: "1rem",
                        boxShadow:
                          counter === 10 && helpMode
                            ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                            : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                        background: "#FFF",
                        height: { lg: "90%", xs: "30rem" },
                      }}
                    >
                      <Box>
                        <TitleStyle tooltipKey="delivery_cost">
                          Third Party Delivery Cost
                        </TitleStyle>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          height: "80%",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          // border:"1px solid"
                        }}
                      >
                        {deliveryCostLoad ? (
                          <Box
                            sx={{
                              display: "flex",
                              height: "50%",
                            }}
                          >
                            <ChartLoading />
                          </Box>
                        ) : (
                          <DeliveryChart
                            data={deliveryCost}
                            isDetailedChart={false}
                            hasDifferentColor={false}
                            range={range}
                            isXSScreen={isXSScreen}
                            isMinimal={true}
                            isPercentage={false}
                            isCurrency={isCurrency}
                            dataRange={dataRange}
                          />
                        )}
                      </Box>
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            width: { lg: "50vw", xs: "95vw" },
            height: { lg: "70vh", xs: "80vh" },
            maxWidth: "none", // This will help override default maxWidth values
            maxHeight: "none", // This will help override default maxHeight values
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "space-between",
            width: "100%",
          }}
        >
          <DialogTitle sx={{ flexGrow: 1 }}>Settings</DialogTitle>

          <CloseIcon
            onClick={handleCloseDialog}
            sx={{
              mr: "1rem",
              cursor: "pointer", // To indicate that the icon is clickable
              borderRadius: "50%", // To make the background change rounded, like an icon
              p: "8px",
              transition: "background-color 0.3s", // Smooth transition for the hover effect
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)", // Slight gray on hover
              },
            }}
          />
        </Box>
      </Dialog>
      <Dialog
        open={openHelpDialog}
        onClose={() => setOpenHelpDialog(false)}
        aria-labelledby="help-dialog-title"
        aria-describedby="help-dialog-description"
      >
        <DialogTitle id="help-dialog-title">{"Help Information"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="help-dialog-description">
            This help button is designed to provide you with additional
            information and guidance on how to use the features of this
            application. Clicking this button will display useful tips and FAQs
            to help you navigate and make the most of the app's functionalities.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenHelpDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={open}
        severity={severity}
        message={message}
        onClose={handleClose}
      />
      {helpMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            width: { lg: "180px", xs: "280px" },
            position: "fixed", // Make it absolute to position at the bottom
            bottom: 20,
            right: 20,
            padding: "10px",
            // border:"solid 1px red"
          }}
        >
          <NavigationButtons
            isXSScreen={isXSScreen}
            setHelpMode={setHelpMode}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            counter={counter}
            maxSteps={maxSteps}
          />
        </Box>
      )}
    </Box>
  );
};

export default Trends;
