const Prefix = () => {
  let prefix = "";

  if (process.env.NODE_ENV === "development") {
    prefix = "http://127.0.0.1:8000";
  }
  return prefix;
};

export default Prefix;
