import * as XLSX from "xlsx";

const downloadProfitLoss = (
  grossProfit = [],
  grossProfitPercent = [],
  staffData = [],
  restaurantNames = "",
  averageSickCost = 0,
  totalActualTakeOut = 0,
  averageEmployeeCost = 0,
  averageEmployeeProductivity = 0,
  totals = {},
  dataRange = "",
  filteredData = [],
  averageGrossProfitPercentage = 0,
  averageNetProfitPercentage = 0,
  statStaffData = 0,
  sickCostPercent = 0,
  totalActualTakeOutPercentage = 0
) => {
  const fileName =
    restaurantNames.length > 0 ? restaurantNames : "Company Overall Data";
  const wb = XLSX.utils.book_new();

  // Helper function to format dates consistently
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    if (dataRange === "today") {
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
    return `${day}/${month}/${year}`;
  };

  // Prepare data with historical and prediction columns
  const profitData = [];
  let startDate, endDate;

  // Choose the correct data set based on dataRange
  const dataToUse = dataRange === "today" ? filteredData : grossProfitPercent;

  dataToUse.forEach((item) => {
    item.data.forEach((entry) => {
      const date = new Date(entry.x);
      const formattedDate = formatDate(date);

      if (!startDate || date < startDate) startDate = date;
      if (!endDate || date > endDate) endDate = date;

      const existingEntry = profitData.find((d) => d.Date === formattedDate);

      if (existingEntry) {
        existingEntry[item.id] = entry.y;
      } else {
        profitData.push({
          Date: formattedDate,
          [item.id]: entry.y,
        });
      }
    });
  });

  // Create date interval string
  const dateInterval = `${formatDate(startDate)} - ${formatDate(endDate)}`;

  // Create the data for the Excel file
  const kpiData = [
    { KPI: "Date Interval", Actual: dateInterval, Prediction: dateInterval }, // Add date interval row
    {
      KPI: "Sales",
      Actual: totals.historical?.totalSales || 0,
      Prediction: totals.prediction?.totalSales || 0,
    },
    {
      KPI: "Gross Profit I",
      Actual: totals.historical?.totalGrossProfitI || 0,
      Prediction: totals.prediction?.totalGrossProfitI || 0,
    },
    {
      KPI: "Gross Profit II",
      Actual: totals.historical?.totalGrossProfitII || 0,
      Prediction: totals.prediction?.totalGrossProfitII || 0,
    },
    {
      KPI: "Total Employee Cost",
      Actual: averageEmployeeCost[0],
      Prediction: averageEmployeeCost[1],
    },
    {
      KPI: "Emp Productivity/hr",
      Actual: averageEmployeeProductivity,
      Prediction: "N/A",
    },
    {
      KPI: "Total Sick Cost",
      Actual: averageSickCost[0],
      Prediction: "N/A",
    },
    {
      KPI: "Third Party Delivery Cost",
      Actual: totalActualTakeOut,
      Prediction: "N/A",
    },
  ];

  const kpiPercentData = [
    { KPI: "Date Interval", Actual: dateInterval, Prediction: dateInterval }, // Add date interval row
    {
      KPI: "Sales",
      Actual: " - ",
      Prediction: " - ",
    },
    {
      KPI: "Gross Profit I",
      Actual: averageGrossProfitPercentage[0],
      Prediction: averageGrossProfitPercentage[1],
    },
    {
      KPI: "Gross Profit II",
      Actual: averageNetProfitPercentage[0],
      Prediction: averageNetProfitPercentage[1],
    },
    {
      KPI: "Total Employee Cost",
      Actual: statStaffData[0],
      Prediction: statStaffData[1],
    },
    {
      KPI: "Emp Productivity/hr",
      Actual: " - ",
      Prediction: "N/A",
    },
    {
      KPI: "Total Sick Cost",
      Actual: sickCostPercent[0],
      Prediction: "N/A",
    },
    {
      KPI: "Third Party Delivery Cost",
      Actual: totalActualTakeOutPercentage,
      Prediction: "N/A",
    },
  ];

  const wsKpiData = XLSX.utils.json_to_sheet(kpiData);
  wsKpiData["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }]; // Adjust column widths

  // Add headers and sub-headers
  XLSX.utils.sheet_add_aoa(wsKpiData, [["KPI", "Actual", "Prediction"]], {
    origin: "A1",
  });

  // Append kpiPercentData below kpiData
  XLSX.utils.sheet_add_aoa(
    wsKpiData,
    [
      ["", "", ""],
      ["KPI Percent Data", "", ""],
    ], // Add a header for the new table
    { origin: -1 } // Append below the last row
  );

  XLSX.utils.sheet_add_json(wsKpiData, kpiPercentData, {
    skipHeader: true,
    origin: -1,
  });

  XLSX.utils.book_append_sheet(wb, wsKpiData, "Key Metrics");

  // Create worksheet from profit data
  const wsData = XLSX.utils.json_to_sheet(profitData);
  wsData["!cols"] = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ]; // Adjust column widths

  // Add headers and sub-headers
  const headers = [
    "Date",
    "Gross Profit II Historical",
    "Gross Profit I Historical",
    "Sales Historical",
    "Gross Profit II Prediction",
    "Gross Profit I Prediction",
    "Sales Prediction",
  ];
  XLSX.utils.sheet_add_aoa(wsData, [headers], {
    origin: "A1",
  });

  XLSX.utils.book_append_sheet(wb, wsData, "Sales and Profit Data");

  const staffDataFormatted = [];

  staffData.forEach((item) => {
    item.data.forEach((entry) => {
      const date = new Date(entry.x);
      const formattedDate = formatDate(date);

      const existingEntry = staffDataFormatted.find(
        (d) => d.Date === formattedDate
      );

      if (existingEntry) {
        existingEntry[item.id] = entry.y;
      } else {
        staffDataFormatted.push({
          Date: formattedDate,
          [item.id]: entry.y,
        });
      }
    });
  });

  // Create worksheet from staff data
  const wsStaffData = XLSX.utils.json_to_sheet(staffDataFormatted);
  wsStaffData["!cols"] = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ]; // Adjust column widths

  // Add headers and sub-headers for staff data
  const staffHeaders = ["Date", "Actual", "Prediction"];
  XLSX.utils.sheet_add_aoa(wsStaffData, [staffHeaders], {
    origin: "A1",
  });

  XLSX.utils.book_append_sheet(wb, wsStaffData, "Staff Data");
  // Create the summary sheet
  const summaryData = [
    { Key: "Average Employee Cost", Value: averageEmployeeCost },
    {
      Key: "Average Employee Productivity",
      Value: averageEmployeeProductivity,
    },
  ];

  if (summaryData.length > 0) {
    const wsSummary = XLSX.utils.json_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(wb, wsSummary, "Summary");
  }

  if (wb.SheetNames.length > 0) {
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });

    // Create a link element
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } else {
    alert("No data available to download!");
  }
};

export default downloadProfitLoss;
