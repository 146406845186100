import React from "react";
import ReactLoading from "react-loading";
import { Box } from "@mui/material";

const KPILoad = () => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <ReactLoading
        type="bars"
        color="#737270"
        height={10}
        width={25}
        sx={{ borderRadius: "1px" }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // border: "solid 1px red",
        }}
      >
        {/* The commented Typography component can be enabled if needed */}
        {/* <Typography
          variant="h6"
          component="div"
          color="text.secondary"
          sx={{
            fontWeight: 500,
          }}
        >
          Loading..
        </Typography> */}
      </Box>
    </Box>
  );
};

export default KPILoad;
