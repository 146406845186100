import React, { useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { getLineTickValues } from "../../utils/GetLineTickValues";
import dayjs from "dayjs";
import formatNumber from "../../utils/FormatNumber";

const latest_created_at = localStorage.getItem("latest_records");
const company = localStorage.getItem("company");

const SlicesLineChart = ({
  data,
  isDetailedChart,
  isCombinedChart = false,
  isStaffChart = false,
  isNotPercentage = false,
  isSecondaryChart = false,
  secondaryData = null,
  range = null,
  isOverlapChart = false,
  isXSScreen = false,
  allowNegativeY = false,
  showZeroLine = false,
  isMinimal = false,
  singleTooltip = false,
  hasPredictionLine = false,
  predictionLineDate = null,
  isMinNonZero = false,
  hasStaffMarker = false,
  startDate,
  endDate,
}) => {
  //SANDBOX
  //SANDBOX
  const goalPercentage =
    company === "Fisketorget" ? 32 : company === "Burgerheim" ? 20 : 28;
  const [hiddenIds, setHiddenIds] = useState([]);
  let newVar;
  let secondVar;

  const format = !isNotPercentage ? (v) => `${v}%` : (v) => `${v} NOK`;
  if (!isDetailedChart) {
    newVar = [];
  }
  for (const property in data) {
    newVar.push(data[property]);
  }
  const lineData =
    newVar && Object.entries(newVar).length !== 0 ? [newVar] : [];
  if (isCombinedChart) {
    //combined chart
    newVar = newVar[0];
  }

  const { tickValuesI, tickValuesO } = getLineTickValues(newVar);

  // Calculate tick values for small screens

  if (secondaryData) {
    secondVar = [];
    for (const property in secondaryData) {
      secondVar.push(secondaryData[property]);
    }
  }

  let minYValue;
  let maxYValue;
  if (newVar && newVar.length > 0) {
    minYValue = Math.min(
      ...newVar.map((series) =>
        Math.min(...series.data.map((point) => point.y))
      )
    );
    maxYValue = Math.max(
      ...newVar.map((series) =>
        Math.max(...series.data.map((point) => point.y))
      )
    );
  }
  if (isNotPercentage) {
    // For NOK
    minYValue = Math.round(minYValue / 10000) * 10000;
    maxYValue = Math.round(maxYValue / 10000) * 10000;
  } else {
    minYValue = Math.round(minYValue / 10) * 10;
    maxYValue = Math.round(maxYValue / 10) * 10;
  }
  let filteredData = newVar?.filter((serie) => !hiddenIds.includes(serie.id));

  let staffMarkerDate;
  if (hasStaffMarker && latest_created_at) {
    const latestCreatedAt = dayjs(latest_created_at);
    const start_date = dayjs(startDate); // Assuming start_date is defined elsewhere
    const end_date = dayjs(endDate); // Assuming end_date is defined elsewhere


    // Check if latest_created_at falls within the start and end date range
    if (
      latestCreatedAt.isAfter(start_date) &&
      latestCreatedAt.isBefore(end_date)
    ) {
      const modifiedDate = latestCreatedAt.add(14, "day");
      staffMarkerDate = modifiedDate.toDate();
      staffMarkerDate.setHours(0, 0, 0, 0);
    }
    // If the date is not in the range, staffMarkerDate remains undefined
  }

  const axisProps = !isSecondaryChart
    ? {
        axisLeft: {
          format,
          legendOffset: 12,
        },
        colors: [
          "#70335D",
          // "#17CB1B",

          // "#E63946",
          // "#40E0D0",
          "#4169E1",
          // "#40E0D0",

          "#bcbd22",
          // "#2ca02c",
          "#d62728",
          "#9467bd",
          "#8c564b",
          "#e377c2",
          "#7f7f7f",

          // "#17becf",
        ],
        sliceTooltip: ({ slice }) => {
          const currentDate = new Date(slice.points[0].data.x);
          let formattedDate;

          if (range === "month") {
            formattedDate = currentDate.toLocaleDateString(undefined, {
              month: "long",
              year: "numeric",
            });
          } else if (range === "week") {
            // Adjust to Sunday of the current week
            const sunday = new Date(currentDate);
            sunday.setDate(sunday.getDate() - sunday.getDay());

            // January 4th is always in week 1 according to ISO standard
            const startOfYear = new Date(sunday.getFullYear(), 0, 1);

            // Calculate the number of days between the date and the start of the year
            const dayDifference =
              (sunday - startOfYear) / (24 * 60 * 60 * 1000);

            // Calculate ISO week number
            const weekNumber =
              1 + Math.floor((dayDifference + startOfYear.getDay() + 5) / 7);
            formattedDate = `${currentDate.toLocaleDateString(undefined, {
              month: "long",
            })} (Week ${weekNumber}) ${sunday.getFullYear()}`;
          } else {
            formattedDate = currentDate.toLocaleDateString(undefined, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }

          return (
            <div
              style={{
                background: "white",
                padding: "10px",
                borderRadius: "2px",
              }}
            >
              <strong>{formattedDate}</strong>
              <br />
              {slice.points.map((point) => (
                <strong key={point.id} style={{ color: point.serieColor }}>
                  {point.serieId}:
                  {isNotPercentage
                    ? new Intl.NumberFormat("nb-NO", {
                        style: "currency",
                        currency: "NOK",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(point.data.y)
                    : ` ${point.data.y}%`}
                  <br />
                  {point.data.comment && (
                    <strong style={{ color: "black" }}>
                      Comment: {point.data.comment}
                      <br />
                    </strong>
                  )}
                </strong>
              ))}
            </div>
          );
        },
      }
    : {
        colors: "#17CB1B",
        axisRight: {
          format,
          legendOffset: 12,
          tickValues: [0, minYValue, maxYValue],
        },
        sliceTooltip: ({ slice }) => {
          const currentDate = new Date(slice.points[0].data.x);
          let formattedDate;

          if (range === "month") {
            formattedDate = currentDate.toLocaleDateString(undefined, {
              month: "long",
              year: "numeric",
            });
          } else if (range === "week") {
            // Adjust to Sunday of the current week
            const sunday = new Date(currentDate);
            sunday.setDate(sunday.getDate() - sunday.getDay());

            // January 4th is always in week 1 according to ISO standard
            const startOfYear = new Date(sunday.getFullYear(), 0, 1);

            // Calculate the number of days between the date and the start of the year
            const dayDifference =
              (sunday - startOfYear) / (24 * 60 * 60 * 1000);

            // Calculate ISO week number
            const weekNumber =
              1 + Math.floor((dayDifference + startOfYear.getDay() + 5) / 7);
            formattedDate = `${currentDate.toLocaleDateString(undefined, {
              month: "long",
            })} (Week ${weekNumber}) ${sunday.getFullYear()}`;
          } else {
            formattedDate = currentDate.toLocaleDateString(undefined, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }

          // Find the object with the matching x value in secondarySalesData
          // const matchedData = secondVar[1].data.find(
          //   (d) =>
          //     d.x.toISOString().split("T")[0] ===
          //     currentDate.toISOString().split("T")[0]
          // );

          // If matchedData is found, extract the y value, else set it to null
          // const secondaryYValue = matchedData ? matchedData.y : null;

          // console.log(secondaryYValue, "secondary data ayo value");

          // Ensure both dates are compared in the same format
          // let isStaffMarkerDate =
          //   hasStaffMarker &&
          //   new Date(slice.points[0].data.x)?.setHours(0, 0, 0, 0) ===
          //     staffMarkerDate?.setHours(0, 0, 0, 0);
          // const pointDate = new Date(slice.points[0].data.x);

          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                borderRadius: "5px",
                boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
              }}
            >
              <strong>{formattedDate}</strong>
              {slice.points.map((point) => {
                // Find the matching series in the secondary data
                const secondarySeries = secondVar.find(
                  (s) => s.id === point.serieId
                );

                // Find the matching data point in this series
                const matchedData = secondarySeries?.data.find(
                  (d) =>
                    new Date(d.x).getTime() === new Date(point.data.x).getTime()
                );

                // Extract the y value if matchedData is found, else set it to null
                const secondaryYValue = matchedData ? matchedData.y : null;
                // console.log(secondaryYValue, "hello");

                return (
                  <div
                    key={point.id}
                    style={{
                      color: "#17CB1B",
                      padding: "3px 0",
                    }}
                  >
                    <strong>
                      {point.serieId}:{" "}
                      {formatNumber(parseInt(point.data.yFormatted))}
                    </strong>
                    {secondaryYValue != null ? (
                      <div
                        style={{
                          color: "#70335D", // Adjust to your secondary data color preference
                          padding: "3px 0",
                        }}
                      >
                        <strong>Staff Cost: {secondaryYValue}%</strong>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          );
        },
      };
  // Check if the sum of y values is 0
  // const isDataZero = newVar.every((series) =>
  //   series.data.every((point) => point.y === 0)
  // );
  return !lineData || lineData.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        // border: "solid red 1px",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    <ResponsiveLine
      animate
      enableGridX={false}
      enableGridY={isMinimal ? true : !isSecondaryChart}
      gridYValues={[minYValue, goalPercentage, maxYValue]} // Add goalPercentage to gridYValues
      axisBottom={{
        format: (value) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString(undefined, {
            // year: "numeric",
            month: "short",
            day: "numeric",
          });

          return formattedDate;
        },
        legendOffset: -12,
        tickValues: isXSScreen ? tickValuesO : tickValuesI, // Assuming tickValuesO and tickValuesI are defined elsewhere
      }}
      {...axisProps}
      axisLeft={
        !isSecondaryChart
          ? {
              tickValues: [minYValue, goalPercentage, maxYValue], // Add goalPercentage to tickValues
              format: isNotPercentage ? undefined : (v) => `${v}%`,
            }
          : null
      }
      curve="monotoneX"
      data={filteredData}
      // enablePointLabel
      // height={400}
      margin={
        isMinimal
          ? {
              bottom: 30,
              left: 50,
              right: 50,
              top: 50,
            }
          : isStaffChart
          ? {
              bottom: 60,
              left: 50,
              right: 90,
              top: 30,
            }
          : {
              bottom: 30,
              left: 80,
              right: 20,
              top: 60,
            }
      }
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      lineWidth={1}
      markers={[
        /*...(showZeroLine
            ? [
                {
                  axis: "y",
                  value: 0, // Set the marker at 0% on the y-axis
                  lineStyle: {
                    stroke: "black",
                    strokeWidth: 1,
                  },
                  legendOrientation: "vertical",
                  legendPosition: "bottom-left",
                },
              ]
            : []),*/
        ...(isStaffChart && !isSecondaryChart
          ? [
              {
                axis: "y",
                value: goalPercentage,
                // legend: `Goal ${goalPercentage}%`,
                lineStyle: {
                  stroke: "#b877d9",
                  strokeWidth: 2,
                },
              },
            ]
          : []),
        // Add the marker for todays date here
        hasPredictionLine
          ? {
              axis: "x", // Vertical line
              value: new Date(latest_created_at),
              lineStyle: {
                stroke: "#505050", // Line color
                strokeWidth: 2, // Line width
              },

              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
        hasStaffMarker // change to hasStaffMarker
          ? {
              axis: "x", // Vertical line
              // legend: "prediction marker",
              value: staffMarkerDate, // Immediately invoke the function to get the new date
              lineStyle: {
                stroke: "green", // Line color
                strokeWidth: 2, // Line width
              },
              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
      ]}
      pointBorderWidth={1}
      pointSize={4}
      enableArea={true}
      areaBaselineValue={isMinNonZero ? minYValue : 0}
      areaOpacity={0.15}
      enableSlices="x"
      labelFormat={format}
      useMesh
      xFormat="time:%Y-%m-%d"
      xScale={{
        format: "%Y-%m-%d",
        precision: "day",
        type: "time",
        useUTC: true,
      }}
      yScale={{
        type: "linear",
        min: allowNegativeY ? "auto" : 0,
        max: isStaffChart & (maxYValue < 120) ? 120 : "auto", // Set the calculated maxY as the max property
      }}
      legends={
        isMinimal
          ? []
          : [
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: isSecondaryChart ? 150 : 2,
                translateY: 45,
                itemsSpacing: 10,
                itemDirection: "left-to-right",
                itemWidth: 109,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
                onClick: (datum) => {
                  setHiddenIds((currentHiddenIds) => {
                    const isCurrentlyHidden = currentHiddenIds.includes(
                      datum.id
                    );
                    const newHiddenIds = isCurrentlyHidden
                      ? currentHiddenIds.filter((id) => id !== datum.id)
                      : [...currentHiddenIds, datum.id];

                    // Ensure we don't hide all lines: if newHiddenIds length is equal to the number of lines, reset it
                    if (newHiddenIds.length >= newVar.length) {
                      return [];
                    }
                    return newHiddenIds;
                  });
                },
              },
            ]
      }
    />
  );
};

export default SlicesLineChart;
