import React from "react";
import "../App.css";
import { Routes, Route } from "react-router-dom";
// import SalesDataLineChart from "./SalesDataLineChart";
// import ProfitLoss from "../dashboard/ProfitLoss";
// import Recommendations from "../dashboard/Recommendations";
import SalesPrediction from "../dashboard/SalesPrediction";
import MyRestaurant from "../dashboard/MyRestaurant";
import Landingv2 from "../Landingv2/Landingv2";
import GetStarted from "../dashboard/GetStarted";
// import ProfilePicPage from "../user/ProfilePicPage";
import Admin from "../user/Admin";
import MarketingCampaign from "../user/MarketingCampaign";
import AboutUs from "../Landingv2/AboutUs";
import Features from "../Landingv2/Features";
import EmployeeCreate from "../user/EmployeeCreate";
import EmployeeList from "../user/EmployeeList";
// import SignIn from "./user/SignIn";
// import Restaurants from "../user/Restaurant";
// import LandingPage from "./user/Landingpage";
import ForgotPassword from "../user/ForgotPassword";
// import Contact from "./user/Contact";
import ChangePassword from "../user/ChangePassword";
import FAQ from "../Landingv2/FAQ";
import ContactUs from "../Landingv2/ContactUs";
// import Contact from "../user/Contact";
import NewProfitLoss from "../dashboard/NewProfitLoss";
import NewSuperDashboard from "../dashboard/NewSuperDashboard";
// import NewDashboard from "../dashboard/NewDashboard";
import NewDashboardTwo from "../dashboard/NewDashboardTwo";
import NewSuperDashboardTwo from "../dashboard/NewSuperDashboardTwo";
import Profile from "../user/Profile";
import NewRecommendations from "../dashboard/NewRecommendations";
// import Blogs from "../Landingv2/Blogs";
// import ErrorPage from "../user/ErrorPage";
// import MaintenancePage from "../user/MaintenancePage";
import ErrorPage from "../user/ErrorPage"; // Import your 404 page component
import DownloadDataPage from "../user/DownloadData";
import Event from "../user/Event";
import Comparisons from "../dashboard/Comparisons";
import Trends from "../dashboard/Trends";
import SupportUs from "../user/SupportUs";
// import Company from "../dashboard/Company"

// function Main() {
//   return (
//     <div className="App">
//       <main>
//         <SalesDataLineChart />
//       </main>
//     </div>
//   );
// }
// function ProfitAndLoss() {
//   return (
//     <div className="App">
//       <main>
//         <ProfitLoss />
//       </main>
//     </div>
//   );
// }
// function Recomm() {
//   return (
//     <div className="App">
//       <main>
//         <Recommendations />
//       </main>
//     </div>
//   );
// }

function ErrorRoute() {
  return (
    <div className="App">
      <main>
        <ErrorPage />
      </main>
    </div>
  );
}

function SalesPredictionMain({
  selectedRestaurant,
  setSelectedRestaurant,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setSelectedPage,
  selectedPage,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <SalesPrediction
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}

function RestaurantMain() {
  return (
    <div className="App">
      <main>
        <MyRestaurant />
      </main>
    </div>
  );
}

// function RestaurantPage() {
//   return (
//     <div className="App">
//       <main>
//         <Restaurants />
//       </main>
//     </div>
//   );
// }
function PasswordForgot() {
  return (
    <div className="App">
      <main>
        <ForgotPassword />
      </main>
    </div>
  );
}
function PasswordChange() {
  return (
    <div className="App">
      <main>
        <ChangePassword />
      </main>
    </div>
  );
}

// function ContactUs() {
//   return (
//     <div className="App">
//       <main>
//         <Contact />
//       </main>
//     </div>
//   );
// }

function AdminPage() {
  return (
    <div className="App">
      <main>
        <Admin />
      </main>
    </div>
  );
}
function MarketingCampaignPage() {
  return (
    <div className="App">
      <main>
        <MarketingCampaign />
      </main>
    </div>
  );
}
function LandingNewPage() {
  return (
    <div className="App">
      <main>
        <Landingv2 />
      </main>
    </div>
  );
}

function NewAboutPage() {
  return (
    <div className="App">
      <main>
        <AboutUs />
      </main>
    </div>
  );
}
function FeaturesPage() {
  return (
    <div className="App">
      <main>
        <Features />
      </main>
    </div>
  );
}
const EmployeeCreatePage = () => {
  return (
    <div className="App">
      <main>
        <EmployeeCreate />
      </main>
    </div>
  );
};
const EmployeeListPage = () => {
  return (
    <div className="App">
      <main>
        <EmployeeList />
      </main>
    </div>
  );
};
function FAQPage() {
  return (
    <div className="App">
      <main>
        <FAQ />
      </main>
    </div>
  );
}
function NewContactPage() {
  return (
    <div className="App">
      <main>
        <ContactUs />
      </main>
    </div>
  );
}

function NewLayoutPage({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <NewProfitLoss
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dataRange={dataRange}
          setDataRange={setDataRange}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          hasPredictionData={hasPredictionData}
          setHasPredictionData={setHasPredictionData}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}

function CompanyStaffPage({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <NewSuperDashboard
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dataRange={dataRange}
          setDataRange={setDataRange}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          hasPredictionData={hasPredictionData}
          setHasPredictionData={setHasPredictionData}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}

// function IndividualStaffPage({
//   startDate,
//   endDate,
//   setStartDate,
//   setEndDate,
//   dataRange,
//   setDataRange,
//   selectedPage,
//   setSelectedPage,
//   selectedRestaurant,
//   setSelectedRestaurant,
//   restaurant,
//   setRestaurant,
//   hasPredictionData,
//   setHasPredictionData,
// }) {
//   return (
//     <div className="App">
//       <main>
//         <NewDashboard
//           startDate={startDate}
//           endDate={endDate}
//           setStartDate={setStartDate}
//           setEndDate={setEndDate}
//           dataRange={dataRange}
//           setDataRange={setDataRange}
//           selectedPage={selectedPage}
//           setSelectedPage={setSelectedPage}
//           selectedRestaurant={selectedRestaurant}
//           setSelectedRestaurant={setSelectedRestaurant}
//           restaurant={restaurant}
//           setRestaurant={setRestaurant}
//           hasPredictionData={hasPredictionData}
//           setHasPredictionData={setHasPredictionData}
//         />
//       </main>
//     </div>
//   );
// }

function IndividualProductPage({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <NewDashboardTwo
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dataRange={dataRange}
          setDataRange={setDataRange}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          hasPredictionData={hasPredictionData}
          setHasPredictionData={setHasPredictionData}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}

function CompanyProductPage({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <NewSuperDashboardTwo
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dataRange={dataRange}
          setDataRange={setDataRange}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          hasPredictionData={hasPredictionData}
          setHasPredictionData={setHasPredictionData}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}

function ComparisonsPage({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <Comparisons
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dataRange={dataRange}
          setDataRange={setDataRange}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          hasPredictionData={hasPredictionData}
          setHasPredictionData={setHasPredictionData}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}

function ProfilePage() {
  return (
    <div className="App">
      <main>
        <Profile />
      </main>
    </div>
  );
}

function NewRecommendationsPage({ selectedRestaurant, setSelectedRestaurant }) {
  return (
    <div className="App">
      <main>
        <NewRecommendations
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
        />
      </main>
    </div>
  );
}

function CreateEvent() {
  return (
    <div className="App">
      <main>
        <Event />
      </main>
    </div>
  );
}
function DownloadData() {
  return (
    <div className="App">
      <main>
        <DownloadDataPage />
      </main>
    </div>
  );
}
function TrendsPage({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <Trends
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dataRange={dataRange}
          setDataRange={setDataRange}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          hasPredictionData={hasPredictionData}
          setHasPredictionData={setHasPredictionData}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}
// function CompanyPage() {
//   return (
//     <div className="App">
//       <main>
//         <Company />
//       </main>
//     </div>
//   );
// }

// function ResourcesPage() {
//   return (
//     <div className="App">
//       <main>
//         <Blogs />
//       </main>
//     </div>
//   );
// }

// function PageError() {
//   return (
//     <div className="App">
//       <main>
//         <ErrorPage />
//       </main>
//     </div>
//   );
// }

// function PageMaintenance() {
//   return (
//     <div className="App">
//       <main>
//         <MaintenancePage />
//       </main>
//     </div>
//   );
// }
const SuperAuthenticatedRoutes = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurants,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <NewLayoutPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            restaurant={restaurants}
            setRestaurant={setRestaurant}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route
        path="/salesprediction"
        element={
          <SalesPredictionMain
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route
        path="/profitloss"
        element={
          <NewLayoutPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            restaurant={restaurants}
            setRestaurant={setRestaurant}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route
        path="/comparisons"
        element={
          <ComparisonsPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            restaurant={restaurants}
            setRestaurant={setRestaurant}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route path="/recommendations" element={<NewRecommendationsPage />} />
      {/* <Route path="/salesprediction" element={<Main />} /> */}
      <Route path="/myrestaurant" element={<RestaurantMain />} />
      <Route path="/forget_password" element={<PasswordForgot />} />
      <Route path="/change_password" element={<PasswordChange />} />
      {/* <Route path="/restaurants" element={<RestaurantPage />} /> */}
      {/* <Route path="/contact" element={<ContactUs />} /> */}
      <Route path="/admin" element={<AdminPage />} />
      <Route path="/marketingcampaign" element={<MarketingCampaignPage />} />
      <Route path="/event" element={<Event />} />
      <Route path="/newlandingpage" element={<LandingNewPage />} />
      <Route path="/aboutus" element={<NewAboutPage />} />
      <Route path="/features" element={<FeaturesPage />} />
      <Route path="/newcontact" element={<ContactUs />} />
      <Route path="/employee_create" element={<EmployeeCreatePage />} />
      {/* <Route path="/employee_edit" element={<EmployeeCreatePage />} /> */}
      <Route path="/employee_create/:id" element={<EmployeeCreatePage />} />
      <Route path="/employee_list" element={<EmployeeListPage />} />
      <Route path="/FAQ" element={<FAQPage />} />
      <Route path="/contact" element={<NewContactPage />} />
      <Route path="/newpage" element={<NewLayoutPage />} />
      <Route path="/getstarted" element={<GetStarted />} />
      <Route path="/supportus" element={<SupportUs />} />

      {/* <Route path="/profile_page" element={<ProfilePicPage />} /> */}
      <Route
        path="/newsuperdashboard"
        element={
          <CompanyStaffPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            restaurant={restaurants}
            setRestaurant={setRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      {/* <Route
        path="/newdashboard"
        element={
          <IndividualStaffPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            restaurant={restaurants}
            setRestaurant={setRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
          />
        }
      /> */}
      <Route
        path="/newdashboardtwo"
        element={
          <IndividualProductPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            restaurant={restaurants}
            setRestaurant={setRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route
        path="/newsuperdashboardtwo"
        element={
          <CompanyProductPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            restaurant={restaurants}
            setRestaurant={setRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/event" element={<CreateEvent />} />
      <Route
        path="/trends"
        element={
          <TrendsPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            restaurant={restaurants}
            setRestaurant={setRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      {/* <Route path="/company" element={<CompanyPage />}/> */}
      <Route path="/downloaddata" element={<DownloadData />} />

      <Route path="*" element={<ErrorRoute />} />
      {/* <Route path="/resources" element={<ResourcesPage />} /> */}
      {/* <Route path="/error" element={<PageError />} /> */}
      {/* <Route path="/maintenance" element={<PageMaintenance />} /> */}
    </Routes>
  );
};

export default SuperAuthenticatedRoutes;
