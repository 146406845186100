import React, { useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { getCompanyColors } from "../CompanyUtils/CompanyColor";
import { getLineTickValues } from "../../utils/GetLineTickValues";
import dayjs from "dayjs";
import formatKpiNumber from "../../utils/formatKpiNumber";
import formatNumber from "../../utils/FormatNumber";
const company = localStorage.getItem("company");

const LineChart = ({
  data,
  startDate,
  endDate,
  isDetailedChart,
  isCombinedChart = false,
  isStaffChart = false,
  isNotPercentage = false,
  isSecondaryChart = false,
  secondaryData = null,
  range = null,
  isXSScreen = false,
  isMinimal = false,
  hasPredictionLine = false,
  predictionLineDate = null,
  isMinNonZero = false,
  isCurrency = false,
  noPrefix = false,
  hasStaffMarker = false,
  isSalesPrediction = false,
  useKpiFormat = false,
  isOverlapChart = false,
  isSickCost = false,
  Latest = null,
  nullPredictions = false,
  hasPredictionData = false,
}) => {
  let secondVar;
  const [hiddenIds, setHiddenIds] = useState([]);

  function isBefore(date1, date2) {
    const dateOnly1 = new Date(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate()
    );
    const dateOnly2 = new Date(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate()
    );
    return dateOnly1 < dateOnly2;
  }

  const LatestChecker = new Date(Latest);
  const dateEnd = new Date(endDate);

  const nullPast = isBefore(dateEnd, LatestChecker);

  function nullMatchingPredictions(data, nullPast, nullPredictions) {
    // Early return if data is not defined or is empty
    if (!data || data.length === 0) {
      return;
    }

    // Check for hasPredictionData flag if it's relevant (assuming it's a global or accessible variable)
    if (
      typeof hasPredictionData !== "undefined" &&
      hasPredictionData === false
    ) {
      return;
    }

    if (!nullPast && nullPredictions) {
      // Extract actual and prediction data
      const actualDataObject = data?.find((d) => d.id === "actual");
      const predictionDataObject = data?.find((d) => d.id === "prediction");

      // Check if actualDataObject and predictionDataObject exist and have data property
      if (
        !actualDataObject ||
        !predictionDataObject ||
        !actualDataObject.data ||
        !predictionDataObject.data
      ) {
        return;
      }

      const actualData = actualDataObject.data;
      const predictionData = predictionDataObject.data;

      // Convert the actual data array to an object for easy lookup
      const actualMap = actualData.reduce((acc, item) => {
        acc[item.x] = item.y;
        return acc;
      }, {});

      // Iterate over the prediction data and null the y values that match the actual y values
      predictionData.forEach((item) => {
        if (actualMap[item.x] === item.y) {
          item.y = null;
        }
      });
    }

    return data;
  }

  let processedData = nullMatchingPredictions(data, nullPast, nullPredictions);

  const format = !isNotPercentage
    ? (v) => `${v}%`
    : isCurrency
    ? (v) => `${v} NOK`
    : (v) => `${v} units`;

  // Handle different formats for data
  // let processedData = [];
  if (Array.isArray(data)) {
    processedData = data.map((series) => {
      return {
        ...series,
        data: series.data.map((point) => {
          return {
            ...point,
            y: isNaN(point.y) ? 0 : point.y,
          };
        }),
      };
    });
  } else if (typeof data === "object" && data !== null) {
    processedData = Object.keys(data).map((key) => {
      return {
        id: key,
        data: data[key].data.map((point) => {
          return {
            ...point,
            y: isNaN(point.y) ? 0 : point.y,
          };
        }),
      };
    });
  }

  let filteredData = processedData.filter(
    (serie) => !hiddenIds.includes(serie.id)
  );
  const goalPercentage =
    company === "Fisketorget" ? 32 : company === "Burgerheim" ? 20 : 28;

  const { tickValuesI, tickValuesO } = getLineTickValues(processedData);

  let minYValue;
  let maxYValue;
  if (processedData.length > 0) {
    const allYValues = processedData.flatMap((series) =>
      series.data.map((point) => point.y).filter((y) => !isNaN(y))
    );

    if (allYValues.length > 0) {
      minYValue = Math.min(...allYValues);
      maxYValue = Math.max(...allYValues);
      if (minYValue === maxYValue) {
        minYValue = 0;
      }
    } else {
      minYValue = 0;
      maxYValue = 0;
    }
  } else {
    minYValue = 0;
    maxYValue = 0;
  }

  minYValue = minYValue.toFixed(0);
  maxYValue = maxYValue.toFixed(0);

  if (maxYValue < 10) {
  } else if (isNotPercentage) {
    maxYValue =
      maxYValue < 1000
        ? Math.round(maxYValue / 100) * 100
        : maxYValue < 9000
        ? Math.round(maxYValue / 1000) * 1000
        : Math.round(maxYValue / 10000) * 10000;
  } else {
    maxYValue = Math.round(maxYValue / 10) * 10;
  }

  let staffMarkerDate;
  if (hasStaffMarker && Latest) {
    const latestCreatedAt = dayjs(Latest);
    const tickValues = getLineTickValues(processedData);
    const firstTickValue = dayjs(tickValues.tickValuesI[0]);
    const lastTickValue = dayjs(
      tickValues.tickValuesO[tickValues.tickValuesO.length - 1]
    );
    if (
      latestCreatedAt.isAfter(firstTickValue) &&
      latestCreatedAt.isBefore(lastTickValue)
    ) {
      const modifiedDate = latestCreatedAt.add(14, "day");
      staffMarkerDate = modifiedDate.toDate();
      staffMarkerDate.setHours(0, 0, 0, 0);
    }
  }

  let predictionLineMarkerDate;
  if (hasPredictionLine && Latest) {
    predictionLineMarkerDate = new Date(Latest);
    predictionLineMarkerDate?.setHours(0, 0, 0, 0);
  }
  // Check if the sum of y values is 0
  const isDataZero = processedData.every((series) =>
    series.data.every((point) => point.y === 0)
  );

  const pointTooltip = ({ point }) => {
    const roundToNearest500 = (value) => {
      if (value < 100) {
        return Math.round(value / 10) * 10;
      } else if (value < 500) {
        return Math.round(value / 100) * 100;
      } else {
        return Math.round(value / 500) * 500;
      }
    };

    const date = new Date(point.data.x);
    let formattedDate;

    if (range === "month") {
      formattedDate = date.toLocaleDateString(undefined, {
        month: "long",
        year: "numeric",
      });
    } else if (range === "week") {
      const sunday = new Date(date);
      sunday.setDate(sunday.getDate() - sunday.getDay());

      const startOfYear = new Date(sunday.getFullYear(), 0, 1);
      const dayDifference = (sunday - startOfYear) / (24 * 60 * 60 * 1000);
      const weekNumber =
        1 + Math.floor((dayDifference + startOfYear.getDay() + 5) / 7);

      formattedDate = `${date.toLocaleDateString(undefined, {
        month: "long",
      })} (Week ${weekNumber}) ${sunday.getFullYear()}`;
    } else {
      formattedDate = date.toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }

    let isStaffMarkerDate =
      hasStaffMarker &&
      new Date(point.data.x)?.setHours(0, 0, 0, 0) ===
        staffMarkerDate?.setHours(0, 0, 0, 0);

    // let tooltipTitle;
    // if (isOverlapChart && pointDate <= latestCreatedAtDate) {
    //   tooltipTitle = "Historical";
    // } else {
    //   tooltipTitle = "Prediction";
    // }

    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          borderRadius: "5px",
        }}
      >
        <strong>{formattedDate}</strong>
        <br />
        {point.data.comment && (
          <strong style={{ color: "black", marginBottom: "10px" }}>
            Comment: {point.data.comment}
            <br />
          </strong>
        )}
        {isStaffMarkerDate && (
          <strong
            style={{ marginTop: "10px", display: "block", color: "#D2042D" }}
          >
            Planned staff cost ends here
            <br />
          </strong>
        )}
        {point.serieId === "Gross Profit II" && (
          <>
            <strong style={{ color: "gray", marginBottom: "5px" }}>
              Employee Cost:{" "}
              {isNotPercentage
                ? formatKpiNumber(roundToNearest500(point.data.emp_cost))
                : point.data.emp_cost}
              <br />
              Third Party Delivery Cost:{" "}
              {isNotPercentage
                ? formatKpiNumber(roundToNearest500(point.data.delivery_cost))
                : point.data.delivery_cost}
              <br />
            </strong>
            <strong style={{ color: point.serieColor }}>
              Gross Profit II:{" "}
              {isNotPercentage
                ? formatKpiNumber(Math.round(point.data.y))
                : `${point.data.y}`}
            </strong>
            <br />
          </>
        )}
        {point.serieId === "Gross Profit I" && (
          <>
            <strong style={{ color: "gray" }}>
              Cost of Goods:{" "}
              {isNotPercentage
                ? formatKpiNumber(roundToNearest500(point.data.cost))
                : point.data.cost}
              <br />
            </strong>
            <strong style={{ color: point.serieColor }}>
              Gross Profit I:{" "}
              {isNotPercentage
                ? formatKpiNumber(Math.round(point.data.y))
                : `${point.data.y}`}
            </strong>
            <br />
          </>
        )}
        {point.serieId !== "Gross Profit II" &&
          point.serieId !== "Gross Profit I" && (
            <strong
              style={{
                color:
                  // tooltipTitle === "Historical" ? "#543c8c" :
                  point.serieColor,
                marginTop: "10px",
              }}
            >
              {!isOverlapChart ? `${point.serieId}: ` : ""}
              {isNotPercentage
                ? useKpiFormat
                  ? formatNumber(Math.round(point.data.y))
                  : isOverlapChart
                  ? `${formatNumber(Math.round(point.data.y))} ${point.serieId}`
                  : formatKpiNumber(Math.round(point.data.y))
                : isOverlapChart
                ? `${point.data.y}% ${point.serieId}`
                : `${point.data.y}%`}
            </strong>
          )}
      </div>
    );
  };

  const axisProps = !isSecondaryChart
    ? {
        axisLeft: {
          format,
          tickValues: [minYValue, maxYValue],
          legendOffset: 12,
        },
        colors: getCompanyColors(),
        tooltip: pointTooltip,
      }
    : {
        colors: "#4169E1",
        axisRight: {
          format,
          legendOffset: 12,
        },
        sliceTooltip: ({ slice }) => {
          const currentDate = new Date(slice.points[0].data.x);
          let formattedDate;

          if (range === "month") {
            formattedDate = currentDate.toLocaleDateString(undefined, {
              month: "long",
              year: "numeric",
            });
          } else if (range === "week") {
            const sunday = new Date(currentDate);
            sunday.setDate(sunday.getDate() - sunday.getDay());

            const startOfYear = new Date(sunday.getFullYear(), 0, 1);
            const dayDifference =
              (sunday - startOfYear) / (24 * 60 * 60 * 1000);
            const weekNumber =
              1 + Math.floor((dayDifference + startOfYear.getDay() + 5) / 7);
            formattedDate = `${currentDate.toLocaleDateString(undefined, {
              month: "long",
            })} (Week ${weekNumber}) ${sunday.getFullYear()}`;
          } else {
            formattedDate = currentDate.toLocaleDateString(undefined, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }

          const matchedData = secondVar[0].data.find(
            (d) =>
              d.x.toISOString().split("T")[0] ===
              currentDate.toISOString().split("T")[0]
          );

          const secondaryYValue = matchedData ? matchedData.y : null;

          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              <strong>{formattedDate}</strong>
              {slice.points.map((point, index) => (
                <div
                  key={index}
                  style={{
                    color: "#4169E1",
                    padding: "3px 0",
                  }}
                >
                  <strong>{point.serieId}:</strong>{" "}
                  {parseInt(point.data.yFormatted)}
                  {secondaryYValue && (
                    <div
                      style={{
                        color: "#E63946",
                        padding: "3px 0",
                      }}
                    >
                      <strong>Staff Cost Percentage:</strong> {secondaryYValue}%
                    </div>
                  )}
                </div>
              ))}
            </div>
          );
        },
      };

  return !processedData || processedData.length === 0 || isDataZero ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        // border: "solid red 1px",
      }}
    >
      <h2>No data</h2>
    </div>
  ) : (
    <ResponsiveLine
      animate
      enableGridX={isMinimal || isSickCost ? false : !isSecondaryChart}
      enableGridY={isMinimal ? true : !isSecondaryChart}
      gridYValues={[0, minYValue, maxYValue]}
      axisBottom={{
        format: (value) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString(undefined, {
            month: "short",
            day: "numeric",
          });
          return formattedDate;
        },
        legendOffset: -12,
        tickValues: isXSScreen ? tickValuesO : tickValuesI,
      }}
      {...axisProps}
      axisLeft={
        !isSecondaryChart
          ? {
              tickValues: [0, minYValue, isStaffChart ? 70 : maxYValue].filter(
                (v, i, a) => a.indexOf(v) === i
              ),
              format: noPrefix ? undefined : (v) => format(v),
              legendOffset: -40,
            }
          : null
      }
      curve="monotoneX"
      data={filteredData}
      margin={
        isMinimal
          ? {
              bottom: 50,
              left: noPrefix ? 50 : maxYValue > 100 ? 80 : 80,
              right: 50,
              top: 35,
            }
          : isStaffChart
          ? {
              bottom: 60,
              left: 80,
              right: 90,
              top: 30,
            }
          : isSickCost
          ? {
              bottom: 50,
              left: 80,
              right: 40,
              top: 30,
            }
          : {
              bottom: 60,
              left: 80,
              right: 40,
              top: 50,
            }
      }
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      lineWidth={1}
      markers={[
        ...(isStaffChart && !isSecondaryChart
          ? [
              {
                axis: "y",
                value: goalPercentage,
                legend: `Goal ${goalPercentage}%`,
                lineStyle: {
                  stroke: "gray",
                  strokeWidth: 2,
                },
              },
            ]
          : []),
        hasPredictionLine
          ? {
              axis: "x",
              value: predictionLineMarkerDate,
              lineStyle: {
                stroke: "#505050",
                strokeWidth: 2,
              },
              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
        hasStaffMarker
          ? {
              axis: "x",
              value: staffMarkerDate,
              lineStyle: {
                stroke: "green",
                strokeWidth: 2,
              },
              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
      ]}
      pointBorderWidth={1}
      pointSize={4}
      enableArea={true}
      areaBaselineValue={isMinNonZero ? 0 : 0}
      areaOpacity={0.15}
      useMesh
      xFormat="time:%Y-%m-%d"
      xScale={{
        format: "%Y-%m-%d",
        precision: "day",
        type: "time",
        useUTC: true,
      }}
      yScale={{
        type: "linear",
        min: minYValue < 0 ? minYValue : 0,
        max: isStaffChart ? 70 : "auto",
      }}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: isSecondaryChart ? 150 : 2,
          translateY: 50,
          itemsSpacing: 10,
          itemDirection: "left-to-right",
          itemWidth: 109,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
          onClick: (datum) => {
            setHiddenIds((currentHiddenIds) => {
              const isCurrentlyHidden = currentHiddenIds.includes(datum.id);
              const newHiddenIds = isCurrentlyHidden
                ? currentHiddenIds.filter((id) => id !== datum.id)
                : [...currentHiddenIds, datum.id];

              if (newHiddenIds.length >= processedData.length) {
                return [];
              }
              return newHiddenIds;
            });
          },
        },
      ]}
    />
  );
};

export default LineChart;
