import React from "react";
import {
  Box,
  Grid,
  // IconButton,
  Typography,
  Button,

  // IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: "Lexend, sans-serif",
  },
});

const AboutUs = () => {
  const navigate = useNavigate();

  const handleNewContact = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box // Main Parent Container
        sx={{
          // mt:"50px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignContent: "center",
          //border: "1px solid red",
          boxSizing: "//border-box",
          alignItems: "center",
          background:
            "linear-gradient(to right, #FFEB49 0%, #FFEB49 50%, white 50%, white 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            //border: "solid 1px green",
            width: "100%",
            boxSizing: "//border-box",
            alignItems: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              //border: "solid 1px purple",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "150vh" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: { xs: 1300, xl: 1600 },
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column" },
                }}
              >
                <Grid
                  item
                  lg={4}
                  xs={12}
                  sx={{
                    //border: "solid 1px blue",
                    display: "flex",
                    justifyContent: { lg: "center", xs: "center" },
                    alignItems: { lg: "center", xs: "center" },
                    flexDirection: { xs: "column", lg: "row" },
                    background: "#FFEB49",
                    minHeight: { lg: "100%", xs: "60vh" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      //border: "solid 1px red",
                      height: { lg: "40%", xs: "70%" },
                      width: "80%",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: { xs: "30px", xl: "40px" },
                        wordWrap: "break-word", // This makes it break
                        hyphens: "auto", // This adds hyphens if needed and supported
                      }}
                    >
                      Bli kjent med oss
                    </Typography>

                    <Typography
                      color={"#4A4C50"}
                      sx={{
                        textAlign: "left",
                        fontSize: { xs: "14px", xl: "20px" },
                      }}
                    >
                      Team Krunch consists of two passionate entrepreneurs and a
                      dedicated team of developers. They are working tirelessly
                      to create an innovative and impressive application
                      designed specifically for the restaurant business.
                      Recognizing the unique challenges this industry faces,
                      their mission is to provide a comprehensive solution that
                      streamlines operations, improves efficiency, and
                      ultimately, drives growth. Their vision is to
                      revolutionize the way the restaurant business operates and
                      contribute to its success in an increasingly digital
                      world.
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={handleNewContact}
                        sx={{
                          width: { xs: "13ch", xl: "16ch" },
                          background: "#FFBD00",
                          color: "black",
                          "&:hover": {
                            backgroundColor: "#e3a902", // You can adjust this color based on your preference
                            // Prevent MUI Button from changing the color on hover
                            boxShadow: "none",
                          },
                          "&:active": {
                            backgroundColor: "#e3a902", // Adjust this color based on your preference
                          },
                          fontWeight: 700,
                          // borderRadius: "5px",
                          fontSize: { xs: "14px", xl: "16px" },
                        }}
                      >
                        Start nå
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={8}
                  xs={12}
                  sx={{
                    display: { lg: "flex", xs: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                    //border: "solid 1px blue",
                    flexDirection: "column",
                    minHeight: { lg: "100%", xs: "200vh" },
                    background: "#FFF",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "10%",
                      width: "70%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        textAlign: "left",
                        fontSize: { xs: "30px", xl: "40px" },
                        wordWrap: "break-word", // This makes it break
                        hyphens: "auto", // This adds hyphens if needed and supported
                      }}
                    >
                      Kontakt oss for å komme i gang
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      // border:"1px solid green",
                      display: "flex",
                      flexDirection: "column",
                      height: { xs: "80%", xl: "85%" },
                      width: "85%",
                      background: "#FFF4BF",
                      borderRadius: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)", // Add this line
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        height: "50%",
                        width: "90%",
                        flexDirection: { lg: "row", xs: "column" },
                        // border: "solid 1px red",
                        justifyContent: { xs: "space-evenly" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          heigth: "100%",
                          width: { lg: "50%", xs: "100%" },
                          flexDirection: { lg: "column", xs: "column" },
                          //border: "solid 1px blue",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            height: "150px",
                            width: "105px",
                          }}
                        >
                          <img
                            src="kristian_contactpage.png"
                            alt="Profile pic"
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "5px",
                              boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)", // Add this line
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 700,
                            fontSize: { xs: "20px", xl: "25px" },
                          }}
                        >
                          Kristian Fauske, CEO
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: { xs: "12px", xl: "17px" },
                            color: "#4A4C50",
                          }}
                        >
                          Kristian er siv.øk fra NHH med erfaring fra både
                          restaurantbransjen og teknologi. Han er tidligere
                          manager for Innovation & Tecnology i Bring Cargo AS i
                          tillegg til andre lederroller i norsk næringsliv.
                        </Typography>
                      </Box>
                      {/*}
                      <Box
                        sx={{
                          display: "flex",
                          heigth: "100%",
                          width: { lg: "50%", xs: "100%" },
                          flexDirection: { lg: "column", xs: "column" },
                          //border: "solid 1px blue",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            height: "150px",
                            width: "105px",
                          }}
                        >
                          <img
                            src="ramtin_contactpage.png"
                            alt="profile pic"
                            style={{
                              height: "100%",
                              width: "100%",
                              //borderRadius: "30px",
                            }}
                          />
                        </Box>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                          Ramtin Jafarzadeh, CCO
                        </Typography>
                        <Typography
                          color={"#4A4C50"}
                          sx={{
                            textAlign: "center",
                            fontSize: "12px",
                          }}
                        >
                          Ramtin elsker å være i kontakt med kunder og sørge for
                          at de får en best mulig opplevelse. Han har en egen
                          evne til å forstå andres behov og tankemønstre, og er
                          den perfekte personen til på lede Krunch sitt
                          kommersielle arbeid
                        </Typography>
                      </Box>
                      */}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        height: "50%",
                        width: "90%",
                        flexDirection: { lg: "row", xs: "column" },
                        // border: "solid 1px red",
                        justifyContent: {
                          xs: "space-evenly",
                          xl: "flex-start",
                        },
                        alignItems: { xl: "flex-start", xs: "none" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          heigth: "100%",
                          width: { lg: "50%", xs: "100%" },
                          flexDirection: { lg: "column", xs: "column" },
                          // border: "solid 1px blue",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            height: "150px",
                            width: "105px",
                          }}
                        >
                          <img
                            src="animesh_contactpage.jpeg"
                            alt="profile pic"
                            style={{
                              height: "auto",
                              width: "100%",
                              borderRadius: "5px",
                              boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)", // Add this line
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 700,
                            textAlign: "center",
                            fontSize: { xs: "20px", xl: "25px" },
                          }}
                        >
                          Animesh Adhikari, Full-Stack Developer
                        </Typography>
                        <Typography
                          color={"#4A4C50"}
                          sx={{
                            textAlign: "center",
                            fontSize: { xs: "12px", xl: "17px" },
                            width: { xs: "100%", xl: "90%" },
                            color: "#4A4C50",
                          }}
                        >
                          Animesh er en full-stack utvikler med bachelor i data
                          science fra University of Pokahara I Nepal. Han evner
                          å forstå komplisert logikk og systemer og elsker
                          utfordrende tekniske problemstillinger. I tillegg har
                          han alltid en vits og et smil på lur
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          heigth: "100%",
                          width: { lg: "50%", xs: "100%" },
                          flexDirection: { lg: "column", xs: "column" },
                          //border: "solid 1px blue",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            height: "150px",
                            width: "105px",
                          }}
                        >
                          <img
                            src="sakar_contactpage.jpeg"
                            alt="profile pic"
                            style={{
                              height: "auto",
                              width: "100%",
                              borderRadius: "5px",
                              boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)", // Add this line
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 700,
                            textAlign: "center",
                            fontSize: { xs: "20px", xl: "25px" },
                          }}
                        >
                          Sakar Sedhai, Frontend Developer
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: { xs: "12px", xl: "17px" },
                            width: { xs: "100%", xl: "90%" },
                            color: "#4A4C50",
                          }}
                        >
                          Sakar er vår front-end utvikler med bachelor i
                          Computing fra London Metropolitan University of London
                          i UK. Sakar er både teknisk begavet on kreativ, en
                          uslåelig kombinasjon når man jobber med alt kunden ser
                          digitalt. Han er en lagspiller som alltid er klar for
                          å hjelpe de andre på teamet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AboutUs;
