import React, { useState, useEffect, useCallback } from "react";
import {
  //   Grid,
  //    Container,
  // Card,
  Typography,
  // TextField,
  // Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // CardContent,
  //   Tooltip,
  //   Zoom,
  //   useMediaQuery,
  //   Tooltip,
  //   Zoom,
  //   useMediaQuery,
  // Collapse,
  //   OutlinedInput,
  // Checkbox,
  ListItemText,
  Box,

  // Box,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SnackbarAlert from "../utils/SnackbarAlert";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import DownloadRawSalesData from "../utils/ExcelDownloads/DownloadRawSalesData";
import LinearBuffer from "../utils/LinearBuffer";
import StyledButton from "../components/Style/ButtonStyle";
let prefix = "";

if (process.env.NODE_ENV === "development") {
  prefix = "http://127.0.0.1:8000";
}
const company = localStorage.getItem("company");
const token = localStorage.getItem("token");
// const default_restaurant = JSON.parse(
//   localStorage.getItem("user")
// )?.restaurant_name;

// const ITEM_HEIGHT = "1rem";
// const ITEM_PADDING_TOP = 8;

const DemoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
}));

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  // getMonth() returns 0-11, so add 1 to get 1-12. Pad the result with '0' if necessary.
  const month = String(date.getMonth() + 1).padStart(2, "0");
  // getDate() returns the day of the month. Pad it with '0' if necessary.
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const DownloadDataPage = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taskStatus, setTaskStatus] = useState("");
  const [taskMessage, setTaskMessage] = useState("");

  const handleRestaurantsChange = (event) => {
    const value = event.target.value;

    if (Array.isArray(value)) {
      const selectedRestaurants = restaurants.filter((restaurant) =>
        value.includes(restaurant.id)
      );
      setSelectedRestaurants(selectedRestaurants);
    } else {
      const selectedRestaurant = restaurants.find(
        (restaurant) => restaurant.id === value
      );
      setSelectedRestaurants(selectedRestaurant ? [selectedRestaurant] : []);
    }
  };
  const fetchRestaurants = useCallback(async () => {
    const restaurant_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restaurant_response.ok) {
      const restaurantData = await restaurant_response.json();
      // Sort restaurants, placing those with 'Food Truck' at the end
      const sortedRestaurants = restaurantData.results.sort((a, b) => {
        const isAFoodTruck = a.name.includes("Food Truck");
        const isBFoodTruck = b.name.includes("Food Truck");
        if (isAFoodTruck && !isBFoodTruck) {
          return 1; // place 'a' after 'b'
        } else if (!isAFoodTruck && isBFoodTruck) {
          return -1; // place 'a' before 'b'
        }
        return 0; // maintain order if both are the same type
      });
      setRestaurants(sortedRestaurants);
    } else {
      console.error("Error fetching restaurants");
    }
  }, []);

  const handleTaskCompletion = (
    statusData,
    formattedStartDate,
    formattedEndDate,
    restaurantSpecificNames
  ) => {
    setTaskStatus(statusData.status);
    if (statusData.status === "SUCCESS") {
      if (statusData.result && statusData.result.length > 0) {
        DownloadRawSalesData(
          statusData.result,
          `Sales Report ${restaurantSpecificNames}:${formattedStartDate} to ${formattedEndDate}`
        );
        setTaskMessage("Data downloaded successfully.");
        setSnackbarMessage("Successfully downloaded data");
        setSnackbarSeverity("success");
      } else {
        setTaskMessage("No data to download.");
        setSnackbarMessage("No data available for the selected criteria.");
        setSnackbarSeverity("info");
      }
    } else if (statusData.status === "FAILURE") {
      setTaskMessage("Failed to download data: " + statusData.error);
      setSnackbarMessage("Failed to download data: " + statusData.error);
      setSnackbarSeverity("error");
    }
    setSnackbarOpen(true);
  };
  const pollTaskStatus = async (
    taskId,
    formattedStartDate,
    formattedEndDate
  ) => {
    const interval = setInterval(async () => {
      const restaurantSpecificNames = selectedRestaurants.map(
        (restro) => restro.name
      );
      try {
        const statusResponse = await fetch(
          `${prefix}/api/salesdata/get_raw_data/?task_id=${taskId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        const statusData = await statusResponse.json();

        switch (statusData.status) {
          case "SUCCESS":
            clearInterval(interval);
            setLoading(false);
            setTaskStatus("SUCCESS");
            if (statusData.result && statusData.result.length > 0) {
              DownloadRawSalesData(
                statusData.result,
                `Sales Report ${restaurantSpecificNames}:${formattedStartDate} to ${formattedEndDate}`
              );
              setTaskMessage("Data downloaded successfully.");
              setSnackbarMessage("Successfully downloaded data");
              setSnackbarSeverity("success");
            } else {
              setTaskMessage("No data to download.");
              setSnackbarMessage(
                "No data available for the selected criteria."
              );
              setSnackbarSeverity("info");
            }
            setSnackbarOpen(true);
            break;
          case "FAILURE":
            clearInterval(interval);
            setLoading(false);
            handleTaskCompletion(
              statusData,
              formattedStartDate,
              formattedEndDate,
              restaurantSpecificNames
            );
            setTaskStatus("FAILURE");
            setTaskMessage("Failed to download data: " + statusData.error);
            setSnackbarMessage("Failed to download data: " + statusData.error);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            break;
          case "PENDING":
            setTaskStatus("PENDING");
            setTaskMessage("Data is being processed...");
            break;
          default:
            setTaskMessage("Waiting for task status...");
            break;
        }
      } catch (error) {
        clearInterval(interval);
        setLoading(false);
        setSnackbarMessage("Error while downloading data");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.error("Polling failed:", error);
      }
    }, 5000); // Poll every 5 seconds
  };

  const handleButtonClick = async () => {
    setLoading(true);
    try {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      const restaurantSpecificNames = selectedRestaurants.map(
        (restro) => restro.name
      );
      const raw_data_response = await fetch(
        `${prefix}/api/salesdata/get_raw_data/?company=${company}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&restaurant=${JSON.stringify(
          restaurantSpecificNames
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      const raw_data = await raw_data_response.json();

      if (raw_data_response.ok) {
        pollTaskStatus(raw_data.task_id, formattedStartDate, formattedEndDate); // Start polling
      } else {
        throw new Error(raw_data.error || "Failed to initiate data fetch");
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage(error.toString());
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      // } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const customTheme = createTheme({
    components: {
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          root: {
            transform: "translateX(-40px)",
            width: "70px",
            "&:hover": {
              width: "70px",
            },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit", // Reset the border color
            },
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          labelContainer: {
            padding: "0 10px", // Reduce padding, adjust as needed
            fontSize: "0.875rem", // Reduce font size, adjust as needed
          },
          switchViewButton: {
            width: "50px", // Replace 'initialWidth' with your normal width value
            "&:hover": {
              width: "50px", // Replace 'smallerHoverWidth' with your desired hover width value
            },
          },
        },
      },
    },
  });
  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        // border: "solid 1px red",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: { lg: 1300, xl: 1600 },
          alignItems: "center",
          // border: "solid 1px blue",
          boxSizing: "border-box",
          height: { lg: "100vh", xs: "100vh" },
        }}
      >
        <Box
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            // border: "solid 1px green",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              // border: "solid 1px green",
              justifyContent: "center",
              height: { lg: "10%", xs: "10%" },
              alignItems: "center",
              mt: { lg: 2, xs: 0 },
            }}
          ></Box>
          <DemoPaper
            elevation={6}
            sx={{
              width: { lg: "50%", xs: "80%" },
              height: { lg: "70%", xs: "60%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { lg: "100%", xs: "100%" },
                gap: 3,
                // border: "solid 1px red",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "10%",
                  justifyContent: "center",
                  alignItems: "center",
                  // border: "solid 1px red",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    mt: "1rem",
                    mb: "1rem",
                    fontSize: { lg: "2rem", xs: "1.5rem" },
                    fontFamily: "'Montserrat', sans-serif",
                  }}
                >
                  Download Data
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "50%",
                  flexDirection: "column",
                  // border: "solid red 1px",
                }}
              >
                <ThemeProvider theme={customTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{}}>
                      <FormControl
                        sx={{
                          display: "flex",
                          flexDirection: { lg: "row", xs: "column" },
                          justifyContent: "center",
                          alignItems: "center",
                          // border: "solid red 1px",
                        }}
                      >
                        <FormControl
                          lg={12}
                          md={12}
                          xs={12}
                          sx={{
                            display: "flex",
                            flexDirection: { lg: "row", xs: "row" },
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => {
                              setStartDate(newValue);
                            }}
                            format="DD/MM/YYYY"
                            sx={{
                              mb: "1rem",
                              mx: ".5rem",
                              background: "#F8F8F8",
                              width: "100%",
                            }}
                          />
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newValue) => {
                              setEndDate(newValue);
                            }}
                            format="DD/MM/YYYY"
                            sx={{
                              mb: "1rem",
                              mx: ".5rem",
                              background: "#F8F8F8",
                              width: "100%",
                            }}
                          />
                        </FormControl>
                      </FormControl>
                    </Box>
                  </LocalizationProvider>
                </ThemeProvider>
                <FormControl
                  sx={{
                    width: {
                      lg: "50%",
                      xs: "70%",
                    },
                    gap: 1,
                    mb: "1rem",
                    mt: "1rem",
                    // border: "solid red 1px",
                  }}
                >
                  <InputLabel id="restaurants-select-label">
                    Select Restaurants
                  </InputLabel>
                  <Select
                    labelId="restaurants-select-label"
                    id="restaurants-multi-select"
                    single
                    value={selectedRestaurants.map((r) => r.id)}
                    onChange={handleRestaurantsChange}
                    // renderValue={(selected) => selected.join(", ")}
                    renderValue={(selected) =>
                      selected.map(
                        (id) =>
                          restaurants.find((restaurant) => restaurant.id === id)
                            ?.name
                      )
                    }
                    label="Select Restaurant"
                  >
                    {restaurants?.map((restaurant) => (
                      <MenuItem key={restaurant.id} value={restaurant.id}>
                        {/* <Checkbox
                        checked={selectedRestaurants.some(
                          (r) => r.id === restaurant.id
                        )}
                      /> */}
                        <ListItemText primary={restaurant.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <StyledButton
                  onClick={handleButtonClick}
                  style={{ width: "20ch", mt: "1rem", mb: "2rem" }}
                  endIcon={<CloudDownloadIcon />}
                >
                  {" "}
                  {loading ? "Downloading..." : "Download"}
                </StyledButton>

                {/* Display LinearProgress when task is pending */}
                {taskStatus === "PENDING" && (
                  <LinearBuffer sx={{ width: "100%", mt: 2 }} />
                )}
                {/* Task Status Message */}
                {taskStatus && (
                  <Typography
                    color={
                      taskStatus === "SUCCESS"
                        ? "green"
                        : taskStatus === "FAILURE"
                        ? "red"
                        : "orange"
                    }
                    sx={{ mt: 2 }}
                  >
                    {taskMessage}
                  </Typography>
                )}
              </Box>
            </Box>
          </DemoPaper>
        </Box>
      </Box>

      <SnackbarAlert
        open={snackbarOpen}
        severity={snackbarSeverity}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default DownloadDataPage;
