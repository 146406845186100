import { React, useEffect, useState, useCallback } from "react";
import {
  Box,
  FormControl,
  Grid,
  Typography,
  TextField,
  Select,
  InputLabel,
  InputAdornment,
  MenuItem,
  Button,
  Checkbox,
  ListItemText,
  styled,
  // Menu,
  // TableHead,
  //Tooltip,
  //Zoom,
  // Card,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../utils/SnackbarAlert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import ChartLoading from "../components/Loading/ChartLoading";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Colors from "../utils/Colors";

const user = JSON.parse(localStorage.getItem("user"));
let prefix = "";

if (process.env.NODE_ENV === "development") {
  prefix = "http://127.0.0.1:8000";
}

const MarketingCampaign = () => {
  const company = localStorage.getItem("company");
  const token = localStorage.getItem("token");
  const [restaurants, setRestaurants] = useState([]);
  const navigate = useNavigate();
  const [restaurantNames, setRestaurantNames] = useState([]);

  const [campaignTypes, setCampaignTypes] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [campaignName, setCampaignName] = useState("");
  const [campaignImpact, setCampaignImpact] = useState("");

  const [campaignStartDate, setCampaignStartDate] = useState("");
  const [campaignEndDate, setCampaignEndDate] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCampaignTypeRows, setSelectedCampaignTypeRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  const [isCampaignVisible, setIsCampaignVisible] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const ITEM_HEIGHT = "1rem";
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [tableData, setTableData] = useState([]);

  const handleSelection = (selectionModel) => {
    setSelectedRows(selectionModel);
  };
  const handleSelectionCampaignType = (selectionModel) => {
    setSelectedCampaignTypeRows(selectionModel);
  };
  const campaignColumns = [
    { field: "name", headerName: "Campaign Type", width: 300 },
  ];

  const handleDelete = async () => {
    if (selectedRows.length === 0) {
      setOpenAlertDialog(true);
      return;
    }
    setOpenDeleteDialog(true);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const deleteSelectedRows = async () => {
    setOpenDeleteDialog(false);
    try {
      const response = await fetch(
        `${prefix}/api/marketing_campaigns/delete_marketing_campaigns/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify({ ids: selectedRows }),
        }
      );

      if (response.ok) {
        setOpen(true);
        setSeverity("success");
        setMessage("Campaign(s) Deleted Successfully");
        navigate(0); // Refresh data after deletion
      } else {
        setOpen(true);
        setSeverity("error");
        setMessage("Error Deleting Campaign(s)");
      }
    } catch (error) {
      console.error("Error:", error);
      setOpen(true);
      setSeverity("error");
      setMessage("An error occurred during deletion");
    }
  };

  const deleteSelectedRowsCampaignType = async () => {
    setOpenDeleteDialog(false);
    try {
      const response = await fetch(
        `${prefix}/api/marketing_campaign_type/delete_marketing_campaign_type/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify({ ids: selectedCampaignTypeRows }),
        }
      );
      if (response.ok) {
        setOpen(true);
        setSeverity("success");
        setMessage("Campaign Type(s) Deleted Successfully");
        navigate(0);
      } else {
        setOpen(true);
        setSeverity("error");
        setMessage("Error Deleting Campaign Type(s)");
      }
    } catch (error) {
      console.error("Error:", error);
      setOpen(true);
      setSeverity("error");
      setMessage("An error occurred during deletion");
    }
  };

  const columns = [
    {
      field: "campaign_type",
      headerName: "Campaigns",
      width: 200,
      // valueGetter: (params) => params.row.name,
    },

    {
      field: "restaurants",
      headerName: "Restaurants",
      width: 200,
    },
    { field: "startdate", headerName: "Start Date", width: 170 },
    { field: "enddate", headerName: "End Date", width: 170 },
    { field: "impact", headerName: "Impact", width: 168 },
  ];

  const StyledDataGrid = styled(DataGrid)({
    ".MuiDataGrid-columnHeader": {
      backgroundColor: Colors.krunchLight,
      color: "black",
      fontSize: "18px",
    },
    ".MuiCheckbox-root": {
      color: "black",
    },
    ".MuiDataGrid-columnSeparator": {
      display: "none",
    },
    ".MuiTablePagination-root": {
      width: "80%", // Adjust this value as needed
    },
    ".MuiTablePagination-actions": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center", // Adjust this to align as needed
    },
    ".MuiIconButton-root": {
      width: "30px", // Adjust this value as needed
      margin: "0 5px", // Adds space between buttons
    },
  });

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleCampaignToggle = () => {
    setIsCampaignVisible(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenDeleteConfirmDialog = () => {
    setOpenDeleteConfirmDialog(true);
  };

  const handleCloseDeleteConfirmDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteConfirmation = () => {
    deleteSelectedRowsCampaignType();
    handleCloseDeleteConfirmDialog();
  };

  const handleCampaignTypeSubmit = async () => {
    const formData = {
      name: campaignName,
      companies: user.company,
    };
    const campaign_type_response = await fetch(
      `${prefix}/api/marketing_campaign_type/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(formData),
      }
    );
    if (campaign_type_response.ok) {
      setOpen(true);
      setSeverity("success");
      setMessage("Campaign Type Created Successfully");
      navigate(0);
    } else {
      setOpen(true);
      setSeverity("error");
      setMessage("Campaign Type Creation Failed");
    }
  };

  const fetchRestaurants = useCallback(async () => {
    const restro_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();

      // Sort restaurants such that those with "Food Truck" in their name are at the end
      const sortedRestaurants = [...restro_data.results].sort((a, b) => {
        const aIsFoodTruck = a.name.includes("Food Truck");
        const bIsFoodTruck = b.name.includes("Food Truck");

        if (aIsFoodTruck && !bIsFoodTruck) {
          return 1; // a should come after b
        }
        if (!aIsFoodTruck && bIsFoodTruck) {
          return -1;
        }
        return 0;
      });

      setRestaurants(sortedRestaurants);

      // setRestaurantNames(sortedRestaurants[0]?.name);
    }
    const marketing_type_response = await fetch(
      `${prefix}/api/marketing_campaign_type/marketing/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (marketing_type_response.ok) {
      const marketingData = await marketing_type_response.json();

      setCampaignTypes(marketingData.results);
    }
  }, [company, token]);

  const fetchMarketingCampaigns = useCallback(async () => {
    setIsLoading(true);
    const marketing_campaigns_response = await fetch(
      `${prefix}/api/marketing_campaigns/campaigns_by_company/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (marketing_campaigns_response.ok) {
      const marketingCampaignsData = await marketing_campaigns_response.json();
      setTableData(marketingCampaignsData.results);
    } else {
      console.error("Failed to fetch marketing campaigns");
    }
    setIsLoading(false);
  }, [company, token]);

  const handleCampaignChange = (event) => {
    const selectedName = event.target.value;
    const foundCampaign = campaignTypes.find((r) => r.name === selectedName);
    setSelectedCampaign(foundCampaign);
  };

  const handleFormSubmission = () => {
    setDialogOpen(true);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRestaurantNames(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSubmit = async () => {
    // handleFormSubmission();
    // Map through restaurantNames to get an array of corresponding IDs
    const restaurantIds = restaurantNames.map((name) => {
      // Find the matching restaurant object from the restaurants array
      const restaurant = restaurants.find(
        (restaurant) => restaurant.name === name
      );
      // Return the id of the matching restaurant object
      return restaurant ? restaurant.id : null;
    });
    //the left ones in formData are the db_fields i.e. they should match one-to-one with the model.
    //the right ones are state-variables from the frontend so they can be different.
    const formData = {
      campaign_type: selectedCampaign.id,
      startdate: campaignStartDate,
      enddate: campaignEndDate,
      impact: campaignImpact,
      restaurants: restaurantIds,
    };
    const submit_response = await fetch(`${prefix}/api/marketing_campaigns/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(formData),
    });
    if (submit_response.ok) {
      setOpen(true);
      setSeverity("success");
      setMessage("Campaign Created Successfully");
      navigate(0);
    } else {
      setOpen(true);
      setSeverity("error");
      setMessage("Campaign Creation Failed");
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);
  useEffect(() => {
    fetchMarketingCampaigns();
  }, [fetchMarketingCampaigns]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        // border: 'solid 1px red',
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", xs: "column" },
          width: "100%",
          maxWidth: { lg: 1300, xl: 1600 },
          alignItems: "center",
          // border: 'solid 1px red',
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column" },
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
            // border: "1px solid red",
          }}
        >
          <Grid
            item
            lg={4}
            xs={12}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              gap: 2,
              flexDirection: "column",
              alignItems: "center",
              // border: "solid 1px red",
              // mt: { xs: "2rem", lg: "2rem" },
              // minHeight: { lg: "100vh", xs: "0" },
              borderRight: { xs: "none", sm: "2px solid #DADADA" },
            }}
          >
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                gap: 3,
                flexDirection: "column",
                alignItems: "center",
                // border: 'solid 1px red',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  mt: { xs: "1rem", sm: "0" },
                  fontFamily: "'Montserrat', sans-serif",

                  fontSize: {
                    lg: "1.5rem",
                    xs: "1.3rem",
                    sm: "1.5rem",
                    md: "2rem",
                  },
                }}
              >
                Add Marketing Campaign
              </Typography>

              {/* Start of the Card component */}

              {isCampaignVisible && (
                <>
                  {/* <Typography variant="h5">
                  If this is the first time you run this campaign type, add it
                  here
                </Typography> */}
                  <FormControl
                    sx={{
                      width: {
                        lg: "70%",
                        xs: "70%",
                      },
                      gap: 1,
                    }}
                  >
                    <Grid
                      container
                      // spacing={2}

                      sx={{
                        gap: 1,
                        display: "flex",
                        justifyContent: "center",
                        // border: "solid 1px red",
                        flexDirection: "column",
                        alignItems: "center",
                        width: { lg: "100%", xs: "100%" },
                        // border: 'solid 1px red',
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Write new campaign name here"
                        type="text"
                        value={campaignName}
                        onChange={(e) => setCampaignName(e.target.value)}
                        sx={{
                          width: { lg: "100%", xs: "100%" },
                        }}
                        InputProps={{
                          style: {
                            height: "3rem", // Set the height as needed
                          },
                        }}
                      />
                      <TextField
                        // fullWidth
                        label="Expected increase in sales"
                        type="number"
                        value={campaignImpact}
                        onChange={(e) => setCampaignImpact(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Typography fontWeight="bold">%</Typography>
                            </InputAdornment>
                          ),
                          style: {
                            height: "3rem", // Set the height as needed
                          },
                        }}
                        sx={{
                          width: { lg: "100%", xs: "100%" },
                        }}
                      />
                      <Button
                        variant="contained"
                        onClick={handleCampaignTypeSubmit}
                        sx={{
                          color: "#645e29",
                          backgroundColor: Colors.krunchLight,
                          width: "6rem",
                          // mb: "2rem",
                          "&:hover": {
                            backgroundColor: "#e3a902", // You can adjust this color based on your preference
                            // Prevent MUI Button from changing the color on hover
                            boxShadow: "none",
                          },
                          "&:active": {
                            backgroundColor: "#e3a902", // Adjust this color based on your preference
                          },
                          // height: "3rem",
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </FormControl>
                </>
              )}

              <FormControl
                sx={{
                  width: {
                    lg: "80%",
                    xs: "70%",
                  },
                }}
              >
                <InputLabel id="restaurant-select-label">
                  {campaignTypes.length !== 0
                    ? "Select a Campaign"
                    : "No Campaigns Found"}
                </InputLabel>
                <Select
                  labelId="restaurant-select-label"
                  s
                  id="restaurant-select"
                  onChange={(event) => {
                    handleCampaignChange(event);
                  }}
                  value={selectedCampaign?.name}
                  label="Select a Campaign"
                  sx={{ height: "3rem" }} // Set the width as required
                >
                  {campaignTypes?.map((camp) => (
                    <MenuItem key={camp.id} value={camp.name}>
                      {camp.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <Grid
              item
              xs={12}
              sx={{
                border: "solid 1px red",
              }}
            >

            </Grid> */}
              <FormControl
                sx={{
                  width: {
                    lg: "80%",
                    xs: "70%",
                  },
                  gap: 1,
                  mb: "2rem",
                  mt: "2rem",
                }}
              >
                <InputLabel id="demo-multiple-checkbox-label">
                  Which Restaurants run the campaign
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  label="Which restaurants runs the campaign?"
                  multiple
                  value={restaurantNames}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  sx={{ height: "3rem" }}
                >
                  {restaurants.map((restaurant) => (
                    <MenuItem key={restaurant.name} value={restaurant.name}>
                      <Checkbox
                        checked={restaurantNames.indexOf(restaurant.name) > -1}
                      />
                      <ListItemText primary={restaurant.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                // border: 'solid 1px red',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: {
                    lg: "1.5rem",
                    xs: "1.3rem",
                    sm: "1.5rem",
                    md: "2rem",
                    fontFamily: "'Montserrat', sans-serif",
                  },
                }}
              >
                Add start and end of campaign
              </Typography>
              <FormControl
                sx={{
                  width: {
                    lg: "80%",
                    xs: "80%",
                  },
                  gap: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Start Date"
                      type="date"
                      value={campaignStartDate}
                      onChange={(e) => setCampaignStartDate(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="End Date"
                      type="date"
                      value={campaignEndDate}
                      onChange={(e) => setCampaignEndDate(e.target.value)}
                      InputProps={{
                        inputProps: {
                          min: campaignStartDate,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  width: { lg: "70%", xs: "70%" },
                  // border: "solid 1px red",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    handleFormSubmission();
                  }}
                  sx={{
                    color: "#645e29",
                    backgroundColor: Colors.krunchLight,
                    width: "8rem",
                    // mb: "2rem",
                    "&:hover": {
                      backgroundColor: "#e3a902", // You can adjust this color based on your preference
                      // Prevent MUI Button from changing the color on hover
                      boxShadow: "none",
                    },
                    "&:active": {
                      backgroundColor: "#e3a902", // Adjust this color based on your preference
                    },
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    color: "#FFF",
                    backgroundColor: "#645e29",
                    width: "17ch",
                    height: "5ch",
                    // mb: "2rem",
                    "&:hover": {
                      backgroundColor: "#544D20", // You can adjust this color based on your preference
                      // Prevent MUI Button from changing the color on hover
                      boxShadow: "none",
                    },
                    "&:active": {
                      backgroundColor: "#544D20", // Adjust this color based on your preference
                    },
                  }}
                  onClick={handleCampaignToggle}
                >
                  Create New
                </Button>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  onClick={handleOpenDialog}
                  sx={{
                    width: "25ch",
                    // mt: "1.5rem",
                    justifyContent: "center",
                    color: "white",
                    backgroundColor: "#70335D",
                    // maxWidth: "5rem",
                    fontFamily: "Montserrat",
                    // border: "none",
                    "&:hover": {
                      backgroundColor: "#5A294A", // You can adjust this color based on your preference
                      // Prevent MUI Button from changing the color on hover
                      boxShadow: "none",
                    },
                    "&:active": {
                      backgroundColor: "#5A294A", // Adjust this color based on your preference
                    },
                  }}
                >
                  Edit Campaign Types
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            lg={8}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: { lg: "center", xs: "flex-start" },
              minHeight: "100vh",
              // border: "1px solid #FFBD00",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textAlign: "flex-start",
                fontSize: {
                  lg: "1.5rem",
                  xs: "1.3rem",
                  sm: "1.5rem",
                  md: "2rem",
                },
                mt: "2rem",
                mb: "1rem",
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              Marketing Campaigns
            </Typography>

            <Box
              sx={{
                height: { lg: 700, xs: 500 },
                width: { lg: "89%", xs: "90%" },
                // border: "solid 1px #FFBD00",
              }}
            >
              {isLoading ? (
                <ChartLoading />
              ) : (
                <>
                  <StyledDataGrid
                    rows={tableData || []}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    pagination
                    rowSelectionModel={selectedRows}
                    onRowSelectionModelChange={handleSelection}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDelete}
                    startIcon={<DeleteIcon />}
                    sx={{
                      marginBottom: "1px",
                      width: "12ch",
                      alignItems: "center",
                      display: "flex",
                      backgroundColor: "#D2042D", // Set button color to red
                      color: "white", // Set text color to white for better visibility
                      "&:hover": {
                        backgroundColor: "#A80324", // You can adjust this color based on your preference
                        // Prevent MUI Button from changing the color on hover
                        boxShadow: "none",
                      },
                      "&:active": {
                        backgroundColor: "#A80324", // Adjust this color based on your preference
                      },
                      mx: 3,
                      mt: 2,
                    }}
                  >
                    Delete {/* Replace 'Delete' text with DeleteIcon */}
                  </Button>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Submission</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="campaign details">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Campaign Name:</strong>
                  </TableCell>
                  <TableCell>{selectedCampaign?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Expected Increase in Sales:</strong>
                  </TableCell>
                  <TableCell>
                    {campaignImpact}
                    {campaignImpact && "%"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Selected Restaurants:</strong>
                  </TableCell>
                  <TableCell>{restaurantNames.join(", ")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Campaign Start Date:</strong>
                  </TableCell>
                  <TableCell>{campaignStartDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Campaign End Date:</strong>
                  </TableCell>
                  <TableCell>{campaignEndDate}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAlertDialog}
        onClose={() => setOpenAlertDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select at least one row to delete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlertDialog(false)}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the selected row(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            sx={{
              backgroundColor: Colors.delete,
              color: "white",
              "&:hover": {
                backgroundColor: Colors.deleteHover, // Replace with your hover color
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={deleteSelectedRows}
            autoFocus
            sx={{
              backgroundColor: Colors.positive,
              color: "white",
              "&:hover": {
                backgroundColor: Colors.positiveHover, // Replace with your hover color
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>List of Campaign Types</DialogTitle>
        <DialogContent>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={campaignTypes || []}
              columns={campaignColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              rowSelectionModel={selectedCampaignTypeRows}
              onRowSelectionModelChange={handleSelectionCampaignType}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            sx={{
              backgroundColor: Colors.delete,
              color: "white",
              "&:hover": {
                backgroundColor: Colors.deleteHover, // Replace with your hover color
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOpenDeleteConfirmDialog}
            color="primary"
            startIcon={<DeleteIcon />}
            sx={{
              backgroundColor: Colors.positive,
              color: "white",
              "&:hover": {
                backgroundColor: Colors.positiveHover, // Replace with your hover color
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog */}
      <Dialog
        open={openDeleteConfirmDialog}
        onClose={handleCloseDeleteConfirmDialog}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the selected campaign(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteConfirmDialog}
            color="primary"
            sx={{
              backgroundColor: Colors.delete,
              color: "white",
              "&:hover": {
                backgroundColor: Colors.deleteHover, // Replace with your hover color
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirmation}
            color="primary"
            sx={{
              backgroundColor: Colors.positive,
              color: "white",
              "&:hover": {
                backgroundColor: Colors.positiveHover, // Replace with your hover color
              },
            }}
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>

      <SnackbarAlert
        open={open}
        onClose={handleClose}
        severity={severity}
        message={message}
      />
    </Box>
  );
};

export default MarketingCampaign;
