import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import formatNumber from "../../utils/FormatNumber";
import { formatRegressorValue } from "../../utils/FormatRegressor";

// Custom legend component
const CustomLegend = ({ symbols }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start", // Align items to the start
        marginBottom: "150px",
        alignItems: "center",
        height: "auto",
        marginLeft: "30px",

        // border: "solid red 1px",
      }}
    >
      {symbols.map((symbol) => (
        <div
          key={symbol.id}
          // onClick={() => onClickLegend(symbol.id)}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center", // Align items to the center vertically
            height: "30px", // Set a fixed height for each legend item
            cursor: "pointer",
            width: "50%", // Ensure two items per row
            boxSizing: "border-box",
            overflow: "hidden", // Ensure no overflow of content
            textOverflow: "ellipsis", // Display ellipsis for overflowing text
            whiteSpace: "nowrap", // Prevent text from wrapping
            // border: "solid red 1px",
          }}
        >
          <svg width="20" height="20" style={{ flexShrink: 0 }}>
            <rect width="15" height="15" fill={symbol.color} x="2.5" y="2.5" />
          </svg>
          <span
            style={{
              marginLeft: "5px",
              fontSize: "12px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {formatRegressorValue(symbol.id)}
          </span>
        </div>
      ))}
    </div>
  );
};

const BarChart = ({
  data,
  isDetailedChart,
  isXSScreen,
  isRecommended = false,
}) => {
  const allValues = data.flatMap((item) =>
    Object.values(item).filter((value) => typeof value === "number")
  );

  const startValue = 0;
  const endValue = Math.max(...allValues);
  const middleValue = (startValue + endValue) / 2;

  let bar_keys = [];

  data.forEach((data) => {
    const all_keys = Object.keys(data);
    all_keys.forEach((key) => {
      if (key !== "date" && !bar_keys.includes(key)) {
        bar_keys.push(key);
      }
    });
  });
  const CustomTooltip = ({ id, value, color, data, range }) => {
    let formattedDate;
    const date = new Date(data.date);

    if (range === "month") {
      formattedDate = date.toLocaleDateString(undefined, {
        month: "long",
        year: "numeric",
      });
    } else if (range === "week") {
      const sunday = new Date(date);
      sunday.setDate(sunday.getDate() - sunday.getDay());

      const startOfYear = new Date(sunday.getFullYear(), 0, 1);
      const dayDifference = (sunday - startOfYear) / (24 * 60 * 60 * 1000);
      const weekNumber =
        1 + Math.floor((dayDifference + startOfYear.getDay() + 5) / 7);

      formattedDate = `${date.toLocaleDateString(undefined, {
        month: "long",
      })} (Week ${weekNumber}) ${sunday.getFullYear()}`;
    } else {
      formattedDate = date.toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
        }}
      >
        <p style={{ margin: 0, fontWeight: 700 }}>
          <strong></strong> {formattedDate}
        </p>
        {id && (
          <p style={{ margin: 0, fontWeight: 700, color: color }}>
            <strong>{formatRegressorValue(id)}:</strong>{" "}
            <strong>{formatNumber(value)}</strong>
          </p>
        )}
      </div>
    );
  };

  const colors = [
    "#c887b4",
    "#9FCAEB",
    "#77DD77",
    "#B783FD",
    "#E5B6DE",
    "#70335D",
    "#E390AA",
    "#C0C524",
    "#2A5F8B",
    "#AEC6CF",
    "#B69AA7",
    "#0199C6",
    "#AA26FE",
    "#55627B",
    "#91FAE0",
    "#2FEBAA",
    "#B42DA3",
    "#05E6C4",
  ];

  return data.length > 0 ? (
    <div
      style={{
        position: "relative",
        // justifyContent: "flex-start",
        // alignItems: "flex-start",
        height: "80%",
        width: "100%",
        // border: "solid pink 1px",
      }}
    >
      <ResponsiveBar
        data={data}
        colors={colors}
        keys={bar_keys}
        indexBy="date"
        groupMode="grouped"
        margin={{ top: 10, right: 50, bottom: 80, left: 50 }}
        padding={0.1}
        innerPadding={3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        layout="horizontal"
        borderRadius={2}
        borderWidth={1}
        tooltip={({ id, value, color, data }) => (
          <CustomTooltip id={id} value={value} color={color} data={data} />
        )}
        axisTop={null}
        axisRight={null}
        enableGridX={false}
        maxValue={endValue < 0 ? 0 : undefined}
        axisBottom={{
          tickSize: 5,
          tickPadding: 4,
          tickRotation: 0,
          legend: "Effect",
          legendPosition: "middle",
          legendOffset: 40,
          tickValues:
            isXSScreen && endValue
              ? [startValue, middleValue, endValue]
              : undefined,
        }}
        axisLeft={null}
        labelSkipWidth={12}
        labelSkipHeight={12}
        markers={[
          {
            axis: "x",
            value: 0,
            lineStyle: { stroke: "gray", strokeWidth: 1 },
          },
        ]}
        labelTextColor="black"
      />
      <CustomLegend
        symbols={bar_keys.map((key, index) => ({
          id: key,
          color: colors[index % colors.length],
        }))}
      />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <h2> No data</h2>
    </div>
  );
};

export default BarChart;
