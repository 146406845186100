import React, {
  useState,
  //  useRef
} from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SettingsIcon from "@mui/icons-material/Settings";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import ReportIssue from "../user/ReportIssue";
import {
  Typography,
  TextField,
  Tooltip,
  Button,
  FormControl,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // ToggleButton,
  Switch,
  // ToggleButtonGroup,
  // Grid,
  Snackbar,
  Alert,
  useMediaQuery,
  Select,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import CloseIcon from "@mui/icons-material/Close";
import LastUpdate from "../utils/LastUpdate";

const SettingsDialog = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  downloadOptions,
  company,
  setCompany,
  email,
  hasPredictionData,
  handlePredictionData,
  trendsToggle,
  setTrendsToggle,
  showPredictionOptions = false, // default false if not passed
  showTrendsOptions = false, // default false if not passed
  isSalesPrediction = false,
  startingDate,
  endingDate,
  setStartingDate,
  setEndingDate,
}) => {
  const theme = useTheme();

  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [openDialog, setOpenDialog] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isDownloading, setIsDownloading] = useState(false);

  const [tempStartingDate, setTempStartingDate] = useState(startingDate);
  const [tempEndingDate, setTempEndingDate] = useState(endingDate);
  // const navigatingRef = useRef(false);
  const navigate = useNavigate();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenSettingsDialog = () => {
    setOpenDialog(true);
  };

  const handleDownloadClick = () => {
    setIsDownloading(true);

    if (!Array.isArray(downloadOptions)) {
      console.error(
        "downloadOptions is not an array or is undefined:",
        downloadOptions
      );
      setIsDownloading(false);
      setSnackbarMessage(
        "Download failed: downloadOptions is not an array or is undefined"
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const invalidOptions = downloadOptions.filter(
      (opt) => typeof opt !== "function"
    );
    if (invalidOptions.length > 0) {
      console.error("Invalid download functions found:", invalidOptions);
      setIsDownloading(false);
      setSnackbarMessage("Download failed: invalid download functions found");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    Promise.all(
      downloadOptions.map((downloadFunction) => {
        try {
          const result = downloadFunction();
          return result instanceof Promise ? result : Promise.resolve(result);
        } catch (error) {
          return Promise.reject(error);
        }
      })
    )
      .then(() => {
        setSnackbarMessage("Download successful!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        setSnackbarMessage(`Download failed: ${error.message}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const handleSetDates = () => {
    const isValidDate = (dateString) => {
      const date = new Date(dateString);
      return date instanceof Date && !isNaN(date);
    };

    if (!isValidDate(tempStartDate)) {
      setSnackbarMessage("Start date is not a valid date.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (!isValidDate(tempEndDate)) {
      setSnackbarMessage("End date is not a valid date.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (new Date(tempEndDate) < new Date(tempStartDate)) {
      setSnackbarMessage("End date cannot be earlier than start date.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setSnackbarMessage("Dates set successfully!");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };

  const handleHourlySetDates = () => {
    const isValidDate = (dateString) => {
      const date = new Date(dateString);
      return date instanceof Date && !isNaN(date);
    };

    if (!isValidDate(tempStartingDate)) {
      setSnackbarMessage("Start date is not a valid date.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (!isValidDate(tempEndingDate)) {
      setSnackbarMessage("End date is not a valid date.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (new Date(tempEndingDate) < new Date(tempStartingDate)) {
      setSnackbarMessage("End date cannot be earlier than start date.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setStartingDate(tempStartingDate);
    setEndingDate(tempEndingDate);
    setSnackbarMessage("Dates set successfully!");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleTrendsToggle = () => {
    setTrendsToggle((prev) => !prev); // This should toggle the trends state
  };
  // const handleContactPage = () => {
  //   window.open("/supportus", "_blank");
  // };
  const handleContactPage = () => {
    const currentPage = window.location.pathname; // Get the current URL path
    navigate("/supportus", { state: { page: currentPage } });
  };
  return (
    <>
      <Tooltip title="Click here to make custom settings for the page">
        <SettingsIcon
          onClick={handleOpenSettingsDialog}
          sx={{
            padding: "8px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              borderRadius: "50%",
            },
          }}
        />
      </Tooltip>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            width: { lg: "50vw", xs: "95vw" },
            height: { lg: "70vh", xs: "80vh" },
            maxWidth: "none",
            maxHeight: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <DialogTitle sx={{ flexGrow: 1 }}>Settings</DialogTitle>
          <CloseIcon
            onClick={handleCloseDialog}
            sx={{
              mr: "1rem",
              cursor: "pointer",
              borderRadius: "50%",
              p: "8px",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          />
        </Box>
        <DialogContent>
          <DialogContentText>
            <Typography
              variant="h5"
              sx={{ margin: "1rem", fontWeight: 500, textAlign: "center" }}
            >
              Filters
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl
                  lg={12}
                  md={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: { lg: "row", xs: "row" },
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    mx: ".5rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { lg: "row", xs: "column" },
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Start Date"
                      type="date"
                      value={tempStartDate}
                      onChange={(e) => setTempStartDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      sx={{ m: 1 }}
                    />
                    <TextField
                      fullWidth
                      label="End Date"
                      type="date"
                      value={tempEndDate}
                      onChange={(e) => setTempEndDate(e.target.value)}
                      InputProps={{ inputProps: { min: tempStartDate } }}
                      InputLabelProps={{ shrink: true }}
                      sx={{ m: 1 }}
                    />

                    <Button
                      onClick={handleDownloadClick}
                      variant="contained"
                      sx={{
                        backgroundColor: "#FFBD00",
                        color: "#645e29",
                        "&:hover": { backgroundColor: "#e3a902" },
                        "&:active": { backgroundColor: "#e3a902" },
                        width: "10%",
                      }}
                      disabled={isDownloading}
                    >
                      <CloudDownloadIcon />
                    </Button>
                  </Box>
                </FormControl>
              </FormControl>

              <Button
                onClick={handleSetDates}
                variant="contained"
                sx={{
                  backgroundColor: "#FFBD00",
                  color: "#645e29",
                  "&:hover": { backgroundColor: "#e3a902" },
                  "&:active": { backgroundColor: "#e3a902" },
                  width: "10rem",
                  mt: 1,
                }}
              >
                Set Dates
              </Button>
            </Box>
            {email === "master@krunch.no" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mt: 1,
                }}
              >
                <Box
                  sx={{
                    height: "5rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "90%", lg: 400 },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      m: 1,
                    }}
                  >
                    <Select
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Select company" }}
                      sx={{
                        width: "15rem",
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select a company
                      </MenuItem>
                      {[
                        "Los Tacos",
                        "Fisketorget",
                        "Krunch",
                        "Burgerheim",
                        "The Broker",
                      ].map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>

                {isSalesPrediction && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: "3rem",
                    }}
                  >
                    <FormControl
                      sx={{
                        display: "flex",
                        flexDirection: { lg: "row", xs: "column" },
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControl
                        lg={12}
                        md={12}
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: { lg: "row", xs: "row" },
                          justifyContent: "center",
                          alignItems: "center",
                          margin: 2,
                          mx: ".5rem",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { lg: "row", xs: "column" },
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            fullWidth
                            label="Start Date"
                            type="date"
                            value={tempStartingDate}
                            onChange={(e) =>
                              setTempStartingDate(e.target.value)
                            }
                            InputLabelProps={{ shrink: true }}
                            sx={{ m: 1 }}
                          />
                          <TextField
                            fullWidth
                            label="End Date"
                            type="date"
                            value={tempEndingDate}
                            onChange={(e) => setTempEndingDate(e.target.value)}
                            InputProps={{
                              inputProps: { min: tempStartingDate },
                            }}
                            InputLabelProps={{ shrink: true }}
                            sx={{ m: 1 }}
                          />
                        </Box>
                      </FormControl>
                    </FormControl>
                    <Button
                      onClick={handleHourlySetDates}
                      variant="contained"
                      sx={{
                        backgroundColor: "#FFBD00",
                        color: "#645e29",
                        "&:hover": { backgroundColor: "#e3a902" },
                        "&:active": { backgroundColor: "#e3a902" },
                        width: "15rem",
                        // mt: 1,
                      }}
                    >
                      Set Hourly Dates
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            {showPredictionOptions && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  mt: 2,
                }}
              >
                <Typography sx={{ fontFamily: "Montserrat", fontSize: "12px" }}>
                  To Date
                </Typography>
                <Switch
                  checked={hasPredictionData}
                  onChange={handlePredictionData}
                />
                <Typography sx={{ fontFamily: "Montserrat", fontSize: "12px" }}>
                  Projected
                </Typography>
              </Box>
            )}
            {showTrendsOptions && (
              <Tooltip title="This toggle determines how the upward and downward trends are calculated. Change since last period compares the sale of the selected date period against an average of the last X days/weeks/months, depending on your date interval. Change since last year compares the selected date period against the sale in the same date period last year.">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 5,
                    width: "100%",
                    // border: "solid red 1px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      color: "#515151",
                      fontSize: "12px",
                      // mb: 1,
                    }}
                  >
                    Change since last period
                  </Typography>
                  <Switch
                    checked={trendsToggle}
                    onChange={handleTrendsToggle}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      color: "#515151",
                      fontSize: "12px",
                    }}
                  >
                    Change since last year's period
                  </Typography>
                </Box>
              </Tooltip>
            )}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                mt: 1,
              }}
            >
              <Typography
                onClick={handleContactPage}
                sx={{
                  cursor: "pointer",
                  color: "black",
                  "&:hover": { color: "#e3a902" },
                  "&:active": { color: "#e3a902" },
                  // width: "20%",
                  textAlign: "center",
                  mt: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "18px",
                  // border: "solid red 1px",
                  width: "100%",
                }}
              >
                Report a Problem
                <ErrorOutlineIcon sx={{ ml: 1, fontSize: "large" }} />{" "}
                {/* Ensure the icon matches the text size */}
              </Typography>
            </Box>
            {isDownloading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 5,
              }}
            >
              {isXSScreen && <LastUpdate />}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SettingsDialog;
