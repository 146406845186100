import React from "react";
import { Box, Collapse } from "@mui/material";

const CollapsibleSection = ({ isOpen, children }) => (
  <Collapse in={isOpen} sx={{ width: "100%" }}>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textDecoration: "none",
        color: "#645e29",
        // gap: 3,
      }}
    >
      {children}
    </Box>
  </Collapse>
);

export default CollapsibleSection;
