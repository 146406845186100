// StyledButton.js
import React from "react";
import Button from "@mui/material/Button";
import Colors from "../../utils/Colors";

const StyledButton = ({ onClick, children, style, ...props }) => {
  const defaultStyles = {
    backgroundColor: Colors.krunch,
    width: "15ch",
    color: "#645E29",
    "&:hover": {
      backgroundColor: "#e3a902",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#e3a902",
    },
  };
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{ ...defaultStyles, ...style }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default StyledButton;
