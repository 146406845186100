// TooltipCreate.js
import React, { useState } from "react";
import TooltipSheet from "./TooltipSheet";
import { Typography } from "@mui/material";
import {
  // Box,
  Tooltip,
  // Typography,
} from "@mui/material";
const TooltipCreate = ({
  tooltipKey,
  content,
  children,
  position = "top",
  // width = "250px",
}) => {
  const [visible, setVisible] = useState(false);

  const staticContent = tooltipKey ? TooltipSheet[tooltipKey]?.content : null;
  const finalContent = content || staticContent;

  // const containerStyle = {
  //   position: "relative",
  //   display: "inline-block",
  //   cursor: "pointer",
  // };

  // const tooltipStyle = {
  //   visibility: visible ? "visible" : "hidden",
  //   width: width,
  //   backgroundColor: "rgba(96, 96, 96, 0.8)",
  //   color: "#fff",
  //   textAlign: "left",
  //   borderRadius: "10px",
  //   padding: "10px",
  //   position: "absolute",
  //   zIndex: 1,
  //   opacity: visible ? 1 : 0,
  //   fontSize: "1rem" /* Default MUI typography font size */,
  //   lineHeight: 1.45,
  //   // transform: visible ? "scale(1)" : "scale(0.5)",
  //   transition: "opacity 0.3s, transform 0.3s",
  //   bottom: "125%",
  //   left: "50%",
  //   transform: "translateX(-50%)", // Center the tooltip
  //   wordWrap: "break-word", // Ensure text wraps within the tooltip
  //   // fontFamily: "Roboto",
  // };

  // const arrowStyle = {
  //   position: "absolute",
  //   width: "0",
  //   height: "0",
  //   borderStyle: "solid",
  //   top: "100%",
  //   left: "50%",
  //   transform: "translateX(-50%)",
  //   borderWidth: "5px 5px 0 5px",
  //   borderColor: "grey transparent transparent transparent",
  // };

  return (
    <Tooltip
      title={<Typography>{finalContent}</Typography>}
      open={visible}
      onOpen={() => setVisible(true)}
      onClose={() => setVisible(false)}
      arrow
      placement={position}
    >
      <div
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default TooltipCreate;
