import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  IconButton,
  Tooltip,
  ListItemIcon,
  InputAdornment,
} from "@mui/material";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import SnackbarAlert from "../utils/SnackbarAlert";
import {
  Restaurant as RestaurantIcon,
  ShowChart as ShowChartIcon,
  Insights as InsightsIcon,
  PersonSearch as PersonSearchIcon,
  Fastfood as FastfoodIcon,
  RestaurantMenu as RestaurantMenuIcon,
  Event as EventIcon,
} from "@mui/icons-material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import WhatshotIcon from "@mui/icons-material/Whatshot";

let prefix = "";
if (process.env.NODE_ENV === "development") {
  prefix = "http://localhost:8000";
}
// const token = localStorage.getItem("token");

const SupportUs = () => {
  const location = useLocation();

  const [issue, setIssue] = useState("");
  const [page, setPage] = useState(location.state?.page || "");
  const [message, setMessage] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("token");
  // const company = localStorage.getItem("company");

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [email, setEmail] = useState("");
  // const [user, setUser] = useState("");
  const [severity, setSeverity] = useState("error");

  // const user = JSON.parse(localStorage.getItem("user"));
  // const userName = user ? `${user.first_name} ${user.last_name}` : "USER";

  const issues = ["Bug", "Feedback", "Other"];
  const pages = [
    { label: "Global" },
    { label: "Profit & Loss", icon: <ShowChartIcon /> },
    { label: "Sales Prediction", icon: <InsightsIcon /> },
    { label: "Compare", icon: <CompareArrowsIcon /> },
    { label: "Staff Insight", icon: <PersonSearchIcon /> },
    { label: "Product Overview", icon: <FastfoodIcon /> },
    { label: "Trends", icon: <WhatshotIcon /> },
    { label: "Manager", icon: <RestaurantIcon /> },
    { label: "Restaurant", icon: <RestaurantMenuIcon /> },
    { label: "Event", icon: <EventIcon /> },
    { label: "Download Data", icon: <CloudDownloadIcon /> },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!issue || !page || !message) {
      setOpen(true);
      setSeverity("error");
      setSnackbarMessage(
        "Issue Type, Page, and Message should not be left empty"
      );
      return;
    }

    if (email && !validateEmail(email)) {
      setOpen(true);
      setSeverity("error");
      setSnackbarMessage("Invalid Email Format");
      return;
    }
    // Prepare the form data
    const formData = new FormData();
    formData.append("email", email);
    formData.append("issue", issue);
    formData.append("page", page);
    formData.append("message", message);
    if (image) {
      formData.append("image", image);
    }
    try {
      // Proceed with the submission logic
      const response = await fetch(`${prefix}/api/users/report_issue/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      });

      const responseData = await response.json();
      if (response.ok && !responseData.error) {
        setOpen(true);
        setSeverity("success");
        setSnackbarMessage(
          `The issue "${issue}" has been reported successfully`
        );
      } else {
        setOpen(true);
        setSeverity("error");
        setSnackbarMessage(responseData.error || "Email sending failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setOpen(true);
      setSeverity("error");
      setSnackbarMessage("An error occurred while submitting the form");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setSnackbarMessage("Image uploaded successfully");
      setSeverity("success");
      setOpen(true);
    }
  };
  useEffect(() => {
    // Map the URL path to the corresponding page label
    const pagePathToLabel = (path) => {
      switch (path) {
        case "/profitloss":
          return "Profit & Loss";
        case "/salesprediction":
          return "Sales Prediction";
        case "/comparisons":
          return "Compare";
        case "/newsuperdashboard":
          return "Staff Insight";
        case "/newsuperdashboardtwo":
          return "Product Overview";
        case "/trends":
          return "Trends";
        case "/recommendations":
          return "Manager";
        case "/admin":
          return "Restaurant";
        case "/event":
          return "Event";
        case "/downloaddata":
          return "Download Data";
        default:
          return "Global";
      }
    };

    if (location.state?.page) {
      const pageLabel = pagePathToLabel(location.state.page);
      setPage(pageLabel);
    }
  }, [location.state]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: { lg: 1300, xl: 1600 },
          alignItems: "center",
          boxSizing: "border-box",
          height: { lg: "100vh", xs: "100vh" },
          // border: "solid red 1px",
        }}
      >
        <Box
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {/* <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                color: "#4c4923",
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              Support
            </h2>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              width: "80%",
              maxWidth: "600px",
              height: "80%",
              boxShadow: 3,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            {/* <Box
              sx={{
                width: { xs: "100%", lg: "100%" }, // Full width on xs, half on md+
                padding: "40px 20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // backgroundColor: "#fce9b3",
                //
                border: "solid red 1px",
              }}
            > */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                width: { xs: "90%", lg: "80%" }, // Full width on xs, half on md+
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontFamily: "'Montserrat', sans-serif",

                  // border: "solid red 1px",
                }}
                textAlign="center"
              >
                Report an Issue
              </Typography>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="issue-label">Issue Type</InputLabel>
                  <Select
                    labelId="issue-label"
                    label="issue type"
                    id="issue"
                    value={issue}
                    onChange={(e) => setIssue(e.target.value)}
                    sx={{ backgroundColor: "#FFF", borderRadius: 3 }}
                  >
                    <MenuItem value="" disabled>
                      Select an issue
                    </MenuItem>
                    {issues.map((issue) => (
                      <MenuItem key={issue} value={issue}>
                        {issue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel id="page-label">Page</InputLabel>
                  <Select
                    labelId="page-label"
                    id="page"
                    label="Page"
                    value={page}
                    onChange={(e) => setPage(e.target.value)}
                    sx={{
                      backgroundColor: "#FFF",
                      borderRadius: 3,
                      display: "flex",
                      alignItems: "center",
                      ".MuiSelect-select": {
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select a page
                    </MenuItem>
                    {pages.map((page) => (
                      <MenuItem key={page.label} value={page.label}>
                        <ListItemIcon>{page.icon}</ListItemIcon>
                        {page.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    placeholder="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "10px",
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  sx={{ position: "relative" }}
                >
                  <TextField
                    id="message"
                    label="Message"
                    multiline
                    rows={5}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "10px",
                        },
                      },
                    }}
                  />
                  <Tooltip title="Attach an image" arrow placement="top">
                    <IconButton
                      component="label"
                      sx={{ position: "absolute", top: 8, right: 8 }}
                    >
                      <AddPhotoAlternateOutlinedIcon />
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </IconButton>
                  </Tooltip>
                  {imagePreview && (
                    <Box mt={2}>
                      <Typography variant="subtitle1">
                        Image Preview:
                      </Typography>
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  )}
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: { xs: "center", lg: "flex-start" },
                    alignItems: { xs: "center", lg: "flex-start" },
                    // border: "solid red 1px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#FFBD00",
                      "&:hover": {
                        backgroundColor: "#D8A000",
                      },
                      width: "12rem",
                      color: "black",
                      mt: 1,
                    }}
                  >
                    Send Message
                  </Button>
                </Box>
              </form>
            </Box>
            {/* </Box> */}
            {/* <Box
              sx={{
                width: "50%",
                display: { xs: "none", md: "flex" }, // Hide on xs, show on md+
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFF",
              }}
            >
              <img
                src="BugReport.png"
                alt="Contact us illustration"
                style={{ width: "100%" }}
              />
            </Box> */}
          </Box>
        </Box>
      </Box>
      <SnackbarAlert
        open={open}
        severity={severity}
        message={snackbarMessage}
        onClose={handleClose}
      />
    </Box>
  );
};

export default SupportUs;
