import React from "react";
import "../App.css";
import { Routes, Route } from "react-router-dom";
import SignIn from "../user/SignIn";
import ForgotPassword from "../user/ForgotPassword";
import ChangePassword from "../user/ChangePassword";
// import Landingv2 from "../Landingv2/Landingv2";
import ContactUs from "../Landingv2/ContactUs";
import Features from "../Landingv2/Features";
import FAQ from "../Landingv2/FAQ";
import NewAboutPage from "../Landingv2/AboutUs";
import Blogs from "../Landingv2/Blogs";
import ErrorPage from "../user/ErrorPage"; // Import your 404 page component

//User and Restaurant Functions
function Login() {
  return (
    <div className="App">
      <main>
        <SignIn />
      </main>
    </div>
  );
}
// function LandingNewPage() {
//   return (
//     <div className="App">
//       <main>
//         <Landingv2 />
//       </main>
//     </div>
//   );
// }

function NewContactPage() {
  return (
    <div className="App">
      <main>
        <ContactUs />
      </main>
    </div>
  );
}
function FeaturesPage() {
  return (
    <div className="App">
      <main>
        <Features />
      </main>
    </div>
  );
}
function FAQPage() {
  return (
    <div className="App">
      <main>
        <FAQ />
      </main>
    </div>
  );
}
function PasswordForgot() {
  return (
    <div className="App">
      <main>
        <ForgotPassword />
      </main>
    </div>
  );
}
function PasswordChange() {
  return (
    <div className="App">
      <main>
        <ChangePassword />
      </main>
    </div>
  );
}

function ResourcesPage() {
  return (
    <div className="App">
      <main>
        <Blogs />
      </main>
    </div>
  );
}
function ErrorRoute() {
  return (
    <div className="App">
      <main>
        <ErrorPage />
      </main>
    </div>
  );
}

const UnauthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signin" element={<Login />} />
      {/* <Route path="/newlandingpage" element={<LandingNewPage />} /> */}
      <Route path="/contact" element={<NewContactPage />} />
      <Route path="/features" element={<FeaturesPage />} />
      <Route path="/FAQ" element={<FAQPage />} />
      <Route path="/aboutus" element={<NewAboutPage />} />
      <Route path="/forgot_password" element={<PasswordForgot />} />
      <Route path="/change_password" element={<PasswordChange />} />
      <Route path="/resources" element={<ResourcesPage />} />
      <Route path="*" element={<ErrorRoute />} />
    </Routes>
  );
};

export default UnauthenticatedRoutes;
