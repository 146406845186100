const company = localStorage.getItem("company");

export const losTacos = [
  // "#563C8A", // Indigo
  // "#E191B1", // Light Pink
  // "#B783FD", // Lavender
  "#543c8c",
  // "#7f7f7f",
  "#e394b4",
  "#949494",
  // "#808080", //GRAY
  // "#808080", //GRAY
  // "#808080", //GRAY
  "#70335D", // Dark Plum
  "#70335D", // Dark Plum
  "#C0C524", // Olive Green
  "#2A5F8B", // Steel Blue
  "#9FCAEB", // Sky Blue
  "#B69AA7", // Muted Pink
  "#0199C6", // Blue
  "#AA26FE", // Purple
];
// export const Oslo = [
//   "#A9A9A9", // Dark Gray
// "#808080", // Gray
//   "#000000", // Black
// ];
export const Krunch = [
  "#0199C6", // bright blue
  "#B69AA7", // plum
  "#9FCAEB", // sky blue
  "#B783FD", // Lavender
];

export const Fisketorget = [
  "#70335D", //dark plum
  "#B783FD", // Lavender
  "#7CB342", //muted green
  "#6182ed", //dusty blue

  "#7CB342", //muted green
  "#6182ed", //dusty blue
  "#0199C6", // blue green
  "#ed61d1", //dusty pink
  "#B8860B",
  "#9FCAEB", //sky blue
];
export const getCompanyColors = () => {
  switch (company) {
    case "Los Tacos":
      return losTacos;
    case "Krunch":
      return Krunch;
    case "Fisketorget":
      return Fisketorget;
    // Add more cases as needed for other companies
    default:
      return Krunch; // Define a default color array
  }
};
