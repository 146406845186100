import React, { useState, useEffect, useCallback, useRef } from "react";

import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Zoom,
  Switch,
  Box,
} from "@mui/material";
import ChartLoading from "../../components/Loading/ChartLoading";
import ProdPieChart from "../../components/ProdPieChart";
import TitleStyle from "../../components/Style/Title";

let prefix = "";

if (process.env.NODE_ENV === "development") {
  prefix = "http://127.0.0.1:8000";
}
const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const ProductPieChart = ({
  startDate,
  endDate,
  isXSScreen,
  dataRange,
  selectedPage,
  selectedRestaurant,
  hasPredictionData,
  range,
  company,
}) => {
  const token = localStorage.getItem("token");
  const [subProducts, setSubProducts] = useState([]);
  const [subProduct, setSubProduct] = useState(null);
  const [isSubCategoryVisible, setIsSubCategoryVisible] = useState(false);
  const [productLoad, setProductLoad] = useState(false);
  const [prodBarLoad, setProdBarLoad] = useState(false);
  const [articleGroupData, setArticleGroupData] = useState([]);
  const [productMixData, setProductMixData] = useState([]);
  // console.log(productMixData, "article");

  const abortControllerRefForArticleGroupData = useRef(new AbortController());
  const abortControllerRefForArticleData = useRef(new AbortController()); // Separate ref for article data fetches
  const handleOptionChange = (sub_product) => {
    setSubProduct(sub_product);
  };
  const handleToggleSubCat = () => {
    setIsSubCategoryVisible((prev) => !prev);
  };

  //function to format the URLS for fetch
  const buildFetchUrl = (baseURL, options) => {
    let {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      range,
      // dateAdjustment,
    } = options;
    let startingDate = startDate;
    let endingDate = endDate;

    let formattedStartDate = startingDate;
    let formattedEndDate = endingDate;
    if (
      (formattedEndDate && !formattedStartDate) ||
      (formattedStartDate && !formattedEndDate)
    ) {
      return;
    }
    if (formattedStartDate > formattedEndDate) {
      //   setOpen(true);
      //   setSeverity("error");
      //   setMessage("Start date cannot be further than end date");
      return;
    }

    // Constructing URL with query parameters
    let url = baseURL;
    let isFirstQueryParam = true;

    // Helper function to append parameters
    const appendParam = (key, value) => {
      if (isFirstQueryParam) {
        url += `?${key}=${encodeURIComponent(value)}`;
        isFirstQueryParam = false;
      } else {
        url += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // Append company if present
    if (company) {
      appendParam("company", company);
    }

    // Append start_date and end_date if present
    if (formattedStartDate && formattedEndDate) {
      appendParam("start_date", startDate);
      appendParam("end_date", endDate);
    }

    // Append restaurantNames if present
    if (selectedRestaurant.length > 0) {
      appendParam("restaurants", JSON.stringify(selectedRestaurant));
    }

    // Append newValue if present
    if (range) {
      appendParam("period", range);
    }

    // Return the URL as a string
    return url;
  };

  const getArticleGroupData = useCallback(() => {
    let range = null;
    range = dataRange === "today" ? "hour" : "day";
    let newValue = range;
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };

    const subProducts = subProduct;
    let Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/sub_product_mix`,
      urlOptions
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    if (!subProducts) {
      return;
    }

    Url += `&sub_product=${subProducts}`;
    abortControllerRefForArticleGroupData.current.abort();
    abortControllerRefForArticleGroupData.current = new AbortController();

    setProdBarLoad(true);

    fetch(Url, {
      headers: headers,
      signal: abortControllerRefForArticleGroupData.current.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          let resultsToUse = hasPredictionData
            ? data.message.prediction
            : data.message.historical;
          if (resultsToUse.length <= 0) {
            setArticleGroupData([]);
            setProdBarLoad(false);
            return;
          }
          const chartData = Object.entries(resultsToUse).map(
            ([actual_date, values]) => {
              let date;
              const totalNetData = {};
              Object.entries(values).forEach(([category, { total_net }]) => {
                totalNetData[category] = total_net;
              });
              if (newValue === "week") {
                const [year, week] = actual_date.split(" ");
                const newDate = new Date(Date.UTC(year, 0)); // January 1st of the year
                const weekOffset = week * 7; // Calculate the number of days to add for the week
                newDate.setUTCDate(newDate.getUTCDate() + weekOffset);
                const transformed_date = new Date(newDate);
                const yyyy = transformed_date.getUTCFullYear();
                const mm = String(transformed_date.getUTCMonth() + 1).padStart(
                  2,
                  "0"
                ); // Month is 0-based, so we add 1 and pad with 0 if needed
                const dd = String(transformed_date.getUTCDate()).padStart(
                  2,
                  "0"
                );
                const formatted_date = `${yyyy}-${mm}-${dd}`;
                date = formatted_date;
              } else {
                date = actual_date;
              }
              return {
                date,
                ...totalNetData,
              };
            }
          );

          setArticleGroupData(chartData);
        } else {
          setArticleGroupData([]);
        }
        setProdBarLoad(false);
      })

      .catch((error) => {
        // console.error("Error fetching sales data:", error);
        // setProdBarLoad(false);
        if (error.name !== "AbortError") {
          console.error(error);
          setProductLoad(false);
        }
      });
  }, [
    subProduct,
    startDate,
    endDate,
    selectedRestaurant,
    dataRange,
    hasPredictionData,
    company,
    token,
  ]);

  const getArticleData = useCallback(() => {
    let range = null;
    range = dataRange === "today" ? "hour" : "day";
    let newValue = range;

    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      hasPredictionData,
      range,
    };
    // if (selectedRestaurant.length === 0) {
    //   return;
    // }
    const Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/product_mix`,
      urlOptions
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    abortControllerRefForArticleData.current.abort();
    abortControllerRefForArticleData.current = new AbortController();

    setProductLoad(true);
    fetch(Url, {
      headers: headers,
      signal: abortControllerRefForArticleData.current.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          let resultsToUse = hasPredictionData
            ? data.message.prediction
            : data.message.historical;
          if (resultsToUse.length === 0) {
            setSubProducts([]);
            setProductMixData([]);
            setProductLoad(false);
            return;
          }
          let sub_products = [];
          Object.entries(resultsToUse).map((map) => {
            const sub_keys = Object.keys(map[1]);
            for (const index in sub_keys) {
              const key = sub_keys[index];
              if (!sub_products.includes(key)) {
                sub_products.push(key);
              }
            }
            return sub_products;
          });
          setSubProducts(sub_products);
          const chartData = Object.entries(resultsToUse).map(
            ([actual_date, values]) => {
              let date;
              const totalNetData = {};
              Object.entries(values).forEach(([category, { total_net }]) => {
                totalNetData[category] = total_net;
              });
              if (newValue === "week") {
                const [year, week] = actual_date.split(" ");
                const newDate = new Date(Date.UTC(year, 0)); // January 1st of the year
                const weekOffset = week * 7; // Calculate the number of days to add for the week
                newDate.setUTCDate(newDate.getUTCDate() + weekOffset);
                const transformed_date = new Date(newDate);
                const yyyy = transformed_date.getUTCFullYear();
                const mm = String(transformed_date.getUTCMonth() + 1).padStart(
                  2,
                  "0"
                ); // Month is 0-based, so we add 1 and pad with 0 if needed
                const dd = String(transformed_date.getUTCDate()).padStart(
                  2,
                  "0"
                );
                const formatted_date = `${yyyy}-${mm}-${dd}`;
                date = formatted_date;
              } else {
                date = actual_date;
              }
              return {
                date,
                ...totalNetData,
              };
            }
          );
          setProductMixData(chartData);
        } else {
          setSubProducts([]);
          setProductMixData([]);
          setProductLoad(false);
        }
        setProductLoad(false);
      })

      .catch((error) => {
        // setProductLoad(false);
        // console.error("Error fetching sales data:", error);
        if (error.name !== "AbortError") {
          console.error(error);
          setProductLoad(false);
        }
      });
  }, [
    endDate,
    startDate,
    selectedRestaurant,
    dataRange,
    hasPredictionData,
    company,
    token,
  ]);

  useEffect(() => {
    return () => {
      // Abort any ongoing fetches for Article Data
      if (abortControllerRefForArticleData.current) {
        abortControllerRefForArticleData.current.abort();
      }
      if (abortControllerRefForArticleGroupData.current) {
        abortControllerRefForArticleGroupData.current.abort();
      }
    };
  }, []);
  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setArticleGroupData([]);
      setProductMixData([]);
    } else {
      getArticleGroupData();
      getArticleData();
    }
  }, [getArticleGroupData, getArticleData, selectedPage, selectedRestaurant]);

  // console.log(tableData, "pikaru");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { lg: "100%", xs: "90%" },
        // gap: 2,
        justifyContent: "flex-start",
        alignItems: "center",
        // border: "solid red 1px",
        height: { lg: "100%", xs: "30rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // fontFamily: "Montserrat",
          width: "100%",
          position: "relative",
          justifyContent: "center",
          // border: "solid 1px red",
        }}
      >
        <TitleStyle tooltipKey="product_sales_profit">Product Sales</TitleStyle>
        {(productMixData.length > 0 || isEmpty(productMixData)) && (
          <Tooltip
            TransitionComponent={Zoom}
            title="Toggle between Product Category and Product"
            placement="top"
            arrow
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: 0,
                // border:"solid 1px red"
              }}
            >
              <Typography variant="h7"></Typography>
              <Switch
                checked={isSubCategoryVisible}
                onChange={handleToggleSubCat}
              />
              <Typography variant="h7"></Typography>
            </Box>
          </Tooltip>
        )}
      </Box>

      {isSubCategoryVisible ? (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            // border: "solid red 1px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormControl sx={{ minWidth: 140 }}>
              <InputLabel id="select-label">Select Option</InputLabel>
              <Select
                labelId="select-label"
                id="select"
                size="small"
                value={subProduct}
                onChange={(e) => {
                  handleOptionChange(e.target.value);
                }}
                label="Select Option"
              >
                {subProducts.map((sub) => (
                  <MenuItem value={sub}>{sub}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: { lg: "90%", xs: "20rem", xl: "80%" },
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              // border: "solid red 1px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                height: "15rem",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                // border: "solid red 1px",
              }}
            >
              {prodBarLoad ? (
                <Box
                  sx={{
                    display: "flex",
                    mt: "5rem",
                  }}
                >
                  <ChartLoading />
                </Box>
              ) : (
                <ProdPieChart
                  data={articleGroupData}
                  isDetailedChart={false}
                  // range={range}
                  // dataRange={dataRange}
                  isXSScreen={isXSScreen}
                  isMinimal={true}
                  // isPercentage={false}
                  // isCurrency={true}
                />
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            height: { lg: "90%", xs: "25rem" },
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            // border: "solid red 1px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "90%",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              // border: "solid red 1px",
            }}
          >
            {productLoad ? (
              <Box
                sx={{
                  display: "flex",
                  mt: "5rem",
                }}
              >
                <ChartLoading />
              </Box>
            ) : (
              <ProdPieChart
                data={productMixData}
                isDetailedChart={false}
                // dataRange={dataRange}
                range={range}
                // isXSScreen={isXSScreen}
                isMinimal={true}
                isPercentage={true}
                // isCurrency={true}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProductPieChart;
