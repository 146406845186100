import React from "react"; // useState
import {
  Box,
  Grid,
  // IconButton,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,

  // IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";

const theme = createTheme({
  typography: {
    fontFamily: "Lexend, sans-serif",
  },
});

const ContactUs = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box // Main Parent Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignContent: "center",
          // border: "1px solid red",
          boxSizing: "//border-box",
          alignItems: "center",
          //   background:
          //     "linear-gradient(to right, #FFF4BF 0%, #FFF4BF 50%, white 50%, white 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // border: "solid 1px green",
            width: "100%",
            boxSizing: "//border-box",
            alignItems: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              // border: "solid 1px purple",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                minHeight: { lg: "100vh", xs: "130vh" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  maxWidth: {xs:1300,xl:1600},
                  width: "100%",
                  flexDirection: { lg: "row", xs: "column" },
                }}
              >
                <Grid
                  item
                  lg={4}
                  xs={12}
                  sx={{
                    //border: "solid 1px blue",
                    display: "flex",
                    justifyContent: { lg: "center", xs: "center" },
                    alignItems: { lg: "flex-start", xs: "center" },
                    flexDirection: { xs: "column", lg: "row" },
                    background: "#FFF",
                    minHeight: { lg: "100vh", xs: "60vh" },
                    mt: { lg: "2rem", xs: 0 },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      //border: "solid 1px red",
                      height: { lg: "60%", xs: "60%" },
                      width: "80%",
                      background: "#FFEB49",
                      gap: 2,
                      padding: "1rem",
                      //borderRadius: "1rem",
                      justifyContent: "space-around",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 700,
                          textAlign: "left",
                          fontSize:{xs:"30px",xl:"50px"},
                          wordWrap: "break-word", // This makes it break
                          hyphens: "auto",// This adds hyphens if needed and supported
                        }}
                      >
                        Get started now
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "left",
                        gap: 1,
                        // height:,
                        //border: "solid 1px blue",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <PhoneIcon />
                        <Typography variant="h7"
                        sx={{
                          fontSize:{xs:"16px",xl:"25px"},
                        }}>
                          +4795070713
                          </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <EmailIcon />
                        <Typography variant="h7"
                         sx={{
                          fontSize:{xs:"16px",xl:"25px"},
                        }}>
                          support@krunch.no
                          </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <PlaceIcon />
                        <Typography variant="h7"
                         sx={{
                          fontSize:{xs:"16px",xl:"25px"},
                        }}>
                          Geitmyrsveien 73D, 0455 Oslo,
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={8}
                  xs={12}
                  sx={{
                    display: { lg: "flex", xs: "flex" },
                    justifyContent: "flex-start",
                    alignItems: "center",
                    //border: "solid 1px blue",
                    flexDirection: "column",
                    minHeight: { lg: "100%", xs: "100vh" },
                    background: "#FFF",
                    mt: { lg: "2rem", xs: 0 },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "80%",
                      width: "85%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      //border: "solid 1px green",
                    }}
                  >
                    <form
                      noValidate
                      autoComplete="off"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "2",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "2rem",
                          marginBottom: "4rem",
                        }}
                      >
                        <TextField
                          label=
                          {
                            <Typography sx={{fontSize:{xs:"16px",xl:"20px"}}}>
                          First Name
                          </Typography>}
                          variant="standard"
                          sx={{
                            // borderBottom: "1px solid",
                            boxShadow: "none",
                            width: "40%",
                          }}
                        />
                        <TextField
                          label=
                          {
                            <Typography sx={{fontSize:{xs:"16px",xl:"20px"}}}>
                          Last Name
                          </Typography>}
                          
                          variant="standard"
                          sx={{
                            //borderBottom: "1px solid",
                            boxShadow: "none",
                            width: "40%",
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "2rem",
                          marginBottom: "4rem",
                        }}
                      >
                        <TextField
                          label=
                          {
                            <Typography sx={{fontSize:{xs:"16px",xl:"20px"}}}>
                          Email
                          </Typography>}
                          
                          variant="standard"
                          sx={{
                            //borderBottom: "1px solid",
                            boxShadow: "none",
                            width: "40%",
                          }}
                        />
                        <TextField
                          label={
                            <Typography
                            sx={{fontSize:{xs:"16px",xl:"20px"}}}>
                              Phone Number

                            </Typography>
                          }
                          
                          variant="standard"
                          sx={{
                            //borderBottom: "1px solid",
                            boxShadow: "none",
                            width: "40%",
                          }}
                        />
                      </Box>

                      <Box sx={{ marginBottom: "2rem" }}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            textAlign: "left",
                            fontSize:{xs:"20px",xl:"30px"}
                          }}
                        >
                          Select Subject
                        </Typography>
                        <RadioGroup
                          row
                          sx={{
                            gap: 8,
                          }}
                        >
                          <FormControlLabel
                            value="subject1"
                            control={<Radio />}
                            label={
                            <Typography 
                            sx={{fontSize:{xs:"16px",xl:"20px"}}}>
                              Get started using Krunch
                              </Typography>}
                          />
                          <FormControlLabel
                            value="subject2"
                            control={<Radio />}
                            label={
                              <Typography 
                              sx={{fontSize:{xs:"16px",xl:"20px"}}}>
                                Support
                                </Typography>}
                          />
                          <FormControlLabel
                            value="subject4"
                            control={<Radio />}
                            label={
                              <Typography 
                              sx={{fontSize:{xs:"16px",xl:"20px"}}}>
                                Other
                                </Typography>}
                          />
                          {/* Add more options if needed */}
                        </RadioGroup>
                      </Box>

                      <Box sx={{ marginBottom: "1rem", width: "100%" }}>
                        <TextField
                          label={
                            <Typography 
                            sx={{fontSize:{xs:"16px",xl:"20px"}}}>
                              Message
                              </Typography>}
                          multiline
                          rows={4}
                          variant="standard"
                          inputProps={{ maxLength: 500 }}
                          fullWidth
                          //sx={{ //borderBottom: "1px solid", boxShadow: "none" }}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          sx={{
                            width: "24ch",
                            background: "#FFEB49",
                            color: "black",
                            "&:hover": {
                              backgroundColor: "#e3a902", // You can adjust this color based on your preference
                              // Prevent MUI Button from changing the color on hover
                              boxShadow: "none",
                            },
                            "&:active": {
                              backgroundColor: "#e3a902", // Adjust this color based on your preference
                            },
                            fontWeight: 700,
                            //borderRadius: "5px",
                            fontSize:{xs:"14px",xl:"20px"}
                          }}
                        >
                          Send Message
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ContactUs;
