import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  // Grid,
  //    Container,
  // Card,
  Typography,
  // TextField,
  // Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // CardContent,
  Tooltip,
  // Zoom,
  useMediaQuery,
  Switch,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  // Dialog,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  //   DialogActions,
  // Switch,
  // Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import getHighlightedStyle from "../utils/HighlightedStyle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LastUpdate from "../utils/LastUpdate";
import Prefix from "../utils/PrefixLink";
import ChartLoading from "../components/Loading/ChartLoading";
import ComparisonLineChart from "../components/LineCharts/ComparisonLineChart";
import ComparisonHourlyChart from "../components/LineCharts/ComparisonHourlyChart";
import SnackbarAlert from "../utils/SnackbarAlert";
import dayjs from "dayjs";
import downloadComparisons from "../utils/ExcelDownloads/DownloadComparisons";
import NavigationButtons from "../utils/HelpButton";
import ComparisonTable from "./DashboardComponents/ComparisonTable";
import SettingsDialog from "../components/SettingsDialog";
import FormatDesiredDate from "../utils/FormatDesiredDate";
import TitleStyle from "../components/Style/Title";
import HelpModeButton from "../components/HelpModeButton";

const isAdmin = JSON.parse(localStorage.getItem("user"))?.company_admin;

// const comp = localStorage.getItem("company");
const token = localStorage.getItem("token");
const ITEM_HEIGHT = "1rem";
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const prefix = Prefix();

const Comparisons = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  hasPredictionData,
  setHasPredictionData,
  range,
  company,
  setCompany,
}) => {
  const latest_created_at = localStorage.getItem("latest_records");
  const now = latest_created_at ? new Date(latest_created_at) : new Date();
  const email = JSON.parse(localStorage.getItem("user"))?.email;

  //   const [correspondingStartDate, setCorrespondingStartDate] = useState(null);
  //   const [correspondingEndDate, setCorrespondingEndDate] = useState(null);

  //   const [dataRange, setDataRange] = useState("today");
  const [helpMode, setHelpMode] = useState(false);
  const [counter, setCounter] = useState(1);
  // const [company, setCompany] = useState(comp);

  // const [hasPredictionData, setHasPredictionData] = useState(false);
  // const [selectedRestaurant, setSelectedRestaurant] = useState([]);
  const [defaultRestaurants, setDefaultRestaurants] = useState([]);
  const theme = useTheme();

  const [profitLoad, setProfitLoad] = useState(false);
  // const [prodLineLoad, setProdLineLoad] = useState(false);

  //   const [prodLineLoad, setProdLineLoad] = useState(false);

  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const [selectedPage, setSelectedPage] = useState(
  //   isAdmin ? "company" : "multiple"
  // );
  //   const maxSteps = 9;

  // const [range, setRange] = useState("day");

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState(null);

  const [comparisonLineData, setComparisonLineData] = useState([]);
  const [selectedLine, setSelectedLine] = useState("delivery_cost"); // initial value

  // const [selectedSupergroup, setSelectedSupergroup] = useState("");
  // const [articleSupergroups, setArticleSupergroups] = useState([]);

  // const [productMixData, setProductMixData] = useState([]);
  // const [subproductMixData, setSubproductMixData] = useState([]);

  // const [filteredChartData, setFilteredChartData] = useState([]);
  const [subProductChartData, setSubProductChartData] = useState([]); // Chart data for sub-products
  // const [selectedSubgroup, setSelectedSubgroup] = useState([]);
  // const [articleSubgroups, setArticleSubgroups] = useState([]);

  // const [profitKPITotal, setProfitKPITotal] = useState(0);
  const [firstFetchSum, setFirstFetchSum] = useState(0);
  const [secondFetchSum, setSecondFetchSum] = useState(0);
  // const [percentageDifference, setPercentageDifference] = useState(0);

  const [firstProductSum, setFirstProductSum] = useState(0);
  const [secondProductSum, setSecondProductSum] = useState(0);
  // const [productSumKPI, setProductSumKPI] = useState(0);
  // const [productPercentageDifference, setProductPercentageDifference] =
  //   useState(0);

  const [firstSubProductSum, setFirstSubProductSum] = useState(0);
  const [secondSubProductSum, setSecondSubProductSum] = useState(0);
  // const [subProductSumKPI, setSubProductSumKPI] = useState(0);
  // const [subProductPercentageDifference, setSubProductPercentageDifference] =
  //   useState(0);

  // const [isSubCategoryVisible, setIsSubCategoryVisible] = useState(false);
  const [isCurrency, setIsCurrency] = useState(true);

  // const [hasProductChanged, setHasProductChanged] = useState(false);
  const maxSteps = 11;

  const abortControllerRef = useRef(new AbortController());

  const downloadOptions = [
    () =>
      downloadComparisons(
        comparisonLineData,
        // productMixData,
        subProductChartData,
        firstFetchSum,
        secondFetchSum,
        firstProductSum,
        secondProductSum,
        firstSubProductSum,
        secondSubProductSum,
        selectedRestaurant,
        allSums
        // comparisonLineData
      ),
    // Add other function calls as necessary
  ];

  const handleToggleCurrency = () => {
    setIsCurrency((prev) => !prev);
  };

  const handleLineChange = (event) => {
    // Find the original option value (with underscores) that corresponds to the selected formatted option
    const originalValue = options.find(
      (option) => formatOption(option) === event.target.value
    );
    // setExtraSelectedLine(originalValue);
    setSelectedLine(originalValue);
  };

  // Options for the select component (original format)
  const options = [
    // "total_net",
    // "gross_profit",
    // "net_profit",
    // "cost",
    "delivery_cost",
    // "employee_cost",
    "rent",
  ];

  // Function to format the display of the options
  const formatOption = (option) => {
    const displayMapping = {
      total_net: "Sales",
      gross_profit: "Gross Profit I",
      operating_profit: "Gross Profit II",
      net_profit: "Net Profit",
      cost: "Cost of Goods",
      employee_cost: "Employee Cost",
      delivery_cost: "Delivery Cost",
      rent: "Rent",
    };

    return (
      displayMapping[option] ||
      option
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    );
  };
  // const difference = firstProductSum - secondProductSum;
  // const differenceFirst = firstFetchSum - secondFetchSum;

  // let tooltipMessage;
  // if (percentageDifference > 0) {
  //   tooltipMessage = `${Math.abs(
  //     percentageDifference
  //   )}% increase from last period`;
  //   // Logic to display KeyboardArrowUpwardIcon
  // } else if (percentageDifference < 0) {
  //   tooltipMessage = `${Math.abs(
  //     percentageDifference
  //   )}% decrease from last period`;
  //   // Logic to display KeyboardArrowDownwardIcon
  // } else {
  //   tooltipMessage = "No change from last period";
  // }

  // let tooltipproductMessage;
  // if (productPercentageDifference > 0) {
  //   tooltipproductMessage = `${Math.abs(
  //     productPercentageDifference
  //   )}% increase from last period`;
  //   // Logic to display KeyboardArrowUpwardIcon
  // } else if (productPercentageDifference < 0) {
  //   tooltipproductMessage = `${Math.abs(
  //     productPercentageDifference
  //   )}% decrease from last period`;
  //   // Logic to display KeyboardArrowDownwardIcon
  // } else {
  //   tooltipproductMessage = "No change from last period";
  // }

  const getRestaurantNameById = (restaurantName) => {
    const restaurant = defaultRestaurants.find(
      (r) => r.name === restaurantName
    );
    return restaurant ? restaurant.name : "";
  };

  // const handleDropdownChange = (e) => {
  //   const selectedGroup = e.target.value;
  //   setSelectedSupergroup(selectedGroup);
  // };

  if (selectedPage === "individual") {
    setSelectedPage("multiple");
  }
  const handlePredictionData = () => {
    setHasPredictionData((prev) => !prev);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRestaurant(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handlePageChange = (newValue) => {
    if (newValue === "company" && !isAdmin) {
      newValue = "multiple";
    }
    setSelectedPage(newValue); // Update the state with the new value

    // if (!newValue) return; // If no button is selected, do nothing

    if (newValue === "multiple") {
    } else if (newValue === "company") {
      setSelectedRestaurant([]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleHelpMode = () => {
    setHelpMode(!helpMode);
    setCounter(1); // Reset counter when toggling helpMode
  };

  const handleBackwardClick = () => {
    // Reset state variables to null or initial state

    const newStartDate = adjustDates(startDate, dataRange, "backward");
    setStartDate(newStartDate);

    if (dataRange === "thismonth") {
      // Set endDate to the last day of the new month
      const monthEndDate = new Date(newStartDate);
      monthEndDate.setMonth(monthEndDate.getMonth() + 1, 0); // Last day of the month
      setEndDate(monthEndDate.toISOString().split("T")[0]);
    } else {
      setEndDate(adjustDates(endDate, dataRange, "backward"));
    }
  };
  // function formatDateToDesiredFormat(dateString) {
  //   const date = new Date(dateString);
  //   if (dataRange === "today") {
  //     return date.toLocaleDateString(undefined, {
  //       year: "numeric",
  //       month: "short",
  //       weekday: "short",
  //       day: "numeric",
  //     });
  //   } else {
  //     return date.toLocaleDateString(undefined, {
  //       year: "numeric",
  //       month: "short",
  //       day: "numeric",
  //     });
  //   }
  // }

  const adjustDates = (date, dataRange, direction) => {
    let newDate = new Date(date);
    const adjustDay = direction === "forward" ? 1 : -1;
    const adjustWeek = direction === "forward" ? 7 : -7;
    const adjustMonth = direction === "forward" ? 1 : -1;

    switch (dataRange) {
      case "today":
        newDate.setDate(newDate.getDate() + adjustDay);
        break;
      case "thisweek":
        newDate.setDate(newDate.getDate() + adjustWeek);
        break;
      case "thismonth":
        newDate.setMonth(newDate.getMonth() + adjustMonth);
        break;
      default:
        break;
    }

    return newDate.toISOString().split("T")[0];
  };
  const handleForwardClick = () => {
    // Reset state variables to null or initial state

    const newStartDate = adjustDates(startDate, dataRange, "forward");
    setStartDate(newStartDate);

    if (dataRange === "thismonth") {
      // Set endDate to the last day of the new month
      const monthEndDate = new Date(newStartDate);
      monthEndDate.setMonth(monthEndDate.getMonth() + 1, 0); // Last day of the month
      setEndDate(monthEndDate.toISOString().split("T")[0]);
    } else {
      setEndDate(adjustDates(endDate, dataRange, "forward"));
    }
  };
  const fetchRestaurants = useCallback(async () => {
    // Replace the URL with the appropriate API endpoint for fetching a single task
    // setLoad(true);
    const restro_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();

      const userDefaultRestaurants = JSON.parse(
        localStorage.getItem("user")
      )?.default_restaurants;

      let filteredRestaurants = restro_data.results.filter(
        (restaurant) =>
          userDefaultRestaurants.includes(restaurant.id) &&
          restaurant.name !== "Fisketorget Stavanger"
      );
      if (userDefaultRestaurants && userDefaultRestaurants.length > 0) {
        // Filter restaurants based on default_restaurants IDs
        filteredRestaurants = restro_data.results.filter((restaurant) =>
          userDefaultRestaurants.includes(restaurant.id)
        );
      }
      if (!userDefaultRestaurants || userDefaultRestaurants.length === 0) {
        filteredRestaurants = restro_data.results.filter(
          (restaurant) => restaurant.name !== "Fisketorget Stavanger"
        );
      }
      // Apply sorting to move 'Food Truck' to the bottom of the list
      filteredRestaurants.sort((a, b) => {
        const isAFoodTruck = a.name.includes("Food Truck");
        const isBFoodTruck = b.name.includes("Food Truck");

        if (isAFoodTruck && !isBFoodTruck) {
          return 1; // Place 'a' after 'b'
        } else if (!isAFoodTruck && isBFoodTruck) {
          return -1; // Place 'a' before 'b'
        }
        return 0; // Keep original order for other restaurants
      });

      setDefaultRestaurants(filteredRestaurants);
    }
  }, [company]);
  const tooltipContent = {
    1: (
      <Typography variant="body2">
        You can filter the dashboards to see the whole company, individual
        restaurants or a combination of multiple restaurants
      </Typography>
    ),
    2: (
      <Typography variant="body2">
        Choose to filter the dashboard by a single day, week or a whole month.
        You can easily navigate back and forth with the arrows
      </Typography>
    ),
    3: (
      <Typography variant="body2">
        Choose to only see actual sales (To Date) or to include the projected
        sales. When you choose the projected view you will see the actual sales
        when there is actual sales, and predicted sales for all dates or hours
        in the future. Look for the vertical line in the graphs to see where the
        actual sales stop and the projected begins
      </Typography>
    ),
    4: (
      <Typography variant="body2">
        This card gives you the key numbers for the selected restaurants and
        time period. Click on the % NOK toggle to switch between percentage view
        and NOK view
      </Typography>
    ),
    5: (
      <Typography variant="body2">
        See your sales for the past, present and future. Hover over the data
        points to see how each number was calculated.
      </Typography>
    ),
    6: (
      <Typography variant="body2">
        See your Gross Profit I for the past, present and future. Hover over the
        data points to see how each number was calculated.
      </Typography>
    ),
    7: (
      <Typography variant="body2">
        See your Gross Profit II for the past, present and future. Hover over
        the data points to see how each number was calculated.
      </Typography>
    ),
    8: (
      <Typography variant="body2">
        See your Cost of Goods for the past, present and future. Hover over the
        data points to see how each number was calculated.
      </Typography>
    ),
    9: (
      <Typography variant="body2">
        See your Employee Cost for the past, present and future. Hover over the
        data points to see how each number was calculated.
      </Typography>
    ),
    10: (
      <Typography variant="body2">
        See your Delivery Cost for the past, present and future. Hover over the
        data points to see how each number was calculated.
      </Typography>
    ),

    // ... add more steps as needed
  };
  if (selectedLine === "rent") {
    tooltipContent[10] = (
      <Typography variant="body2">
        This chart shows the amount of rent that was incurred during the period.
      </Typography>
    );
  }
  //tutorial guide

  const getTooltipMessage = (selectedLine) => {
    switch (selectedLine) {
      case "delivery_cost":
        return "Delivery cost is the amount paid to third party delivery services.";
      case "rent":
        return "The amount of rent that was incurred during the period.";
      // Add more cases as needed for other lines
      default:
        return "Description not available.";
    }
  };

  const handleNext = () => {
    setCounter((prev) => {
      const newCounter = prev + 1;
      if (newCounter >= 11) {
        setHelpMode(false);
      } else {
        // Scroll logic for mobile view in helpMode, only after counter reaches 4
        if (helpMode && newCounter > 4) {
          const remInPixels = parseFloat(
            getComputedStyle(document.documentElement).fontSize
          );
          window.scrollBy({
            top: 30 * remInPixels, // Scroll by 30rem in pixels
            behavior: "smooth",
          });
        }
      }
      return newCounter;
    });
  };
  console.log(handleToggleCurrency);

  const handlePrevious = () => {
    setCounter((prev) => {
      const newCounter = Math.max(prev - 1, 1);

      // Scroll logic for mobile view in helpMode, only if counter was above 4
      if (helpMode && prev > 4) {
        const remInPixels = parseFloat(
          getComputedStyle(document.documentElement).fontSize
        );
        window.scrollBy({
          top: -30 * remInPixels, // Scroll upwards by 30rem in pixels
          behavior: "smooth",
        });
      }

      return newCounter;
    });
  };
  const getCorrespondingDates = (date1, date2 = null) => {
    const getISOWeek = (date) => {
      const tempDate = new Date(date.getTime());
      tempDate.setHours(0, 0, 0, 0);
      // Thursday in current week decides the year.
      tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
      // January 4 is always in week 1.
      const week1 = new Date(tempDate.getFullYear(), 0, 4);
      // Adjust to Thursday in week 1 and count number of weeks from date to week1.
      return (
        1 +
        Math.round(
          ((tempDate.getTime() - week1.getTime()) / 86400000 -
            3 +
            ((week1.getDay() + 6) % 7)) /
            7
        )
      );
    };

    const getCorrespondingDate = (date) => {
      const weekNumber = getISOWeek(date);
      const dayOfWeek = (date.getDay() + 6) % 7; // Adjust for Monday-based week

      const previousYearStart = new Date(date.getFullYear() - 1, 0, 1);
      const daysToNextMonday = (7 - previousYearStart.getDay() + 1) % 7;
      const correspondingDate = new Date(
        previousYearStart.getFullYear(),
        0,
        1 + daysToNextMonday + (weekNumber - 1) * 7 + dayOfWeek
      );

      const formattedDate = `${correspondingDate.getFullYear()}-${(
        correspondingDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${correspondingDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      return formattedDate;
    };

    // Check if two dates are provided or just one
    if (date2) {
      const start = new Date(date1);
      const end = new Date(date2);
      return [getCorrespondingDate(start), getCorrespondingDate(end)];
    } else {
      return getCorrespondingDate(new Date(date1));
    }
  };

  const getGrossProfit = useCallback(async () => {
    if (
      (!selectedRestaurant || selectedRestaurant.length === 0) &&
      selectedPage === "multiple"
    ) {
      return;
    }

    const newStartDate = getCorrespondingDates(startDate);
    const newEndDate = getCorrespondingDates(endDate);

    const buildUrl = (isCorresponding = false) => {
      let url = `${prefix}/api/salesdata/gross_profit`;
      if (dataRange === "today") {
        url = `${prefix}/api/salesdata/hourly_gross_profit`;
      }

      if (company) {
        url += `?company=${company}`;
      }

      let startDateToUse = isCorresponding ? newStartDate : startDate;
      let endDateToUse = isCorresponding ? newEndDate : endDate;

      if (startDateToUse && endDateToUse) {
        url += `&start_date=${startDateToUse}&end_date=${endDateToUse}`;
      }

      if (selectedRestaurant.length > 0) {
        url += `&restaurant=${JSON.stringify(selectedRestaurant)}`;
      }

      return url;
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    if ((endDate && !startDate) || (startDate && !endDate)) {
      return;
    }
    if (startDate > endDate) {
      setOpen(true);
      setSeverity("error");
      setMessage("Start date cannot be further than end date");
      return;
    }

    // Abort any ongoing fetches and create a new controller
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    let combinedData = {};

    const mergeData = (newData, isCorresponding) => {
      let resultsToUse = hasPredictionData
        ? newData.results.prediction
        : newData.results.historical;

      if (parseInt(resultsToUse.length) <= 0) {
        return;
      }

      // Assume resultsToUse is being populated here from fetched data
      resultsToUse.forEach((entry) => {
        const lineTypes = [
          "total_net",
          "gross_profit",
          "operating_profit",
          // "net_profit",
          "cost",
          "delivery_cost",
          "employee_cost",
          "rent",
        ];
        lineTypes.forEach((line) => {
          const processedData = processEntryForSelectedLine(
            entry,
            line,
            isCorresponding,
            isCurrency
          );
          if (processedData) {
            // Check if processedData is valid
            if (!combinedData[line]) {
              combinedData[line] = []; // Initialize as an empty array
            }

            // Determine which dataset to push to based on isCorresponding
            const datasetIndex = isCorresponding ? 1 : 0;

            // If the dataset for current or corresponding doesn't exist, create it
            if (!combinedData[line][datasetIndex]) {
              combinedData[line][datasetIndex] = {
                id: `${line} ${isCorresponding ? "corresponding" : "current"}`,
                data: [],
              };
            }

            // Now push the processed data into the appropriate dataset
            combinedData[line][datasetIndex].data.push({
              x: processedData.x,
              y: processedData.y,
              actualDate: processedData.actualDate, // Include actualDate in each data point
            });
          }
        });
      });
    };

    setProfitLoad(true);
    let fetchAborted = false; // Flag to track if fetch was aborted

    try {
      let url = buildUrl();
      const response = await fetch(url, {
        headers: headers,
        signal: abortControllerRef.current.signal,
      });
      const data = await handleFetchResponse(response);
      mergeData(data, false);

      let urlWithCorrespondingDates = buildUrl(true);
      const correspondingResponse = await fetch(urlWithCorrespondingDates, {
        headers: headers,
        signal: abortControllerRef.current.signal,
      });
      const correspondingData = await handleFetchResponse(
        correspondingResponse
      );
      mergeData(correspondingData, true);
    } catch (error) {
      if (error.name === "AbortError") {
        fetchAborted = true;
      } else {
        handleFetchError(error);
      }
    } finally {
      // setComparisonLineData(Object.values(combinedData));
      setComparisonLineData(combinedData);
      if (!fetchAborted) {
        setProfitLoad(false);
      }
    }

    function handleFetchResponse(response) {
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }
      return response.json();
    }

    function handleFetchError(error) {
      if (error.name !== "AbortError") {
        console.error(error);
        setProfitLoad(false);
      }
    }

    function processEntryForSelectedLine(
      entry,
      selectedLine,
      isCorresponding,
      isCurrency
    ) {
      const {
        total_net,
        gross_profit,
        // net_profit,
        date,
        employee_cost,
        rent,
        delivery_cost,
        cost,
        operating_profit,
      } = entry;

      let date_period = new Date(date);
      let actualDate = new Date(date); // Always preserve the actual date
      if (isCorresponding) {
        const startYear = new Date(startDate).getFullYear();
        date_period.setFullYear(startYear);

        // Calculate the difference in days of the week and adjust the date
        let dayDifference = actualDate.getDay() - date_period.getDay();
        date_period.setDate(date_period.getDate() + dayDifference);
      }

      if (isCorresponding) {
        const startYear = new Date(startDate).getFullYear();
        date_period.setFullYear(startYear);
      }
      // Convert date_period to a weekday name if dataRange is 'thisweek'

      if (dataRange === "thisweek") {
        date_period = date_period.toLocaleDateString("en-US", {
          weekday: "long",
        });
      } else if (dataRange === "today") {
        const newDate = dayjs(date);
        const formattedDate = newDate.format("HH:mm");
        date_period = formattedDate;
      } else if (dataRange === "thismonth") {
        let newDate = dayjs(date);
        // Assuming isCorresponding is a condition you have defined
        // if (isCorresponding) {
        //   // Initialize subtraction value
        //   let a = 1;

        //   // Extract month and day from the date
        //   const monthDay = newDate.format("MM-DD");

        //   // Check if the date is during February (from "02-01" to "02-29")
        //   if (monthDay >= "02-01" && monthDay <= "02-29") {
        //     // The date is within February, so keep a as 1
        //     a = 1;
        //   } else if (monthDay < "02-01") {
        //     // The date is before February, change a to 1
        //     a = 1;
        //   } else {
        //     // The date is after February, change a to 2
        //     a = 2;
        //   }

        //   // Subtract days based on the value of a
        //   newDate = newDate.subtract(a, "day");
        //   newDate = newDate.add(1, "year");
        // }

        if (isCorresponding) {
          // Initialize subtraction value
          let a = 1;

          // Convert the entry date, start date, and end date from strings or JavaScript Date to dayjs objects
          const start_date = dayjs(startDate);
          const end_date = dayjs(endDate);

          // Extract month and day from the date
          const monthDay = newDate.format("MM-DD");

          // Check if the date is during February (from "02-01" to "02-29")
          if (monthDay >= "02-01" && monthDay <= "02-29") {
            // The date is within February, so keep a as 1
            a = 1;
          } else if (monthDay < "02-01") {
            // The date is before February, change a to 1
            a = 1;
          } else {
            // The date is after February, change a to 2
            a = 2;
          }

          // Additional check if both start_date and end_date are within the year 2022
          if (start_date.year() === 2023 && end_date.year() === 2023) {
            a = 1;
          }

          // Subtract days based on the value of a
          newDate = newDate.subtract(a, "day");
          newDate = newDate.add(1, "year");
        }

        const formattedDate = newDate.format("YYYY-MM-DD"); // Format to retain only month and day
        date_period = formattedDate;
      }

      let yValue;
      switch (selectedLine) {
        case "total_net":
          yValue = total_net;
          break;
        case "gross_profit":
          yValue = gross_profit;
          break;

        case "operating_profit":
          yValue = operating_profit;
          break;
        // case "net_profit":
        //   yValue = net_profit;
        //   break;
        case "cost":
          yValue = cost;
          break;

        case "delivery_cost":
          yValue = delivery_cost;
          break;

        case "employee_cost":
          yValue = employee_cost;
          break;

        case "rent":
          yValue = rent;
          break;

        default:
          yValue = null;
      }
      if (!isCurrency && total_net > 0) {
        // Divide by the total_net of the same entry for percentage calculation
        yValue = (yValue / total_net) * 100;
      }

      return {
        x: date_period,
        y: parseFloat(yValue.toFixed(1)),
        actualDate: actualDate,
      };
    }
  }, [
    endDate,
    selectedRestaurant,
    startDate,
    hasPredictionData,
    // hasPredictionData,
    company,
    selectedPage,
    dataRange,
    isCurrency,
  ]);

  function calculateAllSums(comparisonLineData) {
    let allSums = {};
    if (typeof comparisonLineData !== "object") {
      return allSums; // Return empty object if the input is not an object
    }
    Object.keys(comparisonLineData).forEach((line) => {
      const currentData = Array.isArray(comparisonLineData[line][0]?.data)
        ? comparisonLineData[line][0].data
        : [];
      const correspondingData = Array.isArray(comparisonLineData[line][1]?.data)
        ? comparisonLineData[line][1].data
        : [];
      const currentSum = currentData.reduce(
        (acc, entry) => acc + (Number.isFinite(entry.y) ? entry.y : 0),
        0
      );
      const correspondingSum = correspondingData.reduce(
        (acc, entry) => acc + (Number.isFinite(entry.y) ? entry.y : 0),
        0
      );
      allSums[line] = { currentSum, correspondingSum };
    });
    return allSums;
  }

  function calculatePercentageDifferences(allSums) {
    let percentageDifferences = {};
    if (typeof allSums !== "object") {
      return percentageDifferences; // Return empty object if the input is not an object
    }
    Object.keys(allSums).forEach((line) => {
      const { currentSum, correspondingSum } = allSums[line];

      const percentageDifference =
        !Number.isFinite(currentSum) || !Number.isFinite(correspondingSum)
          ? "Invalid Data"
          : correspondingSum === 0
          ? "No Data for Corresponding period"
          : currentSum === 0
          ? "No Data for Current period"
          : ((currentSum - correspondingSum) / Math.abs(correspondingSum)) *
            100;
      percentageDifferences[line] = percentageDifference;
    });
    return percentageDifferences;
  }

  // Now, you can use these functions with your comparisonLineData
  const allSums = calculateAllSums(comparisonLineData);

  const percentageDifferences = calculatePercentageDifferences(allSums);
  // console.log(percentageDifferences, allSums, "khatra");

  // This will give you the total sums and percentage differences for all line categories.
  useEffect(() => {
    setFirstProductSum(0);
    setFirstSubProductSum(0);
    setFirstFetchSum(0);
    setSecondFetchSum(0);
    setSecondProductSum(0);
    setSecondSubProductSum(0);
  }, [dataRange]);

  useEffect(() => {
    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant?.length === 0) {
      // setFirstProductSum(0);
      // setFirstSubProductSum(0);
      // setFirstFetchSum(0);
      // setSecondFetchSum(0);
      // setSecondProductSum(0);
      // setSecondSubProductSum(0);
      setComparisonLineData([]);
      setSubProductChartData([]);
      // setFilteredChartData([]);
    } else {
      getGrossProfit();
    }
  }, [selectedPage, selectedRestaurant, getGrossProfit]);

  // useEffect(() => {
  //   if (selectedPage === "company") {
  //     setSelectedRestaurant([]);
  //   }
  // }, [setSelectedRestaurant, selectedPage]);

  useEffect(() => {
    if (Array.isArray(selectedRestaurant) && selectedRestaurant.length > 1) {
      const firstRestaurantArray = [selectedRestaurant[0]]; // Make it an array containing only the first restaurant
      setSelectedRestaurant(firstRestaurantArray);
    }
  }, [selectedRestaurant, setSelectedRestaurant]);


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        // border: "solid 1px red",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: { lg: 1250, xl: 1600, md: 800 },
          alignItems: "center",
          // border: "solid 1px blue",
          boxSizing: "border-box",
          height: { lg: "200vh", xs: "1" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            // border: "solid 1px green",
            height: "100%",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: { lg: 1, xs: 1 },
              //   border: "solid 1px red",
              justifyContent: { lg: "space-evenly", xl: "space-evenly" },
              height: { lg: "10vh", xs: "20%" },
              alignItems: "center",
              mt: { lg: 2, xs: 0 },
            }}
          >
            <>
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 1}
              >
                <Box
                  sx={{
                    ...(counter === 1 && helpMode ? getHighlightedStyle() : {}),
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column" },
                    alignItems: "center",
                    mt: { lg: 0, xs: 1 },
                    gap: { lg: 0, xs: 1 },
                    // border: "solid 1px blue",
                  }}
                >
                  {/* {selectedPage === "multiple" ? ( */}
                  <FormControl sx={{ width: 200, m: 0 }}>
                    <InputLabel id="demo-simple-select-label">
                      {selectedPage === "company"
                        ? company
                        : "Select Restaurant"}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        selectedPage === "company" ? [] : selectedRestaurant
                      }
                      label={
                        selectedPage === "company"
                          ? company
                          : "Select Restaurant"
                      }
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map((name) => getRestaurantNameById(name))
                              .join(", ")
                          : getRestaurantNameById(selected)
                      }
                      disabled={selectedPage === "company"}
                      onChange={handleChange}
                      sx={{
                        background: "#F8F8F8",
                        mx: ".5rem",
                        height: "3rem",
                        color: "black",
                        boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                        "& .MuiSelect-select": {
                          padding: "0",
                          textIndent: "0.25em",
                          textAlign: "center",
                        },
                      }}
                    >
                      {defaultRestaurants.map((restaurant) => (
                        <MenuItem key={restaurant.id} value={restaurant.name}>
                          {restaurant.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <ToggleButtonGroup
                      value={selectedPage}
                      exclusive
                      disabled={!isAdmin}
                      onChange={(event) => {
                        handlePageChange(event.target.value);
                      }}
                      sx={{
                        "& .MuiToggleButton-root": {
                          // height: "3rem",
                          background: "#F8F8F8",
                          "&.Mui-selected": {
                            background: "#D6D6D6", // Darker shade for the selected button
                          },
                        },
                        mx: ".5rem",
                      }}
                      aria-label="Range"
                    >
                      <ToggleButton value="company" aria-label="company">
                        Company
                      </ToggleButton>
                      <ToggleButton value="multiple" aria-label="multiple">
                        Restaurants
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>
                </Box>
              </Tooltip>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  mt: { lg: 3 },
                }}
              >
                <Tooltip
                  arrow
                  title={tooltipContent[counter]}
                  open={helpMode && counter === 2}
                >
                  <Box
                    sx={{
                      ...(counter === 2 && helpMode
                        ? getHighlightedStyle()
                        : {}),
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      // border: "solid 1px red",
                      height: "100%",
                      width: { xs: "80%", lg: "100%" },
                    }}
                  >
                    <ArrowBackIcon
                      onClick={handleBackwardClick}
                      sx={{
                        padding: "8px", // Adds space around the icon, increasing the border size
                        cursor: "pointer", // Ensures the cursor is a pointer // Ensures the cursor is a pointer
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                          borderRadius: "50%", // Makes the background round
                        },
                      }}
                    />

                    <FormControl sx={{ width: { lg: 250, xs: 300 }, m: 0 }}>
                      {/* <InputLabel id="range-select-label">Frequency</InputLabel> */}
                      <ToggleButtonGroup
                        value={dataRange}
                        // The range is the same. Just hour for today.
                        //  Otherwise it's just the startdate and enddate that need to be adjusted accordingly
                        exclusive
                        disabled={profitLoad}
                        onChange={(e) => {
                          // setFilteredChartData([]);
                          setComparisonLineData([]);
                          setSubProductChartData([]);

                          setDataRange(e.target.value);
                          let startingDate;
                          let endingDate;
                          switch (e.target.value) {
                            case "today":
                              startingDate = new Date(
                                Date.UTC(
                                  now.getUTCFullYear(),
                                  now.getUTCMonth(),
                                  now.getUTCDate()
                                )
                              );
                              endingDate = new Date(
                                Date.UTC(
                                  now.getUTCFullYear(),
                                  now.getUTCMonth(),
                                  now.getUTCDate()
                                )
                              );
                              break;
                            case "thisweek":
                              // Find the start of the week (Monday)
                              startingDate = new Date(
                                Date.UTC(
                                  now.getUTCFullYear(),
                                  now.getUTCMonth(),
                                  now.getUTCDate() -
                                    now.getUTCDay() +
                                    (now.getUTCDay() === 0 ? -6 : 1)
                                )
                              );
                              // Find the end of the week (Sunday)
                              endingDate = new Date(startingDate);
                              endingDate.setDate(startingDate.getDate() + 6); // Add 6 days to get to Sunday
                              break;

                            case "thismonth":
                              startingDate = new Date(
                                Date.UTC(
                                  now.getUTCFullYear(),
                                  now.getUTCMonth(),
                                  1
                                )
                              );
                              endingDate = new Date(
                                Date.UTC(
                                  now.getUTCFullYear(),
                                  now.getUTCMonth() + 1,
                                  0
                                ) // Set to the last day of the current month
                              );
                              break;
                            default:
                              // Handle any other cases or default behavior
                              break;
                          }
                          setStartDate(
                            startingDate?.toISOString().split("T")[0]
                          );
                          setEndDate(endingDate?.toISOString().split("T")[0]);
                        }}
                        sx={{
                          "& .MuiToggleButton-root": {
                            height: "3rem",
                            background: "#F8F8F8",
                            "&.Mui-selected": {
                              background: "#D6D6D6", // Darker shade for the selected button
                            },
                          },
                        }}
                        // disabled={staffLoad || profitLoad}
                        aria-label="Range"
                      >
                        <ToggleButton value="today" aria-label="Today">
                          Day
                        </ToggleButton>
                        <ToggleButton value="thisweek" aria-label="This Week">
                          Week
                        </ToggleButton>
                        <ToggleButton
                          value="thismonth"
                          aria-label="This Month"
                          //   disabled
                        >
                          Month
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>

                    <ArrowForwardIcon
                      onClick={handleForwardClick}
                      sx={{
                        padding: "8px", // Adds space around the icon, increasing the border size
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                          borderRadius: "50%", // Makes the background round
                        },
                      }}
                    />
                  </Box>
                </Tooltip>
                <FormatDesiredDate
                  startDate={startDate}
                  endDate={endDate}
                  dataRange={dataRange}
                />
              </Box>
            </>

            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 3}
            >
              <Tooltip
                title={
                  <Typography variant="body1">
                    Show only numbers back in time or include predicted numbers
                    as well
                  </Typography>
                }
                arrow
                placement="bottom"
              >
                <Box
                  sx={{
                    ...(counter === 3 && helpMode ? getHighlightedStyle() : {}),

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                    }}
                  >
                    To Date
                  </Typography>
                  <Switch
                    checked={hasPredictionData}
                    onChange={handlePredictionData}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                    }}
                  >
                    Projected
                  </Typography>
                </Box>
              </Tooltip>
            </Tooltip>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!isXSScreen && <LastUpdate />}
            </Box>
            <Box
              sx={{
                width: "150px",
                marginBottom: { xs: "1rem", lg: 0 }, // Adds bottom margin on xs screens
              }}
            >
              <SettingsDialog
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                range={range}
                setDataRange={setDataRange}
                downloadOptions={downloadOptions}
                company={company}
                setCompany={setCompany}
                email={email}
              />
              <HelpModeButton
                isXSScreen={isXSScreen}
                handleHelpMode={handleHelpMode}
                helpMode={helpMode}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              mt: { lg: 0, xs: 2 },
              // border: "solid 1px red",
              justifyContent: { lg: "flex-start" },
              alignItems: "center",
              height: { lg: "50vh", xl: "50vh", xs: "120vh" },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 4}
              placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
            >
              <Box
                sx={{
                  ...(counter === 4 && helpMode ? getHighlightedStyle() : {}),
                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "40%", xs: "90%" },
                  gap: { lg: 1, xs: 1 },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 4 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "30rem" },
                  fontFamily: "'Montserrat', sans-serif",
                  paddingY: { lg: 0, xs: 1 },
                  // border: "solid 1px red",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: "10%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "solid 1px red",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      my: "0.5rem",
                      flexGrow: "1",
                      fontWeight: 400,
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    Quick Overview
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    height: "90%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    // border: "solid 1px red",
                  }}
                >
                  <ComparisonTable
                    allSums={allSums}
                    percentageDifferences={percentageDifferences}
                    profitLoad={profitLoad}
                  />
                </Box>
                {/*<Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "10%",
                    width: "90%",
                    // border: "solid 1px green",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "'Montserrat', sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    {formatOption(selectedLine)} Total
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "30%",
                    width: "90%",
                    // border: "solid 1px green",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        fontWeight: 400,
                      }}
                    >
                      This Year's Period
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      {new Intl.NumberFormat("nb-NO", {
                        //style: "currency",
                        //currency: "NOK",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(firstFetchSum)}{" "}
                      NOK
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        fontWeight: 400,
                      }}
                    >
                      Last Year's Period
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      {new Intl.NumberFormat("nb-NO", {
                        //style: "currency",
                        //currency: "NOK",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(secondFetchSum)}{" "}
                      NOK
                    </Typography>
                  </Box>

                  <Tooltip
                    sx={{
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                    placement="top"
                    arrow
                    title={<Typography>{tooltipMessage}</Typography>}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "'Montserrat', sans-serif",
                        }}
                      >
                        Difference
                      </Typography>

                      <Typography
                        sx={{
                          fontFamily: "'Montserrat', sans-serif",
                          // border: "solid 1px  red",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {firstFetchSum === 0 ||
                        secondFetchSum === 0 ? null : differenceFirst > 0 ? (
                          <KeyboardDoubleArrowUpIcon color="success" />
                        ) : differenceFirst < 0 ? (
                          <KeyboardDoubleArrowDownIcon color="error" />
                        ) : null}
                        {new Intl.NumberFormat("nb-NO", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(firstFetchSum - secondFetchSum)}{" "}
                        NOK
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box> */}
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 5}
            >
              <Box
                sx={{
                  ...(counter === 5 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "60%", xs: "90%" },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 5 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "30rem" },
                  // fontFamily: "'Montserrat', sans-serif",
                  paddingY: { lg: 0, xs: 1 },
                  // border: "solid 1px red",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: { lg: "20%", xs: "10%" },
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    // border: "solid 1px red",
                  }}
                >
                  <TitleStyle tooltipKey="sales">Sales</TitleStyle>
                  {/* <FormControl
                    sx={{
                      width: isXSScreen ? "6rem" : "12rem",
                      mr: 1,
                    }}
                  >
                    <InputLabel id="select-label">Select Line</InputLabel>
                    <Select
                      labelId="select-label"
                      value={formatOption(selectedLine)} // Display the formatted selected line
                      label="Select Line"
                      onChange={handleLineChange}
                      MenuProps={MenuProps}
                    >
                      {options.map((option) => (
                        <MenuItem key={option} value={formatOption(option)}>
                          {formatOption(option)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                </Box>
                {dataRange === "today" ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: { lg: "80%", xs: "90%" },
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {profitLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ComparisonHourlyChart
                        data={comparisonLineData.total_net}
                        isNotPercentage={isCurrency}
                        // range={"hour"}
                        // isNotPercentage={true}
                        isMinimal={true}
                        isXSScreen={isXSScreen}
                        allowNegativeY={true}
                        showZeroLine={true}
                        // isMinimal={true}
                        hasPredictionLine={true}
                        // predictionLineDate={switchDate}
                        singleTooltip={true}
                        isMinNonZero={true}
                        isMultiRestaurant={true}
                        dataRange={dataRange}
                        isProfitGraph={true}
                      />
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      height: { lg: "80%", xs: "90%" },
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {profitLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ComparisonLineChart
                        data={comparisonLineData.total_net}
                        isNotPercentage={isCurrency}
                        //   range={range}
                        isXSScreen={isXSScreen}
                        allowNegativeY={true}
                        showZeroLine={true}
                        isMinimal={true}
                        hasPredictionLine={true}
                        // predictionLineDate={switchDate}
                        singleTooltip={true}
                        isMinNonZero={true}
                        noPrefix={true}
                        hasStaffMarker={true}
                        dataRange={dataRange}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Tooltip>
          </Box>

          {/* First section ends here */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 1px red",
              justifyContent: "flex-start",
              alignItems: "center",
              height: { lg: "50vh", xs: "120vh" },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 6}
            >
              <Box
                sx={{
                  ...(counter === 6 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "50%", xs: "90%" },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 6 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "30rem" },
                  // fontFamily: "'Montserrat', sans-serif",
                  paddingY: { lg: 0, xs: 1 },
                  // border: "solid 1px red",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: { lg: "20%", xs: "10%" },
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    // border: "solid 1px red",
                  }}
                >
                  <TitleStyle tooltipKey="gross_profit_i">
                    Gross Profit I
                  </TitleStyle>
                </Box>
                {dataRange === "today" ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: { lg: "80%", xs: "90%" },
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {profitLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ComparisonHourlyChart
                        data={comparisonLineData.gross_profit}
                        isNotPercentage={isCurrency}
                        // range={"hour"}
                        // isNotPercentage={true}
                        isMinimal={true}
                        isXSScreen={isXSScreen}
                        allowNegativeY={true}
                        showZeroLine={true}
                        // isMinimal={true}
                        hasPredictionLine={true}
                        // predictionLineDate={switchDate}
                        singleTooltip={true}
                        isMinNonZero={true}
                        isMultiRestaurant={true}
                        dataRange={dataRange}
                        isProfitGraph={true}
                      />
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      height: { lg: "80%", xs: "90%" },
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {profitLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ComparisonLineChart
                        data={comparisonLineData.gross_profit}
                        isNotPercentage={isCurrency}
                        //   range={range}
                        isXSScreen={isXSScreen}
                        allowNegativeY={true}
                        showZeroLine={true}
                        isMinimal={true}
                        hasPredictionLine={true}
                        // predictionLineDate={switchDate}
                        singleTooltip={true}
                        isMinNonZero={true}
                        noPrefix={true}
                        hasStaffMarker={true}
                        dataRange={dataRange}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 7}
            >
              <Box
                sx={{
                  ...(counter === 7 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "50%", xs: "90%" },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 7 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "30rem" },
                  // fontFamily: "'Montserrat', sans-serif",
                  paddingY: { lg: 0, xs: 1 },
                  // border: "solid 1px red",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: { lg: "20%", xs: "10%" },
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    // border: "solid 1px red",
                  }}
                >
                  {/* <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: 1,
                    }}
                  >
                    <Typography variant="h7">%</Typography>
                    <Switch
                      checked={isCurrency}
                      onChange={handleToggleCurrency}
                    />
                    <Typography variant="h7">NOK</Typography>
                  </Box> */}
                  <TitleStyle tooltipKey="gross_profit_ii">
                    Gross Profit II
                  </TitleStyle>
                </Box>
                {dataRange === "today" ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: "80%",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {profitLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ComparisonHourlyChart
                        data={comparisonLineData.operating_profit}
                        isNotPercentage={isCurrency}
                        // range={"hour"}
                        // isNotPercentage={true}
                        isMinimal={true}
                        isXSScreen={isXSScreen}
                        allowNegativeY={true}
                        showZeroLine={true}
                        // isMinimal={true}
                        hasPredictionLine={true}
                        // predictionLineDate={switchDate}
                        singleTooltip={true}
                        isMinNonZero={true}
                        isMultiRestaurant={true}
                        dataRange={dataRange}
                        isProfitGraph={true}
                      />
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      height: { lg: "80%", xs: "90%" },
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {profitLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ComparisonLineChart
                        data={comparisonLineData.operating_profit}
                        isNotPercentage={isCurrency}
                        //   range={range}
                        isXSScreen={isXSScreen}
                        allowNegativeY={true}
                        showZeroLine={true}
                        isMinimal={true}
                        hasPredictionLine={true}
                        // predictionLineDate={switchDate}
                        singleTooltip={true}
                        isMinNonZero={true}
                        noPrefix={true}
                        hasStaffMarker={true}
                        dataRange={dataRange}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 1px red",
              justifyContent: "flex-start",
              alignItems: "center",
              height: { lg: "50vh", xs: "120vh" },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 8}
            >
              <Box
                sx={{
                  ...(counter === 8 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "50%", xs: "90%" },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 8 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "30rem" },
                  // fontFamily: "'Montserrat', sans-serif",
                  paddingY: { lg: 0, xs: 1 },
                  // border: "solid 1px red",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: { lg: "20%", xs: "10%" },
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    // border: "solid 1px red",
                  }}
                >
                  <TitleStyle tooltipKey="cost_of_goods">
                    Cost of Goods
                  </TitleStyle>
                </Box>
                {dataRange === "today" ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: "80%",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {profitLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ComparisonHourlyChart
                        data={comparisonLineData.cost}
                        isNotPercentage={isCurrency}
                        // range={"hour"}
                        // isNotPercentage={true}
                        isMinimal={true}
                        isXSScreen={isXSScreen}
                        allowNegativeY={true}
                        showZeroLine={true}
                        // isMinimal={true}
                        hasPredictionLine={true}
                        // predictionLineDate={switchDate}
                        singleTooltip={true}
                        isMinNonZero={true}
                        isMultiRestaurant={true}
                        dataRange={dataRange}
                        isProfitGraph={true}
                      />
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      height: { lg: "80%", xs: "90%" },
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {profitLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ComparisonLineChart
                        data={comparisonLineData.cost}
                        isNotPercentage={isCurrency}
                        //   range={range}
                        isXSScreen={isXSScreen}
                        allowNegativeY={true}
                        showZeroLine={true}
                        isMinimal={true}
                        hasPredictionLine={true}
                        // predictionLineDate={switchDate}
                        singleTooltip={true}
                        isMinNonZero={true}
                        noPrefix={true}
                        hasStaffMarker={true}
                        dataRange={dataRange}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 9}
            >
              <Box
                sx={{
                  ...(counter === 9 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "50%", xs: "90%" },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 9 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "30rem" },
                  // fontFamily: "'Montserrat', sans-serif",
                  paddingY: { lg: 0, xs: 1 },
                  // border: "solid 1px red",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: { lg: "20%", xs: "10%" },
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    // border: "solid 1px red",
                  }}
                >
                  {/* <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: 1,
                    }}
                  >
                    <Typography variant="h7">%</Typography>
                    <Switch
                      checked={isCurrency}
                      onChange={handleToggleCurrency}
                    />
                    <Typography variant="h7">NOK</Typography>
                  </Box> */}
                  <TitleStyle tooltipKey="employee_cost_comp">
                    Employee Cost
                  </TitleStyle>
                </Box>
                {dataRange === "today" ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: "80%",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {profitLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ComparisonHourlyChart
                        data={comparisonLineData.employee_cost}
                        isNotPercentage={isCurrency}
                        // range={"hour"}
                        // isNotPercentage={true}
                        isMinimal={true}
                        isXSScreen={isXSScreen}
                        allowNegativeY={true}
                        showZeroLine={true}
                        // isMinimal={true}
                        hasPredictionLine={true}
                        // predictionLineDate={switchDate}
                        singleTooltip={true}
                        isMinNonZero={true}
                        isMultiRestaurant={true}
                        dataRange={dataRange}
                        isProfitGraph={true}
                      />
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      height: { lg: "80%", xs: "90%" },
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {profitLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ComparisonLineChart
                        data={comparisonLineData.employee_cost}
                        isNotPercentage={isCurrency}
                        //   range={range}
                        isXSScreen={isXSScreen}
                        allowNegativeY={true}
                        showZeroLine={true}
                        isMinimal={true}
                        hasPredictionLine={true}
                        // predictionLineDate={switchDate}
                        singleTooltip={true}
                        isMinNonZero={true}
                        noPrefix={true}
                        hasStaffMarker={true}
                        dataRange={dataRange}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 1px red",
              justifyContent: "flex-start",
              alignItems: "center",
              height: { lg: "50vh", xs: "60vh" },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 10}
            >
              <Box
                sx={{
                  ...(counter === 10 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "100%", xs: "90%" },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 10 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "30rem" },
                  fontFamily: "'Montserrat', sans-serif",
                  paddingY: { lg: 0, xs: 1 },
                  // border: "solid 1px red",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: "20%",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center", // Ensure elements are spaced apart
                    // border: "solid 1px red",
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      ml: { lg: "10%", xs: 0 },

                      // border: "solid red 1px",
                    }}
                  >
                    <TitleStyle
                      content={
                        <Typography>
                          {getTooltipMessage(selectedLine)}
                        </Typography>
                      }
                      style={{
                        fontWeight: 400,
                        fontFamily: "'Montserrat', sans-serif",
                        // ml: { lg: "20%", xs: 0 },
                        fontSize: { lg: "22px", xs: "17px" },
                        my: "0.5rem",
                        // border: "solid red 1px",
                        width: "100%",
                      }}
                    >
                      {formatOption(selectedLine)}
                    </TitleStyle>
                  </Box>
                  <Box sx={{ flexShrink: 0 }}>
                    <FormControl
                      sx={{
                        width: isXSScreen ? "10rem" : "12rem",
                        mr: 1,
                      }}
                    >
                      <InputLabel id="select-label">Select Line</InputLabel>
                      <Select
                        labelId="select-label"
                        value={formatOption(selectedLine)} // Display the formatted selected line
                        label="Select Line"
                        onChange={handleLineChange}
                        MenuProps={MenuProps}
                      >
                        {options.map((option) => (
                          <MenuItem key={option} value={formatOption(option)}>
                            {formatOption(option)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                {dataRange === "today" ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: "80%",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {profitLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ComparisonHourlyChart
                        data={comparisonLineData[selectedLine]}
                        isNotPercentage={isCurrency}
                        // range={"hour"}
                        // isNotPercentage={true}
                        isMinimal={true}
                        isXSScreen={isXSScreen}
                        allowNegativeY={true}
                        showZeroLine={true}
                        // isMinimal={true}
                        hasPredictionLine={true}
                        // predictionLineDate={switchDate}
                        singleTooltip={true}
                        isMinNonZero={true}
                        isMultiRestaurant={true}
                        dataRange={dataRange}
                        isProfitGraph={true}
                      />
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      height: "80%",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {profitLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ComparisonLineChart
                        data={comparisonLineData[selectedLine]}
                        isNotPercentage={isCurrency}
                        //   range={range}
                        isXSScreen={isXSScreen}
                        allowNegativeY={true}
                        showZeroLine={true}
                        isMinimal={true}
                        hasPredictionLine={true}
                        // predictionLineDate={switchDate}
                        singleTooltip={true}
                        isMinNonZero={true}
                        noPrefix={true}
                        hasStaffMarker={true}
                        dataRange={dataRange}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Tooltip>
          </Box>
        </Box>

        <SnackbarAlert
          open={open}
          severity={severity}
          message={message}
          onClose={handleClose}
        />
        {helpMode && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              width: { lg: "180px", xs: "280px" },
              position: "fixed", // Make it absolute to position at the bottom
              bottom: 20,
              right: 20,
              padding: "10px",
              // border:"solid 1px red"
            }}
          >
            <NavigationButtons
              isXSScreen={isXSScreen}
              setHelpMode={setHelpMode}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              counter={counter}
              maxSteps={maxSteps}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Comparisons;
