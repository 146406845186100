import React from "react";
import { ResponsiveBar } from "@nivo/bar";
// import { RoundValue } from "../utils/RoundValue";
const company = localStorage.getItem("company");

const PerBarChart = ({
  hasDifferentColor = false,
  data,
  range,
  isPercentage = true,
  isHorizontal = false,
  isXSScreen = false,
  isProductivityGraph = false,
  productLabel = false,
  isMinimal = false,
  isCurrency = false,
  dataRange = false,
  isGrossProfit = false,
}) => {

  // const isFisketorget = data.some((entry) => entry.company === "Fisketorget");
  const format = isPercentage ? (v) => `${v}%` : (v) => `${v} NOK`;
  const lineData = data && Object.entries(data).length !== 0 ? [data] : [];
  // Filter out or adjust entries with value less than -100
  const sortedData = [...data]
    .map((entry) => ({
      ...entry,
      value: entry.value < -10 ? -10 : entry.value, // Set a floor at -100%
    }))
    .sort((a, b) => a.value - b.value);

  const margin = isMinimal
    ? {
        top: company === "Fisketorget" ? 0 : 20,
        right: 50,
        bottom: 50,
        left: 100,
      }
    : { top: 20, right: 50, bottom: 80, left: isPercentage ? 60 : 100 };

  // const getBarTickValues = (data, isXSScreen, productLabel) => {
  //   // Extract all 'id' values from the dataset
  //   const allIds = data.map((d) => d.id);

  //   let tickValues;
  //   if (isXSScreen) {
  //     // For small screens, get the start, middle, and end ids
  //     tickValues = [
  //       allIds[0],
  //       allIds[Math.floor(allIds.length / 2)],
  //       allIds[allIds.length - 1],
  //     ];
  //   } else {
  //     if (productLabel) {
  //       return allIds;
  //     }
  //     // For larger screens, divide the ids into 6 segments and get them
  //     tickValues = [];
  //     for (let i = 0; i <= 5; i++) {
  //       tickValues.push(allIds[Math.floor((allIds.length - 1) * (i / 5))]);
  //     }
  //   }
  //   return tickValues;
  // };
  // const isValidDate =
  //   data &&
  //   data.length > 0 &&
  //   data.every((entry) => !isNaN(new Date(entry.id).getTime()));

  // Conditionally set the tickValues for the x-axis based on isValidDate

  const EmpProductivityTooltip = ({ data }) => {
    // Assume data.type specifies if the tooltip should show date or percentage
    if (data.type === "date") {
      const tooltipDate = new Date(data.id);
      const isValidDate = !isNaN(tooltipDate.getTime());

      if (isValidDate) {
        // Formatting the date
        const formattedDate = tooltipDate.toLocaleString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        // Formatting the value as NOK currency
        const formattedValue = new Intl.NumberFormat("nb-NO", {
          style: "currency",
          currency: "NOK",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(data.value);

        return (
          <div
            style={{
              background: "white",
              padding: "5px",
              borderRadius: "5px",
              boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
            }}
          >
            <p style={{ fontWeight: 700, margin: "0 0 5px 0" }}>
              {formattedDate}
            </p>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                color: data.color,
                margin: "0",
              }}
            >
              <strong>{formattedValue}</strong>
            </p>
          </div>
        );
      }
    }

    // Handle numeric or percentage IDs
    // Formatting the value as a percentage
    const formattedPercentage = `${data.value.toFixed(1)}%`;
    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
        }}
      >
        <p style={{ margin: 0, fontWeight: 700, color: data.Color }}>
          <strong>
            {data.id}: {formattedPercentage}
          </strong>
        </p>
      </div>
    );
  };

  const CustomTooltip = ({ data }) => {
    return (
      <div
        style={{
          background: "white",
          padding: "5px",
          // border: "1px solid #ccc",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          // Center the content vertically
        }}
      >
        {/* Container for colored square and text to align them horizontally */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center", // Center the content vertically in this row
            width: "100%",
          }}
        >
          {/* Display a colored square using data.Color */}
          <span
            style={{
              width: "20px",
              height: "20px",
              background: data.Color,
              marginRight: "10px", // Add some space to the right of the colored square
            }}
          ></span>

          {/* Display the text as data.id : data.value */}
          <span
            style={{
              fontWeight: 700,
              borderBottom: "1px solid rgba(128, 128, 128, 0.3)", // Faint gray with 30% opacity
            }}
          >
            {`${data.id} : `}
            {isCurrency
              ? new Intl.NumberFormat("nb-NO", {
                  // style: "currency",
                  // currency: "NOK",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(data.value) + " NOK"
              : `${data.value}`}
          </span>
        </div>

        {/* Display a small message using data.day_count */}
        <small>There were {data.day_count} days in this interval</small>
      </div>
    );
  };


  const layoutProps = isMinimal
    ? {
        enableGridX: false,
        enableGridY: false,

        axisBottom: {
          // tickValues: undefined,
        },
      }
    : isHorizontal
    ? {
        layout: "horizontal",
        axisBottom: {
          // format,
          // tickSize: 5,
          // tickPadding: 5,
          // tickRotation: 0,
          // legend: "Percentage",
          // legendPosition: "middle",
          // legendOffset: 32,
          // // tickValues: tickValues,
        },
        axisLeft: {
          format,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: isXSScreen
            ? undefined
            : isPercentage
            ? "Percentage"
            : "Productivity Per Hour",
          legendPosition: "middle",
          legendOffset: isPercentage ? -40 : -80,
        },
        margin: { top: 10, right: 50, bottom: 50, left: 100 }, // Add more right margin for horizontal layout
      }
    : {
        layout: "horizontal",
        axisLeft: {
          format,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: isXSScreen
            ? undefined
            : isPercentage
            ? "Percentage"
            : "Productivity Per Hour",
          legendPosition: "middle",
          legendOffset: isPercentage ? -40 : -80,
        },
        axisBottom: {
          undefined,
          // tickSize: 5,
          // tickPadding: 5,
          // tickRotation: 0,
          // legend: isPercentage ? "Product Categories" : "Restaurants",
          // legendPosition: "middle",
          // legendOffset: 32,
          // // tickValues: tickValues,
          // // tickValues: data.map((entry) => entry.id),
        },
      };

  let minYValue;
  let maxYValue;
  if (data && data.length > 0) {
    minYValue = Math.min(...data.map((entry) => entry.value));
    maxYValue = Math.max(...data.map((entry) => entry.value));
  }
  if (!isPercentage) {
    // For NOK
    minYValue = Math.round(minYValue / 10000) * 10000;
    if (maxYValue < 5500) {
      maxYValue = Math.round(maxYValue / 100) * 100; // Round to the nearest 100
    } else {
      maxYValue = Math.round(maxYValue / 10000) * 10000; // Round to the nearest 10000
    }
  } else {
    minYValue = 0;
    maxYValue = Math.round(maxYValue / 10) * 10;
  }


  return !lineData || lineData.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    <ResponsiveBar
      data={sortedData}
      keys={["value"]}
      indexBy="id"
      layout="horizontal"
      padding={0.3}
      enableGridX={false}
      enableGridY={false}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={
        hasDifferentColor ? (data) => data.data.Color : { scheme: "pastel2" }
      }
      // legends={[
      //   {
      //     dataFrom: "indexes",
      //     anchor: "bottom-right",
      //     direction: "row",
      //     justify: false,
      //     translateX: 10,
      //     translateY: 30,
      //     itemsSpacing: 5,
      //     itemWidth: 110,
      //     itemHeight: 20,
      //     itemDirection: "left-to-right",
      //     itemOpacity: 0.85,
      //     symbolSize: 15,
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemOpacity: 1,
      //         },
      //       },
      //     ],
      //   },
      // ]}
      borderRadius={5}
      margin={margin}
      // borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      {...layoutProps}
      // gridYValues={[minYValue, maxYValue]}
      axisLeft={
        isGrossProfit
          ? {
              tickValues: data.map((d) => d.id),
              format: (value) =>
                value.length > 8 ? `${value.substring(0, 8)}...` : value,
            }
          : {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0, // Adjust the rotation angle to make labels visible
              legendPosition: "middle",

              tickValues: undefined,
              format: (value) =>
                value.length > 8 ? `${value.substring(0, 8)}...` : value,
            }
      }
      axisBottom={{
        tickValues: [minYValue, maxYValue],
        format: (value) =>
          isPercentage ? `${value}%` : `${Math.round(value)} NOK`,
      }}
      enableLabel={false}
      labelSkipWidth={0}
      labelSkipHeight={0}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltip={isProductivityGraph ? CustomTooltip : EmpProductivityTooltip}
    />
  );
};
export default PerBarChart;
