import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        justifyContent: { lg: "center", xs: "flex-start" },
        alignItems: "center",
      }}
    >
      <Typography variant="h4">
        {/* Sorry the page you are looking for is not available right now */}
      </Typography>
      <Box
        sx={{
          height: "70%",
          width: "70%",
          display: "flex",
        }}
      >
        <img
          src="404.svg"
          alt="404"
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </Box>
      <Button
        sx={{
          width: "20ch",
          color: "black",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        Go back to Home
      </Button>
    </Box>
  );
};

export default ErrorPage;
