import { React, useEffect, useState, useCallback } from "react";
import {
  Box,
  FormControl,
  Grid,
  Typography,
  TextField,
  Select,
  InputLabel,
  InputAdornment,
  MenuItem,
  Button,
  useMediaQuery,
  Tooltip,
  Zoom,
  // styled,
  ListItemText,
  FormControlLabel,
  // Checkbox,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";

import SnackbarAlert from "../utils/SnackbarAlert";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { DataGrid } from "@mui/x-data-grid";
import ChartLoading from "../components/Loading/ChartLoading";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import SettingsIcon from "@mui/icons-material/Settings";
import getHighlightedStyle from "../utils/HighlightedStyle";
import NavigationButtons from "../utils/HelpButton";
import Colors from "../utils/Colors";
import KPILoad from "../components/Loading/KPILoad";
import Prefix from "../utils/PrefixLink";
import customParseFormat from "dayjs/plugin/customParseFormat"; // If needed for parsing custom formats
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"; // Ensure this is added to handle comparisons properly
import TitleStyle from "../components/Style/Title";
import OpeningHourCard from "../components/OpeningHours";
import StyledButton from "../components/Style/ButtonStyle";
import HelpModeButton from "../components/HelpModeButton";
// import useUnsavedChangesWarning from "./UnsavedChanges";

dayjs.extend(customParseFormat); // Extend dayjs with the plugins
dayjs.extend(isSameOrBefore);

const prefix = Prefix();
const dayNameToIndex = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0,
};

const Admin = () => {
  const subscriptionLevel = JSON.parse(
    localStorage.getItem("user")
  )?.company_subscription_level;
  const company = localStorage.getItem("company");
  const token = localStorage.getItem("token");
  // const [restaurants, setRestaurants] = useState([]);
  const navigate = useNavigate();
  // const navigatingRef = useRef(false);

  const [defaultRestaurants, setDefaultRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [outdoorSeats, setOutdoorSeats] = useState("");
  const [indoorSeats, setIndoorSeats] = useState("");
  const [monthlyRentVariable, setMonthlyRentVariable] = useState("");
  const [minimumMonthlyRent, setMinimumMonthlyRent] = useState("");
  const [takeAwayIntensity, setTakeAwayIntensity] = useState("");
  const [alcoholIntensity, setAlcoholIntensity] = useState("");
  const [otherCosts, setOtherCosts] = useState("");

  const [monthlySales, setMonthlySales] = useState("");
  const [monthlyGrossProfit, setMonthlyGrossProfit] = useState("");
  const [monthlyNetProfit, setMonthlyNetProfit] = useState("");
  // const [monthlyEmployeeCost, setMonthlyEmployeeCost] = useState("");

  const [isDirty, setIsDirty] = useState(false);
  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [openingHours, setOpeningHours] = useState("");
  const [closingHours, setClosingHours] = useState("");
  const [recurringStartDate, setRecurringStartDate] = useState("");
  const [recurringEndDate, setRecurringEndDate] = useState("");
  const [specialStartDate, setSpecialStartDate] = useState("");
  const [specialEndDate, setSpecialEndDate] = useState("");

  // Initialize startDate and endDate with calculated dates

  const [date, setDate] = useState(dayjs()); // This will store the selected month and year

  const [startDate, setStartDate] = useState(dayjs().startOf("month"));
  const [endDate, setEndDate] = useState(dayjs().endOf("month"));
  const [helpMode, setHelpMode] = useState(false);
  const [counter, setCounter] = useState(1);
  const maxSteps = subscriptionLevel === "basic" ? 3 : 6;
  // const [isWeekdayChecked, setIsWeekdayChecked] = useState(false);
  // const [isWeekendChecked, setIsWeekendChecked] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSpecialDialog, setOpenSpecialDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [predictionChecked, setPredictionChecked] = useState(false);
  const [generalChecked, setGeneralChecked] = useState(false);
  const [goalsChecked, setGoalsChecked] = useState(false);
  // const location = useLocation();
  // const { navigator } = useContext(NavigationContext);

  const handlePredictionChange = (event) => {
    setPredictionChecked(event.target.checked);
  };
  const handleGeneralChange = (event) => {
    setGeneralChecked(event.target.checked);
  };
  const handleGoalsChange = (event) => {
    setGoalsChecked(event.target.checked);
  };
  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    return date.getDay(); // Returns the day of the week (0 for Sunday, 1 for Monday, etc.)
  };

  const [errors, setErrors] = useState({
    salesError: false,
    grossProfitError: false,
    netProfitError: false,
  });
  // useUnsavedChangesWarning(isDirty);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    setIsDirty(true);
  };

  const updateDateAndBounds = (newDate) => {
    setDate(newDate);
    setStartDate(newDate.startOf("month"));
    setEndDate(newDate.endOf("month"));
  };

  const handleAddMonth = () => {
    const newDate = date.add(1, "month");
    updateDateAndBounds(newDate);
  };

  const handleSubtractMonth = () => {
    const newDate = date.subtract(1, "month");
    updateDateAndBounds(newDate);
  };

  const handleMonthChange = (newDate) => {
    updateDateAndBounds(newDate);
  };
  // const getRestaurantNameById = (restaurantName) => {
  //   const restaurant = defaultRestaurants.find(
  //     (r) => r.name === restaurantName
  //   );
  //   return restaurant ? restaurant.name : "";
  // };
  // console.log(selectedDate, "date");
  // const formatDateForFetch = (date) => {
  //   const year = date.getFullYear();
  //   const month = date.getMonth() + 1;
  //   return `${year}-${month < 10 ? `0${month}` : month}`;
  // };
  const [openHours, setOpenHours] = useState([]);
  const [days, setDays] = useState([
    { name: "Monday", openingHours: "", closingHours: "" },
    { name: "Tuesday", openingHours: "", closingHours: "" },
    { name: "Wednesday", openingHours: "", closingHours: "" },
    { name: "Thursday", openingHours: "", closingHours: "" },
    { name: "Friday", openingHours: "", closingHours: "" },
    { name: "Saturday", openingHours: "", closingHours: "" },
    { name: "Sunday", openingHours: "", closingHours: "" },
  ]);

  const [dayErrors, setDayErrors] = useState(
    days.map(() => ({ openingHoursError: false, closingHoursError: false }))
  );

  // const columns = [
  //   {
  //     field: "week_days",
  //     headerName: "Weekdays",
  //     width: isXSScreen ? 120 : 250, // Reduced width for small screens
  //   },
  //   {
  //     field: "opening_hours",
  //     headerName: "Opening Hours",
  //     width: isXSScreen ? 120 : 180,
  //   },
  //   {
  //     field: "closing_hours",
  //     headerName: "Closing Hours",
  //     width: isXSScreen ? 105 : 180,
  //   },
  // ];
  // const updateSpecialHours = (date, startHour, endHour) => {
  //   const dayOfWeek = dayjs(date).day(); // Sunday as 0 through Saturday as 6
  //   const formattedDay = dayOfWeek === 0 ? 7 : dayOfWeek; // Convert Sunday to 7, if your week starts from Monday as 1

  //   const newSpecialHours = { start_hour: startHour, end_hour: endHour, start_date: date, end_date: date };

  //   // Update state logic here, you might need to adjust this based on how your state is structured
  //   setRegularHours(prevHours => ({
  //     ...prevHours,
  //     [formattedDay]: newSpecialHours,
  //   }));
  // };

  const handleHelpMode = () => {
    setHelpMode(!helpMode);
    setCounter(1); // Reset counter when toggling helpMode
  };

  const handleNext = () => {
    setCounter((prev) => {
      const newCounter = prev + 1;
      if (subscriptionLevel === "basic" ? newCounter >= 3 : newCounter >= 6) {
        setHelpMode(false);
      } else {
        // Scroll logic for mobile view in helpMode, only after counter reaches 4
        if (isXSScreen && helpMode && newCounter > 2) {
          const remInPixels = parseFloat(
            getComputedStyle(document.documentElement).fontSize
          );
          window.scrollBy({
            top: 30 * remInPixels, // Scroll by 30rem in pixels
            behavior: "smooth",
          });
        }
      }
      return newCounter;
    });
  };

  const handlePrevious = () => {
    setCounter((prev) => {
      const newCounter = Math.max(prev - 1, 1);

      // Scroll logic for mobile view in helpMode, only if counter was above 4
      if (isXSScreen && helpMode && prev > 3) {
        const remInPixels = parseFloat(
          getComputedStyle(document.documentElement).fontSize
        );
        window.scrollBy({
          top: -30 * remInPixels, // Scroll upwards by 30rem in pixels
          behavior: "smooth",
        });
      }
      return newCounter;
    });
  };
  const tooltipContent = {
    1: <Typography variant="body2">Choose a restaurant</Typography>,
    2: <Typography variant="body2">Fixed Costs</Typography>,
    3: <Typography variant="body2">Set Financial Goals</Typography>,
    4: (
      <Typography variant="body2">
        Set Opening Hours and Closing Hours of your restaurant
      </Typography>
    ),
    5: <Typography variant="body2">Set Prediction</Typography>,
    // ... add more steps as needed
  };
  if (subscriptionLevel === "basic") {
    tooltipContent[2] = (
      <Typography variant="body2">
        Set Opening Hours and Closing Hours of your restaurant
      </Typography>
    );
  }

  // week dates for the DataGrid
  const handleWeekStartDateChange = (event) => {
    setWeekStartDate(event.target.value);
    setIsDirty(true);
  };
  const [WeekStartDate, setWeekStartDate] = useState(new Date()); // Initialize with the current week
  const adjustWeekDates = (direction) => {
    const parsedDate = new Date(WeekStartDate);

    // Check if the parsed date is valid before making adjustments
    if (!isNaN(parsedDate)) {
      const adjustWeek = direction === "forward" ? 7 : -7;
      parsedDate.setDate(parsedDate.getDate() + adjustWeek);

      // Update the state with the new date in string format (e.g., "Thu Dec 21 2023")
      setWeekStartDate(parsedDate.toDateString());
    }
  };
  const handleSalesChange = (value) => {
    const numericValue = value === "" ? "" : Number(value);
    setMonthlySales(numericValue); // Assuming you have a state for monthlySales

    // Update error state based on the new value
    setErrors((prevErrors) => ({
      ...prevErrors,
      salesError: numericValue <= parseFloat(monthlyGrossProfit),
      grossProfitError:
        parseFloat(monthlyGrossProfit) <= parseFloat(monthlyNetProfit),
    }));
    setIsDirty(true);
  };

  const handleGrossProfitChange = (value) => {
    const numericValue = value === "" ? "" : Number(value);
    setMonthlyGrossProfit(numericValue); // Assuming you have a state for monthlyGrossProfit

    // Update error state based on the new value
    setErrors((prevErrors) => ({
      ...prevErrors,
      salesError: parseFloat(monthlySales) <= numericValue,
      grossProfitError: numericValue <= parseFloat(monthlyNetProfit),
    }));
    setIsDirty(true);
  };

  const handleNetProfitChange = (value) => {
    const numericValue = value === "" ? "" : Number(value);
    setMonthlyNetProfit(numericValue); // Assuming you have a state for monthlyNetProfit

    // Update error state based on the new value
    setErrors((prevErrors) => ({
      ...prevErrors,
      grossProfitError: parseFloat(monthlyGrossProfit) <= numericValue,
    }));
    setIsDirty(true);
  };
  // const handleEmployeeCostChange = (value) => {
  //   const numericValue = value === "" ? "" : Number(value);
  //   setMonthlyEmployeeCost(numericValue); // Assuming you have a state for monthlyNetProfit
  //   setIsDirty(true);

  //   // Update error state based on the new value
  //   // setErrors((prevErrors) => ({
  //   //   ...prevErrors,
  //   //   grossProfitError: parseFloat(monthlyGrossProfit) <= numericValue,
  //   // }));
  // };
  // const handleNavigation = (path) => {
  //   if (isDirty) {
  //     const confirmLeave = window.confirm(
  //       "You have unsaved changes, do you want to leave?"
  //     );
  //     if (confirmLeave) {
  //       navigate(path);
  //     }
  //   } else {
  //     navigate(path);
  //   }
  // };

  const handleBackwardClick = () => {
    adjustWeekDates("backward");
  };

  const handleForwardClick = () => {
    adjustWeekDates("forward");
  };

  // const StyledDataGrid = styled(DataGrid)({
  //   ".MuiDataGrid-columnHeaders": {
  //     backgroundColor: "#FFBD00",
  //     color: "black",
  //     fontSize: isXSScreen ? "14px" : "18px",
  //   },
  //   ".MuiCheckbox-root": {
  //     color: "black",
  //   },
  //   ".MuiDataGrid-columnSeparator": {
  //     display: "none",
  //   },
  //   ".MuiTablePagination-root": {
  //     width: "80%", // Adjust this value as needed
  //   },
  //   ".MuiTablePagination-actions": {
  //     display: "flex",
  //     flexDirection: "row",
  //     justifyContent: "center", // Adjust this to align as needed
  //   },
  //   ".MuiIconButton-root": {
  //     width: "30px", // Adjust this value as needed
  //     margin: "0 5px", // Adds space between buttons
  //   },
  // });

  const handleOpenSpecialDialog = () => {
    setOpenSpecialDialog(true);
  };

  const handleCloseSpecialDialog = () => {
    setOpenSpecialDialog(false);
  };
  // const getDayOfWeek = (date) => {
  //   if (!date) return null;
  //   const dayOfWeek = new Date(date).getDay();
  //   return dayOfWeek;
  // };

  const handleDayChange = (dayIndex, key, value) => {
    const numericValue = value === "" ? "" : Number(value);

    // Update the days state
    setDays((currentDays) =>
      currentDays.map((day, index) =>
        index === dayIndex ? { ...day, [key]: numericValue } : day
      )
    );
    // Update the dayErrors state
    setDayErrors((currentErrors) =>
      currentErrors.map((error, index) =>
        index === dayIndex
          ? { ...error, [`${key}Error`]: numericValue > 24 }
          : error
      )
    );
    setIsDirty(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchRestaurants = useCallback(async () => {
    // Replace the URL with the appropriate API endpoint for fetching a single task
    // setLoad(true);
    const restro_response = await fetch(
      `${prefix}/api/restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();

      const userDefaultRestaurants = JSON.parse(
        localStorage.getItem("user")
      )?.default_restaurants;

      let filteredRestaurants = restro_data.results.filter(
        (restaurant) =>
          userDefaultRestaurants.includes(restaurant.id) &&
          restaurant.name !== "Fisketorget Stavanger"
      );
      if (userDefaultRestaurants && userDefaultRestaurants.length > 0) {
        // Filter restaurants based on default_restaurants IDs
        filteredRestaurants = restro_data.results.filter((restaurant) =>
          userDefaultRestaurants.includes(restaurant.id)
        );
      }
      if (!userDefaultRestaurants || userDefaultRestaurants.length === 0) {
        filteredRestaurants = restro_data.results.filter(
          (restaurant) => restaurant.name !== "Fisketorget Stavanger"
        );
      }
      // Apply sorting to move 'Food Truck' to the bottom of the list
      filteredRestaurants.sort((a, b) => {
        const isAFoodTruck = a.name.includes("Food Truck");
        const isBFoodTruck = b.name.includes("Food Truck");

        if (isAFoodTruck && !isBFoodTruck) {
          return 1; // Place 'a' after 'b'
        } else if (!isAFoodTruck && isBFoodTruck) {
          return -1; // Place 'a' before 'b'
        }
        return 0; // Keep original order for other restaurants
      });
      setDefaultRestaurants(filteredRestaurants);
    }
  }, [company, token]);

  const getKpiGoals = useCallback(async () => {
    if (!selectedRestaurant) {
      return;
    }
    const startOfMonth = selectedDate.startOf("month").format("YYYY-MM-DD");
    const endOfMonth = selectedDate.endOf("month").format("YYYY-MM-DD");
    let url = `${prefix}/api/kpi_goals/get_kpis/?company=${company}&start_date=${startOfMonth}&end_date=${endOfMonth}`;
    if (selectedRestaurant) {
      url += `&restaurants=[${JSON.stringify(selectedRestaurant.name)}]`;
    }

    try {
      const kpi_response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!kpi_response.ok) {
        throw new Error(`HTTP error! status: ${kpi_response.status}`);
      }
      const data = await kpi_response.json();
      if (data.message && data.message.length > 0) {
        if (!selectedRestaurant) {
          let totalSalesSum = 0,
            grossProfitSum = 0,
            netProfitSum = 0;
          data.message.forEach((kpi) => {
            totalSalesSum += Number(kpi.total_sales_goal);
            grossProfitSum += Number(kpi.gross_profit_goal);
            netProfitSum += Number(kpi.net_profit_goal);
          });
          setMonthlySales(totalSalesSum.toString());
          setMonthlyGrossProfit(grossProfitSum.toString());
          setMonthlyNetProfit(netProfitSum.toString());
        } else {
          const kpiData = data.message[0];
          setMonthlySales(kpiData.total_sales_goal);
          setMonthlyGrossProfit(kpiData.gross_profit_goal);
          setMonthlyNetProfit(kpiData.net_profit_goal);
        }
      } else {
        // Clear the fields if no data is available
        setMonthlySales("");
        setMonthlyGrossProfit("");
        setMonthlyNetProfit("");
      }
    } catch (error) {
      // setRestaurants([]);
      console.error("Error fetching KPI Goals:", error);
    }
  }, [token, company, selectedRestaurant, selectedDate]);

  const fetchOpeningHours = useCallback(async () => {
    setIsLoading(true);
    if (!selectedRestaurant) {
      setOpenHours([]); // Set open hours to empty
      setIsLoading(false);
      return;
    }
    let url = `${prefix}/api/opening_hours/get_opening_hours`;
    url += `?week_start_date=${toISOStringWithoutTimezone(WeekStartDate)}`;
    if (selectedRestaurant) {
      url += `&restaurant_id=${selectedRestaurant.id}`;
    }

    const opening_hours_response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    if (opening_hours_response.ok) {
      const opening_hours_data = await opening_hours_response.json();
      if (opening_hours_data.results) {
        // Map and sort in one step
        const open_hours_sorted = opening_hours_data.results
          .map((opening_hours, index) => {
            const dayOfWeek = Object.keys(dayNameToIndex).find(
              (key) => dayNameToIndex[key] === opening_hours.day_of_week
            );

            return {
              ...opening_hours,
              id: index,
              week_days: dayOfWeek,
              opening_hours: opening_hours.start_hour,
              closing_hours: opening_hours.end_hour,
            };
          })
          .sort((a, b) => {
            const aIndex =
              dayNameToIndex[a.week_days] === 0
                ? 7
                : dayNameToIndex[a.week_days];
            const bIndex =
              dayNameToIndex[b.week_days] === 0
                ? 7
                : dayNameToIndex[b.week_days];
            return aIndex - bIndex;
          });

        setOpenHours(open_hours_sorted);
      } else {
        setOpenHours([]);
      }
    }
    setIsLoading(false);
  }, [token, WeekStartDate, selectedRestaurant]);

  const customTheme = createTheme({
    components: {
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          root: {
            transform: "translateX(-40px)",
            width: "70px",
            "&:hover": {
              width: "70px",
            },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit", // Reset the border color
            },
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          labelContainer: {
            padding: "0 10px", // Reduce padding, adjust as needed
            fontSize: "0.875rem", // Reduce font size, adjust as needed
          },
          switchViewButton: {
            width: "50px", // Replace 'initialWidth' with your normal width value
            "&:hover": {
              width: "50px", // Replace 'smallerHoverWidth' with your desired hover width value
            },
          },
        },
      },
    },
  });

  const handleRestaurantChange = (event) => {
    setIsDirty(true);
    const selectedName = event.target.value;
    const foundRestaurant = defaultRestaurants.find(
      (r) => r.name === selectedName.name
    );
    setSelectedRestaurant(foundRestaurant);
    const filteredCosts = foundRestaurant.restaurant_costs
      .filter((item) => {
        const costStartDate = dayjs(item.start_date);
        const costEndDate = dayjs(item.end_date);
        // console.log(costStartDate);
        return (
          (costStartDate.isBefore(startDate) ||
            costStartDate.isSame(startDate, "day")) &&
          (costEndDate.isAfter(endDate) || costEndDate.isSame(endDate, "day"))
        );
      })
      .sort((a, b) => dayjs(b.created_at).diff(dayjs(a.created_at))); // Sort by created_at in descending order
    // console.log(filteredCosts, "filter ayo");
    // Take the first element from the filtered and sorted array, if exists
    const latestRestaurantCosts =
      filteredCosts.length > 0 ? filteredCosts[0] : null;

    if (foundRestaurant) {
      // Set the values to the foundRestaurant's values or reset to default if not available
      // const data = foundRestaurant.restaurant_costs //array ho yo
      // first check if the date selected by the user falls within the start date and end date of each item in the array. Hint: use .filter()
      // if multiple items satisfy that condition take the latest created_at. Hint: user .sort() and take first element( sort by descending order)
      setOutdoorSeats(latestRestaurantCosts?.outdoor_seats || "0");
      setIndoorSeats(latestRestaurantCosts?.indoor_seats || "0");
      setMinimumMonthlyRent(latestRestaurantCosts?.minimum_rent || "0");
      setMonthlyRentVariable(latestRestaurantCosts?.rent_variable_sum || "0");
      setTakeAwayIntensity(latestRestaurantCosts?.take_away_intensity || "0");
      setAlcoholIntensity(latestRestaurantCosts?.alcohol_intensity || "0");
      setOtherCosts(latestRestaurantCosts?.fixed_costs || "0");
    }
  };

  const toISOStringWithoutTimezone = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);

    return adjustedDate.toISOString().split("T")[0];
  };

  const submitRecurringDays = async () => {
    const openingHoursData = days.reduce((acc, day) => {
      const isValidOpeningHours =
        typeof day.openingHours === "number" &&
        day.openingHours >= 0 &&
        day.openingHours <= 23;
      const isValidClosingHours =
        typeof day.closingHours === "number" &&
        day.closingHours >= 0 &&
        day.closingHours <= 23;
      if (isValidClosingHours && isValidOpeningHours) {
        const dayIndex = dayNameToIndex[day.name];
        acc[dayIndex] = {
          start_hour: day.openingHours,
          end_hour: day.closingHours,
          start_date: recurringStartDate
            ? toISOStringWithoutTimezone(recurringStartDate)
            : "",
          end_date: recurringEndDate
            ? toISOStringWithoutTimezone(recurringEndDate)
            : "",
        };
      }
      return acc;
    }, {});

    try {
      const response = await fetch(
        `${prefix}/api/opening_hours/create_opening_hours/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`, // Ensure this is your actual token
          },
          body: JSON.stringify({
            restaurant_id: selectedRestaurant.id, // Ensure this is the correct ID
            opening_hours: openingHoursData,
          }),
        }
      );

      if (response.ok) {
        // const data = await response.json();
        // Handle success - e.g., update UI or show a success message
        setOpen(true);
        setMessage("Recurring Days Updated Successfully");
        setSeverity("success");
      } else {
        // Handle error - e.g., show an error message
        setOpen(true);
        setMessage("Failed to Update Recurring Days");
        setSeverity("error");
      }
    } catch (error) {
      console.error("Error submitting recurring days:", error);
      // Handle network error
      setOpen(true);
      setMessage("Network Error");
      setSeverity("error");
    }
  };

  const submitSpecialOpeningHours = async () => {
    const formattedStartDate = dayjs(specialStartDate).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(specialEndDate).format("YYYY-MM-DD");

    const dayIndex = getDayOfWeek(formattedStartDate); // Assuming this function can handle 'YYYY-MM-DD' format
    const payload = {
      restaurant_id: selectedRestaurant.id,
      opening_hours: {
        [dayIndex]: {
          start_hour: parseInt(openingHours),
          end_hour: parseInt(closingHours),
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        },
      },
    };

    try {
      const response = await fetch(
        `${prefix}/api/opening_hours/create_opening_hours/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok)
        throw new Error("Failed to submit special opening hours");
      // Handle success here, e.g., showing a success message
    } catch (error) {
      console.error("Error submitting special opening hours:", error);
      // Handle error here, e.g., showing an error message
    }
  };

  const submitKPIGoals = async () => {
    const sales = parseFloat(monthlySales);
    const grossProfit = parseFloat(monthlyGrossProfit);
    const netProfit = parseFloat(monthlyNetProfit);

    let validationFails = false; // Flag to indicate validation failure

    // Correct validation logic
    if (sales <= grossProfit || grossProfit <= netProfit) {
      setMessage(
        "Invalid KPI Goals: Total Sales should be greater than Gross Profit and Net profit, and Gross Profit should be greater than Net Profit"
      );
      validationFails = true;
      setOpen(true);
      setSeverity("error");
      // Stop execution if validation fails
    }
    if (validationFails) {
      return; // Stop execution if validation fails
    }
    const startOfMonth = selectedDate.startOf("month").format("YYYY-MM-DD");
    const endOfMonth = selectedDate.endOf("month").format("YYYY-MM-DD");

    const kpiPostData = {
      start_date: startOfMonth,
      end_date: endOfMonth,
      company: company,
      restaurant: selectedRestaurant?.name || null,
      total_sales_goal: monthlySales,
      gross_profit_goal: monthlyGrossProfit,
      net_profit_goal: monthlyNetProfit,
      apply_for_future: goalsChecked,
    };

    try {
      const kpiResponse = await fetch(`${prefix}/api/kpi_goals/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(kpiPostData),
      });

      if (kpiResponse.ok) {
        // Handle success for KPI Goals
        setOpen(true);
        setMessage("KPI Goals Updated Successfully");
        setSeverity("success");
      } else {
        // Handle non-ok response
        setOpen(true);
        setMessage("Failed to Update KPI Goals");
        setSeverity("error");
      }
    } catch (error) {
      console.error("Error creating KPI Goals:", error);
      // Handle network error
      setOpen(true);
      setMessage("Network Error");
      setSeverity("error");
    }
  };

  const handleAddGoalsMonth = () => {
    const newDate = selectedDate.add(1, "month");
    handleDateChange(newDate);
  };

  const handleSubtractGoalsMonth = () => {
    const newDate = selectedDate.subtract(1, "month");
    handleDateChange(newDate);
  };

  const handleSubmit = async () => {
    // const monthlyRent = (monthlyRentVariable === 0 || monthlyRentVariable === null)
    //   ? minimumMonthlyRent
    //   : monthlyRentVariable;

    const formData = {
      outdoor_seats: outdoorSeats || null,
      indoor_seats: indoorSeats || null,
      minimum_rent: minimumMonthlyRent,
      rent_variable_sum: monthlyRentVariable || null,
      take_away_intensity: takeAwayIntensity,
      alcohol_intensity: alcoholIntensity,
      fixed_costs: otherCosts,
      restaurant: selectedRestaurant.id,
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
      apply_for_future: predictionChecked || generalChecked,
    };
    const restro_response = await fetch(
      `${prefix}/api/restaurant_costs/?restaurant=${selectedRestaurant.name}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(formData),
      }
    );
    if (restro_response.ok) {
      // Assuming the POST request was successful, now refetch data
      // Example of refetching restaurant data
      const fetchData = async () => {
        try {
          const restro_response = await fetch(
            `${prefix}/api/restaurants/?company=${company}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
              },
            }
          );
          if (!restro_response.ok) {
            throw new Error("Failed to fetch updated data");
          }
          // const updatedData = await restro_response.json();
          // setRestaurants(updatedData.results || []);
        } catch (error) {
          console.error("Error fetching updated data:", error);
        }
      };

      // Call fetchData to update your component's state with fresh data from the server
      fetchData();
    } else {
      // Handle errors from the POST request
      console.error("Failed to submit data:", await restro_response.text());
    }
    if (restro_response.ok) {
      await submitRecurringDays(); // This is the call to your submitRecurringDays function
      await submitSpecialOpeningHours();
      const kpiSuccess = await submitKPIGoals();
      if (!kpiSuccess) {
        return; // Stop further execution if KPI submission failed
      }
      // navigate(0);
      if (
        !openingHours &&
        !closingHours &&
        !specialStartDate &&
        !specialEndDate
      ) {
        setOpen(true);
        setMessage("Restaurant Updated Successfully");
        setSeverity("success");
        return;
      }

      const openingHoursData = days.reduce((acc, day) => {
        if (day.openingHours && day.closingHours) {
          const dayIndex = dayNameToIndex[day.name];
          // console.log(dayIndex, "day");
          acc[dayIndex] = {
            start_hour: day.openingHours,
            end_hour: day.closingHours,
            start_date: specialStartDate
              ? toISOStringWithoutTimezone(specialStartDate)
              : "",
            end_date: specialEndDate
              ? toISOStringWithoutTimezone(specialEndDate)
              : "",
          };
        }
        return acc;
      }, {});

      const openingHoursFormData = {
        restaurant: selectedRestaurant.id,
        opening_hours: openingHoursData,
      };
      const opening_hours_response = await fetch(
        `${prefix}/api/opening_hours/?restaurants=${selectedRestaurant.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify(openingHoursFormData),
        }
      );

      if (opening_hours_response.ok) {
        setOpen(true);
        setMessage("Opening Hours and Restaurant Updated Successfully");
        setSeverity("success");
        navigate(0);
      } else {
        setOpen(true);
        setMessage("Restaurant Updated Successfully But Opening Hours Failed");
        setSeverity("warning");
      }
    } else {
      setOpen(true);
      setMessage("Server Error");
      setSeverity("warning");
    }

    if (
      !openingHours &&
      !closingHours &&
      !specialStartDate &&
      !specialEndDate
    ) {
      setOpen(true);
      setMessage("Restaurant Updated Successfully");
      setSeverity("success");
      return;
    }
    if (!startDate || !endDate) {
      setOpen(true); // Assuming this opens your message/snackbar component
      setMessage("Dates shouldn't be empty");
      setSeverity("error"); // Set the severity for the snackbar/message, if applicable
      return; // Prevent the form from submitting
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);

  useEffect(() => {
    // Handle browser tab or window close events
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        const message =
          "You have unsaved changes. Do you really want to leave?";
        event.returnValue = message; // Standard for most browsers
        return message; // For some browsers
      }
    };
    // console.log(isDirty, "dirty");
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    if (!selectedRestaurant || !selectedRestaurant.restaurant_costs) return;

    const filteredCosts = selectedRestaurant.restaurant_costs
      .filter((item) => {
        const costStartDate = dayjs(item.start_date);
        const costEndDate = dayjs(item.end_date);
        return (
          (costStartDate.isBefore(startDate) ||
            costStartDate.isSame(startDate, "day")) &&
          (costEndDate.isAfter(endDate) || costEndDate.isSame(endDate, "day"))
        );
      })
      .sort((a, b) => dayjs(b.created_at).diff(dayjs(a.created_at)));

    const latestRestaurantCosts =
      filteredCosts.length > 0 ? filteredCosts[0] : null;

    setOutdoorSeats(latestRestaurantCosts?.outdoor_seats || "0");
    setIndoorSeats(latestRestaurantCosts?.indoor_seats || "0");
    setMinimumMonthlyRent(latestRestaurantCosts?.minimum_rent || "0");
    setMonthlyRentVariable(latestRestaurantCosts?.rent_variable_sum || "0");
    setTakeAwayIntensity(latestRestaurantCosts?.take_away_intensity || "0");
    setAlcoholIntensity(latestRestaurantCosts?.alcohol_intensity || "0");
    setOtherCosts(latestRestaurantCosts?.fixed_costs || "0");
  }, [startDate, endDate, selectedRestaurant]);

  useEffect(() => {
    fetchOpeningHours();
  }, [fetchOpeningHours]);

  useEffect(() => {
    getKpiGoals();
  }, [getKpiGoals]);

  return (
    <Box
      onKeyDown={handleKeyPress}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        minHeight: company === "Burgerheim" ? "100vh" : "150vh",
        // border: "solid red 1px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", xs: "column" },
          width: "100%",
          maxWidth: { lg: 1300, xl: 1600, md: 800 },
          alignItems: "center",
          boxSizing: "border-box",
          // border: "solid red 1px ",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            minHeight: company === "Burgerheim" ? "100vh" : "150vh",
            // border: "solid red 1px",
          }}
        >
          <ThemeProvider theme={customTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid
                lg={12}
                xs={12}
                item
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent:
                    subscriptionLevel === "basic"
                      ? "center"
                      : { lg: "flex-start", xs: "center" },
                  gap: 2,
                  flexDirection: "row",
                  alignItems:
                    subscriptionLevel === "basic"
                      ? "center"
                      : { lg: "flex-start", xs: "center" },
                  position: "relative",
                  // border: "solid red 1px",
                  // height: "10%",
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100px",
                    justifyContent: { lg: "space-between", xs: "space-around" },
                    alignItems: "center",
                    // border: "solid red 1px",
                  }}
                >
                  <Tooltip
                    arrow
                    title={tooltipContent[counter]}
                    open={helpMode && counter === 1}
                  >
                    <FormControl
                      sx={{
                        ...(counter === 1 && helpMode
                          ? getHighlightedStyle()
                          : {}),

                        width: 200,
                        gap: 1,
                        mb: { lg: "2rem", xs: 0 },
                        mt: "2rem",
                        ml: { xs: "5rem", lg: 0 },
                        // border: "solid red 1px",
                      }}
                    >
                      <InputLabel id="restaurant-select-label">
                        Select Restaurant
                      </InputLabel>
                      <Select
                        labelId="restaurant-select-label"
                        id="restaurant-select"
                        value={selectedRestaurant.name}
                        onChange={(event) => {
                          handleRestaurantChange(event);
                        }}
                        label="Select Restaurant"
                        renderValue={(selected) => selected.name}
                      >
                        {defaultRestaurants.map((restaurant) => (
                          <MenuItem key={restaurant.id} value={restaurant}>
                            <ListItemText primary={restaurant.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Tooltip>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      width: "30px",
                      flexDirection: "row",
                      position: { lg: "absolute", xs: "relative" },
                      top: { lg: 20, xs: 10 },
                      right: 0,
                      padding: "8px",
                      // border: "solid 1px red",
                    }}
                  >
                    <HelpModeButton
                      isXSScreen={isXSScreen}
                      handleHelpMode={handleHelpMode}
                      helpMode={helpMode}
                    />
                  </Box>
                </Box>
              </Grid>

              <Button
                variant="contained"
                endIcon={<CheckIcon />}
                onClick={() => {
                  handleSubmit();
                  setIsDirty(false);
                }}
                sx={{
                  width: { lg: "180px", xs: "100px" },
                  color: "#645e29",
                  backgroundColor: Colors.krunch,
                  "&:hover": {
                    backgroundColor: "#e3a902",
                    boxShadow: "none",
                  },
                  "&:active": {
                    backgroundColor: "#e3a902",
                  },
                  position: "fixed", // Position fixed to make it floating
                  bottom: {
                    lg: 35,
                    xs: helpMode ? 90 : 35, // Change bottom to 90 when helpMode is on
                  },
                  right: { lg: 220, xs: 35 },
                  zIndex: 1000,
                }}
              >
                {isXSScreen ? "Save" : "Save Changes"}
              </Button>

              {subscriptionLevel === "basic" ? (
                <Tooltip
                  arrow
                  title={tooltipContent[counter]}
                  open={helpMode && counter === 2}
                >
                  <Box
                    sx={{
                      ...(counter === 2 && helpMode
                        ? getHighlightedStyle()
                        : {}),

                      boxShadow:
                        counter === 2 && helpMode
                          ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                          : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                      display: "flex",
                      width: { lg: "50%", xs: "90%" },
                      borderRadius: "1rem",
                      // ml: { lg: 2, xs: 0 },
                      mb: { lg: 0, xs: 2 },
                      height: "45rem",
                      flexDirection: "column",
                      // border: "solid red 1px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "20%",
                      }}
                    >
                      <TitleStyle
                        tooltipKey="opening_hours"
                        style={{ fontSize: "22px" }}
                      >
                        Opening Hours
                      </TitleStyle>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mt: 2,
                          // border:"solid red 1px"
                        }}
                      >
                        <FormControl
                          sx={{
                            width: {
                              lg: "80%",
                              xs: "70%",
                            },
                            gap: 1,
                          }}
                        >
                          <FormControl
                            sx={{
                              width: {
                                lg: "100%",
                                xs: "100%",
                              },
                              gap: 1,
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip
                              title="Set opening hours for Recurring Days"
                              placement="top"
                              arrow
                            >
                              {/* <Button
                                onClick={() => setOpenDialog(true)}
                                sx={{
                                  color: "#FFF",
                                  backgroundColor: "#70335D",
                                  width: "20ch",
                                  // mb: "2rem",
                                  "&:hover": {
                                    backgroundColor: "#5A294A", // You can adjust this color based on your preference
                                    // Prevent MUI Button from changing the color on hover
                                    boxShadow: "none",
                                  },
                                  "&:active": {
                                    backgroundColor: "#5A294A", // Adjust this color based on your preference
                                  },
                                }}
                              >
                                Regular
                              </Button> */}
                              <StyledButton
                                onClick={() => setOpenDialog(true)}
                                style={{
                                  backgroundColor: "#70335D",
                                  color: "#FFF",
                                  "&:hover": {
                                    backgroundColor: "#5A294A",
                                    boxShadow: "none",
                                  },
                                }}
                              >
                                Regular
                              </StyledButton>
                            </Tooltip>

                            <Dialog
                              open={openDialog}
                              onClose={() => setOpenDialog(false)}
                              PaperProps={{
                                sx: {
                                  width: { lg: "40%", xs: "100%" },
                                  height: "50%",
                                },
                              }}
                            >
                              <DialogTitle>Regular Opening Hours</DialogTitle>
                              <DialogContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    // border:"solid red 1px"
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: "flex",
                                      flexDirection: { lg: "row", xs: "row" },
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      mt: 2,
                                      mb: 3,
                                    }}
                                  >
                                    <DatePicker
                                      label="Start Date"
                                      value={recurringStartDate}
                                      onChange={(date) =>
                                        setRecurringStartDate(date)
                                      }
                                      format="DD/MM/YYYY"
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      required={false}
                                      sx={{ mr: { lg: 2, xs: 2 } }}
                                    />
                                    <DatePicker
                                      label="End Date"
                                      value={recurringEndDate}
                                      onChange={(date) =>
                                        setRecurringEndDate(date)
                                      }
                                      minDate={recurringStartDate}
                                      format="DD/MM/YYYY"
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      InputProps={{
                                        inputProps: {
                                          min: recurringStartDate, // Set min date based on the selected start date
                                        },
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      required={false}
                                    />
                                  </FormControl>
                                </Box>
                                {days.map((day, index) => (
                                  <Grid key={day}>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                      <Typography variant="body1">
                                        {day.name}
                                      </Typography>
                                    </Grid>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        width: "100%",
                                        // border: "solid red 1px",
                                      }}
                                    >
                                      <TextField
                                        error={
                                          dayErrors[index].openingHoursError
                                        }
                                        helperText={
                                          dayErrors[index].openingHoursError
                                            ? "Time cannot exceed 24"
                                            : ""
                                        }
                                        label="Opening Hours"
                                        type="number"
                                        value={day.openingHours}
                                        onChange={(e) =>
                                          handleDayChange(
                                            index,
                                            "openingHours",
                                            e.target.value
                                          )
                                        }
                                        onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                        inputProps={{ min: 0, max: 24 }}
                                        sx={{
                                          width: { lg: "50%", xs: "45%" },
                                          mr: "15px",
                                          mb: "15px",
                                        }}
                                      />
                                      <TextField
                                        error={
                                          dayErrors[index].closingHoursError
                                        }
                                        helperText={
                                          dayErrors[index].closingHoursError
                                            ? "Time cannot exceed 24"
                                            : ""
                                        }
                                        label="Closing Hours"
                                        type="number"
                                        value={day.closingHours}
                                        onChange={(e) =>
                                          handleDayChange(
                                            index,
                                            "closingHours",
                                            e.target.value
                                          )
                                        }
                                        onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                        inputProps={{ min: 0, max: 24 }}
                                        sx={{
                                          width: { lg: "50%", xs: "45%" },
                                          mb: "15px",
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                ))}

                                {/* Insert DatePicker components here */}
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => setOpenDialog(false)}
                                  sx={{
                                    backgroundColor: Colors.red,
                                    color: "white",
                                    "&:hover": {
                                      backgroundColor: Colors.deleteHover, // Replace with your hover color
                                    },
                                  }}
                                >
                                  Close
                                </Button>
                                <Button
                                  onClick={() => {
                                    submitRecurringDays();
                                    setOpenDialog(false);
                                  }}
                                  sx={{
                                    backgroundColor: Colors.positive,
                                    color: "white",
                                    "&:hover": {
                                      backgroundColor: Colors.positiveHover, // Replace with your hover color
                                    },
                                  }}
                                >
                                  Save
                                </Button>
                              </DialogActions>
                            </Dialog>

                            <Tooltip
                              title="Set opening hours for Special Days"
                              placement="top"
                              arrow
                            >
                              <StyledButton
                                onClick={() => handleOpenSpecialDialog(true)}
                                style={{
                                  backgroundColor: "#70335D",
                                  color: "#FFF",
                                  "&:hover": {
                                    backgroundColor: "#5A294A",
                                    boxShadow: "none",
                                  },
                                }}
                              >
                                Special
                              </StyledButton>
                            </Tooltip>
                          </FormControl>

                          <Dialog
                            open={openSpecialDialog}
                            onClose={handleCloseSpecialDialog}
                          >
                            <DialogTitle>Set Special Opening Hours</DialogTitle>
                            <DialogContent>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={6}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    mt: 2,
                                  }}
                                >
                                  <TextField
                                    sx={{ width: { lg: "100%", xs: "100%" } }}
                                    label="Opening Hours"
                                    type="number"
                                    value={openingHours}
                                    onChange={(e) =>
                                      setOpeningHours(e.target.value)
                                    }
                                    onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                    inputProps={{ min: 0, max: 24 }}
                                    required={false}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    mt: 2,
                                  }}
                                >
                                  <TextField
                                    sx={{ width: { lg: "100%", xs: "100%" } }}
                                    label="Closing Hours"
                                    type="number"
                                    value={closingHours}
                                    onChange={(e) =>
                                      setClosingHours(e.target.value)
                                    }
                                    onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                    inputProps={{ min: openingHours, max: 24 }}
                                    required={false}
                                  />
                                </Grid>
                                <FormControl
                                  sx={{
                                    display: "flex",
                                    flexDirection: { lg: "row", xs: "column" },
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <FormControl
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    sx={{
                                      display: "flex",
                                      flexDirection: { lg: "row", xs: "row" },
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: 2,
                                      ml: 2,
                                      mt: 2,
                                      width: "100%",
                                    }}
                                  >
                                    <DatePicker
                                      label="Start Date"
                                      value={specialStartDate}
                                      onChange={(date) =>
                                        setSpecialStartDate(date)
                                      }
                                      format="DD/MM/YYYY"
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      required={false}
                                      sx={{ width: "50%" }}
                                    />

                                    <DatePicker
                                      label="End Date"
                                      value={specialEndDate}
                                      onChange={(date) =>
                                        setSpecialEndDate(date)
                                      }
                                      format="DD/MM/YYYY"
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      InputProps={{
                                        inputProps: {
                                          min: specialStartDate, // Set min date based on the selected start date
                                        },
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      required={false}
                                      sx={{ width: "50%" }}
                                    />
                                  </FormControl>
                                </FormControl>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleCloseSpecialDialog}
                                sx={{
                                  backgroundColor: Colors.red,
                                  color: "white",
                                  "&:hover": {
                                    backgroundColor: Colors.deleteHover, // Replace with your hover color
                                  },
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={() => {
                                  submitSpecialOpeningHours();
                                  handleCloseSpecialDialog();
                                  // Add any other actions you want to perform on save
                                }}
                                sx={{
                                  backgroundColor: Colors.positive,
                                  color: "white",
                                  "&:hover": {
                                    backgroundColor: Colors.positiveHover, // Replace with your hover color
                                  },
                                }}
                              >
                                Save
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </FormControl>

                        <StyledButton
                          onClick={handleSubmit}
                          style={{ mt: "1rem" }}
                        >
                          Submit
                        </StyledButton>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        height: 500,
                        // justifyContent:"center",
                        alignItems: "center",
                        width: { lg: "100%", xs: "100%" },
                        // border: "solid 1px red",
                        display: "flex",
                        flexDirection: "column",
                        mt: "3rem",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ArrowBackIcon
                          onClick={handleBackwardClick}
                          sx={{
                            padding: "8px",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.04)",
                              borderRadius: "50%",
                            },
                          }}
                        />
                        <TextField
                          label="Select Week"
                          type="text"
                          variant="outlined"
                          value={WeekStartDate}
                          onChange={handleWeekStartDateChange}
                          // disabled
                          sx={{ width: "150px", mb: 1 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <ArrowForwardIcon
                          onClick={handleForwardClick}
                          sx={{
                            padding: "8px",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.04)",
                              borderRadius: "50%",
                            },
                          }}
                        />
                      </Box>
                      {isLoading ? (
                        <ChartLoading />
                      ) : (
                        <>
                          {/* {openHours.length > 0 ? (
                            <Tooltip
                              title={
                                <Typography variant="body1">
                                  The closing hour is rounded to the next full
                                  hour. So closing at 03:30 will show up as 4 in
                                  the table, but this does not affect sales nor
                                  predictions.
                                </Typography>
                              }
                              arrow
                              placement="top"
                              TransitionComponent={Zoom}
                            >
                              <Box
                                sx={{
                                  height: 400,
                                  width: "90%",
                                }}
                              >
                                <StyledDataGrid
                                  rows={openHours}
                                  columns={columns}
                                  pageSize={7}
                                  rowsPerPageOptions={[7]}
                                  checkboxSelection={false}
                                  disableSelectionOnClick
                                />
                              </Box>
                            </Tooltip>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <h2> No data</h2>
                            </div>
                          )} */}
                          <Box
                            sx={{
                              height: "27rem",
                              overflowY: "auto",
                              overflowX: "hidden",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center", // Adjusted to align items to the start
                              flexDirection: "column",
                              width: "90%",
                              padding: "1rem", // Added padding
                              boxSizing: "border-box", // Ensure padding is included in the height calculation
                              // border: "solid 1px red",
                            }}
                          >
                            {openHours.length > 0 ? (
                              openHours.map((hours) => (
                                <OpeningHourCard
                                  key={hours.id}
                                  weekDay={hours.week_days}
                                  openingHour={hours.opening_hours}
                                  closingHour={hours.closing_hours}
                                />
                              ))
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <h2> No data</h2>
                              </div>
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </Tooltip>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { lg: "row", xs: "column" },
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      // border: "solid red 1px",
                    }}
                  >
                    <Tooltip
                      arrow
                      title={tooltipContent[counter]}
                      open={helpMode && counter === 2}
                    >
                      <Box
                        sx={{
                          ...(counter === 2 && helpMode
                            ? getHighlightedStyle()
                            : {}),
                          boxShadow:
                            counter === 2 && helpMode
                              ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                              : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                          display: "flex",
                          width: { lg: "50%", xs: "90%" },
                          borderRadius: "1rem",
                          mr: isXSScreen ? 0 : 2,
                          mb: isXSScreen ? 2 : 0,

                          // justifyContent: "center",
                          // alignItems: "center",
                          height: isXSScreen ? "30rem" : "35rem",

                          // border:"solid 1px red"
                        }}
                      >
                        <Grid
                          lg={12}
                          xs={12}
                          item
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 2,
                            height: "35rem",
                            // border: "solid red 1px",
                          }}
                        >
                          <TitleStyle
                            tooltipKey="fixed_cost"
                            style={{ fontSize: "24px", mt: "2rem" }}
                          >
                            Fixed Costs
                          </TitleStyle>

                          <FormControl
                            sx={{
                              width: {
                                lg: "50%",
                                xs: "70%",
                              },
                              gap: 1,
                            }}
                          >
                            <FormControl
                              sx={{
                                display: "flex",
                                flexDirection: { lg: "row", xs: "row" },
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                mt: 2,
                                mb: 3,
                              }}
                            >
                              {/* Start Date Picker */}
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ArrowBackIcon
                                    sx={{
                                      padding: "8px", // Adds space around the icon, increasing the border size

                                      cursor: "pointer", // Ensures the cursor is a pointer
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                                        borderRadius: "50%", // Makes the background round
                                      },
                                      // Rest of your styles if you have any
                                    }}
                                    onClick={handleSubtractMonth}
                                  />
                                  <DatePicker
                                    views={["year", "month"]}
                                    label="Month"
                                    value={date}
                                    onChange={handleMonthChange}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    sx={{ mx: 2 }} // Add some margin between the arrows and the picker
                                  />
                                  <ArrowForwardIcon
                                    sx={{
                                      padding: "8px", // Adds space around the icon, increasing the border size

                                      cursor: "pointer", // Ensures the cursor is a pointer
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                                        borderRadius: "50%", // Makes the background round
                                      },
                                      // Rest of your styles if you have any
                                    }}
                                    onClick={handleAddMonth}
                                  />
                                </div>
                              </LocalizationProvider>
                            </FormControl>
                            <Tooltip
                              title="Apply the specified value consistently across all fields for the upcoming date ranges. You can change it in the future"
                              arrow
                              placement="top"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={generalChecked}
                                    onChange={handleGeneralChange}
                                  />
                                }
                                label="Apply Default Value"
                              />
                            </Tooltip>
                            <Tooltip
                              TransitionComponent={Zoom}
                              title="This is the least the restaurant have to pay in rent regardless of sales"
                              placement="top"
                              arrow
                            >
                              <TextField
                                type="number"
                                label="Monthly rent minimum"
                                variant="outlined"
                                value={isLoading ? "" : minimumMonthlyRent}
                                onChange={(e) => {
                                  setMinimumMonthlyRent(e.target.value);
                                  setIsDirty(true);
                                }}
                                onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                InputProps={{
                                  endAdornment: isLoading ? <KPILoad /> : null,
                                }}
                              />
                            </Tooltip>

                            <Tooltip
                              TransitionComponent={Zoom}
                              title="This is the percentage of total monthly sales ex. VAT that the 
                restaurant have to pay in rent if that sum is higher than the monthly rent minimum"
                              placement="top"
                              arrow
                            >
                              <TextField
                                type="number"
                                label="Monthly rent % of sales"
                                variant="outlined"
                                value={isLoading ? "" : monthlyRentVariable}
                                onChange={(e) => {
                                  setMonthlyRentVariable(e.target.value);
                                  setIsDirty(true);
                                }}
                                onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      {isLoading ? (
                                        <KPILoad /> // Assuming KPILoading is your custom loading indicator component
                                      ) : (
                                        <Typography fontWeight="bold">
                                          NOK
                                        </Typography>
                                      )}{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Tooltip>

                            {/* <Tooltip
                TransitionComponent={Zoom}
                title="New tooltip"
                placement="top"
                arrow
              >
                <TextField
                  type="number"
                  label="Variable of total sales"
                  variant="outlined"
                  value={monthlySalesVariable}
                  onChange={(e) => setMonthlySalesVariable(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Typography fontWeight="bold">%</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip> */}

                            {/* <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            width: "100%",
                          }}
                        > */}
                            {/* Ensure alignment and spacing */}
                            <Tooltip
                              TransitionComponent={Zoom}
                              title="Other Fixed Costs"
                              placement="top"
                              arrow
                            >
                              <TextField
                                type="number"
                                label="Other Fixed Costs"
                                variant="outlined"
                                value={isLoading ? "" : otherCosts}
                                onChange={(e) => {
                                  setOtherCosts(e.target.value);
                                  setIsDirty(true);
                                }}
                                onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                InputProps={{
                                  endAdornment: isLoading ? <KPILoad /> : null,
                                }}
                              />
                            </Tooltip>
                            {/* <Tooltip
                            TransitionComponent={Zoom}
                            title="Set as default"
                            placement="top"
                            arrow
                          >
                            <Checkbox defaultChecked />
                          </Tooltip> */}
                            {/* </Box> */}
                          </FormControl>
                        </Grid>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      arrow
                      title={tooltipContent[counter]}
                      open={helpMode && counter === 3}
                      placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
                    >
                      <Box
                        sx={{
                          ...(counter === 3 && helpMode
                            ? getHighlightedStyle()
                            : {}),

                          boxShadow:
                            counter === 3 && helpMode
                              ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                              : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                          display: "flex",
                          width: { lg: "50%", xs: "90%" },
                          borderRadius: "1rem",
                          // justifyContent: "center",
                          // alignItems: "center",
                          height: isXSScreen ? "35rem" : "35rem",
                          mb: { lg: 0, xs: 2 },

                          // border: "solid 1px red",
                        }}
                      >
                        <Grid
                          lg={12}
                          xs={12}
                          item
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            gap: 2,
                            flexDirection: "column",
                            alignItems: "center",
                            height: "35rem",
                            // border: "solid red 1px",
                          }}
                        >
                          <TitleStyle
                            tooltipKey="financial_goal"
                            style={{ fontSize: "24px", mt: "2rem" }}
                          >
                            {" "}
                            Financial Goals{" "}
                          </TitleStyle>

                          {/* dropdowns for month and year */}
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                              width: "70%",
                              // border: "solid red 1px",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 2,
                                  mt: 4,
                                }}
                              >
                                <ArrowBackIcon
                                  sx={{
                                    padding: "8px", // Adds space around the icon, increasing the border size

                                    cursor: "pointer", // Ensures the cursor is a pointer
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                                      borderRadius: "50%", // Makes the background round
                                    },
                                    // Rest of your styles if you have any
                                  }}
                                  onClick={handleSubtractGoalsMonth}
                                />
                                <DatePicker
                                  views={["year", "month"]}
                                  label="Year and Month"
                                  minDate={dayjs("2000-01-01")}
                                  maxDate={dayjs("2030-12-31")}
                                  value={selectedDate}
                                  openTo="month"
                                  onChange={handleDateChange}
                                  renderInput={(params) => (
                                    <TextField {...params} fullWidth />
                                  )}
                                />
                                <ArrowForwardIcon
                                  sx={{
                                    padding: "8px", // Adds space around the icon, increasing the border size

                                    cursor: "pointer", // Ensures the cursor is a pointer
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                                      borderRadius: "50%", // Makes the background round
                                    },
                                    // Rest of your styles if you have any
                                  }}
                                  onClick={handleAddGoalsMonth}
                                />
                              </Box>
                            </LocalizationProvider>
                          </Grid>
                          {/* {console.log(selectedDate, "ayo date")} */}
                          <Tooltip
                            title="Apply the specified value consistently across all fields for the upcoming date ranges. You can change it in the future"
                            arrow
                            placement="top"
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={goalsChecked}
                                  onChange={handleGoalsChange}
                                />
                              }
                              label="Apply Default Value"
                            />
                          </Tooltip>
                          <FormControl
                            sx={{
                              width: {
                                lg: "50%",
                                xs: "70%",
                              },
                              gap: 1,
                              mb: "2rem",
                            }}
                          >
                            <TextField
                              type="number"
                              label="Monthly Goal for Sales"
                              variant="outlined"
                              value={isLoading ? "" : monthlySales} // Do not show value when loading
                              onChange={(e) => {
                                handleSalesChange(e.target.value);
                                setIsDirty(true);
                              }}
                              error={errors.salesError}
                              helperText={
                                errors.salesError
                                  ? "Sales should be greater than Gross Profit I"
                                  : ""
                              }
                              onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {isLoading ? (
                                      <KPILoad /> // Assuming KPILoading is your custom loading indicator component
                                    ) : (
                                      <Typography fontWeight="bold">
                                        NOK
                                      </Typography>
                                    )}{" "}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              type="number"
                              label="Monthly Goal for Gross Profit I"
                              variant="outlined"
                              value={isLoading ? "" : monthlyGrossProfit}
                              onChange={(e) => {
                                handleGrossProfitChange(e.target.value);
                                setIsDirty(true);
                              }}
                              error={errors.grossProfitError}
                              helperText={
                                errors.grossProfitError
                                  ? "Gross Profit I should be greater than Gross Profit II"
                                  : ""
                              }
                              onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {isLoading ? (
                                      <KPILoad /> // Assuming KPILoading is your custom loading indicator component
                                    ) : (
                                      <Typography fontWeight="bold">
                                        NOK
                                      </Typography>
                                    )}{" "}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              type="number"
                              label="Monthly Goal for Gross Profit II"
                              variant="outlined"
                              value={isLoading ? "" : monthlyNetProfit}
                              onChange={(e) => {
                                handleNetProfitChange(e.target.value);
                                setIsDirty(true);
                              }}
                              error={errors.netProfitError}
                              helperText={
                                errors.netProfitError
                                  ? "Gross Profit II should not exceed Gross Profit I"
                                  : ""
                              }
                              onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {isLoading ? (
                                      <KPILoad /> // Assuming KPILoading is your custom loading indicator component
                                    ) : (
                                      <Typography fontWeight="bold">
                                        NOK
                                      </Typography>
                                    )}{" "}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {/* <TextField
                          type="number"
                          label="Employee Cost Goal"
                          variant="outlined"
                          value={isLoading ? "" : monthlyEmployeeCost}
                          onChange={(e) => {
                            handleEmployeeCostChange(e.target.value);
                            setIsDirty(true);
                          }}
                          // error={errors.employeeCostError}
                          // helperText={
                          //   errors.employeeCostError
                          //     ? "Gross Profit should be greater than Net Profit"
                          //     : ""
                          // }
                          onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                {isLoading ? (
                                  <KPILoad /> // Assuming KPILoading is your custom loading indicator component
                                ) : (
                                  <Typography fontWeight="bold">NOK</Typography>
                                )}{" "}
                              </InputAdornment>
                            ),
                          }}
                        /> */}
                          </FormControl>
                        </Grid>
                      </Box>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { lg: "row", xs: "column" },
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Tooltip
                      arrow
                      title={tooltipContent[counter]}
                      open={helpMode && counter === 4}
                    >
                      <Box
                        sx={{
                          ...(counter === 4 && helpMode
                            ? getHighlightedStyle()
                            : {}),

                          boxShadow:
                            counter === 4 && helpMode
                              ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                              : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                          display: "flex",
                          width: { lg: "50%", xs: "90%" },
                          borderRadius: "1rem",
                          // ml: { lg: 2, xs: 0 },
                          mb: { lg: 0, xs: 2 },
                          height: "45rem",
                          flexDirection: "column",
                          overflowY: "auto",

                          // border: "solid red 1px",
                        }}
                      >
                        <Box
                          sx={{
                            height: "20%",
                          }}
                        >
                          <TitleStyle
                            tooltipKey="opening_hours"
                            style={{ fontSize: "24px", mt: "2rem" }}
                          >
                            Opening Hours
                          </TitleStyle>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              mt: 2,
                              // border:"solid red 1px"
                            }}
                          >
                            <FormControl
                              sx={{
                                width: {
                                  lg: "80%",
                                  xs: "70%",
                                },
                                gap: 1,
                              }}
                            >
                              <FormControl
                                sx={{
                                  width: {
                                    lg: "100%",
                                    xs: "100%",
                                  },
                                  gap: 1,
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip
                                  title="Set opening hours for Recurring Days"
                                  placement="top"
                                  arrow
                                >
                                  <StyledButton
                                    onClick={() => setOpenDialog(true)}
                                    style={{
                                      backgroundColor: "#70335D",
                                      color: "#FFF",
                                      "&:hover": {
                                        backgroundColor: "#5A294A",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    Regular
                                  </StyledButton>
                                </Tooltip>

                                <Dialog
                                  open={openDialog}
                                  onClose={() => setOpenDialog(false)}
                                  PaperProps={{
                                    sx: {
                                      width: { lg: "40%", xs: "100%" },
                                      height: "50%",
                                    },
                                  }}
                                >
                                  <DialogTitle>
                                    Regular Opening Hours
                                  </DialogTitle>
                                  <DialogContent>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        // border:"solid red 1px"
                                      }}
                                    >
                                      <FormControl
                                        sx={{
                                          display: "flex",
                                          flexDirection: {
                                            lg: "row",
                                            xs: "row",
                                          },
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: "100%",
                                          mt: 2,
                                          mb: 3,
                                        }}
                                      >
                                        <DatePicker
                                          label="Start Date"
                                          value={recurringStartDate}
                                          onChange={(date) =>
                                            setRecurringStartDate(date)
                                          }
                                          format="DD/MM/YYYY"
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          required={false}
                                          sx={{ mr: { lg: 2, xs: 2 } }}
                                        />
                                        <DatePicker
                                          label="End Date"
                                          value={recurringEndDate}
                                          onChange={(date) =>
                                            setRecurringEndDate(date)
                                          }
                                          minDate={recurringStartDate}
                                          format="DD/MM/YYYY"
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                          InputProps={{
                                            inputProps: {
                                              min: recurringStartDate, // Set min date based on the selected start date
                                            },
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          required={false}
                                        />
                                      </FormControl>
                                    </Box>
                                    {days.map((day, index) => (
                                      <Grid key={day}>
                                        <Grid item xs={12} sx={{ mb: 2 }}>
                                          <Typography variant="body1">
                                            {day.name}
                                          </Typography>
                                        </Grid>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            width: "100%",
                                            // border:"solid red 1px"
                                          }}
                                        >
                                          <TextField
                                            error={
                                              dayErrors[index].openingHoursError
                                            }
                                            helperText={
                                              dayErrors[index].openingHoursError
                                                ? "Time cannot exceed 24"
                                                : ""
                                            }
                                            label="Opening Hours"
                                            type="number"
                                            value={day.openingHours}
                                            onChange={(e) =>
                                              handleDayChange(
                                                index,
                                                "openingHours",
                                                e.target.value
                                              )
                                            }
                                            onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                            inputProps={{ min: 0, max: 24 }}
                                            sx={{
                                              width: { lg: "50%", xs: "45%" },
                                              mr: "15px",
                                              mb: "15px",
                                            }}
                                          />
                                          <TextField
                                            error={
                                              dayErrors[index].closingHoursError
                                            }
                                            helperText={
                                              dayErrors[index].closingHoursError
                                                ? "Time cannot exceed 24"
                                                : ""
                                            }
                                            label="Closing Hours"
                                            type="number"
                                            value={day.closingHours}
                                            onChange={(e) =>
                                              handleDayChange(
                                                index,
                                                "closingHours",
                                                e.target.value
                                              )
                                            }
                                            onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                            inputProps={{ min: 0, max: 24 }}
                                            sx={{
                                              width: { lg: "50%", xs: "45%" },
                                              mb: "15px",
                                            }}
                                          />
                                        </Box>
                                      </Grid>
                                    ))}

                                    {/* Insert DatePicker components here */}
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      onClick={() => setOpenDialog(false)}
                                      sx={{
                                        backgroundColor: Colors.red,
                                        color: "white",
                                        "&:hover": {
                                          backgroundColor: Colors.deleteHover, // Replace with your hover color
                                        },
                                      }}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        submitRecurringDays();
                                        setOpenDialog(false);
                                      }}
                                      sx={{
                                        backgroundColor: Colors.positive,
                                        color: "white",
                                        "&:hover": {
                                          backgroundColor: Colors.positiveHover, // Replace with your hover color
                                        },
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </DialogActions>
                                </Dialog>

                                <Tooltip
                                  title="Special Days"
                                  placement="top"
                                  arrow
                                >
                                  <StyledButton
                                    onClick={() =>
                                      handleOpenSpecialDialog(true)
                                    }
                                    style={{
                                      backgroundColor: "#70335D",
                                      color: "#FFF",
                                      "&:hover": {
                                        backgroundColor: "#5A294A",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    Special
                                  </StyledButton>
                                </Tooltip>
                              </FormControl>

                              <Dialog
                                open={openSpecialDialog}
                                onClose={handleCloseSpecialDialog}
                              >
                                <DialogTitle>
                                  Set Special Opening Hours
                                </DialogTitle>
                                <DialogContent>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={6}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        mt: 2,
                                      }}
                                    >
                                      <TextField
                                        sx={{
                                          width: { lg: "100%", xs: "100%" },
                                        }}
                                        label="Opening Hours"
                                        type="number"
                                        value={openingHours}
                                        onChange={(e) =>
                                          setOpeningHours(e.target.value)
                                        }
                                        onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                        inputProps={{ min: 0, max: 24 }}
                                        required={false}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        mt: 2,
                                      }}
                                    >
                                      <TextField
                                        sx={{
                                          width: { lg: "100%", xs: "100%" },
                                        }}
                                        label="Closing Hours"
                                        type="number"
                                        value={closingHours}
                                        onChange={(e) =>
                                          setClosingHours(e.target.value)
                                        }
                                        onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                        inputProps={{
                                          min: openingHours,
                                          max: 24,
                                        }}
                                        required={false}
                                      />
                                    </Grid>
                                    <FormControl
                                      sx={{
                                        display: "flex",
                                        flexDirection: {
                                          lg: "row",
                                          xs: "column",
                                        },
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <FormControl
                                        lg={12}
                                        md={12}
                                        xs={12}
                                        sx={{
                                          display: "flex",
                                          flexDirection: {
                                            lg: "row",
                                            xs: "row",
                                          },
                                          justifyContent: "center",
                                          alignItems: "center",
                                          gap: 2,
                                          ml: 2,
                                          mt: 2,
                                          width: "100%",
                                        }}
                                      >
                                        <DatePicker
                                          label="Start Date"
                                          value={specialStartDate}
                                          onChange={(date) =>
                                            setSpecialStartDate(date)
                                          }
                                          format="DD/MM/YYYY"
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          required={false}
                                          sx={{ width: "50%" }}
                                        />

                                        <DatePicker
                                          label="End Date"
                                          value={specialEndDate}
                                          onChange={(date) =>
                                            setSpecialEndDate(date)
                                          }
                                          format="DD/MM/YYYY"
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                          InputProps={{
                                            inputProps: {
                                              min: specialStartDate, // Set min date based on the selected start date
                                            },
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          required={false}
                                          sx={{ width: "50%" }}
                                        />
                                      </FormControl>
                                    </FormControl>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={handleCloseSpecialDialog}
                                    sx={{
                                      backgroundColor: Colors.red,
                                      color: "white",
                                      "&:hover": {
                                        backgroundColor: Colors.deleteHover, // Replace with your hover color
                                      },
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      submitSpecialOpeningHours();
                                      handleCloseSpecialDialog();
                                      // Add any other actions you want to perform on save
                                    }}
                                    sx={{
                                      backgroundColor: Colors.positive,
                                      color: "white",
                                      "&:hover": {
                                        backgroundColor: Colors.positiveHover, // Replace with your hover color
                                      },
                                    }}
                                  >
                                    Save
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </FormControl>

                            <StyledButton
                              onClick={handleSubmit}
                              style={{ mt: "1rem" }}
                            >
                              Submit
                            </StyledButton>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            height: 500,
                            // justifyContent:"center",
                            alignItems: "center",
                            width: { lg: "100%", xs: "100%" },
                            // border: "solid 1px red",
                            display: "flex",
                            flexDirection: "column",
                            mt: "5rem",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ArrowBackIcon
                              onClick={handleBackwardClick}
                              sx={{
                                padding: "8px",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                  borderRadius: "50%",
                                },
                              }}
                            />
                            <TextField
                              label="Select Week"
                              type="text"
                              variant="outlined"
                              value={WeekStartDate}
                              onChange={handleWeekStartDateChange}
                              // disabled
                              sx={{ width: "150px", mb: 1 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <ArrowForwardIcon
                              onClick={handleForwardClick}
                              sx={{
                                padding: "8px",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                  borderRadius: "50%",
                                },
                              }}
                            />
                          </Box>
                          {isLoading ? (
                            <ChartLoading />
                          ) : (
                            <>
                              <Box
                                sx={{
                                  height: "27rem",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center", // Adjusted to align items to the start
                                  flexDirection: "column",
                                  width: "90%",
                                  padding: "1rem", // Added padding
                                  boxSizing: "border-box", // Ensure padding is included in the height calculation
                                  // border: "solid 1px red",
                                }}
                              >
                                {openHours.length > 0 ? (
                                  openHours.map((hours) => (
                                    <OpeningHourCard
                                      key={hours.id}
                                      weekDay={hours.week_days}
                                      openingHour={hours.opening_hours}
                                      closingHour={hours.closing_hours}
                                    />
                                  ))
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <h2> No data</h2>
                                  </div>
                                )}
                              </Box>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      arrow
                      title={tooltipContent[counter]}
                      open={helpMode && counter === 5}
                    >
                      <Box
                        sx={{
                          ...(counter === 5 && helpMode
                            ? getHighlightedStyle()
                            : {}),
                          boxShadow:
                            counter === 5 && helpMode
                              ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                              : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                          display: "flex",
                          width: { lg: "50%", xs: "90%" },
                          borderRadius: "1rem",
                          ml: { lg: 2, xs: 0 },
                          mb: { lg: 0, xs: 2 },
                          height: isXSScreen ? "30rem" : "45rem",
                          // border: "solid red 1px",
                        }}
                      >
                        <Grid
                          lg={12}
                          xs={12}
                          item
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            gap: 2,
                            flexDirection: "column",
                            alignItems: "center",
                            mt: "2rem",
                          }}
                        >
                          <TitleStyle
                            tooltipKey="prediction"
                            style={{ fontSize: "24px" }}
                          >
                            Prediction
                          </TitleStyle>
                          <Tooltip
                            title="Apply the specified value consistently across all fields for the upcoming date ranges. You can change it in the future"
                            arrow
                            placement="top"
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={predictionChecked}
                                  onChange={handlePredictionChange}
                                />
                              }
                              label="Apply Default Value"
                            />
                          </Tooltip>
                          <FormControl
                            sx={{
                              width: {
                                lg: "50%",
                                xs: "70%",
                              },
                              gap: 1,
                              mb: "2rem",
                            }}
                          >
                            <TextField
                              type="number"
                              label="Number of Outdoor Seats"
                              variant="outlined"
                              value={isLoading ? "" : outdoorSeats}
                              onChange={(e) => setOutdoorSeats(e.target.value)}
                              onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                              InputProps={{
                                endAdornment: isLoading ? <KPILoad /> : null,
                              }}
                            />
                            <TextField
                              type="number"
                              label="Number of Indoor Seats"
                              variant="outlined"
                              value={isLoading ? "" : indoorSeats}
                              onChange={(e) => setIndoorSeats(e.target.value)}
                              onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                              InputProps={{
                                endAdornment: isLoading ? <KPILoad /> : null,
                              }}
                            />

                            <Tooltip
                              TransitionComponent={Zoom}
                              title="How much less labour is needed for take away sales compared to sales in 
                the restaurant. 1 means no difference, 0.8 means 80% of labour need compared to
                normal dine in sale"
                              placement="top"
                              arrow
                            >
                              <TextField
                                type="number"
                                label="Take away labour intensity"
                                variant="outlined"
                                value={isLoading ? "" : takeAwayIntensity}
                                onChange={(e) =>
                                  setTakeAwayIntensity(e.target.value)
                                }
                                onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                InputProps={{
                                  endAdornment: isLoading ? <KPILoad /> : null,
                                }}
                              />
                            </Tooltip>

                            <Tooltip
                              TransitionComponent={Zoom}
                              title="How much more/less labour is needed for alcohol related sales compared to non-alcohol
                sales. 1 means no difference, 0.8 means 80% of labour need compared to
                normal sales"
                              placement="top"
                              arrow
                            >
                              <TextField
                                type="number"
                                label="Alcohol labour intensity"
                                variant="outlined"
                                value={isLoading ? "" : alcoholIntensity}
                                onChange={(e) =>
                                  setAlcoholIntensity(e.target.value)
                                }
                                onWheel={(e) => e.target.blur()} // This will remove focus from the input when scrolling
                                InputProps={{
                                  endAdornment: isLoading ? <KPILoad /> : null,
                                }}
                              />
                            </Tooltip>
                          </FormControl>
                        </Grid>
                      </Box>
                    </Tooltip>
                  </Box>
                </>
              )}
            </LocalizationProvider>
          </ThemeProvider>
        </Grid>
      </Box>

      <SnackbarAlert
        open={open}
        onClose={handleClose}
        severity={severity}
        message={message}
      />
      {helpMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            width: { lg: "180px", xs: "280px" },
            position: "fixed", // Make it absolute to position at the bottom
            bottom: 20,
            right: 20,
            padding: "10px",
            // border:"solid 1px red"
          }}
        >
          <NavigationButtons
            isXSScreen={isXSScreen}
            setHelpMode={setHelpMode}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            counter={counter}
            maxSteps={maxSteps}
          />
        </Box>
      )}
    </Box>
  );
};

export default Admin;
