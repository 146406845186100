import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Tooltip,
  //   Typography,
  //   Tooltip,
} from "@mui/material";

import formatKpiNumber from "../../utils/formatKpiNumber";
import ChartLoading from "../../components/Loading/ChartLoading";

const OverviewTableStaff = ({
  avgEmpProdLoad = false,
  staffCostLoad = false,
  statStaffData = null,
  averageEmployeeProductivity = null,
  averageEmployeeCost = null,
  averageSickCost = null,
  load = false,
}) => {
  // console.log(statStaffData, "staff total here");
  // console.log(averageSickCost, " sick total here");
  // console.log(averageEmployeeCost, "emp total here");
  // console.log(averageEmployeeProductivity, "prod total here");

  //   console.log(sickCostPercent, averageSickCost, "sick cost data");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timer;
    if (!avgEmpProdLoad && !staffCostLoad && !load) {
      timer = setTimeout(() => {
        setLoading(false);
      }, 4000);
    } else {
      setLoading(true);
    }

    return () => clearTimeout(timer);
  }, [avgEmpProdLoad, staffCostLoad, load]);

  if (avgEmpProdLoad || staffCostLoad || load) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {/* Adjust height as needed */}
        <ChartLoading />
      </Box>
    );
  }

  if (
    // TotalData.length === 0 ||
    // !TotalData ||
    !averageEmployeeCost ||
    averageEmployeeCost.length === 0 ||
    !statStaffData ||
    statStaffData.length === 0 ||
    !averageSickCost ||
    averageSickCost.length === 0
    // ||
    // !averageEmployeeProductivity ||
    // averageEmployeeProductivity.length === 0
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {loading ? <ChartLoading /> : <h2>No Data</h2>}
        {/* Adjust height as needed */}
      </Box>
    );
  }

  //Styles
  const nameCellStyle = {
    py: 0,
    fontFamily: "'Montserrat', sans-serif",
    backgroundColor: "transparent",
  };

  const valueCellStyle = {
    // py: 0.5,
    fontWeight: 700,
    fontFamily: "'Montserrat', sans-serif",
    backgroundColor: "transparent",
  };

  // const boxStyle = {
  //   display: "flex",
  //   flexDirection: "row",
  //   width: "100%",
  //   alignItems: "center",
  //   justifyContent: "center",
  // };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        fontFamily: "'Montserrat', sans-serif",
        background: "#FFF",
        // outline: "solid 1px red",
        borderRadius: "0 0 1rem 1rem",
      }}
    >
      <TableContainer sx={{}}>
        <Table sx={{}} aria-label="KPI data table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#ededed", // light gray color for alternate rows

                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                },
              }}
            >
              <TableCell
                align="center"
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  py: 0.5,
                }}
              >
                KPI
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  py: 0.5,
                }}
              >
                Actual
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  py: 0.5,
                }}
              >
                Prediction
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Applying alternate row colors */}
            <TableRow
              sx={{
                backgroundColor: "white",
                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <Tooltip
                title={
                  <Typography>
                    Staff Cost Percentage is the portion of sales that is spent
                    on employees
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell sx={nameCellStyle}>Staff Cost Percentage</TableCell>
              </Tooltip>
              <TableCell align="center" sx={{ ...valueCellStyle }}>
                {statStaffData[0] !== null && statStaffData[0] !== undefined
                  ? `${formatKpiNumber(statStaffData[0])}%`
                  : "N/A"}
              </TableCell>
              <TableCell align="center" sx={{ ...valueCellStyle }}>
                {statStaffData[1] !== null && statStaffData[1] !== undefined
                  ? `${formatKpiNumber(statStaffData[1])}%`
                  : "N/A"}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "#f7f7f7",
                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <Tooltip
                title={
                  <Typography>
                    Employee cost is the breakdown of employee-related expenses
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell sx={nameCellStyle}>Total Employee Cost</TableCell>
              </Tooltip>
              <TableCell align="center" sx={{ ...valueCellStyle }}>
                {averageEmployeeCost[0] !== null &&
                averageEmployeeCost[0] !== undefined
                  ? formatKpiNumber(averageEmployeeCost[0])
                  : "N/A"}
              </TableCell>
              <TableCell align="center" sx={{ ...valueCellStyle }}>
                {averageEmployeeCost[1] !== null &&
                averageEmployeeCost[1] !== undefined
                  ? formatKpiNumber(averageEmployeeCost[1])
                  : "N/A"}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "white",
                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <Tooltip
                title={
                  <Typography>
                    Employee productivity shows how much sales each employee
                    generates per hour.
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell sx={nameCellStyle}>Emp Productivity/hr</TableCell>
              </Tooltip>
              <TableCell align="center" sx={{ ...valueCellStyle }}>
                {averageEmployeeProductivity !== null &&
                averageEmployeeProductivity !== undefined
                  ? formatKpiNumber(averageEmployeeProductivity)
                  : " - "}
              </TableCell>
              <TableCell align="center" sx={{ ...valueCellStyle }}>
                N/A
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "#f7f7f7",
                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <Tooltip
                title={
                  <Typography>
                    The compensation paid to employees on leave
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell sx={nameCellStyle}>Total Sick Cost</TableCell>
              </Tooltip>
              <TableCell align="center" sx={{ ...valueCellStyle }}>
                {averageSickCost[0] !== null &&
                averageSickCost[0] !== undefined &&
                !isNaN(averageSickCost[0])
                  ? formatKpiNumber(averageSickCost[0])
                  : "-"}
              </TableCell>
              <TableCell align="center" sx={{ ...valueCellStyle }}>
                N/A
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OverviewTableStaff;
