import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  //    Container,
  // Card,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  //CardContent,
  Tooltip,
  //Zoom,
  useMediaQuery,
  InputAdornment,
  // Switch,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  ListItemText,
} from "@mui/material";

import ChartLoading from "../components/Loading/ChartLoading";

// import LineChart from "../components/LineChart";
import HourlyLineChart from "../components/LineCharts/HourlyLineChart";
import LineChart from "../components/LineCharts/LineChart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import { useTheme } from "@mui/material/styles";
import getHighlightedStyle from "../utils/HighlightedStyle";
import NavigationButtons from "../utils/HelpButton";
import HolidayTable from "../HolidayTable";
// import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../utils/SnackbarAlert";
import LastUpdate from "../utils/LastUpdate";
// import {
//   FaSun,
//   FaCloudSun,
//   FaSnowflake,
//   FaThermometerHalf,
// } from "react-icons/fa";
import Prefix from "../utils/PrefixLink";
import formatNumber from "../utils/FormatNumber";
import SettingsDialog from "../components/SettingsDialog";
import HelpModeButton from "../components/HelpModeButton";

const prefix = Prefix();

const company = localStorage.getItem("company");
const token = localStorage.getItem("token");

const NewRecommendations = () => {
  const [barDate, setBarDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [openDialog, setOpenDialog] = useState(false);

  // const [restaurants, setRestaurants] = useState([]);
  // const [weather, setWeather] = useState([]);

  const [salesPredData, setSalesPredData] = useState([]);
  const [predictionData, setPredictionData] = useState([]);
  const [holidayData, setHolidayData] = useState({});
  const [week, setWeek] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [helpMode, setHelpMode] = useState(false);
  const [counter, setCounter] = useState(1);
  const maxSteps = 5;
  const [restaurantNames, setRestaurantNames] = useState("");
  const [defaultRestaurants, setDefaultRestaurants] = useState([]);
  const [Loading, setLoading] = useState(false); // Start with loading true
  const [filterDate, setFilterDate] = useState("");

  const getRestaurantNameById = (restaurantName) => {
    const restaurant = defaultRestaurants.find(
      (r) => r.name === restaurantName
    );
    return restaurant ? restaurant.name : "";
  };

  // const [restaurantID, setRestaurantID] = useState();

  // const [hourlySalesData, setHourlySalesData] = useState([]);
  // const [hourlyPredictionData, setHourlyPredictionData] = useState([]);

  const [combinedHourlyData, setCombinedHourlyData] = useState(null);
  const [weatherData, setWeatherData] = useState(null);
  console.log(weatherData, "feather");

  const [isPredDialogOpen, setIsPredDialogOpen] = useState(false);
  const [salesLoad, setSalesLoad] = useState(false);
  const [cardLoad, setCardLoad] = useState(false);

  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  // const minDate = new Date();
  const [singlePredictionTotalGross, setSinglePredictionTotalGross] =
    useState("");
  // const navigate = useNavigate();

  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState("warning");

  const [singlePredictionDate, setSinglePredictionDate] = useState();
  const [comment, setComment] = useState("");
  // const [severity, setSeverity] = useState("error");
  const user = JSON.parse(localStorage.getItem("user"));
  const primaryRestaurant = user?.primary_restaurant;

  const handleClose = () => {
    setAlertOpen(false);
    setOpen(false);
  };

  // const [salesPredLoad, setSalesPredLoad] = useState(false);

  const theme = useTheme();

  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchRestaurants = useCallback(async () => {
    // setLoad(true);
    const restro_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();

      // Sort restaurants such that those with "Food Truck" in their name are at the end
      // const sortedRestaurants = [...restro_data.results].sort((a, b) => {
      //   const aIsFoodTruck = a.name.includes("Food Truck");
      //   const bIsFoodTruck = b.name.includes("Food Truck");

      //   if (aIsFoodTruck && !bIsFoodTruck) {
      //     return 1; // a should come after b
      //   }
      //   if (!aIsFoodTruck && bIsFoodTruck) {
      //     return -1;
      //   }
      //   return 0;
      // });

      // setRestaurants(sortedRestaurants);
      const userDefaultRestaurants = JSON.parse(
        localStorage.getItem("user")
      )?.default_restaurants;

      let filteredRestaurants = restro_data.results?.filter(
        (restaurant) =>
          userDefaultRestaurants.includes(restaurant.id) &&
          restaurant.name !== "Fisketorget Stavanger"
      );
      if (userDefaultRestaurants && userDefaultRestaurants.length > 0) {
        // Filter restaurants based on default_restaurants IDs
        filteredRestaurants = restro_data.results?.filter((restaurant) =>
          userDefaultRestaurants.includes(restaurant.id)
        );
      }
      if (!userDefaultRestaurants || userDefaultRestaurants.length === 0) {
        filteredRestaurants = restro_data.results?.filter(
          (restaurant) => restaurant.name !== "Fisketorget Stavanger"
        );
      }
      // Apply sorting to move 'Food Truck' to the bottom of the list
      filteredRestaurants?.sort((a, b) => {
        const isAFoodTruck = a?.name.includes("Food Truck");
        const isBFoodTruck = b?.name.includes("Food Truck");

        if (isAFoodTruck && !isBFoodTruck) {
          return 1; // Place 'a' after 'b'
        } else if (!isAFoodTruck && isBFoodTruck) {
          return -1; // Place 'a' before 'b'
        }
        return 0; // Keep original order for other restaurants
      });

      setDefaultRestaurants(filteredRestaurants);

      if (primaryRestaurant) {
        const mainRestaurant = filteredRestaurants?.filter(
          (restaurant) => restaurant.id === primaryRestaurant
        );
        setRestaurantNames([mainRestaurant[0]?.name]);
      }
      // setRestaurantNames(sortedRestaurants[0]?.name);
    }
  }, [primaryRestaurant]);

  const handleOpenDialog = (date) => {
    setSelectedDate(date);
    setFilterDate(date);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // console.log(weatherData);
  };
  const handleHelpMode = () => {
    setHelpMode(!helpMode);
    setCounter(1); // Reset counter when toggling helpMode
  };

  const handleNext = () => {
    setCounter((prev) => {
      const newCounter = prev + 1;
      if (newCounter >= 5) {
        setHelpMode(false);
      } else {
        // Scroll logic for mobile view in helpMode, only after counter reaches 4
        if (isXSScreen && helpMode && newCounter > 3) {
          const remInPixels = parseFloat(
            getComputedStyle(document.documentElement).fontSize
          );
          window.scrollBy({
            top: 30 * remInPixels, // Scroll by 30rem in pixels
            behavior: "smooth",
          });
        }
      }
      return newCounter;
    });
  };

  const handlePrevious = () => {
    setCounter((prev) => {
      const newCounter = Math.max(prev - 1, 1);

      // Scroll logic for mobile view in helpMode, only if counter was above 4
      if (helpMode && prev > 3) {
        const remInPixels = parseFloat(
          getComputedStyle(document.documentElement).fontSize
        );
        window.scrollBy({
          top: -30 * remInPixels, // Scroll upwards by 30rem in pixels
          behavior: "smooth",
        });
      }

      return newCounter;
    });
  };
  const tooltipContent = {
    1: (
      <Typography variant="body2">
        You can choose a single restaurant of your choice
      </Typography>
    ),
    2: (
      <Typography variant="body2">
        You can move from week to week with the arrows (it shows a date, which
        is the monday of every week. By clicking on the arrow you move one week
        per click)
      </Typography>
    ),

    3: (
      <Typography variant="body2">
        These cards show the predicted/expected sales for that day and how much
        it deviates from what you would normally expect on this weekday at this
        time of the year. This tells the manager if he/she should plan more or
        less staff than what they usually plan
      </Typography>
    ),
    4: (
      <Typography variant="body2">
        This graph shows the predicted and actual sales for the current week,
        giving the manager an intuitive understanding of (a) how the week will
        likely develop and (b) how much the predictions are off compared to the
        actual sales
      </Typography>
    ),
    5: (
      <Typography variant="body2">
        This graph shows the predicted and actual sales for the current week,
        giving the manager an intuitive understanding of (a) how the week will
        likely develop and (b) how much the predictions are off compared to the
        actual sales
      </Typography>
    ),
  };
  const handleChange = (event) => {
    // const restro = event.target.value;
    // // console.log(restro);
    // setRestaurantNames(restro);
    // // getPredictionData(barDate, restro);
    const {
      target: { value },
    } = event;
    setRestaurantNames(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleDateChange = (event) => {
    setBarDate(event.target.value);
  };
  const handleRightArrow = () => {
    handleNextWeek();
  };

  const handleLeftArrow = () => {
    handlePreviousWeek();
    // getSalesData();
  };

  const handleAdjustPrediction = () => {
    setIsPredDialogOpen(true);
  };

  const handleClosePredictionDialog = () => {
    setIsPredDialogOpen(false);
  };

  const isClosed = (data) => {
    return typeof data === "string" && data.includes("closed");
  };

  // const isSunday = () =>{
  //   if(company==="Fisketorget") {

  //   }
  // }

  const handlePreviousWeek = () => {
    const newDate = new Date(barDate);
    newDate.setDate(newDate.getDate() - 7);
    // const newStartDate = newDate.toISOString().split("T")[0];
    setBarDate(newDate.toISOString().split("T")[0]);

    // getPredictionData();
    // getHolidayData();
  };

  const handleNextWeek = () => {
    const newDate = new Date(barDate);
    newDate.setDate(newDate.getDate() + 7);
    setBarDate(newDate.toISOString().split("T")[0]);
    // getPredictionData();
    // getHolidayData();
  };

  const combineSalesAndPredictionData = (salesData, predictionData) => {
    const combinedData = [salesData, predictionData].filter(Boolean); // Filter out any falsy values

    return combinedData;
  };

  const getHourlyData = useCallback(async () => {
    const restaurant = restaurantNames;
    const actual_date = filterDate;

    if (!restaurant || !actual_date) {
      return;
    }
    setLoading(true);

    const startDate = new Date(actual_date);
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const endDate = new Date(actual_date);
    endDate.setDate(endDate.getDate());
    const formattedEndDate = endDate.toISOString().split("T")[0];

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    const constructUrl = (base) =>
      `${base}?restaurant=${restaurant}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&company=${company}`;

    const hourlySalesUrl = constructUrl(
      `${prefix}/api/salesdata_hourly/sales_data_hourly/`
      // "&start_hour=9&end_hour=5"
    );
    const hourlyPredictionUrl = constructUrl(
      `${prefix}/api/predictions_by_hour/hourly_predictions/`
      // "&start_hour=9&end_hour=5"
    );

    try {
      // Run fetch operations in parallel
      const [salesResponse, predictionResponse] = await Promise.all([
        fetch(hourlySalesUrl, { headers }),
        fetch(hourlyPredictionUrl, { headers }),
      ]);

      if (!salesResponse.ok) {
        throw new Error(`Sales data fetch error: ${salesResponse.statusText}`);
      }
      if (!predictionResponse.ok) {
        throw new Error(
          `Prediction data fetch error: ${predictionResponse.statusText}`
        );
      }

      const hourlySalesData = await salesResponse.json();
      const hourlyPredictionData = await predictionResponse.json();

      const transformDateFormat = (isoString) => {
        return isoString.replace("T", " ").slice(0, -3);
      };

      const salesProcessedData = !hourlySalesData.error
        ? {
            id: "Total Hourly Sales",
            data: hourlySalesData.results.map((entry) => ({
              x: transformDateFormat(entry.date), // Transform the date format
              y: parseFloat(entry.total_net),
            })),
          }
        : null;

      const predictionProcessedData = !hourlyPredictionData.error
        ? {
            id: "Predictions",
            data: hourlyPredictionData.results.map((entry) => ({
              x: transformDateFormat(entry.date), // Transform the date format
              y: parseFloat(entry.total_gross),
            })),
          }
        : null;

      // Combine data using the previously defined function
      const combinedData = combineSalesAndPredictionData(
        salesProcessedData,
        predictionProcessedData
      );

      setCombinedHourlyData(combinedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [restaurantNames, filterDate]);

  const fetchWeatherData = useCallback(async () => {
    // Ensure restaurantNames is an array
    const restaurantArray = Array.isArray(restaurantNames)
      ? restaurantNames
      : [restaurantNames];

    // Determine the city based on the restaurant name (adjust accordingly)
    const restaurantCity = (restroName) => {
      // Find the matching restaurant object from the restaurants array
      const restaurant = defaultRestaurants.find(
        (restaurant) => restaurant?.name === restroName
      );
      // Return the city of the matching restaurant object
      return restaurant ? restaurant.city : null;
    };

    // Assuming restaurantNames is an array and we're using the first restaurant name for the city
    const city = restaurantCity(restaurantArray[0]);

    if (!city) {
      console.error("City not found for restaurant:", restaurantArray[0]);
      return;
    }

    try {
      const weather_response = await fetch(
        `${prefix}/api/weather/fetch_weather/?city=${city}&start_date=${barDate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (weather_response.ok) {
        const weather_data = await weather_response.json();
        setWeatherData(weather_data.results);
      } else {
        console.error(
          "Error fetching weather data:",
          weather_response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching weather data:", error);
    }
  }, [barDate, restaurantNames, defaultRestaurants]);

  const getPredictionData = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const restaurant = restaurantNames;

    if (!restaurant) {
      return;
    }
    const newStartDate = new Date(week[0]);
    const newEndDate = new Date(week[week.length - 1]);

    let urlP = `${prefix}/api/predictions/daily_predictions/?start_date=${
      newStartDate.toISOString().split("T")[0]
    }&end_date=${newEndDate.toISOString().split("T")[0]}`;

    if (restaurant.length > 0) {
      urlP += `&restaurant=${restaurant}`;
    }
    if (company) {
      urlP += `&company=${company}`;
    }
    setCardLoad(true);
    fetch(urlP, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        let tempWeeklyData = {};
        data.results?.forEach((item) => {
          tempWeeklyData[item.date] = item.total_gross;
        });

        // Set the specific and average prediction values in the state
        setPredictionData(tempWeeklyData);
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
      })
      .finally(() => {
        setCardLoad(false);
      });
  }, [restaurantNames, week]);

  //API fetch for daily salesdata
  const fetchData = async (url) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const response = await fetch(url, { headers: headers });
    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }
    return response.json();
  };

  const processSalesData = (data) => {
    return data.results?.reduce((result, entry) => {
      const { gastronomic_day, total_net } = entry;
      return {
        ...result,
        total_sales: {
          id: "Total Sales",
          data: [
            ...(result["total_sales"]?.data || []),
            {
              x: new Date(gastronomic_day),
              y: Math.ceil(parseFloat(total_net) / 500) * 500,
            },
          ],
        },
      };
    }, {});
  };

  const processPredictionData = (data, label, id) => {
    return data.results?.reduce((result, entry) => {
      const { date, total_gross, comment } = entry;
      return {
        ...result,
        [id]: {
          id: label,
          data: [
            ...(result[id]?.data || []),
            {
              x: new Date(date),
              y: Math.ceil(parseFloat(total_gross) / 500) * 500,
              comment: comment,
            },
          ],
        },
      };
    }, {});
  };

  const getSalesData = useCallback(async () => {
    const newStartDate = new Date(week[0]);
    const newEndDate = new Date(week[week.length - 1]);
    const formattedStartDate = newStartDate
      ? new Date(newStartDate).toISOString().split("T")[0]
      : null;
    const formattedEndDate = newEndDate
      ? new Date(newEndDate).toISOString().split("T")[0]
      : null;

    try {
      let url = `${prefix}/api/salesdata/sales_data/`;
      let urlU = `${prefix}/api/userpredictions/user_predictions`;
      let urlP = `${prefix}/api/predictions/daily_predictions/`;
      if (restaurantNames) {
        url += `?restaurant=${restaurantNames}`;
        urlP += `?restaurant=${restaurantNames}`;
        urlU += `?restaurant=${restaurantNames}`;
      } else {
        return;
      }
      if (formattedStartDate && formattedEndDate) {
        url += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
        urlU += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
        urlP += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
      }
      if (company) {
        url += `&company=${company}`;
        urlU += `&company=${company}`;
        urlP += `&company=${company}`;
      }
      setSalesLoad(true);
      const salesData = await fetchData(url);
      const predictionData = await fetchData(urlP);

      const userPredictionData = await fetchData(urlU);

      const salesProcessedData = !salesData.error
        ? processSalesData(salesData)
        : null;
      const predictionProcessedData = !predictionData.error
        ? processPredictionData(predictionData, "Prediction", "prediction")
        : null;

      const userPredictionProcessedData = !userPredictionData.error
        ? processPredictionData(
            userPredictionData,
            "User Prediction",
            "user_prediction"
          )
        : null;

      const userPredictionMap = {};
      if (userPredictionProcessedData) {
        userPredictionProcessedData.user_prediction?.data.forEach((entry) => {
          userPredictionMap[entry.x.toISOString()] = entry;
        });
      }
      // Merge user predictions with predictions, filling in the gaps
      const combinedUserPredictionData = predictionProcessedData
        ? predictionProcessedData.prediction.data.map((item) => {
            return userPredictionMap[item.x.toISOString()] || item;
          })
        : [];

      const combinedData = {
        ...(predictionProcessedData || {}),
        ...(combinedUserPredictionData.length > 0
          ? {
              user_prediction: {
                id: "User Prediction",
                data: combinedUserPredictionData,
              },
            }
          : {}),
        ...(salesProcessedData || {}),
      };
      setSalesLoad(false);
      setSalesPredData(combinedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setMessage("Error while fetching data");
    } finally {
    }
  }, [restaurantNames, week]);

  //API fetch for holiday parameters
  const getHolidayData = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const restaurant = restaurantNames;

    if (!restaurant) {
      return;
    }
    const newStartDate = new Date(week[0]);
    const newEndDate = new Date(week[week.length - 1]);

    let url = `${prefix}/api/holidayparameters/holiday_params/?start_date=${
      newStartDate.toISOString().split("T")[0]
    }&end_date=${
      newEndDate.toISOString().split("T")[0]
    }&restaurant=${JSON.stringify(restaurant)}`;
    if (company) {
      url += `&company=${company}`;
    }

    fetch(url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const filterOut = [
          // "sunshine_amount",
          // "monthly",
          "weekly",
        ];
        const filteredData = data.results?.filter(
          (result) => !filterOut.includes(result.name)
        );

        // Initialize an object to hold the weekly data
        let tempWeeklyData = {};

        // Loop through the filtered data
        filteredData.forEach((item) => {
          const date = item.date;

          const dateObject = new Date(item.date);

          const isSunday = dateObject.getDay() === 0;

          // If the item name is "closed", set the value for the date to "closed"
          if (item.name === "closed") {
            tempWeeklyData[date] = "closed";
          } else if (company === "Fisketorget" && isSunday) {
            tempWeeklyData[date] = "closed";
          }
          // Otherwise, sum the effect values as before
          else {
            if (!tempWeeklyData.hasOwnProperty(date)) {
              tempWeeklyData[date] = 0;
            }
            tempWeeklyData[date] += parseInt(item.effect);
          }
        });

        // Set the weekly data in the state
        setHolidayData(tempWeeklyData);
      })
      .catch((error) => console.error("Error fetching holiday data:", error));
  }, [restaurantNames, week]);

  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);

  useEffect(() => {
    fetchWeatherData(); // Existing fetch logic
  }, [fetchWeatherData]); // Re-run when barDate or fetchRestaurants changes

  useEffect(() => {
    const generateWeek = (reference = barDate) => {
      const today = reference ? new Date(reference) : new Date();
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - today.getDay() + 1); // Find Monday of the current week
      const weekArray = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        weekArray.push(date);
      }
      const endOfWeek = new Date(startDate);
      endOfWeek.setDate(startDate.getDate() + 6); // Find Sunday of the current week
      setWeek(weekArray);
    };
    generateWeek();
  }, [barDate]);

  useEffect(() => {
    // getHourlyData();
    getPredictionData();
    getHolidayData();
  }, [getPredictionData, getHolidayData]);

  useEffect(() => {
    getSalesData();
  }, [getSalesData]);
  useEffect(() => {
    getHourlyData();
  }, [getHourlyData]);

  const getWeekdayName = (dateString) => {
    const date = new Date(dateString);
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return weekdays[date.getDay()];
  };

  const resetUserPrediction = async () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };
    let delete_start_date;
    let delete_end_date;
    const isSingleDateEmpty = !singlePredictionDate;
    if (isSingleDateEmpty) {
      setAlertOpen(true);
      setSeverity("error");
      setMessage("You need to select a date");
      return;
    }
    delete_start_date = singlePredictionDate;
    delete_end_date = singlePredictionDate;
    fetch(
      `${prefix}/api/userpredictions/reset_user_predictions/?start_date=${delete_start_date}&end_date=${delete_end_date}&restaurant=${restaurantNames}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setAlertOpen(true);
          setSeverity("success");
          setMessage(`${data.success}`);
        } else if (data.error) {
          setAlertOpen(true);
          setSeverity("error");
          setMessage(`${data.error}`);
        }
      })
      .catch((err) => {
        setAlertOpen(true);
        setSeverity("error");
        setMessage("Server Error");
      });
  };

  const handleAdjustClick = () => {
    const isSingleDateEmpty = !singlePredictionDate;

    const isSinglePredictionTotalGrossEmpty = !singlePredictionTotalGross;
    if (isSingleDateEmpty) {
      setAlertOpen(true);
      setSeverity("error");
      setMessage("You need to enter a date");
      return;
    }
    if (isSinglePredictionTotalGrossEmpty) {
      setAlertOpen(true);
      setSeverity("error");
      setMessage("You need to enter a value");
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };

    const predictionDateObj = new Date(singlePredictionDate);
    predictionDateObj.setHours(0, 0, 0, 0);
    const predictionDate = `${predictionDateObj.getFullYear()}-${String(
      predictionDateObj.getMonth() + 1
    ).padStart(2, "0")}-${String(predictionDateObj.getDate()).padStart(
      2,
      "0"
    )}`;
    // console.log(predictionDate, "entry");

    // Check the structure of a single entry
    if (salesPredData.prediction.data.length > 0) {
    }

    // Find the prediction entry for the specific date
    const predictionEntry = salesPredData.prediction.data.find((entry) => {
      return entry.x.getTime() === predictionDateObj.getTime();
    });
    // console.log(predictionEntry, "entry1");
    // console.log(salesPredData, "entry2");

    // Calculate the adjusted prediction
    const adjustedPrediction = predictionEntry
      ? predictionEntry.y * (1 + parseFloat(singlePredictionTotalGross) / 100)
      : parseFloat(singlePredictionTotalGross); // Use the provided value as default if no prediction

    requestOptions.body = JSON.stringify({
      date: predictionDate,
      total_gross: Math.round(adjustedPrediction),
      restaurant: Array.isArray(restaurantNames)
        ? restaurantNames[0]
        : restaurantNames,
      comment: comment,
      company: company,
    });

    // Submit adjusted prediction
    fetch(`${prefix}/api/userpredictions/`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(() => {
        getSalesData();
      })
      .catch((error) => {
        console.error(
          "Error submitting user prediction for single date:",
          error
        );
      });
    // Fetch prediction for specific date
    // const predictionDate = new Date(singlePredictionDate)
    //   .toISOString()
    //   .split("T")[0];
    // const url = `${prefix}/api/predictions/daily_predictions/?${
    //   restaurantNames ? `restaurant=${restaurantNames}&` : ""
    // }date=${predictionDate}`;
    // const second_header = {
    //   "Content-Type": "application/json",
    //   Authorization: `Token ${token}`,
    // };
    // fetch(url, { headers: second_header })
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error(`HTTP error ${response.status}`);
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     const predictionEntry = data.results.find(
    //       (entry) =>
    //         new Date(entry.date).getTime() ===
    //         new Date(predictionDate).getTime()
    //     );

    //     if (!predictionEntry) {
    //       console.error("No prediction found for this date");
    //       return;
    //     }

    //     const adjustedPrediction =
    //       predictionEntry.total_gross *
    //       (1 + parseFloat(singlePredictionTotalGross) / 100);

    //     requestOptions.headers = {
    //       "Content-Type": "application/json",
    //       Authorization: `Token ${token}`,
    //     };
    //     requestOptions.body = JSON.stringify({
    //       date: predictionDate,
    //       total_gross: Math.round(adjustedPrediction),
    //       restaurant: restaurantNames,
    //       comment: comment,
    //       company: company,
    //     });
    //     console.log(adjustedPrediction, "selected");

    //     // Submit adjusted prediction
    //     fetch(`${prefix}/api/userpredictions/`, requestOptions)
    //       .then((response) => response.json())
    //       .then(() => {
    //         getSalesData();
    //         // navigate(0);
    //       });
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching prediction data for single date:", error);
    //   });
  };

  // const sunshineIcon = () => {
  //   const sunshine = weatherData[0]?.total_sunshine;
  //   if (sunshine > 400) {
  //     return <FaSun />;
  //   } else if (sunshine > 200) {
  //     return <FaCloudSun />;
  //   } else {
  //     return <FaSnowflake />;
  //   }
  // };

  // const temperatureIcon = () => {
  //   const temperature = weatherData[0]?.max_temperature;
  //   if (temperature > 12) {
  //     return <FaSun color="gold" />;
  //   } else {
  //     return <FaThermometerHalf color="blue" />;
  //   }
  // };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        // border: "solid 1px red",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 1300,
          alignItems: "center",
          // border: "solid 1px blue",
          boxSizing: "border-box",
          height: { lg: "100vh", xs: "200vh" },
        }}
      >
        <Box
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            // border: "solid 1px green",
            height: "100%",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: { lg: 1, xs: 1 },
              // border: "solid 1px red",
              justifyContent: { lg: "space-between" },
              height: {
                lg: "10%",
                //   xs: collapseOpen ? "25%" : "15%"
              },
              alignItems: "center",
              mt: { lg: 2, xs: 1 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { lg: "row", xs: "column" },
                gap: { lg: 1, xs: 1 },
                alignItems: { lg: "center", xs: "center" },
                justifyContent: { xs: "center", lg: "flex-start" },
                width: "100%",
              }}
            >
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 1}
              >
                <FormControl
                  sx={{
                    width: 200,
                    m: 0,
                    ...(counter === 1 && helpMode ? getHighlightedStyle() : {}),
                  }}
                >
                  <InputLabel id="restaurant-label">
                    Select Restaurant
                  </InputLabel>
                  <Select
                    labelId="restaurant-label"
                    id="restaurant"
                    value={restaurantNames}
                    onChange={handleChange}
                    label="Select Restaurant"
                    renderValue={(selected) =>
                      selected
                        .map((name) => getRestaurantNameById(name))
                        .join(", ")
                    }
                    sx={
                      {
                        // "& .MuiSelect-select": {
                        //   // height: '3rem',
                        //   padding: "0",
                        //   textIndent: "0.25em",
                        //   textAlign: "center",
                        // },
                        // // mb: "1rem",
                        // mx: ".5rem",
                        // height: "3rem",
                      }
                    }
                  >
                    {/* Render the options dynamically */}

                    {/* {restaurantNames?.length > 0 && (
                    <MenuItem
                      key={primaryRestaurant}
                      value={restaurantNames[0]}
                    >
                      {restaurantNames[0]}
                    </MenuItem>
                  )} */}
                    {defaultRestaurants.map((restaurant) => (
                      <MenuItem key={restaurant.id} value={restaurant.name}>
                        {/* <Checkbox
                        checked={restaurantNames.includes(restaurant.name)}
                      /> */}
                        <ListItemText primary={restaurant.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 2}
              >
                <FormControl
                  sx={{
                    flexDirection: "row",
                    gap: 1,
                    justifyContent: "center",
                    ...(counter === 2 && helpMode ? getHighlightedStyle() : {}),
                  }}
                >
                  <FormControl
                    sx={{ flexDirection: "row" }}
                    variant="contained"
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#FFBD00",
                        "&:hover": {
                          backgroundColor: "#e3a902", // You can adjust this color based on your preference
                          // Prevent MUI Button from changing the color on hover
                          boxShadow: "none",
                        },
                        "&:active": {
                          backgroundColor: "#e3a902", // Adjust this color based on your preference
                        },
                        color: "black",
                      }}
                      onClick={handleLeftArrow}
                      disabled={salesLoad}
                    >
                      <ArrowBackIcon /> {/* Left arrow icon */}
                    </Button>
                  </FormControl>

                  <FormControl sx={{ width: "15ch" }} variant="outlined">
                    <TextField
                      label="Select Date"
                      type="date"
                      id="selectDate"
                      value={barDate}
                      onChange={handleDateChange}
                      disabled
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& input": {
                          height: "3em", // Adjust this value to your desired height
                          padding: "0", // Remove padding to avoid extra space inside the input
                          fontSize: "1em",
                          textIndent: "0.25em", // Add a small margin to the left of the text inside the input field
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ flexDirection: "row" }} variant="outlined">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#FFBD00",
                        "&:hover": {
                          backgroundColor: "#e3a902", // You can adjust this color based on your preference
                          // Prevent MUI Button from changing the color on hover
                          boxShadow: "none",
                        },
                        "&:active": {
                          backgroundColor: "#e3a902", // Adjust this color based on your preference
                        },
                        color: "black",
                      }}
                      onClick={handleRightArrow}
                      disabled={salesLoad}
                    >
                      <ArrowForwardIcon /> {/* Right arrow icon */}
                    </Button>
                  </FormControl>
                </FormControl>
              </Tooltip>

              <Box
                sx={{
                  width: "150px",
                  marginBottom: { xs: "1rem", lg: 0 }, // Adds bottom margin on xs screens
                }}
              >
                <SettingsDialog
                // startDate={startDate}
                // setStartDate={setStartDate}
                // endDate={endDate}
                // // setEndDate={setEndDate}
                // range={range}
                // downloadOptions={downloadOptions}
                // dataRange={dataRange}
                // setDataRange={setDataRange}
                // company={company}
                // setCompany={setCompany}
                // email={email}
                />

                <HelpModeButton
                  isXSScreen={isXSScreen}
                  handleHelpMode={handleHelpMode}
                  helpMode={helpMode}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                width: { lg: "20%" },
              }}
            >
              <LastUpdate />
            </Box>
          </Box>
          {/* Top section ends here */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 1px red",
              justifyContent: { lg: "flex-start" },
              alignItems: { lg: "flex-start", xs: "center" },
              height: { lg: "75%", xs: "130vh" },
              mt: { lg: 0, xs: 0 },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 3}
            >
              <Box
                sx={{
                  ...(counter === 3 && helpMode ? getHighlightedStyle() : {}),
                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "40%", xs: "90%" },
                  gap: { lg: 1, xs: 1 },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 3 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "70vh" },
                  fontFamily: "'Montserrat', sans-serif",
                  paddingY: { lg: 0, xs: 1 },
                  overflowY: "auto",
                  minHeight: { xs: "70vh" },
                }}
              >
                {cardLoad ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <ChartLoading />
                  </Box>
                ) : (
                  Object.entries(predictionData).map(([date, prediction]) => {
                    // console.log(date, "ayo date");
                    const holidayDataForDate = holidayData[date];
                    return (
                      <Box
                        onClick={() => handleOpenDialog(date)}
                        key={date}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "rgba(0, 0, 0, 0.05)",
                          border: isClosed(holidayDataForDate)
                            ? "3px solid black"
                            : !holidayDataForDate
                            ? "3px solid rgba(0, 0, 0, 0.05)"
                            : parseInt(holidayDataForDate) > 7000
                            ? "3px solid #9deba1"
                            : parseInt(holidayDataForDate) < -7000
                            ? "3px solid #ed4c4c"
                            : "3px solid rgba(0, 0, 0, 0.05)",
                          margin: ".1rem",
                          width: {
                            xs: "95%",
                            lg: "90%",
                          },
                          borderRadius: "1rem",
                          minHeight: { lg: "20%" },
                          justifyContent: "flex-start",
                          fontFamily: "Montserrat",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: 700,
                            textAlign: "center",
                            mt: "1rem",
                            marginBottom: "0.5rem",
                            fontSize: "1rem",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {getWeekdayName(date)},&nbsp;
                          {new Date(date).toLocaleString("en-GB", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            fontFamily: "Montserrat",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                              color: isClosed(holidayDataForDate)
                                ? "black"
                                : parseInt(holidayDataForDate) > 7000
                                ? "#7bba7e"
                                : parseInt(holidayDataForDate) < -7000
                                ? "#ed4c4c"
                                : "black",
                            }}
                          >
                            {isClosed(holidayDataForDate)
                              ? "Restaurant Closed"
                              : parseInt(holidayDataForDate) > 7000
                              ? "Consider adding Staff"
                              : parseInt(holidayDataForDate) < -7000
                              ? "Consider reducing Staff"
                              : ""}
                          </Typography>
                        </Box>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          sx={{ gap: 1 }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 300,
                              flexGrow: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              paddingLeft: "1rem",
                              fontSize: "1rem",
                              fontFamily: "Montserrat",
                            }}
                          >
                            {isClosed(holidayDataForDate)
                              ? ""
                              : "Expected Sales:"}
                          </Typography>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 400,
                              fontSize: "1rem",
                              paddingRight: "1rem",
                              fontFamily: "Montserrat",
                            }}
                          >
                            {isClosed(holidayDataForDate)
                              ? ""
                              : prediction
                              ? formatNumber(Math.ceil(prediction / 500) * 500)
                              : "No Prediction"}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          sx={{ gap: 1 }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 500,
                              flexGrow: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              paddingLeft: "1rem",
                              marginBottom: "0.5rem",
                              fontSize: "1rem",
                              fontFamily: "Montserrat",
                            }}
                          >
                            {isClosed(holidayDataForDate)
                              ? ""
                              : "Deviation from the norm:"}
                          </Typography>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 400,
                              fontSize: "1rem",
                              paddingRight: "1rem",
                              marginBottom: "0.6rem",
                              fontFamily: "Montserrat",
                            }}
                          >
                            {isClosed(holidayDataForDate)
                              ? ""
                              : holidayDataForDate
                              ? new Intl.NumberFormat("nb-NO", {
                                  style: "currency",
                                  currency: "NOK",
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                }).format(holidayDataForDate)
                              : "No Data"}
                          </Typography>
                        </Grid>
                      </Box>
                    );
                  })
                )}
              </Box>
            </Tooltip>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { lg: "60%", xs: "90%" },
                gap: { lg: 2, xs: 3 },
                justifyContent: "flex-start",
                alignItems: "center",
                // borderRadius: "1rem",
                // boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                // background: "#FFF",
                height: { lg: "100%", xs: "100vh" },
                fontFamily: "'Montserrat', sans-serif",
                // minHeight: { xs: "70vh" },
                // border: "solid 1px red",
              }}
            >
              {/* <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 4}
                placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
              >
                <Box
                  sx={{
                    ...(counter === 4 && helpMode ? getHighlightedStyle() : {}),
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column" },
                    width: { lg: "100%", xs: "100%" },
                    gap: { lg: 2, xs: 3 },
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "1rem",
                    // boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    // background: "#FFF",
                    height: { lg: "30%", xs: "20rem" },
                    fontFamily: "'Montserrat', sans-serif",
                    border: "solid 1px red",
                  }}
                > */}
              {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: { lg: "30%", xs: "100%" },
                      gap: { lg: 2, xs: 3 },
                      justifyContent: "flex-start",
                      alignItems: "center",
                      borderRadius: "1rem",
                      boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                      background: "#FFF",
                      height: { lg: "100%", xs: "20rem" },
                      fontFamily: "'Montserrat', sans-serif",
                      // padding: "1rem", // Added padding for inner content
                    }}
                  >
                    <Typography
                      // variant="h6"
                      sx={{
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      Weather
                    </Typography>
                    {weatherData ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          width: "90%",
                          height: "90%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {temperatureIcon()}
                          <Typography variant="Max temperature">
                            Temperature: {weatherData[0]?.max_temperature}{" "}
                            {weatherData[0] ? "°C" : ""}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {sunshineIcon()} */}

              {/* <Typography variant="Sunshine">
                            Sunshine */}
              {/* {weatherData[0].total_sunshine}  */}
              {/* </Typography> */}
              {/* </Box>
                      </Box>
                    ) : (
                      <Typography variant="subtitle1">
                        Loading weather data...
                      </Typography> // This message will display while the data is being fetched
                    )}
                  </Box> */}

              {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: { lg: "30%", xs: "100%" },
                      gap: { lg: 2, xs: 3 },
                      justifyContent: "flex-start",
                      alignItems: "center",
                      borderRadius: "1rem",
                      boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                      background: "#FFF",
                      height: { lg: "100%", xs: "20rem" },
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    Weather
                  </Box> */}
              {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: { lg: "30%", xs: "100%" },
                      gap: { lg: 2, xs: 3 },
                      justifyContent: "flex-start",
                      alignItems: "center",
                      borderRadius: "1rem",
                      boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                      background: "#FFF",
                      height: { lg: "100%", xs: "20rem" },
                      fontFamily: "'Montserrat', sans-serif",
                    }}
                  >
                    Weather
                  </Box> */}
              {/* </Box>
              </Tooltip> */}
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 4}
                placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
              >
                <Box
                  sx={{
                    ...(counter === 5 && helpMode ? getHighlightedStyle() : {}),

                    display: "flex",
                    flexDirection: "column",
                    width: { lg: "100%", xs: "100%" },
                    gap: { lg: 2, xs: 3 },
                    justifyContent: "flex-start",
                    alignItems: "center",
                    borderRadius: "1rem",
                    boxShadow:
                      counter === 4 && helpMode
                        ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                        : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    background: "#FFF",
                    height: { lg: "100%", xs: "45rem" },
                    fontFamily: "'Montserrat', sans-serif",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",

                      // border: "solid red 1px",
                    }}
                  >
                    <Typography
                      // variant="h6"
                      sx={{
                        my: "0.5rem",
                        fontWeight: 400,
                        fontFamily: "'Montserrat', sans-serif",
                        flexGrow: "1",
                        ml: "20%",
                        mt: 2,
                        fontSize: "18px",
                      }}
                    >
                      Sales Prediction
                    </Typography>
                    <Button
                      onClick={handleAdjustPrediction}
                      sx={{
                        color: "#515151",
                        width: "30%",
                      }}
                    >
                      Adjust Prediction
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      height: { lg: "80%", xs: "80%" },
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // border: "solid 1px red",
                    }}
                  >
                    {salesLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                          justifyContent: "center",
                          alignItems: "center",
                          // border: "solid 1px red",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <LineChart
                        data={salesPredData}
                        isNotPercentage={true}
                        range={"day"}
                        isXSScreen={isXSScreen}
                        isCurrency={true}
                        isSalesPrediction={true}
                      />
                    )}
                  </Box>
                </Box>
              </Tooltip>
            </Box>
          </Box>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            PaperProps={{
              sx: {
                width: { lg: "50vw", xs: "95vw" },
                height: { lg: "70vh", xs: "80vh" },
                maxWidth: "none", // This will help override default maxWidth values
                maxHeight: "none", // This will help override default maxHeight values
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "space-between",
                width: "100%",
              }}
            >
              <DialogTitle sx={{ flexGrow: 1 }}>
                Detailed Information
              </DialogTitle>

              <CloseIcon
                onClick={handleCloseDialog}
                sx={{
                  mr: "1rem",
                  cursor: "pointer", // To indicate that the icon is clickable
                  borderRadius: "50%", // To make the background change rounded, like an icon
                  transition: "background-color 0.3s", // Smooth transition for the hover effect
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Slight gray on hover
                  },
                }}
              />
            </Box>
            <DialogContent>
              <DialogContentText>
                {/* Hourly Sales Prediction Graph */}
                <Typography
                  variant="h5"
                  sx={{
                    margin: "1rem",
                    fontWeight: 500,
                    textAlign: "center",
                    fontSize: { lg: "22px", xs: "17px" },
                  }}
                >
                  Sales Through The Day
                </Typography>
                <Grid sx={{ height: "30rem", position: "relative" }}>
                  {Loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        height: "100%",
                        // mt: "2rem",
                        justifyContent: "center",
                        alignItems: "center",
                        // border: "solid red 1px",
                      }}
                    >
                      <ChartLoading />
                    </Box>
                  ) : (
                    filterDate && (
                      <HourlyLineChart
                        data={combinedHourlyData}
                        isDailyData={true}
                        startDate={filterDate}
                        endDate={filterDate}
                        isNotPercentage={true}
                        isXSScreen={isXSScreen}
                      />
                    )
                  )}
                </Grid>

                {/* Factors built into the prediction */}
                <Typography
                  variant="h5"
                  sx={{
                    margin: "1rem",
                    fontWeight: 500,
                    fontSize: { lg: "22px", xs: "17px" },
                  }}
                >
                  Factors Built into the Prediction
                </Typography>
                <Grid sx={{ height: "16rem" }}>
                  {selectedDate && (
                    <HolidayTable
                      restaurant={restaurantNames}
                      startDate={selectedDate}
                      endDate={selectedDate}
                      isXSScreen={true}
                      isRecommended={true}
                    />
                  )}
                </Grid>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Box>
        <AdjustPredictionDialog
          open={isPredDialogOpen}
          onClose={handleClosePredictionDialog}
          restaurantNames={restaurantNames}
          // restaurantName={restaurantName}
          singlePredictionDate={singlePredictionDate}
          setSinglePredictionDate={setSinglePredictionDate}
          singlePredictionTotalGross={singlePredictionTotalGross}
          setSinglePredictionTotalGross={setSinglePredictionTotalGross}
          comment={comment}
          setComment={setComment}
          handleAdjustClick={handleAdjustClick}
          resetUserPrediction={resetUserPrediction}
          alertOpen={alertOpen}
          severity={severity}
          message={message}
          handleClose={handleClose}
        />
      </Box>
      <Snackbar
        open={open}
        onClose={handleClose}
        // severity={severity}
        message={message}
      />
      {helpMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            width: { lg: "180px", xs: "280px" },
            position: "fixed", // Make it absolute to position at the bottom
            bottom: 20,
            right: 20,
            padding: "10px",
            // border:"solid 1px red"
          }}
        >
          <NavigationButtons
            isXSScreen={isXSScreen}
            setHelpMode={setHelpMode}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            counter={counter}
            maxSteps={maxSteps}
          />
        </Box>
      )}
    </Box>
  );
};
const AdjustPredictionDialog = ({
  open,
  onClose,
  restaurantNames,
  singlePredictionDate,
  setSinglePredictionDate,
  singlePredictionTotalGross,
  setSinglePredictionTotalGross,
  comment,
  setComment,
  handleAdjustClick,
  resetUserPrediction,
  alertOpen,
  severity,
  message,
  handleClose,
}) => {
  const minDate = new Date();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: { lg: "30%", xs: "80%" },
          height: { lg: "45%", xs: "50%" },
          maxWidth: "none",
          maxHeight: "none",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <DialogTitle sx={{ flexGrow: 1 }}>Adjust The Predictions</DialogTitle>
          <CloseIcon
            onClick={onClose}
            sx={{
              mr: "1rem",
              cursor: "pointer",
              borderRadius: "50%",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          />
        </Box>
        <DialogContent>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0)",
              boxShadow: "none",
              justifyContent: "center",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "column" },
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  marginBottom: "1rem",
                  justifyContent: "center",
                  // border:"solid red 1px"
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    ml: {
                      xs: 0,
                      lg: 0,
                    },
                    mb: {
                      xs: 1,
                      lg: 0,
                    },
                    fontFamily: "Montserrat",
                  }}
                >
                  Adjust Single Date
                </Typography>
                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "column" },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControl
                    sx={{
                      m: 1,
                      width: { xs: "20ch", sm: "15ch", lg: "30ch" },
                    }}
                    variant="outlined"
                  >
                    <TextField
                      label="Date"
                      type="date"
                      id="UserPredictionDate"
                      value={singlePredictionDate}
                      onChange={(event) =>
                        setSinglePredictionDate(event.target.value)
                      }
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: minDate,
                      }}
                      sx={{
                        "& input": {
                          height: "3em",
                          padding: "0",
                          fontSize: "1em",
                          textIndent: "0.25em",
                        },
                        background: "#F8F8F8",
                      }}
                    />
                  </FormControl>

                  <FormControl
                    sx={{ m: 1, width: { xs: "20ch", sm: "15ch", lg: "30ch" } }}
                    variant="outlined"
                  >
                    <TextField
                      label="Total Gross"
                      type="number"
                      id="UserPredictionTotalGross"
                      value={singlePredictionTotalGross}
                      onChange={(event) =>
                        setSinglePredictionTotalGross(event.target.value)
                      }
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="flex-end">%</InputAdornment>
                        ),
                      }}
                      sx={{
                        "& input": {
                          height: "3em",
                          padding: "0",
                          fontSize: "1em",
                          textIndent: "0.5em",
                        },
                        background: "#F8F8F8",
                      }}
                    />
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: { xs: "20ch", sm: "15ch", lg: "30ch" } }}
                    variant="outlined"
                  >
                    <TextField
                      label="Comments(optional)"
                      type="text"
                      value={comment}
                      onChange={(event) => setComment(event.target.value)}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& input": {
                          height: "3em",
                          padding: "0",
                          fontSize: "1em",
                          textIndent: "0.5em",
                        },
                        background: "#F8F8F8",
                      }}
                    />
                  </FormControl>
                </FormControl>
                <FormControl
                  sx={{
                    m: 1,
                    width: "15ch",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    ml: {
                      lg: 0,
                      xs: 0,
                    },
                    gap: 1,
                    // border: "solid red 1px",
                  }}
                  variant="outlined"
                >
                  <Button
                    variant="contained"
                    onClick={handleAdjustClick}
                    sx={{
                      backgroundColor: "#FFBD00",
                      width: "15ch",
                      color: "#645E29",
                      "&:hover": {
                        backgroundColor: "#e3a902",
                        boxShadow: "none",
                      },
                      "&:active": {
                        backgroundColor: "#e3a902",
                      },
                    }}
                  >
                    Adjust
                  </Button>
                  <Button
                    variant="contained"
                    onClick={resetUserPrediction}
                    sx={{
                      backgroundColor: "#FFBD00",
                      width: "15ch",
                      color: "#645E29",
                      "&:hover": {
                        backgroundColor: "#e3a902",
                        boxShadow: "none",
                      },
                      "&:active": {
                        backgroundColor: "#e3a902",
                      },
                    }}
                  >
                    Reset
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <SnackbarAlert
            open={alertOpen}
            onClose={handleClose}
            severity={severity}
            message={message}
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default NewRecommendations;
