// DialogComponent.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const InputDialog = ({ open, onClose, title, content, actions }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "auto", // Default to auto if size.width is not provided
          height: "auto", // Default to auto if size.height is not provided
          maxWidth: "none",
          minWidth: "20%", // This will ensure it can take the full width if needed
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default InputDialog;
