import React from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import ChartLoading from "../../components/Loading/ChartLoading";

const ComparisonTable = ({ allSums, percentageDifferences, profitLoad }) => {
  // Loading logic
  if (profitLoad) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {/* Adjust height as needed */}
        <ChartLoading />
      </Box>
    );
  }

  // Functions used in the component
  //   const formatOption = (option) => {
  //     return option.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
  //   };
  const tooltipTexts = {
    total_net:
      "The sales without MVA for the select restaurants and date period",
    gross_profit: "Gross Profit I is total sales minus cost of goods",
    operating_profit:
      "Gross Profit II is Gross Profit I minus employee cost and third party delivery cost",
    net_profit: "The actual profit after all expenses have been deducted",
    cost: "Cost of Goods is the amount of goods that we sold during the period",
    employee_cost:
      "Employee cost is the breakdown of employee-related expenses",
    delivery_cost:
      "Delivery Cost is the amount paid to third party delivery services",
    rent: "The amount of rent that was incurred during the period",
  };

  const formatOption = (option) => {
    const displayMapping = {
      total_net: "Sales",
      gross_profit: "Gross Profit I",
      operating_profit: "Gross Profit II",
      net_profit: "Net Profit",
      cost: "Cost of Goods",
      employee_cost: "Employee Cost",
      delivery_cost: "Delivery Cost",
      rent: "Rent",
    };

    const formattedOption =
      displayMapping[option] ||
      option
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    const tooltipText = tooltipTexts[option] || "No description available";

    return (
      <Tooltip
        title={<Typography>{tooltipText}</Typography>}
        arrow
        placement="top"
      >
        <span>{formattedOption}</span>
      </Tooltip>
    );
  };

  const formatNumber = (value) => {
    return new Intl.NumberFormat("nb-NO", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
    // + " NOK"
  };

  const getTooltipMessage = (difference) => {

    if (difference > 0) {
      return `${difference.toFixed(1)}% increase from last period`;
    } else if (difference < 0) {
      return `${Math.abs(difference).toFixed(1)}% decrease from last period`;
    } else {
      return "No change from last period";
    }
  };

  const renderDifferenceIcon = (difference) => {

    if (
      difference === 0 ||
      difference === "No Data for Current period" ||
      difference === "No Data for Corresponding period"
    ) {
      return null;
    } else if (difference > 0) {
      return <KeyboardDoubleArrowUpIcon color="success" />;
    } else {
      return <KeyboardDoubleArrowDownIcon color="error" />;
    }
  };

  // The component's rendered JSX
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        fontFamily: "'Montserrat', sans-serif",
        // border: "solid red 1px",
      }}
    >
      <Box sx={{ height: "100%", overflowY: "auto" }}>
        <Table
          stickyHeader
          sx={{
            fontFamily: "'Montserrat', sans-serif",
            // border: "solid red 1px",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "'Montserrat', sans-serif",
                  padding: "6px 8px",
                  fontSize: "0.875rem",
                  // width: "23%", // Set strict widths
                  minWidth: "13%",
                  maxWidth: "13%",
                  // whiteSpace: "nowrap",
                  overflow: "hidden",
                  // textOverflow: "ellipsis",
                  // border: "solid red 1px",
                }}
                align="center"
              >
                Category
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "'Montserrat', sans-serif",
                  padding: "6px 8px",
                  fontSize: "0.875rem",
                  // width: "23%", // Set strict widths
                  minWidth: "13%",
                  maxWidth: "13%",
                  // whiteSpace: "nowrap",
                  overflow: "hidden",
                  // textOverflow: "ellipsis",
                  // border: "solid red 1px",
                }}
                align="center"
              >
                This Year's Period
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "'Montserrat', sans-serif",
                  padding: "6px 8px",
                  fontSize: "0.875rem",
                  // width: "23%", // Set strict widths
                  minWidth: "13%",
                  maxWidth: "13%",
                  // whiteSpace: "nowrap",
                  overflow: "hidden",
                  // textOverflow: "ellipsis",
                  // border: "solid red 1px",
                }}
                align="center"
              >
                Last Year's Period
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontFamily: "'Montserrat', sans-serif",
                  padding: "6px 8px",
                  fontSize: "0.875rem",
                  // width: "23%", // Set strict widths
                  minWidth: "13%",
                  maxWidth: "13%",
                  // whiteSpace: "nowrap",
                  overflow: "hidden",
                  // textOverflow: "ellipsis",
                  // border: "solid red 1px",
                }}
                align="center"
              >
                Difference
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(allSums).map((line, index) => {
              const flatDifference =
                allSums[line].currentSum - allSums[line].correspondingSum;
              const percentageDifference = percentageDifferences[line];
              return (
                <TableRow
                  key={line}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#F5F5F5" : "#FFF", // Alternating row colors
                    fontFamily: "'Montserrat', sans-serif", // Applying font family to rows
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      // fontWeight: "bold",
                      fontFamily: "'Montserrat', sans-serif",
                      padding: { xs: "6px 8px", lg: "15px 10px " },
                      fontSize: { xs: "0.8rem", lg: "0.9rem" },
                      minWidth: { xs: "10%", lg: "25" },
                      maxWidth: { xs: "10%", lg: "25" },
                      overflow: "hidden",
                      // border: "solid red 1px",
                    }}
                  >
                    {formatOption(line)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      // fontWeight: "bold",
                      fontFamily: "'Montserrat', sans-serif",
                      padding: { xs: "6px 8px", lg: "15px 10px " },
                      fontSize: { xs: "0.8rem", lg: "0.9rem" },
                      // width: "23%", // Set strict widths
                      minWidth: "10%",
                      maxWidth: "10%",
                      // whiteSpace: "nowrap",
                      overflow: "hidden",
                      // textOverflow: "ellipsis",
                      // border: "solid red 1px",
                    }}
                  >
                    {formatNumber(allSums[line].currentSum)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      // fontWeight: "bold",
                      fontFamily: "'Montserrat', sans-serif",
                      padding: { xs: "6px 8px", lg: "15px 10px " },
                      fontSize: { xs: "0.8rem", lg: "0.9rem" },
                      // width: "23%", // Set strict widths
                      minWidth: "10%",
                      maxWidth: "10%",
                      // whiteSpace: "nowrap",
                      overflow: "hidden",
                      // textOverflow: "ellipsis",
                      // border: "solid red 1px",
                    }}
                  >
                    {formatNumber(allSums[line].correspondingSum)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      // fontWeight: "bold",
                      fontFamily: "'Montserrat', sans-serif",
                      padding: { xs: "6px 8px", lg: "15px 10px " },
                      fontSize: { xs: "0.8rem", lg: "0.9rem" },
                      // width: "23%", // Set strict widths
                      minWidth: "10%",
                      maxWidth: "10%",
                      // whiteSpace: "nowrap",
                      overflow: "hidden",
                      // textOverflow: "ellipsis",
                      // border: "solid red 1px",
                    }}
                  >
                    <Tooltip
                      title={
                        <Typography
                          sx={{ fontFamily: "'Montserrat', sans-serif" }}
                        >
                          {getTooltipMessage(percentageDifference)}
                        </Typography>
                      }
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {renderDifferenceIcon(percentageDifference)}
                        <Box
                          sx={{
                            ml: 1,
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                        >
                          {formatNumber(flatDifference)}
                        </Box>
                      </Box>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default ComparisonTable;
