import React, { useState, useEffect, useCallback } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LineChart from "./LineCharts/LineChart";
import ChartLoading from "./Loading/ChartLoading";
import TakeAwayBarChart from "./BarCharts/TakeAwayBarChart";
import Prefix from "../utils/PrefixLink";

const token = localStorage.getItem("token");
const prefix = Prefix();

const TakeAway = ({
  startDate,
  endDate,
  selectedRestaurant,
  company,
  period,
  dataRange,
  selectedThirdPartySupergroup,
  selectedPage,
}) => {
  let range = period === "today" ? "hour" : "day";
  const [combinedData, setCombinedData] = useState([]);
  const [combinedLineData, setCombinedLineData] = useState([]);

  // const [takeAwayLoad, setTakeAwayLoad] = useState(false);
  const [load, setLoad] = useState(false);
  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getLineTypeData = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    let urlP = `${prefix}/api/dining_type_predictions/type_predictions/?company=${company}&period=${range}&start_date=${startDate}&end_date=${endDate}`;
    let urlA = `${prefix}/api/salesdata_hourly/actual_take_out?company=${company}&period=${range}&start_date=${startDate}&end_date=${endDate}`;
    if (selectedRestaurant && selectedRestaurant.length > 0) {
      urlP += `&restaurants=${JSON.stringify(selectedRestaurant)}`;
      urlA += `&restaurants=${JSON.stringify(selectedRestaurant)}`;
    }
    if (
      selectedThirdPartySupergroup &&
      selectedThirdPartySupergroup !== "All"
    ) {
      urlA += `&sub_product=${selectedThirdPartySupergroup}`;
    }

    setLoad(true);

    let hasPrediction = false;
    if (selectedThirdPartySupergroup === "All") {
      hasPrediction = true;
    }

    Promise.all([
      fetch(urlP, { headers })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
          }
          return response.json();
        })
        .catch((error) => {
          console.error("Error fetching prediction data:", error);
          return { results: [] }; // Return empty results in case of an error
        }),
      fetch(urlA, { headers })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
          }
          return response.json();
        })
        .catch((error) => {
          console.error("Error fetching actual data:", error);
          return { message: [] }; // Return empty message in case of an error
        }),
    ])
      .then(([predictionData, actualData]) => {
        // Handle specific error messages
        if (predictionData.error === "No data found for the parameters") {
          predictionData.results = [];
        }
        if (actualData.error === "No data found for the parameters") {
          actualData.message = [];
        }

        if (
          predictionData.results.length > 0 ||
          actualData.message.length > 0
        ) {
          if (period === "today") {
            let combinedData = [];

            const processData = (data, key) =>
              data.reduce((acc, { period, [key]: value }) => {
                const dateKey = new Date(period).toISOString();
                if (!acc[dateKey]) {
                  acc[dateKey] = [];
                }
                acc[dateKey].push(parseFloat(value));
                return acc;
              }, {});

            const groupedPredictions = processData(
              predictionData.results,
              "take_out"
            );
            const groupedActuals = processData(
              actualData.message,
              "percentage"
            );

            const allPeriods = new Set([
              ...Object.keys(groupedPredictions),
              ...Object.keys(groupedActuals),
            ]);

            allPeriods.forEach((period) => {
              const predictionValues = groupedPredictions[period] || [];
              const actualValues = groupedActuals[period] || [];

              const predictionAvg = predictionValues.length
                ? (
                    predictionValues.reduce((acc, val) => acc + val, 0) /
                    predictionValues.length
                  ).toFixed(1)
                : 0;

              const actualAvg = actualValues.length
                ? (
                    actualValues.reduce((acc, val) => acc + val, 0) /
                    actualValues.length
                  ).toFixed(1)
                : 0;

              combinedData.push({
                date: new Date(period).toISOString().slice(0, 10),
                Prediction: hasPrediction ? predictionAvg : 0,
                ActualData: actualAvg,
              });
            });

            setCombinedData(combinedData);
          } else {
            const groupedPredictionData = predictionData.results.reduce(
              (acc, { period, take_out }) => {
                const dateKey = new Date(period).toISOString();
                if (!acc[dateKey]) {
                  acc[dateKey] = [];
                }
                acc[dateKey].push(parseFloat(take_out));
                return acc;
              },
              {}
            );

            let temp_prediction_type = Object.entries(
              groupedPredictionData
            ).reduce((result, [period, values]) => {
              const sum = values.reduce((acc, val) => acc + val, 0);
              const avg = sum / values.length || 0;

              return {
                ...result,
                take_out: {
                  id: "Prediction",
                  data: [
                    ...(result.take_out?.data || []),
                    {
                      x: new Date(period),
                      y: hasPrediction ? avg.toFixed(1) : 0,
                    },
                  ],
                },
              };
            }, {});

            const groupedActualData = actualData.message.reduce(
              (acc, { period, percentage }) => {
                const dateKey = new Date(period).toISOString();
                if (!acc[dateKey]) {
                  acc[dateKey] = [];
                }
                acc[dateKey].push(parseFloat(percentage));
                return acc;
              },
              {}
            );

            let temp_actual_type = Object.entries(groupedActualData).reduce(
              (result, [period, percentages]) => {
                const sum = percentages.reduce((acc, val) => acc + val, 0);
                const avg = sum / percentages.length || 0;
                return {
                  ...result,
                  take_out: {
                    id: "Actual Data",
                    data: [
                      ...(result.take_out?.data || []),
                      {
                        x: new Date(period),
                        y: avg.toFixed(1),
                      },
                    ],
                  },
                };
              },
              {}
            );

            if (
              Object.keys(temp_prediction_type).length === 0 &&
              Object.keys(temp_actual_type).length === 0
            ) {
              setCombinedLineData([]);
            } else {
              const finalData = [];

              if (Object.keys(temp_prediction_type).length > 0) {
                finalData.push(temp_prediction_type.take_out);
              }

              if (Object.keys(temp_actual_type).length > 0) {
                finalData.push(temp_actual_type.take_out);
              }

              setCombinedLineData(finalData);
            }
          }

          setLoad(false);
        } else {
          setCombinedData([]);
          setCombinedLineData([]);
          setLoad(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setCombinedData([]);
        setCombinedLineData([]);
        setLoad(false);
      });
  }, [
    startDate,
    endDate,
    selectedRestaurant,
    company,
    range,
    period,
    selectedThirdPartySupergroup,
  ]);


  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setCombinedData([]);
      setCombinedLineData([]);
    } else {
      const timer = setTimeout(() => {
        getLineTypeData();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [startDate, endDate, selectedPage, selectedRestaurant, getLineTypeData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        // border: "solid red 1px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "80%",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          // border: "solid red 1px",
        }}
      >
        {load ? (
          <ChartLoading />
        ) : period === "today" ? (
          <TakeAwayBarChart
            data={combinedData}
            isDetailedChart={false}
            hasDifferentColor={false}
            range={"day"}
            isXSScreen={isXSScreen}
            isMinimal={true}
            isCombinedChart={true}
            // isPercentage={false}
            // isCurrency={isCurrency}
            // dataRange={dataRange}
          />
        ) : (
          <LineChart
            data={combinedLineData}
            isNotPercentage={false}
            isDetailedChart={false}
            isCombinedChart={true}
            // range={"day"}
            // dataRange={dataRange}
            isXSScreen={isXSScreen}
            isMinimal={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default TakeAway;

