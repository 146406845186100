// Colors.js

const Colors = {
    primary: "#3498db", // Blue
    positive: "#549E72", // Green
    positiveHover:"#2AA860",
    danger: "#e74c3c", // Red
    warning: "#f1c40f", // Yellow
    info: "#9b59b6", // Purple
    delete:"#D2042D",
    deleteHover:"#A80324",
    cancel:"#005a9c",
    krunch:"#FFBD00",
    krunchLight:"#ffbc04",
    krunchHover:"#645e29",
    yellow:"#FFBF23",
    edit:"#7FBCFF",
    red:"#d64242",
    lightYellow: "#FFD66C",
    darkerYellow: "#CC9600",
    neutral:"#333333",
    warmOrange: "#E6A23C",
    softRed: "#F56C6C"
  };
  
  export default Colors;
  