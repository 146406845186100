import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";

const CookieConfirmation = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const userChoice = localStorage.getItem("cookieChoiceMade") === "true";
    if (!userChoice) {
      setOpen(true);
    }
  }, []);
  const handleAccept = () => {
    localStorage.setItem("cookieChoiceMade", true);
    setOpen(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieChoiceMade", false);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleDecline} // Assuming closing the dialog is equivalent to declining
      aria-labelledby="cookie-dialog-title"
      aria-describedby="cookie-dialog-description"
      PaperProps={{
        style: {
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "50%",
          height: "15rem",
        },
      }}
    >
      <DialogContent>
        <DialogContentText id="cookie-dialog-description">
          We use cookies to enhance your experience. By continuing to visit this
          site you agree to our use of cookies.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDecline} color="primary">
          Decline Cookies
        </Button>
        <Button onClick={handleAccept} color="primary" autoFocus>
          Accept Cookies
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieConfirmation;
