import * as React from "react";
import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Link from "@mui/material/Link";

import SnackbarAlert from "../utils/SnackbarAlert";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Prefix from "../utils/PrefixLink";
// import { Link as RouterLink } from 'react-router-dom';

const prefix = Prefix();

const user = JSON.parse(localStorage.getItem("user"));
const token = localStorage.getItem("token");

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://krunch.no/">
        Krunch
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function ChangePassword() {
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState("error");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showMessageAlert, setShowMessageAlert] = React.useState(false);
  const [messageSeverity, setMessageSeverity] = React.useState("error");
  const [message, setMessage] = React.useState("");
  const [validation, setValidation] = React.useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [changeToken, setChangeToken] = useState(null);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = async (e) => {
    e.preventDefault();
    if (passwordMatch) {
      const response = await fetch(
        `${prefix}/api/users/reset_password/?token=${changeToken}&password=${newPassword}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        if (!responseData.error) {
          setShowAlert(true);
          setAlertSeverity("success");
          setAlertMessage("Password changed successfully");
          window.location.href = "/";
        } else {
          setShowAlert(true);
          setAlertSeverity("error");
          setAlertMessage("Error during login. Invalid Credentials");
        }
      }
    } else {
      setShowAlert(true);
      setAlertSeverity("error");
      setAlertMessage("The passwords do not match.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validation) {
      if (passwordMatch) {
        if (oldPassword !== newPassword) {
          const response = await fetch(
            `${prefix}/api/users/change_password/?id=${user.id}&password=${newPassword}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
              },
            }
          );
          if (response.ok) {
            const responseData = await response.json();
            if (!responseData.error) {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              setShowAlert(true);
              setAlertSeverity("success");
              setAlertMessage("Password changed successfully");
              window.location.href = "/";
              setOpen(true);
              setSeverity("success");
              setMessage("Successful! Password Changed.");
            } else {
              setOpen(true);
              setSeverity("error");
              setMessage("Error! Password Not Changed.");
            }
          } else {
            setShowAlert(true);
            setAlertSeverity("error");
            setAlertMessage("Error during login.");
          }
        } else {
          setShowAlert(true);
          setAlertSeverity("error");
          setAlertMessage(
            "Your old password cannot be used as your new password"
          );
        }
      } else {
        setShowAlert(true);
        setAlertSeverity("error");
        setAlertMessage("Current Passwords do not match");
      }
    } else {
      setShowAlert(true);
      setAlertSeverity("error");
      setAlertMessage("Old Password incorrect");
    }
  };
  const handleBlur = async (e) => {
    if (e.target.value) {
      const response = await fetch(
        `${prefix}/api/users/validate_old_password/?id=${user.id}&password=${e.target.value}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      const validity = await response.json();
      if (validity.correct) {
        setValidation(true);
        setShowMessageAlert(false);
      } else {
        setShowMessageAlert(true);
        setMessageSeverity("error");
        setMessage("Old Password Incorrect");
        setValidation(false);
      }
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params) {
      console.log(params.get("changeToken"));
      setChangeToken(params.get("changeToken"));
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          maxWidth: { lg: "600px", xs: "400px" },
          boxShadow: "0px 5px 5px 3px rgba(0,0,0,0.12)", // Adds shadow to the form box
          borderRadius: 2, // Rounded corners for the form box
          backgroundColor: "background.paper",
          maxHeight: "800px",

          // border: "1px solid red",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: { lg: "600px", xs: "400px" },
            padding: 4,
            height: "700px",
            // boxShadow: "0 3px 5px 2px rgba(0,0,0,0.12)", // Adds shadow to the form box
            borderRadius: 2, // Rounded corners for the form box
            backgroundColor: "background.paper",
            // border: "solid red 1px",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#CC9600" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            sx={{ fontFamily: "Montserrat", fontWeight: 600, mb: "1rem" }}
          >
            Change Password
          </Typography>
          {console.log(changeToken === null)}
          <Box
            component="form"
            onSubmit={changeToken === null ? handleSubmit : handleReset}
            noValidate
            sx={{ mt: 1, width: "80%" }}
          >
            {!changeToken && (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="oldPassword"
                label="Old Password"
                type="password"
                id="oldPassword"
                value={oldPassword}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "10px",
                    },
                  },
                }}
              />
            )}
            {showMessageAlert && (
              <Alert
                severity={messageSeverity}
                onClose={() => setShowMessageAlert(false)}
              >
                {message}
              </Alert>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setPasswordMatch(e.target.value === confirmPassword);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "10px",
                  },
                },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              error={!passwordMatch}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setPasswordMatch(e.target.value === newPassword);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "10px",
                  },
                },
              }}
            />
            {showAlert && (
              <Alert
                severity={alertSeverity}
                onClose={() => setShowAlert(false)}
              >
                {alertMessage}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              // onClick={handleReset}
              sx={{
                width: "12rem",
                backgroundColor: "#FFBD00",
                color: "#645e29",
                mt: 3,
                mb: 2,
                "&:hover": {
                  backgroundColor: "#e3a902",
                  boxShadow: "none",
                },
                "&:active": {
                  backgroundColor: "#e3a902",
                },
              }}
            >
              Change Password
            </Button>
          </Box>
        </Box>
        {/* <Box
          sx={{
            // marginTop: 2,
            display: { xs: "none", md: "flex" }, // Hide on xs, show on md+
            flexDirection: "row",
            alignItems: "flex-end",
            width: "100%",
            maxWidth: { lg: "600px", xs: "400px" },
            // boxShadow: "0 3px 5px 2px rgba(0,0,0,0.12)", // Adds shadow to the form box
            backgroundColor: "background.paper",
            height: "100%",
            borderRadius: 2,
            // border: "10px solid #FFBD00",
            // backgroundClip: "padding-box",

            // border: "1px solid red",
          }}
        >
          <Box
            sx={{
              width: "100%", // Image takes roughly the other half
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",

              height: "100%",
            }}
          >
            <img
              src="changepassword.jpg" // Replace with your image path
              alt="Descriptive Alt Text"
              style={{
                width: "100%",
                height: "700px",
                // borderRadius: "4px",
              }}
            />
          </Box>
        </Box> */}
      </Box>
      <Copyright sx={{ mt: 5 }} />
      <SnackbarAlert
        open={open}
        onClose={handleClose}
        severity={severity}
        message={message}
      />
    </Box>
  );
}
