import React from "react";
import "../App.css";
import { Routes, Route } from "react-router-dom";
// import ProfitLoss from "../dashboard/ProfitLoss";
// import SuperDashboard from "../dashboard/SuperDashBoard";
import SalesPrediction from "../dashboard/SalesPrediction";
import MyRestaurant from "../dashboard/MyRestaurant";
import Landingv2 from "../Landingv2/Landingv2";
import GetStarted from "../dashboard/GetStarted";
// import NewDashboard from "../dashboard/NewDashboard";
import NewSuperDashboard from "../dashboard/NewSuperDashboard";
import NewSuperDashboardTwo from "../dashboard/NewSuperDashboardTwo";
import ContactUs from "../Landingv2/ContactUs";
import Features from "../Landingv2/Features";
import FAQ from "../Landingv2/FAQ";
import NewAboutPage from "../Landingv2/AboutUs";
// import FeatureFirst from "../Landingv2/FeatureFirst";
// import FeatureSecond from "../Landingv2/FeatureSecond";
// import FeatureThird from "../Landingv2/FeatureThird";
// import Faq from "../Landingv2/FAQ";

// import SignIn from "./user/SignIn";
// import Restaurants from "../user/Restaurant";
// import LandingPage from "./user/Landingpage";
import ForgotPassword from "../user/ForgotPassword";
// import Contact from "./user/Contact";
import ChangePassword from "../user/ChangePassword";
// import Contact from "../user/Contact";

import NewProfitLoss from "../dashboard/NewProfitLoss";
import Profile from "../user/Profile";
import NewRecommendations from "../dashboard/NewRecommendations";
import ErrorPage from "../user/ErrorPage"; // Import your 404 page component
import Trends from "../dashboard/Trends";
import Comparisons from "../dashboard/Comparisons";
import Event from "../user/Event";
import SupportUs from "../user/SupportUs";

function NewRecommendationsPage() {
  return (
    <div className="App">
      <main>
        <NewRecommendations />
      </main>
    </div>
  );
}

function SalesPredictionMain({
  selectedRestaurant,
  setSelectedRestaurant,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  selectedPage,
  setSelectedPage,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <SalesPrediction
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}
function CompanyStaffPage({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <NewSuperDashboard
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dataRange={dataRange}
          setDataRange={setDataRange}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          hasPredictionData={hasPredictionData}
          setHasPredictionData={setHasPredictionData}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}
// function IndividualStaffPage({
//   startDate,
//   endDate,
//   setStartDate,
//   setEndDate,
//   dataRange,
//   setDataRange,
//   selectedPage,
//   setSelectedPage,
//   selectedRestaurant,
//   setSelectedRestaurant,
//   restaurant,
//   setRestaurant,
//   hasPredictionData,
//   setHasPredictionData,
// }) {
//   return (
//     <div className="App">
//       <main>
//         <NewDashboard
//           startDate={startDate}
//           endDate={endDate}
//           setStartDate={setStartDate}
//           setEndDate={setEndDate}
//           dataRange={dataRange}
//           setDataRange={setDataRange}
//           selectedPage={selectedPage}
//           setSelectedPage={setSelectedPage}
//           selectedRestaurant={selectedRestaurant}
//           setSelectedRestaurant={setSelectedRestaurant}
//           restaurant={restaurant}
//           setRestaurant={setRestaurant}
//           hasPredictionData={hasPredictionData}
//           setHasPredictionData={setHasPredictionData}
//         />
//       </main>
//     </div>
//   );
// }

function IndividualProductPage({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <NewSuperDashboardTwo
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dataRange={dataRange}
          setDataRange={setDataRange}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          hasPredictionData={hasPredictionData}
          setHasPredictionData={setHasPredictionData}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}
function RestaurantMain() {
  return (
    <div className="App">
      <main>
        <MyRestaurant />
      </main>
    </div>
  );
}
function CreateEvent() {
  return (
    <div className="App">
      <main>
        <Event />
      </main>
    </div>
  );
}
function TrendsPage({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <Trends
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dataRange={dataRange}
          setDataRange={setDataRange}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          hasPredictionData={hasPredictionData}
          setHasPredictionData={setHasPredictionData}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}
// function MultipleDashboardTwo() {
//   return (
//     <div className="App">
//       <main>
//         <SuperDashboardTwo />
//       </main>
//     </div>
//   );
// }

// function RestaurantPage() {
//   return (
//     <div className="App">
//       <main>
//         <Restaurants />
//       </main>
//     </div>
//   );
// }
function PasswordForgot() {
  return (
    <div className="App">
      <main>
        <ForgotPassword />
      </main>
    </div>
  );
}
function PasswordChange() {
  return (
    <div className="App">
      <main>
        <ChangePassword />
      </main>
    </div>
  );
}
// function ContactUs() {
//   return (
//     <div className="App">
//       <main>
//         <Contact />
//       </main>
//     </div>
//   );
// }

// function AdminPage() {
//   return (
//     <div className="App">
//       <main>
//         <Admin />
//       </main>
//     </div>
//   );
// }
function LandingNewPage() {
  return (
    <div className="App">
      <main>
        <Landingv2 />
      </main>
    </div>
  );
}

function NewContactPage() {
  return (
    <div className="App">
      <main>
        <ContactUs />
      </main>
    </div>
  );
}
function FeaturesPage() {
  return (
    <div className="App">
      <main>
        <Features />
      </main>
    </div>
  );
}
function FAQPage() {
  return (
    <div className="App">
      <main>
        <FAQ />
      </main>
    </div>
  );
}

// function FirstFeaturePage() {
//   return (
//     <div className="App">
//       <main>
//         <FeatureFirst />
//       </main>
//     </div>
//   );
// }
// function SecondFeaturePage() {
//   return (
//     <div className="App">
//       <main>
//         <FeatureSecond />
//       </main>
//     </div>
//   );
// }
// function ThirdFeaturePage() {
//   return (
//     <div className="App">
//       <main>
//         <FeatureThird />
//       </main>
//     </div>
//   );
// }
// function FaqPage() {
//   return (
//     <div className="App">
//       <main>
//         <Faq />
//       </main>
//     </div>
//   );
// }

function NewLayoutPage({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <NewProfitLoss
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dataRange={dataRange}
          setDataRange={setDataRange}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          hasPredictionData={hasPredictionData}
          setHasPredictionData={setHasPredictionData}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}
function ComparisonsPage({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) {
  return (
    <div className="App">
      <main>
        <Comparisons
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dataRange={dataRange}
          setDataRange={setDataRange}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          selectedRestaurant={selectedRestaurant}
          setSelectedRestaurant={setSelectedRestaurant}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          hasPredictionData={hasPredictionData}
          setHasPredictionData={setHasPredictionData}
          company={company}
          setCompany={setCompany}
        />
      </main>
    </div>
  );
}
function ProfilePage() {
  return (
    <div className="App">
      <main>
        <Profile />
      </main>
    </div>
  );
}
function ErrorRoute() {
  return (
    <div className="App">
      <main>
        <ErrorPage />
      </main>
    </div>
  );
}

const AuthenticatedRoutes = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  restaurant,
  setRestaurant,
  hasPredictionData,
  setHasPredictionData,
  company,
  setCompany,
}) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <NewLayoutPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            restaurant={restaurant}
            setRestaurant={setRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />

      <Route
        path="/salesprediction"
        element={
          <SalesPredictionMain
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route
        path="/profitloss"
        element={
          <NewLayoutPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            restaurant={restaurant}
            setRestaurant={setRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route path="/event" element={<CreateEvent />} />

      {/* <Route path="/superdashboard" element={<SuperDash />} /> */}
      {/* <Route path="/salesprediction" element={<Main />} /> */}
      <Route path="/myrestaurant" element={<RestaurantMain />} />
      {/* <Route path="/superdashboardtwo" element={<MultipleDashboardTwo />} /> */}
      <Route path="/forget_password" element={<PasswordForgot />} />
      <Route path="/change_password" element={<PasswordChange />} />
      {/* <Route path="/restaurants" element={<RestaurantPage />} /> */}
      <Route path="/contact" element={<ContactUs />} />
      {/* <Route path="/admin" element={<AdminPage />} /> */}
      <Route path="/newlandingpage" element={<LandingNewPage />} />
      <Route path="/newcontact" element={<NewContactPage />} />
      <Route path="/features" element={<FeaturesPage />} />
      <Route path="/FAQ" element={<FAQPage />} />
      <Route path="/aboutus" element={<NewAboutPage />} />
      <Route path="/newpage" element={<NewLayoutPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="*" element={<ErrorRoute />} />
      <Route path="/getstarted" element={<GetStarted />} />
      {/* 
      <Route
        path="/newdashboard"
        element={
          <IndividualStaffPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            restaurant={restaurant}
            setRestaurant={setRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
          />
        }
      /> */}
      <Route
        path="/newsuperdashboard"
        element={
          <CompanyStaffPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            restaurant={restaurant}
            setRestaurant={setRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route
        path="/newsuperdashboardtwo"
        element={
          <IndividualProductPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            restaurant={restaurant}
            setRestaurant={setRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route
        path="/comparisons"
        element={
          <ComparisonsPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            restaurant={restaurant}
            setRestaurant={setRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route path="/recommendations" element={<NewRecommendationsPage />} />
      <Route
        path="/trends"
        element={
          <TrendsPage
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dataRange={dataRange}
            setDataRange={setDataRange}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            restaurant={restaurant}
            setRestaurant={setRestaurant}
            hasPredictionData={hasPredictionData}
            setHasPredictionData={setHasPredictionData}
            company={company}
            setCompany={setCompany}
          />
        }
      />
      <Route path="/event" element={<Event />} />
      <Route path="/supportus" element={<SupportUs />} />

      {/* <Route path="/employee_create" element={<EmployeeCreatePage />} />
      <Route path="/employee_list" element={<EmployeeListPage />} /> */}
    </Routes>
  );
};

export default AuthenticatedRoutes;
