import { React, useEffect, useState, useCallback } from "react";
import {
  Box,
  FormControl,
  Grid,
  Typography,
  TextField,
  Select,
  InputLabel,
  // Table,
  // TableBody,
  styled,
  ListItemText,
  // InputAdornment,
  MenuItem,
  Button,
  // Tooltip,
  // Zoom,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../utils/SnackbarAlert";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InputDialog from "../components/InputDialog";
// import MuiPagination from "@mui/material/Pagination"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import ChartLoading from "../components/Loading/ChartLoading";
import CityDialog from "../dialog/CityDialog";
import LocationDialog from "../dialog/LocationDialog";
import EventDialog from "../dialog/EventDialog";
import Colors from "../utils/Colors";
import CloseIcon from "@mui/icons-material/Close";
import StyledButton from "../components/Style/ButtonStyle";

let prefix = "";

if (process.env.NODE_ENV === "development") {
  prefix = "http://127.0.0.1:8000";
}

const ageGroups = ["Under 18", "18-30", "30-45", "45-60", "60+", "Unknown"];
const eventSizes = ["Small ", "Medium ", "Large", "Extra Large", "Unknown"];

const Event = () => {
  const columns = [
    { field: "name", headerName: "Events", width: 150 },
    { field: "event_category", headerName: "Category", width: 150 },
    { field: "event_size", headerName: "Size", width: 150 },
    { field: "location", headerName: "Location", width: 150 },
    { field: "audience_type", headerName: "Age", width: 150 },
    { field: "is_sold_out", headerName: "Sold Out", width: 150 },
    { field: "start_date", headerName: "Start Date", width: 150 },
    { field: "end_date", headerName: "End Date", width: 150 },
    { field: "cities", headerName: "City", width: 150 },
    // { field: "companies", headerName: "Company", width: 150 },
    { field: "restaurants", headerName: "Restaurant", width: 150 },
  ];

  const listCityColumns = [{ field: "name", headerName: "Cities", width: 300 }];
  const listLocationColumns = [
    { field: "name", headerName: "Locations", width: 300 },
  ];
  const listEventColumns = [
    { field: "name", headerName: "Event Category", width: 300 },
  ];
  const company = localStorage.getItem("company");
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedAges, setSelectedAges] = useState([]);
  // const [selectedCompanies, setSelectedCompanies] = useState([])
  const [selectedRestaurants, setSelectedRestaurants] = useState([]);

  const [startTime, setStartTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [dateSelection, setDateSelection] = useState(""); // 'single' or 'multiple'
  const [eventName, setEventName] = useState("");
  // const [locationName, setLocationName] = useState("");
  // const [isAvailable, setIsAvailable] = useState(false)
  const [isSoldOut, setIsSoldOut] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const [newCityName, setNewCityName] = useState("");
  const [newLocationName, setNewLocationName] = useState("");
  const [newLocationCity, setNewLocationCity] = useState("");

  const [isAddCityDialogOpen, setIsAddCityDialogOpen] = useState(false);

  const [isAddLocationDialogOpen, setIsAddLocationDialogOpen] = useState(false);

  const [isNewCategoryDialogOpen, setIsNewCategoryDialogOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryDescription, setNewCategoryDescription] = useState("");

  const [cities, setCities] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [eventCategories, setEventCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  // const [selectedEventCategoryRows, setSelectedEventCategoryRows] = useState([])
  // const [selectedCityRows, setSelectedCityRows] = useState([])
  // const [selectedLocationRows, setSelectedLocationRows] = useState([])

  const audienceTypeString = selectedAges.join(", ");
  // const [openDialog, setOpenDialog] = useState(false);
  // const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [openList, setOpenList] = useState(false);

  const handleSelection = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  // const handleSelectionEventCategory = (selectionModel) => {
  //   setSelectedEventCategoryRows(selectionModel)
  // }
  // const handleSelectionCity = (selectionModel) => {
  //   setSelectedCityRows(selectionModel)
  // }
  // const handleSelectionLocation = (selectionModel) => {
  //   setSelectedLocationRows(selectionModel)
  // }

  const handleDelete = async () => {
    if (selectedRows.length === 0) {
      setOpenAlertDialog(true);
      return;
    }
    setOpenDeleteDialog(true);
  };

  const [openCityListDialog, setOpenCityListDialog] = useState(false);
  const [openLocationListDialog, setOpenLocationListDialog] = useState(false);
  const [openEventListDialog, setOpenEventListDialog] = useState(false);

  // Modify handleOpenList to open the dialog with three buttons
  const handleOpenList = () => {
    setOpenList(true);
  };

  // New function to handle clicking on the "City" button in the first dialog
  const handleOpenCityListDialog = () => {
    setOpenCityListDialog(true);
    setOpenList(false); // Close the first dialog
  };
  const handleOpenLocationListDialog = () => {
    setOpenLocationListDialog(true);
    setOpenList(false); // Close the first dialog
  };
  const handleOpenEventListDialog = () => {
    setOpenEventListDialog(true);
    setOpenList(false); // Close the first dialog
  };
  // const handleOpenDialog = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };
  // const handleOpenDeleteConfirmDialog = () => {
  //   setOpenDeleteConfirmDialog(true);
  // };

  // const handleCloseDeleteConfirmDialog = () => {
  //   setOpenDeleteConfirmDialog(false);
  // };
  const deleteSelectedRows = async () => {
    setOpenDeleteDialog(false);
    try {
      const response = await fetch(`${prefix}/api/events/delete_events/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        setOpen(true);
        setSeverity("success");
        setMessage("Event(s) Deleted Successfully");
        navigate(0); // Refresh data after deletion
      } else {
        setOpen(true);
        setSeverity("error");
        setMessage("Error Deleting Event(s)");
      }
    } catch (error) {
      console.error("Error:", error);
      setOpen(true);
      setSeverity("error");
      setMessage("An error occurred during deletion");
    }
  };
  // const deleteSelectedRowsEventCategory = async () => {
  //   setOpenDeleteDialog(false)
  //   try {
  //     const response = await fetch(
  //       `${prefix}/api/event_category/delete_event_category`,
  //       {
  //         method: "DELETE",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //         body: JSON.stringify({ ids: selectedEventCategoryRows }),
  //       }
  //     )
  //     if (response.ok) {
  //       setOpen(true)
  //       setSeverity("success")
  //       setMessage("Event Category Deleted Successfully")
  //       navigate(0)
  //     } else {
  //       setOpen(true)
  //       setSeverity("error")
  //       setMessage("Error Deleting Event Category")
  //     }
  //   } catch (error) {
  //     console.error("Error:", error)
  //     setOpen(true)
  //     setSeverity("error")
  //     setMessage("An error occurred during deletion")
  //   }
  // }
  // const deleteSelectedRowsCity = async () => {
  //   setOpenDeleteDialog(false)
  //   try {
  //     const response = await fetch(`${prefix}/api/city/delete_city`, {
  //       method: "DELETE",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Token ${token}`,
  //       },
  //       body: JSON.stringify({ ids: selectedCityRows }),
  //     })
  //     if (response.ok) {
  //       setOpen(true)
  //       setSeverity("success")
  //       setMessage("City Deleted Successfully")
  //       navigate(0)
  //     } else {
  //       setOpen(true)
  //       setSeverity("error")
  //       setMessage("Error Deleting City ")
  //     }
  //   } catch (error) {
  //     console.error("Error:", error)
  //     setOpen(true)
  //     setSeverity("error")
  //     setMessage("An error occurred during deletion")
  //   }
  // }
  // const deleteSelectedRowsLocation = async () => {
  //   setOpenDeleteDialog(false)
  //   try {
  //     const response = await fetch(`${prefix}/api/location/delete_location`, {
  //       method: "DELETE",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Token ${token}`,
  //       },
  //       body: JSON.stringify({ ids: selectedLocationRows }),
  //     })
  //     if (response.ok) {
  //       setOpen(true)
  //       setSeverity("success")
  //       setMessage("Location Deleted Successfully")
  //       navigate(0)
  //     } else {
  //       setOpen(true)
  //       setSeverity("error")
  //       setMessage("Error Deleting Location")
  //     }
  //   } catch (error) {
  //     console.error("Error:", error)
  //     setOpen(true)
  //     setSeverity("error")
  //     setMessage("An error occurred during deletion")
  //   }
  // }

  <Box sx={{ display: "flex", alignItems: "center" }}>
    {/* <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => onPageChange(value - 1)}
    /> */}
  </Box>;

  const StyledDataGrid = styled(DataGrid)({
    ".MuiDataGrid-columnHeader": {
      backgroundColor: "#FFBD00",
      color: "black",
      fontSize: "18px",
    },
    ".MuiCheckbox-root": {
      color: "black",
    },
    ".MuiDataGrid-columnSeparator": {
      display: "none",
    },
    ".MuiTablePagination-root": {
      width: "80%", // Adjust this value as needed
    },
    ".MuiTablePagination-actions": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center", // Adjust this to align as needed
    },
    ".MuiIconButton-root": {
      width: "30px", // Adjust this value as needed
      margin: "0 5px", // Adds space between buttons
    },
  });

  // const handleCloseDialog = () => {
  //   setIsDialogOpen(false);
  // };

  const doesNameExist = (name, array) => {
    return array.some((item) => item.name.toLowerCase() === name.toLowerCase());
  };

  const handleSubmitNewCity = async () => {
    if (doesNameExist(newCityName, cities)) {
      setSeverity("error");
      setMessage("City already exists.");
      setOpen(true);
      return;
    }
    const formData = {
      name: newCityName,
    };
    try {
      const add_city_response = await fetch(`${prefix}/api/city/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(formData),
      });
      if (add_city_response.ok) {
        // Update your city list state here
        fetchCities();
        setSeverity("success");
        setMessage("City Added Successfully");
      } else {
        setSeverity("error");
        setMessage("City Addition Failed");
      }
    } catch (error) {
      console.error("Error adding city:", error);
      setSeverity("error");
      setMessage("An error occurred");
    }
    setOpen(true);
    // handleCloseDialog();
  };

  const handleSubmitNewLocation = async () => {
    if (doesNameExist(newLocationName, locations)) {
      setSeverity("error");
      setMessage("Location already exists.");
      setOpen(true);
      return;
    }
    const formData = {
      name: newLocationName,
      cities: newLocationCity.id,
    };
    try {
      const add_location_response = await fetch(`${prefix}/api/location/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(formData),
      });
      if (add_location_response.ok) {
        // Update your location list state here
        fetchLocations();
        setSeverity("success");
        setMessage("Location Added Successfully");
      } else {
        setSeverity("error");
        setMessage("Location Addition Failed");
      }
    } catch (error) {
      console.error("Error adding location:", error);
      setSeverity("error");
      setMessage("An error occurred");
    }
    setOpen(true);
    // handleCloseDialog();
  };

  const handleOpenAddCityDialog = () => {
    setIsAddCityDialogOpen(true);
  };
  const handleOpenAddLocationDialog = () => {
    setIsAddLocationDialogOpen(true);
  };

  // Handler to close the add city dialog
  const handleCloseAddCityDialog = () => {
    setIsAddCityDialogOpen(false);
  };
  const handleCloseAddLocationDialog = () => {
    setIsAddLocationDialogOpen(false);
  };
  const handleOpenAddCategoryDialog = () => {
    setIsNewCategoryDialogOpen(true);
  };

  const handleCloseAddCategoryDialog = () => {
    setIsNewCategoryDialogOpen(false);
  };

  // const handleDeleteConfirmation = () => {
  //   // Determine which type of item is selected for deletion
  //   if (openCityListDialog) {
  //     deleteSelectedRowsCity();
  //   } else if (openLocationListDialog) {
  //     deleteSelectedRowsLocation();
  //   } else if (openEventListDialog) {
  //     deleteSelectedRowsEventCategory();
  //   }
  //   handleCloseDeleteConfirmDialog();
  // };

  const handleSubmitNewCategory = async () => {
    if (doesNameExist(newCategoryName, eventCategories)) {
      setSeverity("error");
      setMessage("Event Category already exists.");
      setOpen(true);
      return;
    }
    const formData = {
      name: newCategoryName,
      description: newCategoryDescription,
    };
    try {
      const add_event_category_response = await fetch(
        `${prefix}/api/event_category/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );
      if (add_event_category_response.ok) {
        fetchEventCategories();
        setSeverity("success");
        setMessage("Event Category Added Successfully");
      } else {
        setSeverity("error");
        setMessage("Event Category Addition Failed");
      }
    } catch (error) {
      console.error("Error adding event category:", error);
      setSeverity("error");
      setMessage("An error occurred");
    }
    setOpen(true);
    handleCloseAddCategoryDialog();
  };

  const fetchEvents = useCallback(async () => {
    setIsLoading(true);
    const events_response = await fetch(
      `${prefix}/api/events/all_events?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (events_response.ok) {
      const eventsData = await events_response.json();
      if (eventsData.results) {
        const processedData = eventsData.results.map((row) => ({
          ...row,
          events_category:
            row.event_category === null ? "None" : row.event_category,
          event_size: row.event_size === null ? "None" : row.event_size,
          location: row.location === null ? "None" : row.location,
          audience_type:
            row.audience_type === null ? "None" : row.audience_type,
          end_date: row.end_date === null ? "None" : row.end_date, // Corrected this line
          cities: !row.cities || row.cities.length === 0 ? "None" : row.cities,
          // companies: row.companies === null ? "None" : row.companies,
          restaurants: row.restaurants === null ? "None" : row.restaurants,
        }));
        setTableData(processedData);
      }
    } else {
      console.error("Failed to fetch events");
    }
    setIsLoading(false);
  }, [company, token]);

  const fetchCities = useCallback(async () => {
    const city_response = await fetch(`${prefix}/api/city/all_cities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (city_response.ok) {
      const cityData = await city_response.json();
      cityData.results.sort((a, b) => a.name.localeCompare(b.name));
      setCities(cityData.results);
    } else {
      console.error("Error fetching cities");
    }
  }, [token]);

  const fetchLocations = useCallback(async () => {
    const location_response = await fetch(
      `${prefix}/api/location/all_locations`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (location_response.ok) {
      const locationData = await location_response.json();
      locationData.results.sort((a, b) => a.name.localeCompare(b.name));
      setLocations(locationData.results);
      setFilteredLocations([]);
    } else {
      console.error("Error fetching locations");
    }
  }, [token]);

  const fetchEventCategories = useCallback(async () => {
    const event_category_response = await fetch(
      `${prefix}/api/event_category/all_event_categories`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (event_category_response.ok) {
      const eventCategoryData = await event_category_response.json();
      eventCategoryData.results.sort((a, b) => a.name.localeCompare(b.name));
      setEventCategories(eventCategoryData.results);
    } else {
      console.error("Error fetching event categories");
    }
  }, [token]);

  const fetchRestaurants = useCallback(async () => {
    const restaurant_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restaurant_response.ok) {
      const restaurantData = await restaurant_response.json();
      restaurantData.results.sort((a, b) => a.name.localeCompare(b.name));

      // Sort the restaurants, moving 'Food Truck' to the end of the list
      restaurantData.results.sort((a, b) => {
        const isAFoodTruck = a.name.includes("Food Truck");
        const isBFoodTruck = b.name.includes("Food Truck");

        if (isAFoodTruck && !isBFoodTruck) {
          return 1; // Place 'a' after 'b'
        } else if (!isAFoodTruck && isBFoodTruck) {
          return -1; // Place 'a' before 'b'
        }
        return 0; // Keep original order for other restaurants
      });

      setRestaurants(restaurantData.results);
    } else {
      console.error("Error fetching restaurants");
    }
  }, [company, token]);

  const fetchCompanies = useCallback(async () => {
    const companies_response = await fetch(`${prefix}/api/company`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
    if (companies_response.ok) {
      const companiesData = await companies_response.json();
      setCompanies(companiesData.results);
    } else {
      console.error("Error fetching companies");
    }
  }, [token]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCityChange = (event) => {
    const value = event.target.value;

    if (Array.isArray(value)) {
      const selectedCity = cities.filter((city) => value.includes(city.id));
      setSelectedCity(selectedCity);

      // Filter locations based on selected cities
      const filteredLocations = locations.filter((location) =>
        selectedCity.some((city) => city.id === location.cities)
      );
      setFilteredLocations(filteredLocations);
    } else {
      const selectedCity = cities.find((city) => city.id === value);
      setSelectedCity(selectedCity ? [selectedCity] : []);

      // Filter locations based on the selected city
      const filteredLocations = locations.filter(
        (location) => selectedCity && location.cities === selectedCity.id
      );
      setFilteredLocations(filteredLocations);
    }
  };

  const handleLocationChange = (event) => {
    const selectedName = event.target.value;
    const foundLocation = locations.find((l) => l.name === selectedName);
    setSelectedLocation(foundLocation);
  };

  const handleEventChange = (event) => {
    const selectedName = event.target.value;
    const foundEvent = eventCategories.find((e) => e.name === selectedName);
    setSelectedEvent(foundEvent);
  };

  const handleSizeChange = (event) => {
    // Set the selected size based on the event's value
    setSelectedSize(event.target.value);
  };
  const handleAgeChange = (event) => {
    setSelectedAges(event.target.value);
  };

  // restaurants select
  const handleRestaurantsChange = (event) => {
    const value = event.target.value;

    if (Array.isArray(value)) {
      const selectedRestaurants = restaurants.filter((restaurant) =>
        value.includes(restaurant.id)
      );
      setSelectedRestaurants(selectedRestaurants);
    } else {
      const selectedRestaurant = restaurants.find(
        (restaurant) => restaurant.id === value
      );
      setSelectedRestaurants(selectedRestaurant ? [selectedRestaurant] : []);
    }
  };

  const handleNewLocationCityChange = (event) => {
    setNewLocationCity(event.target.value);
  };
  // const handleOpenAddNewsDialog = () => {
  //   setOpenDialog(true);
  // };
  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  useEffect(() => {
    const storedCompany = localStorage.getItem("company");
    if (storedCompany) {
      setCompanies([storedCompany]); // Set as an array with a single company ID
    }
  }, []);

  const handleSubmit = async () => {
    // Check if all or either of the selections is empty
    if (
      selectedCity.length === 0 &&
      // selectedCompanies.length === 0 &&
      selectedRestaurants.length === 0
    ) {
      setOpen(true);
      setSeverity("error");
      setMessage("Please select at least one city and one restaurant.");
      return; // Stop the form submission
    }

    if (selectedCity.length === 0) {
      setOpen(true);
      setSeverity("error");
      setMessage("Please select at least one city.");
      return; // Stop the form submission
    }

    if (selectedRestaurants.length === 0) {
      setOpen(true);
      setSeverity("error");
      setMessage("Please select at least one restaurant.");
      return; // Stop the form submission
    }

    const company_ids = companies
      .filter((comp) => comp.name === company)
      .map((comp) => comp.id);
    const restaurant_ids = selectedRestaurants.map((restro) => restro.id);
    const city_ids = selectedCity.map((city) => city.id);

    const formData = {
      cities: city_ids,
      name: eventName,
      event_category: selectedEvent ? selectedEvent.id : null,
      location: selectedLocation.id,
      audience_type: audienceTypeString,
      is_sold_out: isSoldOut,
      start_date: startDate,
      companies: company_ids,
      event_size: selectedSize,
      restaurants: restaurant_ids,
    };
    if (endDate) {
      formData.end_date = endDate;
    }

    const submit_response = await fetch(`${prefix}/api/events/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(formData),
    });
    if (submit_response.ok) {
      setOpen(true);
      setSeverity("success");
      setMessage("Event Created Successfully");
      // fetchEvents()
      setTimeout(() => navigate(0), 3000);
    } else {
      setOpen(true);
      setSeverity("error");
      setMessage("Event Creation Failed");
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (isAddCityDialogOpen) {
        handleSubmitNewCity();
        handleCloseAddCityDialog();
      } else if (isNewCategoryDialogOpen) {
        handleSubmitNewCategory();
        handleCloseAddCategoryDialog();
      } else if (isAddLocationDialogOpen) {
        handleSubmitNewLocation();
        handleCloseAddLocationDialog();
      } else {
        handleSubmit();
      }
    }
  };
  const addCityDialogContent = (
    <>
      {/* Add your dialog content here */}
      <TextField
        label="Enter City Name"
        variant="outlined"
        value={newCityName}
        onChange={(event) => setNewCityName(event.target.value)}
        fullWidth
        sx={{ mt: 2 }}
      />
    </>
  );
  const addLocationDialogContent = (
    <>
      {/* Add your dialog content here */}
      <TextField
        label="Enter Location Name"
        variant="outlined"
        value={newLocationName}
        onChange={(event) => setNewLocationName(event.target.value)}
        fullWidth
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="new-city-select-label">Select City</InputLabel>
        <Select
          labelId="new-city-select-label"
          id="new-city-select"
          value={newLocationCity}
          onChange={handleNewLocationCityChange}
          label="City"
        >
          {/* setLocafsfasfas(event.target.value) */}
          {/* city object is like this {id:balabla, name:"oslo city"} */}
          {cities.map((city) => (
            <MenuItem key={city.id} value={city}>
              {city.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

  // JSX for the add city dialog actions
  const addCityDialogActions = (
    <>
      <Button
        onClick={handleCloseAddCityDialog}
        sx={{
          backgroundColor: Colors.delete,
          color: "white",
          "&:hover": {
            backgroundColor: Colors.deleteHover, // Replace with your hover color
          },
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          handleSubmitNewCity(); // Call the submit function
          handleCloseAddCityDialog(); // Close the dialog
        }}
        sx={{
          backgroundColor: Colors.positive,
          color: "white",
          "&:hover": {
            backgroundColor: Colors.positiveHover, // Replace with your hover color
          },
        }}
      >
        Add
      </Button>
    </>
  );
  const addLocationDialogActions = (
    <>
      <Button
        onClick={handleCloseAddLocationDialog}
        sx={{
          backgroundColor: Colors.delete,
          color: "white",
          "&:hover": {
            backgroundColor: Colors.deleteHover, // Replace with your hover color
          },
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          handleSubmitNewLocation(); // Call the submit function
          handleCloseAddLocationDialog(); // Close the dialog
        }}
        sx={{
          backgroundColor: Colors.positive,
          color: "white",
          "&:hover": {
            backgroundColor: Colors.positiveHover, // Replace with your hover color
          },
        }}
      >
        Add
      </Button>
    </>
  );

  const addCategoryDialogContent = (
    <>
      <TextField
        label="Enter name"
        variant="outlined"
        value={newCategoryName}
        onChange={(event) => setNewCategoryName(event.target.value)}
        fullWidth
      />
      <div style={{ margin: "16px" }}></div>
      <TextField
        label="Enter description"
        variant="outlined"
        value={newCategoryDescription}
        onChange={(event) => setNewCategoryDescription(event.target.value)}
        fullWidth
      />
    </>
  );

  const addCategoryDialogActions = (
    <>
      <Button
        onClick={handleCloseAddCategoryDialog}
        sx={{
          backgroundColor: Colors.delete,
          color: "white",
          "&:hover": {
            backgroundColor: Colors.deleteHover, // Replace with your hover color
          },
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          handleSubmitNewCategory(); // Call the submit function
          handleCloseAddCategoryDialog(); // Close the dialog
        }}
        sx={{
          backgroundColor: Colors.positive,
          color: "white",
          "&:hover": {
            backgroundColor: Colors.positiveHover, // Replace with your hover color
          },
        }}
      >
        Add
      </Button>
    </>
  );

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);
  useEffect(() => {
    fetchCities();
  }, [fetchCities]);

  useEffect(() => {
    fetchEventCategories();
  }, [fetchEventCategories]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  return (
    <Box
      onKeyDown={handleKeyPress}
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        // minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: { lg: 1300, xl: 1600 },
          alignItems: "center",
          // Align items to the top
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column" },
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            // border: '1px solid red',
          }}
        >
          <Grid
            item
            lg={4}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRight: { xs: "none", sm: "2px solid #DADADA" },
              // border: "1px solid red",
              alignItems: "center",
              // minHeight: "100vh",
              mt: { xs: "2rem", lg: "2rem" },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                mb: "1rem",
                fontSize: {
                  lg: "1.5rem",
                  xs: "1.5rem",
                  sm: "1.5rem",
                  md: "2rem",
                },
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              Create Event
            </Typography>

            {/* city */}
            <FormControl
              sx={{
                width: {
                  lg: "70%",
                  xs: "100%",
                },
                gap: 1,
                mb: "0rem",
                mt: "1rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // border:'solid 1px red',
                ml: { lg: "20%", xs: "30%" },
              }}
            >
              <InputLabel id="city-select-label" sx={{ mr: 2 }}>
                Select City
              </InputLabel>
              <Select
                labelId="city-select-label"
                id="city-select"
                // value={selectedCity.name}
                value={selectedCity.map((c) => c.id)}
                onChange={handleCityChange}
                renderValue={(selectedIds) =>
                  selectedIds
                    .map((id) => cities.find((c) => c.id === id)?.name)
                    .join(", ")
                }
                label="Select City"
                sx={{ width: "70%", height: "3rem" }} // Set the width as required
              >
                {cities?.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    <ListItemText primary={city.name} />
                  </MenuItem>
                ))}
              </Select>
              <Box>
                <AddCircleIcon
                  onClick={handleOpenAddCityDialog}
                  sx={{
                    padding: "8px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      borderRadius: "50%",
                    },
                  }}
                />

                {/* Add City Dialog */}
                <InputDialog
                  open={isAddCityDialogOpen}
                  onClose={handleCloseAddCityDialog}
                  title="Add City"
                  content={addCityDialogContent}
                  actions={addCityDialogActions}
                />
              </Box>
            </FormControl>
            {/* city */}

            {/* category */}
            <FormControl
              sx={{
                width: {
                  lg: "70%",
                  xs: "100%",
                },
                mb: "1rem",
                mt: "1rem",

                gap: 1,

                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                ml: { lg: "20%", xs: "30%" },
              }}
            >
              <InputLabel id="event-select-label" sx={{ mr: 1 }}>
                Select Event Category
              </InputLabel>
              <Select
                labelId="event-select-label"
                id="event-select"
                value={selectedEvent.name}
                onChange={(event) => {
                  handleEventChange(event);
                }}
                label="Select Event Category"
                sx={{ width: "70%", height: "3rem" }} // Set the width as required
              >
                {eventCategories?.map((event) => (
                  <MenuItem key={event.id} value={event.name}>
                    {event.name}
                  </MenuItem>
                ))}
              </Select>
              <Box>
                <AddCircleIcon
                  onClick={handleOpenAddCategoryDialog}
                  sx={{
                    padding: "8px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                      borderRadius: "50%", // Makes the background round
                    },
                  }}
                />

                {/* Add Event Dialog */}
                <InputDialog
                  open={isNewCategoryDialogOpen}
                  onClose={handleCloseAddCategoryDialog}
                  title="Add Event Category"
                  content={addCategoryDialogContent}
                  actions={addCategoryDialogActions}
                />
              </Box>
            </FormControl>
            {/* category */}

            {/* location */}
            <FormControl
              sx={{
                width: {
                  lg: "70%",
                  xs: "100%",
                },
                mb: "1rem",
                mt: "0rem",

                gap: 1,

                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                ml: { lg: "20%", xs: "30%" },
              }}
            >
              <InputLabel id="location-select-label" sx={{ mr: 1 }}>
                Select Location
              </InputLabel>
              <Select
                labelId="location-select-label"
                id="location-select"
                value={selectedLocation.name}
                onChange={(event) => {
                  handleLocationChange(event);
                }}
                label="Select Location"
                sx={{
                  width: "70%",
                  height: "3rem",
                }}
              >
                {filteredLocations.map((location) => (
                  <MenuItem key={location.id} value={location.name}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
              <Box>
                <AddCircleIcon
                  onClick={handleOpenAddLocationDialog}
                  sx={{
                    padding: "8px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      borderRadius: "50%",
                    },
                  }}
                />
                {/* Add Location Dialog */}
                <InputDialog
                  open={isAddLocationDialogOpen}
                  onClose={handleCloseAddLocationDialog}
                  title="Add Location"
                  content={addLocationDialogContent}
                  actions={addLocationDialogActions}
                />
              </Box>
            </FormControl>
            {/* location */}

            {/* event name */}
            <TextField
              label="Enter Event Name"
              variant="outlined"
              value={eventName}
              onChange={(event) => setEventName(event.target.value)}
              sx={{
                width: {
                  lg: "50%",
                  xs: "70%",
                },
                mb: "0rem",
                mt: "0rem",
              }}
              InputProps={{
                style: {
                  height: "3rem", // Set the height as needed
                },
              }}
            />
            {/* event name */}

            {/* event size */}
            <FormControl
              sx={{
                width: {
                  lg: "50%",
                  xs: "70%",
                },
                gap: 1,
                mb: "0rem",
                mt: "1rem",
              }}
            >
              <InputLabel id="event-size-select-label">Event Size</InputLabel>
              <Select
                labelId="event-size-select-label"
                id="event-size-select"
                value={selectedSize}
                onChange={handleSizeChange}
                label="Event Size"
                sx={{
                  height: "3rem", // Adjust the height as needed
                }}
              >
                {eventSizes.map((size, index) => (
                  <MenuItem key={index} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* event size */}

            {/* audience age */}
            <FormControl
              sx={{
                width: {
                  lg: "50%",
                  xs: "70%",
                },
                gap: 1,
                mb: "0rem",
                mt: "1rem",
              }}
            >
              <InputLabel id="age-select-label">
                Select Audience Age Group
              </InputLabel>
              <Select
                labelId="age-select-label"
                id="age-multi-select"
                multiple
                value={selectedAges}
                onChange={handleAgeChange}
                renderValue={(selected) => selected.join(", ")}
                label="Select Audience Age Group"
                sx={{ height: "3rem" }}
              >
                {ageGroups.map((age, index) => (
                  <MenuItem key={index} value={age}>
                    <Checkbox checked={selectedAges.includes(age)} />
                    <ListItemText primary={age} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* audience age */}

            {/* companies */}

            {/* companies */}

            {/* restaurants */}
            <FormControl
              sx={{
                width: {
                  lg: "50%",
                  xs: "70%",
                },
                gap: 1,
                mb: "1rem",
                mt: "1rem",
              }}
            >
              <InputLabel id="restaurants-select-label">
                Select Restaurant
              </InputLabel>
              <Select
                labelId="restaurants-select-label"
                id="restaurants-multi-select"
                multiple
                value={selectedRestaurants.map((r) => r.id)}
                onChange={handleRestaurantsChange}
                // renderValue={(selected) => selected.join(", ")}
                renderValue={(selected) =>
                  selected
                    .map(
                      (id) =>
                        restaurants.find((restaurant) => restaurant.id === id)
                          ?.name
                    )
                    .join(", ")
                }
                label="Select Restaurant"
                sx={{ height: "3rem" }}
              >
                {restaurants?.map((restaurant) => (
                  <MenuItem key={restaurant.id} value={restaurant.id}>
                    <Checkbox
                      checked={selectedRestaurants.some(
                        (r) => r.id === restaurant.id
                      )}
                    />
                    <ListItemText primary={restaurant.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* restaurants */}

            <Grid
              lg={12}
              xs={12}
              item
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                // border:'1px solid red',
                gap: 2,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: {
                    lg: "1.2rem",
                    xs: "1.3rem",
                    sm: "1.5rem",
                    md: "2rem",
                  },
                  mt: 2,
                  fontFamily: "'Montserrat', sans-serif",
                }}
              >
                Choose the Date of the Event
              </Typography>
              <FormControl
                sx={{
                  width: {
                    lg: "70%",
                    xs: "70%",
                  },
                  // gap: 3,
                }}
              >
                <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                  {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAvailable}
                      onChange={(event) => setIsAvailable(event.target.checked)}
                    />
                  }
                  label='Available'
                /> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSoldOut}
                        onChange={(event) => setIsSoldOut(event.target.checked)}
                      />
                    }
                    label="Sold Out"
                  />
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Grid item xs={12}>
                    <RadioGroup
                      row
                      value={dateSelection}
                      onChange={(e) => setDateSelection(e.target.value)}
                      sx={{ justifyContent: "center", width: "100%" }}
                    >
                      <FormControlLabel
                        value="single"
                        control={<Radio />}
                        label="Single date"
                      />
                      <FormControlLabel
                        value="multiple"
                        control={<Radio />}
                        label="Multiple dates"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Start Time"
                      type="number"
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                      inputProps={{ min: 0, max: 24 }}
                      required={false}
                      sx={{ display: dateSelection ? "block" : "none" }} // show for both 'single' and 'multiple'
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label='Closing Hours'
                    type='number'
                    value={closingHours}
                    onChange={(e) => setClosingHours(e.target.value)}
                    inputProps={{ min: openingHours, max: 24 }}
                    required={false}
                    style={{
                      display: dateSelection === 'multiple' ? 'block' : 'none',
                    }} // show only for 'multiple'
                  />
                </Grid> */}
                  <Grid item xs={dateSelection === "multiple" ? 6 : 12}>
                    <TextField
                      fullWidth
                      label="Start Date"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={false}
                      sx={{ display: dateSelection ? "block" : "none" }} // show for both 'single' and 'multiple'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="End Date"
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      InputProps={{
                        inputProps: {
                          min: startDate,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={false}
                      sx={{
                        display:
                          dateSelection === "multiple" ? "block" : "none",
                      }} // show only for 'multiple'
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <StyledButton onClick={handleSubmit}>Submit</StyledButton>
              <Box>
                <StyledButton
                  onClick={handleOpenList}
                  style={{
                    width: "25ch",
                    color: "white",
                    backgroundColor: "#70335D",
                    "&:hover": {
                      backgroundColor: "#5A294A",
                      boxShadow: "none",
                    },
                    "&:active": {
                      backgroundColor: "#5A294A",
                    },
                  }}
                >
                  Edit Event Details
                </StyledButton>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            lg={8}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: { lg: "center", xs: "flex-start" },
              minHeight: "100vh",
              mt: { lg: "0", xs: "2rem" },
              // border: "1px solid #FFBD00",
            }}
          >
            <Box sx={{ height: { lg: 700, xs: 500 }, width: "89%" }}>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%", // You can adjust this height based on your layout
                  }}
                >
                  <ChartLoading />
                </div>
              ) : (
                <>
                  <StyledDataGrid
                    rows={tableData}
                    columns={columns} // Define your columns array
                    checkboxSelection
                    onDeleteButtonClick={handleDelete}
                    rowSelectionModel={selectedRows}
                    onRowSelectionModelChange={handleSelection}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDelete}
                    startIcon={<DeleteIcon />}
                    sx={{
                      marginBottom: "1px",
                      width: "12ch",
                      alignItems: "center",
                      display: "flex",
                      backgroundColor: "#D2042D", // Set button color to red
                      color: "white", // Set text color to white for better visibility
                      "&:hover": {
                        backgroundColor: "#A80324",
                        boxShadow: "none",
                      },
                      "&:active": {
                        backgroundColor: "#A80324", // Adjust this color based on your preference
                      },
                      mt: 2,
                      mx: 3,
                    }}
                  >
                    Delete
                  </Button>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={openAlertDialog}
        onClose={() => setOpenAlertDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select at least one row to delete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenAlertDialog(false)}
            sx={{
              backgroundColor: Colors.krunch,
              color: "white",
              "&:hover": {
                backgroundColor: Colors.krunchHover, // Replace with your hover color
              },
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the selected row(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            sx={{
              backgroundColor: Colors.delete,
              color: "white",
              "&:hover": {
                backgroundColor: Colors.deleteHover, // Replace with your hover color
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={deleteSelectedRows}
            autoFocus
            sx={{
              backgroundColor: Colors.positive,
              color: "white",
              "&:hover": {
                backgroundColor: Colors.positiveHover, // Replace with your hover color
              },
            }}
            startIcon={<DeleteIcon />}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openList}
        onClose={() => setOpenList(false)}
        fullWidth
        maxWidth="sm"
      >
        {/* <DialogTitle>Select Option</DialogTitle> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "space-between",
            width: "100%",
          }}
        >
          <DialogTitle sx={{ flexGrow: 1 }}>Select Option</DialogTitle>

          <CloseIcon
            onClick={() => setOpenList(false)}
            sx={{
              mr: "1rem",
              cursor: "pointer", // To indicate that the icon is clickable
              borderRadius: "50%", // To make the background change rounded, like an icon
              p: "8px",
              transition: "background-color 0.3s", // Smooth transition for the hover effect
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)", // Slight gray on hover
              },
            }}
          />
        </Box>
        <DialogActions>
          <Button
            onClick={handleOpenCityListDialog}
            sx={{
              color: "#FFF",
              backgroundColor: "#70335D",
              "&:hover": {
                backgroundColor: "#5A294A", // You can adjust this color based on your preference
                // Prevent MUI Button from changing the color on hover
                boxShadow: "none",
              },
              "&:active": {
                backgroundColor: "#5A294A", // Adjust this color based on your preference
              },
            }}
          >
            City
          </Button>
          <Button
            onClick={handleOpenLocationListDialog}
            sx={{
              color: "#FFF",
              backgroundColor: "#70335D",
              "&:hover": {
                backgroundColor: "#5A294A", // You can adjust this color based on your preference
                // Prevent MUI Button from changing the color on hover
                boxShadow: "none",
              },
              "&:active": {
                backgroundColor: "#5A294A", // Adjust this color based on your preference
              },
            }}
          >
            Location
          </Button>
          <Button
            onClick={handleOpenEventListDialog}
            sx={{
              color: "#FFF",
              backgroundColor: "#70335D",
              "&:hover": {
                backgroundColor: "#5A294A", // You can adjust this color based on your preference
                // Prevent MUI Button from changing the color on hover
                boxShadow: "none",
              },
              "&:active": {
                backgroundColor: "#5A294A", // Adjust this color based on your preference
              },
            }}
          >
            Event Category
          </Button>
        </DialogActions>
      </Dialog>

      {/* //city list dialog */}

      <CityDialog
        open={openCityListDialog}
        onClose={() => setOpenCityListDialog(false)}
        title="List of Cities"
        listCityColumns={listCityColumns}
        data={cities}
        // actions={deleteCityDialogActions}
      />

      {/* //location list dialog */}
      <LocationDialog
        open={openLocationListDialog}
        onClose={() => setOpenLocationListDialog(false)}
        title="List of Location"
        listLocationColumns={listLocationColumns}
        data={locations}
        // actions={deleteLocationDialogActions}
      />

      {/* //event list dialog */}
      <EventDialog
        open={openEventListDialog}
        onClose={() => setOpenEventListDialog(false)}
        title="List of Event Categories"
        listEventColumns={listEventColumns}
        data={eventCategories}
        // actions={deleteEventDialogActions}
      />

      <SnackbarAlert
        open={open}
        onClose={handleClose}
        severity={severity}
        message={message}
      />
    </Box>
  );
};

export default Event;
