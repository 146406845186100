export function formatRegressorValue(key) {
  // Embedded dataset within the function
  const dataset = {
    custom_regressor: "special, S",
    covid_restriction_christmas: "special, S",
    covid_loose_fall21: "special, Covid Fall 2021",
    christmas_shopping: "seasonal, S",
    sunshine_amount: "weather, S",
    heavy_rain_fall_weekend: "weather, S",
    heavy_rain_winter_weekday: "weather, S",
    heavy_rain_winter_weekend: "weather, S",
    non_heavy_rain_fall_weekend: "weather, S",
    closed_jan: "special, Closed in January",
    cruise_ship_arrivals: "event, S",
    day_before_red_day: "event, Day before public holiday",
    dec_holiday: "event, December holiday",
    easter: "event, S",
    easter_lowsaturday: "event, Easter Saturday",
    easter_mondaydayoff: "event, Easter Monday off",
    eight_may: "event, Eight of May",
    extra_regressors_multiplicative: "special, S",
    fadder_week: "special, Fadderuken",
    fellesferie: "event, Fellesferie",
    first_may: "event, First of May",
    firstweek_jan: "special, First week of January",
    high_weekend: "seasonal, Season with busy weekends",
    historical_sale: "special, S",
    is_fellesferie: "seasonal, Fellesferie",
    is_specific_month: "special, Special month",
    june_july: "special, June/July custom pattern",
    multiplicative_terms: "special, S",
    new_year_eve: "event, S",
    new_years_day: "event, S",
    oslo_pride: "event, S",
    outliers: "special, S",
    pinse: "event, S",
    rain_windy_weekend: "weather, S",
    seventeenth_may: "event, Seventeenth of May",
    sunday_regressor: "special, S",
    tons_of_rock: "event, S",
    unexpected_closure: "special, S",
    unknown_outliers: "special, S",
    valentines_day: "event, S",
    warm_and_dry: "weather, S",
    weekendmiddec_21covid: "special, Weekend mid December 2021 - covid",
    weekly_fall_start: "special, S",
    weekly_fellesferie: "special, S",
    
  };

  if (key in dataset) {
    const value = dataset[key];
    const parts = value.split(", ");
    const lastPart = parts[parts.length - 1];

    if (!lastPart === "S") {
      return lastPart;
    }
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  } else {
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }
}
