import { React, useEffect, useState, useCallback } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import AuthenticatedRoutes from "./routes/AuthenticatedRoutes";
import UnAuthenticatedRoutes from "./routes/UnauthenticatedRoutes";
import SuperAuthenticatedRoutes from "./routes/SuperAuthenticatedRoutes";
import BasicAuthenticatedRoutes from "./routes/BasicAuthenticatedRoutes";

// import EmployeeNavBar from "./components/EmployeeNavbar";
import PublicNavbar from "./components/PublicNavbar";
import Loading from "./components/Loading/Loading";
// import AdminNavBar from "./components/AdminNavbar";
// import NewNavBar from "./Landingv2/Newnavbar";
import { Box } from "@mui/material";
import NewEmployeeNavBar from "./components/NavBar/NewEmployeeNavBar";
import NewAdminNavBar from "./components/NavBar/NewAdminNavBar";
import BasicLevelNavBar from "./components/NavBar/BasicLevelNavBar";

import Prefix from "./utils/PrefixLink";
// import LoadingAnimation from "./components/Loading/BarLoad";
// import CookingAnimation from "./components/Loading/BarLoad";
// import Loader from "./components/Loading/BarLoad";
// import Loading from "react-loading";

// const latest_created_at = localStorage.getItem("latest_records");
const role = JSON.parse(localStorage.getItem("user"))?.role;
const subscriptionLevel = JSON.parse(
  localStorage.getItem("user")
)?.company_subscription_level;
// const user = JSON.parse(localStorage.getItem("user"));
// const role = ["basic", "manager", "admin"];
const token = localStorage.getItem("token");
const prefix = Prefix();
const LoadingStatus = () => {
  return (
    <div className="App">
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          font: "3rem",
          // fontSize: "10em",
          // transform: "scale(1.2)",
        }}
      >
        <Loading />
      </main>
    </div>
  );
};
const App = () => {
  const comp = localStorage.getItem("company");
  const [dataRange, setDataRange] = useState("today");
  const [selectedPage, setSelectedPage] = useState(
    role === "admin" ? "company" : "multiple"
  );
  const [company, setCompany] = useState(comp);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRestaurant, setSelectedRestaurant] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  // const [defaultRestaurants, setDefaultRestaurants] = useState([]);
  const [hasPredictionData, setHasPredictionData] = useState(true);

  // const [isBasic, setBasic] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state for loading
  // const [areDatesSet, setAreDatesSet] = useState(false);
  // const checkAuthentication = useCallback(() => {
  //   try {
  //     let url = `${prefix}/api/users/verify_token/`;
  //     const token = localStorage.getItem("token");
  //     if (token) {
  //       const company = localStorage.getItem("company");
  //       if (company) {
  //         url += `?company=${company}`;
  //       }
  //       // Set up the fetch options
  //       const requestOptions = {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Token ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       };

  //       // Call the verify_token API endpoint
  //       fetch(url, requestOptions)
  //         .then((response) => response.json())
  //         .then((data) => {
  //           if (data.success) {
  //             setIsAuthenticated(true);
  //             localStorage.setItem("latest_records", data["latest_created_at"]);
  //             const now = new Date(data["latest_created_at"]);

  //             let startingDate = new Date(
  //               Date.UTC(
  //                 now.getUTCFullYear(),
  //                 now.getUTCMonth(),
  //                 now.getUTCDate()
  //               )
  //             );
  //             let endingDate = new Date(
  //               Date.UTC(
  //                 now.getUTCFullYear(),
  //                 now.getUTCMonth(),
  //                 now.getUTCDate()
  //               )
  //             );

  //             setStartDate(startingDate.toISOString().split("T")[0]);
  //             setEndDate(endingDate.toISOString().split("T")[0]);

  //             const user = JSON.parse(localStorage.getItem("user"));
  //             if (user.company_admin) {
  //               setIsSuperUser(true);
  //             } else {
  //               setIsSuperUser(false);
  //             }
  //             setIsLoading(false); // Set loading to false once verification is complete
  //           } else {
  //             // Handle the error response from the server
  //             localStorage.removeItem("token");
  //             localStorage.removeItem("latest_records");
  //             setIsLoading(false); // Set loading to false once verification is complete
  //             window.location.href = "/";
  //           }
  //         })
  //         .catch((error) => {
  //           // Handle errors in the fetch call itself
  //           console.error("Error fetching the API:", error);
  //           setIsLoading(false); // Set loading to false once verification is complete
  //         });
  //     } else {
  //       setIsLoading(false);
  //     }
  //   } catch (error) {}
  // }, []);
  const checkAuthentication = useCallback(async () => {
    try {
      let url = `${prefix}/api/users/verify_token/`;
      const token = localStorage.getItem("token");
      if (token) {
        const company = localStorage.getItem("company");
        if (company) {
          url += `?company=${company}`;
        }
        // Set up the fetch options
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        };

        // Call the verify_token API endpoint
        const response = await fetch(url, requestOptions);
        const data = await response.json();

        if (data.success) {
          setIsManager(true);
          localStorage.setItem("latest_records", data["latest_created_at"]);
          const now = new Date(data["latest_created_at"]);

          let startingDate = new Date(
            Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
          );
          let endingDate = new Date(
            Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
          );
          // if (!areDatesSet) {
          //   setStartDate(startingDate.toISOString().split("T")[0]);
          //   setEndDate(endingDate.toISOString().split("T")[0]);
          // }

          setStartDate(startingDate.toISOString().split("T")[0]);
          setEndDate(endingDate.toISOString().split("T")[0]);

          // setAreDatesSet(true);
          const user = JSON.parse(localStorage.getItem("user"));
          if (user.role === "admin") {
            setIsSuperUser(true);
            setIsManager(false);
            setIsAuthenticated(true);
          } else if (user.role === "manager") {
            setIsSuperUser(false);
            setIsManager(true);
            setIsAuthenticated(true);
          } else {
            setIsSuperUser(false);
            setIsManager(false);
            setIsAuthenticated(true);
          }
          setIsLoading(false); // Set loading to false once verification is complete
        } else {
          // Handle the error response from the server
          localStorage.removeItem("token");
          localStorage.removeItem("latest_records");
          setIsLoading(false); // Set loading to false once verification is complete
          window.location.href = "/";
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      // Handle errors in the fetch call itself or in the try block
      console.error("Error:", error);
      setIsLoading(false); // Set loading to false once verification is complete
    }
  }, []);

  // useEffect(() => {
  //   checkAuthentication(); // Initial check
  // }, [checkAuthentication]);

  const fetchRestaurants = useCallback(async () => {
    // Replace the URL with the appropriate API endpoint for fetching a single task
    // setLoad(true);

    const restro_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();
      // Set the list of restaurants
      setRestaurants(restro_data);
      if (restro_data && restro_data.length > 0) {
        setSelectedRestaurant(restro_data[0].name); // Adjust according to your data structure
      }
    }
  }, [company]);

  useEffect(() => {
    checkAuthentication();
    const interval = setInterval(() => {
      checkAuthentication();
    }, 60 * 60 * 1000); // 60 minutes

    // Call checkAuthentication once on component mount

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [checkAuthentication]);

  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);
  return (
    <Router>
      {isLoading ? (
        <LoadingStatus />
      ) : isSuperUser && subscriptionLevel === "premium" ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              justifyContent: { lg: "flex-start", xs: "center" },
              width: "100%",
            }}
          >
            <NewAdminNavBar />
            <Box
              sx={{
                width: "100%",
                // Apply base scale for all sizes
                // transform: "scale(1)",
                // // transformOrigin: "top-left",
                // outline: "solid 1px red",

                // // Override scale for lg screens
                // "@media (min-width: 800px)": {
                //   // This breakpoint is for 'lg' screens in MUI v5, adjust if using a different version
                //   transform: "scale(1)",
                // },
                // "@media (min-width: 1200px)": {
                //   // This breakpoint is for 'lg' screens in MUI v5, adjust if using a different version
                //   transform: "scale(0.95)",
                //   width:
                //     "150%" /* Increase width to compensate for the shrinkage */,
                //   height: "100%",
                // },
                // // Override scale for xl screens
                // "@media (min-width: 1536px)": {
                //   // This breakpoint is for 'xl' screens in MUI v5, adjust if using a different version
                //   transform: "scale(1)",
                // },
              }}
            >
              <SuperAuthenticatedRoutes
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dataRange={dataRange}
                setDataRange={setDataRange}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                selectedRestaurant={selectedRestaurant}
                setSelectedRestaurant={setSelectedRestaurant}
                restaurants={restaurants}
                setRestaurants={setRestaurants}
                hasPredictionData={hasPredictionData}
                setHasPredictionData={setHasPredictionData}
                company={company}
                setCompany={setCompany}
              />
            </Box>
          </Box>
        </>
      ) : isManager && subscriptionLevel === "premium" ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              justifyContent: { lg: "flex-start", xs: "center" },
              width: "100%",
            }}
          >
            <NewEmployeeNavBar />
            <Box
              sx={{
                width: "100%",
              }}
            >
              <AuthenticatedRoutes
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dataRange={dataRange}
                setDataRange={setDataRange}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                selectedRestaurant={selectedRestaurant}
                setSelectedRestaurant={setSelectedRestaurant}
                restaurants={restaurants}
                setRestaurants={setRestaurants}
                hasPredictionData={hasPredictionData}
                setHasPredictionData={setHasPredictionData}
                company={company}
                setCompany={setCompany}
              />
            </Box>
          </Box>
        </>
      ) : isAuthenticated && subscriptionLevel === "basic" ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              justifyContent: { lg: "flex-start", xs: "center" },
              width: "100%",
            }}
          >
            <BasicLevelNavBar />
            <Box
              sx={{
                width: "100%",
              }}
            >
              <BasicAuthenticatedRoutes
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dataRange={dataRange}
                setDataRange={setDataRange}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                selectedRestaurant={selectedRestaurant}
                setSelectedRestaurant={setSelectedRestaurant}
                restaurants={restaurants}
                setRestaurants={setRestaurants}
                hasPredictionData={hasPredictionData}
                setHasPredictionData={setHasPredictionData}
                company={company}
                setCompany={setCompany}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <>
            <PublicNavbar />
            <UnAuthenticatedRoutes />
          </>
        </Box>
      )}
    </Router>
  );
};

export default App;
