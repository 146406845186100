import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  CardMedia,
} from "@mui/material";

function BlogCard({ blog }) {
  //   console.log(blog);
  return (
    <a
      href={blog.link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <Card
        sx={{
          maxWidth: 345,
          height: 300,
        }}
      >
        <CardActionArea>
          <CardMedia
            sx={{
              height: 170,
            }}
            image={blog?.thumbnail || "OverlapImage.jpg"}
            title={blog?.title || "Title not available"}
          />
          <CardHeader
            avatar={
              <Avatar
                aria-label="writer"
                sx={{
                  backgroundColor: "#FFF",
                }}
                src={blog?.writerAvatarUrl || "small_logo_transparent.png"}
              >
                {blog?.author?.charAt(0) || "A"}
              </Avatar>
            }
            title={blog?.title || "Title not available"}
            subheader={blog?.author || "Author not available"}
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {/* {blog?.description || "Description not available"} */}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </a>
  );
}

export default BlogCard;
