const formatNumber = (value) => {
  return (
    new Intl.NumberFormat("nb-NO", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value) + " NOK"
  );
  // +" NOK";
};
export default formatNumber;
