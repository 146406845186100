import { React, useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Box,
  Toolbar,
  // List,
  Button,
  // ListItem,
  // ListItemButton,
  // ListItemText,
  IconButton,
  Drawer,
  AppBar,
  // ThemeProvider,
  Collapse,
  // Typography,
} from "@mui/material/";
import {
  Close as CloseIcon,
  Menu as MenuIcon,
  // ArrowDropDown as ArrowDropDownIcon,
  MonetizationOn as MonetizationOnIcon,
  Logout as LogoutIcon,
  Restaurant as RestaurantIcon,
  AccountCircle as AccountCircleIcon,
  ShowChart as ShowChartIcon,
  Insights as InsightsIcon,
  PersonSearch as PersonSearchIcon,
  Fastfood as FastfoodIcon,
  VpnKey as VpnKeyIcon,
  PersonPin as PersonPinIcon,
  Event as EventIcon,
} from "@mui/icons-material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import Prefix from "../../utils/PrefixLink";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import NavBarToggle from "./NavBarComponents/PhoneNavBar";
import CustomButton from "./NavBarComponents/DesktopNavBar";
import CollapsibleSection from "./NavBarComponents/CollapseDesktop";
// import ContactSupportIcon from "@mui/icons-material/ContactSupport";

const prefix = Prefix();

export default function NewEmployeeNavBar() {
  //   const [adminCollapseOpen, setAdminCollapseOpen] = useState(false);
  //   const handleClick = () => {
  //     setAdminCollapseOpen(!adminCollapseOpen);
  //   };
  const appBarRef = useRef(null);
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [clickedIcon, setClickedIcon] = useState(null);
  //   const [adminOpen, setAdminOpen] = useState(false);
  const [profitOpen, setProfitOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [profitCollapseOpen, setProfitCollapseOpen] = useState(false);
  const [isBarExpanded, setIsBarExpanded] = useState(false);
  const [productCollapseOpen, setProductCollapseOpen] = useState(false);
  const [productOpen, setProductOpen] = useState(false);
  const [ignoreReduction, setIgnoreReduction] = useState(false);

  const navigatingRef = useRef(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  // Extract the first name and last name from the user object
  const userName = user ? `${user.first_name} ${user.last_name}` : "USER";
  const handleBarClick = () => {
    if (!navigatingRef.current) {
      setIsBarExpanded(true);
    }
  };

  // const handleProfitClick = () => {
  //   setProfitCollapseOpen(!profitCollapseOpen);
  // };

  const handleUserToggle = () => {
    setUserOpen(!userOpen);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };
  const handleTrendsNavigate = () => {
    navigatingRef.current = true;
    navigate("/trends");
  };
  // const handleReportPage = () => {
  //   navigatingRef.current = true;

  //   navigate("/reportissue");
  // };
  const handleComparisonsNavigate = () => {
    navigatingRef.current = true;
    navigate("/comparisons");
  };
  const handleHighProductNavigate = () => {
    navigatingRef.current = true;
    navigate("/newsuperdashboardtwo");
  };
  const handleEventNavigate = () => {
    navigatingRef.current = true;

    navigate("/event");
  };
  // const handleProductClick = () => {
  //   setProductCollapseOpen(!productCollapseOpen);
  // };
  //   const handleAdminToggle = () => {
  //     setAdminOpen((prevState) => !prevState);
  //     setClickedIcon(null);
  //   };
  const handleProfitToggle = () => {
    setProfitOpen((prevState) => !prevState);
    setClickedIcon(null);
    if (clickedIcon !== "Profit") {
      setClickedIcon("Profit");
    } else {
      setClickedIcon(null); // or whatever the default or none-selected state should be
    }
  };
  const handleProductToggle = () => {
    setProductOpen((prevState) => !prevState);
    setClickedIcon(null);
    if (clickedIcon !== "Product") {
      setClickedIcon("Product");
    } else {
      setClickedIcon(null); // or whatever the default or none-selected state should be
    }
  };
  const handleChangePassword = () => {
    navigate("/change_password");
  };
  // const handleProductToggle = () => {
  //   setProductOpen((prevState) => !prevState);
  //   setClickedIcon(null);
  // };
  // handle click event on navbar icon to navaigate link

  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${prefix}/api/users/logout/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        // Successfully logged out
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("company");
        window.location.href = "/";
      } else {
        // Handle any errors that occur during the logout process
        console.error("Failed to log out");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleLogoClick = () => {
    navigatingRef.current = true;
    navigate("/");
  };
  const handleHighLevelNavigate = () => {
    navigatingRef.current = true;
    navigate("/profitloss");
  };
  const handlePredictionNavigate = () => {
    navigatingRef.current = true;
    navigate("/salesprediction");
  };
  const handleStaffNavigate = () => {
    // navigatingRef.current = true;
    setIgnoreReduction(true);

    navigate("/newsuperdashboard");
  };

  const handleProfileNavigate = () => {
    navigatingRef.current = true;
    navigate("/profile");
  };
  const handleManagerNavigate = () => {
    // navigatingRef.current = true;
    setIgnoreReduction(true);

    navigate("/recommendations");
  };
  const handleGetStarted = () => {
    navigatingRef.current = true;
    navigate("/getstarted");
  };

  //append here if new page is to be added to desktop navbar

  const navBarConfig = {
    profitLoss: {
      label: "Profit & Loss",
      icon: MonetizationOnIcon,
      handleClick: handleProfitToggle,
      currentIcon: "Profit",
      isCollapsed: false,
      isParent: true,
      children: [
        {
          label: "Overview",
          icon: ShowChartIcon,
          handleClick: handleHighLevelNavigate,
          currentIcon: "High",
          isCollapsed: true,
          isParent: false,
        },
        {
          label: "Sales prediction",
          icon: InsightsIcon,
          handleClick: handlePredictionNavigate,
          currentIcon: "Prediction",
          isCollapsed: true,
          isParent: false,
        },
        {
          label: "Compare",
          icon: CompareArrowsIcon,
          handleClick: handleComparisonsNavigate,
          currentIcon: "Comparisons",
          isCollapsed: true,
          isParent: false,
        },
        {
          label: "Event",
          icon: EventIcon,
          handleClick: handleEventNavigate,
          currentIcon: "EventIcon",
          isCollapsed: true,
          isParent: false,
        },
      ],
    },
    staffInsight: {
      label: "Staff Insight",
      icon: PersonSearchIcon,
      handleClick: handleStaffNavigate,
      currentIcon: "Staff",
      isCollapsed: false,
      isParent: true,
    },
    product: {
      label: "Product Insight",
      icon: FastfoodIcon, // Replace with the appropriate icon
      handleClick: handleProductToggle,
      currentIcon: "Product",
      isCollapsed: false,
      isParent: true,
      children: [
        {
          label: "Overview",
          icon: ShowChartIcon,
          handleClick: handleHighProductNavigate,
          currentIcon: "HighProduct",
          isCollapsed: true,
          isParent: false,
        },
        {
          label: "Trends",
          icon: WhatshotIcon,
          handleClick: handleTrendsNavigate,
          currentIcon: "Trends",
          isCollapsed: true,
          isParent: false,
        },
      ],
    },
    manager: {
      label: "Manager",
      icon: RestaurantIcon,
      handleClick: handleManagerNavigate,
      currentIcon: "Manager",
      isCollapsed: false,
      isParent: true,
    },

    getStarted: {
      label: "Get Started",
      icon: PlayCircleFilledIcon,
      handleClick: handleGetStarted,
      currentIcon: "Get Started",
      isCollapsed: false,
      isParent: true,
    },

    // Add more sections as needed
  };

  const profitroutes = [
    { text: "Overview", path: "/profitloss" },
    { text: "Sales Prediction", path: "/salesprediction" },
    { text: "Comparisons", path: "/comparisons" },
    { text: "Event", path: "/event" },
  ];

  const userroutes = [
    { text: "Profile", path: "/profile" },
    { text: "Change Password", path: "/change_password" },
    // { text: "Contact Us", path: "/reportissue" },
    { text: "Logout", path: "/" },
  ];
  const productroutes = [
    { text: "Overview", path: "/newsuperdashboardtwo" },
    { text: "Trends", path: "/trends" },
  ];

  const handleToggle = (setState) => () => setState((prevState) => !prevState);

  const handleNavigate = (path) => {
    setIsOpen(false);
    navigate(path);
  };

  //append here if new page is to be added to mobile navbar
  const phoneNavBar = [
    {
      text: "Profit and Loss",
      isOpen: profitCollapseOpen,
      handleToggle: handleToggle(setProfitCollapseOpen),
      routes: profitroutes,
    },
    {
      text: "Staff Insight",
      isNormalButton: true,
      buttonPath: "/newsuperdashboard",
    },
    {
      text: "Product Insight",
      isOpen: productCollapseOpen,
      handleToggle: handleToggle(setProductCollapseOpen),
      routes: productroutes,
    },
    {
      text: "Manager",
      isNormalButton: true,
      buttonPath: "/recommendations",
    },
    {
      text: "Get Started",
      isNormalButton: true,
      buttonPath: "/getstarted",
    },
  ];

  useEffect(() => {
    function handleClickOutside(event) {
      if (appBarRef.current && !appBarRef.current.contains(event.target)) {
        // The click was outside the AppBar, set isBarExpanded to false
        setIsBarExpanded(false);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Collapse the bar on location change if not navigating
    if (ignoreReduction) {
      setIsBarExpanded(true);
    } else {
      setIsBarExpanded(false);
    }

    // Reset the navigating flag after the location change is handled
    navigatingRef.current = false;
  }, [location, ignoreReduction]);

  const list = () => (
    <Box
      sx={{
        width: 260,
        height: "110vh",
        backgroundColor: "#FFBD00",
        display: "flex",
        flexDirection: "column",
      }}
      role="presentation"
      // onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box
        sx={{
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mr: ".5rem",
        }}
      >
        <CloseIcon
          sx={{
            height: "28px",
            width: "28px",
          }}
          onClick={toggleDrawer(false)}
        />
      </Box>

      {/* profit and loss ends here */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          backgroundColor: "#FFBD00",
          flexGrow: 1,
        }}
      >
        {phoneNavBar.map((config, index) => (
          <Box key={index} sx={{ width: "100%" }}>
            <NavBarToggle
              text={config.text}
              isOpen={config.isOpen}
              handleToggle={config.handleToggle}
              routes={config.routes}
              handleNavigate={config.handleNavigate || handleNavigate}
              nestedRoutes={config.nestedRoutes}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                backgroundColor: "#FFBD00",
              }}
              isNormalButton={config.isNormalButton}
              buttonPath={config.buttonPath}
            />
          </Box>
        ))}
      </Box>
      <NavBarToggle
        text={userName}
        isOpen={userOpen}
        handleToggle={handleToggle(setUserOpen)}
        routes={userroutes}
        handleNavigate={(path) => {
          if (path === "/") {
            handleLogout();
          } else {
            handleNavigate(path);
          }
        }}
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          alignItems: "flex-start",
          backgroundColor: "#FFBD00",
        }}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: { xs: "flex", lg: "none" },
        }}
      >
        <AppBar
          position="static"
          sx={{ backgroundColor: "#FFBD00", width: "100%" }}
        >
          <Toolbar
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Link to="/">
              <img
                src="transparent_logo.png"
                alt="Cover"
                style={{
                  maxHeight: "40px",
                  maxWidth: "120px",
                  marginRight: "8px",
                }}
              />
            </Link>

            <IconButton
              color="Secondary"
              aria-label="menu"
              sx={{ fontSize: "1rem", width: "2px" }}
            >
              <MenuIcon onClick={toggleDrawer(true)} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </Box>

      {/* NAV Bar for Large screens */}
      <AppBar
        onClick={handleBarClick}
        ref={appBarRef}
        sx={{
          display: { lg: "flex", xs: "none" },
          backgroundColor: "#FFBD00",
          width: isBarExpanded ? "12rem" : "4rem", // Same width as the Box
          height: "1",
          flexDirection: "column",
          position: "fixed", // It stays on the side as user scrolls
          top: 0,
          left: 0,
          zIndex: 1000,
          transition: "width 0.1s ease-in-out", // This line adds the transition
          overflow: "auto", // Allow scrolling
          // Hide scrollbars
          "&::-webkit-scrollbar": {
            width: "0px",
            background: "transparent",
          },
          msOverflowStyle: "none", // for Internet Explorer and Edge
          scrollbarWidth: "none", // for Firefox
        }}
      >
        <Toolbar
          sx={{
            flexDirection: "column",
            //   justifyContent: "start",
            alignItems: "center",
            gap: 4, // Spacing between items
            height: "100%",
            paddingLeft: { sm: 0 },
            paddingRight: { sm: 0 },
            width: "100%",
          }}
        >
          {/* <Link to="/.">
                <img
                  src="https://restostagingstorage3.blob.core.windows.net/static/cover_photo_big.png"
                  alt="Cover"
                  style={{
                    maxHeight: "40px",
                    maxWidth: "120px",
                    marginRight: "8px",
                  }}
                />
              </Link> */}

          <Box onClick={handleLogoClick} sx={{ cursor: "pointer" }}>
            <img
              src="small_logo_transparent.png"
              alt="Cover"
              style={{
                maxHeight: "40px",
                maxWidth: "60px",
                // marginRight: "8px",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // width: "100%",
              height: "100%",
              justifyContent: "space-between",
              alignItems: isBarExpanded ? "left" : "center",
              width: "100%",
              // gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              {Object.keys(navBarConfig).map((sectionKey) => {
                const section = navBarConfig[sectionKey];
                const isOpen =
                  sectionKey === "profitLoss"
                    ? profitOpen
                    : sectionKey === "product"
                    ? productOpen
                    : false;
                const setOpen =
                  sectionKey === "profitLoss"
                    ? setProfitOpen
                    : sectionKey === "product"
                    ? setProductOpen
                    : () => {};

                return (
                  <Box key={section.label} sx={{ width: "100%" }}>
                    <CustomButton
                      icon={section.icon}
                      label={section.label}
                      handleClick={() => {
                        section.handleClick();
                        setClickedIcon(section.currentIcon);
                        if (section.isParent && section.children) {
                          setOpen(!isOpen);
                        }
                      }}
                      isBarExpanded={isBarExpanded}
                      clickedIcon={clickedIcon}
                      currentIcon={section.currentIcon}
                      isCollapsed={section.isCollapsed}
                      isParent={section.isParent}
                      notShowDropdown={
                        !section.children || section.children.length === 0
                      }
                    />
                    {section.children && section.children.length > 0 && (
                      <CollapsibleSection isOpen={isOpen}>
                        <Box sx={{ width: "100%" }}>
                          {section.children.map((child) => (
                            <Box key={child.label} sx={{ width: "100%" }}>
                              <CustomButton
                                icon={child.icon}
                                label={child.label}
                                handleClick={() => {
                                  child.handleClick();
                                  setClickedIcon(child.currentIcon);
                                }}
                                isBarExpanded={isBarExpanded}
                                clickedIcon={clickedIcon}
                                currentIcon={child.currentIcon}
                                isCollapsed={child.isCollapsed}
                                isParent={child.isParent}
                                notShowDropdown={
                                  !child.children || child.children.length === 0
                                }
                              />
                              {child.children && child.children.length > 0 && (
                                <CollapsibleSection>
                                  <Box sx={{ width: "100%" }}>
                                    {child.children.map((grandchild) => (
                                      <Box
                                        key={grandchild.label}
                                        sx={{ width: "100%" }}
                                      >
                                        <CustomButton
                                          icon={grandchild.icon}
                                          label={grandchild.label}
                                          handleClick={() => {
                                            grandchild.handleClick();
                                            setClickedIcon(
                                              grandchild.currentIcon
                                            );
                                          }}
                                          isBarExpanded={isBarExpanded}
                                          clickedIcon={clickedIcon}
                                          currentIcon={grandchild.currentIcon}
                                          isCollapsed={grandchild.isCollapsed}
                                          isParent={grandchild.isParent}
                                          notShowDropdown={
                                            !grandchild.children ||
                                            grandchild.children.length === 0
                                          }
                                        />
                                      </Box>
                                    ))}
                                  </Box>
                                </CollapsibleSection>
                              )}
                            </Box>
                          ))}
                        </Box>
                      </CollapsibleSection>
                    )}
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  flexDirection: "column-reverse",
                  display: "flex",
                  my: "2rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    // gap : 1
                  }}
                >
                  {!isBarExpanded ? (
                    <IconButton
                      onClick={() => {
                        handleUserToggle();
                        setClickedIcon("user");
                      }}
                      sx={{
                        color: "#645e29",
                        display: "flex",
                        justifyContent: "column",
                        backgroundColor:
                          clickedIcon === "user" ? "white" : "none",
                        my: "1.5rem",
                        // width: isBarExpanded ? "50%" : "100%",
                      }}
                    >
                      <AccountCircleIcon />
                    </IconButton>
                  ) : (
                    <Button
                      onClick={() => {
                        handleUserToggle();
                        setClickedIcon("user");
                        setProfitCollapseOpen(false);
                      }}
                      sx={{
                        color: "#645e29",
                        display: "flex",
                        backgroundColor:
                          clickedIcon === "user" ? "white" : "none",
                        // my: "1.5rem",
                        // width: isBarExpanded ? "50%" : "100%",
                        fontSize: "12px",
                        fontWeight: "bold",
                        justifyContent: "flex-start",
                      }}
                      startIcon={<AccountCircleIcon />}
                    >
                      {userName}
                    </Button>
                  )}
                  {/* {isBarExpanded ? (
                      <Typography
                        sx={{
                          color: "#645e29",
                          fontSize: "12px",
                          fontWeight: 700,
                        }}
                      >
                        User
                      </Typography>
                    ) : null} */}
                </Box>
                <Collapse
                  in={userOpen}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      color: "#645e29",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        // gap : 1
                      }}
                    >
                      {!isBarExpanded ? (
                        <IconButton
                          onClick={() => {
                            handleProfileNavigate();
                            setClickedIcon("Profile");
                          }}
                          sx={{
                            border:
                              clickedIcon === "Profile"
                                ? "2px solid #645e29"
                                : "none",
                            marginY: "0.5rem",

                            // width: isBarExpanded ? "50%" : "100%",
                          }}
                        >
                          <PersonPinIcon />
                        </IconButton>
                      ) : (
                        <Button
                          onClick={() => {
                            handleProfileNavigate();
                            setClickedIcon("Profile");
                          }}
                          sx={{
                            color: "#645e29",
                            display: "flex",
                            border:
                              clickedIcon === "Profile"
                                ? "2px solid #645e29"
                                : "none",
                            // my: "1.5rem",
                            // width: isBarExpanded ? "50%" : "100%",
                            fontSize: "12px",
                            justifyContent: "flex-start",
                          }}
                          startIcon={<PersonPinIcon />}
                        >
                          Profile
                        </Button>
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        // gap : 1
                      }}
                    >
                      {!isBarExpanded ? (
                        <IconButton
                          onClick={() => {
                            handleChangePassword();
                            setClickedIcon("Password Change");
                          }}
                          sx={{
                            border:
                              clickedIcon === "Password Change"
                                ? "2px solid #645e29"
                                : "none",
                            marginY: "0.5rem",
                            // width: isBarExpanded ? "50%" : "100%",
                          }}
                        >
                          <VpnKeyIcon />
                        </IconButton>
                      ) : (
                        <Button
                          onClick={() => {
                            handleChangePassword();
                            setClickedIcon("Password Change");
                          }}
                          sx={{
                            color: "#645e29",
                            display: "flex",
                            border:
                              clickedIcon === "Password Change"
                                ? "2px solid #645e29"
                                : "none",
                            // my: "1.5rem",
                            // width: isBarExpanded ? "50%" : "100%",
                            fontSize: "12px",
                            justifyContent: "flex-start",
                          }}
                          startIcon={<VpnKeyIcon />}
                        >
                          Change Password
                        </Button>
                      )}
                    </Box>
                    {/* <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        // gap : 1
                      }}
                    >
                      {!isBarExpanded ? (
                        <IconButton
                          onClick={() => {
                            handleReportPage();
                            setClickedIcon("Report Page");
                          }}
                          sx={{
                            border:
                              clickedIcon === "Report Page"
                                ? "2px solid #645e29"
                                : "none",
                            marginY: "0.5rem",
                            // width: isBarExpanded ? "50%" : "100%",
                          }}
                        >
                          <ContactSupportIcon />
                        </IconButton>
                      ) : (
                        <Button
                          onClick={() => {
                            handleReportPage();
                            setClickedIcon("Report Page");
                          }}
                          sx={{
                            color: "#645e29",
                            display: "flex",

                            border:
                              clickedIcon === "Report Page"
                                ? "2px solid #645e29"
                                : "none",
                            // my: "1.5rem",
                            // width: isBarExpanded ? "50%" : "100%",
                            fontSize: "12px",
                            justifyContent: "flex-start",
                          }}
                          startIcon={<ContactSupportIcon />}
                        >
                          Contact Us
                        </Button>
                      )}
                      
                    </Box> */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        // gap : 1
                      }}
                    >
                      {!isBarExpanded ? (
                        <IconButton
                          onClick={() => {
                            handleLogout();
                          }}
                          sx={{
                            // border:
                            //   clickedIcon === "Logou"
                            //     ? "2px solid #645e29"
                            //     : "none",
                            marginY: "0.5rem",
                            // width: isBarExpanded ? "50%" : "100%",
                          }}
                        >
                          <LogoutIcon />
                        </IconButton>
                      ) : (
                        <Button
                          onClick={() => {
                            handleLogout();
                          }}
                          sx={{
                            color: "#645e29",
                            display: "flex",
                            // border:
                            //   clickedIcon === "Password Change"
                            //     ? "2px solid #645e29"
                            //     : "none",
                            // my: "1.5rem",
                            // width: isBarExpanded ? "50%" : "100%",
                            fontSize: "12px",
                            justifyContent: "flex-start",
                          }}
                          startIcon={<LogoutIcon />}
                        >
                          Logout
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Collapse>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
