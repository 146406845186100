import React from "react";
import { ResponsiveLine } from "@nivo/line";
// import { getCompanyColors } from "../CompanyUtils/CompanyColor";
// import formatNumber from "../../utils/FormatNumber";
import { getLineTickValues } from "../../utils/GetLineTickValues";
import dayjs from "dayjs";
import formatKpiNumber from "../../utils/formatKpiNumber";
import formatNumber from "../../utils/FormatNumber";

// const latest_created_at = localStorage.getItem("latest_records");
const company = localStorage.getItem("company");

const GrossProfitLineChart = ({
  data,
  startDate,
  endDate,
  isDetailedChart,
  isCombinedChart = false,
  isStaffChart = false,
  isNotPercentage = false,
  isSecondaryChart = false,
  secondaryData = null,
  range = null,
  // isOverlapChart = false,
  isXSScreen = false,
  // allowNegativeY = false,
  // showZeroLine = false,
  isMinimal = false,
  // singleTooltip = false,
  hasPredictionLine = false,
  predictionLineDate = null,
  isMinNonZero = false,
  isCurrency = false,
  noPrefix = false,
  hasStaffMarker = false,
  isSalesPrediction = false,
  useKpiFormat = false,
  isOverlapChart = false,
  Latest = null,
  hasPredictionData = false,
}) => {
  console.log(data, "data in chart");
  //SANDBOX
  //SANDBOX
  let newVar;
  let secondVar;

  // const [activeId, setActiveId] = useState(null);
  //   const [hiddenIds, setHiddenIds] = useState([]);

  const format = !isNotPercentage
    ? (v) => `${v}%`
    : isCurrency
    ? (v) => `${v} NOK`
    : (v) => `${v} units`;

  if (!isDetailedChart) {
    newVar = [];
  }
  const goalPercentage = company === "Fisketorget" ? 32 : 28;

  // Assuming staffchart is a boolean variable defined earlier
  for (const property in data) {
    if (isOverlapChart) {
      newVar.push(data[property]);
    } else {
      // If staffchart is false, keep your original structure
      newVar.push(data[property]);
    }
  }

  const lineData =
    newVar && Object.entries(newVar).length !== 0 ? [newVar] : [];
  if (isCombinedChart) {
    //combined chart
    newVar = newVar[0];
  }

  const { tickValuesI, tickValuesO } = getLineTickValues(newVar);

  if (secondaryData) {
    secondVar = [];
    for (const property in secondaryData) {
      secondVar.push(secondaryData[property]);
    }
  }
  if (newVar && newVar.length > 0) {
    newVar = newVar.map((series) => {
      return {
        ...series,
        data: series.data.map((point) => {
          return {
            ...point,
            y: isNaN(point.y) ? 0 : point.y,
          };
        }),
      };
    });
  }

  //   let filteredData = newVar?.filter((serie) => !hiddenIds.includes(serie.id));

  function processData(data, latestDate) {
    const latestDateTime = new Date(latestDate).setHours(0, 0, 0, 0); // Normalize the "Latest" date to the start of the day

    return data.map((dataset) => {
      const isHistorical = dataset.id.includes("Historical");
      const isPrediction = dataset.id.includes("Prediction");

      const processedData = dataset.data.map((entry) => {
        const entryTime = new Date(entry.x).setHours(0, 0, 0, 0); // Normalize entry date to the start of the day

        // For historical data, nullify 'y' for the 'Latest' day and past it.
        // For prediction data, nullify 'y' strictly before the 'Latest' day.
        if (
          (isHistorical && entryTime > latestDateTime) ||
          (isPrediction && entryTime < latestDateTime)
        ) {
          return { ...entry, y: null };
        }

        return entry;
      });

      return { ...dataset, data: processedData };
    });
  }

  const filteredData = hasPredictionData ? processData(newVar, Latest) : newVar;

  let minYValue;
  let maxYValue;
  if (filteredData && filteredData.length > 0) {
    // Extract all y-values from all data series and filter out NaN values
    const allYValues = filteredData.flatMap((series) =>
      series.data.map((point) => point.y).filter((y) => !isNaN(y))
    );

    // Check if there are any valid values left after filtering
    if (allYValues.length > 0) {
      // Find the minimum and maximum y-values from the filtered array
      minYValue = Math.min(...allYValues);
      maxYValue = Math.max(...allYValues);
      if (minYValue === maxYValue) {
        minYValue = 0;
      }
    } else {
      // Default values if no valid data is available
      minYValue = 0;
      maxYValue = 0;
    }
  } else {
    // Default values if no data is available
    minYValue = 0;
    maxYValue = 0;
  }

  minYValue = minYValue.toFixed(0);
  maxYValue = maxYValue.toFixed(0);

  if (maxYValue < 10) {
  } else if (isNotPercentage) {
    maxYValue =
      maxYValue < 1000
        ? Math.round(maxYValue / 100) * 100
        : maxYValue < 9000
        ? Math.round(maxYValue / 1000) * 1000
        : Math.round(maxYValue / 10000) * 10000;
  } else {
    // For percentages or other units
    maxYValue = Math.round(maxYValue / 10) * 10; // Adjust rounding logic as needed
  }

  let staffMarkerDate;
  if (hasStaffMarker && Latest) {
    const latestCreatedAt = dayjs(Latest);
    const tickValues = getLineTickValues(newVar); // Assuming getLineTickValues is defined elsewhere and returns an object with tickValuesI and tickValuesO
    const firstTickValue = dayjs(tickValues.tickValuesI[0]); // Assuming tickValuesI is an array of date strings or timestamps
    const lastTickValue = dayjs(
      tickValues.tickValuesO[tickValues.tickValuesO.length - 1]
    );
    // Check if latest_created_at falls within the tick values range
    if (
      latestCreatedAt.isAfter(firstTickValue) &&
      latestCreatedAt.isBefore(lastTickValue)
    ) {
      const modifiedDate = latestCreatedAt.add(14, "day");
      staffMarkerDate = modifiedDate.toDate();
      staffMarkerDate.setHours(0, 0, 0, 0);
    }
    // else {
    //   return; // Early return if latest_created_at is not within the range
    // }
  }

  // let start_date, end_date;
  let predictionLineMarkerDate;
  if (hasPredictionLine && Latest) {
    predictionLineMarkerDate = new Date(Latest);
    // Set the time to midnight to align with the graph points
    predictionLineMarkerDate?.setHours(0, 0, 0, 0);
  }

  const pointTooltip = ({ point }) => {
    const roundToNearest500 = (value) => {
      if (value < 100) {
        // Round to the nearest 10 for values less than 100
        return Math.round(value / 10) * 10;
      } else if (value < 500) {
        // Round to the nearest 100 for values between 100 and 499
        return Math.round(value / 100) * 100;
      } else {
        // Round to the nearest 500 for values 500 and above
        return Math.round(value / 500) * 500;
      }
    };

    const date = new Date(point.data.x);
    let formattedDate;

    if (range === "month") {
      formattedDate = date.toLocaleDateString(undefined, {
        month: "long",
        year: "numeric",
      });
    } else if (range === "week") {
      const sunday = new Date(date);
      sunday.setDate(sunday.getDate() - sunday.getDay());

      const startOfYear = new Date(sunday.getFullYear(), 0, 1);
      const dayDifference = (sunday - startOfYear) / (24 * 60 * 60 * 1000);
      const weekNumber =
        1 + Math.floor((dayDifference + startOfYear.getDay() + 5) / 7);

      formattedDate = `${date.toLocaleDateString(undefined, {
        month: "long",
      })} (Week ${weekNumber}) ${sunday.getFullYear()}`;
    } else {
      formattedDate = date.toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }

    // Ensure both dates are compared in the same format
    let isStaffMarkerDate =
      hasStaffMarker &&
      new Date(point.data.x)?.setHours(0, 0, 0, 0) ===
        staffMarkerDate?.setHours(0, 0, 0, 0);

    // Convert latest_created_at to a Date object and set hours to zero for accurate comparison
    const latestCreatedAtDate = new Date(Latest).setHours(0, 0, 0, 0);
    // Convert point date to the same format for accurate comparison
    const pointDate = new Date(point.data.x).setHours(0, 0, 0, 0);

    // Determine whether the tooltip should display "Historical" or "Prediction"
    let tooltipTitle;
    if (isOverlapChart && pointDate <= latestCreatedAtDate) {
      tooltipTitle = "Historical";
    } else {
      tooltipTitle = "Prediction";
    }

    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          borderRadius: "5px",
        }}
      >
        <strong>{formattedDate}</strong>
        <br />
        {point.data.comment && (
          <strong style={{ color: "black", marginBottom: "10px" }}>
            Comment: {point.data.comment}
            <br />
          </strong>
        )}
        {isStaffMarkerDate && (
          <strong
            style={{ marginTop: "10px", display: "block", color: "#D2042D" }}
          >
            Planned staff cost ends here
            <br />
          </strong>
        )}
        {/* Conditional rendering for Net Profit, Gross Profit at the bottom */}
        {(point.serieId === "Gross Profit II Historical" ||
          point.serieId === "Gross Profit II Prediction") && (
          <>
            <strong style={{ color: "gray", marginBottom: "5px" }}>
              Employee Cost:{" "}
              {isNotPercentage
                ? formatKpiNumber(roundToNearest500(point.data.emp_cost))
                : point.data.emp_cost}
              <br />
              Third Party Delivery Cost:{" "}
              {isNotPercentage
                ? formatKpiNumber(roundToNearest500(point.data.delivery_cost))
                : point.data.delivery_cost}
              <br />
            </strong>
            <strong style={{ color: point.serieColor }}>
              {point.serieId}:{" "}
              {isNotPercentage
                ? formatKpiNumber(Math.round(point.data.y))
                : `${point.data.y}`}
            </strong>
            <br />
          </>
        )}
        {(point.serieId === "Gross Profit I Historical" ||
          point.serieId === "Gross Profit I Prediction") && (
          <>
            <strong style={{ color: "gray" }}>
              Cost of Goods:{" "}
              {isNotPercentage
                ? formatKpiNumber(roundToNearest500(point.data.cost))
                : point.data.cost}
              <br />
            </strong>
            <strong style={{ color: point.serieColor }}>
              {point.serieId}:{" "}
              {isNotPercentage
                ? formatKpiNumber(Math.round(point.data.y))
                : `${point.data.y}`}
            </strong>
            <br />
          </>
        )}
        {point.serieId !== "Gross Profit II Historical" &&
          point.serieId !== "Gross Profit I Historical" &&
          point.serieId !== "Gross Profit II Prediction" &&
          point.serieId !== "Gross Profit I Prediction" && (
            <strong
              style={{
                color:
                  tooltipTitle === "Historical" ? "#543c8c" : point.serieColor,
                marginTop: "10px",
              }}
            >
              {!isOverlapChart ? `${point.serieId}: ` : ""}
              {isNotPercentage
                ? useKpiFormat
                  ? formatNumber(Math.round(point.data.y))
                  : isOverlapChart
                  ? `${formatNumber(Math.round(point.data.y))} ${tooltipTitle}`
                  : formatKpiNumber(Math.round(point.data.y))
                : isOverlapChart
                ? `${point.data.y}% ${tooltipTitle}`
                : `${point.data.y}%`}
            </strong>
          )}
      </div>
    );
  };

  const axisProps = {
    axisLeft: {
      format,
      tickValues: [minYValue, maxYValue],
      legendOffset: 12,
    },

    tooltip: pointTooltip,
  };

  return !lineData || lineData.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        // border: "solid red 1px",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    // <Box
    //   sx={{
    //     display: "flex",
    //     flexDirection: "column",
    //     height: "100%",
    //     width: "100%",
    //   }}
    // >
    <ResponsiveLine
      animate
      enableGridX={isMinimal ? false : !isSecondaryChart}
      enableGridY={isMinimal ? true : !isSecondaryChart}
      gridYValues={[0, minYValue, maxYValue]}
      axisBottom={{
        format: (value) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString(undefined, {
            // year: "numeric",
            month: "short",
            day: "numeric",
          });

          return formattedDate;
        },

        legendOffset: -12,
        tickValues: isXSScreen ? tickValuesO : tickValuesI, // Assuming tickValuesO and tickValuesI are defined elsewhere
      }}
      {...axisProps}
      axisLeft={
        !isSecondaryChart
          ? {
              tickValues: [0, minYValue, isStaffChart ? 70 : maxYValue].filter(
                (v, i, a) => a.indexOf(v) === i
              ), // Ensuring no duplicates
              format: noPrefix ? undefined : (v) => format(v),
              legendOffset: -40,
            }
          : null
      }
      curve="monotoneX"
      data={filteredData}
      // enablePointLabel
      // height={400}
      margin={
        isMinimal
          ? {
              bottom: 40,
              left: noPrefix ? 60 : maxYValue > 100 ? 60 : 60,
              right: 50,
              top: 40,
            }
          : isStaffChart
          ? {
              bottom: 60,
              left: 80,
              right: 90,
              top: 30,
            }
          : isSalesPrediction
          ? {
              bottom: 60,
              left: 80,
              right: 40,
              top: 80,
            }
          : {
              bottom: 60,
              left: 80,
              right: 40,
              top: 50,
            }
      }
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      colors={{ datum: "color" }}
      lineWidth={1}
      markers={[
        ...(isStaffChart && !isSecondaryChart
          ? [
              {
                axis: "y",
                value: goalPercentage,
                legend: `Goal ${goalPercentage}%`,
                lineStyle: {
                  stroke: "gray",
                  strokeWidth: 2,
                },
              },
            ]
          : []),
        // Add the marker for todays date here
        hasPredictionData && hasPredictionLine
          ? {
              axis: "x", // Vertical line
              // legend: "prediction marker",
              value: predictionLineMarkerDate,
              lineStyle: {
                stroke: "#505050", // Line color
                strokeWidth: 2, // Line width
              },

              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
        hasStaffMarker // change to hasStaffMarkers
          ? {
              axis: "x", // Vertical line
              // legend: "prediction marker",
              value: staffMarkerDate,
              // Immediately invoke the function to get the new date
              lineStyle: {
                stroke: "green", // Line color
                strokeWidth: 2, // Line width
              },
              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
      ]}
      pointBorderWidth={1}
      pointSize={4}
      enableArea={true}
      areaBaselineValue={isMinNonZero ? 0 : 0}
      areaOpacity={0.15}
      // enableSlices={singleTooltip ? undefined : "x"}
      labelFormat={format}
      useMesh
      xFormat="time:%Y-%m-%d"
      xScale={{
        format: "%Y-%m-%d",
        precision: "day",
        type: "time",
        useUTC: true,
      }}
      yScale={{
        type: "linear",
        // min: allowNegativeY ? "auto" : 0,
        min: minYValue < -100 ? -100 : 0, // Always start from 0
        max: isStaffChart ? 70 : "auto", // Set the calculated maxY as the max property
      }}
      legends={
        [
          // {
          //   anchor: "bottom",
          //   direction: "row",
          //   justify: false,
          //   translateX: isSecondaryChart ? 150 : 2,
          //   translateY: 50,
          //   itemsSpacing: 10,
          //   itemDirection: "left-to-right",
          //   itemWidth: 109,
          //   itemHeight: 20,
          //   itemOpacity: 0.75,
          //   symbolSize: 12,
          //   symbolShape: "circle",
          //   symbolBorderColor: "rgba(0, 0, 0, .5)",
          //   effects: [
          //     {
          //       on: "hover",
          //       style: {
          //         itemBackground: "rgba(0, 0, 0, .03)",
          //         itemOpacity: 1,
          //       },
          //     },
          //   ],
          //   onClick: (datum) => {
          //     setHiddenIds((currentHiddenIds) => {
          //       const isCurrentlyHidden = currentHiddenIds.includes(datum.id);
          //       const newHiddenIds = isCurrentlyHidden
          //         ? currentHiddenIds.filter((id) => id !== datum.id)
          //         : [...currentHiddenIds, datum.id];
          //       // Ensure we don't hide all lines: if newHiddenIds length is equal to the number of lines, reset it
          //       if (newHiddenIds.length >= newVar.length) {
          //         return [];
          //       }
          //       return newHiddenIds;
          //     });
          //   },
          // },
        ]
      }
    />
  );
};

export default GrossProfitLineChart;

// const CustomLegend = ({ symbols, onClickLegend }) => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         marginBottom: "20px",
//         alignItems: "center",
//         justifyContent: "center",
//         height: "2rem",
//       }}
//     >
//       {symbols.map((symbol) => (
//         <div
//           key={symbol.id}
//           onClick={() => onClickLegend(symbol.id)}
//           style={{ marginRight: "10px", cursor: "pointer" }}
//         >
//           <svg width="20" height="20">
//             <circle cx="10" cy="10" r="6" fill={symbol.color} />
//           </svg>
//         </div>
//       ))}
//     </div>
//   );
// };

// const handleLegendClick = (id) => {
//     setHiddenIds((currentHiddenIds) => {
//       if (currentHiddenIds.includes(id)) {
//         return currentHiddenIds.filter((existingId) => existingId !== id);
//       }
//       return [...currentHiddenIds, id];
//     });
//   };

/* <CustomLegend
        symbols={data.map((line) => ({
          id: line.id,
          color: line.color || line.borderColor,
        }))}
        onClickLegend={handleLegendClick}
      /> */

//   function processData(data, latestDate) {
//     const latestDateTime = new Date(latestDate).setHours(0, 0, 0, 0); // Normalize the "Latest" date to the start of the day

//     return data.map((dataset) => {
//       const isPrediction = dataset.id.includes("Prediction");

//       const processedData = dataset.data.map((entry) => {
//         const entryTime = new Date(entry.x).setHours(0, 0, 0, 0); // Normalize entry date to the start of the day

//         // For prediction data, nullify 'y' strictly before the 'Latest' day.
//         if (isPrediction && entryTime < latestDateTime) {
//           return { ...entry, y: null };
//         }

//         // Return the entry unchanged if it's historical data
//         return entry;
//       });

//       return { ...dataset, data: processedData };
//     });
//   }
