// function lightenColor(color, amount) {
//   let usePound = false;
//   if (color[0] === "#") {
//     color = color.slice(1);
//     usePound = true;
//   }

//   let num = parseInt(color, 16);

//   let r = (num >> 16) + amount;
//   if (r > 255) r = 255;
//   else if (r < 0) r = 0;

//   let g = ((num >> 8) & 0x00ff) + amount;
//   if (g > 255) g = 255;
//   else if (g < 0) g = 0;

//   let b = (num & 0x0000ff) + amount;
//   if (b > 255) b = 255;
//   else if (b < 0) b = 0;

//   return (
//     (usePound ? "#" : "") +
//     ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")
//   );
// }

// function getLineColor(lineType, intensity) {
//   const baseColors = {
//     Sales: "#543c8c",
//     "Gross Profit I": "#e394b4",
//     "Gross Profit II": "#70335D", // Changed base color for Gross Profit II
//   };

//   const baseColor = baseColors[lineType];
//   if (!baseColor) {
//     throw new Error("Invalid line type");
//   }

//   const intensityLevels = {
//     1: 5,
//     2: 10,
//     3: 15,
//     4: 20,
//     5: 25,
//   };

//   const amount = intensityLevels[intensity];
//   if (amount === undefined) {
//     throw new Error("Invalid intensity level");
//   }

//   return lightenColor(baseColor, amount);
// }

// // Example usage:
// console.log(getLineColor("Sales", 2)); // Returns a lighter shade of purple
// console.log(getLineColor("Gross Profit I", 3)); // Returns a lighter shade of pink
// console.log(getLineColor("Gross Profit II", 4)); // Returns a lighter shade of gray

//ORIGINAL
// const colors = {
//   Sales: ["#543c8c", "#5f449e", "#694bb0", "#785cb9", "#866dc1", "#957fc8"],
//   "Gross Profit I": [
//     "#e394b4",
//     "#e8a8c2",
//     "#eebcd0",
//     "#f3d1de",
//     "#f8e5ed",
//     "#fdf9fb",
//   ],
//   "Gross Profit II": [
//     "#70335D",
//     "#865073",
//     "#9C6C89",
//     "#B2889F",
//     "#C8A4B5",
//     "#DEC0CB",
//   ],
// };

const colors = {
  Sales: [
    "#543c8c",
    "#5f449e",
    "#694bb0",
    "#785cb9",
    "#866dc1",
    "#957fc8",
    "#e394b4",
  ],
  "Gross Profit I": [
    "#157037",
    "#e8a8c2",
    "#eebcd0",
    "#f3d1de",
    "#f8e5ed",
    "#fdf9fb",
    "#6bcfbe",
  ],
  "Gross Profit II": [
    "#70335D",
    "#865073",
    "#9C6C89",
    "#B2889F",
    "#C8A4B5",
    "#DEC0CB",
    "#949494",
  ],
};

function getLineColor(lineType, intensity) {
  const intensityIndex = intensity - 1; // Convert to 0-based index
  return colors[lineType][intensityIndex] || colors[lineType][0];
}

export default getLineColor;

// // Example usage:
// console.log(getLineColor("Sales", 2)); // Returns a lighter shade of purple
// console.log(getLineColor("Gross Profit I", 3)); // Returns a lighter shade of pink
// console.log(getLineColor("Gross Profit II", 4)); // Returns a lighter shade of gray
