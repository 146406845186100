import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Card,
  Button,
  CardHeader,
  // CardMedia,
  CardContent,
  // CardActions,
  Avatar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  // CardMedia,
  Divider,
  ListItemIcon,
  Skeleton,
  TextField,
  // FormControl,
  // InputLabel,
  // FormControlLabel,
  // Checkbox,
  // Select,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import MoreVertIcon from "@mui/icons-material/MoreVert";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import { red, yellow } from "@mui/material/colors";
// import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CakeIcon from "@mui/icons-material/Cake";
// import PersonIcon from "@mui/icons-material/Person";
// import ChartLoading from "../components/Loading/ChartLoading";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SnackbarAlert from "../utils/SnackbarAlert";
// import Colors from "../utils/Colors";
// import CheckIcon from "@mui/icons-material/Check";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";

let prefix = "";
if (process.env.NODE_ENV === "development") {
  prefix = "http://localhost:8000";
}

const EmployeeList = () => {
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [openIt, setOpenIt] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterRole, setFilterRole] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null);
  const [roleMenuAnchorEl, setRoleMenuAnchorEl] = useState(null);
  const [numCards, setNumCards] = useState(9);
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    if (isLg) {
      setNumCards(6);
    } else if (isXs) {
      setNumCards(3);
    } else {
      setNumCards(9);
    }
  }, [isLg, isXs]);

  const filteredUsers = users
    .filter((user) => {
      return (
        (user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.last_name.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (!filterRole || user.role === filterRole)
      );
    })
    .sort((a, b) => {
      const nameA = `${a.first_name} ${a.last_name}`;
      const nameB = `${b.first_name} ${b.last_name}`;
      return sortOrder === "asc"
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });

  const handleClick = (event, userId) => {
    setAnchorEl({ anchor: event.currentTarget, id: userId });
  };

  const handleEditClick = () => {
    navigate(`/employee_create/${anchorEl.id}`);
    setAnchorEl(null); // Close menu after navigating
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilterMenuAnchorEl(null);
    setRoleMenuAnchorEl(null);
  };
  const handleCreateClick = () => {
    navigate(`/employee_create`);
  };
  const handleRoleMenuClick = (event) => {
    setRoleMenuAnchorEl(event.currentTarget);
  };
  const handleFilterMenuClick = (event) => {
    setFilterMenuAnchorEl(event.currentTarget);
  };

  // const handleFilter = (role) => {
  //   console.log("Filtering by:", role);
  //   setFilterMenuAnchorEl(null);
  //   setAnchorEl(null);
  // };
  const handleSort = (order) => {
    setSortOrder(order);
    setFilterMenuAnchorEl(null); // Close the menu after selecting
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const user_response = await fetch(
      `${prefix}/api/users/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const user_data = await user_response.json();
    if (user_response.ok) {
      setUsers(user_data.results);
    }
    setIsLoading(false);
  }, [token, company]);

  const handleDeleteClick = async (id) => {
    const response = await fetch(`${prefix}/api/users/${id}/`, {
      method: "DELETE",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      // Refetch the employee list after deletion
      setOpenIt(true);
      setSeverity("success");
      setMessage("Employee Deleted!");
      fetchData();
    } else {
      setOpenIt(true);
      setSeverity("error");
      setMessage("Server Error");
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        // p: 2, // adds padding around the outer Box
      }}
    >
      <Typography
        sx={{
          color: "#4c4923",
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: 700,
          fontSize: "25px",
          mt: 3,
          mb: 3,
        }}
      >
        Employee List
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: { lg: "center", xs: "center" },
          gap: 2,
          maxWidth: { lg: 1300, xl: 1600 },
          width: "100%",
          // border: "solid red 1px",
        }}
      >
        <Box
          sx={{
            width: { lg: "100%", xs: "80%" },
            display: "flex",
            flexDirection: { xs: "row", lg: "row" },
            alignItems: { xs: "center", lg: "center" },
            justifyContent: { lg: "flex-end", xs: "flex-start" },
            // ml: 2,
            mx: "auto",
            // border: "solid red 1px",
          }}
        >
          <Box
            sx={{
              width: { lg: "50%", xs: "100%" },
              display: "flex",
              flexDirection: { xs: "row", lg: "row" },
              alignItems: { xs: "center", lg: "center" },
              justifyContent: { lg: "flex-end", xs: "flex-start" },
              mr: { lg: "100px", xs: 0 },
              // mx: "auto",
              // border: "solid red 1px",
            }}
          >
            {/* Filter by Role with Icon Button */}

            <Button
              variant="outlined"
              startIcon={<FilterListIcon />}
              onClick={handleRoleMenuClick}
              sx={{
                width: { lg: "8rem", xs: "40%" },
                // backgroundColor: "black",
                color: "black",

                // mt: 1, // margin top for spacing
                "&:hover": {
                  // backgroundColor: "black", // Keep background color same on hover
                  boxShadow: "none",
                  border: "none", // Remove border on hover
                },
                "&:active": {
                  // backgroundColor: "black", // Keep background color same when active
                  border: "none", // Remove border when active
                },

                ml: 2,
                height: "3.5rem",
                border: "none", // Remove border from the button
              }}
            >
              Filter
            </Button>
            {/* Search Field */}

            <Menu
              anchorEl={roleMenuAnchorEl}
              open={Boolean(roleMenuAnchorEl)}
              onClose={() => setRoleMenuAnchorEl(null)} // Handler to close menu when clicking away
            >
              <MenuItem
                onClick={() => {
                  setFilterRole("");
                  // setAnchorEl(null);
                }}
              >
                All
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFilterRole("admin");
                  // setAnchorEl(null);
                }}
              >
                Admin
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFilterRole("manager");
                  // setAnchorEl(null);
                }}
              >
                Manager
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFilterRole("basic");
                  // setAnchorEl(null);
                }}
              >
                Basic
              </MenuItem>
            </Menu>
            <TextField
              label="Search by Name"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                width: { xs: "100%", lg: "40%" },
                order: { xs: -1 }, // Make search field always on top on smaller screens
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {/* Sorting Checkbox */}

            <MoreVertIcon
              onClick={handleFilterMenuClick}
              sx={{
                padding: "8px",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  borderRadius: "50%",
                },
              }}
            />
            <Menu
              id="long-menu"
              anchorEl={filterMenuAnchorEl}
              // keepMounted
              open={Boolean(filterMenuAnchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleSort("asc")}>Sort by ASC</MenuItem>
              <MenuItem onClick={() => handleSort("desc")}>
                Sort by DESC
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        {isLoading ? (
          Array.from(new Array(numCards)).map((_, index) => (
            <Card
              sx={{
                width: {
                  xs: "90%",
                  sm: "calc(45% - 16px)",
                  md: "calc(30% - 16px)",
                  xl: "calc(27% - 16px)",
                  lg: "calc(33.333% - 16px)",
                },
                padding: "10px",
                borderRadius: 5,
                border: "1px solid #e0e0e0",
                minHeight: "230px",
                m: 1,
                display: "flex",
                flexDirection: "column",
                // border: "solid red 1px",
              }}
              elevation={2}
              key={index}
            >
              <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
                <Skeleton variant="circular" width={77} height={60} />
                <Box sx={{ marginLeft: 2, width: "100%" }}>
                  <Skeleton width="60%" height={24} />
                  <Skeleton width="40%" height={20} />
                </Box>
              </Box>
              <Divider variant="fullWidth" />
              <CardContent>
                <Skeleton width="80%" height={20} />
                <Skeleton width="80%" height={20} />
                <Skeleton width="80%" height={20} />
              </CardContent>
            </Card>
          ))
        ) : (
          <>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <Card
                  sx={{
                    width: {
                      xs: "90%",
                      sm: "calc(45% - 16px)",
                      md: "calc(30% - 16px)",
                      xl: "calc(27% - 16px)",
                      lg: "calc(33.333% - 16px)",
                    },
                    padding: "10px",
                    borderRadius: 5,
                    border: "1px solid #e0e0e0", // Optional: adds outer border to the card
                    minHeight: "230px",

                    m: 1, // margin around each card
                  }}
                  elevation={2}
                  key={user.id}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                      // border: "solid red 2px",
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{
                            bgcolor: "#FFB573",
                            width: 56, // Increase width
                            height: 56, // Increase height
                            fontSize: "1.5rem",
                            // border: "solid red 1px",
                          }}
                          aria-label="employee"
                        >
                          {user.first_name[0]}
                        </Avatar>
                      }
                      action={
                        <>
                          <IconButton
                            aria-label="settings"
                            onClick={(e) => handleClick(e, user.id)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl?.anchor}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            elevation={2}
                          >
                            <MenuItem onClick={handleEditClick}>
                              <ListItemIcon>
                                <EditIcon fontSize="small" />
                              </ListItemIcon>
                              Edit
                            </MenuItem>
                            <MenuItem
                              onClick={() => handleDeleteClick(anchorEl.id)}
                            >
                              <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                              </ListItemIcon>
                              Delete
                            </MenuItem>
                          </Menu>
                        </>
                      }
                      title={
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            fontSize: { lg: "1.05rem", xs: "0.95rem" },
                            fontWeight: "bold",
                            letterSpacing: "0.0075em",
                            fontFamily: "'Montserrat', sans-serif",
                            textAlign: "start",
                          }}
                        >
                          {user.first_name} {user.last_name}
                        </Typography>
                      }
                      subheader={
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          {/* <PersonIcon fontSize="small" /> */}
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: "1rem",
                              // fontWeight: "bold",
                              letterSpacing: "0.0075em",
                              fontFamily: "'Montserrat', sans-serif",
                            }}
                          >
                            {user.role}
                          </Typography>
                        </Box>
                      }
                    />
                    <Divider />

                    <CardContent>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <EmailIcon sx={{ mr: 1 }} fontSize="small" />
                        {user.email}
                      </Typography>
                      <Divider sx={{ marginY: 1, gap: 1 }} />
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <PhoneIcon sx={{ mr: 1 }} fontSize="small" />
                        {user.phone}
                      </Typography>
                      <Divider sx={{ marginY: 1, gap: 1 }} />
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <CakeIcon sx={{ mr: 1 }} fontSize="small" />
                        {user.birthdate}
                      </Typography>
                    </CardContent>

                    <Fab
                      color="primary" // You can choose 'primary', 'secondary' or 'inherit'
                      onClick={() => {
                        handleCreateClick();
                      }}
                      sx={{
                        width: 66, // Set width for circular shape
                        height: 66, // Set height for circular shape
                        color: "white",
                        backgroundColor: "#000000", // Adjust the variable or replace with a literal color value
                        "&:hover": {
                          backgroundColor: "#e3a902",
                          boxShadow: "none",
                        },
                        "&:active": {
                          backgroundColor: "#424242",
                        },
                        position: "fixed", // Position fixed to make it floating
                        bottom: { lg: 35, xs: 85 }, // Distance from the bottom
                        right: { lg: 220, xs: 35 }, // Distance from the right
                        zIndex: 1000, // Ensure it's above other elements
                        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)", // Adjusted for lighter shadow
                      }}
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                </Card>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "500px",
                  width: "500px",
                  // mt: "10rem",
                  // border: "solid red 1px",
                }}
              >
                <h2>No data</h2>
              </div>
            )}
          </>
        )}
      </Box>
      <SnackbarAlert
        open={openIt}
        onClose={handleClose}
        severity={severity}
        message={message}
      />
    </Box>
  );
};

export default EmployeeList;
