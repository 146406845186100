import React from "react";
import { ResponsivePie } from "@nivo/pie";

const PieChart = ({ data, isDetailedChart, isMinimal }) => {
  const AverageProductivityTooltip = ({ datum }) => {
    // Check if 'datum.type' indicates the data should be treated as a date
    if (datum.type === "date") {
      const tooltipDate = new Date(datum.id);
      const isValidDate = !isNaN(tooltipDate.getTime());

      if (isValidDate) {
        // Formatting the date
        const formattedDate = tooltipDate.toLocaleString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
        });

        // Formatting the value as NOK currency
        const formattedValue = new Intl.NumberFormat("nb-NO", {
          style: "currency",
          currency: "NOK",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(datum.value);

        return (
          <div
            style={{
              background: "white",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <p style={{ fontWeight: 700, margin: "0 0 5px 0" }}>
              {formattedDate}
            </p>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                color: datum.color,
                margin: "0",
              }}
            >
              <strong>{formattedValue}</strong>
            </p>
          </div>
        );
      }
    }

    // For non-date types, assume numeric or percentage
    const formattedPercentage = `${datum.value.toFixed(1)}%`;
    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
        }}
      >
        <p style={{ margin: 0, fontWeight: 700, color: datum.color }}>
          <strong>
            {datum.id}: {formattedPercentage}
          </strong>
        </p>
      </div>
    );
  };

  const colors = [
    // "#E63946",
    // "#40E0D0",
    // "#4169E1",
    // "#bcbd22",
    // "#2ca02c",
    // "#d62728",
    // "#9467bd",
    // "#8c564b",
    // "#e377c2",
    // "#7f7f7f",
    // "#17becf",
    // "#11E8DA", // Turquoise
    "#BA43B4", // Fuchsia
    // "#111052", // Dark Blue
    // "#2BC81C", // Bright Green
    // "#530C8E", // Purple
    // "#0970F6", // Bright Blue
    // "#6755DA", // Medium Purple
    // "#DD7C6C", // Salmon
    // "#B2A30B", // Olive
    "#563C8A", // Indigo
    // "#A3E4E8", // Light Blue

    "#E191B1", // Light Pink
    "#B783FD", // Lavender
    "#E5B6DE", // Pale Pink
    "#70335D", // Dark Plum

    // "#65D822", // Lime Green

    "#E390AA", // Pink
    "#C0C524", // Olive Green
    "#2A5F8B", // Steel Blue

    "#9FCAEB", // Sky Blue
    "#B69AA7", // Muted Pink
    "#0199C6", // Blue

    // "#E8BD42", // Mustard Yellow
    "#AA26FE", // Bright Purple
    "#55627B", // Slate Grey
    "#91FAE0", // Turquoise
    "#2FEBAA", // Mint Green
    "#B42DA3", // Magenta

    "#05E6C4", // Bright Turquoise
  ];
  const formatLabel = (value) =>
    value.length > 10 ? `${value.substring(0, 10)}...` : value;
  return !data || data.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    <ResponsivePie
      data={data}
      margin={
        isMinimal
          ? { top: 40, right: 80, bottom: 80, left: 80 }
          : { top: 30, right: 70, bottom: 70, left: 70 }
      }
      innerRadius={0.5}
      padAngle={0.7}
      theme={{
        fontSize: "0.8rem",
      }}
      colors={colors}
      cornerRadius={3}
      tooltip={({ datum }) => <AverageProductivityTooltip datum={datum} />}
      activeOuterRadiusOffset={8}
      borderWidth={0}
      borderColor={{
        from: "color",
        modifiers: [["darker", 2.2]],
      }}
      sortByValue={true}
      enableArcLabels={false}
      enableArcLinkLabels={true}
      arcLinkLabelsSkipAngle={15}
      arcLinkLabel={(e) => `${formatLabel(e.id)}: ${e.value.toFixed(1)}%`}
      arcLinkLabelsStraightLength={10}
      arcLinkLabelsTextOffset={5}
      arcLinkLabelsDiagonalLength={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={isMinimal ? 2 : 2} // Set to 0 when isMinimal
      arcLabel={(d) => (d.value >= 5 ? d.id : "")} // Conditionally return the name based on the value
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 8.8]],
      }}
      legends={
        isDetailedChart
          ? [
              {
                anchor: "right",
                direction: "column",
                justify: false,
                translateX: 2,
                translateY: 45,
                itemsSpacing: 20,
                itemDirection: "left-to-right",
                itemWidth: 109,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : ""
      }
    />
  );
};

export default PieChart;
