import React, { useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { getCompanyColors } from "../CompanyUtils/CompanyColor";
import formatNumber from "../../utils/FormatNumber";
import dayjs from "dayjs";

const latest_created_at = localStorage.getItem("latest_records");

const ComparisonHourlyChart = ({
  data,
  startDate,
  endDate,
  // isCombinedChart = false,s
  isDailyData = false,
  isNotPercentage = false,
  isMinimal = false,
  isXSScreen = false,
  hasPredictionLine = false,
  predictionLineDate = null,
  isMultiRestaurant = false,
  isStaffChart = false,
  isEmployeeChart = false,
  isProfitGraph = false,
}) => {
  // const [activeId, setActiveId] = useState(null);
  const [hiddenIds, setHiddenIds] = useState([]);

  const openingHours = [
    { start: "06:00", end: "06:00" }, // Sunday
    { start: "06:00", end: "06:00" }, // Monday
    { start: "06:00", end: "06:00" }, // Tuesday
    { start: "06:00", end: "06:00" }, // Wednesday
    { start: "06:00", end: "06:00" }, // Thursday
    { start: "06:00", end: "06:00" }, // Friday
    { start: "06:00", end: "06:00" }, // Saturday
  ];

  const format = !isNotPercentage ? (v) => `${v}` : (v) => `${v}`;

  function formatSerieId(serieId) {
    return serieId
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // let interval;
  let newVar = data ? data : null;
  if (isMultiRestaurant) {
    newVar = [];
    for (const property in data) {
      newVar.push(data[property]);
    }
  }
  // if (isDailyData) {
  //   interval = 2;
  // } else {
  //   interval = isXSScreen ? 6 : 2;
  // }
  if (newVar && newVar.length > 0) {
    newVar = newVar.map((series) => {
      // Assuming `actualDate` is a representative date for the series to extract the year
      const representativeDate = new Date(series.data[0]?.actualDate);
      const year = representativeDate.getFullYear();
      return {
        ...series,
        id: `${year}`, // Set the series ID to the year
      };
    });
  }
  const colors = getCompanyColors();

  if (newVar && newVar.length > 0) {
    newVar = newVar.map((series) => {
      return {
        ...series,
        data: series.data.map((point) => {
          return {
            ...point,
            y: isNaN(point.y) ? 0 : point.y,
          };
        }),
      };
    });
  }

  let minYValue;
  let maxYValue;
  if (newVar && newVar.length > 0) {
    // Extract all y-values from all data series and filter out NaN values
    const allYValues = newVar.flatMap((series) =>
      series.data.map((point) => point.y).filter((y) => !isNaN(y))
    );
    // Check if there are any valid values left after filtering
    if (allYValues.length > 0) {
      // Find the minimum and maximum y-values from the filtered array
      minYValue = Math.min(...allYValues);
      maxYValue = Math.max(...allYValues);
      if (minYValue === maxYValue) {
        minYValue = 0;
      }
    } else {
      // Default values if no valid data is available
      minYValue = 0;
      maxYValue = 0;
    }
  } else {
    // Default values if no data is available
    minYValue = 0;
    maxYValue = 0;
  }

  minYValue = minYValue.toFixed(0);
  maxYValue = maxYValue.toFixed(0);

  if (maxYValue < 10) {
  } else if (isNotPercentage) {
    maxYValue =
      maxYValue < 1000
        ? Math.round(maxYValue / 100) * 100
        : maxYValue < 9000
        ? Math.round(maxYValue / 1000) * 1000
        : Math.round(maxYValue / 10000) * 10000;
  } else {
    // For percentages or other units
    maxYValue = Math.round(maxYValue / 10) * 10; // Adjust rounding logic as needed
  }

  function completeDayData(dataSets, openingHours) {
    return dataSets.map((dataset) => {
      const newData = [];
      const originalData = dataset.data;

      if (originalData.length > 0) {
        let firstPointDate = new Date(originalData[0]?.actualDate);

        if (isNaN(firstPointDate.getTime())) {
          console.error("Invalid date:", originalData[0]?.actualDate);
          return dataset;
        }

        // Determine the day of the week and get corresponding hours
        const dayOfWeek = firstPointDate.getDay();
        const hours = openingHours[dayOfWeek]; // assuming this exists and is correct

        // Start time setup based on opening hours
        let [startHour, startMinute] = hours.start.split(":").map(Number);
        let currentTime = new Date(
          firstPointDate.setHours(startHour, startMinute, 0, 0)
        );

        // End time setup based on opening hours
        let [endHour, endMinute] = hours.end.split(":").map(Number);
        let endTime = new Date(
          firstPointDate.setHours(endHour, endMinute, 0, 0)
        );
        endTime = new Date(endTime.getTime() - 1); // Adjust to be within the same day

        // In case end time is before start time, assume it goes to the next day
        if (endTime <= currentTime) {
          endTime = new Date(endTime.getTime() + 24 * 60 * 60 * 1000);
        }

        while (currentTime <= endTime) {
          const formattedTime = dayjs(currentTime).format("HH:mm");
          newData.push({
            x: formattedTime,
            y: "0",
            actualDate: new Date(currentTime), // Keep the actual date for reference
          });
          currentTime = new Date(currentTime.getTime() + 60 * 60 * 1000);
        }

        originalData.forEach((dataPoint) => {
          const dataPointHour = dayjs(dataPoint.actualDate).format("HH:mm");
          const index = newData.findIndex((point) => point.x === dataPointHour);
          if (index !== -1) {
            newData[index].y = dataPoint.y;
            newData[index].actualDate = dataPoint.actualDate;
          }
        });
      } else {
        console.log("No data available to determine the day's range.");
        return { ...dataset, data: [] };
      }

      return { ...dataset, data: newData };
    });
  }

  const finalData = completeDayData(newVar, openingHours);
  let filteredData = finalData?.filter(
    (serie) => !hiddenIds.includes(serie.id)
  );

  const pointTooltip = ({ point }) => {
    const date = new Date(point.data.actualDate);
    const formattedDate =
      `${date.toLocaleString("default", { weekday: "long" })}, ` + // Adds weekday
      `${String(date.getDate()).padStart(2, "0")} ` +
      `${date.toLocaleString("default", { month: "short" })}, ` +
      `${date.getFullYear()} ` + // Adds the year
      `${String(date.getHours()).padStart(2, "0")}:` +
      `${String(date.getMinutes()).padStart(2, "0")}`;

    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",

          // position: 'absolute',
          // left: `${point.x - 50}px`, // Adjust the left position based on the point’s x-coordinate
          // top: `${point.y + 20}px`, // Adjust the top position to place the tooltip below the point
        }}
      >
        <strong>{formattedDate}</strong>
        <br />
        <strong style={{ color: point.serieColor }}>
          {formatSerieId(point.serieId)}:{" "}
          {isNotPercentage
            ? formatNumber(Math.round(point.data.y))
            : `${point.data.y} units`}{" "}
          <br />
          {point.data.comment && (
            <strong style={{ color: "black" }}>
              Comment: {point.data.comment}
              <br />
            </strong>
          )}
        </strong>
      </div>
    );
  };

  function getUniqueTickValues(dataSets) {
    const seen = new Set();
    const uniqueTickValues = [];

    dataSets?.forEach((series) => {
      series.data?.forEach((point) => {
        if (!seen.has(point.x)) {
          seen.add(point.x);
          uniqueTickValues.push(point.x);
        }
      });
    });

    return uniqueTickValues;
  }

  function convertTickValuesToDateObjects(tickValues) {
    return tickValues.map((time) => {
      const [hours, minutes] = time.split(":");
      const date = new Date();
      date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
      return date;
    });
  }

  // Assuming your data variable is an array of series, each having a data array
  const tickValues = getUniqueTickValues(newVar);
  const dateObjects = convertTickValuesToDateObjects(tickValues);

  const xScale = isProfitGraph
    ? {
        type: "point",
      }
    : {
        type: "time",
        format: "%H:%M",
        // min: "9:00",
        // max: "24:00",
        // max: "3:00",
        // precision: "hour",
      };

  const axisBottom = isProfitGraph
    ? null
    : {
        format: "%H:%M", // or any other format you want
        tickValues: dateObjects,
      };

  return !newVar || newVar.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    <ResponsiveLine
      animate
      enableGridX={!isMinimal}
      enableGridY={isMinimal}
      gridYValues={[0, minYValue, maxYValue]}
      xScale={xScale}
      axisBottom={axisBottom}
      axisLeft={{
        tickValues: [0, minYValue, isStaffChart ? 70 : maxYValue],
        format,
      }}
      curve="monotoneX"
      colors={colors}
      data={filteredData}
      margin={
        isMinimal
          ? {
              bottom: 80,
              left: maxYValue > 100000 ? 70 : 60,
              right: 50,
              top: 60,
            }
          : {
              bottom: 60,
              left: 80,
              right: 20,
              top: 30,
            }
      }
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      lineWidth={1}
      pointBorderWidth={1}
      pointSize={4}
      tooltip={pointTooltip}
      enableArea={true}
      areaBaselineValue={0}
      areaOpacity={0.15}
      // labelFormat={format}
      useMesh
      markers={[
        ...(isStaffChart
          ? [
              {
                axis: "y",
                value: 28, // Set the marker at 28% (0.28) on the y-axis
                legend: "Goal 28%",
                lineStyle: {
                  stroke: "gray",
                  strokeWidth: 2,
                },
              },
            ]
          : []),
        // Add the marker for todays date here
        hasPredictionLine && predictionLineDate
          ? {
              axis: "x", // Vertical line
              // legend: "prediction marker",
              value: new Date(latest_created_at),
              lineStyle: {
                stroke: "#505050", // Line color
                strokeWidth: 2, // Line width
              },

              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
      ]}
      yScale={{
        tickValues: [0, minYValue, maxYValue],
        min: minYValue < 0 ? minYValue : 0,

        max: isStaffChart ? 70 : "auto", // Set the calculated maxY as the max property
        type: "linear",
      }}
      legends={
        isMinimal
          ? [
              {
                anchor: "bottom",
                direction: "row",
                translateY: 30,
                itemsSpacing: 2,
                itemDirection: "left-to-right",
                itemWidth: 109,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                onClick: (datum) => {
                  setHiddenIds((currentHiddenIds) => {
                    const isCurrentlyHidden = currentHiddenIds.includes(
                      datum.id
                    );
                    const newHiddenIds = isCurrentlyHidden
                      ? currentHiddenIds.filter((id) => id !== datum.id)
                      : [...currentHiddenIds, datum.id];

                    // Ensure we don't hide all lines: if newHiddenIds length is equal to the number of lines, reset it
                    if (newHiddenIds.length >= newVar.length) {
                      return [];
                    }
                    return newHiddenIds;
                  });
                },
              },
            ]
          : [
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 2,
                translateY: 45,
                itemsSpacing: 10,
                itemDirection: "left-to-right",
                itemWidth: 109,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
      }
    />
  );
};

export default ComparisonHourlyChart;

// function convertTickValuesToDateObjects(tickValues) {
//   // Assuming tickValues are sorted and the first tick represents the base day
//   const baseDay = parseInt(tickValues[0].split(" ")[0], 10);

//   return tickValues.map((dateTime) => {
//     const [day, time] = dateTime.split(" "); // Split into day and time parts
//     const [hours, minutes] = time.split(":"); // Split time into hours and minutes
//     const dayOffset = parseInt(day, 10) - baseDay; // Calculate day offset from the base day

//     // Create a reference date (e.g., today, or a specific date representing the "start" of your data)
//     const referenceDate = new Date();
//     referenceDate.setDate(referenceDate.getDate() + dayOffset); // Adjust to the correct day using the offset
//     referenceDate.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0); // Set time

//     return referenceDate;
//   });
// }
