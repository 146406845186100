import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Grid, Tooltip, Zoom } from "@mui/material";

const OpeningHourCard = ({ weekDay, openingHour, closingHour }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        margin: ".1rem",
        width: {
          xs: "95%",
          lg: "90%",
        },
        borderRadius: "1rem",
        minHeight: { lg: "20%" },
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Montserrat",
        // border: "solid red 1px",
      }}
    >
      {/* <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          textAlign: "right",
          //   mt: "1rem",
          //   marginBottom: "0.5rem",
          fontSize: "1rem",
          fontFamily: "Montserrat",
          border: "solid red 1px",
        }}
      >
        {weekDay}
      </Typography> */}

      {/* <Box>
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Montserrat",
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          Opening: {openingHour}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Montserrat",
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          Closing: {closingHour}
        </Typography>
      </Box> */}

      <Grid container spacing={2} sx={{ padding: "1rem" }}>
        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              // border: "solid red 1px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                marginBottom: "0.5rem",
                fontSize: { lg: "1rem", xs: "0.8rem" },
                fontFamily: "Montserrat",
                textAlign: "left",
                width: "30%",
                // border: "solid red 1px",
              }}
            >
              {weekDay}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                fontFamily: "Montserrat",
                textAlign: "right",
                fontSize: { lg: "0.875rem", xs: "0.775rem" },

                fontWeight: 400,
                //   border: "solid red 1px",
              }}
            >
              Opening: {openingHour}
            </Typography>
            <Tooltip
              title={
                <Typography variant="body1">
                  The closing hour is rounded to the next full hour. So closing
                  at 03:30 will show up as 4 in the table, but this does not
                  affect sales nor predictions.
                </Typography>
              }
              arrow
              placement="top"
              TransitionComponent={Zoom}
            >
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Montserrat",
                  textAlign: "right",
                  fontSize: { lg: "0.875rem", xs: "0.775rem" },

                  fontWeight: 400,
                }}
              >
                Closing: {closingHour}
              </Typography>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

OpeningHourCard.propTypes = {
  weekDay: PropTypes.string.isRequired,
  openingHour: PropTypes.string.isRequired,
  closingHour: PropTypes.string.isRequired,
};

export default OpeningHourCard;
