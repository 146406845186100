import React from "react";
import { ResponsivePie } from "@nivo/pie";

const ProdPieChart = ({ data }) => {
  const AverageProductivityTooltip = ({ datum }) => {
    const formattedValue = `${datum.value.toFixed(1)}%`; // Assuming datum.value is the percentage value
    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
        }}
      >
        <p style={{ margin: 0, fontWeight: 700, color: datum.color }}>
          <strong>
            {datum.id}: {formattedValue}
          </strong>
        </p>
      </div>
    );
  };
  const colors = [
    "#BA43B4", // Fuchsia
    "#563C8A", // Indigo
    "#E191B1", // Light Pink
    "#B783FD", // Lavender
    "#E5B6DE", // Pale Pink
    "#70335D", // Dark Plum
    "#E390AA", // Pink
    "#C0C524", // Olive Green
    "#2A5F8B", // Steel Blue
    "#9FCAEB", // Sky Blue
    "#B69AA7", // Muted Pink
    "#0199C6", // Blue
    "#AA26FE", // Bright Purple
    "#55627B", // Slate Grey
    "#91FAE0", // Turquoise
    "#2FEBAA", // Mint Green
    "#B42DA3", // Magenta
    "#05E6C4", // Bright Turquoise
  ];

  // Calculate total net value
  const totalNet = data.reduce((acc, entry) => {
    Object.keys(entry).forEach((key) => {
      if (key !== "date" && key !== "Color") {
        acc += entry[key];
      }
    });
    return acc;
  }, 0);

  // Transform data for the Pie chart, converting values to percentages
  const pieData = data.reduce((acc, entry) => {
    Object.keys(entry).forEach((key) => {
      if (key !== "date" && key !== "Color") {
        const existing = acc.find((item) => item.id === key);
        if (existing) {
          existing.value += (entry[key] / totalNet) * 100;
        } else {
          acc.push({ id: key, value: (entry[key] / totalNet) * 100 });
        }
      }
    });
    return acc;
  }, []);

  const formatLabel = (value) =>
    value.length > 10 ? `${value.substring(0, 10)}...` : value;
  // ... your data processing remains unchanged
  return !pieData || pieData.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <h2> No data </h2>
    </div>
  ) : (
    <ResponsivePie
      data={pieData}
      colors={colors}
      margin={{ top: 30, right: 70, bottom: 70, left: 70 }}
      theme={{
        fontSize: "0.8rem",
      }}
      borderWidth={0}
      borderColor={{
        from: "color",
        modifiers: [["darker", 2.2]],
      }}
      enableArcLabels={false}
      tooltip={AverageProductivityTooltip}
      innerRadius={0.5}
      enableArcLinkLabels={true}
      padAngle={0.7}
      sortByValue={true}
      arcLinkLabel={(e) => `${formatLabel(e.id)}: ${e.value.toFixed(1)}%`}
      arcLinkLabelsSkipAngle={15}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsStraightLength={10}
      arcLinkLabelsTextOffset={5}
      arcLinkLabelsDiagonalLength={10}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabel={(d) => (d.value >= 5 ? `${d.id}: ${d.value.toFixed(1)}%` : "")}
      arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
    />
  );
};

export default ProdPieChart;
