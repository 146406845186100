import React from "react";
import { Typography } from "@mui/material";
import TooltipCreate from "../Tooltips/Tooltip";

const TitleStyle = ({ tooltipKey, content, children, style, position }) => {
  const defaultStyles = {
    my: "0.5rem",
    fontWeight: 500,
    fontFamily: "'Montserrat', sans-serif",
    flexGrow: 1,
  };
  return (
    <TooltipCreate
      tooltipKey={tooltipKey}
      content={content}
      position={position}
    >
      <Typography variant="h6" sx={{ ...defaultStyles, ...style }}>
        {children}
      </Typography>
    </TooltipCreate>
  );
};

export default TitleStyle;
