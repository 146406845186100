import { React, useState, useEffect, useCallback } from "react";
import {
  //  Select, MenuItem,
  InputLabel,
  Box,
  Grid,
  // Link,
  Checkbox,
  // FormControlLabel,
  TextField,
  CssBaseline,
  // Button,
  Avatar,
  Typography,
  // Container,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useParams } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../utils/SnackbarAlert";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import PersonIcon from "@mui/icons-material/Person"; // Import the icon you want to use
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import StyledButton from "../components/Style/ButtonStyle";

// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="https://krunch.no/">
//         Krunch
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }
let prefix = "";
if (process.env.NODE_ENV === "development") {
  prefix = "http://localhost:8000";
}

const ITEM_HEIGHT = "1rem";
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EmployeeCreate() {
  // const [employeeGroups, setEmployeeGroups] = React.useState([]);
  const [restaurants, setRestaurants] = useState([]);
  // const [restaurantNames, setRestaurantNames] = useState([])
  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [birthdate, setBirthdate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  const [emp, setEmp] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const { id } = useParams();

  // const [companyInfo, setCompanyInfo] = useState("")

  // const [isMultiselectVisible, setIsMultiselectVisible] = useState(true);

  const [primaryRestaurant, setPrimaryRestaurant] = useState("");
  const handleClose = () => {
    setOpen(false);
  };

  // const handleCompanyChange = (event) => {
  //   setCompanyInfo(event.target.value)
  // }
  // const handleDefaultRestaurantChange = (event) => {
  //   setDefaultRestaurant(event.target.value)
  // }

  // const handlePrimaryRestaurantChange = (event) => {
  //   setPrimaryRestaurant(event.target.value)
  // }

  // const setCookie = (cookieKey, cookieValue, expirationDays) => {
  //   let expiryDate = ""

  //   if (expirationDays) {
  //     const date = new Date()
  //     date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000)
  //     expiryDate = `; expires=${date.toUTCString()}`
  //   }

  //   document.cookie = `${cookieKey}=${encodeURIComponent(
  //     cookieValue
  //   )}${expiryDate}; path=/`
  // }

  const fetchData = useCallback(async () => {
    const restaurant_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const restaurant_data = await restaurant_response.json();
    if (restaurant_response.ok) {
      // Filter out "Fisketorget Stavanger"
      const filteredRestaurants = restaurant_data.results.filter(
        (restaurant) => restaurant.name !== "Fisketorget Stavanger"
      );

      // Sort restaurants such that those with "Food Truck" in their name are at the end
      const sortedRestaurants = filteredRestaurants.sort((a, b) => {
        const aIsFoodTruck = a.name.includes("Food Truck");
        const bIsFoodTruck = b.name.includes("Food Truck");

        if (aIsFoodTruck && !bIsFoodTruck) {
          return 1;
        }
        if (!aIsFoodTruck && bIsFoodTruck) {
          return -1;
        }
        return 0;
      });

      setRestaurants(sortedRestaurants);
    } else {
      setOpen(true);
      setSeverity("success");
      setMessage("Employee Deleted!");
    }
  }, [token, company]);

  const customTheme = createTheme({
    components: {
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          root: {
            transform: "translateX(-40px)",
            width: "70px",
            "&:hover": {
              width: "70px",
            },
          },
        },
      },
      MuiInputLabel: {
        // Target the InputLabel component
        styleOverrides: {
          root: {
            // Apply to the root style of the component
            color: "rgba(0, 0, 0, 0.35)", // Placeholder-like color
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit", // Reset the border color
            },
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          labelContainer: {
            padding: "0 10px", // Reduce padding, adjust as needed
            fontSize: "0.875rem", // Reduce font size, adjust as needed
          },
          switchViewButton: {
            width: "50px", // Replace 'initialWidth' with your normal width value
            "&:hover": {
              width: "50px", // Replace 'smallerHoverWidth' with your desired hover width value
            },
          },
        },
      },
    },
  });

  const fetchUser = useCallback(
    async (userId) => {
      try {
        const user_response = await fetch(`${prefix}/api/users/${userId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });
        const user_data = await user_response.json();
        setEmp(user_data);
        // setSelectedRestaurants(
        //   user_data.restaurants.map((restaurant) => restaurant)
        // )
        if (Array.isArray(user_data.restaurants)) {
          setSelectedRestaurants(
            user_data.restaurants.map((restaurant) => restaurant)
          );
        } else {
          setSelectedRestaurants([]);
        }

        setPrimaryRestaurant(user_data.primary_restaurant);
        if (user_data.birthdate) {
          const adjustedDate = dayjs(user_data.birthdate);
          const originalDate = adjustedDate.subtract(
            adjustedDate.utcOffset(),
            "minute"
          );
          const formattedDate = originalDate.toDate();
          setSelectedDate(dayjs(formattedDate));
        } else {
          setSelectedDate(""); // Explicitly setting to null when no valid date is present
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching user data:", error);
      }
    },
    [token]
  );

  useEffect(() => {
    if (id) {
      fetchUser(id);
    } else {
      fetchUser();
      fetchData();
    }
    fetchData();
  }, [fetchData, id, fetchUser]);

  // useEffect(() => {
  //   fetchUser();
  //   fetchData();
  // }, [fetchData, fetchUser]);

  const handleEmpChange = (e) => {
    setEmp({ ...emp, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    if (id) {
      // Validation for an existing employee (updating information)
      // Assume some fields are optional in updates
      if (
        !emp.first_name ||
        !emp.last_name ||
        !emp.email ||
        !emp.phone ||
        !emp.role
      ) {
        setMessage("All fields must be filled correctly.");
        setOpen(true);
        setSeverity("error");
        return; // Exit the function if validation fails
      }
    } else {
      // Validation for a new employee (creating a new entry)
      if (
        !emp.first_name ||
        !emp.last_name ||
        !emp.email ||
        !emp.password ||
        !birthdate ||
        !emp.phone ||
        selectedRestaurants.length === 0 ||
        !emp.role
      ) {
        setMessage("All fields must be filled correctly.");
        setOpen(true);
        setSeverity("error");
        return; // Exit the function if validation fails
      }
    }

    const formData = {
      email: emp.email,
      first_name: emp.first_name,
      last_name: emp.last_name,
      birthdate: birthdate || null,
      phone: emp.phone,
      default_restaurants: selectedRestaurants.map(
        (restaurant) => restaurant.id
      ),
      primary_restaurant: primaryRestaurant,
      hourly_pay: Number(emp.hourly_pay) || 0,
      company_admin: emp.role === "admin",
      role: emp.role,
      password: emp.password,
      company: company,
      id: id, // Include id for updates
    };

    try {
      const method = id ? "PUT" : "POST"; // Use PUT for update, POST for create
      const endpoint = id
        ? `${prefix}/api/users/${id}/`
        : `${prefix}/api/users/registration/`; // Adjust the endpoint if necessary

      const response = await fetch(endpoint, {
        method: method,
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json(); // Always parse the JSON to get the error message

      if (response.ok) {
        // Handling success
        setMessage(
          id ? "Employee Updated Successfully" : "Employee Created Successfully"
        );
        setSeverity("success");
        navigate("/employee_list", { replace: true });
      } else {
        // Handling errors
        if (!id && data.error && data.error === "User Already Exists") {
          // For new entries, report back if email is already in use
          setMessage("A user with this email already exists.");
          setSeverity("error");
        } else {
          // General error handling
          setMessage("Failed to process request. Please try again.");
          setSeverity("error");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Network error. Please try again.");
      setSeverity("error");
      setOpen(true);
    } finally {
      setOpen(true); // Ensure the message modal is shown
    }
  };

  // function handleErrors(data, id) {
  //   let errorMessage = "Failed to process request.";
  //   if (data && data.error) {
  //     if (id) {
  //       errorMessage = "Failed to update employee.";
  //     } else if (
  //       data.error.email &&
  //       data.error.email.includes("already in use")
  //     ) {
  //       errorMessage = "A user with this email already exists.";
  //     }
  //   }

  //   setMessage(errorMessage);
  //   setSeverity("error");
  // }

  // const handleSubmit = async (event) => {
  //   event.preventDefault(); // Prevent default form submission

  //   // Start by validating all required fields
  //   if (id) {
  //     // Validation for an existing employee (updating information)
  //     // Exit the function if validation fails
  //   } else {
  //     // Validation for a new employee (creating a new entry)
  //     if (
  //       !emp.first_name ||
  //       !emp.last_name ||
  //       !emp.email ||
  //       !emp.password ||
  //       !birthdate ||
  //       !emp.phone ||
  //       selectedRestaurants.length === 0 ||
  //       !emp.role
  //     ) {
  //       setMessage("All fields must be filled correctly.");
  //       setOpen(true);
  //       setSeverity("error");
  //       return; // Exit the function if validation fails
  //     }
  //   }

  //   const formData = {
  //     email: emp.email,
  //     first_name: emp.first_name,
  //     last_name: emp.last_name,
  //     birthdate: birthdate || null,
  //     phone: emp.phone,
  //     default_restaurants: selectedRestaurants.map(
  //       (restaurant) => restaurant.id
  //     ),
  //     primary_restaurant: primaryRestaurant,
  //     hourly_pay: Number(emp.hourly_pay) || 0,
  //     company_admin: emp.role === "admin",
  //     role: emp.role,
  //     password: emp.password,
  //     company: company,
  //   };

  //   try {
  //     const response = await fetch(`${prefix}/api/users/registration/`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Token ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     const data = await response.json(); // Always parse the JSON to get the error message

  // if (response.ok) {
  //   setMessage("Employee Created Successfully");
  //   setSeverity("success");
  //   navigate("/employee_list", { replace: true });
  // } else {
  //   // Assuming 'id' indicates if we are updating an existing employee
  //   if (
  //     id
  //     // data.error
  //     // data.error.email &&
  //     // data.error.email.includes("already in use")
  //   ) {
  //     // If updating, and the error is about the email being in use, ignore it or handle it differently
  //     setMessage("Employee updated successfully"); // Assuming the email conflict is managed by the backend appropriately
  //     setSeverity("success");
  //     navigate("/employee_list", { replace: true });
  //   } else if (
  //     !id &&
  //     data.error &&
  //     data.error.email &&
  //     data.error.email.includes("already in use")
  //   ) {
  //     // If creating a new employee and the email is already in use
  //     setMessage("A user with this email already exists.");
  //     setSeverity("error");
  //       } else {
  //         // For other errors
  //         setMessage("Failed to process request.");
  //         setSeverity("error");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     setMessage("Network error. Please try again.");
  //     setSeverity("error");
  //   } finally {
  //     setOpen(true);
  //   }
  // };

  const handlePrimaryRestaurantSelect = (event) => {
    setPrimaryRestaurant(event.target.value);
  };

  // const handleCompanyAdmin = (event) => {
  //   // setIsMultiselectVisible(event.target.checked);

  //   setEmp((prevEmp) => ({
  //     ...prevEmp,
  //     company_admin: !prevEmp.company_admin,
  //   }));
  // };

  const handleRestaurantSelect = (event) => {
    const value = event.target.value;
    // For multi-select
    if (Array.isArray(value)) {
      const selectedRestaurantsList = restaurants.filter((restaurant) =>
        value.includes(restaurant.id)
      );
      setSelectedRestaurants(selectedRestaurantsList);
    }
    // For single select
    else {
      const selectedRestaurant = restaurants.find(
        (restaurant) => restaurant.id === value
      );
      setSelectedRestaurants(selectedRestaurant ? [selectedRestaurant] : []);
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);

    const birthdate = dayjs(date.$d);
    const adjustedDate = birthdate.add(birthdate.utcOffset(), "minute");
    setBirthdate(adjustedDate.format("YYYY-MM-DD"));
  };
  const handleReset = () => {
    setEmp({
      first_name: "",
      last_name: "",
      email: "",
      password: "", // Only if applicable, as password might not be displayed for updates
      phone: "",
      role: "",
      hourly_pay: 0, // Reset to default or remove if not used
      company_admin: false, // Reset to default or remove if not used
    });
    setSelectedDate(null);
    setSelectedRestaurants([]);
    setPrimaryRestaurant("");
    setBirthdate("");
    setOpen(true); // Ensure the snackbar is opened
    setMessage("Form has been reset."); // Correct message
    setSeverity("info");
  };

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event
  //   setRestaurantNames(typeof value === "string" ? value.split(",") : value)
  //   setSelectedRestaurants(restaurantNames)
  // }
  return (
    <ThemeProvider theme={customTheme}>
      {/* {console.log("selected restaurants", selectedRestaurants)} */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: { lg: "center", xs: "flex-start" },
            alignItems: "center",
            minHeight: "100vh",
            width: "100%",
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              maxWidth: { lg: "600px", xs: "400px" },
              boxShadow: "0px 5px 5px 3px rgba(0,0,0,0.12)", // Adds shadow to the form box
              borderRadius: 2, // Rounded corners for the form box
              backgroundColor: "background.paper",
              maxHeight: "800px",
              mt: 2,

              // border: "1px solid red",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                maxWidth: { lg: "600px", xs: "400px" },
                padding: 4,
                height: { lg: "700px", xs: "95vh" },
                // boxShadow: "0 3px 5px 2px rgba(0,0,0,0.12)", // Adds shadow to the form box
                borderRadius: 2, // Rounded corners for the form box
                backgroundColor: "background.paper",
                // border: "solid red 1px",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "#CC9600" }}>
                <PersonAddIcon />
              </Avatar>
              <Typography
                // component="h1"
                variant="h5"
                sx={{ mb: 3, fontFamily: "Montserrat", fontWeight: 600 }}
              >
                {id ? "Update Employee Info" : "Create An Employee"}
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="first_name"
                      placeholder="First Name"
                      name="first_name"
                      autoComplete="given-name"
                      autoFocus
                      value={emp.first_name || ""}
                      onChange={handleEmpChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="last_name"
                      placeholder="Last Name"
                      name="last_name"
                      autoComplete="family-name"
                      value={emp.last_name || ""}
                      onChange={handleEmpChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      placeholder="Email Address"
                      name="email"
                      autoComplete="email"
                      value={emp.email || ""}
                      onChange={handleEmpChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {!id && (
                    <Grid item xs={12} lg={6}>
                      <TextField
                        required
                        fullWidth
                        name="password"
                        placeholder="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        onChange={handleEmpChange}
                        value={emp.password || ""}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <HttpsOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <DatePicker
                      required
                      fullWidth
                      name="birthdate"
                      label="Fødselsdato"
                      id="birthdate"
                      // defaultValue={formData.first_name}
                      onChange={(value) => handleDateSelect(value)}
                      value={selectedDate}
                      format="DD/MM/YYYY"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            style: { color: "rgba(0, 0, 0, 0.35)" }, // Placeholder-like color
                          }}
                          // This will remove the red color when there is an error
                          error={false}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      name="phone"
                      type="number"
                      placeholder="Telefonnummer"
                      id="phone"
                      value={emp.phone || ""}
                      onChange={handleEmpChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalPhoneOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="role-select-label">
                        Company Role
                      </InputLabel>{" "}
                      {/* Ensure this is added */}
                      <Select
                        labelId="role-select-label" // This should match the id of the InputLabel
                        id="role-select"
                        value={emp.role || ""} // Adjust based on how you're storing the role in your 'emp' state
                        label="Company Role" // Make sure to include this so the label and select are linked
                        onChange={(event) => {
                          const newRole = event.target.value;
                          setEmp((prevEmp) => ({ ...prevEmp, role: newRole }));
                        }}
                      >
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="manager">Manager</MenuItem>
                        <MenuItem value="basic">Basic</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* Render Default Restaurants dropdown */}
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Default Restaurants
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedRestaurants.map((r) => r.id)}
                        onChange={handleRestaurantSelect}
                        input={<OutlinedInput label="Default Restaurants" />}
                        renderValue={(selectedIds) =>
                          selectedIds
                            .map(
                              (id) => restaurants.find((r) => r.id === id)?.name
                            )
                            .join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        {restaurants.map((restaurant) => (
                          <MenuItem key={restaurant.id} value={restaurant.id}>
                            <Checkbox
                              checked={selectedRestaurants.some(
                                (r) => r.id === restaurant.id
                              )}
                            />
                            <ListItemText primary={restaurant.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Conditional rendering for Primary Restaurant Dropdown */}
                  {!emp.company_admin && selectedRestaurants.length > 0 && (
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      <FormControl fullWidth>
                        <InputLabel id="primary-restaurant-label">
                          Primary Restaurant
                        </InputLabel>
                        <Select
                          labelId="primary-restaurant-label"
                          id="primary-restaurant"
                          value={primaryRestaurant}
                          onChange={handlePrimaryRestaurantSelect}
                          input={<OutlinedInput label="Primary Restaurants" />}
                        >
                          {selectedRestaurants.map((restaurant) => (
                            <MenuItem key={restaurant.id} value={restaurant.id}>
                              {restaurant.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                {/* Other fields remain unchanged */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <StyledButton
                    onClick={handleReset}
                    style={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: "#FFE699",
                      "&:hover": {
                        backgroundColor: "#D8C482",
                      },
                      width: "8rem",
                      fontSize: "15px",
                    }}
                  >
                    Reset
                  </StyledButton>

                  <StyledButton
                    onClick={handleSubmit}
                    style={{
                      mt: 3,
                      mb: 2,
                      // backgroundColor: "#FFE699",
                      // "&:hover": {
                      //   backgroundColor: "#D8C482",
                      // },
                      width: "8rem",
                      // color: "black",
                      fontSize: "15px",
                    }}
                  >
                    {id ? "Update" : "Create"}
                  </StyledButton>
                </Box>
              </Box>
            </Box>
            {/* 
            <Box
              sx={{
                // marginTop: 2,
                display: { xs: "none", md: "flex" }, // Hide on xs, show on md+
                flexDirection: "row",
                alignItems: "flex-end",
                width: "100%",
                maxWidth: { lg: "600px", xs: "400px" },
                // boxShadow: "0 3px 5px 2px rgba(0,0,0,0.12)", // Adds shadow to the form box
                backgroundColor: "background.paper",
                height: "100%",
                borderRadius: 2,
                // border: "10px solid #FFBD00",
                // backgroundClip: "padding-box",

                // border: "1px solid red",
              }}
            >
              <Box
                sx={{
                  width: "100%", // Image takes roughly the other half
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",

                  height: "100%",
                }}
              >
                <img
                  src="Register.jpg" // Replace with your image path
                  alt="Descriptive Alt Text"
                  style={{
                    width: "100%",
                    height: "700px",
                    // borderRadius: "4px",
                  }}
                />
              </Box>
            </Box> */}
          </Box>
        </Box>
        <SnackbarAlert
          open={open}
          onClose={handleClose}
          severity={severity}
          message={message}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
