import { React, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  styled,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../utils/SnackbarAlert";
import Colors from "../utils/Colors";
import DeleteIcon from "@mui/icons-material/Delete";

let prefix = "";

if (process.env.NODE_ENV === "development") {
  prefix = "http://127.0.0.1:8000";
}
const LocationDialog = ({
  data,
  open,
  onClose,
  title,
  listLocationColumns,
}) => {
  const [selectedLocationRows, setSelectedLocationRows] = useState([]);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleSelectionLocation = (selectionModel) => {
    setSelectedLocationRows(selectionModel);
  };

  const handleCancel = () => {
    onClose();
  };
  const handleDeleteConfirmation = () => {
    if (open) {
      deleteSelectedRowsLocation();
    }
  };
  const deleteSelectedRowsLocation = async () => {
    try {
      const response = await fetch(`${prefix}/api/location/delete_location`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({ ids: selectedLocationRows }),
      });
      if (response.ok) {
        // setOpen(true)
        setSeverity("success");
        setMessage("Location Deleted Successfully");
        navigate(0);
      } else {
        // setOpen(true)
        setSeverity("error");
        setMessage("Error Deleting Location");
      }
    } catch (error) {
      console.error("Error:", error);
      // setOpen(true)
      setSeverity("error");
      setMessage("An error occurred during deletion");
    }
  };

  const deleteLocationDialogActions = (
    <>
      <Button
        onClick={handleCancel}
        sx={{
          backgroundColor: Colors.delete,
          color: "white",
          "&:hover": {
            backgroundColor: Colors.deleteHover, // Replace with your hover color
          },
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={handleDeleteConfirmation}
        sx={{
          backgroundColor: Colors.positive,
          color: "white",
          "&:hover": {
            backgroundColor: Colors.positiveHover, // Replace with your hover color
          },
        }}
        startIcon={<DeleteIcon />}
      >
        Delete
      </Button>
    </>
  );
  const StyledDataGrid = styled(DataGrid)({
    ".MuiCheckbox-root": {
      color: "black",
    },

    ".MuiTablePagination-root": {
      width: "100%", // Adjust this value as needed
    },
    ".MuiTablePagination-actions": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center", // Adjust this to align as needed
    },
    ".MuiIconButton-root": {
      width: "30px", // Adjust this value as needed
      margin: "0 5px", // Adds space between buttons
    },
  });
  // console.log('Data:', data);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "auto", // Default to auto if size.width is not provided
            height: "auto", // Default to auto if size.height is not provided
            maxWidth: "none",
            minWidth: "30%", // This will ensure it can take the full width if needed
          },
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div style={{ height: 400, width: "100%" }}>
            <StyledDataGrid
              rows={data}
              columns={listLocationColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              rowSelectionModel={selectedLocationRows}
              onRowSelectionModelChange={handleSelectionLocation}
            />
          </div>
        </DialogContent>
        <DialogActions>{deleteLocationDialogActions}</DialogActions>
      </Dialog>
      <SnackbarAlert severity={severity} message={message} />
    </>
  );
};
export default LocationDialog;
