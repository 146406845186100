const formatDate = (momentObject) => {
  const date = momentObject.$d;
  if (!date) return null;
  const year = date.getFullYear();
  let month = date.getMonth() + 1; // getMonth() returns 0-11
  let day = date.getDate(); // getDate() returns 1-31

  // Pad month and day with leading zeros if necessary
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  return `${year}-${month}-${day}`;
};

export default formatDate;
