import * as XLSX from "xlsx";

const downloadDashboardTwo = (
  grossProductMixData,
  productMixPercentageData,
  grossArticleGroupData,
  percentageArticleGroupData,
  averageBasketCost,
  grossProfitMarginGroupData,
  subGrossProfitMarginData,
  restaurantNames,
  basketSize,
  grossProfitMargin,
  takeAway,
  highestSellerQty,
  highestSellerValue
) => {
  const wb = XLSX.utils.book_new();
  const fileName =
    restaurantNames.length > 0 ? restaurantNames : "Company Overall Data";
  // Process gross product mix data
  let transformedGrossData = [];
  grossProductMixData.forEach((item) => {
    const date = item.date;
    let isFirstRow = true;
    for (const [product, value] of Object.entries(item)) {
      if (product !== "date") {
        transformedGrossData.push({
          Date: isFirstRow ? date : "",
          Product: product,
          Value: value,
        });
        isFirstRow = false;
      }
    }
  });
  if (transformedGrossData?.length > 0) {
    const wsGross = XLSX.utils.json_to_sheet(transformedGrossData);
    XLSX.utils.book_append_sheet(wb, wsGross, "Gross Product Mix Data");
  }

  const transformedPercentageData = productMixPercentageData.map((item) => ({
    Id: item.id,
    Value: `${item.value}%`,
  }));
  if (transformedPercentageData?.length > 0) {
    const wsPercentage = XLSX.utils.json_to_sheet(transformedPercentageData);
    XLSX.utils.book_append_sheet(
      wb,
      wsPercentage,
      "Percentage Product Mix Data"
    );
  }
  let transformedSubGrossData = [];
  grossArticleGroupData.forEach((item) => {
    const date = item.date;
    let isFirstRow = true;
    for (const [subProduct, value] of Object.entries(item)) {
      if (subProduct !== "date") {
        transformedSubGrossData.push({
          Date: isFirstRow ? date : "",
          SubProduct: subProduct,
          Value: value,
        });
        isFirstRow = false;
      }
    }
  });
  if (transformedSubGrossData?.length > 0) {
    const wsData = XLSX.utils.json_to_sheet(transformedSubGrossData);
    XLSX.utils.book_append_sheet(wb, wsData, "Gross Sub Product Mix Data");
  }
  const transformedSubPercentageData = percentageArticleGroupData.map(
    (item) => ({
      Id: item.id,
      Value: `${item.value}%`,
    })
  );
  if (transformedSubPercentageData?.length > 0) {
    const wsPercentage = XLSX.utils.json_to_sheet(transformedSubPercentageData);
    XLSX.utils.book_append_sheet(
      wb,
      wsPercentage,
      "Percentage Sub Product Mix Data"
    );
  }

  const transformedData = [];

  // Create a mapping of dates to an object that holds restaurant values
  const dateToValuesMap = {};

  for (const [restaurant, restaurantData] of Object.entries(
    averageBasketCost
  )) {
    restaurantData.data.forEach((item) => {
      const dateStr = item.x.toISOString().split("T")[0]; // Convert date to YYYY-MM-DD format
      if (!dateToValuesMap[dateStr]) {
        dateToValuesMap[dateStr] = {};
      }
      dateToValuesMap[dateStr][restaurant] = ` kr ${item.y}`;
    });
  }
  for (const [date, values] of Object.entries(dateToValuesMap)) {
    transformedData.push({
      Date: date,
      ...values,
    });
  }
  if (transformedData.length > 0) {
    const wsData = XLSX.utils.json_to_sheet(transformedData);
    XLSX.utils.book_append_sheet(wb, wsData, "Basket Cost Data");
  }
  const transformedGrossProfitMarginData = grossProfitMarginGroupData.map(
    (item) => ({
      Id: item.id,
      Value: `${item.value}%`,
    })
  );
  if (transformedGrossProfitMarginData?.length > 0) {
    const wsPercentage = XLSX.utils.json_to_sheet(
      transformedGrossProfitMarginData
    );
    XLSX.utils.book_append_sheet(wb, wsPercentage, "Gross Profit Margin Data");
  }
  const transformedSubGrossProfitMarginData = subGrossProfitMarginData.map(
    (item) => ({
      Id: item.id,
      Value: `${item.value}%`,
    })
  );
  if (transformedSubGrossProfitMarginData?.length > 0) {
    const wsPercentage = XLSX.utils.json_to_sheet(
      transformedSubGrossProfitMarginData
    );
    XLSX.utils.book_append_sheet(
      wb,
      wsPercentage,
      "Sub Gross Profit Margin Data"
    );
  }
  const summaryData = [
    { Key: "Basket Size", Value: basketSize },
    { Key: "Gross Profit Margin", Value: grossProfitMargin },
    { Key: "Take Away", Value: takeAway },
    {
      Key: "Highest Seller by qty",
      Value:
        highestSellerQty.product + " (" + highestSellerQty.quantity + " units)",
    },
    {
      Key: "Highest Seller by value",
      Value: highestSellerValue.product + " (" + highestSellerValue.value + ")",
    },
  ];

  // Convert summary data to a worksheet and add to the workbook
  const wsSummary = XLSX.utils.json_to_sheet(summaryData);
  XLSX.utils.book_append_sheet(wb, wsSummary, "Summary");

  if (wb.SheetNames.length > 0) {
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  } else {
    alert("No data available to download!");
  }
};

export default downloadDashboardTwo;
