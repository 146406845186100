import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  //    Container,
  Card,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  Tooltip,
  Zoom,
  useMediaQuery,

  // Switch,
  // Box,
} from "@mui/material";

import ChartLoading from "../components/Loading/ChartLoading";
// import LineChart from "../components/LineChart";

import HourlyLineChart from "../components/LineCharts/HourlyLineChart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RemoveIcon from "@mui/icons-material/Remove";

import { useTheme } from "@mui/material/styles";

import HolidayTable from "../HolidayTable";

let prefix = "";

if (process.env.NODE_ENV === "development") {
  prefix = "http://127.0.0.1:8000";
}

const MyRestaurant = () => {
  const [barDate, setBarDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState([]);

  const [averageHolidayData, setAverageHolidayData] = useState("");
  const [averagePredictionData, setAveragePredictionData] = useState("");
  const [averageAlcoholData, setAverageAlcoholData] = useState("");
  const [averageDiningTypeData, setAverageDiningTypeData] = useState("");

  const [diningTypeData, setDiningTypeData] = useState([]);
  const [alcoholData, setAlcoholData] = useState([]);
  const [predictionData, setPredictionData] = useState([]);
  const [holidayData, setHolidayData] = useState("");

  // const [hourlySalesData, setHourlySalesData] = useState([]);
  // const [hourlyPredictionData, setHourlyPredictionData] = useState([]);

  const [combinedHourlyData, setCombinedHourlyData] = useState(null);

  const [load, setLoad] = useState(false);
  const company = localStorage.getItem("company");
  const token = localStorage.getItem("token");
  const theme = useTheme();

  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchRestaurants = useCallback(async () => {
    // setLoad(true);
    const restro_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();
      setRestaurants(restro_data.results);
      setSelectedRestaurant(restro_data.results[0]?.name);
    }
  }, [company, token]);

  const handleChange = (event) => {
    const restro = event.target.value;
    setSelectedRestaurant(restro);
    getHourlyData(barDate, restro);
    getTypeData(barDate, restro);
    getAlcoholData(barDate, restro);
    getPredictionData(barDate, restro);
    getHolidayData(barDate, restro);
  };

  const handleDateChange = (event) => {
    const actual_date = event.target.value;
    setBarDate(actual_date);
    getTypeData(actual_date);
    getAlcoholData(actual_date);
    getPredictionData(actual_date);
    getHolidayData(actual_date);
    getHourlyData(actual_date);
  };

  const handleRightArrow = () => {
    handleNextDay();
  };

  const handleLeftArrow = () => {
    handlePreviousDay();
  };

  const handlePreviousDay = () => {
    const prevDate = new Date(barDate);
    prevDate.setDate(prevDate.getDate() - 1);
    const actual_date = prevDate.toISOString().split("T")[0];
    setBarDate(actual_date);

    getTypeData(actual_date);
    getAlcoholData(actual_date);
    getPredictionData(actual_date);
    getHolidayData(actual_date);
    getHourlyData(actual_date);
  };

  const handleNextDay = () => {
    const nextDate = new Date(barDate);
    nextDate.setDate(nextDate.getDate() + 1);
    const actual_date = nextDate.toISOString().split("T")[0];
    setBarDate(actual_date);

    getTypeData(actual_date);
    getAlcoholData(actual_date);
    getPredictionData(actual_date);
    getHolidayData(actual_date);
    getHourlyData(actual_date);
  };

  const getHourlyData = async (actual_date, restro) => {
    const restaurant = restro || selectedRestaurant;
    try {
      // Set start date to 9am of the given date
      const startDate = new Date(actual_date);
      const formattedStartDate = startDate.toISOString().split("T")[0];

      // Set end date to 6am of the next day
      const endDate = new Date(actual_date);
      endDate.setDate(endDate.getDate() + 1);
      const formattedEndDate = endDate.toISOString().split("T")[0];

      // const start_hour = 9; //starting hour for the cut-off
      // const end_hour = 5; //ending hour for the cut-off
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      let hourlySalesUrl = `${prefix}/api/salesdata_hourly/sales_data_hourly/`;
      let hourlyPredictionUrl = `${prefix}/api/predictions_by_hour/hourly_predictions/`;
      if (restaurant) {
        hourlySalesUrl += `?restaurant=${restaurant}`;
        hourlyPredictionUrl += `?restaurant=${restaurant}`;
      } else {
        window.alert("Select a restaurant first");
        return;
      }
      if (formattedStartDate && formattedEndDate) {
        hourlySalesUrl += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
        hourlyPredictionUrl += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
      }
      hourlySalesUrl += "&start_hour=9&end_hour=5";
      hourlyPredictionUrl += "&start_hour=9&end_hour=5";

      // Fetching hourly sales data
      const hourlySalesDataResponse = await fetch(hourlySalesUrl, {
        headers: headers,
      });
      const hourlySalesData = await hourlySalesDataResponse.json();
      const salesProcessedData = !hourlySalesData.error
        ? {
            id: "Total Hourly Sales",
            data: hourlySalesData.results.map((entry) => ({
              x: entry.date.slice(0, -3), // Removing seconds
              y: parseFloat(entry.total_net),
            })),
          }
        : null;

      // Fetching hourly prediction data
      const hourlyPredictionDataResponse = await fetch(hourlyPredictionUrl, {
        headers: headers,
      });
      const hourlyPredictionData = await hourlyPredictionDataResponse.json();
      const predictionProcessedData = !hourlyPredictionData.error
        ? {
            id: "Predictions",
            data: hourlyPredictionData.results.map((entry) => ({
              x: `${entry.date.slice(0, -3)}`, // Removing seconds
              y: parseFloat(entry.total_gross),
            })),
          }
        : null;

      // Combining sales and predictions data
      const combinedData = [salesProcessedData, predictionProcessedData].filter(
        Boolean
      );
      setCombinedHourlyData(combinedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  //API fetch for takeout predictions
  const getTypeData = (actual_date, restro) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const restaurant = restro || selectedRestaurant;

    const newStartDate = new Date(actual_date);
    newStartDate.setDate(newStartDate.getDate() - 2);

    const newEndDate = new Date(actual_date);
    newEndDate.setDate(newEndDate.getDate() + 2);

    let urlP = `${prefix}/api/dining_type_predictions/type_predictions/?start_date=${
      newStartDate.toISOString().split("T")[0]
    }&end_date=${newEndDate.toISOString().split("T")[0]}`;

    if (restaurant.length > 0) {
      urlP += `&restaurants=${JSON.stringify([restaurant])}`;
    }

    // setLoad(true);
    fetch(urlP, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        let sumOfTakeOut = 0;
        let specificDayTakeOut = 0;

        data.results.forEach((item) => {
          sumOfTakeOut += item.take_out;
          if (item.date === actual_date) {
            specificDayTakeOut = item.take_out;
          }
        });

        const averageOfTakeOut = sumOfTakeOut / data.results.length;

        // Set the specific and average take_out values in the state
        setDiningTypeData(
          specificDayTakeOut >= 0 ? specificDayTakeOut : specificDayTakeOut
        );
        setAverageDiningTypeData(
          averageOfTakeOut >= 0 ? averageOfTakeOut : averageOfTakeOut
        );

        setLoad(false);
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setLoad(false);
      });
  };

  //API fetch for alcohol data
  const getAlcoholData = (actual_date, restro) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const restaurant = restro || selectedRestaurant;

    const newStartDate = new Date(actual_date);
    newStartDate.setDate(newStartDate.getDate() - 2);

    const newEndDate = new Date(actual_date);
    newEndDate.setDate(newEndDate.getDate() + 2);

    let urlP = `${prefix}/api/product_mix_predictions/product_mix_predictions/?start_date=${
      newStartDate.toISOString().split("T")[0]
    }&end_date=${newEndDate.toISOString().split("T")[0]}`;

    if (restaurant.length > 0) {
      urlP += `&restaurants=${JSON.stringify([restaurant])}`;
    }

    // setLoad(true);
    fetch(urlP, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        let sumOfAlcohol = 0;
        let specificDayAlcohol = 0;

        data.results.forEach((item) => {
          sumOfAlcohol += item.product_mix;
          if (item.date === actual_date) {
            specificDayAlcohol = item.product_mix;
          }
        });

        const averageOfAlcohol = sumOfAlcohol / data.results.length;

        // Set the specific and average alcohol values in the state
        setAlcoholData(
          specificDayAlcohol >= 0 ? specificDayAlcohol : specificDayAlcohol
        );
        setAverageAlcoholData(
          averageOfAlcohol >= 0 ? averageOfAlcohol : averageOfAlcohol
        );

        setLoad(false);
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setLoad(false);
      });
  };

  const getPredictionData = (actual_date, restro) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const restaurant = restro || selectedRestaurant;

    const newStartDate = new Date(actual_date);
    newStartDate.setDate(newStartDate.getDate() - 2);

    const newEndDate = new Date(actual_date);
    newEndDate.setDate(newEndDate.getDate() + 2);

    let urlP = `${prefix}/api/predictions/daily_predictions/?start_date=${
      newStartDate.toISOString().split("T")[0]
    }&end_date=${newEndDate.toISOString().split("T")[0]}`;

    if (restaurant.length > 0) {
      urlP += `&restaurant=${restaurant}`;
    }

    // setLoad(true);
    fetch(urlP, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        let sumOfPredictions = 0;
        let specificDayPrediction = 0;

        data.results.forEach((item) => {
          sumOfPredictions += parseInt(item.total_gross);
          if (item.date === actual_date) {
            // using actual_date to filter
            specificDayPrediction = item.total_gross;
          }
        });

        const averageOfPredictions = sumOfPredictions / data.results.length;

        // Set the specific and average prediction values in the state
        setPredictionData(
          specificDayPrediction >= 0
            ? +specificDayPrediction
            : specificDayPrediction
        );
        setAveragePredictionData(
          averageOfPredictions >= 0
            ? +averageOfPredictions
            : averageOfPredictions
        );

        setLoad(false);
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setLoad(false);
      });
  };

  //API fetch for holiday parameters
  const getHolidayData = (actual_date, restro) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const restaurant = restro || selectedRestaurant;

    const newStartDate = new Date(actual_date);
    newStartDate.setDate(newStartDate.getDate() - 2);

    const newEndDate = new Date(actual_date);
    newEndDate.setDate(newEndDate.getDate() + 2);

    let url = `${prefix}/api/holidayparameters/holiday_params/?start_date=${
      newStartDate.toISOString().split("T")[0]
    }&end_date=${
      newEndDate.toISOString().split("T")[0]
    }&restaurant=${JSON.stringify([restaurant])}`;

    fetch(url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const filterOut = [
          // "sunshine_amount",
          // "monthly",
          "weekly",
        ];
        const filteredData = data.results.filter(
          (result) => !filterOut.includes(result.name)
        );

        // Calculate the sum of effect values
        let sumOfEffects = 0;
        let specificDayEffects = 0;

        filteredData.forEach((item) => {
          sumOfEffects += item.effect;
          if (item.date === actual_date) {
            // using actual_date to filter
            specificDayEffects += item.effect;
          }
        });

        const averageOfEffects = sumOfEffects / filteredData.length;

        // Set the sum of effect values in the state
        setHolidayData(
          specificDayEffects >= 0
            ? "+" + specificDayEffects
            : specificDayEffects
        );
        setAverageHolidayData(
          averageOfEffects >= 0 ? "+" + averageOfEffects : averageOfEffects
        );

 
      })
      .catch((error) => console.error("Error fetching holiday data:", error));
  };

  useEffect(() => {
    fetchRestaurants(); // Existing fetch logic
  }, [fetchRestaurants]); // Re-run when barDate or fetchRestaurants changes

  return (
    <>
      {load ? (
        <ChartLoading />
      ) : (
        <Grid
          // className='centered-div'
          // This is the main container that encapsulates the whole page
          sx={
            {
              // backgroundColor: 'gray'
            }
          }
        >
          <Grid
            // gap={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1rem",
              // marginBottom: "1rem",
            }}
          >
            <FormControl
              lg={12}
              md={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: ["column", "column"],
                // my: ["none", "1rem"],
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ width: 200, m: 0 }}>
                <InputLabel id="restaurant-label" shrink={true}>
                  Select Restaurant
                </InputLabel>
                <Select
                  labelId="restaurant-label"
                  id="restaurant"
                  value={selectedRestaurant}
                  onChange={handleChange}
                  label="Select Restaurant"
                  sx={{
                    "& .MuiSelect-select": {
                      // height: '3rem',
                      padding: "0",
                      textIndent: "0.25em",
                      textAlign: "center",
                    },
                    background: "#F8F8F8",
                    mb: "1rem",
                    mx: ".5rem",
                    height: "3rem",
                    color: "black",
                    boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  }}
                >
                  {/* Render the options dynamically */}
                  {restaurants.map((restaurant) => (
                    <MenuItem key={restaurant.id} value={restaurant.name}>
                      {restaurant.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ flexDirection: "row" }}>
                <FormControl
                  sx={{ m: 1, width: "4ch", flexDirection: "row" }}
                  variant="contained"
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#DBC400",
                      color: "#f8f8f8",
                      "&:hover": {
                        backgroundColor: "#FFE500", // You can adjust this color based on your preference
                        // Prevent MUI Button from changing the color on hover
                        boxShadow: "none",
                      },
                      "&:active": {
                        backgroundColor: "#FFE500", // Adjust this color based on your preference
                      },
                    }}
                    onClick={handleLeftArrow}
                  >
                    <ArrowBackIcon /> {/* Left arrow icon */}
                  </Button>
                </FormControl>

                <FormControl
                  sx={{ m: 1, width: "15ch", flexDirection: "row", ml: 5 }}
                  variant="outlined"
                >
                  <TextField
                    label="Select Date"
                    type="date"
                    id="selectDate"
                    value={barDate}
                    onChange={handleDateChange}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& input": {
                        height: "3em", // Adjust this value to your desired height
                        padding: "0", // Remove padding to avoid extra space inside the input
                        fontSize: "1em",
                        textIndent: "0.25em", // Add a small margin to the left of the text inside the input field
                      },
                      background: "#F8F8F8",
                    }}
                  />
                </FormControl>
                <FormControl
                  sx={{ m: 1, width: "4ch", flexDirection: "row" }}
                  variant="outlined"
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#DBC400",
                      color: "#f8f8f8",
                      "&:hover": {
                        backgroundColor: "#FFE500", // You can adjust this color based on your preference
                        // Prevent MUI Button from changing the color on hover
                        boxShadow: "none",
                      },
                      "&:active": {
                        backgroundColor: "#FFE500", // Adjust this color based on your preference
                      },
                    }}
                    onClick={handleRightArrow}
                  >
                    <ArrowForwardIcon /> {/* Right arrow icon */}
                  </Button>
                </FormControl>
              </FormControl>
            </FormControl>
            <Grid
              gap={1}
              lg={12}
              md={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: ["column", "row"],
                // alignItems: 'flex-start'
                justifyContent: {
                  lg: "center",
                  xs: "flex-start",
                }, // overflow:'auto'
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: {
                    xs: "100%",
                    lg: "33%",
                  },
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Card
                  sx={{
                    backgroundColor: "#FFF", // Faint gray background
                    boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    borderRadius: "1rem",
                    margin: ".1rem",
                    width: {
                      xs: "50%",
                      lg: "50%",
                    },
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                  }}
                >
                  <Grid
                    sx={{
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: "1rem",
                      mr: "1rem",
                      color:
                        !predictionData || !holidayData
                          ? "black"
                          : parseInt(holidayData) > 5000 && holidayData > 0
                          ? "green"
                          : parseInt(holidayData) > 5000 && holidayData < 0
                          ? "red"
                          : "#0548b3",
                    }}
                  >
                    <Grid
                      sx={{
                        mb:
                          predictionData !== "" && holidayData !== ""
                            ? "0"
                            : "1.5rem",
                      }}
                    >
                      {predictionData !== "" &&
                        holidayData !== "" &&
                        (parseInt(holidayData) > 5000 && holidayData > 0 ? (
                          <ArrowUpwardIcon />
                        ) : parseInt(holidayData) > 5000 && holidayData < 0 ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <RemoveIcon />
                        ))}
                    </Grid>
                  </Grid>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title="This is the expected sale for today (without MVA). The arrow anc colour indicates 
                  whether this is more, equal to or less than what you would normally expect for such a day
                  "
                    placement="top"
                    arrow
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        // gap: '1rem',
                        borderRadius: "10px",
                        // backgroundColor: 'rgba(0, 0, 0, 0.05)', // Faint gray background
                        // padding: '1rem',
                        mt: "-1rem",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 700,
                          color:
                            !predictionData || !averagePredictionData
                              ? "black"
                              : parseInt(holidayData) > 5000 && holidayData > 0
                              ? "green"
                              : parseInt(holidayData) > 5000 && holidayData < 0
                              ? "red"
                              : "#0548b3",
                        }}
                      >
                        {predictionData} NOK
                      </Typography>

                      <Typography
                        variant="h7"
                        sx={{
                          fontWeight: 400,
                        }}
                      >
                        Expected Sales
                      </Typography>
                    </CardContent>
                  </Tooltip>
                </Card>

                <Card
                  sx={{
                    backgroundColor: "#FFF", // Faint gray background
                    boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    borderRadius: "1rem",
                    margin: ".1rem",
                    width: {
                      xs: "50%",
                      lg: "50%",
                    },
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                  }}
                >
                  <Grid
                    sx={{
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: "1rem",
                      mr: "1rem",
                      color:
                        !holidayData || !averageHolidayData
                          ? "black"
                          : parseInt(holidayData) > 5000 && holidayData > 0
                          ? "green"
                          : parseInt(holidayData) > 5000 && holidayData < 0
                          ? "red"
                          : "#0548b3",
                    }}
                  >
                    <Grid
                      sx={{
                        mb:
                          holidayData !== "" && averageHolidayData !== ""
                            ? "0"
                            : "1.5rem",
                      }}
                    >
                      {holidayData !== "" &&
                        averageHolidayData !== "" &&
                        (parseInt(holidayData) > 5000 && holidayData > 0 ? (
                          <ArrowUpwardIcon />
                        ) : parseInt(holidayData) > 5000 && holidayData < 0 ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <RemoveIcon />
                        ))}
                    </Grid>
                  </Grid>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title="This is the net effect of on expected sales from factors like weather, concerts etc."
                    placement="top"
                    arrow
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        // gap: '1rem',
                        borderRadius: "10px",
                        // backgroundColor: 'rgba(0, 0, 0, 0.05)', // Faint gray background
                        // padding: '1rem',
                        mt: "-1rem",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 700,
                          color:
                            !holidayData || !averageHolidayData
                              ? "black"
                              : parseInt(holidayData) > 5000 && holidayData > 0
                              ? "green"
                              : parseInt(holidayData) > 5000 && holidayData < 0
                              ? "red"
                              : "#0548b3",
                        }}
                      >
                        {holidayData} NOK
                      </Typography>

                      <Typography
                        variant="h7"
                        sx={{
                          fontWeight: 400,
                        }}
                      >
                        Deviation from the norm
                      </Typography>
                    </CardContent>
                  </Tooltip>
                </Card>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: {
                    xs: "100%",
                    lg: "50%",
                  },
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Card
                  sx={{
                    backgroundColor: "#FFF", // Faint gray background
                    boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    borderRadius: "1rem",
                    margin: ".1rem",
                    width: {
                      xs: "50%",
                      lg: "50%",
                    },
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                  }}
                >
                  <Grid
                    sx={{
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: "1rem",
                      mr: "1rem",
                      color:
                        !diningTypeData || !averageDiningTypeData
                          ? "black"
                          : parseInt(diningTypeData) >
                            parseInt(averageDiningTypeData)
                          ? "green"
                          : "red",
                    }}
                  >
                    <Grid
                      sx={{
                        mb:
                          diningTypeData !== "" && averageDiningTypeData !== ""
                            ? "0"
                            : "1.5rem",
                      }}
                    >
                      {diningTypeData !== "" &&
                        averageDiningTypeData !== "" &&
                        (parseInt(diningTypeData) >
                        parseInt(averageDiningTypeData) ? (
                          <ArrowUpwardIcon />
                        ) : (
                          <ArrowDownwardIcon />
                        ))}
                    </Grid>
                  </Grid>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title="This is the predicted share of sales that are take away for today"
                    placement="top"
                    arrow
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        // gap: '1rem',
                        borderRadius: "10px",
                        // backgroundColor: 'rgba(0, 0, 0, 0.05)', // Faint gray background
                        // padding: '1rem',
                        mt: "-1rem",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 700,
                          color:
                            !diningTypeData || !averageDiningTypeData
                              ? "black"
                              : parseInt(diningTypeData) >
                                parseInt(averageDiningTypeData)
                              ? "green"
                              : "red",
                        }}
                      >
                        {diningTypeData}%
                      </Typography>

                      <Typography
                        variant="h7"
                        sx={{
                          fontWeight: 400,
                        }}
                      >
                        Expected Take-Away Share
                      </Typography>
                    </CardContent>
                  </Tooltip>
                </Card>
                <Card
                  sx={{
                    backgroundColor: "#FFF", // Faint gray background
                    boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    borderRadius: "1rem",
                    margin: ".1rem",
                    width: {
                      xs: "50%",
                      lg: "50%",
                    },
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                  }}
                >
                  <Grid
                    sx={{
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: "1rem",
                      mr: "1rem",
                      color:
                        !alcoholData || !averageAlcoholData
                          ? "black"
                          : parseInt(alcoholData) > parseInt(averageAlcoholData)
                          ? "green"
                          : "red",
                    }}
                  >
                    <Grid
                      sx={{
                        mb:
                          alcoholData !== "" && averageAlcoholData !== ""
                            ? "0"
                            : "1.5rem",
                      }}
                    >
                      {alcoholData !== "" &&
                        averageAlcoholData !== "" &&
                        (parseInt(alcoholData) >
                        parseInt(averageAlcoholData) ? (
                          <ArrowUpwardIcon />
                        ) : (
                          <ArrowDownwardIcon />
                        ))}
                    </Grid>
                  </Grid>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title="This is the predicted share of sales that are alcohol related for today"
                    placement="top"
                    arrow
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        // gap: '1rem',
                        borderRadius: "10px",
                        // backgroundColor: 'rgba(0, 0, 0, 0.05)', // Faint gray background
                        // padding: '1rem',
                        mt: "-1rem",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 700,
                          color:
                            !alcoholData || !averageAlcoholData
                              ? "black"
                              : parseInt(alcoholData) >
                                parseInt(averageAlcoholData)
                              ? "green"
                              : "red",
                        }}
                      >
                        {alcoholData}%
                      </Typography>

                      <Typography
                        variant="h7"
                        sx={{
                          fontWeight: 400,
                        }}
                      >
                        Expected Alcohol Share
                      </Typography>
                    </CardContent>
                  </Tooltip>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            // gap={1}
            // spacing={1}
            sx={{
              // direction: 'column'
              paddingTop: "1rem",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {/*Sales Prediction graph Hourly*/}
            <Grid
              // container
              direction="row"
              // spacing={1}
              alignItems="flex-start"
              lg={8}
              md={8}
              xs={12}
              sx={{
                // display: "flex",
                // flexDirection: 'column',
                // alignitems: 'center'
                boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                background: "#FFF",
                border: "none",
                borderRadius: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Grid
                item
                xs={12}
                // sm={6} md={6} lg={6}
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  title="Hourly Sales tooltip"
                  placement="top"
                  arrow
                >
                  <Typography
                    variant="h4"
                    sx={{
                      margin: "1rem",
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Sales Prediction
                  </Typography>
                </Tooltip>
                <Grid
                  // onClick={()=>{handleRedirect(productData)}}
                  sx={{
                    height: "30rem",
                  }}
                >
                  <HourlyLineChart
                    data={combinedHourlyData}
                    isDailyData={true}
                    isNotPercentage={true}

                    // isCombinedChart={true}
                    // isCombinedChart={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              // container
              direction="row"
              // spacing={1}
              alignItems="flex-start"
              lg={8}
              md={8}
              xs={12}
              sx={{
                // display: "flex",
                // flexDirection: 'column',
                // alignitems: 'center'
                boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                background: "#FFF",
                border: "none",
                borderRadius: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Grid
                item
                xs={12}
                // sm={6} md={6} lg={6}
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  title="Hourly Sales tooltip"
                  placement="top"
                  arrow
                >
                  <Typography
                    variant="h4"
                    sx={{
                      margin: "1rem",
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Factors built into the prediction
                  </Typography>
                </Tooltip>
                <Grid
                  // onClick={()=>{handleRedirect(productData)}}
                  sx={{
                    height: "15rem",
                  }}
                >
                  <HolidayTable
                    restaurant={selectedRestaurant}
                    startDate={barDate}
                    endDate={barDate}
                    isXSScreen={isXSScreen}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MyRestaurant;
