import * as XLSX from "xlsx";

const downloadSuperDashboardOne = (
  staffData = [],
  employeeProductivity = [],
  intervalValue = "",
  absoluteStaffSalesData = [],
  restaurantNames = "",
  statStaffData = [],
  averageEmployeeProductivity = 0,
  averageEmployeeCost = 0,
  averageSickCost = 0,
  avgEmployeeCost = 0,
  avgEmployeeProductivity = 0,
  staffCostPercentage = 0
) => {
  const fileName =
    restaurantNames.length > 0 ? restaurantNames : "Company Overall Data";
  const wb = XLSX.utils.book_new();

  // let startDate, endDate;

  // Format date function
  // const formatDate = (date) => {
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  //   const year = date.getFullYear();
  //   const hours = String(date.getHours()).padStart(2, "0");
  //   const minutes = String(date.getMinutes()).padStart(2, "0");
  //   if (dataRange === "today") {
  //     return `${day}/${month}/${year} ${hours}:${minutes}`;
  //   }
  //   return `${day}/${month}/${year}`;
  // };
  // Generate date interval

  const kpiData = [
    {
      KPI: "Staff Cost Percentage",
      Actual: statStaffData[0],
      Prediction: statStaffData[1],
    },
    {
      KPI: "Total Employee Cost",
      Actual: averageEmployeeCost[0],
      Prediction: averageEmployeeCost[1],
    },
    {
      KPI: "Emp Productivity/hr",
      Actual: averageEmployeeProductivity,
      Prediction: "N/A",
    },
    {
      KPI: "Total Sick Cost",
      Actual: averageSickCost[0],
      Prediction: "N/A",
    },
  ];
  const wsKpiData = XLSX.utils.json_to_sheet(kpiData);
  wsKpiData["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }]; // Adjust column widths

  // Add headers and sub-headers
  XLSX.utils.sheet_add_aoa(wsKpiData, [["KPI", "Actual", "Prediction"]], {
    origin: "A1",
  });
  XLSX.utils.book_append_sheet(wb, wsKpiData, "Key Metrics");

  // Process net gross profit data
  let transformedData = [];
  employeeProductivity.forEach((item) => {
    transformedData.push({
      Restaurant: item.id,
      "Number of days": item.day_count,
      "Avg. Productivity": item.value,
    });
  });

  if (transformedData.length > 0) {
    const wsData = XLSX.utils.json_to_sheet(transformedData);
    XLSX.utils.book_append_sheet(
      wb,
      wsData,
      `Emp Prod Data for ${intervalValue}`
    );
  }

  const transformedStaffData = {};

  // Iterate over each city's data
  for (const city in staffData) {
    staffData[city].data.forEach((item) => {
      const dateStr = item.x.toString();

      // If the date doesn't exist in the transformedData, create it
      if (!transformedStaffData[dateStr]) {
        transformedStaffData[dateStr] = {
          Date: item.x,
        };
      }

      // Add the city's value for that date
      transformedStaffData[dateStr][city] = item.y;
    });
  }

  const excelData = Object.values(transformedStaffData);

  if (excelData.length > 0) {
    const wsData = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, wsData, "Staff Data");
  }

  const transformedEmployeeData = {};

  // Iterate over each city's data
  for (const city in absoluteStaffSalesData) {
    absoluteStaffSalesData[city].data.forEach((item) => {
      const dateStr = item.x.toString();

      // If the date doesn't exist in the transformedData, create it
      if (!transformedEmployeeData[dateStr]) {
        transformedEmployeeData[dateStr] = {
          Date: item.x,
        };
      }

      // Add the city's value for that date
      transformedEmployeeData[dateStr][city] = item.y;
    });
  }

  const excelEmployeeData = Object.values(transformedEmployeeData);

  if (excelEmployeeData.length > 0) {
    const wsData = XLSX.utils.json_to_sheet(excelEmployeeData);
    XLSX.utils.book_append_sheet(wb, wsData, "Employee Cost Data");
  }

  const summaryData = [
    { Key: "Staff Cost Percentage", Value: staffCostPercentage },
    { Key: "Average Employee Productivity", Value: avgEmployeeProductivity },
    { Key: "Average Employee Cost", Value: avgEmployeeCost },
  ];

  // Convert summary data to a worksheet and add to the workbook
  const wsSummary = XLSX.utils.json_to_sheet(summaryData);
  XLSX.utils.book_append_sheet(wb, wsSummary, "Summary");

  if (wb.SheetNames.length > 0) {
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  } else {
    alert("No data available to download!");
  }
};

export default downloadSuperDashboardOne;
