import React from "react";
import ReactLoading from "react-loading";
import { Box } from "@mui/material";

const Loading = () => {
  return (
    <Box sx={{ position: "relative", display: "flex" }}>
      <ReactLoading
        type="spinningBubbles"
        color="#858282"
        height={300}
        width={200}
        // sx={{ borderRadius: "10px" }}
      />
      {/* <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
      </Box> */}
    </Box>
  );
};

export default Loading;
