import * as XLSX from "xlsx";

const downloadSalesPred = (
  salesPredData,
  hourlySalesData,
  prodCombinedData,
  hourlyPredictionData,
  combinedData,
  recommendedHours,
  selectedRestaurant,
  company
) => {
  const wb = XLSX.utils.book_new();
  // Process daily sales prediction data
  const dailyData = salesPredData?.prediction?.data.map((item) => {
    const date = item.x;
    const dateString = date.toString();
    const totalSales =
      salesPredData?.total_sales?.data.find(
        (x) => x.x.toString() === dateString
      )?.y ?? 0;
    const userPrediction =
      salesPredData?.user_prediction?.data.find(
        (x) => x.x.toString() === dateString
      )?.y ?? 0;

    return {
      Date: date,
      "Predicted Sales": item.y,
      "Total Sales": totalSales,
      "User Prediction": userPrediction,
    };
  });
  if (dailyData?.length > 0) {
    const wsDaily = XLSX.utils.json_to_sheet(dailyData);
    XLSX.utils.book_append_sheet(wb, wsDaily, "Daily Sales Data");
  }
  // Process hourly sales data
  const hourlyData = hourlySalesData?.data?.map((item) => {
    const date = item.x;
    const dateString = date.toString();
    const predictedHourlySales =
      hourlyPredictionData?.data.find((x) => x.x.toString() === dateString)
        ?.y ?? 0;

    return {
      Date: date,
      "Hourly Sales": item.y,
      "Predicted Hourly Sales": predictedHourlySales,
    };
  });
  if (hourlyData?.length > 0) {
    const wsHourly = XLSX.utils.json_to_sheet(hourlyData);
    XLSX.utils.book_append_sheet(wb, wsHourly, "Hourly Sales Data");
  }
  // Process take-out predictions data
  const predictionData =
    combinedData?.take_out?.find((item) => item.id === "Prediction")?.data ??
    [];
  const actualData =
    combinedData?.take_out?.find((item) => item.id === "Actual Data")?.data ??
    [];
  const longerData =
    predictionData.length >= actualData.length ? predictionData : actualData;
  const isPredictionLonger = predictionData.length >= actualData.length;

  const takeOutData = longerData.map((item) => {
    const date = item.x;
    const dateString = date.toString();
    const predictedTakeOutSales = isPredictionLonger
      ? item.y
      : predictionData.find((x) => x.x.toString() === dateString)?.y ?? 0;
    const actualTakeOutSales = isPredictionLonger
      ? actualData.find((x) => x.x.toString() === dateString)?.y ?? 0
      : item.y;
    return {
      Date: date,
      "Predicted Take-Out Sales": predictedTakeOutSales,
      "Actual Take-Out Sales": actualTakeOutSales,
    };
  });
  if (takeOutData?.length > 0) {
    const wsTakeOut = XLSX.utils.json_to_sheet(takeOutData);
    XLSX.utils.book_append_sheet(wb, wsTakeOut, "Take out Data");
  }

  // Process product predictions data
  const prodPredictionData =
    prodCombinedData?.product?.find((item) => item.id === "Prediction")?.data ??
    [];
  const prodActualData =
    prodCombinedData?.product?.find((item) => item.id === "Actual")?.data ?? [];
  const prodLongerDat =
    prodPredictionData.length >= prodActualData.length
      ? prodPredictionData
      : prodActualData;
  const isProdPredictionLonger =
    prodPredictionData.length >= prodActualData.length;
  const productData = prodLongerDat.map((item) => {
    const date = item.x;
    const dateString = date.toString();
    const predictedProductSales = isProdPredictionLonger
      ? item.y
      : prodPredictionData.find((x) => x.x.toString() === dateString)?.y ?? 0;
    const actualProductSales = isProdPredictionLonger
      ? prodActualData.find((x) => x.x.toString() === dateString)?.y ?? 0
      : item.y;
    return {
      Date: date,
      "Predicted Alcohol Sales": predictedProductSales,
      "Actual Alcohol Sales": actualProductSales,
    };
  });
  if (productData?.length > 0) {
    const wsProduct = XLSX.utils.json_to_sheet(productData);
    XLSX.utils.book_append_sheet(wb, wsProduct, "Alcohol Data");
  }
  const recommendedHourData = recommendedHours?.map((item) => ({
    Date: item.date,
    Value: `${item.Values}hrs`,
  }));
  if (recommendedHourData?.length > 0) {
    const wsHours = XLSX.utils.json_to_sheet(recommendedHourData);
    XLSX.utils.book_append_sheet(wb, wsHours, "Recommended Hours per Day");
  }
  // Write Excel file and trigger download
  const fileName =
    selectedRestaurant && selectedRestaurant.length > 0
      ? selectedRestaurant
      : company;

  if (wb.SheetNames.length > 0) {
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  } else {
    alert("No data available to download!");
  }
};

export default downloadSalesPred;
