import React, { useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { getCompanyColors } from "../CompanyUtils/CompanyColor";
// import formatNumber from "../../utils/FormatNumber";
import formatKpiNumber from "../../utils/formatKpiNumber";
import formatNumber from "../../utils/FormatNumber";
const company = localStorage.getItem("company");

const PointHourlyLineChart = ({
  data,

  // isCombinedChart = false,
  isDailyData = false,
  isNotPercentage = false,
  isMinimal = false,
  isXSScreen = false,
  hasPredictionLine = false,
  predictionLineDate = null,
  isMultiRestaurant = false,
  isStaffChart = false,
  isEmployeeChart = false,
  isProfitGraph = false,
  useKpiFormat = false,
  isOverlapChart = false,
  Latest = null,
}) => {
  // const [activeId, setActiveId] = useState(null);
  const [hiddenIds, setHiddenIds] = useState([]);

  const format = !isNotPercentage ? (v) => `${v}%` : (v) => `${v}`;
  let interval;
  let processedData = data ? data : null;
  if (isMultiRestaurant) {
    processedData = [];
    for (const property in data) {
      processedData.push(data[property]);
    }
  }
  if (isDailyData) {
    interval = 2;
  } else {
    interval = isXSScreen ? 6 : 2;
  }


  const colors = getCompanyColors();
  const goalPercentage =
    company === "Fisketorget" ? 32 : company === "Burgerheim" ? 20 : 28;

  if (processedData && processedData.length > 0) {
    processedData = processedData.map((series) => {
      return {
        ...series,
        data: series.data.map((point) => {
          return {
            ...point,
            y: isNaN(point.y) ? 0 : point.y,
          };
        }),
      };
    });
  }

  let filteredData = processedData?.filter(
    (serie) => !hiddenIds.includes(serie.id)
  );

  let minYValue;
  let maxYValue;
  if (processedData && processedData.length > 0) {
    // Extract all y-values from all data series and filter out NaN values
    const allYValues = processedData.flatMap((series) =>
      series.data.map((point) => point.y).filter((y) => !isNaN(y))
    );
    // Check if there are any valid values left after filtering
    if (allYValues.length > 0) {
      // Find the minimum and maximum y-values from the filtered array
      minYValue = Math.min(...allYValues);
      maxYValue = Math.max(...allYValues);
      if (minYValue === maxYValue) {
        minYValue = 0;
      }
    } else {
      // Default values if no valid data is available
      minYValue = 0;
      maxYValue = 0;
    }
  } else {
    // Default values if no data is available
    minYValue = 0;
    maxYValue = 0;
  }

  minYValue = minYValue.toFixed(0);
  maxYValue = maxYValue.toFixed(0);
  // console.log(minYValue, maxYValue, "min max data");

  if (maxYValue < 10) {
  } else if (isNotPercentage) {
    maxYValue =
      maxYValue < 1000
        ? Math.round(maxYValue / 100) * 100
        : maxYValue < 9000
        ? Math.round(maxYValue / 1000) * 1000
        : Math.round(maxYValue / 10000) * 10000;
  } else {
    // For percentages or other units
    maxYValue = Math.round(maxYValue / 10) * 10; // Adjust rounding logic as needed
  }

  // // console.log(minYValue, data);
  let predictionLineMarkerDate;
  if (hasPredictionLine && Latest) {
    predictionLineMarkerDate = new Date(Latest);
    // Set the time to midnight to align with the graph points
    predictionLineMarkerDate?.setHours(0, 0, 0, 0);
  }

  const isDataZero = processedData.every((series) =>
    series.data.every((point) => point.y === 0)
  );

  const pointTooltip = ({ point }) => {
    const date = new Date(point.data.x);
    const formattedDate =
      `${date.toLocaleString("default", { weekday: "long" })}, ` + // Adds weekday
      `${String(date.getDate()).padStart(2, "0")} ` +
      `${date.toLocaleString("default", { month: "short" })}, ` +
      `${date.getFullYear()} ` + // Adds the year
      `${String(date.getHours()).padStart(2, "0")}:` +
      `${String(date.getMinutes()).padStart(2, "0")}`;

    // Convert latest_created_at to a Date object and retain its original hour
    // const latestCreatedAtDate = new Date(Latest);

    // // Convert point date to Date object for comparison
    // const pointDate = new Date(point.data.x);

    // Determine whether the tooltip should display "Historical" or "Prediction"
    // let tooltipTitle;
    // // Check if the chart is a staff chart and if the point's date and hour is before or equal to the latest created date and hour
    // if (
    //   isOverlapChart &&
    //   pointDate.getTime() <= latestCreatedAtDate.getTime()
    // ) {
    //   tooltipTitle = "Historical";
    // } else {
    //   tooltipTitle = "Prediction";
    // }

    // console.log(filteredData, "data ayo");

    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          borderRadius: "5px",
        }}
      >
        <strong>{formattedDate}</strong>
        <br />
        {point.data.comment && (
          <strong style={{ color: "black", marginBottom: "10px" }}>
            Comment: {point.data.comment}
            <br />
          </strong>
        )}

        {/* Conditional rendering for Net Profit, Gross Profit at the bottom */}
        {point.serieId === "Gross Profit II" && (
          <>
            <strong style={{ color: "gray", marginBottom: "5px" }}>
              Employee Cost:{" "}
              {isNotPercentage
                ? formatKpiNumber(point.data.emp_cost)
                : point.data.emp_cost}
              <br />
              Third Party Delivery Cost:{" "}
              {isNotPercentage
                ? formatKpiNumber(point.data.delivery_cost)
                : point.data.delivery_cost}
              <br />
              {/* Rent:{" "}
              {isNotPercentage
                ? new Intl.NumberFormat("nb-NO").format(point.data.rent)
                : point.data.rent}
              <br />
              Other Fixed Cost:{" "}
              {isNotPercentage
                ? new Intl.NumberFormat("nb-NO").format(point.data.fixed_cost)
                : point.data.fixed_cost} */}
              {/* <br /> */}
            </strong>
            <strong style={{ color: point.serieColor }}>
              Gross Profit II:{" "}
              {isNotPercentage
                ? formatKpiNumber(Math.round(point.data.y))
                : `${point.data.y}`}
            </strong>
            <br />
          </>
        )}
        {point.serieId === "Gross Profit I" && (
          <>
            <strong style={{ color: "gray" }}>
              Cost of Goods:{" "}
              {isNotPercentage
                ? formatKpiNumber(point.data.cost)
                : point.data.cost}
              <br />
            </strong>
            <strong style={{ color: point.serieColor }}>
              Gross Profit I:{" "}
              {isNotPercentage
                ? formatKpiNumber(Math.round(point.data.y))
                : `${point.data.y}`}
            </strong>
            <br />
          </>
        )}
        {point.serieId !== "Gross Profit II" &&
          point.serieId !== "Gross Profit I" && (
            <strong
              style={{
                color:
                  // tooltipTitle === "Historical" ? "#543c8c" :
                  point.serieColor,
                marginTop: "10px",
              }}
            >
              {!isOverlapChart ? `${point.serieId}: ` : ""}
              {isNotPercentage
                ? useKpiFormat
                  ? formatNumber(Math.round(point.data.y))
                  : isOverlapChart
                  ? `${formatNumber(Math.round(point.data.y))} ${point.serieId}`
                  : formatKpiNumber(Math.round(point.data.y))
                : isOverlapChart
                ? `${point.data.y}% ${point.serieId}`
                : `${point.data.y}%`}
            </strong>
          )}
      </div>
    );
  };

  const yScaleConfig = isProfitGraph
    ? {
        type: "linear",
        min: isEmployeeChart
          ? 0
          : !isNotPercentage && minYValue < -100
          ? -100
          : isStaffChart
          ? 0
          : "auto",
        max: "auto",
        reverse: false,
      }
    : {
        tickValues: [0, minYValue, maxYValue],
        min: isEmployeeChart
          ? 0
          : !isNotPercentage && minYValue < -100
          ? -100
          : isStaffChart
          ? 0
          : "auto",
        max: isStaffChart ? 70 : "auto",
        type: "linear",
      };

  return !processedData || processedData.length === 0 || isDataZero ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        // border: "solid red 1px",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    <ResponsiveLine
      animate
      enableGridX={!isMinimal}
      enableGridY={isMinimal}
      gridYValues={isProfitGraph ? undefined : [0, minYValue, maxYValue]}
      axisBottom={{
        format: (value) => {
          const date = new Date(value);
          // Using toLocaleTimeString to format the time in 12-hour format
          return date.toLocaleTimeString([], {
            hour: "2-digit",
            // minute: "2-digit",
            hour12: false, // Ensures 12-hour format
          });
        },
        tickValues: `every ${interval} hours`,
      }}
      yScale={yScaleConfig}
      axisLeft={{
        ...(isProfitGraph
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: "middle",
            }
          : {
              tickValues: [0, minYValue, isStaffChart ? 70 : maxYValue],
              format,
            }),
      }}
      curve="monotoneX"
      colors={colors}
      data={filteredData}
      margin={
        isMinimal
          ? {
              bottom: 50,
              left: maxYValue > 100000 ? 70 : 50,
              right: 50,
              top: 40,
            }
          : {
              bottom: 60,
              left: 80,
              right: 20,
              top: 20,
            }
      }
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      lineWidth={1}
      pointBorderWidth={1}
      pointSize={4}
      tooltip={pointTooltip}
      enableArea={true}
      areaBaselineValue={0}
      areaOpacity={0.15}
      // labelFormat={format}
      useMesh
      markers={[
        ...(isStaffChart && !isXSScreen
          ? [
              {
                axis: "y",
                value: goalPercentage,
                legend: `Goal ${goalPercentage}%`,
                lineStyle: {
                  stroke: "gray",
                  strokeWidth: 2,
                },
              },
            ]
          : []),
        // Add the marker for todays date here
        hasPredictionLine
          ? {
              axis: "x", // Vertical line
              // legend: "prediction marker",
              value: new Date(Latest),
              lineStyle: {
                stroke: "#505050", // Line color
                strokeWidth: 2, // Line width
              },

              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
      ]}
      xScale={{
        type: "time",
        format: "%Y-%m-%d %H:%M",
        precision: "hour",
        useUTC: false,
      }}
      legends={
        // isMinimal
        //   ? []
        //   :
        [
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 2,
            translateY: 45,
            itemsSpacing: 10,
            itemDirection: "left-to-right",
            itemWidth: 109,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
            // onClick: (datum) =>
            //   setActiveId(activeId === datum.id ? null : datum.id), // Add this line
            onClick: (datum) => {
              setHiddenIds((currentHiddenIds) => {
                const isCurrentlyHidden = currentHiddenIds.includes(datum.id);
                const newHiddenIds = isCurrentlyHidden
                  ? currentHiddenIds.filter((id) => id !== datum.id)
                  : [...currentHiddenIds, datum.id];

                // Ensure we don't hide all lines: if newHiddenIds length is equal to the number of lines, reset it
                if (newHiddenIds.length >= processedData.length) {
                  return [];
                }
                return newHiddenIds;
              });
            },
          },
        ]
      }
    />
  );
};

export default PointHourlyLineChart;
