import React from "react";
import {
  Box,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  // Zoom,
} from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import Prefix from "../../utils/PrefixLink";
import ChartLoading from "../../components/Loading/ChartLoading";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
// const prefix = Prefix();
// const token = localStorage.getItem("token");
// const company = localStorage.getItem("company");

const UpwardTrends = ({ positiveAmountTrendData, trendsLoad }) => {
  // const [takeAwayLoad, setTakeAwayLoad] = useState(false);

  const compareTrendValues = (todaysSaleAmount, averageAmountSold) => {
    if (
      todaysSaleAmount === null ||
      averageAmountSold === null ||
      isNaN(todaysSaleAmount) ||
      isNaN(averageAmountSold)
    ) {
      return null;
    }

    const netProductTrend = todaysSaleAmount - averageAmountSold;
    const percentageChange =
      averageAmountSold !== 0 ? (netProductTrend / averageAmountSold) * 100 : 0;

    // Conditionally render the arrow only when percentageChange is not zero
    const arrowIcon =
      percentageChange !== 0 ? (
        netProductTrend >= 0 ? (
          <KeyboardDoubleArrowUpIcon sx={{ color: "green" }} />
        ) : (
          <KeyboardDoubleArrowDownIcon sx={{ color: "red" }} />
        )
      ) : null;

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // border: "solid 1px red",
          width: "100%",
        }}
      >
        <span>{percentageChange.toFixed(1)}%</span>
        {arrowIcon}
      </Box>
    );
  };
  const calculatePercentageChange = (todaysSaleAmount, averageAmountSold) => {
    if (
      todaysSaleAmount === null ||
      averageAmountSold === null ||
      isNaN(todaysSaleAmount) ||
      isNaN(averageAmountSold) ||
      averageAmountSold === 0
    ) {
      return 0; // Return 0 or some other value indicating no change or invalid calculation
    }

    const netProductTrend = todaysSaleAmount - averageAmountSold;
    return (netProductTrend / averageAmountSold) * 100;
  };
  const createTooltipContent = (
    article_group,
    sale_amount,
    average_amount_sold,
    qty_sold,
    netProductTrend
  ) => {
    const formatter = new Intl.NumberFormat("nb-NO", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    const averageAmountSoldFormatted = formatter.format(average_amount_sold);
    const todaysSaleAmountFormatted = formatter.format(sale_amount);
    const difference = sale_amount - average_amount_sold;
    const differenceFormatted = formatter.format(difference);

    // Define your style object here
    const tooltipTextStyle = {
      // fontFamily: "'Montserrat', sans-serif",

      fontSize: "16px", // Change this value to your desired font size
      // color: "#333", // Example of adding more styles, e.g., text color
      // Add more styles as needed
    };

    return (
      <div>
        <div style={tooltipTextStyle}>
          Avg from Last Period: {averageAmountSoldFormatted}
        </div>
        <div style={tooltipTextStyle}>
          This Period: {todaysSaleAmountFormatted}
        </div>
        <div style={tooltipTextStyle}>Difference: {differenceFormatted}</div>
      </div>
    );
  };
  // Example: Assuming positiveAmountTrendData is your data array
  positiveAmountTrendData.forEach((row) => {
    // Calculate percentage change for each row
    row.percentageDifference = calculatePercentageChange(
      row.sale_amount,
      row.average_amount_sold
    );
  });

  // Sort the data based on the calculated percentage difference
  positiveAmountTrendData.sort(
    (a, b) => b.percentageDifference - a.percentageDifference
  );

  //   negativeAmountTrendData.forEach((row) => {
  //     // Calculate percentage change for each row
  //     row.percentageDifference = calculatePercentageChange(
  //       row.sale_amount,
  //       row.average_amount_sold
  //     );
  //   });

  //   // Sort the data based on the calculated percentage difference
  //   negativeAmountTrendData.sort(
  //     (a, b) => a.percentageDifference - b.percentageDifference
  //   );

  //   useEffect(() => {
  //     if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
  //       setPositiveAmountTrendData([]);
  //       setNegativeAmountTrendData([]);
  //       setPositiveQuantityTrendData([]);
  //       setNegativeQuantityTrendData([]);
  //     } else {
  //       getTrends();
  //     }
  //   }, [selectedPage, selectedRestaurant, getTrends]);

  const todaySaleAmountColumn = {
    title: "Sale Amount",
    field: "todaysSaleAmount",
    render: (rowData) => {
      // Pass the article_group in the createTooltipContent function
      const tooltipContent = createTooltipContent(
        rowData.article_group,
        rowData.sale_amount,
        rowData.average_amount_sold,
        rowData.qty_sold,
        rowData.netProductTrend
      );
      const trendValues = compareTrendValues(
        rowData.sale_amount,
        rowData.average_amount_sold
      );

      return (
        <Tooltip title={tooltipContent} arrow>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              fontFamily: "'Montserrat', sans-serif",
            }}
          >
            {trendValues}
          </Box>
        </Tooltip>
      );
    },
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "90%",
          width: "100%",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            width: "100%",
            maxHeight: "100%",
            // flexGrow: 1,
          }}
        >
          <Table
            sx={{ width: "100%", height: "100%" }}
            aria-label="simple table"
          >
            {/* <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#70335D",
                    color: "#FFF",
                    position: "sticky",
                    top: 0,
                    textAlign: "center",
                    fontSize: 20,
                  }}
                >
                  Article Group
                </TableCell>
         
                <TableCell
                  align="right"
                  sx={{
                    backgroundColor: "#70335D",
                    color: "#FFF",
                    position: "sticky",
                    top: 0,
                    textAlign: "center",
                    fontSize: 20,
                  }}
                >
                  Sale Amount
                </TableCell>
              </TableRow>
            </TableHead> */}
            <TableBody sx={{ overflowY: "auto" }}>
              {trendsLoad ? (
                // Display loading indicator when data is being loaded
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <ChartLoading />
                  </TableCell>
                </TableRow>
              ) : positiveAmountTrendData.length > 0 ? (
                // If we have data, render it as table rows
                positiveAmountTrendData.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#F5F5F5" : "#FFF", // Alternating row colors
                      height: "50px", // Set a fixed height for the table rows
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      sx={{
                        fontSize: "16px",
                        fontFamily: "'Montserrat', sans-serif",
                        height: "20px", // Set a fixed height for the table cells
                      }}
                    >
                      {row.article_group}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "16px",

                        height: "20px", // Set a fixed height for the table cells
                      }}
                    >
                      {todaySaleAmountColumn.render(row)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                // Render a "No data" message if there's nothing to display
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    // border: "solid red 1px",
                  }}
                >
                  <h2> No data</h2>
                </div>
              )}
              {/* Render empty rows to maintain the design if there are less than 10 items */}
              {positiveAmountTrendData.length > 0 &&
                [
                  ...Array(Math.max(10 - positiveAmountTrendData.length, 0)),
                ].map((_, index) => (
                  <TableRow key={`empty-${index}`} sx={{ height: "20px" }}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      sx={{
                        borderBottom: "none",
                        height: "20px",
                      }}
                    />
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: "none",
                        height: "20px",
                      }}
                    />{" "}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default UpwardTrends;
