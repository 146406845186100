const getHighlightedButtonStyle = () => {
  const highlightedIconButtonStyle = {
    color: "black", // Adjust the color as needed
    backgroundColor: "#FFBD00", // Light grey background
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)", // Adding a subtle shadow
    "&:hover": {
      backgroundColor: "#e3a902", // You can adjust this color based on your preference
      // Prevent MUI Button from changing the color on hover
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#e3a902", // Adjust this color based on your preference
    },
    padding: "8px",
    margin: "4px",

    // other styles as needed
  };
  
    return highlightedIconButtonStyle;
  };
  
  export default getHighlightedButtonStyle;
  